import React, { useEffect, useState } from "react";
import { FastField, Form } from "formik";
import { Button } from "react-bootstrap";
import { withFormikFormComponent } from "../../Forms/FormikForm";
import InputComponent from "../../Forms/Input/InputComponent";
import DebugModal from "../../Debug/DebugModal";

const ComplianceReportsReviewFormInternal = (props) => {
  const { formikBag, contract, setStatus, complianceReport, handleSubmitComplianceReport } = props;
  const { values, resetForm } = formikBag;
  return (
    <div id="compliance-update">
      <h3 className="mt-4">Agency Review</h3>
      <p>Status: {complianceReport?.status}</p>

      <Form>
        <DebugModal formikBag={formikBag} name="Formik Bag" />

        <div className="field-group row">
          {complianceReport?.status === "Submitted" ? (
            <>
              <FastField
                wrapperClass="col-12 mb-3"
                label="Determination"
                name={"status"}
                component={InputComponent}
                type="select"
                options={[
                  [false, "Set Status"],
                  ["accept", "Accepted"],
                  ["deficient", "Notice of deficiency"],
                ]}
                required={false}
              />
              {["deficient"].includes(values.status) && <FastField wrapperClass="col-12" name={"notes"} component={InputComponent} type="textarea" label="Message" required={false}></FastField>}
              <div className="col-12">
                <Button
                  type="button"
                  className="btn-secondary btn-md btn-block"
                  disabled={["Submitted", "false"].includes(values?.status)}
                  onClick={async () => {
                    await setStatus(values);
                    resetForm();
                  }}
                >
                  Update
                </Button>
              </div>
            </>
          ) : null}
        </div>
      </Form>
    </div>
  );
};

const ComplianceReportsReviewForm = withFormikFormComponent(ComplianceReportsReviewFormInternal);
export default ComplianceReportsReviewForm;

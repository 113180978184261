import React from "react";
import { withFormikFormComponent } from "../../Forms/FormikForm";
import FilterTableForm from "../../Forms/Input/FilterTableForm";

const ContractListViewAgencyInternal = (props) => {
  const { initialItems } = props;

  const FilterListConfig = {
    nameLink: {
      isJoin: false,
    },
    contractStatus: {
      isJoin: false,
    },
  };

  const Columns = [
    {
      Header: "Contract",
      accessor: "nameLink", // accessor is the "key" in the data
      disableSortBy: true,
      filterCode: "nameLink",
    },
    {
      Header: "Status",
      accessor: "contractStatus", // accessor is the "key" in the data
      disableSortBy: true,
      filterCode: "contractStatus",
    },
    {
      Header: "Agency/Authority",
      accessor: "agency.name", // accessor is the "key" in the data
      disableSortBy: true,
      filterCode: "agency",
    },
    {
      Header: "Active Period",
      accessor: "activePeriod", // accessor is the "key" in the data
      disableSortBy: true,
      filterCode: "activePeriod",
    },
    {
      Header: "Utilization Plan",
      accessor: "utilizationPlanLink", // accessor is the "key" in the data
      disableSortBy: true,
      filterCode: "plan",
    },
  ];

  return (
    <section id="contracts-list">
      <div>
        {initialItems.length ? (
          <FilterTableForm tableName="contract-list" Columns={Columns} FilterListConfig={FilterListConfig} tableItems={initialItems} />
        ) : (
          <h2>There are currently no contracts assigned to your business</h2>
        )}
      </div>
    </section>
  );
};

const ContractListViewAgency = withFormikFormComponent(ContractListViewAgencyInternal);
export default ContractListViewAgency;

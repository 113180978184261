import React, { useRef, useState, useEffect } from "react";
import { FastField, Form } from "formik";
import { Link } from "react-router-dom";
import _ from "lodash";
import ContactInformationSection from "../BusinessApplication/tabs/sections/ContactInformationSection";
import { loadUserRole, loadActiveOrgId, roles } from "../../util/userHelper";
import { withFormikFormComponent } from "../Forms/FormikForm";
import InputComponent from "../Forms/Input/InputComponent";
import { getArrayIndex } from "../../util/helpers";
import AddressForm from "../BusinessApplication/tabs/sections/AddressForm";
import { schemas } from "../../util/errorHelper";
import DebugModal from "../Debug/DebugModal";
import naicsCodes from "../../util/sourceData/naicsCodes";
import counties from "../../util/sourceData/counties";
import categories from "../../util/sourceData/categories";
import { Button } from "react-bootstrap";
import { sizeCategories } from "../../util/typelistHelper";
import ArrayWrapperDynamic from "../Forms/helpers/ArrayWrapperDynamic";
import DbaForm from "../BusinessApplication/tabs/sections/DbaForm";

const UpdateBusinessInfoFormInternal = ({ formikBag, businessId, business, backendErrors }) => {
  const { values, setFieldValue, isValid, isSubmitting } = formikBag;
  const [btnText, setBtnText] = useState("Update Business");

  useEffect(() => {
    if (isSubmitting) setBtnText("Updating...");
  }, [isSubmitting]);

  const getArrayIndexWrapper = (type, arrayName) => {
    const elements = _.get(values, arrayName);
    const index = getArrayIndex(elements, type);
    return `${arrayName}.${index}`;
  };

  return (
    <div>
      <h2 id="create-contractor-account-header">{businessId ? `Edit Your Information for ${business?.legalName}` : "Create a New Contractor Account"}</h2>

      <Form className="bg-light p-4">
        <DebugModal formikBag={formikBag} name="Formik Bag" />
        <h4>Contractor Profile Information</h4>
        {[roles.DivisionAdmin, roles.DivisionUser].includes(loadUserRole()) ? (
          <div className="field-group row">
            <FastField wrapperClass="col-lg-6" label="Legal Business Name" type="text" name={`legalName`} component={InputComponent} placeholder="" />
            <div className="col-12 dba-names">
              <p>Doing Business As (DBA) Name(s) (If Applicable)</p>
              <ArrayWrapperDynamic name={`dbaNames`} additionLabel="Add New DBA Name" removalLabel="Remove DBA Name" component={DbaForm} dynamic={true} formikBag={formikBag} />
            </div>
          </div>
        ) : null}
        <div className="field-group row">
          <ContactInformationSection formikBag={formikBag} />
        </div>
        <AddressForm setFieldValue={setFieldValue} name={`${getArrayIndexWrapper("Mailing", "addresses")}`} type="Mailing" />
        <AddressForm setFieldValue={setFieldValue} name={`${getArrayIndexWrapper("Physical", "addresses")}`} type="Physical" />
        <div className="field-group row">
          {/* <FastField wrapperClass="col-md-6" label="County" type="select" name={`countyLocatedIn`} options={counties} component={InputComponent} /> */}
          <FastField wrapperClass="col-md-6" label="County" type="select" name={`countyLocatedIn`} options={[["", "Choose county"], ...counties]} component={InputComponent} />
          {loadUserRole() === roles.DivisionAdmin ? (
            <FastField wrapperClass="col-md-6" label="Business Size" type="select" name={`sizeCategory`} options={[[null, "Choose Size"], ...sizeCategories]} component={InputComponent} />
          ) : null}
        </div>
        {business?.isCertifiedSdvob ? (
          <>
            <div className="">
              <h2>New York Service Areas</h2>
            </div>
            <div>Please indicate the areas where your business can provide its goods or services in NYS. You can select individual counties, or statewide for all counties.</div>
            <p>Upon certification, this information will appear in your business profile on the Directory of New York State Certified Service-Disabled Veteran-Owned Businesses.</p>

            <div className="field-group">
              <FastField type="checkbox" label="Does your business provide goods or services statewide?" name={`isStatewide`} options={[[true, "Yes"]]} component={InputComponent} required={false} />

              {values.isStatewide !== true ? (
                <FastField
                  wrapperClass=""
                  childClass="col-6 col-md-4"
                  label="Counties served"
                  type="checkbox"
                  name={`counties`}
                  multiple={true}
                  options={counties}
                  disabled={values?.statewide}
                  component={InputComponent}
                />
              ) : null}
            </div>
          </>
        ) : null}

        {loadUserRole() === roles.DivisionAdmin ? (
          <>
            <div id="naics-form">
              <p>
                Enter NAICS codes that best describe your products and/or services. You can look up codes using the{" "}
                <a href="https://naics.com/search" target="_blank" rel="noopener noreferrer">
                  NAICS Search
                </a>{" "}
                tool.
              </p>
              <FastField
                // label="Enter up to six codes that best describe your business."
                filterBy={["id"]}
                name={`naicsCodes`}
                options={naicsCodes}
                multiple
                type="typeahead"
                selected={values?.categories}
                placeholder="Choose a code..."
                isTypeheadCodeAnInteger={true}
                component={InputComponent}
              />
            </div>
            <div className="">
              <FastField
                label="Enter categories that best describe your business."
                name={`categories`}
                selected={values?.categories}
                options={categories}
                multiple
                type="typeahead"
                placeholder="Choose a category..."
                component={InputComponent}
                allowNew={false}
              />
            </div>

            <div className="">
              <FastField
                label="Enter keywords that best describe your business."
                allowNew
                name={`tags`}
                options={[]}
                multiple
                selected={values?.tags}
                type="typeahead"
                uniqueSelect={true}
                placeholder="Choose a keyword..."
                isTypeaheadASimpleArray={true}
                component={InputComponent}
              />
            </div>
          </>
        ) : null}

        {loadUserRole() === "Business" && (
          <p>
            For requests to the division to edit additional business details, please <Link to={`/business/${loadActiveOrgId()}/correspondence`}>open a correspondence thread</Link>.
          </p>
        )}

        {backendErrors ? <div className="alert alert-primary py-3">{backendErrors}</div> : null}
        <div className="col-12">
          <div className="d-flex flex-row justify-content-center">
            {!isValid ? (
              <h3>Please complete all required information</h3>
            ) : (
              <Button type="submit" className="btn-md btn-secondary mt-3">
                {btnText}
              </Button>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

const validationSchema = loadUserRole() === "Business" ? schemas.editBusinessAsOwnerSchema : schemas.editBusinessAsDivisionSchema;
const UpdateBusinessInfoForm = withFormikFormComponent(UpdateBusinessInfoFormInternal, validationSchema);
export default UpdateBusinessInfoForm;

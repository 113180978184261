import React from "react";
import { loadUserRole } from "../../util/userHelper";

const HelpMessage = () => {
  return (
    <>
      {loadUserRole() === "Business" ? (
        <div class="row">
          <div class="col-md-10 alert alert-danger p-4 mb-4">
            <h2 className="h3">SDVOB Application Assistance</h2>
            <p class="display-5">If you need assistance with your application, call (518) 474-2015 or email veteransdevelopment@ogs.ny.gov.</p>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default HelpMessage;

import React from "react";
import { createNavLinks } from "../../util/helpers";
import Navbar from "react-bootstrap/Navbar";

const AgencyAdminNav = () => {
  const items = [
    // ["/contract-list", "Contracts"],
    ["/", "Main Dashboard"],
    ["/procurements", "Awards"],
    ["/agency-reporting/activity-report", "Agency Submissions"],
    // ["/utilization-plans/submit", "Utilization Plans"],
    // [
    //   "#",
    //   "Reports",
    //   "",
    //   [
    //     ["/agency-reporting/activity-report", "Activity Reports"],
    //     ["/agency-reporting/yearly-goal-plan", "Yearly Goal Plans"],
    //     ["/agency-reporting/narrative-statement", "Narrative Statements"],
    //   ],
    // ],
    ["/reporting", "Reporting"],
    ["/invite-prime", "Invite Prime"],
    ["/settings", "Data Import"],
  ];

  return (
    <Navbar collapseOnSelect expand="md" className="p-0 d-block">
      <div className="nav-header bg-black p-4 d-flex justify-content-between align-items-center">
        Agency Admin dashboard
        <Navbar.Toggle aria-controls="sidebar-nav" />
      </div>
      <Navbar.Collapse id="sidebar-nav">{createNavLinks(items)}</Navbar.Collapse>
    </Navbar>
  );
};

export default AgencyAdminNav;

import React from "react";
import { outputMoney, outputPercentage } from "../../../../util/helpers";

const SdvobInfo = (props) => {
  const { planToLoad, contract } = props;
  const { adjustedValue } = contract || 0;
  // console.log(adjustedValue / 100);
  // const dollarGoal = adjustedValue && planToLoad?.goal ? (adjustedValue / 100) * planToLoad?.goal : null;
  // console.log({ planToLoad, contract })

  return (
    <div>
      <div className="row mb-2">
        <div className="col-sm-5"># of SDVOBs Subcontractors</div>
        <div className="col-sm-7">
          {planToLoad?.subcontracts?.length} {contract?.primeContractor?.isCertifiedSdvob ? "(Prime is SDVOB)" : null}
        </div>
      </div>

      <div className="row mb-2">
        <div className="col-sm-5">Plan Goals</div>
        <div className="col-sm-7">{outputPercentage(planToLoad?.goal / 100)}</div>
      </div>

      <div className="row mb-2">
        <div className="col-sm-5">Total Dollar Value of SDVOB Goal</div>
        <div className="col-sm-7">{planToLoad ? outputMoney(planToLoad?.assessedValueOfPlanToSdvobs) : "N/A"}</div>
      </div>
    </div>
  );
};

export default SdvobInfo;

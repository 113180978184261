import React, { useState, useEffect } from "react";
import { getBusinessUsersByUserName, assignBusinessUser } from "../../util/api";
import { Form, FastField } from "formik";
import InputComponent from "../Forms/Input/InputComponent";
import { withFormikFormComponent } from "../Forms/FormikForm";
import { schemas } from "../../util/errorHelper";
import DebugModal from "../Debug/DebugModal";

const BusinessAddUserFormInternal = ({ formikBag }) => {
  const [businessUsers, setBusinessUsers] = useState(null);
  const { values, isValid } = formikBag;

  const fetchUsers = async () => {
    const resObj = await getBusinessUsersByUserName(values.userNamePartial);
    const newUsers = resObj.map((user) => {
      return [user.id, `${user.firstName} ${user.lastName} - ${user.userId}`];
    });
    setBusinessUsers(newUsers);
  };

  useEffect(() => {}, []);

  return (
    <>
      <h4>Add additional business users.</h4>
      <Form>
        <DebugModal formikBag={formikBag} />
        <div className="mb-4">
          <div className="row align-items-end">
            <div class="col-md-9">
              <FastField type="text" label="Username search (you can search for part of a username)" name={`userNamePartial`} component={InputComponent} />
            </div>
            <div class="col-md-3">
              <button
                type="button"
                className="btn btn-secondary btn-sm mb-md-2 btn-block"
                onClick={() => {
                  fetchUsers(values.userNamePartial);
                }}
              >
                Search Users
              </button>
            </div>
          </div>

          {businessUsers ? <FastField type="select" label="Business user to add" name="userId" options={[[null, "Choose User"], ...businessUsers]} component={InputComponent} /> : null}
        </div>

        <button type="submit" className="btn btn-secondary" disabled={!isValid}>
          Add User
        </button>
      </Form>
    </>
  );
};

const BusinessAddUserForm = withFormikFormComponent(BusinessAddUserFormInternal, schemas.addBusinessUserSchema);

export default BusinessAddUserForm;

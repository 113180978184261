import React, { useEffect, useState } from "react";
import { Field } from "formik";
import { withFormikFormComponent } from "../../Forms/FormikForm";
import { getUserId, loadUserRole, roles } from "../../../util/userHelper";
import InputComponent from "../../Forms/Input/InputComponent";
import { Button } from "react-bootstrap";
import { schemas } from "../../../util/errorHelper";
import {
  handleChangeRequestSubmit,
  handleInitialReviewCompletion,
  handleInDepthReviewCompletion,
  handleApplicationApproval,
  handleApplicationAppeal,
  handleApplicationAppealDenial,
  handleApplicationCertification,
  handleApplicationDenial,
  handleApplicationAppealAllow,
  handleVisitationStatus,
  handleRevokeCertification,
} from "../../../util/api";
import { hasExpired, outputStatus } from "../../../util/helpers";
import ModalActions from "./ModalActions";
import { getUtcDate } from "../../../util/helpers";

const ApplicationReviewFormInternal = (props) => {
  const { applicationId, formikBag, application, changeCount, setChangeCount } = props;
  const { status, phase } = application || "";
  const [changeRequestConfirmation, setChangeRequestConfirmation] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalChoice, setModalChoice] = useState();
  const { setFieldValue, values } = formikBag;
  const [minEffectiveDate, setMinEffectiveDate] = useState(null);
  const [maxEffectiveDate, setMaxEffectiveDate] = useState(null);
  const [isRecertification, setIsRecertification] = useState(false);

  const confirmRevoke = async () => {
    const resObj = await handleRevokeCertification(application?.businessId, "My reason");
    if (resObj.status === 200) {
      setShowModal(false);
      setChangeCount(changeCount + 1);
    }
  };

  useEffect(() => {
    if (!props.application) {
      return;
    }

    setIsRecertification(!!props.application.businessCertificationExpDate);

    const expirationDate = !!props.application.businessCertificationExpDate ? new Date(props.application.businessCertificationExpDate) : null;

    const certificationExpDate = expirationDate ? new Date(expirationDate.getUTCFullYear(), expirationDate.getUTCMonth(), expirationDate.getUTCDate()) : null;

    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    const certificationExpDatePlusOne = certificationExpDate ? new Date(certificationExpDate) : null;

    if (certificationExpDatePlusOne) {
      certificationExpDatePlusOne.setDate(certificationExpDate.getUTCDate() + 1);
    }

    setMinEffectiveDate(certificationExpDatePlusOne ?? today);

    setMaxEffectiveDate(today < certificationExpDatePlusOne ? certificationExpDatePlusOne : today);
  }, [props.application]);

  useEffect(() => {
    setFieldValue("effectiveDate", minEffectiveDate);
  }, [minEffectiveDate]);

  const confirmations = {
    InitialReview: {
      heading: "Initial Review",
      message: "Complete Initial Review?",
      action: handleInitialReviewCompletion,
    },
    InDepthReview: {
      heading: "In-Depth Review",
      message: "Complete In-Depth Review?",
      action: handleInDepthReviewCompletion,
    },
    Deny: {
      heading: "Confirm Denial",
      message: "I have sent an email explaining the reason for denial.",
      action: handleApplicationDenial,
      condition: true,
      secondConditionMessage: "I have entered a manual activity log entry for the denial.",
      secondCondition: true,
    },
    Approve: {
      heading: "Approval",
      message: "Approve Application?",
      action: handleApplicationApproval,
    },
    Certify: {
      heading: "Certify",
      message: "Certify Application?",
      action: handleApplicationCertification,
      includedFormValues: ["effectiveDate"],
    },
    Appeal: {
      heading: "Appeal",
      message: "Open Appeal?",
      action: handleApplicationAppeal,
    },
    DenyAppeal: {
      heading: "Deny",
      message: "Deny Appeal?",
      action: handleApplicationAppealDenial,
    },
    AllowAppeal: {
      heading: "Appeal",
      message: "Allow Appeal?",
      action: handleApplicationAppealAllow,
    },
    Revoke: {
      heading: "Revoke Certification",
      message: "Are you sure you would like to revoke this business's certification?",
      action: confirmRevoke,
    },
  };

  return (
    <>
      <div className="">
        <h3>Application Review</h3>
        <hr />
        <h3>Application status: {outputStatus(status)}</h3>

        {[roles.DivisionUser, roles.DivisionAdmin].includes(loadUserRole()) ? (
          <>
            {["Deficient"].includes(status) ? (
              <>
                <div className="bg-light p-4 mb-3">
                  <h4>Change request submitted</h4>
                </div>
              </>
            ) : (
              <>
                {/* Standard review phase options, while application is in a positive/workable status */}
                {["InitialReview", "InDepthReview"].includes(phase) && !["Denied", "Appealed", "Deficient", "AppealDenied"].includes(status) ? (
                  <>
                    {["InitialReview"].includes(phase) ? (
                      <div className="bg-light p-4 mb-3">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            checked={["Preparation", "InitialReview"].includes(phase) ? false : true}
                            id="initialReviewStatusSwitch"
                            onChange={async (e) => {
                              setModalChoice("InitialReview");
                              setShowModal(true);
                            }}
                          />
                          <label className="custom-control-label" htmlFor="initialReviewStatusSwitch">
                            Complete Initial Review
                          </label>
                        </div>
                      </div>
                    ) : null}

                    {["InDepthReview"].includes(phase) ? (
                      <div className="bg-light p-4 mb-3">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            checked={["Preparation", "InitialReview", "InDepthReview"].includes(phase) ? false : true}
                            id="inDepthReviewStatusSwitch"
                            onChange={async (e) => {
                              setModalChoice("InDepthReview");
                              setShowModal(true);
                            }}
                          />
                          <label className="custom-control-label" htmlFor="inDepthReviewStatusSwitch">
                            Complete In-Depth Review
                          </label>
                        </div>
                      </div>
                    ) : null}

                    <div className="bg-light p-4 mb-3">
                      <h4>Submit change requests</h4>
                      <Field label="Notes" name="notes" isdisabled={!!changeRequestConfirmation} type="textarea" component={InputComponent}></Field>
                      {changeRequestConfirmation ? <p className="text-success small">{changeRequestConfirmation}</p> : null}
                      <Button
                        name="submit-change-request"
                        type="submit"
                        variant="secondary"
                        className="btn-block btn-sm mt-3"
                        onClick={async () => {
                          try {
                            const description = formikBag?.values?.notes;
                            const preparerId = application?.preparerId;
                            const requesterId = getUserId();
                            const status = "Created";
                            await handleChangeRequestSubmit(applicationId, description, preparerId, requesterId, status);
                            setFieldValue("notes", "");
                            setChangeRequestConfirmation("Change request successfully submitted");
                            setChangeCount(changeCount + 1);
                          } catch (e) {
                            console.log("Error processing", e);
                          }
                        }}
                      >
                        UPDATE
                      </Button>
                    </div>
                  </>
                ) : null}
              </>
            )}
          </>
        ) : null}

        {/* Approve, Deny, Appeal Actions and Director Visit are restricted to Division Admins */}
        {loadUserRole() === roles.DivisionAdmin ? (
          <>
            {/* If application is active, show the actions */}
            {!["Approved", "Certified", "Denied", "Withdrawn"].includes(status) ? (
              <>
                <div className="bg-light p-4 mb-3">
                  <Field
                    label="Site Visitation Status"
                    name="visitationStatus"
                    type="select"
                    options={[
                      ["VisitScheduled", "Scheduled"],
                      ["VisitCompleted", "Completed"],
                      ["VisitCancelled", "Cancelled"],
                      ["NotNeeded", "Not Needed"],
                    ]}
                    component={InputComponent}
                  ></Field>

                  <Button
                    name="submit-visitation-status"
                    type="submit"
                    variant="secondary"
                    className="btn-block btn-sm mt-3"
                    onClick={(e) => {
                      handleVisitationStatus(applicationId, values.visitationStatus);
                    }}
                  >
                    UPDATE
                  </Button>
                </div>

                {!["Appealed", "AppealDenied"].includes(status) && ["InDepthReview", "DirectorReview"].includes(phase) ? (
                  <Button
                    type="submit"
                    variant="primary"
                    className="btn-block btn-sm mt-3"
                    onClick={async (e) => {
                      setModalChoice("Deny");
                      setShowModal(true);
                    }}
                  >
                    Deny Application
                  </Button>
                ) : null}

                {["Appealed"].includes(application?.status) ? (
                  <>
                    <Button
                      type="submit"
                      variant="primary"
                      className="btn-block btn-sm mt-3"
                      onClick={async (e) => {
                        setModalChoice("DenyAppeal");
                        setShowModal(true);
                      }}
                    >
                      Deny Appeal
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      className="btn-block btn-sm mt-3"
                      onClick={async (e) => {
                        setModalChoice("AllowAppeal");
                        setShowModal(true);
                      }}
                    >
                      Allow Appeal
                    </Button>
                  </>
                ) : null}

                {["DirectorReview", "Appealed"].includes(application?.phase) ? (
                  <Button
                    type="submit"
                    variant="secondary"
                    className="btn-block btn-sm mt-3"
                    onClick={async (e) => {
                      setModalChoice("Approve");
                      setShowModal(true);
                    }}
                  >
                    Approve Application
                  </Button>
                ) : null}
              </>
            ) : null}

            {/* If application isn't active, show these other options */}
            {status === "Denied" && !hasExpired(application?.timers.find((item) => item?.type === "AbleToAppeal")) ? (
              <Button
                type="submit"
                variant="primary"
                className="btn-block btn-sm mt-3"
                onClick={async (e) => {
                  setModalChoice("Appeal");
                  setShowModal(true);
                }}
              >
                Open Appeal
              </Button>
            ) : null}

            {status === "Approved" ? (
              <>
                <div class="pb-4" hidden={!isRecertification || minEffectiveDate.getTime() === maxEffectiveDate.getTime()}>
                  <div>
                    <p class="pb-4">
                      The effective date can only be backdated as far back as the business's original certification expiration date of {getUtcDate(minEffectiveDate)} and can only be as recent as
                      today's date of {getUtcDate(maxEffectiveDate)}.
                    </p>
                    <Field
                      type="datepicker"
                      label="Effective Date"
                      name={`effectiveDate`}
                      maxDate={maxEffectiveDate}
                      minDate={minEffectiveDate}
                      monthPlaceholder={"MM"}
                      dayPlaceholder={"DD"}
                      yearPlaceholder={"YYYY"}
                      component={InputComponent}
                    />
                  </div>
                </div>
                <p class="pb-4" hidden={!isRecertification || minEffectiveDate.getTime() !== maxEffectiveDate.getTime()}>
                  The effective date for certification will be {getUtcDate(minEffectiveDate)}.
                </p>
                <Button
                  type="submit"
                  variant="secondary"
                  className="btn-block btn-sm mt-3"
                  onClick={async (e) => {
                    setModalChoice("Certify");
                    setShowModal(true);
                  }}
                >
                  Certify Application
                </Button>
              </>
            ) : null}
          </>
        ) : null}
      </div>

      <ModalActions
        showModal={showModal}
        setShowModal={setShowModal}
        confirmations={confirmations}
        modalChoice={modalChoice}
        setModalChoice={setModalChoice}
        formikBag={formikBag}
        application={application}
        setChangeCount={setChangeCount}
        changeCount={changeCount}
      />
    </>
  );
};

const ApplicationReviewForm = withFormikFormComponent(ApplicationReviewFormInternal, schemas.changeRequestSchema);
export default ApplicationReviewForm;

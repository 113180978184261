import React from "react";
import { Link } from "react-router-dom";
import PlanPresenceList from "./PlanPresenceList";

const PlanPresence = (props) => {
  return (
    <section id="utilization-plans-presence">
      <PlanPresenceList />
    </section>
  );
};

export default PlanPresence;

import React, { useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";

const Logout = props => {

  useEffect(() => {
    props.logOut()
  }, [])

  return (<>
    <div>You do not have the authorization to perform this action.</div>
  </>)
};

export default Logout;

import React, { useEffect, useState } from "react";
import { Form, FastField } from "formik";
import { withFormikFormComponent } from "../../Forms/FormikForm";
import ReactiveButton from "../../Forms/Input/ReactiveButton";
import InputComponent from "../../Forms/Input/InputComponent";
import DebugModal from "../../Debug/DebugModal";
import { getAgencies } from "../../../util/api";

function SystemSeedDataFormInternal(props) {
  const { formikBag, successMessage, setSuccessMessage } = props;
  const { setFieldValue, values, isSubmitting, isValid } = formikBag;
  const [agencies,setAgencies] = useState([]);

   useEffect(() => {
    async function fetchData() {

      const agenciesRes = await getAgencies();
      const agenciesOptions = agenciesRes.agencies.map((item) => {
        return [item.id, item.name];
      });
      setAgencies(agenciesOptions);
    }
    fetchData();
  }, []);

  return (
    <>
      <Form>
        <div className="row mb-2">
        { agencies.length ? <FastField wrapperClass="col-12" type="select" options={[[null,'Choose an agency'],...agencies]} name={`agencyId`} component={InputComponent} label="Agency to Seed"></FastField>
          : 'Loading Agencies'
        }
      </div>
        <p className="text-success">{successMessage}</p>
        <ReactiveButton type="submit" className="small btn-secondary margin-auto" label={"Submit"} processing={isSubmitting} disabled={!isValid} />
      </Form>
    </>
  );

}

const SystemSeedDataForm = withFormikFormComponent(SystemSeedDataFormInternal);
export default SystemSeedDataForm;

import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import BigButton from "../components/CTAs/BigButton";

export default function Application404(props) {
  const { userInfo, loginAndReturn } = props;
  // console.log(props);
  const location = useLocation();
  const isHome = location.pathname === "/";
  return (
    <div>
      {isHome ? (
        <>
          <h3>Welcome to the New York State Office of General Services Division of Service-Disabled Veterans' Business Development Portal.</h3>
          <p>
            Article 3 of the Veterans’ Services Law allows eligible Veteran business owners to get certified as a New York State Service-Disabled
            Veteran-Owned Business (SDVOB). The goal of the Article is to encourage and support eligible SDVOBs to play a greater role in the state's economy by increasing their participation in New York
            State's contracting opportunities. The OGS Division of Service-Disabled Veterans' Business Development is responsible for certifying eligible SDVOBs and assisting and promoting their
            participation in the state's procurement activities.
          </p>
           <div className="row justify-content-center">

             <BigButton
              icon={"search"}
              iconColor="primary"
              heading={`Search the directory of Service-Disabled Veteran Owned Businesses`}
              content=""
              ctaText="Search"
              route={`/business-search`}
              id="sdvob-search"
            />

          </div>
          <div className="my-4">
            <p>
              <strong>
                We are excited to begin the rollout of our Service-Disabled Veterans' Enterprise System (SDVES). The Directory (above) is ready for your use. The remaining parts of SDVES are being systematically rolled out. You will be notified once the sections below are ready for your use.
              </strong>
            </p>

          </div>
          <div className="row">
            <BigButton
              icon={"toolbox"}
              iconColor="secondary"
              heading={`Service-Disabled Veteran Owned Business`}
              content="Certify or Manage my Business"
              ctaText="Continue"
              route={`/login`}
              id="sdvob-login"
            />

            <BigButton
              icon={"wrench"}
              iconColor="secondary"
              heading={`Contractor Providing Services to New York State`}
              content="Register or Manage my Business"
              ctaText="Continue"
              route={`/login`}
              id="prime-signup-login"
            />

            <BigButton icon={"building"} iconColor="secondary" heading={`New York State Agency or Authority`} content="Administer my Program" ctaText="Continue" route={`/login`} id="agency-login" />
          </div>
        <div className="my-4">
            <p>
            If you are a participating Service-Disabled Veteran's Owned Business, or an Agency / Authority user, log in using the appropriate option above.
            </p>
          </div>
        </>
      ) : (
        <div className="text-center">
          <h2 className=" text-primary">404</h2>
          <p className="">The page either doesn't exist, or you do not have permission to view it.</p>

          {userInfo ? (
            <Link to="/" className="btn btn-secondary">
              Back to Dashboard
            </Link>
          ) : (
            <button onClick={loginAndReturn} className="btn btn-secondary">
              Log in to the SDVES
            </button>
          )}
        </div>
      )}
    </div>
  );
}

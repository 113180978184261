import { FastField } from "formik";
import _ from "lodash";
import React from "react";
import DocumentWrapper from "../../../Forms/helpers/DocumentWrapper";
import InputComponent from "../../../Forms/Input/InputComponent";

const TaxReturnsForm = ({ name, values, yearNumber, formikBag }) => {
  const index = yearNumber - 1;
  const localName = `${name}.${index}`;
  const localValue = _.get(values, localName);

  return (
    <div className="tax-returns-group">
      <div className="row">
        <FastField
          wrapperClass="col-md-7"
          type="checkbox"
          name={`${localName}.documentationExists`}
          component={InputComponent}
          options={[[false, `The tax return for year ${yearNumber} is not available to me`]]}
          required={false}
        />
      </div>
      {localValue?.documentationExists !== false ? (
        <div className="row">
          <FastField wrapperClass="col-md-6" label={`Tax Year ${yearNumber}, Beginning`} type="inputdate" name={`${localName}.yearBeginning`} placeholder="MM/DD/YYYY" component={InputComponent} />

          <FastField wrapperClass="col-md-6" label={`Tax Year ${yearNumber}, Ending`} type="inputdate" name={`${localName}.yearEnding`} placeholder="MM/DD/YYYY" component={InputComponent} />

          <FastField
            wrapperClass="col-12"
            label="Gross Annual Receipts Less Returns and Allowances (See Above for Assistance Locating This Line Item)"
            type="inputnumeral"
            name={`${localName}.value`}
            placeholder=""
            component={InputComponent}
          />
          <div className="col-12 pt-3">
            <DocumentWrapper
              // label={`Tax year ${yearNumber}`}
              documentType={`TaxReturn`}
              formikBag={formikBag}
              name={`${localName}`}
              optional={true}
              embeddedDoc={true}
            />
          </div>
        </div>
      ) : (
        <div className="row">
          <FastField wrapperClass="col-md-7" type="textarea" label="Please explain" name={`${localName}.description`} component={InputComponent} required={true} />
        </div>
      )}
      <hr />
    </div>
  );
};
export default TaxReturnsForm;

import { FastField } from "formik";
import React from "react";
import Button from "react-bootstrap/Button";
import { useHistory, useLocation } from "react-router-dom";
import { createNarrativeStatement, saveNarrativeStatement, submitNarrativeStatement } from "../../../util/api";
import { schemas } from "../../../util/errorHelper";
import DebugModal from "../../Debug/DebugModal";
import { withFormikFormComponent } from "../../Forms/FormikForm";
import InputComponent from "../../Forms/Input/InputComponent";

const NarrativeStatementSubmitFormInternal = (props) => {
  const { formikBag, hasSaved, setHasSaved, reportId } = props;
  const { values } = formikBag;
  const history = useHistory();
  const location = useLocation();

  const handleSubmit = async (values, action) => {
    const { fiscalYear, statementText, commissionerName, chiefCounselName, chiefFinancialOfficerName, primarySDVOBComplianceContactName, primarySDVOBComplianceContactEmailAddress, agencyId } = values;

    let submitObj = {
      id: reportId,
      statementText: statementText,
      commissionerName: commissionerName,
      chiefCounselName: chiefCounselName,
      chiefFinancialOfficerName: chiefFinancialOfficerName,
      primarySDVOBComplianceContactName: primarySDVOBComplianceContactName,
      primarySDVOBComplianceContactEmailAddress: primarySDVOBComplianceContactEmailAddress,
    };

    if (action === "create") {
      submitObj = { agencyId: agencyId, fiscalYear: fiscalYear };
      const resObj = await createNarrativeStatement(submitObj);
      history.push(`${location.pathname}/${resObj.data.id}`);
    }

    if (action === "save") {
      await saveNarrativeStatement(submitObj);
      setHasSaved(`Report saved ${new Date().toLocaleString()}`);
    }

    if (action === "submit") {
      await submitNarrativeStatement(submitObj);
      history.push("/agency-reporting/narrative-statement");
    }
  };

  return (
    <section id="narrative-statement-submit-form">
      {hasSaved ? (
        <div className="alert alert-secondary">
          <p>{hasSaved}</p>
        </div>
      ) : null}
      <DebugModal formikBag={formikBag} name="Formik Bag" />

      <div id="narrative-statement-submit-form-wrapper" className="bg-light p-4">
        <h3>Narrative Statement Submission</h3>
        <hr />
        <form>
          <div className="field-group row">
            <FastField wrapperClass="col-md-6" label="Fiscal Year" type="number" name={"fiscalYear"} component={InputComponent} placeholder="" isdisabled={!!reportId} />
            {values.narrativeStatementId ? (
              <>
                <FastField wrapperClass="col-md-6" label="Commissioner Name" type="text" name={"commissionerName"} component={InputComponent} placeholder="" />
                <FastField wrapperClass="col-md-6" label="Chief Counsel Name" type="text" name={"chiefCounselName"} component={InputComponent} placeholder="" />
                <FastField wrapperClass="col-md-6" label="Chief Financial Officer Name" type="text" name={"chiefFinancialOfficerName"} component={InputComponent} placeholder="" />
                <FastField wrapperClass="col-md-6" label="Primary SDVOB Compliance Contact Name" type="text" name={"primarySDVOBComplianceContactName"} component={InputComponent} placeholder="" />
                <FastField
                  wrapperClass="col-md-6"
                  label="Primary SDVOB Compliance Email Address"
                  type="email"
                  name={"primarySDVOBComplianceContactEmailAddress"}
                  component={InputComponent}
                  placeholder=""
                />
                <FastField wrapperClass="col-12" label="Statement Text" type="textarea" name={"statementText"} component={InputComponent} placeholder="" />
              </>
            ) : null}
          </div>
          <div className="row">
            {values.narrativeStatementId ? (
              <>
                <div className=" col-md-6">
                  <Button
                    onClick={async () => {
                      await handleSubmit(values, "save");
                    }}
                    type="button"
                    variant="secondary"
                    className="btn-md btn-submit btn-block"
                  >
                    Save
                  </Button>
                </div>
                <div className=" col-md-6">
                  <Button
                    onClick={async () => {
                      await handleSubmit(values, "submit");
                    }}
                    type="button"
                    variant="secondary"
                    className="btn-md btn-submit btn-block"
                    disabled={!formikBag.isValid}
                  >
                    Submit
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className=" col-md-6">
                  <Button
                    onClick={async () => {
                      await handleSubmit(values, "create");
                    }}
                    type="button"
                    variant="secondary"
                    className="btn-md btn-submit btn-block"
                    disabled={!formikBag.isValid}
                  >
                    Create
                  </Button>
                </div>
              </>
            )}
          </div>
        </form>
      </div>
    </section>
  );
};

const NarrativeStatementSubmitForm = withFormikFormComponent(NarrativeStatementSubmitFormInternal, schemas.editNarrativeStatementSchema);
export default NarrativeStatementSubmitForm;

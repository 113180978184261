import React from "react";
import { FastField } from "formik";
import * as Yup from "yup";
import InputComponent from "../../../Forms/Input/InputComponent";

// const schema = Yup.object().shape({
//   name: Yup.string().required("Required"),
//   title: Yup.string().required("Required here but"),
//   role: Yup.string().required("Required"),
// });

const DbaForm = ({ name, errors, touched, formikBag }) => {
  return (
    <div className="dbaNames-group fade-in">
      <div className="row">
        <FastField wrapperClass="col-md-6" label="DBA Name" type="text" name={`${name}.name`} placeholder="" component={InputComponent} />
      </div>
    </div>
  );
};
export default DbaForm;

export default [
  ["Albany", "Albany"],
  ["Allegany", "Allegany"],
  ["Bronx", "Bronx"],
  ["Broome", "Broome"],
  ["Cattaraugus", "Cattaraugus"],
  ["Cayuga", "Cayuga"],
  ["Chautauqua", "Chautauqua"],
  ["Chemung", "Chemung"],
  ["Chenango", "Chenango"],
  ["Clinton", "Clinton"],
  ["Columbia", "Columbia"],
  ["Cortland", "Cortland"],
  ["Delaware", "Delaware"],
  ["Dutchess", "Dutchess"],
  ["Erie", "Erie"],
  ["Essex", "Essex"],
  ["Franklin", "Franklin"],
  ["Fulton", "Fulton"],
  ["Genesee", "Genesee"],
  ["Greene", "Greene"],
  ["Hamilton", "Hamilton"],
  ["Herkimer", "Herkimer"],
  ["Jefferson", "Jefferson"],
  ["Kings", "Kings"],
  ["Lewis", "Lewis"],
  ["Livingston", "Livingston"],
  ["Madison", "Madison"],
  ["Monroe", "Monroe"],
  ["Montgomery", "Montgomery"],
  ["Nassau", "Nassau"],
  ["New York", "New York"],
  ["Niagara", "Niagara"],
  ["Oneida", "Oneida"],
  ["Onondaga", "Onondaga"],
  ["Ontario", "Ontario"],
  ["Orange", "Orange"],
  ["Orleans", "Orleans"],
  ["Oswego", "Oswego"],
  ["Otsego", "Otsego"],
  ["Putnam", "Putnam"],
  ["Queens", "Queens"],
  ["Rensselaer", "Rensselaer"],
  ["Richmond", "Richmond"],
  ["Rockland", "Rockland"],
  ["St. Lawrence", "St. Lawrence"],
  ["Saratoga", "Saratoga"],
  ["Schenectady", "Schenectady"],
  ["Schoharie", "Schoharie"],
  ["Schuyler", "Schuyler"],
  ["Seneca", "Seneca"],
  ["Steuben", "Steuben"],
  ["Suffolk", "Suffolk"],
  ["Sullivan", "Sullivan"],
  ["Tioga", "Tioga"],
  ["Tompkins", "Tompkins"],
  ["Ulster", "Ulster"],
  ["Warren", "Warren"],
  ["Washington", "Washington"],
  ["Wayne", "Wayne"],
  ["Westchester", "Westchester"],
  ["Wyoming", "Wyoming"],
  ["Yates", "Yates"],
  ["Out-Of-State", "Out-Of-State"]
];

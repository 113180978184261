import React from "react";
import { componentsFromRoutes } from "../../../util/helpers";
import AgencyUserManagement from "./AgencyUserManagement";
import UserListNav from "./UserListNav";

const UserManagement = () => {
  const UserManagementRoutes = {
    routes: [
      {
        path: "/user-list/agency/:agencyUserId",
        title: "Activity Report",
        exact: true,
        component: AgencyUserManagement,
        props: {},
      },
      {
        path: "/user-list/agency",
        title: "Activity Logs",
        exact: true,
        component: AgencyUserManagement,
        props: {},
      },
    ],
  };

  return (
    <>
      {/* <UserListNav /> */}
      {componentsFromRoutes(UserManagementRoutes)}
    </>
  );
};

export default UserManagement;

import React from "react";
import './ApplicationChanges.scss'

const ApplicationChanges = (props) => {
  const { mergedDetail } = props;


  // console.log(mergedLog);
  return (
    <>
      <div className="">
        {mergedDetail ? mergedDetail.map((item, index) => {
          if (item?.OldValue !== item?.NewValue) {

            return (
              <div className="mb-3" key={`${item.FieldName}-${index}`}>
                <h4>{item?.FieldName}</h4>
                <div className="row">
                  <div className="mb-2 col-6">
                    From:
                    {/* <pre className="bg-light p-3">{JSON.stringify(item?.OldValue, undefined, 4).replace(/\\n/g, '')}</pre> */}
                    <pre className="bg-light p-3">{item?.OldValue !== null ? String(item?.OldValue)?.replace(/\\n|  +/g, '') : 'NO VALUE SET'}</pre>
                  </div>
                  <div className="mb-2 col-6">
                    To:
                    {/* <pre className="bg-light p-3">{JSON.stringify(item?.NewValue, undefined, 4)?\n/g, '')}</pre> */}
                    <pre className="bg-light p-3">{item?.NewValue !== null ? String(item?.NewValue)?.replace(/\\n|  +/g, '') : 'NO VALUE SET'}</pre>
                  </div>
                </div>
              </div>
            )
          }
        }) : null}
      </div>
    </>

  )
}

export default ApplicationChanges;

import { FastField, Form } from "formik";
import _ from "lodash";
import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { handlePrimeContractorSignup, handlePrimeLookup } from "../../util/api";
import { schemas } from "../../util/errorHelper";
import { getArrayIndex, getBackendErrors } from "../../util/helpers";
import AddressForm from "../BusinessApplication/tabs/sections/AddressForm";
import ContactInformationSection from "../BusinessApplication/tabs/sections/ContactInformationSection";
import DebugModal from "../Debug/DebugModal";
import { withFormikFormComponent } from "../Forms/FormikForm";
import InputComponent from "../Forms/Input/InputComponent";

const INITIAL_VALUES = {
  addresses: [
    {
      type: "Mailing",
      line1: "",
      city: "",
      state: "",
      zipCode: "",
    },
    // {
    //   type: "Physical"
    // }
  ],
  legalName: "",
  federalId: "",
  nysVendorId: "",
  isNewVendor: false,
  // firstName: '',
  // lastName: '',
  phoneNumber: "",
  emailAddress: "",
  isCertifiedSmallBusiness: false,
  isCertifiedMinorityOwned: false,
  isCertifiedWomenOwned: false,
  isCapableMeetingInsuranceRequirements: false,
};

const PrimeContractorInternal = ({ formikBag, backendErrors }) => {
  const { values, setFieldValue, submitCount } = formikBag;

  const getArrayIndexWrapper = (type, arrayName) => {
    const elements = _.get(values, arrayName);
    const index = getArrayIndex(elements, type);
    return `${arrayName}.${index}`;
  };

  const frontendErrorRefs = Object.keys(formikBag.errors);
  return (
    <div>
      {values.id ? (
        <div>
          <h2>Created the prime contractor</h2>
        </div>
      ) : (
        <Form>
          <h2 id="create-contractor-account-header" className="mb-4">
            Create a New Contractor Account
          </h2>

          <Card>
            <Card.Body>
              <DebugModal formikBag={formikBag} name="Formik Bag" />

              <h3>Contractor Profile Information</h3>
              <div className="field-group row mt-3">
                <FastField
                  wrapperClass="col-12"
                  type="radio"
                  label="What action would you like to take with your business?"
                  name={`isNewVendor`}
                  options={[
                    [false, "I am an existing New York State vendor."],
                    [true, "I want to fill out a new prime contractor profile."],
                  ]}
                  component={InputComponent}
                  required={false}
                />
              </div>
              <div className="field-group row">
                {values?.isNewVendor ? <FastField wrapperClass="col-6" label="Legal Business Name" type="text" name={`legalName`} placeholder="" component={InputComponent} /> : null}
                <FastField wrapperClass="col-6" label="Federal Employer Identification Number (FEIN)" type="inputFEIN" name={`federalId`} placeholder="" component={InputComponent} />
                {/* <FastField wrapperClass="col-6" label="State Vendor ID" type="text" name={`nysVendorId`} placeholder="" component={InputComponent} /> */}
              </div>

              {values?.isNewVendor ? (
                <>
                  <div className="field-group row">
                    <ContactInformationSection showPhoneExtension={false} />
                  </div>
                  <div>
                    <AddressForm name={`${getArrayIndexWrapper("Mailing", "addresses")}`} type="Mailing" setFieldValue={setFieldValue} />
                  </div>

                  <div className="field-group row"></div>

                  {/* <h4>Please identify if any of the following apply?</h4>
                  <div className="mb-4">
                    <FastField
                      wrapperClass=""
                      label="New York State Small Business as defined in Executive Law Section 31o(20) and as detailed in the 'New York State Required Certifications' included in Appendix B herein."
                      type="radio"
                      name={`isCertifiedSmallBusiness`}
                      options={[
                        [true, "Yes"],
                        [false, "No"],
                      ]}
                      placeholder=""
                      component={InputComponent}
                      required={true}
                    />
                    <FastField
                      wrapperClass=""
                      label="New York State Certified Minority Owned Business"
                      type="radio"
                      name={`isCertifiedMinorityOwned`}
                      options={[
                        [true, "Yes"],
                        [false, "No"],
                      ]}
                      placeholder=""
                      component={InputComponent}
                      required={true}
                    />
                    <FastField
                      wrapperClass=""
                      label="New York Certified Woman Owned Business"
                      type="radio"
                      name={`isCertifiedWomenOwned`}
                      options={[
                        [true, "Yes"],
                        [false, "No"],
                      ]}
                      placeholder=""
                      component={InputComponent}
                      required={true}
                    />
                    <FastField
                      wrapperClass=""
                      label="Do you understand and is your firm capable of meeting the insurance requirements to enter into a contract with New York State?"
                      type="radio"
                      name={`isCapableMeetingInsuranceRequirements`}
                      options={[
                        [true, "Yes"],
                        [false, "No"],
                      ]}
                      placeholder=""
                      component={InputComponent}
                      required={true}
                    />
                  </div> */}
                </>
              ) : null}
              {/* {backendErrors ? <div className="alert alert-primary py-3">{backendErrors}</div> : null} */}
              {frontendErrorRefs.length && submitCount ? <div className="alert alert-primary py-3">Please check the form above for errors, and try again.</div> : null}
              <div className="col-12">
                <div className="d-flex flex-row justify-content-center">
                  <Button id="create-account" type="submit" className="btn-secondary btn-md">
                    {values?.isNewVendor ? "CREATE ACCOUNT" : "Find my Business"}
                  </Button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Form>
      )}
    </div>
  );
};
const PrimeContractorFormik = withFormikFormComponent(PrimeContractorInternal, schemas.primeContractorSignupSchema);

const PrimeContractor = ({ userInfo, routeInitialValues, onActiveOrgId }) => {
  const history = useHistory();
  const [primeContractor, setPrimeContractor] = useState({ ...routeInitialValues, ...INITIAL_VALUES });
  const [backendErrors, setBackendErrors] = useState();
  const onSubmit = async (values) => {
    let processedValues = values;
    if (!values?.isNewVendor) {
      console.log("existing");
      try {
        const resObj = await handlePrimeLookup(processedValues);
        // makeBusinessPromptToShownLocal();
        window.scrollTo(0, 0);
        await onActiveOrgId(resObj.data.id);
        history.push(`/`);
      } catch (e) {
        setBackendErrors(
          <div>
            <p>{getBackendErrors(e)}</p>
            <p>Try another FEIN above, or fill out a contractor profile</p>
          </div>
        );
      }
    } else {
      try {
        const resObj = await handlePrimeContractorSignup(processedValues);
        // makeBusinessPromptToShownLocal();
        window.scrollTo(0, 0);
        await onActiveOrgId(resObj.data.id);
        history.push(`/`);
      } catch (e) {
        setBackendErrors(<p>{getBackendErrors(e)}</p>);
      }
    }
  };

  return <PrimeContractorFormik validateOnChange={true} validateOnMount={true} routeInitialValues={primeContractor} onSubmit={onSubmit} backendErrors={backendErrors} />;
};
export default PrimeContractor;

import React from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilterTableForm from "../../Forms/Input/FilterTableForm";

const AgencyUserList = ({ agencies, users }) => {
  const history = useHistory();

  const filterListConfig = {
    name: {},
    agencies: {
      type: "typeahead",
      options: agencies?.map((agency) => ({ id: agency.name, label: agency.name })),
    },
  };

  const columns = [
    // accessor is the "key" in the data
    {
      Header: "Name",
      accessor: "name",
      disableSortBy: true,
      filterCode: "name",
    },
    {
      Header: "Email",
      accessor: "emailAddress",
      disableSortBy: true,
      filterCode: "emailAddress",
    },
    {
      Header: "User ID",
      accessor: "userId",
      disableSortBy: true,
      filterCode: "userId",
    },
    {
      Header: "Role",
      accessor: "role",
      disableSortBy: true,
      filterCode: "role",
    },
    {
      Header: "Agencies",
      accessor: "agencies",
      disableSortBy: true,
      filterCode: "agencies",
    },
    // {
    //   Header: "Agency List",
    //   accessor: "simpleAgencyList",
    //   defaultCanSort: true,
    //   filterCode: "simpleAgencyList",
    //   isVisible: false,
    // },
    {
      Header: "Actions",
      accessor: "actions",
      disableSortBy: true,
      filterCode: "actions",
    },
  ];

  // const getAgencyNameFromId = (agencyId) => {
  //   // console.log(agencies);
  //   if (agencies?.length) {
  //     let agency = agencies.find((agency) => agency.id === agencyId);
  //     return agency?.name ? agency.name : agencyId;
  //   } else {
  //     return agencyId;
  //   }
  // };

  // edit button
  const tableActions = (agencyUserId) => (
    <div>
      <button
        type="link"
        className="faux-link"
        to={`/user-list/agency/${agencyUserId}`}
        aria-label="Edit"
        onClick={() => {
          window.scrollTo(0, 0);
          history.push(`/user-list/agency/${agencyUserId}`);
        }}
      >
        <FontAwesomeIcon className="mr-2 text-success" icon={["fas", "pencil-alt"]} size="sm" />
      </button>
    </div>
  );

  const initialItems = users?.map((user) => {
    const { role, firstName, lastName, agencyIds, id } = user;

    return {
      ...user,
      name: `${lastName}${", "}${firstName}`,
      agencies: user?.agencyNames ? user?.agencyNames.join(", ") : "",
      simpleAgencyList: agencyIds.join(", "),
      actions: tableActions(id),
      role: _.startCase(role),
    };
  });

  return initialItems.length > 0 && <FilterTableForm tableName="agency-user-list" Columns={columns} FilterListConfig={filterListConfig} tableItems={initialItems} />;
};

export default AgencyUserList;

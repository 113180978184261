import React from "react";
import { loadActiveOrgId } from "../../../util/userHelper";
import PlanReviewSubmitForm from "./PlanReviewSubmitForm";
import PlansAndWaiversBusiness from "./PlansAndWaiversBusiness";
import { roles, loadUserRole } from "../../../util/userHelper";

const PlanReview = (props) => {
  const { procurement, procurementId, onSubmit, planToLoad } = props;
  const { contract } = procurement;
  let planStatusOptions = [
    [false, "Choose Status"],
    ["deficient", "Deficient"],
    ["approve", "Approve"],
  ];

  if (planToLoad?.awardStatus !== "Awarded") {
    planStatusOptions.push(["deny", "Deny"]);
  }
  // console.log(planToLoad);
  return (
    <section id="plan-waiver">
      <div className="row">
        <div className="col-md-8">
          <PlansAndWaiversBusiness
            contract={procurement?.contract}
            procurement={procurement}
            procurementId={procurement?.id}
            activeBusinessId={loadActiveOrgId()}
            version={planToLoad ? planToLoad?.history?.length + 1 : 1}
            planVersion={planToLoad}
          />
        </div>
        {![roles.DivisionAdmin, roles.DivisionUser].includes(loadUserRole()) && (
          <div className="col-4 bg-light mt-3 py-3">
            <h3 className="mt-4">Agency Review</h3>
            {["Submitted"].includes(planToLoad?.status) ? (
              <PlanReviewSubmitForm
                contract={contract}
                procurement={procurement}
                onSubmit={onSubmit}
                planStatusOptions={planStatusOptions}
                planToLoad={planToLoad}
                procurementId={procurementId}
                routeInitialValues={{ status: planToLoad?.status }}
              />
            ) : (
              <p>Status: {planToLoad?.status}</p>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default PlanReview;

import React, { useEffect, useState } from "react";
import { withFormikFormComponent } from "../../Forms/FormikForm";
import FilterTableForm from "../../Forms/Input/FilterTableForm";
import { getLocalTime } from "../../../util/helpers";
import _ from "lodash";
import { getAnnotationsLogs } from "../../../util/api";

const FilterListConfig = {
  type: {
    isJoin: false,
  },
  created: {
    isJoin: false,
  },
};

const Columns = [
  {
    Header: "Change Type",
    accessor: "type", // accessor is the "key" in the data
    disableSortBy: false,
    filterCode: "type",
  },
  {
    Header: "Description",
    accessor: "description", // accessor is the "key" in the data
    disableSortBy: false,
    filterCode: "description",
  },
  {
    Header: "Date",
    accessor: "created", // accessor is the "key" in the data
    disableSortBy: false,
    filterCode: "created",
  },
];

const AnnotationsLogInternal = (props) => {
  const { procurement, procurementId } = props;
  // const { annotations } = procurement;
  const [annotationsLogs, setAnnotationsLogs] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const resObj = await getAnnotationsLogs(procurementId);
      const annotationsSorted = resObj.data.annotations.sort((a, b) => (a.created < b.created ? 1 : -1));
      setAnnotationsLogs(annotationsSorted);
    };
    fetchData();
  }, []);

  const initialItems = annotationsLogs
    ? annotationsLogs?.map((item, index) => {
        return {
          ...item,
          created: getLocalTime(item.created),
          // type: annotationsLogsTypes[item.type]
          type: _.startCase(item.type),
        };
      })
    : null;
  // console.log(initialItems);

  return (
    <section id="annotations-list">
      <div>
        <h2>
          Annotations Log <span className="font-weight-normal">for {procurement?.description}</span>
        </h2>
        {initialItems ? (
          initialItems.length ? (
            <FilterTableForm tableName="annotation-log-list" Columns={Columns} FilterListConfig={FilterListConfig} tableItems={initialItems} />
          ) : (
            <h3>No Annotations Logs to Show</h3>
          )
        ) : (
          <h2>Loading...</h2>
        )}
      </div>
    </section>
  );
};

const AnnotationsLog = withFormikFormComponent(AnnotationsLogInternal);
export default AnnotationsLog;

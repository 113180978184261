import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getLocalDate } from "../../util/helpers";
import FilterTableForm from "../Forms/Input/FilterTableForm";
import { handleDocumentClick } from "../../util/helpers";

const Columns = [
  {
    Header: "Document",
    accessor: "documentLink", // accessor is the "key" in the data
    filterCode: "description",
    isVisible: true,
  },
  {
    Header: "Status",
    accessor: "status",
    filterCode: "status",
    isVisible: false,
  },
  {
    Header: "Status",
    accessor: "statusLink",
    filterCode: "statusLink",
  },
  {
    Header: "Status Date",
    accessor: "statusDateLocal",
    filterCode: "statusDate",
  },
];

const SettingsImportHistoryList = ({ importHistory }) => {
  const initialItems = importHistory?.length
    ? importHistory.map((file) => {
        return {
          ...file,
          documentLink: (
            <button
              className="faux-link"
              onClick={() => {
                handleDocumentClick(file?.documentId);
              }}
            >
              <FontAwesomeIcon icon={["fas", "paperclip"]} size="sm" /> {file?.description}
            </button>
          ),
          statusDateLocal: getLocalDate(file?.statusDate),
          statusLink: file?.notificationId ? (
            <Link to={`/notifications/${file.notificationId}`} target="_blank">
              {file.status}
            </Link>
          ) : (
            file?.status
          ),
        };
      })
    : [];

  const FilterListConfig = {
    status: {
      type: "typeahead",
      options: ["Uploaded", "Queued for Import", "Exception", "Complete"],
    },
    description: {},
  };

  return (
    <>
      {initialItems ? (
        <section id="import-history-list">
          <h4>Imported documents</h4>
          <div>{initialItems.length > 0 ? <FilterTableForm tableName="import-documents-list" Columns={Columns} FilterListConfig={FilterListConfig} tableItems={initialItems} /> : "No document imports to show"}</div>
        </section>
      ) : (
        <span className="loader loader-pulse">Loading import history...</span>
      )}
    </>
  );
};

export default SettingsImportHistoryList;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ReportingColumn.scss";

const ReportingColumn = (props) => {
  const id = `entity-column-${props.columnNumber}`;
  const orderDirectionId = `${id}-order-direction`;
  const aggregateId = `${id}-aggregate`;

  return <>
    <li
      className="report-column"
      key={id}
    >
      <div className="report-column-selection">
        <div className="report-column-name">
          <span className="report-column-number">
            {props.columnNumber}
          </span>
          <input
            type="checkbox"
            id={id}
            name={props.reportColumn.fieldName}
            value={props.reportColumn.fieldName}
            checked={props.reportColumn.selected}
            onChange={() => props.onUpdateColumnProperty(
              props.reportColumn.fieldName,
              'selected',
              !props.reportColumn.selected,
              props.reportColumn.coupledFieldNames
            )}
          />
          <label htmlFor={id}>
            {props.reportColumn.friendlyName}
          </label>
        </div>
      </div>
      <div className="report-column-ordering">
        <div className="report-column-aggregation">
          {
            <select
              disabled={props.reportColumn.aggregates.length === 1}
              value={props.reportColumn.aggregate}
              onChange={(event) => props.onUpdateColumnProperty(
                props.reportColumn.fieldName,
                'aggregate',
                event.target.value
              )}
            >
              {
                props.reportColumn.aggregates.map((aggregate, j) => {
                  const key = `${aggregateId}-${j}`;
  
                  return <option
                    key={key}
                    className="report-column-aggregation-name"
                    value={aggregate.name}
                  >
                    {aggregate.label}
                  </option>
                })
              }
            </select>
          }
        </div>
        <div className="report-column-order-direction">
          <select
            id={orderDirectionId}
            value={props.reportColumn.orderDirection}
            onChange={(event) => props.onUpdateColumnProperty(
              props.reportColumn.fieldName,
              'orderDirection',
              event.target.value
            )}
          >
            {
              props.reportColumn.orderDirections.map((orderDirection, j) => {
                const key = `${orderDirectionId}-${j}`;

                return <option
                  key={key}
                  value={orderDirection.name}
                >
                  {orderDirection.label}
                </option>;
              })
            }
          </select>
        </div>
        <div className="report-column-order">
          {
            props.hasOrderUpButton
              ? <button 
                  className={props.hasOrderDownButton ? 'has-separator' : ''}
                  onClick={() => props.onUpdateColumnOrder(props.reportColumn.fieldName, -1)}
                >
                    <FontAwesomeIcon icon={['fas', 'angle-up']} size="sm" />
                </button>
              : <></>
          }
          {
            props.hasOrderDownButton
              ? <button
                  className={props.hasOrderUpButton ? 'has-separator' : ''}
                  onClick={() => props.onUpdateColumnOrder(props.reportColumn.fieldName, 1)}
                >
                  <FontAwesomeIcon icon={['fas', 'angle-down']} size="sm" />
                </button>
              : <></>
          }
        </div>
      </div>
    </li>
  </>;
};

export default ReportingColumn;

import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import { roles, loadUserRole } from "../../util/userHelper";
import { hasActiveCertification } from "../../util/helpers";
import { trainingVideos, trainingSections } from "../../util/trainingVideoCatalog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./help.scss";
import HelpMessage from "./HelpMessage";

const Help = (props) => {
  const { activeOrg } = props;
  const { videoId } = useParams();
  const history = useHistory();
  const currentVideo = trainingVideos?.find((video) => video.videoTitle === videoId);
  const [modalStatus, setModalStatus] = useState(videoId ? true : false);

  // Unauthenticated users
  const columns = 2;
  const colClass = Math.ceil(12 / columns);
  let contextualRole = "None";

  switch (true) {
    case !!loadUserRole():
      // this is a business with an active organization that is an SDVOB
      if (loadUserRole() === "Business" && activeOrg && hasActiveCertification(activeOrg)) {
        contextualRole = "SDVOB";
      } else if (loadUserRole() === "Business" && !activeOrg) {
        // this is a business user with no active organization
        contextualRole = "None";
      } else {
        contextualRole = loadUserRole();
      }
      break;
    default:
      contextualRole = "None";
      break;
  }

  return (
    <div id="help-wrapper">
      <h1>{contextualRole == "None" ? "" : contextualRole.replace("Admin", "").replace("User", "")} Help Center</h1>
      <HelpMessage />

      {trainingSections?.map((section) => {
        const videos = trainingVideos.filter((video) => video.userContexts.includes(contextualRole) && video?.section === section?.id);

        return (
          <>
            {videos?.length ? (
              <section className="help-video-section" id={encodeURI(section.sectionTitle)}>
                <div className="row mb-8 help-video-row">
                  <div className="col-md-10 col-lg-8">
                    <h2>{section?.sectionTitle}</h2>
                    <p>{section?.description}</p>
                  </div>
                </div>
                <div className="row mb-8">
                  {videos.map((video, index) => {
                    const videoId = video.videoId ? video.videoId : encodeURI(video?.videoTitle);
                    return (
                      <div id={`video-wrapper-${videoId}`} class={`video-wrapper col-md-${colClass} mb-4`}>
                        <h4>
                          <div class="video-title">{video?.videoTitle}&nbsp;&nbsp;</div>
                        </h4>
                        {video?.videoId ? (
                          <iframe
                            id={`video-${videoId}`}
                            className="video-thumbnail"
                            width="100%"
                            src={`https://www.youtube.com/embed/${videoId}`}
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                          ></iframe>
                        ) : (
                          <video id={`video-${videoId}`} className="video-thumbnail" poster={video.imageUrl} controls width="100%">
                            <source src={video.videoUrl} type="video/mp4" />
                          </video>
                        )}
                      </div>
                    );
                  })}
                </div>
              </section>
            ) : null}
          </>
        );
      })}

      <Modal
        show={modalStatus}
        onHide={async () => {
          // stop video
          history.push(`/help`);
        }}
        size="lg"
      >
        <Modal.Header className="bg-light" closeButton></Modal.Header>
        <Modal.Body>
          <p>
            <strong>{currentVideo?.videoTitle}</strong>
          </p>

          <video id="current-video" className="video-thumbnail" poster={currentVideo?.imageUrl} controls width="100%">
            <source src={currentVideo?.videoUrl} type="video/mp4" />
          </video>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="faux-link"
            type="button"
            onClick={async () => {
              history.push("/help");
            }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Help;

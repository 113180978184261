import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import BusinessDetailModal from "./BusinessDetailModal";
import { roles, loadUserRole } from "../../util/userHelper";

const BusinessDetailNav = ({ business }) => {
  const location = useLocation();
  const isEditing = location.pathname.includes("edit") ? true : false;
  const [certificationUpdated, setCertificationUpdated] = useState(0);
  const [modalChoice, setModalChoice] = useState();
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = (action) => {
    setShowModal(false);

    if (action === "confirm") {
      window.location.reload();
    }
  };

  return (
    <nav className="actions-bar">
      <div className="row mb-4">
        <div className="col-md-6 col-lg-4">
          <Link
            role="button"
            name={isEditing ? "cancel-edit-business-info" : "edit-business-info"}
            className={`btn ${isEditing ? "btn-primary" : "btn-secondary"} btn-block btn-sm`}
            to={`${isEditing ? `/business/${business?.id}` : `/business/${business.id}/edit`}`}
          >
            {isEditing ? "Cancel Editing" : "Edit Business Info"}
          </Link>
        </div>
        {business?.isActive && business?.isCertifiedSdvob && (
          <div className="col-md-6 col-lg-4">
            <button
              onClick={async (e) => {
                setModalChoice("Deactivate");
                setShowModal(true);
              }}
              className="btn btn-secondary btn-block btn-sm mb-0"
            >
              Deactivate Certification
            </button>
          </div>
        )}
        {!business?.isActive && business?.isCertifiedSdvob && (
          <div className="col-md-6 col-lg-4">
            <button
              onClick={async () => {
                setModalChoice("Reactivate");
                setShowModal(true);
              }}
              className="btn btn-secondary btn-block btn-sm mb-0"
            >
              Reactivate Certification
            </button>
          </div>
        )}
        {business?.isCertifiedSdvob && [roles.DivisionAdmin, roles.DivisionAdmin].includes(loadUserRole()) ? (
          <div className="col-md-6 col-lg-4">
            <button
              onClick={async () => {
                setModalChoice("Revoke");
                setShowModal(true);
              }}
              className="btn btn-secondary btn-block btn-sm mb-0"
            >
              Revoke Certification
            </button>
          </div>
        ) : null}
        <BusinessDetailModal
          businessId={business?.id}
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          certificationUpdated={certificationUpdated}
          setCertificationUpdated={setCertificationUpdated}
          modalChoice={modalChoice}
        />
      </div>
    </nav>
  );
};

export default BusinessDetailNav;

import React, { useState, useEffect } from "react";
import { getLocalDate, outputMoney } from "../../../../util/helpers";
import { withFormikFormComponent } from "../../../Forms/FormikForm";
import { Form, FastField } from "formik";
import InputComponent from "../../../Forms/Input/InputComponent";
import CompliancePaymentsList from "../../Payments/CompliancePaymentsList";
import FinancialProgress from "../../Sections/FinancialProgress";
import DebugModal from "../../../Debug/DebugModal";
import { loadUserRole } from "../../../../util/userHelper";

const ComplianceReportSubmitInternal = (props) => {
  const { formikBag, contract, canSubmit, awardedPlan, procurement, monthPayments, monthPaymentsTotal, setTotalSalesForMonth, totalPayments, year, month, complianceReport, isLoading, setIsLoading } =
    props;
  const { values } = formikBag;
  const { disbursementsToSDVOBs, submittedDisbursementsToSDVOBs, disbursementsToPrime } = contract;
  const { totalSdvobPaymentsForMonth, totalSdvobPaymentsThroughMonth, totalSubmittedSdvobPaymentsThroughMonth, totalPrimePaymentsThroughMonth } = complianceReport;

  // This would identify us as being on a compliance report
  // We will need to modify some of these numbers to account
  // for unofficial/unaccepted payment info in this scenario
  const [disbursementsToPrimeToUse, setDisbursementsToPrimeToUse] = useState(0);
  const [disbursementsToSDVOBsToUse, setDisbursementsToSDVOBsToUse] = useState(0);

  let disbursementsStatusDesc = "Submitted Payments to SDVOBs";
  if (["Created", "Deficient"].includes(complianceReport?.status)) disbursementsStatusDesc = `Previously ${disbursementsStatusDesc}`;
  if (complianceReport?.status == "Accepted") disbursementsStatusDesc = `Accepted Payments to SDVOBs`;
  const disbursementsDesc = complianceReport?.id ? disbursementsStatusDesc : "Accepted Payments to SDVOBs";



  useEffect(() => {

    switch (complianceReport?.status) {
      case "Created":
        setDisbursementsToPrimeToUse(contract.isOGSCentralized ? complianceReport?.totalSalesThroughMonth : totalPrimePaymentsThroughMonth );
        setDisbursementsToSDVOBsToUse(totalSubmittedSdvobPaymentsThroughMonth);
      case "Deficient":
        setDisbursementsToPrimeToUse(contract.isOGSCentralized ? values?.totalSalesForMonth + complianceReport?.totalSalesThroughMonth : totalPrimePaymentsThroughMonth );
        setDisbursementsToSDVOBsToUse(totalSubmittedSdvobPaymentsThroughMonth);
        break;
      case "Submitted":
        setDisbursementsToPrimeToUse(contract.isOGSCentralized ? complianceReport?.totalSalesThroughMonth : totalPrimePaymentsThroughMonth );
        setDisbursementsToSDVOBsToUse(totalSubmittedSdvobPaymentsThroughMonth);
        break;
      // do other stuff
      default:
        // do default stuff
        setDisbursementsToPrimeToUse(contract.isOGSCentralized ? complianceReport.totalSalesThroughMonth : disbursementsToPrime);
        setDisbursementsToSDVOBsToUse(disbursementsToSDVOBs);
    }
    setTotalSalesForMonth(values?.totalSalesForMonth);
  }, [values]);

  return (
    <div>
      <FinancialProgress
        totalPayments={totalPayments}
        values={values}
        awardedPlan={awardedPlan}
        procurement={procurement}
        contract={contract}
        complianceReport={complianceReport}
        disbursementsToPrimeToUse={disbursementsToPrimeToUse}
        disbursementsToSDVOBsToUse={disbursementsToSDVOBsToUse}
        disbursementsDesc={disbursementsDesc}
      />
      <hr />
      <CompliancePaymentsList payments={monthPayments} contract={contract} />
      <hr />
      {canSubmit ? (
        ["Accepted", "Submitted"].includes(complianceReport?.status) ? (
          <p className="font-weight-bold">{loadUserRole() === "Business" ? <>Your report has been {complianceReport?.status}. There is no action to take.</> : <>This report has been submitted</>}</p>
        ) : (
          <Form>
            <h4>Sign and Submit</h4>
            <p>
              Submission of this form constitutes the Contractor's acknowledgement as to the accuracy of the information contained herein. Failure to submit complete and accurate information may
              result in a finding of noncompliance, non-responsibility, suspension and/or termination of the Contract.
            </p>

            <div className="row">
              {contract?.isOGSCentralized && (
                <FastField wrapperClass="col-6 col-md-4" label={`Total sales this month`} type="number" required={false} name={`totalSalesForMonth`} component={InputComponent} />
              )}
            </div>

            <hr />

            <div className="row">
              <FastField
                wrapperClass="col-md-8"
                childClass="col-6 col-md-4"
                label={`I acknowledge that the information I am submitting today, ${getLocalDate(new Date())}, is complete and accurate.`}
                type="checkbox"
                name={`acknowledge`}
                options={[[true, "Yes"]]}
                component={InputComponent}
              />
            </div>

            <div className="text-center">
              {values.acknowledge ? (
                <button type="submit" className="btn btn-secondary">
                  {!isLoading ? "Submit" : <span className="loader loader-pulse mb-4">Loading...</span>}
                </button>
              ) : null}
              {/* {console.log(isLoading)} */}
            </div>
          </Form>
        )
      ) : (
        <h3>This report is not yet able to be submitted</h3>
      )}
    </div>
  );
};
const ComplianceReportSubmit = withFormikFormComponent(ComplianceReportSubmitInternal);
export default ComplianceReportSubmit;

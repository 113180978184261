import React from "react";
import { Field } from "formik";
import { withFormikFormComponent } from "../../Forms/FormikForm";
import FilterTableField from "../../Forms/Input/FilterTableField";
import FilterTableForm from "../../Forms/Input/FilterTableForm";
import { getLocalTime } from "../../../util/helpers";
import ActivityItemForm from "./ActivityItemForm";

function ActivityListInternal(props) {
  //Get the application and business information
  const { activityLogs, application, handleActivityLogSubmit } = props;

  const FilterListConfig = {
    created: {
      isJoin: false,
    },
    activity: {
      isJoin: false,
    },
  };

  const Columns = [
    {
      Header: "Time",
      accessor: "created", // accessor is the "key" in the data
      // disableSortBy: true,
      sortType: "basic",
      filterCode: "created",
    },
    {
      Header: "Item",
      accessor: "activity",
      defaultCanSort: true,
      sortType: "basic",
      filterCode: "activity",
    },
    {
      Header: "Creator",
      accessor: "creatorName",
      defaultCanSort: true,
      sortType: "basic",
      filterCode: "creatorName",
    },
  ];

  /**
   * Add the Z to account for the fact that this timestamp was originally stored in UTC
   */
  const initialItems = activityLogs?.map((item) => {
    const { creator } = item;
    // console.log(item);
    return {
      ...item,
      created: getLocalTime(item.created),
      creatorName: creator?.firstName && creator?.lastName ? `${creator?.firstName} ${creator?.lastName}` : creator?.userId,
    };
  });

  return (
    <>
      <div>
        {initialItems?.length ? (
          <FilterTableForm tableName="activity-list" Columns={Columns} FilterListConfig={FilterListConfig} tableItems={initialItems} />
        ) : (
          <div className="p-5 bg-light mt-4">
            <h2>No Activity Items to display, currently.</h2>
          </div>
        )}
      </div>
    </>
  );
}
const ActivityList = withFormikFormComponent(ActivityListInternal);
export default ActivityList;

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Field, Form } from "formik";
import { withFormikFormComponent } from "../../Forms/FormikForm";
import InputComponent from "../../Forms/Input/InputComponent";
import { Button, Row, Col, Container } from "react-bootstrap";
import { assignAgencyUser, removeAgencyUser } from "../../../util/api";

function AgencyUserFormInternal({ user, agencies }) {
  const history = useHistory();

  const initialAgencyIds = user.agencyIds;
  const [updatedAgencies, setUpdatedAgencies] = useState(initialAgencyIds);

  const handleSubmit = () => {
    const agenciesToAssign = updatedAgencies.filter((id) => !initialAgencyIds.includes(id));
    const agenciesToRemove = initialAgencyIds.filter((id) => !updatedAgencies.includes(id));

    if (agenciesToAssign.length > 0) {
      agenciesToAssign.forEach((agencyId) => assignAgencyUser(agencyId, user.id));
    }
    if (agenciesToRemove.length > 0) {
      agenciesToRemove.forEach((agencyId) => removeAgencyUser(agencyId, user.id));
    }

    setTimeout(() => {
      history.push(`/user-list/agency`);
    }, 1000);
  };

  return (
    <Container className="bg-light p-4 my-4">
      <h3>
        Edit User {user?.lastName}
        {", "}
        {user?.firstName}
      </h3>
      <hr />
      <Row>
        <Col xs={9}>
          <Form>
            <Field
              type="typeahead"
              filterBy={["id"]}
              id="agency-user-form"
              name={`agencyIds`}
              component={InputComponent}
              label="Agencies"
              options={agencies?.map((agency) => ({ ...agency, label: agency.name }))}
              multiple={true}
              onChange={(newAgenciesList) => setUpdatedAgencies(newAgenciesList.map((agency) => agency.id))}
            />
          </Form>
        </Col>
        <Col xs={3}>
          <Button className="btn-sm mt-4 px-3" onClick={() => handleSubmit()}>
            <span className="d-block">Submit</span>
            <span className="d-block">Changes</span>
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

const AgencyUserForm = withFormikFormComponent(AgencyUserFormInternal);
export default AgencyUserForm;

import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import FilterTableForm from "../../Forms/Input/FilterTableForm";
import { getActivityReportDetail } from "../../../util/api";
import { outputMoney } from "../../../util/helpers";
import { Typeahead } from "react-bootstrap-typeahead";
import { exclusionTypes, contractCategories, businessTypes } from "../../../util/typelistHelper";

const FilterListConfig = {
  contractorName: {},
  contractInformation: {},
  exclusionType: {
    type: "typeahead",
    options: exclusionTypes.map((exclusionType) => {
      return { label: exclusionType[1], id: exclusionType[1] };
    }),
  },
  isTerm: {
    type: "typeahead",
    options: ["Yes", "No"],
  },
  planType: {
    type: "typeahead",
    options: ["Plan", "Partial Waiver", "Waiver"],
  },
  contractServiceType: {
    type: "typeahead",
    options: businessTypes.map((businessType) => {
      return { label: businessType[1], id: businessType[1] };
    }),
  },
  contractCategory: {
    type: "typeahead",
    options: contractCategories.map((contractCategory) => {
      return { label: contractCategory[1], id: contractCategory[1] };
    }),
  },
};

const Columns = [
  {
    Header: "Contractor",
    accessor: "contractorName", // accessor is the "key" in the data
    filterCode: "contractorName",
  },
  {
    Header: "Contract",
    accessor: "contractInformation", // accessor is the "key" in the data
    filterCode: "contractInformation",
  },
  {
    Header: "Contract Amount",
    accessor: "contractAmount", // accessor is the "key" in the data
    filterCode: "contractAmount",
  },
  {
    Header: "SDVOB Participation",
    accessor: "exclusionType", // accessor is the "key" in the data
    filterCode: "exclusionType",
  },
  {
    Header: "Participation",
    accessor: "sdvobParticipationAmount", // accessor is the "key" in the data
    filterCode: "sdvobParticipationAmount",
  },
  {
    Header: "Payment",
    accessor: "paymentsToSdvob", // accessor is the "key" in the data
    filterCode: "paymentsToSdvob",
  },
  {
    Header: "Term",
    accessor: "isTerm", // accessor is the "key" in the data
    filterCode: "isTerm",
  },
  {
    Header: "Award",
    accessor: "isAward", // accessor is the "key" in the data
    filterCode: "isAward",
  },
  {
    Header: "Plan Type",
    accessor: "planType", // accessor is the "key" in the data
    filterCode: "planType",
  },
  {
    Header: "Classification",
    accessor: "contractServiceType", // accessor is the "key" in the data
    filterCode: "contractServiceType",
  },
  {
    Header: "Category",
    accessor: "contractCategory", // accessor is the "key" in the data
    filterCode: "contractCategory",
  },
  {
    Header: "Nature of Participation",
    accessor: "natureOfParticipation", // accessor is the "key" in the data
    filterCode: "natureOfParticipation",
  },
];

const ActivityReportDetails = (props) => {
  const { reportId, updateCount } = props;
  const [reportDetails, setReportDetails] = useState(null);

  useEffect(() => {
    const fetchData = async (id) => {
      const resObj = await getActivityReportDetail(id);
      setReportDetails(resObj.data.activityReportDetails);
    };
    if (reportId) {
      fetchData(reportId);
    }
  }, [updateCount]);

  const initialItems = reportDetails?.length
    ? reportDetails.map((reportDetail) => {
        return {
          ...reportDetail,
          contractorName: reportDetail.contractor.name,
          contractAmount: outputMoney(reportDetail.contractAmount),
          sdvobParticipationAmount: outputMoney(reportDetail.sdvobParticipationAmount),
          paymentsToSdvob: outputMoney(reportDetail.paymentsToSdvob),
          isTerm: reportDetail.contractTerm === "Term" ? "Yes" : "No",
          isAward: reportDetail.isAward ? "Yes" : "No",
          planType: _.startCase(reportDetail.planType),
          exclusionType: exclusionTypes.find((exclusionType) => exclusionType[0] === reportDetail.exclusionType)[1],
          contractServiceType: _.startCase(reportDetail.contractServiceType),
        };
      })
    : [];

  return (
    <>
      <h2>Activity Report Details</h2>

      {initialItems ? (
        <section id="activity-report-details-list">
          <div>
            {initialItems.length > 0 ? (
              <FilterTableForm tableName="activity-report-details-list" Columns={Columns} FilterListConfig={FilterListConfig} tableItems={initialItems}></FilterTableForm>
            ) : (
              "No details to show"
            )}
          </div>
          <p>
            <small>Waiver and Total Contract Value records prior to 1/1/2023 are not available in SDVES.</small>
          </p>
        </section>
      ) : (
        <span className="loader loader-pulse">Loading activity report details...</span>
      )}
    </>
  );
};

export default ActivityReportDetails;

export default [
  {
    id: "Capital Region",
    label: "Capital Region"
  },
  {
    id: "Central New York",
    label: "Central New York"
  },
  {
    id: "Finger Lakes",
    label: "Finger Lakes"
  },
  {
    id: "Long Island",
    label: "Long Island"
  },
  {
    id: "Mid-Hudson",
    label: "Mid-Hudson"
  },
  {
    id: "Mohawk Valley",
    label: "Mohawk Valley"
  },
  {
    id: "New York City",
    label: "New York City"
  },
  {
    id: "North Country",
    label: "North Country"
  },
  {
    id: "Southern Tier",
    label: "Southern Tier"
  },
  {
    id: "Western New York",
    label: "Western New York"
  },
]

import React from "react";
import { useParams } from "react-router-dom";
import { createTabNavLinks } from "../../../util/helpers";

const ApplicationNav = () => {
  let { applicationId } = useParams();

  const items = [
    [`/application/${applicationId}/review`, "Review"],
    [`/application/${applicationId}/activity-log`, "Activity Log"],
    // [`/application/${applicationId}/change-requests`, "Change Requests"],
  ];

  return (
    <div className="application-navigation mb-5">
      <nav id={`application-nav`} className="nav nav-tabs">
        {createTabNavLinks(items)}
      </nav>
    </div>
  );
};

export default ApplicationNav;

import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Field } from "formik";
import _ from "lodash";
import { withFormikFormComponent } from "../../Forms/FormikForm";
import { getCertifiedBusinesses, handleBusinessTimerStatus, getBusinessTimers } from "../../../util/api";
import { daysLeftColorize, outputTimer, tableSortByValue } from "../../../util/helpers";
import { roles, loadUserRole } from "../../../util/userHelper";
import FilterTableForm from "../../Forms/Input/FilterTableForm";

function MaintenanceTimerListInternal(props) {
  const { formikBag } = props;
  const { setFieldValue, values } = formikBag;
  //Get the application and business information
  const [businesses, setBusinesses] = useState([]);
  const [updatedCount, setUpdatedCount] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    // console.log('doing it again');
    const myBusinesses = await getBusinessTimers();
    setBusinesses(myBusinesses?.data);
  }

  const sortByName = (rowA, rowB, name, other) => {
    return tableSortByValue(rowA, rowB, "legalName");
  };

  const sortByDaysLeft = (rowA, rowB, name, other) => {
    return tableSortByValue(rowA, rowB, "daysLeft");
  };

  const FilterListConfig = {
    legalName: {},
    daysLeft: {},
  };

  let Columns = [
    {
      Header: "Business",
      accessor: "legalNameLink", // accessor is the 'key' in the data
      sortType: sortByName,
    },
    {
      Header: "Business",
      accessor: "legalName", // accessor is the 'key' in the data
      filterCode: "legalName",
      isVisible: false,
    },
    {
      // Header: 'Days Left',
      Header: "Days Until Expiration",
      accessor: "businessDaysLeft", // accessor is the 'key' in the data
      // disableSortBy: true,
      sortType: sortByDaysLeft,
      filterCode: "businessDaysLeft",
    },
    {
      // Header: 'Days Left',
      Header: "Days Left",
      accessor: "daysLeft", // accessor is the 'key' in the data
      // disableSortBy: true,
      // sortType: sortByDaysLeft,
      filterCode: "daysLeft",
      isVisible: false,
    },
  ];

  if (loadUserRole() === roles.DivisionAdmin) {
    Columns.push({
      Header: "Timer Running",
      accessor: "timerRunning", // accessor is the 'key' in the data
      // disableSortBy: true,
      sortType: "basic",
      filterCode: "timerRunning",
    });
  }

  const initialItems = businesses?.map((item, index) => {
    const annualTimer = item.timers.find((timer) => timer?.type === "AnnualCheckup");
    const recertTimer = item.timers.find((timer) => timer?.type === "Recertification");
    const fiveYearTimer = item.timers.find((timer) => timer?.type === "FiveYearCertification");
    // const relTimer = annualTimer ? annualTimer : recertTimer ? recertTimer : fiveYearTimer ? fiveYearTimer : {};
    const relTimer = fiveYearTimer ? fiveYearTimer : {};
    const daysLeft = relTimer ? relTimer?.daysLeft : "N/A";
    const daysLeftColorized = daysLeftColorize(relTimer);
    const stopped = _.get(values, `timer-${item.id}`) === undefined ? relTimer.isStopped : _.get(values, `timer-${item.id}`);

    return {
      ...item,
      legalNameLink: (
        <div>
          <Link key={item.legalName} id={item.legalName} to={`/business/${item.businessId}`}>
            {item.legalName}
          </Link>
        </div>
      ),
      // legalNameLink: item?.legalName,
      // endDate: new Date(relTimer.endDate).toDateString(),
      businessDaysLeft: daysLeftColorized,
      daysLeft: daysLeft,
      type: outputTimer(relTimer?.type),
      timerRunning: (
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            className="custom-control-input"
            checked={!stopped}
            id={`timer-${relTimer?.id}`}
            name={`timer-${relTimer?.id}`}
            onChange={async (e) => {
              if (stopped) {
                const resObj = await handleBusinessTimerStatus(relTimer?.id, "start");
                // if (resObj.status === 200) {
                //   console.log('it was a 200', updatedCount);
                //   setUpdatedCount(updatedCount + 1);
                //   // setFieldValue(`timer-${item?.id}`, !stopped)
                //   fetchData();
                //   console.log('do the is stopped stuff', updatedCount)
                // }
                await fetchData();
              } else {
                console.log(relTimer);
                const resObj = await handleBusinessTimerStatus(relTimer?.id, "stop");
                // if (resObj.status === 200) {
                //   console.log('it was a 200', updatedCount);
                //   // setUpdatedCount(updatedCount + 1);
                //   // setFieldValue(`timer-${item?.id}`, !stopped)
                //   fetchData();
                //   console.log('do the is started stuff', updatedCount);
                // }
                await fetchData();
              }
            }}
          />
          <label className="custom-control-label" htmlFor={`timer-${relTimer?.id}`}>
            <span className="sr-only">Toggle Timer</span>
          </label>
        </div>
      ),
    };
  });

  return (
    <>
      <h2>Certified Business List</h2>
      <p>Review statuses of timers associated with certified SDVOBs</p>

      <div>
        {initialItems?.length ? (
          <FilterTableForm tableName="maintenance-timer-list" Columns={Columns} FilterListConfig={FilterListConfig} tableItems={initialItems} />
        ) : (
          <div className="p-5 bg-light mt-4">
            <h2 className="loader loader-pulse">Loading Certified Businesses...</h2>
          </div>
        )}
      </div>
    </>
  );
}
const MaintenanceTimerList = withFormikFormComponent(MaintenanceTimerListInternal);
export default MaintenanceTimerList;

import React from "react";
import { createNavLinks } from "../../util/helpers";
import Navbar from "react-bootstrap/Navbar";

const DivisionNav = () => {
  const items = [
    ["/", "Dashboard"],
    ["/applications", "Applications Queue"],
    ["/maintenance-timers", "Certified Business List"],
    ["/agency-reporting/activity-report", "Agency Submissions"],
    ["/correspondence", "Correspondence"],
    ["/reporting", "Reporting"],
    [`/business-search`, "SDVOB Directory"],
  ];

  return (
    <Navbar collapseOnSelect expand="md" className="p-0 d-block">
      <div className="nav-header bg-black p-4 d-flex justify-content-between align-items-center">
        Navigation <Navbar.Toggle aria-controls="sidebar-nav" />
      </div>
      <Navbar.Collapse id="sidebar-nav">{createNavLinks(items)}</Navbar.Collapse>
    </Navbar>
  );
};

export default DivisionNav;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAgencyDashboardData } from "../../util/api";

import { getLocalTime, getLocalDate } from "../../util/helpers";
import { loadActiveOrgId } from "../../util/userHelper";

const AgencyAdminPortal = (props) => {
  const { notifications, activeOrgId } = props;
  const procurements = [];
  const [dashboardData, setDashboardData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const dashboardRes = await getAgencyDashboardData();
      setDashboardData(dashboardRes);
    };
    if (activeOrgId) {
      fetchData();
    }
  }, [activeOrgId]);

  return (
    <section className="portal-wrapper">
      {loadActiveOrgId() ? (
        <>
          <div className="card-deck mb-4">
            <div className="card w-100">
              <div className="card-header bg-white">
                <h3>
                  <Link to="/notifications">
                    View All Notifications <FontAwesomeIcon className="ml-2" icon={["fas", "angle-right"]} />
                  </Link>
                </h3>
              </div>

              <div className="card-body bg-light">
                <h3>
                  <strong>Recent Notifications</strong>
                </h3>

                <div className="faux-table-list">
                  {notifications && notifications?.length
                    ? notifications.slice(0, 5).map((notification, index) => {
                        const { body, timeStamp, read } = notification;

                        return (
                          <div className="row item" key={`notification-${index}`}>
                            <div className="col-md-8">
                              <Link to={`/notifications/${notification.id}`}>{body}</Link>
                            </div>
                            <div className="col-md-2">{new Date(timeStamp).toLocaleString()}</div>
                            <div className="col-md-2">{read ? `Read` : <span className="text-success font-weight-bold">Unread</span>}</div>
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
            </div>
          </div>

          <div className="card-deck mb-4">
            <div className="card w-100">
              <div className="card-header bg-white">
                <h3>
                  <Link to="/procurements">
                    View Procurements <FontAwesomeIcon className="ml-2" icon={["fas", "angle-right"]} />
                  </Link>
                </h3>
              </div>
              <div className="card-body">
                <h2>
                  Active procurements with goals assigned: <strong>{dashboardData?.activeProcurementsWithGoalsAssigned}</strong>
                </h2>
              </div>
              <div className="card-body">
                <h2>
                  Eligible procurements without an assigned contract: <strong>{dashboardData?.procurementsThatNeedAContract?.total}</strong>
                </h2>
              </div>
              <div className="card-body">
                <h2>
                  Procurements without plans: <strong>{dashboardData?.procurementsWaitingForPlans?.total}</strong>
                </h2>
              </div>
              <div className="card-body">
                <h2>
                  Procurements with submitted plans in need of review: <strong>{dashboardData?.procurementsWithSubmittedPlansInNeedOfReview?.total}</strong>
                </h2>
              </div>
            </div>

            <div className="card w-100">
              <div className="card-header bg-white">
                <h3>
                  <Link to="/agency-reporting/activity-report">
                    Agency Submission Deadlines <FontAwesomeIcon className="ml-2" icon={["fas", "angle-right"]} />
                  </Link>
                </h3>
              </div>
              <div className="card-body">
                {dashboardData?.agencyReportsDue?.map((reportType, i) => {
                  return (
                    <div className="row" key={`report-${i}`}>
                      <div className="col-5">
                        <Link to={`/agency-reporting/${reportType.reportPath}/${reportType.id ?? ""}`}>{reportType?.name}</Link>
                      </div>
                      <div className="col-1">
                        {reportType.overdue && (
                          <span>
                            <FontAwesomeIcon className="ml-2" icon={["fas", "exclamation-circle"]} />
                          </span>
                        )}
                      </div>
                      <div className="col-6">Due {getLocalDate(reportType?.dueDate)}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <h2>No Agencies currently assigned to your user</h2>
          <p>Please contact the Division to be assigned your agency</p>
        </>
      )}
    </section>
  );
};

export default AgencyAdminPortal;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { FieldArray } from "formik";
import Button from "react-bootstrap/Button";

const ArrayWrapperDynamic = (props) => {
  const { name, component, formikBag, appBag, additionLabel, removalLabel, atLeastOne, initialValues, documentType, btnSize } = props;
  const { values } = formikBag;
  let arrayValues = _.get(values, name);
  const Component = component;

  // useEffect(() => {
  //   if (atLeastOne && arrayValues?.length < 1) {
  //     arrayValues.push(baseArrayEntity ? baseArrayEntity : {});
  //     formikBag.setFieldValue(name, arrayValues);
  //   }
  // })

  return (
    <>
      <FieldArray
        name={name}
        render={(arrayHelpers) => {
          return (
            <div>
              {arrayValues?.map((value, index) => {
                const RemoveButton = <Button className="btn-small faux-link text-primary" onClick={() => arrayHelpers.remove(index)}><FontAwesomeIcon className="" icon={["fas", 'minus']} size="xs" /> {removalLabel}</Button>;
                return (
                  <div key={index} className="">
                    <Component
                      name={`${name}.${index}`}
                      index={index}
                      appBag={appBag}
                      formikBag={formikBag}
                      documentType={documentType}
                      fileOnly={true}
                    />
                    {arrayValues.length > 1 || (arrayValues.length <= 1 && !atLeastOne) ? RemoveButton : null}
                    <hr />
                  </div>
                )
              })}
              {<Button variant="" className={`btn-${btnSize} text-secondary p-0`} onClick={() => arrayHelpers.push(initialValues)}>
                <FontAwesomeIcon className="" icon={["fas", 'plus']} size="xs" /> {additionLabel}
              </Button>}
            </div>
          );
        }}
      />
    </>
  );
};

//export const AddressComponent = withFormikComponent(AddressForm, nameSchema);
export default ArrayWrapperDynamic;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import { getProcurementsByPrime, getProcurementsBySub, getAllContracts } from "../../util/api";
import { loadUserRole, roles } from "../../util/userHelper";
import ContractListViewAgency from "./Views/ContractListViewAgency";
import BusinessProcurementsList from "./Views/BusinessProcurementsList";
import SubPayments from "./Payments/SubPayments";

const BusinessProcurementListOverview = (props) => {
  const { activeOrgId, activeOrg } = props;
  // console.log({ activeOrg, activeOrgId });
  const { isCertifiedSdvob } = activeOrg;
  const [procurements, setProcurements] = useState([]);
  const [subProcurements, setSubProcurements] = useState([]);
  const [isLoadingPrime, setIsLoadingPrime] = useState(false);
  const [isLoadingSub, setIsLoadingSub] = useState(false);

  // Get all the contracts assigned to business
  useEffect(() => {
    async function fetchPrimeData() {
      setIsLoadingPrime(true);
      setIsLoadingSub(true);
      if (loadUserRole() === roles.Business) {
        // Get procurements I am a prime on
        const myProcurements = await getProcurementsByPrime();
        setProcurements(myProcurements.procurements);
        setIsLoadingPrime(false);
        setIsLoadingSub(false);
      } else if (loadUserRole() === roles.AgencyAdmin) {
        const myProcurements = await getAllContracts();
        setProcurements(myProcurements.procurements);
        setIsLoadingPrime(false);
      }
    }

    async function fetchSubData() {
      // Get procurements I am a subcontractor on
      const mySubProcurements = await getProcurementsBySub();
      setSubProcurements(mySubProcurements.procurements);
      setIsLoadingSub(false);
    }
    fetchPrimeData();

    if (isCertifiedSdvob) {
      fetchSubData();
    }
  }, [activeOrgId]);

  function formatProcurements(procurements, isSub) {
    if (loadUserRole() === roles.Business) {
      return procurements?.length
        ? procurements?.map((item) => {
            const contractCode = item.contractCode ? item.contractCode : "Undefined Code";
            // const role = item?.primeContractor?.id === activeOrgId ? "Prime" : "Sub";
            const role = "Prime";
            return {
              ...item,
              descriptionLink: <Link to={`/procurements/${item.id}/${isSub ? `payments` : ``}`}>{item.description}</Link>,
              description: item.description,
              role: role,
              agencyName: item.agency.name,
              subcontractorLink: <Link to={`/procurements/${item.id}`}>{item.description}</Link>,
              utilizationPlanLink: item?.awardedPlanId ? (
                <Link to={`/procurements/${item.id}/plans-waivers`}>See Plan / Waiver</Link>
              ) : (
                <Link to={`/procurements/${item.id}/plans-waivers/submit`}>Submit Plan</Link>
              ),
              activePeriod: `${new Date(item.startDate).toDateString()} - ${new Date(item.endDate).toDateString()}`,
            };
          })
        : [];
    } else if (loadUserRole() === roles.AgencyAdmin) {
      return procurements.length
        ? procurements?.map((item) => {
            return {
              ...item,
              descriptionLink: <Link to={`/procurements/${item.id}`}>{item.description}</Link>,
              description: item.description,
              subcontractorLink: <Link to={`/procurements/${item.id}`}>{item.description}</Link>,
              utilizationPlanLink: <Link to={`/procurements/${item.id}/plans-waivers`}>See Plan / Waiver</Link>,
              activePeriod: `${new Date(item.startDate).toDateString()} - ${new Date(item.endDate).toDateString()}`,
            };
          })
        : [];
    }
  }

  return (
    <section id="procurements-list">
      <div>
        <h2>NYS Awards</h2>
        {loadUserRole() === roles.Business && (
          <>
            {isCertifiedSdvob ? (
              <Tabs defaultActiveKey="prime" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="prime" title="Prime Contractor">
                  <BusinessProcurementsList prime={true} initialItems={formatProcurements(procurements, false)} />
                </Tab>
                <Tab eventKey="sub" title="Sub Contractor">
                  {/* <BusinessProcurementsList initialItems={formatProcurements(subProcurements, true)} /> */}
                  <SubPayments />
                </Tab>
              </Tabs>
            ) : (
              <BusinessProcurementsList isLoadingPrime={isLoadingPrime} prime={true} initialItems={formatProcurements(procurements, false)} />
            )}
          </>
        )}
        {loadUserRole() === roles.AgencyAdmin && <ContractListViewAgency prime={true} initialItems={formatProcurements(procurements, true)} />}
      </div>
    </section>
  );
};

export default BusinessProcurementListOverview;

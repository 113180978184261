import React, { useEffect, useState, useRef } from "react";
import { orderDirections, aggregates, reportFieldTemplateTypes } from "../../util/typelistHelper";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './ReportingResultsModal.scss';

function sanitizeAddressResult(result, key) {
  return (result || '').split('\n').map((part) => <><span key={key}>{part}</span><br></br></>);
}

function sanitizeResult(result, key) {
  // Yes the \\n is correct, the second \ is added by the server when serializing
  return (result || '').split('\\n').map((part) => <><span key={key}>{part}</span><br></br></>);
}

function createDocumentTemplateType(rowValue, key) {
  if (
    typeof rowValue === 'object'
    && rowValue
    && typeof rowValue.type === 'string'
    && typeof rowValue.url === 'string'
  ) {
    const description = sanitizeResult(rowValue.description);

    return <span key={key}>
      <span>
        {rowValue.type}
      </span>
      <br>
      </br>
      <a
        target="_blank"
        href={rowValue.url}
      >
        {description.length > 0 ? description : rowValue.url}
      </a>
    </span>;
  }

  return rowValue;
}

const rowFormatters = {
  [reportFieldTemplateTypes.none]: (rowValue, key) => {
    if (Array.isArray(rowValue)) {
      return rowValue.map((rv, i) => sanitizeResult(rv, `${key}-${i}`));
    }

    return sanitizeResult(rowValue);
  },
  [reportFieldTemplateTypes.url]: (rowValue, key) => {
    if (Array.isArray(rowValue)) {
      return rowValue.map((rv, i) => <a key={key + '-' + i} target="_blank" href={rv}>{rv}</a>);
    }

    return <a key={key + ' ' + 0} target="_blank" href={rowValue}>{rowValue}</a>
  },
  [reportFieldTemplateTypes.document]: (rowValue, key) => {
    if (Array.isArray(rowValue)) {
      return rowValue.map((rv, i) => <>{createDocumentTemplateType(rv, `${key}-${i}`)}<br></br></>);
    }

    return createDocumentTemplateType(rowValue);
  },
  [reportFieldTemplateTypes.multiLine]: (rowValue, key) => {
    if (Array.isArray(rowValue)) {
      return rowValue.map((rv, i) => <>{sanitizeResult(rv, `${key}-${i}`)}<br></br></>);
    }

    return sanitizeResult(rowValue, `${key}-0`);
  },
  [reportFieldTemplateTypes.address]: (rowValue, key) => {
    if (Array.isArray(rowValue)) {
      return rowValue.map((rv, i) => <>{sanitizeAddressResult(rv, `${key}-${i}`)}<br></br></>);
    }

    return sanitizeAddressResult(rowValue, `${key}-0`);
  }
};

function formatRowValue(reportColumn, rowValue, columnKey) {
  const formatter = rowFormatters[reportColumn.reportFieldTemplateType] ?? rowFormatters[reportFieldTemplateTypes.none];
  const formattedValue = formatter(rowValue, columnKey);

  return <div
    className="report-result-value"
    key={columnKey}
  >
    {formattedValue}
  </div>;
}

function mapReportColumns(reportColumn, reportColumnDefinitions) {
  const reportColumnAggregate = reportColumn.aggregate || aggregates.none;
  const reportColumnOrderDirection = reportColumn.orderDirection || orderDirections.none;
  
  const friendlyName = reportColumnAggregate !== aggregates.none
    ? `${reportColumn.aggregate} of ${reportColumn.friendlyName}`
    : reportColumn.friendlyName

  const fieldName = reportColumnAggregate !== aggregates.none
    ? `${reportColumn.aggregate}Of${reportColumn.fieldName}`
    : reportColumn.fieldName;

  const reportFieldTemplateType = reportColumnDefinitions.find((reportColumnDefinition) => reportColumnDefinition.fieldName === reportColumn.fieldName)?.reportFieldTemplateType ?? reportFieldTemplateTypes.none;

  return {
    friendlyName,
    aggregate: reportColumnAggregate,
    orderDirection: reportColumnOrderDirection,
    fieldName,
    reportFieldTemplateType
  };
}

const ReportingResultsModal = (props) => {
  const {
    show,
    reportData,
    onHide,
    page,
    totalPages,
    pageSize,
    reportName,
    onPageChange,
    isReportRunning,
    onDownloadReportCsv,
    isDownloadingReportCsv,
    reportColumnDefinitions
  } = props;

  const [reportColumns, setReportColumns] = useState([]);
  const columnHeadersRef = useRef(null);
  const resultsRef = useRef(null);
  const [rowStyle, setRowStyle] = useState({});
  
  useEffect(() => {
    const updatedReportColumns = reportData.reportColumns
      .filter((reportColumn) => reportColumn.selected)
      .map((reportColumn) => mapReportColumns(reportColumn, reportColumnDefinitions));

    setReportColumns(updatedReportColumns);

    setRowStyle({
      minWidth: `${updatedReportColumns.length * 8}rem`
    })
  }, [props.reportData]);

  useEffect(() => {
    if (!isReportRunning && columnHeadersRef.current) {
      columnHeadersRef.current.scrollLeft = 0;
    }
  }, [props.isReportRunning]);

  function toPage(newPage) {
    if (
      newPage >= 0
      && newPage <= totalPages
      && newPage != page
      && typeof onPageChange === 'function'
    ) {
      onPageChange(newPage);
    }
  }

  function adjustPage(direction) {
    toPage(page + direction);
  }

  function handleResultsScroll() {
    columnHeadersRef.current.scrollLeft = resultsRef.current.scrollLeft;
  }

  return (
    <Modal 
      show={show}
      onHide={onHide}
      className="reporting-results-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="reporting-results-modal-title">
            <span>
              '{reportName}' Report
            </span>
            <span 
              hidden={!reportData.total}
              className="reporting-results-modal-title-number-found"
            >
              ({reportData.total} Results Found)
            </span>
          </div> 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          isReportRunning
            ? <div className="reporting-results-loading">
                <div>
                  Running Report ...
                </div>
                <FontAwesomeIcon
                  icon={"spinner"}
                  className="fa-spin"
                />
              </div>
            : reportData.total > 0
              ? <div 
                  className="report-results-container"
                  onScroll={handleResultsScroll}
                >
                  <div 
                    ref={columnHeadersRef}
                    className="report-results-columns"
                  >
                    <div 
                      className="report-resolts-column-number"
                    >
                      #
                    </div>
                    {
                      reportColumns.map((reportColumn, index) => {
                        const columnKey = `report-results-column-${index}`;
                        const hasNoDirection = reportColumn.orderDirection === orderDirections.none;
        
                        const direction = hasNoDirection
                          ? <></>
                          : <FontAwesomeIcon icon={['fas', reportColumn.orderDirection === orderDirections.ascending ? 'angle-up' : 'angle-down']} size="sm" />;
        
                        return <div
                          key={columnKey}
                          className="report-results-column"
                        >
                          <span>
                            {reportColumn.friendlyName}
                          </span>
                          <span 
                            hidden={hasNoDirection}
                            className="report-results-column-icon"
                          >
                            {direction}
                          </span>
                        </div>;
                      })
                    }
                  </div>
                  <div 
                    ref={resultsRef}
                    className="report-results"
                  >
                    {
                      reportData.results.map((result, i) => {
                        const rowKey = `report-result-row-${i}`;
        
                        const resultColumns = reportColumns.map((reportColumn, j) => {
                          const columnKey = `report-result-${i}-${j}`;
                          const rowValue = result[reportColumn.fieldName];
                          
                          return formatRowValue(reportColumn, rowValue, columnKey);
                        });
        
                        return <div
                          className="report-result-row"
                          key={rowKey}
                          style={rowStyle}
                        >
                          <div className="report-result-row-number">
                            {(page * pageSize) + (i + 1)}
                          </div>
                          {resultColumns}
                        </div>;
                      })
                    }
                  </div>
                </div>
              : <div className="reporting-no-results">No results found</div>
        }
      </Modal.Body>
      <Modal.Footer hidden={!reportData.total}>
        <div className="reporting-results-modal-footer-spacer"></div>
        <div className="report-pagination">
          <button
            className="report-pagination-button"
            disabled={page === 0}
            onClick={() => toPage(0)}
          >
            <FontAwesomeIcon icon={['fas', 'angle-double-left']} size="sm" />
          </button>
          <button
            className="report-pagination-button"
            disabled={page === 0}
            onClick={() => adjustPage(-1)}
          >
            <FontAwesomeIcon icon={['fas', 'angle-left']} size="sm" />
          </button>
          <div className="report-pagination-position">
            {page + 1} / {totalPages + 1}
          </div>
          <button
            className="report-pagination-button"
            disabled={page === totalPages}
            onClick={() => adjustPage(1)}
          >
            <FontAwesomeIcon icon={['fas', 'angle-right']} size="sm" />
          </button>
          <button
            className="report-pagination-button"
            disabled={page === totalPages}
            onClick={() => toPage(totalPages)}
          >
            <FontAwesomeIcon icon={['fas', 'angle-double-right']} size="sm" />
          </button>
        </div>
        <div className="reporting-results-footer-download-button-container">
          <button
            type="button"
            disabled={isDownloadingReportCsv}
            className="btn btn-primary"
            onClick={() => onDownloadReportCsv()}
          >
            {isDownloadingReportCsv ? 'Downloading CSV ...' : 'Download CSV'}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ReportingResultsModal;

import React from "react";
import { DocumentsSection } from "../../Forms/DocumentHelper";
import AuthorBox from "./AuthorBox";
import PartiesList from "./PartiesList";

const MainCorrespondence = ({ correspondenceId, correspondence }) => {
  return (
    <div>
      {/* <h2>Corresepondence <span className="font-weight-normal">for {procurement?.description}</span></h2> */}
      {correspondence?.id && (
        <div>
          <hr />
          <PartiesList correspondence={correspondence} />
          <div className="bg-light py-4 px-5 mb-4">
            <AuthorBox userId={correspondence.userId} created={correspondence.created} creatorName={correspondence.creatorName} creatorRole={correspondence.creatorRole} />
            <p className="text-dark">{correspondence.subject}</p>
            <p>{correspondence?.message}</p>
            <DocumentsSection documents={correspondence?.attachments}></DocumentsSection>
          </div>
        </div>
      )}
    </div>
  );
};
export default MainCorrespondence;

import { FastField, Form } from "formik";
import React from "react";
import * as Yup from "yup";
import { withFormikFormComponent } from "../Forms/FormikForm";
import InputComponent from "../Forms/Input/InputComponent";

const schema = { reason: Yup.string().required(), }

const CertificationStatusFormInternal = (props) => {
  const { business, setShowModal, formikBag, onSubmit } = props;
  const { values } = formikBag;

  return (
    <Form>
      <p>Are you sure you would like to change your certification status? This changes your presence on the public directory.</p>
      <FastField component={InputComponent} type="textarea" label="Reason" name="reason" required={false}></FastField>
      <div className="row">
        <div className="col-md-6">
          <button onClick={async () => {
            const resObj = await onSubmit(values.reason, !business.isActive);
          }} className="btn btn-secondary btn-block btn-sm mb-0">Yes. {business.isActive ? 'Deactivate.' : 'Reactivate.'}</button>
        </div>
        <div className="col-md-6">
          <button className="btn btn-primary btn-block btn-sm mb-0" onClick={() => { setShowModal(false) }}>Cancel</button>
        </div>
      </div>
    </Form>
  )
}

const CertificationStatusForm = withFormikFormComponent(CertificationStatusFormInternal, schema);
export default CertificationStatusForm;

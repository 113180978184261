import React, { useEffect, useState } from "react";
import { Form, FastField } from "formik";
import { withFormikFormComponent } from "../../Forms/FormikForm";
import ReactiveButton from "../../Forms/Input/ReactiveButton";
import InputComponent from "../../Forms/Input/InputComponent";
import { schemas } from "../../../util/errorHelper";
import DebugModal from "../../Debug/DebugModal";
import { getAgencies } from "../../../util/api";

function SystemPreferencesInternal(props) {
  const { formikBag, successMessage, setSuccessMessage } = props;
  const { setFieldValue, values, isSubmitting, isValid } = formikBag;

  return (
    <>
      <Form>
        <div className="row mb-2">
          <FastField wrapperClass="col-md-6" type="text" name={`ogsvmuContactEmail`} component={InputComponent} label="VMU Contact Email"></FastField>
          <FastField wrapperClass="col-md-6" type="text" name={`ogsvmuContactName`} component={InputComponent} label="VMU Contact Name"></FastField>
          <FastField wrapperClass="col-12" type="textarea" name={`additionalGoalPlanUploadInstructions`} component={InputComponent} label="Goal Plan Instructions" required={false}></FastField>
        </div>
        <p className="text-success">{successMessage}</p>
        <ReactiveButton type="submit" className="small btn-secondary margin-auto" label={"Submit"} processing={isSubmitting} disabled={!isValid} />
      </Form>
    </>
  );
}
const SystemPreferences = withFormikFormComponent(SystemPreferencesInternal, schemas.systemPreferencesSchema);
export default SystemPreferences;

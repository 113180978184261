import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../Layouts/Base";
import { loadUserToken, roles } from "../../util/userHelper";
import UserInfo from "../UserInfo/UserInfo";
import { getNotificationsCount } from "../../util/api";

const Header = (props) => {
  const { onActiveOrgId, activeOrgId, loginAndReturn } = props;
  const location = useLocation();
  const userInfo = useContext(UserContext);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const hasUnread = notificationsCount > 0 ? true : false;

  useEffect(() => {
    if (location.pathname === "/logout") {
      props.logOut();
    }
  }, [location]);

  useEffect(() => {
    const fetchNotificationsCount = async () => {
      const resObj = await getNotificationsCount();
      setNotificationsCount(resObj.data);
    };
    if (loadUserToken()) {
      fetchNotificationsCount();
    }
  }, []);

  let LoginButton;

  if (userInfo?.user) {
    // if (loadUserToken()) {
    LoginButton = (
      <UserInfo userInfo={userInfo} onActiveOrgId={onActiveOrgId} activeOrgId={activeOrgId}>
        <hr />
        <a href="/notifications" className="d-flex flex-row align-items-center d-md-none mb-3">
          <span className="position-relative ">
            <FontAwesomeIcon icon={["fas", "bell"]} className="mx-2 text-secondary" size="2x" /> {hasUnread ? <span className="indicator circle position-absolute bg-success"></span> : null}
          </span>
          Notifications
        </a>
        <hr />
        <button
          onClick={() => {
            props.logOut();
          }}
          className="btn btn-secondary btn-block btn-md mb-0"
        >
          Log Out
        </button>
      </UserInfo>
    );
  } else {
    LoginButton = (
      <button onClick={loginAndReturn} id="user-action-login" className="faux-link d-flex flex-column justify-content-center">
        Login
      </button>
    );
  }

  let portalName = "Service-Disabled Veterans' Enterprise System";

  switch (userInfo?.user?.role) {
    case roles.DivisionUser:
      portalName = "Division Portal";
      break;
    case roles.DivisionAdmin:
      portalName = "Division Admin Portal";
      break;
    case roles.AgencyAdmin:
    case roles.AgencyUser:
      portalName = "Agency & Authority Portal";
      break;
    case roles.ContractManager:
      portalName = "Agency & Authority Portal";
      break;
    case roles.Business:
      portalName = "Service-Disabled Veterans' Enterprise System";
      break;
    default:
      portalName = "Service-Disabled Veterans' Enterprise System";
  }

  useEffect(() => {});

  return (
    <header>
      {/* <div className="header-top py-3">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-7">
              <a href="/">
                <img src={Logo} alt="NYS Logo" height="50" />
              </a>
            </div>
          </div>
        </div>
      </div> */}
      <div className="header-bottom bg-primary py-3">
        <div className="container-fluid">
          <header position="static" className="nys-margins">
            <div className="row align-items-center">
              <div className="col-sm-7 mb-3 mb-md-0">
                <p className="h3 mb-0 d-sari">
                  <span>Office of General Services</span>
                </p>
                <p className=" mb-0 d-sari">
                  <strong>Service-Disabled Veterans' Enterprise System</strong>
                </p>
                {/* <p className="h3 mb-0">{portalName}</p> */}
              </div>
              <div className="col-sm-5 d-flex justify-content-center justify-content-md-end align-items-center">
                {userInfo?.user ? (
                  <a href="/notifications" className="position-relative d-none d-md-block">
                    <FontAwesomeIcon icon={["fas", "bell"]} className="mx-2" size="2x" /> {hasUnread ? <span className="indicator circle position-absolute bg-success"></span> : null}
                    <span className="sr-only">Notifications</span>
                  </a>
                ) : null}
                <div className="user-links-separator mx-3 bg-white d-none d-md-block"> </div>
                {LoginButton}
              </div>
            </div>
          </header>
        </div>
      </div>
    </header>
  );
};

export default Header;

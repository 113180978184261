import React from "react";
import { Card } from "react-bootstrap";
import { formatAddress, formatPhoneNumber, transformToUrl, getUtcDate } from "../../util/helpers";
import naicsCodes from "../../util/sourceData/naicsCodes";
import { sizeCategories } from "../../util/typelistHelper";
import { loadUserRole, roles } from "../../util/userHelper";
import { businessTypes } from "../../util/typelistHelper";
import BusinessDetailRecertificationCard from "./BusinessDetailRecertificationCard";

const millisecondsPerSecond = 1000;
const secondsPerMinute = 60;
const minutesPerHour = 60;
const hoursPerDay = 24;
const daysPerCertifcationWindow = 182; // This is about 6 months
const millisecondsPerCertificationWindow = daysPerCertifcationWindow * hoursPerDay * minutesPerHour * secondsPerMinute * millisecondsPerSecond;

const BusinessDetail = ({ business }) => {
  const {
    legalName,
    dbaNames,
    addresses,
    phoneNumber,
    emailAddress,
    homepageUrl,
    controlNumber,
    tags,
    specificFunction,
    description,
    serviceTypes,
    categories,
    regionLocatedIn,
    countyLocatedIn,
    counties,
    nyc_contract_title,
    sizeCategory,
  } = business;

  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const userRole = loadUserRole();
  const canRecertify = !!(business.certificationExpDate) && !!(business.isCertifiedSdvob);

  const expirationDate = canRecertify 
    ? new Date(business.certificationExpDate)
    : null;

  const isWithinCertificationTimePeriod = canRecertify
    ? now.getTime() >= expirationDate.getTime()
      ? true
      : expirationDate.getTime() - now.getTime() < millisecondsPerCertificationWindow
    : false;
    
  const certificationExpDate = expirationDate 
    ? new Date(expirationDate.getUTCFullYear(), expirationDate.getUTCMonth(), expirationDate.getUTCDate())
    : null;
  
  const certificationExpDatePlusOne = certificationExpDate
    ? new Date(certificationExpDate)
    : null;

  if (certificationExpDatePlusOne) {
    certificationExpDatePlusOne.setDate(certificationExpDate.getUTCDate() + 1);
  }

  const minEffectiveDate = certificationExpDatePlusOne ?? today;

  const maxEffectiveDate = today < certificationExpDatePlusOne
    ? certificationExpDatePlusOne
    : today;

  const routeInitialValues = {
    effectiveDate: minEffectiveDate,
    sendEmail: true
  };

  const canManuallyRecertify = canRecertify
    && isWithinCertificationTimePeriod
    && (userRole === roles.DivisionAdmin || userRole === roles.DivisionUser);

  const websiteUrl = transformToUrl(homepageUrl);

  const outputArr = [
    {
      name: "Legal Name",
      value: legalName,
      ref: "legalName",
    },
    {
      name: "DBA Names",
      value: dbaNames,
      ref: "dbaNames",
      sdvobOnly: true
    },
    {
      name: "Physical Address",
      value: addresses,
      ref: "physicalAddress",
    },
    {
      name: "Mailing Address",
      value: addresses,
      ref: "mailingAddress",
    },
    {
      name: "Phone",
      value: formatPhoneNumber(phoneNumber),
      ref: "phoneNumber",
    },
    {
      name: "Email",
      value: emailAddress,
      ref: "emailAddress",
    },
    {
      name: "Website",
      value: websiteUrl ? (
        <a href={websiteUrl} target="_blank" rel="noopener noreferrer">
          {websiteUrl}
        </a>
      ) : (
        ""
      ),
      ref: "homepageUrl",
    },
    {
      name: "Control Number",
      value: controlNumber,
      ref: "controlNumber",
      sdvobOnly: true
    },
    {
      name: "Categories",
      value: categories,
      ref: "categories",
      sdvobOnly: true
    },
    {
      name: "Business Size",
      value: sizeCategories?.length && sizeCategory ? sizeCategories.find((item) => item[0] === sizeCategory)[1] : "Not provided",
      ref: "sizeMaximum",
      sdvobOnly: true
    },
    {
      name: "Specific Function/Business Description",
      value: (
        <div>
          {specificFunction && <p>{specificFunction}</p>} {description && <p>{description}</p>}
        </div>
      ),
      ref: "specificFunction",
      sdvobOnly: true
    },
    {
      name: "Classifications",
      value: serviceTypes,
      ref: "serviceTypes",
      sdvobOnly: true

    },
    {
      name: "Keywords",
      value: tags,
      ref: "keywords",
      sdvobOnly: true
    },
    {
      name: "Home County",
      value: countyLocatedIn,
      ref: "countyLocatedIn",
      sdvobOnly: true
    },
    {
      name: "Home Region",
      value: regionLocatedIn,
      ref: "regionLocatedIn",
      sdvobOnly: true
    },
    {
      name: "Counties Served",
      value: counties,
      ref: "counties",
      sdvobOnly: true

    },
  ];
  return (
    <div className="field-group">
      <div className="row">
        <div className="col-lg-6">
          <Card>
            <Card.Header className="bg-primary">Business Details</Card.Header>
            <Card.Body>
              {business
                ? outputArr.map((item, i) => {
                    let val = "";
                    if(item?.sdvobOnly == true && !business?.isCertifiedSdvob){
                      return ""
                    }
                    switch (item.ref) {
                      case "physicalAddress":
                        const physicalAddr = business?.addresses?.length
                          ? business.addresses.find((addr) => {
                              return addr.type === "Physical";
                            })
                          : {};
                        val = formatAddress(physicalAddr);
                        break;
                      case "mailingAddress":
                        const mailingAddr = business?.addresses?.length
                          ? business.addresses.find((addr) => {
                              return addr.type === "Mailing";
                            })
                          : {};
                        val = formatAddress(mailingAddr);
                        break;
                      case "dbaNames":
                        val = (
                          <>
                            <span>
                              {item?.value?.length
                                ? item.value.map((item) => (
                                    <>
                                      {item.name}
                                      <br />
                                    </>
                                  ))
                                : "N/A"}
                            </span>
                          </>
                        );
                        break;
                      case "keywords":
                      case "counties":
                      case "categories":
                      case "serviceTypes":
                        item.value = item?.value?.map((serviceType) => {
                          let serviceTypeArr = businessTypes.find((businessType) => businessType[0] === serviceType);
                          return serviceTypeArr && serviceTypeArr?.length ? serviceTypeArr[1] : serviceType;
                        });
                        val = (
                          <>
                            <span>{item?.value ? item?.value.join(", ") : "N/A"}</span>
                          </>
                        );
                        break;
                      default:
                        val = item?.value;
                        break;
                    }
                    return (
                      <div key={i} className="row mb-3">
                        <div className="col-6 ">
                          <span className="font-weight-bold">{item.name}</span>
                        </div>
                        <div className="col-6">{val}</div>
                      </div>
                    );
                  })
                : null}
            </Card.Body>
          </Card>
        </div>
        <div className="col-lg-6">
          <Card className="mb-3">
            <Card.Header className="bg-primary">NAICS Codes</Card.Header>
            <Card.Body>
              {business?.naicsCodes?.length
                ? business.naicsCodes.map((item, i) => {
                    const naicsItem = naicsCodes.find((naics) => naics.id == item);
                    return (
                      <div key={i} className="row mb-3">
                        <div className="col-4 col-md-3">
                          <span className="font-weight-bold">{naicsItem.id}</span>
                        </div>
                        <div className="col-8 col-md-9">{naicsItem?.label.substring(9)}</div>
                      </div>
                    );
                  })
                :  "N/A"}
              {/* <Table data={naicsArr} columns={columns} hidePagination={true} hideHeaders={true} /> */}
            </Card.Body>
          </Card>

          <Card className="mb-3">
            <Card.Header className="bg-primary">Primary Contact</Card.Header>
            <Card.Body>
              {business?.owners?.length
                ? business.owners.map((item, i) => {

                    return (
                      <div key={i} className="row mb-3">
                        <div className="col-6 ">
                          <span className="font-weight-bold">{item?.title}</span>
                        </div>
                        <div className="col-6">{item?.name}</div>
                      </div>
                    );
                  })
                :  "N/A"}
              {/* <Table data={primaryContactArr} columns={columns} hidePagination={true} hideHeaders={true} /> */}
            </Card.Body>
          </Card>

          <Card className="mb-3">
            <Card.Header className="bg-primary">NYS Centralized Contract</Card.Header>
            <Card.Body>
              <>
                {business?.centralizedContracts?.length
                  ? business?.centralizedContracts.map((centralizedContract) => {
                      return (
                        <>
                          <h3>{centralizedContract?.name}</h3>
                          {centralizedContract?.contracts.map((contract) => {
                            return (
                              <p>
                                <strong>{contract?.contractNumber}</strong> - {contract?.primeContractorName}
                              </p>
                            );
                          })}
                        </>
                      );
                    })
                  : "N/A"}
              </>
            </Card.Body>
          </Card>

          {userRole === roles.DivisionAdmin && (
            <Card className="mb-3">
              <Card.Header className="bg-primary">Certification History</Card.Header>
              <Card.Body>
                {business?.certificationHistory?.map((historyItem, index) => {
                  return (
                    <div key={`cert-history-${index}`}>
                      {historyItem.isCertifiedSdvob ? "Certified" : "Uncertified"}: {getUtcDate(historyItem.startDate)} - {getUtcDate(historyItem.endDate)}
                    </div>
                  );
                })}
              </Card.Body>
            </Card>
          )}
          {
            canManuallyRecertify
            ?  <BusinessDetailRecertificationCard 
                business={business}
                minDate={minEffectiveDate}
                maxDate={maxEffectiveDate}
                routeInitialValues={routeInitialValues}
              />
            : <></>
          }
        </div>
      </div>
    </div>
  );
};

export default BusinessDetail;

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getAgencies, getPlansByContractor } from "../../../util/api";
import { outputAwardStatus, outputPercentage } from "../../../util/helpers";
import { withFormikFormComponent } from "../../Forms/FormikForm";
import FilterTableForm from "../../Forms/Input/FilterTableForm";

const FilterListConfig = {
  agency: {
    isJoin: false,
  },
  goal: {
    isJoin: false,
  },
  sdvobs: {
    isJoin: false,
  },
};

const Columns = [
  {
    Header: "Solicitation",
    accessor: "procurement.solicitationNumber", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "solicitationNumber",
  },
  {
    Header: "Agency",
    accessor: "agency", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "agency",
  },
  {
    Header: "Procurement",
    accessor: "procurementDesc", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "procurementDesc",
  },
  {
    Header: "SDVOBs",
    accessor: "sdvobs", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "sdvobs",
  },
  {
    Header: "Status",
    accessor: "status", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "status",
    isVisible: false,
  },
  {
    Header: "Status",
    accessor: "statusLink", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "statusLink",
  },
  {
    Header: "Award Status",
    accessor: "awardStatus", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "awardStatus",
  },
  {
    Header: "Goal",
    accessor: "goal", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "goal",
  },
];

const BusinessUtilizationPlanListInternal = (formikBag) => {
  const [utilizationPlans, setUtilizationPlans] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      const plansRes = await getPlansByContractor();
      setUtilizationPlans(plansRes.plans);
      setIsLoading(false);

      const agenciesRes = await getAgencies();
      setAgencies(agenciesRes.agencies);
    }
    fetchData();
  }, []);

  const initialItems = utilizationPlans?.length
    ? utilizationPlans?.map((item) => {
        // console.log(item);
        return {
          ...item,
          agency: agencies.find((agency) => agency.id == item.agencyId)?.name,
          sdvobs: item.subcontracts.length,
          procurementDesc: item.awardStatus === "Awarded" ? <Link to={`/procurements/${item?.procurementId}/`}>{item?.procurement?.description}</Link> : item?.procurement?.description,
          statusLink: <Link to={`${pathname}/${item.id}/${["Submitted", "Approved"].includes(item.status) ? "view" : "submit"}`}>{item.status}</Link>,
          awardStatus: outputAwardStatus(item.awardStatus),
          goal: outputPercentage(item.goal / 100),
        };
      })
    : [];

  return (
    <section id="utilization-plans-list">
      <div>
        <h2>Utilization Plans</h2>
        {initialItems.length ? (
          <FilterTableForm tableName="business-utilization-plans-list" Columns={Columns} FilterListConfig={FilterListConfig} tableItems={initialItems} />
        ) : (
          <div>{isLoading ? <span className="loader loader-pulse">Loading utilization plans...</span> : <span>You have no Utilization Plans to show</span>}</div>
        )}
      </div>
    </section>
  );
};

const BusinessUtilizationPlanList = withFormikFormComponent(BusinessUtilizationPlanListInternal);
export default BusinessUtilizationPlanList;

import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import { awardPlan, getPlansByProcurement } from "../../../util/api";
import FilterTableForm from "../../Forms/Input/FilterTableForm";
import { Link } from "react-router-dom";

let FilterListConfig = {
  businessName: {},
  extraStatus: {},
  goal: {},
};

let Columns = [
  {
    Header: "Prime",
    accessor: "businessName", // accessor is the "key" in the data
    defaultCanSort: true,
    sortType: "basic",
    filterCode: "businessName",
    isVisible: false,
  },
  {
    Header: "Prime",
    accessor: "id", // accessor is the "key" in the data
    defaultCanSort: true,
    sortType: "basic",
    filterCode: "id",
  },
  {
    Header: "Status",
    accessor: "extraStatus", // accessor is the "key" in the data
    defaultCanSort: true,
    sortType: "basic",
    filterCode: "extraStatus",
  },
  {
    Header: "SDVOB Subcontractors",
    accessor: "totalSdvobs", // accessor is the "key" in the data
    defaultCanSort: true,
    sortType: "basic",
    filterCode: "totalSdvobs",
  },
  {
    Header: "Goal %",
    accessor: "goal", // accessor is the "key" in the data
    defaultCanSort: true,
    sortType: "basic",
    filterCode: "goal",
  },
  {
    Header: "Actions",
    accessor: "actions", // accessor is the "key" in the data
    defaultCanSort: true,
    sortType: "basic",
    filterCode: "actions",
  },
];

const PlansListAgency = (props) => {
  const { procurement, updateCount, setUpdateCount, submittedPlans, setSubmittedPlans } = props;
  const { awardedPlan, awardedPlanId, contract } = procurement;
  const { procurementId, planId } = useParams();
  const history = useHistory();
  const specificPlan = submittedPlans ? submittedPlans?.find((plan) => plan.id == planId) : {};
  const planToLoad = specificPlan ? specificPlan : awardedPlan;
  // const plansOrdered = plans.length ? plans.sort((a, b) => a?.awardStatus?.localeCompare(b?.awardStatus)) : [];

  useEffect(() => {
    const fetchData = async () => {
      const resObj = await getPlansByProcurement(procurementId);
      if (resObj.plans.find((item) => item.awardStatus === "Awarded")) {
        setUpdateCount(updateCount + 1);
      } else {
        setSubmittedPlans(resObj?.plans);
      }
    };
    fetchData();
  }, [updateCount]);

  const initialItems = submittedPlans
    ? submittedPlans.map((item) => {
        return {
          ...item,
          id: <Link to={`/procurements/${procurementId}/plans-waivers/${item.id}`}>{item.businessName}</Link>,
          totalSdvobs: item.subcontracts.length,
          goal: item.goal.toString(),
          actions: awardedPlanId ? (
            "N/A"
          ) : (
            <Button
              className="btn btn-secondary btn-sm"
              onClick={async () => {
                await awardPlan(procurementId, item.id);
                history.push(`/procurements/${procurementId}/plans-waivers`);
                setUpdateCount(updateCount + 1);
              }}
            >
              Award
            </Button>
          ),
          extraStatus: `${item.status} ${item?.primeIsSDVOB ? `(SDVOB Prime)` : ``}`,
        };
      })
    : null;

  return (
    <>
      <h2>Plan List</h2>
      {initialItems?.length ? (
        <FilterTableForm tableName="agency-plan-list" Columns={Columns} FilterListConfig={FilterListConfig} tableItems={initialItems} />
      ) : (
        <h3>There are currently no submitted plans for review</h3>
      )}
    </>
  );
};

export default PlansListAgency;

import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getBusiness, updateBusiness } from "../../util/api";
import { loadActiveOrgId, loadUserRole } from "../../util/userHelper";
import UpdateBusinessInfoForm from "./UpdateBusinessInfoForm";

const UpdateBusinessInfo = ({ business, businessHasUpdated, setBusinessHasUpdated }) => {
  const params = useParams();
  const history = useHistory();
  const businessId = params.businessId ? params.businessId : loadActiveOrgId();

  // const [business, setBusiness] = useState({
  //   categories: [],
  //   naicsCodes: [],
  //   tags: [],
  // });
  const [backendErrors, setBackendErrors] = useState();

  // useEffect(() => {
  //   console.log("double trouble");
  //   const getAndSetBusiness = async () => await getBusiness(businessId).then((res) => setBusiness(res.data));

  //   getAndSetBusiness();
  // }, [businessId, businessHasUpdated]);

  const onSubmit = async (values) => {
    try {
      await updateBusiness(values);
      window.scrollTo(0, 0);
      setBusinessHasUpdated(businessHasUpdated + 1);
      loadUserRole() === "Business" ? history.go(0) : history.push(`/business/${businessId}`);
    } catch (e) {
      if (e?.response?.status === 400) {
        setBackendErrors(e?.response?.data);
      } else {
        setBackendErrors(e.message);
      }
    }
  };

  return <UpdateBusinessInfoForm routeInitialValues={business} onSubmit={onSubmit} businessId={businessId} business={business} backendErrors={backendErrors} />;
};
export default UpdateBusinessInfo;

import React from "react";
import { Button, Modal } from "react-bootstrap";
import { DeactivateCertification, handleRevokeCertification, ReactivateCertification } from "../../util/api";


const BusinessDetailModal = ({ businessId, showModal, handleCloseModal, setCertificationUpdated, certificationUpdated, modalChoice }) => {
  const confirmations = {
    Revoke: {
      message: "Revoke Certification?",
      action: handleRevokeCertification,
    },
    Deactivate: {
      message: "Deactivate Certification?",
      action: DeactivateCertification,
    },
    Reactivate: {
      message: "Reactivate Certification?",
      action: ReactivateCertification,
    },
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>{/* <Modal.Title>Modal heading</Modal.Title> */}</Modal.Header>
      <Modal.Body>{confirmations[modalChoice]?.message}</Modal.Body>
      <Modal.Footer className="d-block">
        <div className="row">
          <div className="col-md-6">
            <Button className="btn-block btn-sm" variant="secondary" onClick={() => handleCloseModal("cancel")}>
              Cancel
            </Button>
          </div>
          <div className="col-md-6">
            <Button
              className="btn-block btn-sm"
              variant="primary"
              onClick={async () => {
                const resObj = await confirmations[modalChoice]?.action(businessId);
                // await reloadApplication();
                setCertificationUpdated(certificationUpdated + 1);
                handleCloseModal("confirm");
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default BusinessDetailModal;

import React from "react";
import { useParams } from "react-router";
import InputComponent from "../../Forms/Input/InputComponent";
import { withFormikFormComponent } from "../../Forms/FormikForm";
import { Field } from "formik";
import DebugModal from "../../Debug/DebugModal";
import { YES_NO_RADIO } from "../../../util/typelistHelper";

const DocumentChecklistInternal = (props) => {
  const { formikBag } = props;
  const { values } = formikBag;
  const { applicationId } = useParams();
  let content = "";
  if (values?.cve === true) {
    content = (
      <div>
        <h4>CVE</h4>
        <ul>
          <li>Proof of VETS FIRST Certification</li>
          <li>DD214(s) and/or NGB22(s) with Line of Duty Report</li>
          <li>
            U.S. Veterans Administration documentation of service-connected disability rating which must be dated within one year of the date DSDVBD receives your SDVOB Certification Application and
            must demonstrate a service-connected disability rating of at least 10%
          </li>
          <li>Official Internal Revenue Service (IRS) documentation verifying the firm’s Federal Employer Identification Number (FEIN) or Social Security Number (SSN) listed on the application</li>
          <li>The entire Federal Income Tax Returns for the applicant business for the three most recently completed tax years</li>
          <li>Business License, if applicable</li>
          <li>Resume which communicates the Disabled Veteran’s experience, education, knowledge, and qualifications</li>
        </ul>
      </div>
    );
  } else if (values?.businessType === "SoleProprietorship") {
    content = (
      <div>
        <h4>Sole Prop</h4>
        <ul>
          <li>DD214(s) and/or NGB22(s) with Line of Duty Report</li>

          <li>
            U.S. Veterans Administration documentation of service-connected disability rating which must be dated within one year of the date DSDVBD receives your SDVOB Certification Application and
            must demonstrate a service-connected disability rating of at least 10%.{" "}
          </li>

          <li>Official Internal Revenue Service (IRS) documentation verifying the firm’s Federal Employer Identification Number (FEIN) or Social Security Number (SSN) listed on the application. </li>

          <li>The entire Federal Income Tax Returns for the applicant business for the three most recently completed tax years</li>
          <li>Business License, if applicable</li>
          <li>Resume which communicates the Disabled Veteran’s experience, education, knowledge, and qualifications</li>
          <li>Franchise, Trust Agreement, and Amendments, as applicable</li>
        </ul>
      </div>
    );
  } else if (values?.businessType === "Partnership") {
    content = (
      <div>
        <h4>Partnership</h4>
        <ul>
          <li>DD214(s) and/or NGB22(s) with Line of Duty Report</li>

          <li>
            U.S. Veterans Administration documentation of service-connected disability rating which must be dated within one year of the date DSDVBD receives your SDVOB Certification Application and
            must demonstrate a service-connected disability rating of at least 10%.{" "}
          </li>

          <li>Official Internal Revenue Service (IRS) documentation verifying the firm’s Federal Employer Identification Number (FEIN) or Social Security Number (SSN) listed on the application. </li>

          <li>The entire Federal Income Tax Returns for each partner for the three most recently completed tax years </li>

          <li>Business License, if applicable </li>

          <li>Resumes which communicate the Disabled Veterans’ experience, education, knowledge, and qualifications. </li>

          <li>Franchise, Trust Agreement and Amendments, as applicable </li>

          <li>Partnership agreement and amendments </li>
        </ul>
      </div>
    );
  } else if (values?.businessType === "Corporation") {
    content = (
      <div>
        <h4>Corporation</h4>
        <ul>
          <li>DD214(s) and/or NGB22(s) with Line of Duty Report</li>

          <li>
            U.S. Veterans Administration documentation of service-connected disability rating which must be dated within one year of the date DSDVBD receives your SDVOB Certification Application and
            must demonstrate a service-connected disability rating of at least 10%.{" "}
          </li>

          <li>Official Internal Revenue Service (IRS) documentation verifying the firm’s Federal Employer Identification Number (FEIN) or Social Security Number (SSN) listed on the application. </li>

          <li>The entire Federal Income Tax Returns for the applicant business for the three most recently completed tax years, if applicable </li>

          <li>Business License, if applicable </li>

          <li>Resumes which communicate the Disabled Veterans’ experience, education, knowledge, and qualifications </li>

          <li>Franchise, Trust Agreement and Amendments, as applicable </li>

          <li>Articles of Incorporation </li>

          <li>Corporate meeting minutes listing current elected corporate officers and directors, or the most recent Statement of Information </li>

          <li>Corporate bylaws and amendments </li>

          <li>Stock Transfer Ledger and Stock Certificates for original applicants or changes in business structure </li>
        </ul>
      </div>
    );
  } else if (values?.businessType === "LLC") {
    content = (
      <div>
        <h4>LLC</h4>
        <ul>
          <li>DD214(s) and/or NGB22(s) with Line of Duty Report</li>

          <li>
            U.S. Veterans Administration documentation of service-connected disability rating which must be dated within one year of the date DSDVBD receives your SDVOB Certification Application and
            must demonstrate a service-connected disability rating of at least 10%.{" "}
          </li>

          <li>Official Internal Revenue Service (IRS) documentation verifying the firm’s Federal Employer Identification Number (FEIN) or Social Security Number (SSN) listed on the application. </li>
          <li>The entire Federal Income Tax Returns for the applicant business for the three most recently completed tax years, if applicable </li>

          <li>Business License, if applicable </li>

          <li>Resumes which communicate the Disabled Veterans’ experience, education, knowledge, and qualifications </li>

          <li>Franchise, Trust Agreement and Amendments, as applicable </li>

          <li>Articles of Organization </li>

          <li>Operating Agreement and amendments </li>
        </ul>
      </div>
    );
  } else if (values?.businessType === "LLP") {
    content = (
      <div>
        <h4>LLP</h4>
        <ul>
          <li>DD214(s) and/or NGB22(s) with Line of Duty Report</li>

          <li>
            U.S. Veterans Administration documentation of service-connected disability rating which must be dated within one year of the date DSDVBD receives your SDVOB Certification Application and
            must demonstrate a service-connected disability rating of at least 10%.{" "}
          </li>

          <li>Official Internal Revenue Service (IRS) documentation verifying the firm’s Federal Employer Identification Number (FEIN) or Social Security Number (SSN) listed on the application. </li>
          <li>The entire Federal Income Tax Returns for each limited liability partner for the three most recently completed tax years, if applicable </li>
          <li>Business License, if applicable </li>
          <li>Resumes which communicate the Disabled Veterans’ experience, education, knowledge, and qualifications. </li>
          <li>Franchise, Trust Agreement and Amendments, as applicable </li>
          <li>Partnership agreement and amendments B5(f).</li>
          <li>Limited Liability Partnership Registration </li>
        </ul>
      </div>
    );
  } else {
    content = (
      <div>
        <ul>
          <li>DD214(s) and/or NGB22(s) with Line of Duty Report</li>

          <li>
            U.S. Veterans Administration documentation of service-connected disability rating which must be dated within one year of the date DSDVBD receives your SDVOB Certification Application and
            must demonstrate a service-connected disability rating of at least 10%.{" "}
          </li>

          <li>Official Internal Revenue Service (IRS) documentation verifying the firm’s Federal Employer Identification Number (FEIN) or Social Security Number (SSN) listed on the application. </li>
        </ul>
      </div>
    );
  }

  return (
    <div>
      <DebugModal formikBag={formikBag} name="Formik Bag" />

      <h2>Pre-Application Document Checklist</h2>

      <p>
        During the SDVOB application, you will be asked to upload supporting documentation through this portal. Please answer the following questions to generate a checklist of documents to help you
        prepare for the certification process.{" "}
      </p>
      <p>Note: These questions do not constitute the start of the application.</p>

      <form>
        <Field
          wrapperClass="mb-4"
          label="1. Has your business been certified by the US Department of Veterans Affairs, Vets First Program (CVE)?"
          type="radio"
          component={InputComponent}
          name="cve"
          inline={true}
          options={YES_NO_RADIO}
          required={false}
        />
        <Field
          wrapperClass="mb-4"
          label="2. Which ownership type best describes your business?"
          type="radio"
          component={InputComponent}
          childClass="col-md-6"
          name="businessType"
          required={false}
          options={[
            [
              "SoleProprietorship",
              "Sole Proprietorship",
              "A sole proprietor is personally responsible for all debts of his or her business. If operating under a name other than the proprietor's, an Assumed Name Certificate must be filed; otherwise, no formation documentation is required.",
            ],
            [
              "Partnership",
              "Partnership",
              "A business where personal liability is joint and individual for the general partners responsible for the obligations of the partnership. Requires the filing of a Certificate of Assumed Name following an agreement of the partners.",
            ],
            [
              "Corporation",
              "Corporation",
              "A legal entity separate and distinct from the individual(s) who compose the business. A Certificate of Incorporation must be filed (signed by at least one incorporator) with the Department of State.",
            ],
            ["LLC", "Limited Liability Company", "A business entity that limits personal liability for its members. Requires the filing of Articles of Organization and a Certificate of Publication."],
            [
              "LLP",
              "Limited Liability Partnership",
              "Personal liability is joint and individual for the general partner(s) who are responsible for the obligations of the partnership; limited partner(s) are liable to the extent of their capital contributions to the partnership. Requires filing of Certificate of Limited Partnership and Certificate of Publication.",
            ],
          ]}
        />
        {values.businessType === "SoleProprietorship" ? <p className="text-danger">Note – A single member LLC should check Limited Liability Company, NOT sole proprietorship.  Please only select this option if you are certain that you are a sole proprietorship.</p> : null}
      </form>

      <div className="document-checklist-content py-4">
        <p>You will need the documents listed below to successfully complete your application.</p>
        {content}
        <div className="py-4">
          {/* <a href={checklistUrl} className="btn btn-secondary">Generate Document Checklist</a> */}
          {applicationId && <a href={`/application/${applicationId}`} className="btn btn-secondary">
            Proceed to Application
          </a>}
        </div>
      </div>
    </div>
  );
};

const DocumentChecklist = withFormikFormComponent(DocumentChecklistInternal);
export default DocumentChecklist;

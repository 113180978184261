import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { deleteReport } from "../../util/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DeleteReportModal = (props) => {
  const { show, report, onHide } = props;
  const [isSaving, setIsSaving] = useState(false);

  async function deleteExistingReport() {
    if (!report) {
      // TODO, can't really happen but whatever
    }

    setIsSaving(true);

    try {
      await deleteReport(report.id);

    } catch (e) {
      // TODO
      console.log(e);
    } finally {
      onHide(true);
      setIsSaving(false);
    }
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Delete Report
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div hidden={isSaving}>
          Are you sure you want to delete the report, "{report?.name}"?
        </div>
        <FontAwesomeIcon
          hidden={!isSaving}
          icon={"spinner"}
          className="fa-spin"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={deleteExistingReport}
          disabled={isSaving}
        >
          Yes, Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteReportModal;

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || "{clientId}";
const ISSUER =
  process.env.REACT_APP_ISSUER || "https://login-qa.ny.gov/oauth2/default";

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: `${window.location.origin}/implicit/callback`,
    postLogoutRedirectUri: `${window.location.origin}/`,
    scopes: ["openid", "profile", "email", "groups", "ITS", "ogs"],
    pkce: true,
  },
};

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { setPlanStatus, getPlan } from "../../../util/api";
import PlanReview from "./PlanReview";
import PlansListAgency from "./PlansListAgency";
import { roles, loadUserRole } from "../../../util/userHelper";

function PlansAndWaiversAgency(props) {
  const { procurement, updateCount, setUpdateCount } = props;
  const { awardedPlan } = procurement;
  const { procurementId, planId } = useParams();
  const [submittedPlans, setSubmittedPlans] = useState(null);
  // const specificPlan = submittedPlans?.find((plan) => plan.id == planId);
  const [specificPlan, setSpecificPlan] = useState(false);
  const planToLoad = specificPlan ? specificPlan : awardedPlan;

  async function onSubmit(values, { resetForm }) {
    await setPlanStatus(procurementId, planToLoad.id, values.status, values.message);
    setUpdateCount(updateCount + 1);
    resetForm();
  }

  useEffect(() => {
    const fetchData = async () => {
      const resObj = await getPlan(planId);
      setSpecificPlan(resObj);
    };

    if (planId) {
      fetchData();
    }
  }, [updateCount]);

  return (
    <section id="plan">
      {planId && !specificPlan ? <h2 className="loader loader-pulse">Loading Plan Information...</h2> : null}

      {(planId && specificPlan) || awardedPlan ? (
        <PlanReview planToLoad={planToLoad} procurement={procurement} procurementId={procurementId} onSubmit={onSubmit} routeInitialValues={{ status: planToLoad?.status }} />
      ) : null}

      {!!awardedPlan || [roles.DivisionAdmin, roles.DivisionUser].includes(loadUserRole()) ? null : (
        <PlansListAgency
          submittedPlans={submittedPlans}
          setSubmittedPlans={setSubmittedPlans}
          planToLoad={planToLoad}
          procurement={procurement}
          procurementId={procurementId}
          updateCount={updateCount}
          setUpdateCount={setUpdateCount}
        />
      )}
    </section>
  );
}
export default PlansAndWaiversAgency;

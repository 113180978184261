import { FastField } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { getArrayIndex, isUserAnOwner } from "../../../../util/helpers";
import { YES_NO_RADIO } from "../../../../util/typelistHelper";
import { getUserId, loadUserToken } from "../../../../util/userHelper";
import InputComponent from "../../../Forms/Input/InputComponent";
import AddressForm from "./AddressForm";

const OwnerForm = (props) => {
  const { index, name, formikBag } = props;
  let ownerValues = _.get(formikBag.values, name);
  const { setFieldValue, values } = formikBag;

  const getArrayIndexWrapper = (type, arrayName) => {
    const elements = _.get(formikBag.values, arrayName);
    const index = getArrayIndex(elements, type);
    return `${arrayName}.${index}`
  };

  const onPreparerAssign = (event, checkedValue) => {
    if (event.target.checked) {
      // setFieldValue('preparerId', checkedValue);
      setFieldValue(`owners[0].emailAddress`, loadUserToken().user.emailAddress);
    } else {
      // setFieldValue('preparerId');
      // console.log('run the else')
      setFieldValue(`owners[0].emailAddress`);
      setFieldValue(`owners[0].userId`, undefined);
    }
  }

  // console.log(values?.owners[index].userId !== getUserId(), index !== 0);

  return (
    <div className="owner-group">
      <div className="field-group justify-content-end">
        <div className="row">
          {index === 0 && <FastField
            wrapperClass="col-12"
            label='If you are the owner of this application, check "Yes" below and enter your information as the first owner'
            type="checkbox"
            options={[[getUserId(), 'Yes']]}
            name={`${name}.userId`}
            placeholder=""
            required={false}
            component={InputComponent}
            onChange={onPreparerAssign}
          />}
          <FastField
            wrapperClass="col-12"
            label="Name of Individual, Owner, Partner, LLC Member, LLC Manager, Shareholder, or Officer"
            type="text"
            name={`${name}.name`}
            placeholder=""
            component={InputComponent}
          />
          <FastField wrapperClass="col-md-6" label="Title" type="text" name={`${name}.title`} placeholder="" component={InputComponent} />
          <FastField wrapperClass="col-md-6" label="Owner's Primary Function in the Business" type="text" name={`${name}.role`} placeholder="" component={InputComponent} />
          <FastField wrapperClass="col-md-4" label="Percentage of ownership" type="number" min="1" max="100" name={`${name}.percentageOwnership`} placeholder="" component={InputComponent} />
          <FastField
            wrapperClass="col-md-4"
            label="Service-disabled?"
            childClass="mr-3"
            type="radio"
            name={`${name}.isServiceDisabled`}
            options={YES_NO_RADIO}
            component={InputComponent}
          />
          {ownerValues.isServiceDisabled ? (
            <FastField
              wrapperClass="col-md-4"
              label="Percentage Service Disability"
              type="select"
              options={[[0, 0], [10, 10], [20, 20], [30, 30], [40, 40], [50, 50], [60, 60], [70, 70], [80, 80], [90, 90], [100, 100]]}
              defaultValue={10}
              name={`${name}.percentageServiceDisabled`}
              placeholder=""
              component={InputComponent}
            />
          ) : null}
        </div>
      </div>
      <div className="field-group ">
        <AddressForm name={getArrayIndexWrapper('Home', `${name}.addresses`)} type="Home"
          setFieldValue={setFieldValue}

        />
        <AddressForm
          values={values}
          name={getArrayIndexWrapper("Mailing", `${name}.addresses`)} type="Mailing"
          setFieldValue={setFieldValue}
          addressFromName={getArrayIndexWrapper('Home', `${name}.addresses`)}
          copyButtonType="Home"
        />
      </div>

      <div className="field-group ">
        <div className="row">
          <FastField
            wrapperClass="col-md-6"
            label="Phone Number (10 digit)"
            type="inputphone"
            name={`${name}.phoneNumber`}
            placeholder=""
            component={InputComponent}
          />
          <FastField
            wrapperClass="col-md-6"
            label="Phone Number Extension"
            type="inputphoneextension"
            name={`${name}.phoneNumberExtension`}
            placeholder=""
            component={InputComponent}
            required={false}
          />

          {!isUserAnOwner(values) || index !== 0 ?
            <FastField
              wrapperClass="col-md-6"
              label="Email"
              type="text"
              name={`${name}.emailAddress`}
              placeholder=""
              component={InputComponent}
            />
            : null
          }
        </div>
      </div>
      {/* <ArrayWrapperDynamic name={`${name}.addresses`} component={AddressForm} formikBag={formikBag} /> */}
    </div>
  );
};
export default OwnerForm;

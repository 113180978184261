import { FastField } from "formik";
import React from "react";
import InputComponent from "../../../Forms/Input/InputComponent";

function ContactInformationSection({ showPhoneExtension, formikBag }) {
  return (
    <>
      <FastField wrapperClass="col-lg-6" label="Business Email Address" type="email" name={`emailAddress`} component={InputComponent} placeholder="" />
      <FastField wrapperClass="col-lg-6" label="Website" type="text" required={false} name={`homepageUrl`} component={InputComponent} placeholder="" />
      <FastField wrapperClass="col-lg-6" label="Phone Number (10 digit)" name={`phoneNumber`} placeholder="" component={InputComponent} type="inputphone" />
      {showPhoneExtension && (
        <FastField wrapperClass="col-lg-6" label="Phone Number Extension" name={`phoneNumberExtension`} placeholder="" component={InputComponent} type="inputphoneextension" required={false} />
      )}
      <FastField wrapperClass="col-lg-6" label="Fax Number" name={`faxNumber`} component={InputComponent} placeholder="" type="inputphone" required={false} />
    </>
  );
}

export default ContactInformationSection;

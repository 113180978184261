import React from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";


/**
 * @param {tab, slideCount} props 
 */
const ProgressBar = (props) => {
  let completion = ((props.tab.activeSlide + 1) * 100) / props.slideCount;

  return (
    <div className="position-relative">
      <div className="progress-bar bg-light pt-2 w-100 position-absolute"></div>
      <div className="stages pt-2 bg-secondary mb-4 position-relative" style={{ width: completion + "%" }}></div>
      {props.tab.activeSlide + 1}/{props.slideCount} — {props.title}
    </div>
  );
};

export default ProgressBar;

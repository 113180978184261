import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { getErrorsForTab, parseApplicationErrors } from "../../../../util/errorHelper";
import { getNextTabKey, TabComponents } from "../../../../util/tabHelper";

const ErrorLine = (props) => {
  const { errors, onTabChange, tabKey } = props;
  const { message, slide } = parseApplicationErrors(errors);

  return (
    <div key={message}>
      {message && (
        <div className="d-flex flex-row justify-content-between mb-3">
          <span className="pr-4">{message}</span>
          {slide && (
            <span onClick={() => onTabChange(tabKey, { activeSlide: slide - 1 }, false)} className="location">
              <button variant="link" type="button" className="faux-link text-nowrap">
                page {slide} <FontAwesomeIcon icon={["fas", "angle-right"]} />
              </button>
            </span>
          )}
        </div>
      )}
    </div>
  );
};

const SectionCompleteForm = (props) => {
  const { errors, onTabChange, tabKey } = props;
  // console.log(errors);
  const nextTabKey = getNextTabKey(TabComponents, tabKey);
  const errorsForTab = getErrorsForTab(errors, tabKey);

  const ErrorRow = ({ errors }) => {
    if (typeof errors === "object") {
      // console.log('is object');
      // console.log(errors);
      if (Array.isArray(errors)) {
        // console.log('is array');
        // console.log(errors);
        return (
          <div>
            {errors.map((childError, index) => (
              <ErrorRow key={index} errors={childError} />
            ))}
          </div>
        );
      } else {
        // console.log('is object not array');
        // console.log(errors);
        const keys = Object.keys(errors);
        return (
          <div>
            {keys.map((key1, index) => (
              <ErrorRow key={index} errors={errors[key1]} />
            ))}
          </div>
        );
      }
    } else {
      // console.log('is not object');
      return <ErrorLine errors={errors} onTabChange={onTabChange} tabKey={tabKey}></ErrorLine>;
    }
  };
  return (
    <>
      <div>
        {errors?.err || Object.keys(errorsForTab).length > 0 ? (
          <>
            <h2 className="text-danger">Section Incomplete</h2>
            <div className="alert alert-danger mb-5 mt-3">
              {errors?.err && <div>{errors.err}</div>}
              <ErrorRow errors={errorsForTab} />
            </div>
          </>
        ) : (
          <div className="">
            <div className="form-tab-intro">
              <h2>Section Complete</h2>
            </div>
            <p>
              Congratulations, you have completed the {TabComponents[tabKey]?.title} section. Next, you will fill out the {TabComponents[nextTabKey]?.title} section.
            </p>
            <p>A reminder that the information you have entered is automatically saved. If you leave the application at any time, you can return later and continue where you left off.</p>
          </div>
        )}
      </div>
      <button id={`${nextTabKey}-advance`} type="button" className="btn btn-secondary" onClick={() => onTabChange(nextTabKey, { activeSlide: 0, isTouched: true }, true)}>
        Go to {TabComponents[nextTabKey]?.title}
      </button>
    </>
  );
};

export default SectionCompleteForm;

import React, { useState, useEffect, Fragment } from "react";
import { FastField, Form } from "formik";
import { withFormikFormComponent } from "../../Forms/FormikForm";
import InputComponent from "../../Forms/Input/InputComponent";
import { Row, Col, Container } from "react-bootstrap";
import { getAgencyUsers, getUsersByAgency } from "../../../util/api";
import DebugModal from "../../Debug/DebugModal";
import ReactiveButton from "../../Forms/Input/ReactiveButton";

const ProcurementUpdateUsersFormInternal = (props) => {
  const [agencyUsers, setAgencyUsers] = useState([]);
  const { formikBag } = props;
  const { isSubmitting } = formikBag;

  const getAndSetAgencyUsers = async () => {
    let { users } = await getUsersByAgency();
    setAgencyUsers(users);
  };

  useEffect(() => {
    getAndSetAgencyUsers();
  }, []);

  return (
    <>
      {agencyUsers && agencyUsers.length > 0 ? (
        <>
          <h3>Assign and/or Remove Agency Users</h3>
          <Form>
            <FastField
              type="typeahead"
              filterBy={["id"]}
              id="agency-users-form"
              name={`userIds`}
              component={InputComponent}
              label={"Agency Users"}
              options={agencyUsers?.map((user) => ({ ...user, label: `${user.lastName}, ${user.firstName}` }))}
              multiple={true}
            />

            <ReactiveButton type="submit" className="mt-4 btn-md btn-block btn-secondary margin-auto" label="Assign" processing={isSubmitting} />
          </Form>
        </>
      ) : null}
    </>
  );
}

const ProcurementUpdateUsersForm = withFormikFormComponent(ProcurementUpdateUsersFormInternal);
export default ProcurementUpdateUsersForm;

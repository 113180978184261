import React, { useEffect, useRef } from "react";
import ReportingColumn from './ReportingColumn';
import "./ReportingColumns.scss";

function reportColumnSorter(a, b) {
  if (a.selected && b.selected) {
    if (a.order !== undefined && b.order !== undefined) {
      return a.order > b.order ? 1 : -1;
    } else if (a.order !== undefined) {
      return 1;
    } else if (b.order) {
      return -1;
    }
  } else if (a.selected) {
    return 1;
  } else if (b.selected) {
    return -1;
  }

  return a.fieldName > b.fieldName ? 1 : -1;
}

function initializeReportColumns(reportColumns, unselectIdFields = false) {
  return reportColumns
    .filter((reportColumn) => !reportColumn.unselectable)
    .map((reportColumn, index) => {
      const friendlyNameParts = reportColumn.friendlyName.split(' ');
      const selected = !unselectIdFields || friendlyNameParts[friendlyNameParts.length - 1] !== 'Id';

      return {
        fieldName: reportColumn.fieldName,
        friendlyName: reportColumn.friendlyName,
        selected,
        orderDirection: reportColumn.orderDirections[0].name,
        aggregate: reportColumn.aggregates[0].name,
        aggregates: reportColumn.aggregates,
        orderDirections: reportColumn.orderDirections,
        order: index,
        coupledFieldNames: reportColumn.coupledFieldNames
      };
    })
    .sort(reportColumnSorter);
}

const ReportingColumns = (props) => {
  const { reportColumns, setReportColumns } = props;
  const toggleAllCheckboxRef = useRef();

  function getNumberOfSelectedColumns() {
    return reportColumns.map((reportColumn) => +!!reportColumn.selected).reduce((a, b) => a + b, 0);
  }

  function toggleAllReportColumns() {
    const numberOfSelectedColumns = getNumberOfSelectedColumns();
  
    setReportColumns(
      reportColumns.map(
        (reportColumn) => (
          {
            ...reportColumn,
            selected: reportColumns.length !== numberOfSelectedColumns
          }
        )
      )
    );
  }

  function getUpdatedColumn(fieldName, propertyName, propertyValue) {
    const columnIndex = reportColumns.findIndex((reportColumn) => reportColumn.fieldName === fieldName);
    const column = reportColumns[columnIndex];
  
    const newReportColumn = {
      ...column,
      [propertyName]: propertyValue
    };
  
    return {
      index: columnIndex,
      value: newReportColumn
    };
  }

  function updateColumnProperty(fieldName, propertyName, propertyValue, coupledFieldNames = undefined) {  
    const newReportColumns = [
      ...reportColumns
    ];

    const updatedColumn = getUpdatedColumn(fieldName, propertyName, propertyValue);
  
    newReportColumns[updatedColumn.index] = updatedColumn.value;

    if (Array.isArray(coupledFieldNames) && coupledFieldNames.length > 0) {
      coupledFieldNames.forEach((coupledFieldName) => {
        const updatedCoupledColumn = getUpdatedColumn(coupledFieldName, propertyName, propertyValue);

        newReportColumns[updatedCoupledColumn.index] = updatedCoupledColumn.value;
      });
    }
    
    setReportColumns(newReportColumns);
  }

  function updateColumnOrder(fieldName, direction) {
    const columnIndex = reportColumns.findIndex((reportColumn) => reportColumn.fieldName === fieldName);
    const column = reportColumns[columnIndex];
    const adjascentColumnIndex = columnIndex + direction;
    const adjascentColumn = reportColumns[adjascentColumnIndex];
  
    const newReportColumn = {
      ...column,
      order: adjascentColumn.order
    };
  
    const newAdjascentColumn = {
      ...adjascentColumn,
      order: column.order
    }
  
    const newReportColumns = [
      ...reportColumns
    ];
  
    newReportColumns[columnIndex] = newAdjascentColumn;
    newReportColumns[adjascentColumnIndex] = newReportColumn;
  
    setReportColumns(newReportColumns);
  }

  function isToggleAllCheckboxChecked() {
    return getNumberOfSelectedColumns() === reportColumns.length;
  }

  function isToggleAllCheckboxIndeterminate() {
    const numberOfSelectedColumns = getNumberOfSelectedColumns();
  
    return numberOfSelectedColumns > 0 && numberOfSelectedColumns < reportColumns.length;
  }

  useEffect(() => {
    toggleAllCheckboxRef.current.indeterminate = isToggleAllCheckboxIndeterminate(reportColumns);
  }, [reportColumns]);

  useEffect(() => {
    let updatedReportColumns = [];

    if (Array.isArray(props.existingReportColumns)) {
      const newReportColumns = initializeReportColumns(props.reportColumnDefinitions, false);

      props.existingReportColumns.forEach((existingReportColumn) => {
        const reportColumnIndex = newReportColumns.findIndex((newReportColumn) => newReportColumn.fieldName === existingReportColumn.fieldName);

        if (reportColumnIndex > -1)
        {
          const reportColumn = newReportColumns.splice(reportColumnIndex, 1)[0];

          reportColumn.selected = existingReportColumn.selected;
          reportColumn.aggregate = existingReportColumn.aggregate;
          reportColumn.orderDirection = existingReportColumn.orderDirection;
          reportColumn.order = existingReportColumn.order;

          updatedReportColumns.push(reportColumn);
        }
      });

      let currentOrder = updatedReportColumns.length;

      newReportColumns.forEach((newReportColumn) => {
        newReportColumn.order = currentOrder;
        newReportColumn.selected = false;
        
        ++currentOrder;

        updatedReportColumns.push(newReportColumn);
      });

      setReportColumns(updatedReportColumns);
    } else {
      updatedReportColumns = initializeReportColumns(props.reportColumnDefinitions, true);
      setReportColumns(updatedReportColumns);
    }
  }, [props.reportColumnDefinitions, props.existingReportColumns]);

  return (
    <>
      <div className="reporting-columns-container">
        <div className="reporting-columns-header">
          <div>
            <span className="reporting-column-number">
              #
            </span>
            <input
              type="checkbox"
              id="select-all-coliumns"
              name="select-all-coliumns"
              value={true}
              ref={toggleAllCheckboxRef}
              checked={isToggleAllCheckboxChecked()}
              onChange={toggleAllReportColumns}
            />
            <label htmlFor="select-all-coliumns">
              Columns
            </label>
          </div>
          <div className="report-column-options">
            <span 
              className="report-column-aggregate">
              Aggregate
            </span>
            <span className="report-column-order-direction">
              Order Direction
            </span>
          </div>
        </div>
        <ul className="no-list reporting-columns">
          {
            reportColumns.map((reportColumn, i) => {
              const key = `entity-column-list-item-${i}`;

              return <ReportingColumn
                key={key}
                columnNumber={i + 1}
                reportColumn={reportColumn}
                onUpdateColumnProperty={updateColumnProperty}
                onUpdateColumnOrder={updateColumnOrder}
                hasOrderUpButton={i > 0}
                hasOrderDownButton={i < reportColumns.length - 1}
              />;
            })
          }
        </ul>
      </div>
    </>
  )
};

export default ReportingColumns;

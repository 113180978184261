import React from "react";
import "./Footer.scss";
import Logo from "../Images/nys-logo-white.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = (props) => {
  return (
    <footer className="primary-footer bg-primary-light">
      {/* <div className="footer-top dark-links">
        <div className="container-fluid">
          <div className="d-flex flex-column flex-md-row justify-content-between py-5">
            <div className="footer-menu-block">
              <p className="menu-title font-weight-bold mb-2">About</p>
              <ul className="footer-menu no-list mb-4">
                <li>
                  <a href="/">Agency Overview</a>
                </li>
                <li>
                  <a href="/">Commissioner RoAnn M. Destito</a>
                </li>
                <li>
                  <a href="/">Employment</a>
                </li>
                <li>
                  <a href="/">Contact Us</a>
                </li>
                <li>
                  <a href="/">Buy Flags</a>
                </li>
                <li>
                  <a href="/">Permits to Assemble</a>
                </li>
              </ul>
            </div>

            <div className="footer-menu-block">
              <p className="menu-title font-weight-bold mb-2">Public Information</p>
              <ul className="footer-menu no-list mb-4">
                <li>
                  <a href="/">Press</a>
                </li>
                <li>
                  <a href="/">Freedom of Information Law (FOIL)</a>
                </li>
                <li>
                  <a href="/">Open Meetings</a>
                </li>
                <li>
                  <a href="/">Project Sunlight</a>
                </li>
                <li>
                  <a href="/">Register to Vote</a>
                </li>
                <li>
                  <a href="/">Site Map</a>
                </li>
              </ul>
            </div>

            <div className="footer-menu-block">
              <p className="menu-title font-weight-bold mb-2">Legal</p>
              <ul className="footer-menu no-list mb-4">
                <li>
                  <a href="/">Rules and Regulations</a>
                </li>
                <li>
                  <a href="/">Service of Process</a>
                </li>
                <li>
                  <a href="/">Debarred and Non-Responsible Entities</a>
                </li>
                <li>
                  <a href="/">Executive Order 157</a>
                </li>
                <li>
                  <a href="/">Buy Flags</a>
                </li>
              </ul>
            </div>

            <div className="footer-menu-block">
              <p className="menu-title font-weight-bold mb-2">Policies</p>
              <ul className="footer-menu no-list mb-4">
                <li>
                  <a href="/">Agency Overview</a>
                </li>
                <li>
                  <a href="/">Commissioner RoAnn M. Destito</a>
                </li>
                <li>
                  <a href="/">Employment</a>
                </li>
                <li>
                  <a href="/">Contact Us</a>
                </li>
                <li>
                  <a href="/">Buy Flags</a>
                </li>
              </ul>
            </div>

            <div className="footer-menu-block">
              <p className="menu-title font-weight-bold mb-2">More OGS Websites</p>
              <ul className="footer-menu no-list mb-4">
                <li>
                  <a href="/">Agency Overview</a>
                </li>
                <li>
                  <a href="/">Commissioner RoAnn M. Destito</a>
                </li>
                <li>
                  <a href="/">Employment</a>
                </li>
                <li>
                  <a href="/">Contact Us</a>
                </li>
                <li>
                  <a href="/">Buy Flags</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="social-footer-container">
            <h3 className="text-center">Office of General Services</h3>
            <div className="social-media">
              <div className="social-media-title">
                <p className="text-center hr-heading">
                  <span className="px-3 font-weight-bold">CONNECT WITH US</span>
                </p>
              </div>
              <div className="social-media-links py-4">
                <ul className="menu no-list d-flex w-100 justify-content-between">
                  <li>
                    <a href="https://www.facebook.com/NewYorkStateOGS?ref=hl" id="facebook" target="_blank" rel="noopener noreferrer" title="">
                      <FontAwesomeIcon className="mx-3 text-primary" icon={["fab", "facebook-f"]} />
                      <span>Facebook</span>
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="https://twitter.com/NYS_OGS" id="twitter" target="_blank" rel="noopener noreferrer" title="">
                      <FontAwesomeIcon className="mx-3 text-primary" icon={["fab", "twitter"]} />
                      <span>Twitter</span>
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="https://www.youtube.com/user/NYSOGS" id="youtube" target="_blank" rel="noopener noreferrer" title="">
                      <FontAwesomeIcon className="mx-3 text-primary" icon={["fab", "youtube"]} />
                      <span>YouTube</span>
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="https://www.flickr.com/photos/nysogs/sets/" id="flickr" target="_blank" rel="noopener noreferrer" title="">
                      <FontAwesomeIcon className="mx-3 text-primary" icon={["fab", "flickr"]} />
                      <span>Flickr</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom bg-dark py-3">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-2">
              <img src={Logo} alt="NYS Logo" height="50" />
            </div>
            <div className="col-md-10">
              <ul className="menu no-list d-flex justify-content-between text-white">
                <li className="first leaf">
                  <a href="http://www.ny.gov/agencies" className="text-white" title="">
                    Agencies
                  </a>
                </li>
                <li className="leaf">
                  <a href="http://www.ny.gov/mobileapps" className="text-white" title="">
                    App Directory
                  </a>
                </li>
                <li className="leaf">
                  <a href="http://www.ny.gov/counties" className="text-white" title="">
                    Counties
                  </a>
                </li>
                <li className="leaf">
                  <a href="http://www.ny.gov/events" className="text-white" title="">
                    Events
                  </a>
                </li>
                <li className="leaf">
                  <a href="http://www.ny.gov/programs" className="text-white" title="">
                    Programs
                  </a>
                </li>
                <li className="last leaf">
                  <a href="http://www.ny.gov/services" className="text-white" title="">
                    Services
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div> */}
      <div className="container-fluid">
        <div className="">
          <div className="d-flex flex-column flex-md-row py-4 align-items-md-center justify-content-between">
            <h1 className="h2">
              <a href="/greencleaning/" aria-label="New York State Office of General Services Green Products List" className="text-black ">
                Office of General Services
              </a>
            </h1>
            <div>
              <a href="https://ogs.ny.gov/privacy-policy" aria-label="Link to Agency's privacy policy." className="text-black d-block d-md-inline h5 mr-3 font-bold">
                Privacy Policy
              </a>

              <a href="https://ogs.ny.gov/website-accessibility" aria-label="Link to Agency's accessibility policy." className="text-black d-block d-md-inline h5 mr-3 font-bold">
                Accessibility
              </a>

              <a href="https://ogs.ny.gov/contact-office-general-services" aria-label="Link to Agency's contact page." className="text-black d-block d-md-inline h5 mr-3 font-bold">
                Contact Us
              </a>
            </div>

            <nav className="d-flex flex-row" aria-label="social media links">
              <a href="https://www.facebook.com/NewYorkStateOGS/" title="Facebook" aria-label="Visit us on Facebook.">
                <FontAwesomeIcon className="mr-3 text-primary" icon={["fab", "facebook-f"]} />
              </a>
              <a href="https://twitter.com/NYS_OGS" title="Twitter" aria-label="Visit us on Twitter.">
                <FontAwesomeIcon className="mr-3 text-primary" icon={["fab", "twitter"]} />
              </a>
              <a href="https://www.instagram.com/nysgeneralservices/" title="Instagram" aria-label="Visit us on Instagram.">
                <FontAwesomeIcon className="mr-3 text-primary" icon={["fab", "instagram"]} />
              </a>
            </nav>
          </div>
        </div>
      </div>

      <div id="nygov-universal-footer">
        <noscript>
          <iframe
            width="100%"
            height="200px"
            src="//static-assets.ny.gov/load_global_footer/ajax?iframe=true"
            frameBorder="0"
            style={{ border: "none", overflow: "hidden", width: "100%", height: "200px" }}
            scrolling="no"
          >
            Your browser does not support iFrames
          </iframe>
        </noscript>
      </div>
    </footer>
  );
};

export default Footer;

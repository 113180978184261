import React from "react";
import { documentTypes, EMPTY_GUID } from "../../../../util/typelistHelper";
import ReviewSubmitFormLine from "./ReviewSubmitFormLine";

const ReviewSubmitFormDocumentLines = (props) => {
  const { documents, types } = props;
  return (
    <>
      {documents && types?.map((type, index) => {
        const documentsOfType = documents?.filter((document) => document.type === type);

        return (
          documentsOfType.map((document) => {
            return (
              <div key={index}>
                <ReviewSubmitFormLine
                  name={document?.type && documentTypes.find((item) => item[0] === document?.type)[1]}
                  value={document?.documentId === EMPTY_GUID ? `<span>Reason for exclusion:<br />${document?.description ? document?.description : ''}</span>` : document?.description}
                  downloadId={document?.documentId !== EMPTY_GUID ? document?.documentId : false}
                />
              </div>
            );
          })
        )
      })}
    </>
  );
};
export default ReviewSubmitFormDocumentLines;

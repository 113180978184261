import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { NextIcon, PrevIcon } from "../../../util/helpers";
import { useTabValidStatus } from "../../Forms/FormikForm";
import f1040 from "../../Images/1040.png";
import f1065 from "../../Images/1065.png";
import f1120 from "../../Images/1120.png";
import ProgressBar from "../components/ProgressBar";
import SectionCompleteForm from "./sections/SectionCompleteForm";
import TaxReturnsForm from "./sections/TaxReturnsForm";
// import f1120s from "../../Images/1120s.png"
// import f8825 from "../../Images/8825.png"

const FinancialInformationFormInternal = ({ appBag, formikBag }) => {
  const { values, errors, isValid, validateForm, initialValues } = formikBag;
  const { onTabChange, tabs, title, tabKey } = appBag;
  let tab = tabs.tabs[tabKey];
  useTabValidStatus(tabs.tabKeyActive, validateForm);

  const [showImage, setShowImage] = useState(false);

  const handleCloseImage = () => setShowImage(false);
  const handleShowImage = () => setShowImage(true);

  const ownershipInfo = [
    {
      type: "SoleProprietorship",
      title: "Sole Proprietorship",
      desc: "SCHEDULE C (FORM 1040), SECTIONA, LINE3",
      file: f1040,
    },
    {
      type: "Partnership",
      title: "Partnership",
      desc: "FORM 1065, LINE 1C",
      file: f1065,
    },
    {
      type: "Corporation",
      title: "Corporation",
      desc: "FORM 1120 LINE 1C",
      file: f1120,
    },
    {
      type: "LLC",
      title: "Limited Liability Company",
      desc: "FORM 1065 OR 1120, LINE 1C",
      file: f1065,
    },
    {
      type: "LLP",
      title: "Limited Liability Partnership",
      desc: "FORM 1065, LINE 1C",
      file: f1040,
    },
  ];

  const ownershipObj = ownershipInfo.find((item) => item.type === values.ownershipType);

  return (
    <>
      <Carousel
        activeIndex={tab.activeSlide}
        wrap={false}
        prevIcon={PrevIcon}
        nextIcon={NextIcon}
        interval={null}
        fade={false}
        keyboard={false}
        slide={false}
        onSelect={(activeSlide) => onTabChange(tabKey, { activeSlide }, false)}
      >
        <Carousel.Item>
          <div className="form-tab-intro">
            <h2 id="financial-section-heading" className="carousel-section-heading">
              Gross Annual Receipts
            </h2>
          </div>
          <p>
            Below you will provide the Gross Annual Receipts Less Returns and Allowances from your business’s federal income tax return. You will need this figure from the three more recently
            completed tax years or for the total years in business (if less than three).
          </p>

          <p>
            Where to find this information:
            <br />
            Based on the business entity that you provided, you can find your Gross Annual Receipts Less Returns on the following tax form and line. To view an example of the form and where the
            information can be located, click the blue question mark.
          </p>

          {ownershipObj && (
            <div className="bg-white mb-3 py-1">
              <div className="tip row ">
                <div className="col-md-4">
                  <p className="mb-0 pl-2">{ownershipObj?.title}</p>
                </div>
                <div className="col-md-8">
                  <button type="button" className="faux-link" onClick={handleShowImage}>
                    {" "}
                    {ownershipObj?.desc}
                    <FontAwesomeIcon className="ml-2 text-secondary" icon={"question-circle"} size="1x" />
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="field-group">
            <TaxReturnsForm name={"taxReturns"} values={values} yearNumber={1} formikBag={formikBag} />
            <TaxReturnsForm name={"taxReturns"} values={values} yearNumber={2} formikBag={formikBag} />
            <TaxReturnsForm name={"taxReturns"} values={values} yearNumber={3} formikBag={formikBag} />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <SectionCompleteForm errors={errors} onTabChange={onTabChange} tabs={tabs} tabKey={tabKey} />
        </Carousel.Item>
      </Carousel>
      <ProgressBar tab={tab} title={title} slideCount={2} />

      <Modal show={showImage} size="lg" onHide={handleCloseImage}>
        <Modal.Header closeButton>{/* <Modal.Title>Modal heading</Modal.Title> */}</Modal.Header>
        <Modal.Body>
          <img src={ownershipObj?.file} alt={ownershipObj?.title} className="w-100" />
        </Modal.Body>
      </Modal>
    </>
  );
};

// const FinancialInformationForm = withFormikComponent(FinancialInformationFormInternal, schemas.financialInformationSchema);
export default FinancialInformationFormInternal;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const BigButton = (props) => {
  const { heading, content, icon, id, route, ctaText, iconColor, onClick } = props;
  const ButtonType = route ? Link : Button;
  // console.log(onClick);

  return (
    <div className=" col-lg-6 col-xl-4 d-flex flex-column">
      <ButtonType className="btn btn-light btn-huge flex-grow-1" id={id} to={route} onClick={onClick ? onClick : () => {}}>
        <div>{heading ? <p className="heading">{heading}</p> : null}</div>
        <div>
          <FontAwesomeIcon icon={icon} size="3x" className={`text-${iconColor} mb-3 mr-2`} />
        </div>
        <div className="small">
          {content ? <p className="content font-weight-light mb-0" dangerouslySetInnerHTML={{ __html: content }}></p> : null}
          {ctaText ? (
            <p className="cta-text font-weight-normal">
              {ctaText} <FontAwesomeIcon className="" icon={"arrow-right"} size="xs" />
            </p>
          ) : null}
        </div>
      </ButtonType>
    </div>
  );
};

export default BigButton;

import React from "react";
import { createTabNavLinks } from "../../../util/helpers";

const AgencyReportingNav = () => {
  const items = [
    ["/agency-reporting/activity-report", "Quarterly Activity Reports", null, null, false],
    ["/agency-reporting/goal-plan", "Yearly Goal Plans"],
    ["/agency-reporting/narrative-statement", "Narrative Statements"],
  ];

  return (
    <div className="agency-reporting-navigation mb-5">
      <nav id={"agency-reporting-nav"} className="nav nav-tabs">
        {createTabNavLinks(items)}
      </nav>
    </div>
  );
};

export default AgencyReportingNav;

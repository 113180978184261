import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FastField, Field, FieldArray, Form } from "formik";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import get from "lodash/get";
import DatePicker from "react-date-picker";
import { schemas } from "../../../util/errorHelper";
import { handleDocumentClick } from "../../../util/helpers";
import DebugModal from "../../Debug/DebugModal";
import { withFormikFormComponent } from "../../Forms/FormikForm";
import InputComponent from "../../Forms/Input/InputComponent";
import ReactiveButton from "../../Forms/Input/ReactiveButton";

function PaymentCreateFormInternal(props) {
  const { formikBag, awardedPlan, paymentId, setInitialPaymentValues } = props;
  const [formDisplayed, setFormDisplayed] = useState(!!paymentId);
  const { values, setFieldValue, isSubmitting, isValid } = formikBag;
  const { documents } = values;
  const [resetFileInput, setResetFileInput] = useState(false);
  let contractors = awardedPlan?.subcontracts?.length
    ? awardedPlan?.subcontracts?.map((item) => {
        return [item.contractor.id, item.contractor.name];
      })
    : [];
  contractors.sort((a, b) => a[1].localeCompare(b[1]));

  contractors.unshift([null, "Choose Contractor"]);
  // console.log(JSON.stringify(values?.paidDate));

  return (
    <Form className="mb-4">
      {formDisplayed && (
        <div className="bg-light p-5">
          <DebugModal formikBag={formikBag} name="Formik Bag" />
          <div className="field-group">
            <div className="row">
              <div className="col-12">
                New Payment
                <FontAwesomeIcon
                  icon={["fas", "minus-circle"]}
                  className="ml-2"
                  onClick={() => {
                    setFormDisplayed(false);
                  }}
                />
              </div>
            </div>

            <div className="row mb-4">
              <FastField wrapperClass="col-md-6" type="select" name={`contractorId`} component={InputComponent} label="Payee" options={contractors} isdisabled={!!paymentId}></FastField>

              <FastField wrapperClass="col-md-6" type="number" name={`amount`} component={InputComponent} label="Amount"></FastField>

              <FastField
                wrapperClass="col-md-9"
                type="datepicker"
                label="Paid Date"
                name={`paidDate`}
                maxDate={new Date()}
                monthPlaceholder={"MM"}
                dayPlaceholder={"DD"}
                yearPlaceholder={"YYYY"}
                component={InputComponent}
              ></FastField>
              {/* <div className="col-md-7">
                <DatePicker
                  label="date"
                  onChange={(value) => setFieldValue(`paidDate`, value)}
                  value={get(values, `paidDate`)}
                  maxDate={new Date()}
                  monthPlaceholder={"MM"}
                  dayPlaceholder={"DD"}
                  yearPlaceholder={"YYYY"}
                  minDate={new Date("2000-01-01T00:00:00.000Z")}
                />
              </div> */}
            </div>
            <div className="row mb-4">
              <FieldArray
                id={`documents`}
                name={`documents`}
                render={(arrayHelpers) => {
                  return (
                    <div className="col-sm-6 col-md-4 col-xl-3">
                      {resetFileInput ? (
                        setResetFileInput(false)
                      ) : (
                        <Field
                          name={`temp_field`}
                          wrapperClass=""
                          // label={<FontAwesomeIcon className="" icon={['fas', 'paperclip']} size="lg" />}
                          label={`Upload Document`}
                          type="fileonly"
                          component={InputComponent}
                          onFileUpload={(document) => {
                            arrayHelpers.push({ ...document });
                          }}
                        />
                      )}
                      {documents?.map((document, index) => {
                        return (
                          <div key={index}>
                            <div className="faux-document document-sm ">
                              <FontAwesomeIcon
                                className="mr-3 text-secondary"
                                icon={["fas", "times"]}
                                size="sm"
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                  setFieldValue("temp_field", null);
                                  setResetFileInput(true);
                                }}
                                data-target={"documents"}
                              />
                              <span className="faux-link inline" onClick={() => handleDocumentClick(document?.documentId)}>
                                {document.description}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
              />
            </div>
            <div className="row mb-4">
              <div className="col-md-3">
                <ReactiveButton type="submit" className="small btn-secondary margin-auto" label={"Submit"} processing={isSubmitting} disabled={!isValid} />
              </div>
            </div>
          </div>
        </div>
      )}
      {formDisplayed ? null : (
        <div className="mt-4">
          <Button
            onClick={() => {
              // history.push(`/procurements/${procurementId}/payments`)
              setInitialPaymentValues({});
              setFormDisplayed(true);
            }}
            type="button"
            variant="light"
          >
            <FontAwesomeIcon className="mr-3" icon={["fas", "plus"]} /> Add payment
          </Button>
        </div>
      )}
    </Form>
  );
}

const PaymentCreateForm = withFormikFormComponent(PaymentCreateFormInternal, schemas.addPaymentSchema);
export default PaymentCreateForm;

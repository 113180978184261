import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import ReportSearchForm from "./ReportSearchForm";
import ReportSearchList from "./ReportSearchList";
import {
  getActivityReportsDivision,
  getGoalPlansDivision,
  getNarrativeStatementsDivision,
  getAssignedActivityReportsDivisionUser,
  getAssignedGoalPlansDivisionUser,
  getAssignedNarrativeStatementsDivisionUser,
  getDivisionUsers,
} from "../../../../util/api";
import camelCase from "lodash/camelCase";
import { loadUserRole, roles, getUserFullName } from "../../../../util/userHelper";

const ReportSearch = ({ reportSearchValues, setReportSearchValues }) => {
  // console.log("report search");
  const { reportType, reportId } = useParams();
  // console.log(reportType);
  const [reports, setReports] = useState(null);
  const [initialValues, setInitialValues] = useState(reportSearchValues);

  // used to reset search list after division user is set.
  // const [searchValues, setSearchValues] = useState(null);
  const [refreshListCount, setRefreshListCount] = useState(0);

  const [divisionUsers, setDivisionUsers] = useState([]);
  const [divisionUser, setDivisionUser] = useState({});

  useEffect(() => {
    const loadDivisionUsers = async () => await getDivisionUsers();

    loadDivisionUsers().then(({ users }) => {
      const userOptions = users.map((user) => makeDivisionUserOption(user)).sort((x, y) => x.label.localeCompare(y.label));
      setDivisionUsers([{ label: "Unassigned", filterLabel: "Unassigned", id: "" }, ...userOptions]);
    });

    return () => setDivisionUsers([]);
  }, []);

  const makeDivisionUserOption = (user) => {
    return {
      ...user,
      label: `${user.userId} - ${user.firstName} ${user.lastName}`,
      filterLabel: getUserFullName(user),
    };
  };

  // for division user
  const getAssignedReports = async () => {
    // console.log("getting aswigned reports");
    let resObj;
    switch (reportType) {
      case "activity-report":
        resObj = await getAssignedActivityReportsDivisionUser();
        break;
      case "goal-plan":
        resObj = await getAssignedGoalPlansDivisionUser();
        break;
      case "narrative-statement":
        resObj = await getAssignedNarrativeStatementsDivisionUser();
        break;
      default:
        console.log("report type error");
        break;
    }

    setReports(resObj.data[`${camelCase(reportType)}s`]);
  };

  // if division user, auto populate assigned reports
  useEffect(() => {
    if (loadUserRole() === roles.DivisionUser) {
      getAssignedReports();
    }
  }, [refreshListCount, reportType]);

  useEffect(() => {
    if (reportSearchValues?.fiscalYear && reportSearchValues?.fiscalQuarter && reportSearchValues?.status && loadUserRole() === roles.DivisionAdmin) {
      handleSubmit(reportSearchValues);
    }
  }, [refreshListCount, reportType]);

  // handle search submit for division admin
  const handleSubmit = async (values) => {
    console.log(values);
    const { fiscalYear, fiscalQuarter, status } = values;
    // setReportSearchValues({ fiscalYear, fiscalQuarter, status });

    let resObj;
    switch (reportType) {
      case "activity-report":
        resObj = await getActivityReportsDivision(fiscalYear, fiscalQuarter, status);
        break;
      case "goal-plan":
        resObj = await getGoalPlansDivision(fiscalYear, status);
        break;
      case "narrative-statement":
        resObj = await getNarrativeStatementsDivision(fiscalYear, status);
        break;
      default:
        console.log("report type error");
        break;
    }

    setReports(resObj.data[`${camelCase(reportType)}s`]);

    // persist form values
    localStorage.setItem("agencyReportSearch", JSON.stringify({ fiscalQuarter, fiscalYear, status }));
    setInitialValues({ fiscalQuarter, fiscalYear, status });
    setReportSearchValues({ fiscalQuarter, fiscalYear, status });
  };

  return (
    <>
      {loadUserRole() === roles.DivisionAdmin ? (
        <ReportSearchForm reportType={reportType} routeInitialValues={initialValues} validateOnMount={true} validateOnChange={true} onSubmit={handleSubmit} />
      ) : null}

      {reports?.length === 0 ? (
        <div className="alert alert-primary">
          <p>No results, please try again.</p>
        </div>
      ) : null}

      {reports?.length > 0 ? (
        <ReportSearchList
          divisionUsers={divisionUsers}
          divisionUser={divisionUser}
          setDivisionUser={setDivisionUser}
          reports={reports}
          reportType={reportType}
          refreshListCount={refreshListCount}
          setRefreshListCount={setRefreshListCount}
        />
      ) : null}
    </>
  );
};

export default ReportSearch;

import React from "react";
import { getPlanType, getLocalDate } from "../../../../util/helpers";

const Plan = (props) => {
  const { planToLoad, version, procurement } = props;
  const planType = getPlanType(procurement?.goal, planToLoad?.goal);

  return (
    <div>
      <div className="row mb-2">
        <div className="col-sm-5">Plan Version</div>
        <div className="col-sm-7">{version?.toString()}</div>
      </div>
      <div className="row mb-2">
        <div className="col-sm-5">Revision Date</div>
        <div className="col-sm-7">{getLocalDate(planToLoad?.created)}</div>
      </div>
      <div className="row mb-2">
        <div className="col-sm-5">Solicitation #</div>
        <div className="col-sm-7">{procurement?.solicitationNumber}</div>
      </div>
      <div className="row mb-2">
        <div className="col-sm-5">Contract #</div>
        <div className="col-sm-7">{procurement?.contractNumber}</div>
      </div>
      <div className="row mb-2">
        <div className="col-sm-5">Agency SDVOB Goal %</div>
        <div className="col-sm-7">{procurement?.goal}%</div>
      </div>
      <div className="row mb-2">
        <div className="col-sm-5">Plan Types</div>
        <div className="col-sm-7">{planType}</div>
      </div>
      <div className="row mb-2">
        <div className="col-sm-5">Plan Status</div>
        <div className="col-sm-7">{planToLoad?.status}</div>
      </div>
      {planToLoad?.waiverDescription && (
        <div className="info-block row mb-2">
          <div className="col-5">Waiver Description</div>
          <div className="col-7">{planToLoad?.waiverDescription}</div>
        </div>
      )}
      {planToLoad?.message && (
        <div className="info-block row mb-2">
          <div className="col-5">Deficiency Message</div>
          <div className="col-7">{planToLoad?.message}</div>
        </div>
      )}
      {planToLoad?.planType !== "NoWaiver" ? (
        <div className="info-block row mb-2">
          <div className="col-5">Supporting Documentation</div>
          <div className="col-7">
            {planToLoad?.documents?.length ? (
              <>
                {planToLoad?.documents?.map((document, index) => {
                  return (
                    <div key={`plan-${planToLoad?.id}-document-${index}`}>
                      <a href={`/documents/${document.id}`} className="text-secondary">
                        {document.description}
                      </a>
                    </div>
                  );
                })}
              </>
            ) : (
              <p>No supporting documentation provided</p>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Plan;

import { FastField } from "formik";
import _ from "lodash";
import React from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Carousel from "react-bootstrap/Carousel";
import { getArrayIndex, hasDocumentType, NextIcon, PrevIcon } from "../../../util/helpers";
import categories from "../../../util/sourceData/categories";
import counties from "../../../util/sourceData/counties";
import naicsCodes from "../../../util/sourceData/naicsCodes";
import { businessTypes, documentTypes, ownershipTypes, VetsFirstCertification, YES_NO_RADIO } from "../../../util/typelistHelper";
import DebugModal from "../../Debug/DebugModal";
import { useTabValidStatus } from "../../Forms/FormikForm";
import ArrayWrapperDynamic from "../../Forms/helpers/ArrayWrapperDynamic";
import DocumentWrapper from "../../Forms/helpers/DocumentWrapper";
import InputComponent from "../../Forms/Input/InputComponent";
import ProgressBar from "../components/ProgressBar";
import AddressForm from "./sections/AddressForm";
import ContactInformationSection from "./sections/ContactInformationSection";
import DbaForm from "./sections/DbaForm";
import SectionCompleteForm from "./sections/SectionCompleteForm";

const BusinessInformationFormInternal = ({ name, appBag, formikBag }) => {
  const { values, errors, validateForm, setFieldValue } = formikBag;
  const { onTabChange, tabs, title, tabKey } = appBag;
  let tab = tabs.tabs[tabKey];
  useTabValidStatus(tabs.tabKeyActive, validateForm);

  const getArrayIndexWrapper = (type, arrayName) => {
    const elements = _.get(values, arrayName);
    const index = getArrayIndex(elements, type);
    return `${arrayName}.${index}`;
  };

  // useEffect(() => {
  //   hydrateArrays(`addresses`, values.addresses, { line1: '', city: '', state: '', zipCode: '' }, formikBag.setFieldValue)
  // }, [, values?.addresses?.length])

  return (
    <div>
      <DebugModal formikBag={formikBag} name="Formik Bag" />
      {tab && (
        <Carousel
          activeIndex={tab.activeSlide}
          wrap={false}
          prevIcon={PrevIcon}
          nextIcon={NextIcon}
          interval={null}
          fade={false}
          keyboard={false}
          slide={false}
          onSelect={(activeSlide) => onTabChange(tabKey, { activeSlide }, false)}
        >
          <Carousel.Item>
            <div className="form-tab-intro">
              <h2 id="business-identification-section-heading" className="carousel-section-heading">
                Business Identification
              </h2>
            </div>
            <div className="field-group row">
              <FastField
                wrapperClass="col-12"
                label="Legal Business Name (Please enter name EXACTLY as it appears on official IRS documentation)"
                type="text"
                name={`legalName`}
                placeholder=""
                component={InputComponent}
              />

              <FastField wrapperClass="col-12" label="Federal Employer Identification Number (FEIN)" type="inputFEIN" name={`federalId`} placeholder="" component={InputComponent} />
            </div>
            <div className="field-group dba-names">
              <p>Doing Business As (DBA) Name(s) (If Applicable)</p>
              <ArrayWrapperDynamic name={`dbaNames`} additionLabel="Add New DBA Name" removalLabel="Remove DBA Name" component={DbaForm} dynamic={true} formikBag={formikBag} />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="form-tab-intro">
              <h2>Business Contact Information</h2>
            </div>
            <AddressForm name={`${getArrayIndexWrapper("Mailing", "addresses")}`} type="Mailing" setFieldValue={setFieldValue} />
            <AddressForm
              values={values}
              name={`${getArrayIndexWrapper("Physical", "addresses")}`}
              type="Physical"
              setFieldValue={setFieldValue}
              addressFromName={getArrayIndexWrapper("Mailing", `addresses`)}
              copyButtonType="Mailing"
            />

            <div className="field-group row">
              <ContactInformationSection showPhoneExtension={true} formikBag={formikBag} />
              <FastField
                wrapperClass="col-lg-6"
                label="Number of employees"
                subLabel="(Average Last 4 Quarters)"
                type="number"
                min="1"
                name={`totalEmployees`}
                component={InputComponent}
                placeholder=""
              />

              <FastField wrapperClass="col-md-6" label="County" type="select" name={`countyLocatedIn`} options={[["", "Choose county"], ...counties]} component={InputComponent} />
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div className="form-tab-intro">
              <h2>SDVOB Vets First Verification Program</h2>
              <p>
                If your business is verified as a Service-Disabled Veteran-Owned Small Business (SDVOSB) by the U.S. Department of Veterans Vets First Verification Program, you may be eligible for an
                expedited application process with less required documentation.
              </p>
            </div>
            <div className="field-group">
              <div className="row">
                <FastField
                  wrapperClass="col-lg-12"
                  label="Is the business certified by the U.S. Department of Veterans Affairs Vets First Verification Program (CVE)?"
                  type="radio"
                  name={`isCertifiedVetsFirst`}
                  component={InputComponent}
                  options={YES_NO_RADIO}
                  placeholder=""
                />
              </div>

              {values.isCertifiedVetsFirst && (
                <DocumentWrapper
                  label="Proof of U.S. Department of Veterans Affairs Vets First Verification (CVE) program Certification"
                  documentType={`VetsFirstCertification`}
                  name={`${getArrayIndexWrapper("VetsFirstCertification", "documents")}`}
                  formikBag={formikBag}
                  component={InputComponent}
                  optional={true}
                />
              )}

              <FastField
                wrapperClass=""
                label="If you have been Certified as a Service-Disabled Veteran-Owned Business by an entity other than New York State or the U.S. Department of Veterans Affairs, please provide those entities below"
                type="text"
                name={`otherCertifyingEntities`}
                placeholder=""
                required={false}
                component={InputComponent}
              />
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div className="form-tab-intro">
              <h2>Business Description</h2>
            </div>
            <FastField
              wrapperClass="mb-4"
              label="Please provide a brief description of your business. e.g. Commercial and Residential Landscaping, Glass and Glazing Contractor, IT Design and Consulting, etc.."
              type="textarea"
              name={`businessDescription`}
              placeholder=""
              component={InputComponent}
            />

            <hr />

            <div className="form-tab-intro">
              <h2>Ownership Type</h2>
            </div>

            <FastField
              wrapperClass="mb-4"
              childClass="col-md-6"
              label="Check the business entity or structure that best fits your company."
              type="radio"
              name={`ownershipType`}
              component={InputComponent}
              options={Object.keys(ownershipTypes).map((ownershipTypeKey) => {
                const ownershipType = ownershipTypes[ownershipTypeKey];
                return [ownershipTypeKey, ownershipType.label, ownershipType?.subLabel];
              })}
              onChange={() => {
                if (values.ownershipType !== "Corporation") {
                  setFieldValue("isOwnersResponsibleForEquipment");
                  setFieldValue("isOwnersResponsibleForMaterials");
                  setFieldValue("isOwnersResponsibleForFacilities");
                  setFieldValue("isOwnersReceiveDividendsMajority");
                  setFieldValue("isOwnersControlBoardMajority");
                  setFieldValue("isOwnersEntitledFullStockShareValue");
                }
              }}
              placeholder=""
              // onChange={() => {
              //   const ownershipTypeDocs = getOwnershipTypeDocs(values.ownershipType);
              //   const documents = values.documents.filter(document1 => !ownershipTypeDocs.includes(document1.type))
              //   setFieldValue('documents', documents);
              // }}
            />

            {values.ownershipType === "SoleProprietorship" ? (
              <p className="text-danger">
                Note – A single member LLC should check Limited Liability Company, NOT sole proprietorship. Please only select this option if you are certain that you are a sole proprietorship.
              </p>
            ) : null}

            <>
              <div className="form-tab-intro">
                <h3 className="text-dark text-black">Required Supporting Documentation</h3>
              </div>
              <p>Based on the information you provided, the following documentation is required for the certification process. Please upload the following documents.</p>

              {/* <DocumentWrapper
                label="U.S. Veterans Administration documentation of service-connected disability rating which must be dated within one year of the date DSDVBD receives your SDVOB Certification application and must demonstrate a service-connected disability rating of at least 10%"
                documentType={`VeteransAdministrationServiceConnectedDisabilityRating`}
                name={`${getArrayIndexWrapper('VeteransAdministrationServiceConnectedDisabilityRating', 'documents')}`}
                formikBag={formikBag}
                component={InputComponent}
                optional={true}
              /> */}
              <DocumentWrapper
                wrapperClassDocumentWrapper
                label="Official Internal Revenue Service (IRS) documentation verifying the firm's Federal Employer Identification Number (FEIN) or Social Security Number (SSN) listed on the application"
                documentType={`FederalIdOrSocialSecurityNumberVerification`}
                name={`${getArrayIndexWrapper("FederalIdOrSocialSecurityNumberVerification", "documents")}`}
                formikBag={formikBag}
                component={InputComponent}
                optional={true}
              />
              <DocumentWrapper
                wrapperClassDocumentWrapper
                label="Business License, if applicable"
                documentType={`BusinessLicense`}
                name={`${getArrayIndexWrapper("BusinessLicense", "documents")}`}
                formikBag={formikBag}
                component={InputComponent}
                optional={true}
              />

              {!(values?.isCertifiedVetsFirst && hasDocumentType(VetsFirstCertification, values?.documents)) &&
                values.ownershipType &&
                ownershipTypes[values.ownershipType]?.documentTypes.map((documentType, index) => {
                  const label = documentTypes.find((item) => item[0] === documentType)[1];
                  return <DocumentWrapper key={index} label={label} documentType={documentType} name={`${getArrayIndexWrapper(documentType, "documents")}`} formikBag={formikBag} optional={true} />;
                })}
            </>
          </Carousel.Item>

          <Carousel.Item>
            <div className="form-tab-intro">
              <h2>Business Type</h2>
            </div>
            <FastField
              wrapperClass="col-lg-6"
              label="Which of the following best describes your type of business?"
              type="checkbox"
              name={`serviceTypes`}
              options={businessTypes}
              placeholder=""
              component={InputComponent}
              multiple={true}
            />
          </Carousel.Item>

          <Carousel.Item>
            <div className="form-tab-intro">
              <h2>Other Certifications</h2>
            </div>
            <FastField
              wrapperClass="col-lg-6"
              label="Is the business currently certified as a Minority-Owned Business (MBE)?"
              type="radio"
              name={`isCertifiedMinorityOwned`}
              component={InputComponent}
              options={YES_NO_RADIO}
              placeholder=""
            />

            <FastField
              wrapperClass="col-lg-6"
              label="Is the business currently certified as a Women-Owned Business (WBE)?"
              type="radio"
              name={`isCertifiedWomenOwned`}
              options={YES_NO_RADIO}
              placeholder=""
              component={InputComponent}
            />

            {/* {values.isCertifiedWomenOwned || values.isCertifiedMinorityOwned ? ( */}
            {/* {true ? (
              <Field
                wrapperClass="col-md-7"
                label="Please provide the certifying entities of your MBE or WBE status."
                type="text"
                name={`otherCertifyingEntities`}
                placeholder=""
                component={InputComponent}
              />
            ) : null} */}
          </Carousel.Item>

          <Carousel.Item>
            <div className="form-tab-intro">
              <h2>New York Service Areas</h2>
            </div>
            <p>Please indicate the areas where your business can provide its goods or services in NYS. You can select individual counties, or statewide for all counties.</p>
            <p>Upon certification, this information will appear in your business profile on the Directory of New York State Certified Service-Disabled Veteran-Owned Businesses.</p>

            <FastField
              wrapperClass=""
              childClass="col-6 col-md-4"
              type="checkbox"
              label="Does your business provide goods or services statewide?"
              name={`isStatewide`}
              options={[[true, "Yes"]]}
              component={InputComponent}
              required={false}
            />

            {values.isStatewide !== true ? (
              <FastField
                wrapperClass=""
                childClass="col-6 col-md-4"
                label="Counties served"
                type="checkbox"
                name={`counties`}
                multiple={true}
                options={counties}
                disabled={values?.statewide}
                component={InputComponent}
              />
            ) : null}
          </Carousel.Item>

          <Carousel.Item>
            <div className="form-tab-intro">
              <h2>Business Classification Codes and Keywords</h2>
              <p>
                In this section, you will provide NAICS codes and keywords that help describe what your business does. Upon certification, this information will appear in your business profile on the
                Directory of New York State Certified Service-Disabled Veteran-Owned Businesses.
              </p>
            </div>

            <div className="">
              <FastField
                label="Enter categories that best describe your business."
                allowNew={false}
                filterBy={["code"]}
                name={`categories`}
                options={categories}
                multiple
                type="typeahead"
                shouldSelectHint
                placeholder="Choose a category..."
                // isTypeaheadASimpleArray={false}
                component={InputComponent}
              />
              {/* <FastField
              allowNew
              name={`categories`}
              options={categories}
              multiple
              type="typeahead"
              placeholder="Choose a category..."
              component={InputComponent}
            /> */}
            </div>

            <div className="">
              <p>
                Enter NAICS codes that best describe your products and/or services. You can look up codes using the{" "}
                <a href="https://naics.com/search" target="_blank" rel="noopener noreferrer">
                  NAICS Search
                </a>{" "}
                tool.
              </p>
              <FastField
                // label="Enter up to six keywords or short phrases that best describe your business."
                filterBy={["code"]}
                name={`naicsCodes`}
                options={naicsCodes}
                multiple
                placeholder="Choose a code..."
                type="typeahead"
                isTypeheadCodeAnInteger={true}
                component={InputComponent}
              />
            </div>
            <div className="">
              <FastField
                label="Enter keywords that best describe your business."
                allowNew
                name={`tags`}
                options={appBag.tags}
                multiple
                type="typeahead"
                uniqueSelect={true}
                placeholder="Choose a keyword..."
                isTypeaheadASimpleArray={true}
                component={InputComponent}
              />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="form-tab-intro">
              <h2>Equipment Ownership</h2>
            </div>
            <FastField
              wrapperClass="col-lg-6"
              label="Will the applicant business rent equipment to the state?"
              type="radio"
              name={`isEquipmentRenter`}
              options={YES_NO_RADIO}
              placeholder=""
              component={InputComponent}
              onChange={() => {
                if (values.isEquipmentRenter === true) {
                  setFieldValue("isEquipmentOwner");
                }
                // if value is false
                // then setFieldValue('isEquipmentRenter')
              }}
            />
            {values.isEquipmentRenter === true ? (
              <FastField
                wrapperClass="col-lg-6"
                label="Does the disabled veteran owner own at least 51% of the quantity and value of each piece of equipment that will be provided under a contract?"
                type="radio"
                name={`isEquipmentOwner`}
                options={YES_NO_RADIO}
                placeholder=""
                component={InputComponent}
              />
            ) : null}
          </Carousel.Item>
          <Carousel.Item>
            <SectionCompleteForm errors={errors} onTabChange={onTabChange} tabs={tabs} tabKey={tabKey} title={title} />
          </Carousel.Item>
        </Carousel>
      )}
      <ProgressBar tab={tab} title={title} slideCount={10} />
    </div>
  );
};
// export default BusinessInformationForm;
// const BusinessInformationForm = withFormikComponent(BusinessInformationFormInternal, schemas.businessInformationSchema);
export default BusinessInformationFormInternal;

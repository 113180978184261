import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useParams, useLocation } from "react-router-dom";
import { roles, loadUserRole, loadActiveOrgId } from "../../util/userHelper";
import { goalPlanDocumentTypes } from "../../util/typelistHelper";
import AgencyReportingNav from "./Shared/AgencyReportingNav";
import ReportView from "./Shared/ReportView";
import ReportSearch from "./AgencyReviews/Reports/ReportSearch";

import ActivityReportDisplayData from "./ActivityReports/ActivityReportDisplayData";
import ActivityReportSubmitForm from "./ActivityReports/ActivityReportSubmitForm";
import ActivityReportList from "./ActivityReports/ActivityReportList";

import NarrativeStatementDisplayData from "./NarrativeStatements/NarrativeStatementDisplayData";
import NarrativeStatementSubmitForm from "./NarrativeStatements/NarrativeStatementSubmitForm";
import NarrativeStatementList from "./NarrativeStatements/NarrativeStatementList";

import GoalPlanDisplayData from "./GoalPlans/GoalPlanDisplayData";
import GoalPlanSubmitForm from "./GoalPlans/GoalPlanSubmitForm";
import GoalPlanList from "./GoalPlans/GoalPlanList";
// import ActivityReportSubmitForm from "./ActivityReports/ActivityReportSubmitForm"
// import ActivityReportSubmitForm from "./ActivityReports/ActivityReportSubmitForm"
import { getActivityReport, getActivityReports, getGoalPlan, getGoalPlans, getNarrativeStatement, getNarrativeStatementsByAgency } from "../../util/api";

// This is for the AGENCY view of the Agency Reports
// Down the tree, there will be some components and logic shared with division views but they are largely separate
// Everything in this file is directly pertaining to Agencies interacting with these reports

const AgencyReporting = (props) => {
  const { reportType, reportId } = useParams();
  const [reportList, setReportList] = useState({ "activity-report": null, "goal-plan": null, "narrative-statement": null });
  const [report, setReport] = useState(null);
  const [updateCount, setUpdateCount] = useState(0);
  const [hasSaved, setHasSaved] = useState(null);
  const [showForm, setShowForm] = useState(report?.status === "Deficient" ? true : false);
  const { location } = useLocation();
  const neatName = _.startCase(reportType);
  const isAgency = [roles.AgencyUser, roles.AgencyAdmin].includes(loadUserRole());
  const [reportSearchValues, setReportSearchValues] = useState(
    localStorage.getItem("agencyReportSearch") ? JSON.parse(localStorage.getItem("agencyReportSearch")) : { fiscalQuarter: 1, fiscalYear: null, status: "Created" }
  );

  const reportSchema = {
    "activity-report": {
      getSingle: getActivityReport,
      getAll: getActivityReports,
      responseKey: "activityReports",
      displayComponent: ActivityReportDisplayData,
      formComponent: ActivityReportSubmitForm,
      listComponent: ActivityReportList,
      initialValues: {
        agencyId: loadActiveOrgId(),
        fiscalYear: report ? report.fiscalYear : "",
        fiscalQuarter: report ? report.fiscalQuarter : 1,
        activityReportId: reportId,
        totalNumberOfAwards: report?.totalNumberOfAwards ? report.totalNumberOfAwards : 0,
        totalValueOfAwards: report?.totalValueOfAwards ? report.totalValueOfAwards : 0,
        totalDisbursementsToSdvobs: report?.totalDisbursementsToSdvobs ? report.totalDisbursementsToSdvobs : 0,
        nonExemptNonExcludedPaymentTotal: report?.nonExemptNonExcludedPaymentTotal ? report.nonExemptNonExcludedPaymentTotal : 0,
        utilizationPercentage: report ? (report.totalDisbursementsToSdvobs + report.totalAdditionalSpendOnSdvobs) / (report.nonExemptNonExcludedPaymentTotal + report.totalAdditionalSpendOnSdvobs) : 0,
        totalNumberOfWaiversGranted: report?.totalNumberOfWaiversGranted ? report.totalNumberOfWaiversGranted : 0,
        totalAdditionalSpendOnSdvobs: report?.totalAdditionalSpendOnSdvobs ? report.totalAdditionalSpendOnSdvobs : 0,
        totalContractsValue: report?.totalContractsValue ? report.totalContractsValue : 0,
      },
    },
    "goal-plan": {
      getSingle: getGoalPlan,
      getAll: getGoalPlans,
      responseKey: "goalPlans",
      displayComponent: GoalPlanDisplayData,
      formComponent: GoalPlanSubmitForm,
      listComponent: GoalPlanList,
      initialValues: {
        agencyId: loadActiveOrgId(),
        goalPlanId: reportId,
        totalAwards: report ? report.totalAwards : "",
        sdvobSetAsides: report ? report.sdvobSetAsides : "",
        discretionaries: report ? report.discretionaries : "",
        annualBudget: report ? report.annualBudget : "",
        budgetMinusExclusions: report ? report.budgetMinusExclusions : "",
        projectedSDVOBSpendingAmount: report ? report.projectedSDVOBSpendingAmount : "",
        numWaivers: report ? report.numWaivers : "",
        documents: report ? report.documents : [],
        specificDocuments: goalPlanDocumentTypes.map((docType) => {
          return report?.specificDocuments?.find((item) => item.type === docType) ? report?.specificDocuments?.find((item) => item.type === docType) : { type: docType };
        }),
        fiscalYear: report ? report.fiscalYear : "",
        fiscalYearEnd: report ? report.fiscalYearEnd : "",
        sdvobWebsiteContentUrl: report ? report.sdvobWebsiteContentUrl : "",
      },
    },
    "narrative-statement": {
      getSingle: getNarrativeStatement,
      getAll: getNarrativeStatementsByAgency,
      responseKey: "narrativeStatements",
      displayComponent: NarrativeStatementDisplayData,
      formComponent: NarrativeStatementSubmitForm,
      listComponent: NarrativeStatementList,
      initialValues: {
        agencyId: loadActiveOrgId(),
        narrativeStatementId: reportId,
        fiscalYear: report ? report.fiscalYear : "",
        statementText: report ? report.statementText : "",
        commissionerName: report ? report.commissionerName : "",
        chiefCounselName: report ? report.chiefCounselName : "",
        chiefFinancialOfficerName: report ? report.chiefFinancialOfficerName : "",
        primarySDVOBComplianceContactName: report ? report.primarySDVOBComplianceContactName : "",
        primarySDVOBComplianceContactEmailAddress: report ? report.primarySDVOBComplianceContactEmailAddress : "",
      },
    },
  };

  const reportTypeData = reportSchema[reportType];
  const ReportComponent = reportTypeData.component;
  const FormComponent = reportTypeData.formComponent;
  const ListComponent = reportTypeData.listComponent;
  const DisplayComponent = reportTypeData.displayComponent;
  const initialValues = reportTypeData.initialValues;

  useEffect(() => {
    const responseKey = reportTypeData["responseKey"];

    const fetchData = async () => {
      if (reportId) {
        const resObj = await reportTypeData.getSingle(reportId);
        setReport(resObj.data);
      } else if (reportList[reportType] === null) {
        const resObj = await reportTypeData.getAll();
        let newList = { ...reportList };
        newList[reportType] = resObj.data[responseKey];
        setReportList(newList);
      }
    };

    if (reportId || isAgency) {
      fetchData();
    }
  }, [reportId, updateCount, reportType]);

  return (
    <>
      <AgencyReportingNav />

      <h2>
        {neatName}
        {report ? " for " : "s"}
        {report?.fiscalQuarter ? "Q" + report?.fiscalQuarter + " / " : ""}
        {report ? report.fiscalYear : ""}
      </h2>

      {/* Should we show the create/update form */}
      {isAgency && showForm ? (
        <>
          <FormComponent
            reportId={reportId}
            report={report}
            routeInitialValues={initialValues}
            showForm={showForm}
            setShowForm={setShowForm}
            hasSaved={hasSaved}
            setHasSaved={setHasSaved}
            updateCount={updateCount}
            setUpdateCount={setUpdateCount}
            validateOnMount={true}
          />
          <hr />
        </>
      ) : !reportId && isAgency ? (
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => {
            setShowForm(true);
          }}
        >
          Create {neatName}
        </button>
      ) : null}

      {reportId ? (
        <>
          {/* Same component for both agency and division when reviewing */}
          <ReportView
            report={report}
            reportType={reportType}
            reportTypeData={reportTypeData}
            reportId={reportId}
            updateCount={updateCount}
            setUpdateCount={setUpdateCount}
            hasSaved={hasSaved}
            setHasSaved={setHasSaved}
          />
        </>
      ) : reportList[reportType] !== null ? (
        reportList[reportType].length ? (
          <ListComponent reportList={reportList[reportType]} />
        ) : (
          <h2>No reports to show</h2>
        )
      ) : isAgency ? (
        <h2>
          <span className="loader loader-pulse">Loading...</span>
        </h2>
      ) : (
        <ReportSearch reportSearchValues={reportSearchValues} setReportSearchValues={setReportSearchValues} />
      )}
    </>
  );
};

export default AgencyReporting;

import React from "react";
import { FastField, Form } from "formik";
import { Button } from "react-bootstrap";
import InputComponent from "../../Forms/Input/InputComponent";
import ProcurementsList from "../../AgencyAdmin/ProcurementsList";
import { awardPlan } from "../../../util/api";
import { withFormikFormComponent } from "../../Forms/FormikForm";
import ReactiveButton from "../../Forms/Input/ReactiveButton";
import { outputPercentage } from "../../../util/helpers";
import DebugModal from "../../Debug/DebugModal";

const PlanReviewSubmitFormInternal = (props) => {
  const { formikBag, planStatusOptions, planToLoad, contract, procurement, procurementId, onSubmit } = props;
  const { values, isValid, isSubmitting } = formikBag;
  return (
    <>
      <div className="info-block row">
        <div className="col-5 font-weight-bold">Contract SDVOB Goal</div>
        <div className="col-7">{`${procurement?.goal}%`}</div>
      </div>
      <div className="info-block row">
        <div className="col-5 font-weight-bold">SDVOB GOALS</div>
        <div className="col-7">{outputPercentage(planToLoad?.goal / 100)}</div>
      </div>
      {procurement?.contractTerm !== "Term" && (
        <div className="info-block row">
          <div className="col-5 font-weight-bold">SDVOB $</div>
          <div className="col-7">${planToLoad?.assessedValueOfPlanToSdvobs?.toLocaleString()}</div>
        </div>
      )}
      <hr className="mb-4" />
      DETERMINATION
      <Form>
        <DebugModal formikBag={formikBag} name="Formik Bag" />
        <div className="info-block row">
          <FastField wrapperClass="col-12 mb-3" name={"status"} label="Set status" component={InputComponent} type="select" options={planStatusOptions} required={false} />

          {["deficient", "deny"].includes(values.status) && <FastField wrapperClass="col-12" name={"message"} component={InputComponent} type="textarea" label="Message" required={false}></FastField>}

          <div className="col-12">
            <ReactiveButton
              type="submit"
              className="small btn-secondary margin-auto"
              label={"Submit"}
              processing={isSubmitting}
              disabled={!isValid || ["Submitted", "false"].includes(values?.status)}
            />
            {/* <button type="submit" className="btn btn-secondary">Submit</button> */}
          </div>
        </div>
        {planToLoad.awardStatus !== "Awarded" ? (
          <Button
            type="button"
            className="btn-secondary btn-md btn-block"
            onClick={() => {
              awardPlan(procurementId, planToLoad.id);
            }}
          >
            Award Plan
          </Button>
        ) : null}
      </Form>
    </>
  );
};

const PlanReviewSubmitForm = withFormikFormComponent(PlanReviewSubmitFormInternal);
export default PlanReviewSubmitForm;

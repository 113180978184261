import React from "react";
import { matchPath, useHistory } from "react-router";
import { Route, Link, NavLink, useRouteMatch } from "react-router-dom";
import _ from "lodash";
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { documentTypes, EMPTY_GUID } from "./typelistHelper";
import { getActiveOrg, getUserId, loadActiveOrgId, loadUserRole, roles } from "./userHelper";
import { getDocumentDownloadUrl, getUser, applicationOwnerAssociationIdLocalStorageKey, returnPathLocalStorageKey } from "./api";
import { isSameRefreshToken } from "@okta/okta-auth-js";
const today = new Date();

const { history } = useHistory;

export const getContext = (context, entityContextName) => {
  return `${context}_${entityContextName}`;
};

export const getName = (context, name) => {
  return `${context}_${name}`;
};

export function trimName(str, n) {
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
}

export const NextIcon = <span aria-hidden="true" className="bg-secondary carousel-control-icon carousel-control-next-icon" id="carousel-control-next" />;

export const PrevIcon = <span aria-hidden="true" className="bg-secondary carousel-control-icon carousel-control-prev-icon" id="carousel-control-prev" />;

/**
 * bubbles a single field of data as an standard InputEvent.
 * Ex. {name: 'address', value: {line1:"My address line1"}}
 * @param InputEvent e
 * @param entityName
 */
export const buildInputChangeEventForGeneral = (e, entityName) => {
  const field = {};
  field[e.target.getAttribute("data-entity-name")] = e.target.value;
  const event = {
    name: entityName,
    value: {
      ...field,
    },
  };
  return event;
};

/**
 * To be used when creating an event for an CustomEntity.
 * @param {name: {name, value:{*}}} e
 * @param {*} entityName
 */
export const buildObjectChangeEventForGeneral = (e, entityName) => {
  const field = {};
  field[e.name] = e.value;
  const event = {
    name: entityName,
    value: {
      ...field,
    },
  };
  return event;
};

export const initArrays = (parentItem, initialValues, itemName, itemTypes, itemTypeName, userId) => {
  // console.log(initialValues);
  itemTypeName = itemTypeName || "type";
  let items = parentItem[itemName] ? parentItem[itemName] : [];
  if (userId) {
    items = items.filter((item) => item.userId === userId);
    // console.log(items);
  }

  itemTypes.forEach((itemType) => {
    const itemIndex = items.findIndex((item) => item[itemTypeName] === itemType);
    if (itemIndex === -1) {
      const item = { ...initialValues };
      item[itemTypeName] = itemType;
      items.push(item);
    } else {
      items[itemIndex] = { ...initialValues, ...items[itemIndex] };
    }
  });
  parentItem[itemName] = items;
};

// This is used to take the applicaiton object from the BE and hydrate out form
// This is primarily needed because of the arrays of objects we need to work with
export const hydrateApplication = (application) => {
  application.status = application.status ? application.status : "InProgress";
  // application.preparerId = application.preparerId ? application.preparerId : getUserId();

  const initialAddressValues = { line1: "", city: "", state: "", zipCode: "" };
  initArrays(application, initialAddressValues, "addresses", ["Mailing", "Physical"]);

  application.owners = application.owners ? application.owners : [];

  if (application.owners?.length === 0) {
    application.owners = [{ addresses: [] }];
  }

  application.owners.forEach((owner) => {
    initArrays(owner, initialAddressValues, "addresses", ["Mailing", "Home"]);
  });

  // application.owners = _.orderBy(application.owners, function (owner) { return owner.userId === getUserId() }, ['desc']);

  initArrays(
    application,
    {},
    "documents",
    documentTypes.map((documentType) => documentType[0])
  );

  const initialTaxReturnValues = { documentationExists: true };
  initArrays(application, initialTaxReturnValues, "taxReturns", [1, 2, 3], "yearNumber");
};

// Similar to the application hydration
// The arrays of documents need index-based names for Formik to associate so we hydrate this way
export const hydrateAttestation = (values, user) => {
  const { id } = user;
  const owner = values?.owners?.find((item) => item.userId == user.id);
  const { isServiceDisabled } = owner;

  const docs = isServiceDisabled ? ["Resume", "NGB22", "DD214", "DisabilityLetter"] : ["Resume"];
  // console.log(values, userId);
  initArrays(values, {}, "documents", docs, "type", id);
};

export const getTotalOwnership = (application) => {
  return application?.owners?.reduce((accumulator, owner, index) => {
    return accumulator + owner.percentageOwnership;
  }, 0);
};

// This gets the index of an item in an array by it's "type"
// This is used specifically for working with how formik stores these and associates values to inputs
export const getArrayIndex = (elements, itemType, itemTypeName) => {
  itemTypeName = itemTypeName ? itemTypeName : "type";
  const items = elements ? elements : [];
  let index = items?.findIndex((item) => item[itemTypeName] === itemType);
  return index;
};

// export const hydrateArrays = (itemName, elements, initialValues, setFieldValue) => {
//   const items = elements?.map((element) => {
//     return { ...initialValues, ...element };
//   });
//   setFieldValue(itemName, items);
// };

export const mutate = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

export const getDaysLeft = (endDate) => {
  if (endDate) {
    const today = new Date();
    const newEndDate = new Date(endDate);
    var timeDifference = newEndDate.getTime() - today.getTime();
    var daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
    return daysDifference;
  } else {
    return "TIMER NOT STARTED";
  }
};

export const outputStatus = (status) => {
  const statuses = {
    AppealDenied: "Appeal Denied",
    InProgress: "In Progress",
    Approved: "Ready for Director Review",
    FinalDenial: "Final Denial",
  };

  return statuses[status] ? statuses[status] : status;
};

export const outputPhase = (phase) => {
  const phases = {
    InitialReview: "Initial Review",
    InDepthReview: "In-Depth Review",
    DirectorReview: "Director Review",
  };

  return phases[phase] ? phases[phase] : phase;
};

export const outputVisitStatus = (phase) => {
  const phases = {
    NotNeeded: "Not Needed",
    VisitCancelled: "Visit Cancelled",
    VisitCompleted: "Visit Completed",
    VisitScheduled: "Visit Scheduled",
  };

  return phases[phase] ? phases[phase] : phase;
};

export const outputTimer = (timer) => {
  const timers = {
    AnnualCheckup: "Annual Checkup",
    AbleToAppeal: "Able To Appeal",
    AdditionalInfoNeeded: "Additional Info Needed",
    FiveYearCertification: "Five Year Certification",
    InDepthReview: "In Depth Review",
    CanReapply: "Can Reapply",
  };

  return timers[timer] ? timers[timer] : timer;
};

export const outputApplicationStatus = (application) => {
  const { isCertifiedSdvob, isActive, dateCertified } = application;
  let status = "Not Certified";

  if (isCertifiedSdvob && isActive) {
    status = "Certified";
  } else if (isCertifiedSdvob && !isActive) {
    status = "Certification Temporarily Deactivated";
  } else if (dateCertified) {
    status = "Revoked";
  } else {
    status = "Not Certified";
  }
  return status;
};

export const outputProcurementStatus = (status) => {
  const statuses = {
    AcceptingSubmissions: "Accepting Submissions",
  };

  return statuses[status] ? statuses[status] : status;
};

export const outputAwardStatus = (status) => {
  const statuses = {
    NotAwarded: "Not Awarded",
    null: "Not Set",
  };

  return statuses[status] ? statuses[status] : status;
};

export const getRelevantApplicationTimer = (application) => {
  const { phase, status, timers } = application;
  const applicationTimerMatrix = {
    Preparation: {
      New: null,
      InProgress: null,
      Submitted: null,
      Deficient: null,
      Appealed: null,
      Withdrawn: null,
      Denied: null,
      Approved: null,
      Certified: null,
    },
    InitialReview: {
      New: "Received",
      InProgress: "Received",
      Submitted: "Received",
      Deficient: "Deficiency",
      Appealed: null,
      Withdrawn: null,
      Denied: null,
      Approved: null,
      Certified: null,
    },
    InDepthReview: {
      New: "InDepthReview",
      InProgress: "InDepthReview",
      Submitted: "InDepthReview",
      Deficient: "AdditionalInfoNeeded",
      Appealed: null,
      Withdrawn: null,
      Denied: "AbleToAppeal",
      Approved: null,
      Certified: null,
    },
    DirectorReview: {
      New: "InDepthReview",
      InProgress: "InDepthReview",
      Submitted: "InDepthReview",
      Deficient: "Deficiency",
      Appealed: null,
      Withdrawn: null,
      Denied: "AbleToAppeal",
      Approved: "InDepthReview",
      Certified: null,
    },
  };

  const timerType = applicationTimerMatrix[phase] ? applicationTimerMatrix[phase][status] : "";
  const relTimer = timers.find((timer) => timer.type === timerType);
  return relTimer;
};

export const daysLeftColorize = (timer) => {
  return (
    <span className={`text-${daysLeftClass(timer)}`} data-toggle="tooltip" data-placement="bottom" title={timer?.minutesLeft}>
      {" "}
      {timer?.daysLeftFormatted}
    </span>
  );
};

export const daysLeftClass = (timer) => {
  // Days Timers

  let classString = "success";
  if (timer) {
    // console.log(timer);
    const { daysLeft, type } = timer;
    const isShortTimer = ["Deficiency", "AdditionalInfoNeeded"].includes(type);
    let classString = "success";

    if (isNaN(daysLeft)) {
      classString = "";
    } else if (daysLeft < 3) {
      // } else if (daysLeft < 7) {
      classString = "danger";
    } else if (daysLeft > 7 && daysLeft <= 10) {
      // } else if (daysLeft > 7 && daysLeft <= 14) {
      classString = "yellow";
    }
  }
  return classString;
};

// Check whether a timer has expired
export const hasExpired = (timer) => {
  return !timer || timer?.minutesLeft <= 0;
};

export const differenceInDays = (endDate, startDate) => {
  const newStartDate = startDate ? new Date(startDate) : new Date();
  const newEndDate = new Date(endDate);
  const diffTime = Math.abs(newEndDate - newStartDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

export const formatAddress = (addressObj) => {
  if (addressObj?.city == "" && addressObj?.state == null) return "";

  const val = addressObj ? (
    <span>
      {addressObj.line1 ? (
        <span>
          {`${addressObj.line1}`}
          <br />
        </span>
      ) : null}
      {addressObj.line2 ? (
        <span>
          {`${addressObj.line2}`}
          <br />
        </span>
      ) : null}
      <span className="text-nowrap text-break">
        {addressObj.city}, {addressObj.state} {addressObj.zipCode}
      </span>
    </span>
  ) : (
    <p>No address provided</p>
  );

  return val;
};

// Consistent markup around address output
export const outputAddresses = (addressesArr, outterClass, headerClass, detailsClass) => {
  const addressesOutput = addressesArr.map((address) => {
    return (
      <div className={outterClass} key={`address-${address.id}`}>
        {addressesArr.length > 1 ? <div className={headerClass}>{address.type} Address:</div> : null}
        <div className={detailsClass}>{formatAddress(address)}</div>
        <br />
      </div>
    );
  });

  return addressesOutput;
};

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};

// export const numberWithCommas = (number) => {
//   return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// };

export const getPercentage = (current, total) => {
  const num = current / total;
  return current && total ? parseFloat((num + Number.EPSILON) * 100).toFixed(2) : "0";
};

export const getPlanType = (agencyGoal, realGoal) => {
  if (!realGoal && realGoal !== 0) {
    return "Unknown";
  } else {
    return realGoal >= agencyGoal ? "Utilization Plan" : realGoal > 0 ? "Partial Waiver" : "Total Waiver";
  }
};

// This is needed because of the use of short-lived and single-use tokens to retrieve files
// The BE will verify that the requestor should have access to the document and that the URL used to access can't be shared or used again
export const handleDocumentClick = async (documentId) => {
  const tokenId = await getDocumentDownloadUrl(documentId);
  const url = window.URL.createObjectURL(tokenId);
  window.open(url, '_blank');
};

// To be determined if this is still needed
// Early on, this was needed so that we weren't passing data back to the BE in ways it wasn't prepared to handle
export const cleanNull = (obj) => {
  // iterate over application and remove any null values so we don't pass them at all.
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null) {
      delete obj[key];
    } else if (key === "documents") {
      // when uploading, we'd get a status for documents
      // remove that status for the document in the context of an application
      obj[key] = obj[key].filter((item) => item.documentId);
      obj[key].map((doc) => {
        delete doc["status"];
      });
    } else if (key === "taxReturns") {
      let taxReturnsArr = [];
      obj[key].map((item) => {
        // iterate over all tax returns
        if (item.documentationExists !== false) {
          // make sure documentationExists value isn't null
          taxReturnsArr.push({
            ...item,
            documentationExists: true,
          });
        } else {
          taxReturnsArr.push(item);
        }

        obj[key] = taxReturnsArr;
      });
    } else if (key === "federalId") {
      obj[key] = obj[key].toString();
    } else if (key === "addresses") {
      obj[key] = obj[key].filter((item) => item.line1);
    } else if (key === "owners") {
      obj[key] = obj[key].filter((item) => item.name);
      obj[key].forEach((key) => {
        // prevent saving owner with no ownership
        // BE wouldn't let us save this way
        if (key.percentageOwnership === "") {
          delete key.percentageOwnership;
        }
        if (key.percentageServiceDisabled === "") {
          delete key.percentageServiceDisabled;
        }
      });
    }
  });
  return obj;
};

export const addSavedCheckForm = (dirty) => {
  if (dirty) {
    window.addEventListener("beforeunload", function (e) {
      var confirmationMessage = "It looks like you have been editing something. " + "If you leave before saving, your changes will be lost.";

      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    });
  } else {
    window.addEventListener("beforeunload", undefined);
  }
};

// At least 1 route will be public and not need a sidebar nav
export const hasSidebar = (currentRoute) => {
  const exclusionPaths = ["/business-search"];
  const match =
    matchPath(currentRoute, {
      path: exclusionPaths,
    }) ||
    (matchPath(currentRoute, {
      path: "/application/",
    }) &&
      loadUserRole() === roles.Business);

  return [null, false].includes(match) ? true : false;
};

// Whether to use container or container-fluid.
// container-fluid will use more width and is useful for screens with large tables or tabbed navigation
export const isExtraWide = (currentRoute) => {
  const exclusionPaths = ["/application/:applicationId/", "/procurements/:procurementId/", "/business-search", "/agency-reporting"];
  const match = matchPath(currentRoute, {
    path: exclusionPaths,
  });
  return match === null ? false : true;
};

// This is used so that we can define multi-level user role navs consistently and with some simple JSON
export const createNavLinks = (navArr) => {
  const nav = navArr.map((item, index) => {
    if (item !== null) {
      const [url, label, liClass, sub] = item;
      const idUrl = url === "/" ? `home-${index}` : url.replace(/\//g, "-");

      if (url === "#") {
        return (
          <li key={`user-nav-item-${index}`} className={`${liClass} bg-gray `}>
            <Accordion.Toggle variant="link" eventKey={index} className="text-white accordion-toggle text-left">
              {label}
              <FontAwesomeIcon className="" icon={["fas", "plus"]} size="xs" />
              {/* <FontAwesomeIcon className="" icon={["fas", "minus"]} size="xs" /> */}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={index}>
              <ul className="no-list bg-white">
                {sub.map((item, index) => {
                  const [url, label, liClass] = item;
                  return (
                    <li key={`user-nav-item-${index}`} className={liClass}>
                      {/* <div onClick={() => handleClick(url)} id={`user-nav-item-${idUrl}`} dangerouslySetInnerHTML={{ __html: label }} className="light-hover"></div> */}
                      <Link role="link" id={`user-nav-item-${idUrl}`} to={url} className="light-hover">
                        {label}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </Accordion.Collapse>
          </li>
        );
      } else if (url === "") {
        {
          return (
            <li key={`user-nav-item-${index}`} className={liClass}>
              <div className="light-hover">{label}</div>
              {/* <div onClick={() => handleClick(url)} id={`user-nav-item-${idUrl}`} dangerouslySetInnerHTML={{ __html: label }} className="light-hover"></div> */}
            </li>
          );
        }
      } else {
        return (
          <li key={`user-nav-item-${index}`} className={liClass}>
            <Link role="link" id={`user-nav-item-${idUrl}`} to={url} className="light-hover">
              {label}
            </Link>
            {/* <div onClick={() => handleClick(url)} id={`user-nav-item-${idUrl}`} dangerouslySetInnerHTML={{ __html: label }} className="light-hover"></div> */}
          </li>
        );
      }
    }
  });

  return (
    <Accordion defaultActiveKey="0">
      <ul className="no-list">{nav}</ul>
    </Accordion>
  );
};

export const createTabNavLinks = (navArr) => {
  const nav = navArr.map((item, index) => {
    const [url, label, liClass, sub, exact] = item;
    const idUrl = url === "/" ? `tab-nav-home-${index}` : url.replace(/\//g, "-");
    return (
      <NavLink role="link" key={`tab-nav-item-${index}`} to={url} exact={exact} activeClassName="active" className="nav-link nav-item">
        {label}
      </NavLink>
    );
  });

  return <>{nav}</>;
};

export const ownershipTypes = [
  ["SoleProprietorship", "Sole Proprietorship", "Please confirm that you are not an LLC"],
  [
    "Partnership",
    "Partnership",
    // "A business where personal liability is joint and individual for the general partners responsible for the obligations of the partnership. Requires the filing of a Certificate of Assumed Name following an agreement of the partners.",
  ],
  [
    "Corporation",
    "Corporation",
    // "A legal entity separate and distinct from the individual(s) who compose the business. A Certificate of Incorporation must be filed (signed by at least one incorporator) with the Department of State.",
  ],
  [
    "LLC",
    "Limited Liability Company",
    // "A business entity that limits personal liability for its members. Requires the filing of Articles of Organization and a Certificate of Publication.",
  ],
  [
    "LLP",
    "Limited Liability Partnership",
    // "Personal liability is joint and individual for the general partner(s) who are responsible for the obligations of the partnership; limited partner(s) are liable to the extent of their capital contributions to the partnership. Requires filing of Certificate of Limited Partnership and Certificate of Publication.",
  ],
];

export const isUserAnOwner = (application) => {
  // console.log(getUserId())
  return application?.owners?.find((owner) => owner.userId === getUserId()) !== undefined;
};

export const isUserThisOwner = (owner) => {
  return owner.userId === getUserId();
};

export const isUserAPreparer = (application) => {
  // console.log(getUserId(), application.preparerId);
  return application?.preparerId === getUserId();
};

export const getBackendErrors = (e) => {
  if ([400, 404].includes(e?.response?.status)) {
    return e?.response?.data;
  } else {
    return e.message;
  }
};

export const updateInitialItems = (tableItems1, FilterListConfig) => {
  let items1 = tableItems1 ? [...tableItems1] : [];
  items1 = items1.map((item) => {
    const item1 = {};
    Object.keys(item).map((key) => {
      const value1 = item[key];
      const filter = Object.keys(FilterListConfig)?.includes(key);
      if (filter) {
        item1[key] = Array.isArray(value1) ? value1.join(",") : value1;
      } else {
        item1[key] = value1;
      }
    });
    return item1;
  });
  return items1;
};

export const getLocalTime = (date) => {
  return date !== null ? new Date(date).toLocaleString([], { dateStyle: "short", timeStyle: "short" }) : "Not Set";
};

export const getLocalDate = (date) => {
  return date !== null ? new Date(date).toLocaleString([], { dateStyle: "long" }) : "Not Set";
};

export const getUtcDate = (date) => {
  if (!date) {
    return "Not Set";
  }

  const newDate = new Date(date);
  const utcDate = new Date(newDate.getUTCFullYear(), newDate.getUTCMonth(), newDate.getUTCDate());

  return getLocalDate(utcDate);
};

export const getLocalDateTime = (date) => {
  return date !== null ? new Date(date).toLocaleString([], { dateStyle: "short", timeStyle: "short" }) : "Not Set";
};

export const getYear = (date) => {
  return date !== null ? new Date(date).getUTCFullYear() : "Not Set";
};

export const getMonth = (date) => {
  return date !== null ? new Date(date).getUTCMonth() + 1 : "Not Set";
};

export const getYearMonth = (timestamp) => {
  const year = timestamp.substring(0, 4);
  const month = timestamp.substring(5, 7);
  return `${year}/${month}`;

  // const dateValue = new Date(timestamp);
  // const year = dateValue.getFullYear();
  // const month = dateValue.getMonth();
  // // const month = timestamp.substring(5, 7);
  // // return `${month}/${year}`;
  // return dateValue.toLocaleString([], {
  //   // you can use undefined as first argument
  //   year: "2-digit",
  //   month: "2-digit",
  //   // day: "2-digit",
  // });
};

export const isPastMonth = (timestamp) => {
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + 1;
  return getYear(timestamp) < currentYear || (getYear(timestamp) === currentYear && getMonth(timestamp) < currentMonth);
};

export const truncate = (text, length) => {
  const lengthToUse = length ? length : 25;
  return <span>{text.replace(/(.{25})..+/, "$1\u2026")}</span>;
};

export const outputPercentage = (num) => {
  // return Math.round((num + Number.EPSILON) * 100) + "%";
  let formattedNum = (num * 100).toFixed(2);

  return parseFloat(formattedNum) + "%";
};

export const outputMoney = (amount) => {
  return amount ? amount.toLocaleString("en-US", { style: "currency", currency: "USD" }) : "$0";
};

// export const getMoneyNoDecimal = (amount) => {
//   return numeral(amount).format("$ 0,0");
// };

/**
 * Transform and download a CSV file from an API call.
 *
 * @param {string} data
 * @param {null|string} desc Name for downloaded file.
 *
 * @return {void}
 */
export const handleDownloadCSV = (data, desc = null) => {
  const file = new Blob([data], { type: "text/csv" });
  const downloadUrl = window.URL.createObjectURL(file);
  const link = document.createElement("a");
  link.href = downloadUrl;

  if (desc) {
    link.download = desc;
  }

  link.click();

  // cleanup
  window.URL.revokeObjectURL(downloadUrl);
};

export const hasDocumentType = (type, documents) => {
  const document = documents?.find((document) => document.type === type);
  const result = !!(document && document.documentId && document.documentId !== EMPTY_GUID);
  return result;
};

export const transformToUrl = (website) => {
  const websiteUrl = website && !website.includes("http") ? `http://${website}` : website;
  return websiteUrl;
};

export const ownerHasAttested = (application) => {
  const ownerToAttest = application?.owners.find((owner) => owner.userId === getUserId());
  // console.log(ownerToAttest);
  return ownerToAttest ? ownerToAttest.hasAttested : false;
};

export const hasActiveCertification = (business) => {
  return business?.isActive && business?.isCertifiedSdvob;
};

export const goToLogin = () => {
  localStorage.setItem(returnPathLocalStorageKey, history.location.pathname);
  setApplicationOwnerAssociationId();

  history.push("/login");
};

export const tableSortByValue = (rowA, rowB, fieldName) => {
  const a = typeof rowA?.original[fieldName] === "string" ? rowA?.original[fieldName].toLowerCase() : rowA?.original[fieldName];
  const b = typeof rowB?.original[fieldName] === "string" ? rowB?.original[fieldName].toLowerCase() : rowB?.original[fieldName];
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
};

export const userCanAttest = (application) => {
  // console.log(isUserAnOwner(application), (application?.phase === "Preparation" && application?.status === "Submitted"), application?.status === "Deficient")
  return isUserAnOwner(application) && ((application?.phase === "Preparation" && application?.status === "Submitted") || application?.status === "Deficient");
};

export const isPrime = (plan) => {
  // console.log(plan?.primeContractorId, loadActiveOrgId())
  return plan?.primeContractorId === loadActiveOrgId();
};

export const procurementHasGoal = (procurement) => {
  // console.log(plan?.primeContractorId, loadActiveOrgId())
  return procurement?.goal > 0 || procurement?.awardedPlan?.goal > 0;
  // return procurement?.goal > 0;
};

export const procurementCanHavePayments = (procurement) => {
  return procurement?.awardedPlan?.goal > 0 && !procurement?.contract?.primeContractor?.isCertifiedSdvob;
  // return procurement?.goal > 0;
};

export const plansExpected = (procurement) => {
  return procurementHasGoal(procurement) && procurement?.exclusionType !== "ExemptExcluded";
};

export const componentsFromRoutes = (componentRoutes) => {
  // console.log(componentRoutes);
  const { routes, globalProps } = componentRoutes;
  return routes.map((route) => {
    const { path, exact, props, initialValues } = route || null;
    let Component;
    if (route.component) {
      Component = route.component;
    } else {
      const component = route.components.find((component) => component[0] === loadUserRole());
      Component = component[1];
    }
    return <Route key={path} path={path} exact={exact} render={() => <Component {...props} {...globalProps} />} />;
  });
};

export function setApplicationOwnerAssociationId() {
  let applicationOwnerAssociationId = undefined;
  const queryString = window.location.search?.split("?").filter((rp) => (rp || "").trim().length > 0)[0];

  // Check to see if we need to associate an authenticated user with an application owner record
  if (queryString) {
    const queryStringParameters = queryString.split("&").map((queryStringParameter) => {
      const queryStringParameterParts = queryStringParameter.split("=");

      return {
        key: queryStringParameterParts[0],
        value: queryStringParameterParts[1],
      };
    });

    applicationOwnerAssociationId = queryStringParameters.find((queryStringParameter) => queryStringParameter.key === applicationOwnerAssociationIdLocalStorageKey)?.value?.trim() || undefined;

    if (applicationOwnerAssociationId) {
      localStorage.setItem(applicationOwnerAssociationIdLocalStorageKey, applicationOwnerAssociationId);

      return true;
    }
  }
}

export function reverseEnumLookup(value, enumeration) {
  return enumeration.find((enumType) => (enumType[1] === value ? enumType[0] : enumType[1]));
}

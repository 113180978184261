import React from "react";
import "./MainSidebar.scss";
import { Link } from "react-router-dom";
import BusinessNav from "./BusinessNav";
// import SdvobNav from "./SdvobNav";
import DivisionNav from "./DivisionNav";
import DivisionAdminNav from "./DivisionAdminNav";
import AgencyAdminNav from "./AgencyAdminNav";
// import ApplicationNav from "./ApplicationNav";
import ContractManagerNav from "./ContractManagerNav";
import FallbackNav from "./FallbackNav";
import { loadUserRole, roles, loadActiveOrgId } from "../../util/userHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MainSidebar = (props) => {
  const { messages, userInfo, activeOrg, activeOrgId } = props;
  // console.log(userInfo);
  // const isCertified = activeOrg?.dateCertified !== null;
  let Nav = FallbackNav;
  let SecondNav = null;
  switch (loadUserRole()) {
    case roles.Business:
      // Nav = isCertified ? SdvobNav : BusinessNav;
      Nav = BusinessNav;
      break;
    case roles.DivisionUser:
      Nav = DivisionNav;
      break;
    case roles.DivisionAdmin:
      Nav = DivisionNav;
      SecondNav = DivisionAdminNav;
      break;
    case roles.AgencyAdmin:
    case roles.AgencyUser:
      Nav = loadActiveOrgId() ? AgencyAdminNav : FallbackNav;
      break;
    case roles.ContractManager:
      Nav = ContractManagerNav;
      break;
    default:
      Nav = FallbackNav;
      break;
  }

  return (
    <div className="sidebar main-sidebar col-xl-2 col-lg-3 col-md-4 mt-3 mt-md-0 pl-lg-0 mb-5">
      <div className="sidebar-inner">
        <div className="mb-3 mb-lg-5 nav-wrapper">
          <Nav activeOrg={activeOrg} activeOrgId={activeOrgId} userInfo={userInfo} messageCount={messages?.length} />
        </div>
        {SecondNav ? (
          <div className="nav-wrapper mb-3 mb-lg-5">
            <SecondNav messageCount={messages?.length} />
          </div>
        ) : null}
        <Link className="btn btn-primary btn-block text-align-left text-left oswald px-3 mb-2 d-flex flex-row justify-content-between align-items-center" to="/help">
          <span>Help Center</span> <FontAwesomeIcon icon={["fa", "arrow-right"]} className="" />
        </Link>
        <div className="px-3">
          <small className="text-break">
            If you need assistance with your application, <strong>call</strong> <span className="text-nowrap">(518) 474-2015</span> or <strong>email</strong> veteransdevelopment@ogs.ny.gov.
          </small>
        </div>
      </div>
    </div>
  );
};

export default MainSidebar;

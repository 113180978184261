import React from "react";
import { Link } from "react-router-dom";
import BusinessUtilizationPlanList from "./BusinessUtilizationPlanList";

const BusinessUtilizationPlans = (props) => {
  return (
    <section id="utilization-plans">
      <div>
        <Link name="submit-new-plan" role="button" className="btn btn-secondary" to="/utilization-plans/submit">
          Submit a new plan
        </Link>
      </div>

      <BusinessUtilizationPlanList />
    </section>
  );
};

export default BusinessUtilizationPlans;

import React from "react"
import { Link } from "react-router-dom"

const Reporting = (props) => {
  return (
    <div>
      <div className="row">
        <div className="col-12"><Link to="./compliance-reports">View or Submit Monthly Compliance Reports</Link></div>
        <div className="col-12"><Link to="./payments">View or Submit Payments</Link></div>
      </div>
    </div>
  )
}

export default Reporting;

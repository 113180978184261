import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import JSONPretty from "react-json-pretty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ENVIRONMENT = process.env.NODE_ENV;
const DEBUG = process.env.REACT_APP_DEV_SHOW_DEBUG;

function DebugModal(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { name } = props;

  return (
    <>
      {/* {false ? */}
      {ENVIRONMENT == "development" && DEBUG == "true" ? (
        <>
          <Button variant="primary" className="btn-sm faux-link text-primary" onClick={handleShow}>
            <u>
              {name ? name : "Debug control"} <FontAwesomeIcon icon={["fas", "external-link"]} className="mr-3" />
            </u>
          </Button>

          <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Debug State</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <JSONPretty data={props.formikBag}></JSONPretty>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
            </Modal.Footer>
          </Modal>
        </>
      ) : null}
    </>
  );
}
export default DebugModal;

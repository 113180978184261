export const trainingVideos = [
  {
    videoTitle: "How to use the Public Directory",
    videoUrl: "/videos/public-directory/public-directory-search.mp4",
    videoId: "Oyh5CQjjepM",
    userContexts: ["None", "Business", "SDVOB", "AgencyAdmin", "AgencyUser", "DivisionAdmin", "DivisionUser"],
    section: "Directory",
  },
  {
    videoTitle: "Business details on the Public Directory",
    videoUrl: "/videos/public-directory/public-directory-details.mp4",
    videoId: "grUGkisEe-s",
    userContexts: ["None", "Business", "SDVOB", "AgencyAdmin", "AgencyUser", "DivisionAdmin", "DivisionUser"],
    section: "Directory",
  },
  {
    videoTitle: "Logging into the Portal",
    videoUrl: "/videos/authentication/login.mp4",
    videoId: "gyuqPFRuRw0",
    userContexts: ["None", "Business", "SDVOB", "AgencyAdmin", "AgencyUser", "DivisionAdmin", "DivisionUser"],
    section: "Authentication",
  },
  {
    videoTitle: "Registering a New Business",
    videoUrl: "/videos/register/create-business.mp4",
    videoId: "b0cJZO0Wd1k",
    userContexts: ["None", "Business", "SDVOB", "DivisionAdmin", "DivisionUser"],
    section: "Register",
  },
  {
    videoTitle: "Claim an Existing Registered Business",
    videoUrl: "/videos/register/claim-existing-vendor.mp4",
    videoId: "7hNEvHM6FNU",
    userContexts: ["None", "Business", "SDVOB", "DivisionAdmin", "DivisionUser"],
    section: "Register",
  },
  {
    videoTitle: "Register Additional Businesses",
    videoUrl: "/videos/register/add-additional-business.mp4",
    videoId: "p0hoY1QKUJw",
    userContexts: ["None", "Business", "SDVOB", "DivisionAdmin", "DivisionUser"],
    section: "Register",
  },
  {
    videoTitle: "Switch Between Multiple Businesses",
    videoUrl: "/videos/portal-basics/choose-active-business.mp4",
    userContexts: ["Business", "SDVOB"],
    section: "PortalBasics",
  },
  {
    videoTitle: "Notifications",
    videoUrl: "/videos/portal-basics/notifications.mp4",
    userContexts: ["Business", "SDVOB", "AgencyAdmin", "AgencyUser", "DivisionAdmin", "DivisionUser"],
    section: "PortalBasics",
  },
  {
    videoTitle: "Applying for SDVOB Status",
    videoUrl: "/videos/application/apply-start.mp4",
    videoId: "54CCRS1UrfI",
    userContexts: ["None", "Business", "DivisionAdmin", "DivisionUser"],
    section: "Application",
  },
  {
    videoTitle: "Reapply for Certification",
    videoUrl: "/videos/application/reapply.mp4",
    userContexts: ["SDVOB", "DivisionAdmin", "DivisionUser"],
    section: "Application",
  },
  {
    videoTitle: "Business Information",
    videoUrl: "/videos/application/application-business-information.mp4",
    videoId: "Ure5FJh6Hck",
    userContexts: ["None", "Business", "SDVOB", "DivisionAdmin", "DivisionUser"],
    section: "Application",
  },
  {
    videoTitle: "Applicant Information",
    videoUrl: "/videos/application/application-applicant-information.mp4",
    videoId: "_gX2djsre_U",
    userContexts: ["None", "Business", "SDVOB", "DivisionAdmin", "DivisionUser"],
    section: "Application",
  },
  {
    videoTitle: "Financial Information",
    videoUrl: "/videos/application/application-financial-information.mp4",
    videoId: "OtGAGUB_ejM",
    userContexts: ["None", "Business", "SDVOB", "DivisionAdmin", "DivisionUser"],
    section: "Application",
  },
  {
    videoTitle: "Review Application and Attest",
    videoUrl: "/videos/application/application-review-application-and-attest.mp4",
    videoId: "Gv2Ah2GNUa4",
    userContexts: ["None", "Business", "SDVOB", "DivisionAdmin", "DivisionUser"],
    section: "Application",
  },
  {
    videoTitle: "Additional Owner Attestation",
    videoUrl: "/videos/application/application-additional-attestation.mp4",
    videoId: "BbBtXOWUI_Y",
    userContexts: ["None", "Business", "SDVOB", "DivisionAdmin", "DivisionUser"],
    section: "Application",
  },
  {
    videoTitle: "Respond to Requests",
    videoUrl: "/videos/application/respond-to-requests.mp4",
    videoId: "jBMy2napHLQ",
    userContexts: ["None", "Business", "SDVOB", "DivisionAdmin", "DivisionUser"],
    section: "Application",
  },
  {
    videoTitle: "Withdraw Application",
    videoUrl: "/videos/application/withdraw-application.mp4",
    videoId: "-kJRcwtXSIM",
    userContexts: ["None", "Business", "SDVOB", "DivisionAdmin", "DivisionUser"],
    section: "Application",
  },
  /// START BUSINESS MANAGEMENT SECTION - 7 / 7 Videos
  {
    videoTitle: "Update Business Information",
    videoUrl: "/videos/manage-business/update-business-information.mp4",
    userContexts: ["SDVOB"],
    section: "ManageBusiness",
  },
  {
    videoTitle: "Temporarily Deactivate your Listing",
    videoUrl: "/videos/manage-business/deactivate-listing.mp4",
    userContexts: ["SDVOB", "DivisionAdmin", "DivisionUser"],
    section: "ManageBusiness",
  },
  {
    videoTitle: "Manage Business Directory Listing",
    videoUrl: "/videos/manage-business/manage-directory-listing.mp4",
    userContexts: ["DivisionAdmin", "DivisionUser"],
    section: "ManageBusiness",
  },
  {
    videoTitle: "Remove an owner from the Directory Listing",
    videoUrl: "/videos/manage-business/manage-business-owners.mp4",
    userContexts: ["DivisionAdmin", "DivisionUser"],
    section: "ManageBusiness",
  },
  {
    videoTitle: "Deactivate a Business Directory Listing",
    videoUrl: "/videos/manage-business/deactivate-certification.mp4",
    userContexts: ["DivisionAdmin", "DivisionUser"],
    section: "ManageBusiness",
  },
  {
    videoTitle: "Revoke Certification for a Business",
    videoUrl: "/videos/manage-business/revoke-certification.mp4",
    userContexts: ["DivisionAdmin", "DivisionUser"],
    section: "ManageBusiness",
  },
  {
    videoTitle: "Manage Certified Business Timers",
    videoUrl: "/videos/manage-business/pause-timers.mp4",
    userContexts: ["DivisionAdmin", "DivisionUser"],
    section: "ManageBusiness",
  },
  /// END START BUSINESS MANAGEMENT SECTION
  /// START PLANS SECTION - 14/14 Videos (0 left)
  {
    videoTitle: "Submit a Utilization Plan",
    videoUrl: "/videos/utilization-plans/submit-a-plan.mp4",
    userContexts: ["Business"],
    section: "UtilizationPlans",
  },
  {
    videoTitle: "Revise a Submitted Utilization Plan",
    videoUrl: "/videos/utilization-plans/revise-a-plan.mp4",
    userContexts: ["Business"],
    section: "UtilizationPlans",
  },
  {
    videoTitle: "Submit a Utilization Plan",
    videoUrl: "/videos/utilization-plans/sdvob-submit-a-plan.mp4",
    userContexts: ["SDVOB"],
    section: "UtilizationPlans",
  },
  {
    videoTitle: "Respond to Utilization Plan Deficiencies",
    videoUrl: "/videos/utilization-plans/sdvob-revise-a-plan.mp4",
    userContexts: ["SDVOB"],
    section: "UtilizationPlans",
  },
  {
    videoTitle: "Submit a Revised Approved Utilization Plan",
    videoUrl: "/videos/utilization-plans/revise-approved-plan.mp4",
    userContexts: ["SDVOB"],
    section: "UtilizationPlans",
  },
  {
    videoTitle: "Review Submitted Plans",
    videoUrl: "/videos/utilization-plans/review-submitted-plans.mp4",
    userContexts: ["AgencyAdmin", "AgencyUser"],
    section: "UtilizationPlans",
  },
  {
    videoTitle: "Mark a Plan as Deficient",
    videoUrl: "/videos/utilization-plans/mark-plan-deficient.mp4",
    userContexts: ["AgencyAdmin", "AgencyUser"],
    section: "UtilizationPlans",
  },
  {
    videoTitle: "Approve a Plan",
    videoUrl: "/videos/utilization-plans/approve-plan.mp4",
    userContexts: ["AgencyAdmin", "AgencyUser"],
    section: "UtilizationPlans",
  },
  {
    videoTitle: "Deny a Plan",
    videoUrl: "/videos/utilization-plans/deny-plan.mp4",
    userContexts: ["AgencyAdmin", "AgencyUser"],
    section: "UtilizationPlans",
  },
  {
    videoTitle: "Invite a Contractor to the Portal",
    videoUrl: "/videos/utilization-plans/invite-prime.mp4",
    userContexts: ["AgencyAdmin", "AgencyUser"],
    section: "UtilizationPlans",
  },
  {
    videoTitle: "Revise a Submitted Utilization Plan on Behalf of a Prime Contractor",
    videoUrl: "/videos/utilization-plans/revise-a-plan-for-prime.mp4",
    userContexts: ["AgencyAdmin", "AgencyUser"],
    section: "UtilizationPlans",
  },
  {
    videoTitle: "Confirm Participation in a Plan",
    videoUrl: "/videos/utilization-plans/confirm-participation.mp4",
    userContexts: ["SDVOB"],
    section: "UtilizationPlans",
  },

  /// END PLANS SECTION
  /// START BUSINESS CONTRACT
  {
    videoTitle: "Work with your Awarded Contracts",
    videoUrl: "/videos/utilization-plans/work-a-contract.mp4",
    userContexts: ["Business", "SDVOB"],
    section: "Awards",
  },
  {
    videoTitle: "Enter Payments to SDVOB subcontractors",
    videoUrl: "/videos/payments/enter-payment-to-subcontractors.mp4",
    userContexts: ["Business", "DivisionAdmin", "DivisionUser"],
    section: "Payments",
  },
  {
    videoTitle: "Update or delete an incorrectly entered payment",
    videoUrl: "/videos/payments/edit-delete-payment.mp4",
    userContexts: ["Business", "DivisionAdmin", "DivisionUser"],
    section: "Payments",
  },
  {
    videoTitle: "Add a Receipt to a Payment",
    videoUrl: "/videos/payments/add-payment-receipt.mp4",
    userContexts: ["Business"],
    section: "Payments",
  },
  {
    videoTitle: "Confirm Payment from a Prime Contractor",
    videoUrl: "/videos/payments/confirm-prime-payment.mp4",
    userContexts: ["SDVOB"],
    section: "Payments",
  },
  {
    videoTitle: "Correspond with the Division about your Business",
    videoUrl: "/videos/correspondence/correspond-with-division-about-business.mp4",
    userContexts: ["Business", "SDVOB", "DivisionAdmin", "DivisionUser"],
    section: "Correspondence",
  },
  {
    videoTitle: "Respond to Business Correspondences",
    videoUrl: "/videos/correspondence/correspond-with-business-about-business.mp4",
    userContexts: ["DivisionAdmin", "DivisionUser"],
    section: "Correspondence",
  },
  {
    videoTitle: "Submit a Compliance Report ",
    videoUrl: "/videos/compliance-reports/submit-compliance-report.mp4",
    userContexts: ["Business"],
    section: "ComplianceReports",
  },
  {
    videoTitle: "Respond to Compliance Report Change Requests from the Agency",
    videoUrl: "/videos/compliance-reports/resubmit-compliance-report.mp4",
    userContexts: ["Business"],
    section: "ComplianceReports",
  },
  /// END BUSINESS CONTRACT SECTION
  /// START AGENCY CONTRACTS SECTION (2)
  {
    videoTitle: "Choose an Active Agency",
    videoUrl: "/videos/portal-basics/switch-agencies.mp4",
    userContexts: ["AgencyUser", "AgencyAdmin"],
    section: "PortalBasics",
  },
  {
    videoTitle: "Import Vendors, Contracts, and Payments to Prime Contractors",
    videoUrl: "/videos/imports/data-import-contracts.mp4",
    userContexts: ["AgencyAdmin", "AgencyUser"],
    section: "Import",
  },
  {
    videoTitle: "Import Direct Payments to Subcontractors",
    videoUrl: "/videos/imports/data-import-direct-payments.mp4",
    userContexts: ["AgencyAdmin", "AgencyUser"],
    section: "Import",
  },
  {
    videoTitle: "Procurements and Awards",
    videoUrl: "/videos/procurements/what-are-procurements.mp4",
    userContexts: ["AgencyAdmin", "AgencyUser"],
    section: "Awards",
  },
  {
    videoTitle: "Create a Procurement",
    videoUrl: "/videos/procurements/create-procurement.mp4",
    userContexts: ["AgencyAdmin", "AgencyUser"],
    section: "Awards",
  },
  {
    videoTitle: "Auto-Assignment of a Contract to a Procurement",
    videoUrl: "/videos/procurements/auto-assign-procurement.mp4",
    userContexts: ["AgencyAdmin", "AgencyUser"],
    section: "Awards",
  },
  {
    videoTitle: "Manually assigning a Contract to a Procurement",
    videoUrl: "/videos/procurements/assign-contract.mp4",
    userContexts: ["AgencyAdmin", "AgencyUser"],
    section: "Awards",
  },
  {
    videoTitle: "Manage a Procurement",
    videoUrl: "/videos/procurements/manage-procurement.mp4",
    userContexts: ["AgencyAdmin", "AgencyUser"],
    section: "Awards",
  },
  {
    videoTitle: "Request a Plan from a Prime Contractor",
    videoUrl: "/videos/utilization-plans/request-a-plan-from-prime.mp4",
    userContexts: ["AgencyAdmin", "AgencyUser"],
    section: "UtilizationPlans",
  },
  {
    videoTitle: "Submit a Utilization Plan on Behalf of a Prime Contractor",
    videoUrl: "/videos/utilization-plans/submit-plan-for-prime.mp4",
    userContexts: ["AgencyAdmin", "AgencyUser"],
    section: "UtilizationPlans",
  },
  {
    videoTitle: "Assign Agency Users to a Procurement",
    videoUrl: "/videos/procurements/assign-contract-users.mp4",
    userContexts: ["AgencyAdmin", "AgencyUser"],
    section: "Awards",
  },
  {
    videoTitle: "Enter Payments to SDVOB Subcontractors on behalf of a contractor",
    videoUrl: "/videos/payments/add-payment-for-contractor.mp4",
    userContexts: ["AgencyAdmin", "AgencyUser"],
    section: "Payments",
  },
  {
    videoTitle: "Approve a Contractor's submitted Compliance Report",
    videoUrl: "/videos/compliance-reports/accept-compliance-report.mp4",
    userContexts: ["AgencyAdmin", "AgencyUser"],
    section: "ComplianceReports",
  },
  {
    videoTitle: "Mark a Contractor's submitted Compliance Report deficient",
    videoUrl: "/videos/compliance-reports/mark-compliance-report-deficient.mp4",
    userContexts: ["AgencyAdmin", "AgencyUser"],
    section: "ComplianceReports",
  },
  {
    videoTitle: "Submit a Compliance Report on behalf of a contractor",
    videoUrl: "/videos/compliance-reports/submit-compliance-report-for-contractor.mp4",
    userContexts: ["AgencyAdmin", "AgencyUser"],
    section: "ComplianceReports",
  },
  {
    videoTitle: "Submit an OGS Centralized Contract Compliance Report on behalf of a contractor",
    videoUrl: "/videos/compliance-reports/agency-centralized-compliance-reports.mp4",
    userContexts: ["AgencyAdmin", "AgencyUser"],
    section: "ComplianceReports",
  },
  /// END AGENCY CONTRACTS  SECTION
  /// START AGENCY SUBMISSIONS SECTION -(20)
  {
    videoTitle: "Create and submit an Activity Report",
    videoUrl: "/videos/activity-reports/create-activity-report.mp4",
    userContexts: ["AgencyAdmin", "AgencyUser"],
    section: "ActivityReports",
  },
  {
    videoTitle: "Force Create an Activity Report",
    videoUrl: "/videos/activity-reports/force-create-activity-report.mp4",
    userContexts: ["AgencyAdmin", "AgencyUser"],
    section: "ActivityReports",
  },
  {
    videoTitle: "Assign a User to an Activity Report",
    videoUrl: "/videos/activity-reports/assign-activity-report.mp4",
    userContexts: ["DivisionAdmin"],
    section: "ActivityReports",
  },
  {
    videoTitle: "Working Agency Submissions Reports",
    videoUrl: "/videos/activity-reports/working-reports.mp4",
    userContexts: ["DivisionUser"],
    section: "ActivityReports",
  },
  {
    videoTitle: "Mark an Agency Activity Report Deficient",
    videoUrl: "/videos/activity-reports/reject-activity-report.mp4",
    userContexts: ["DivisionAdmin", "DivisionUser"],
    section: "ActivityReports",
  },
  {
    videoTitle: "Revise a Submitted Activity Report",
    videoUrl: "/videos/activity-reports/revise-submitted-report.mp4",
    userContexts: ["AgencyAdmin", "AgencyUser"],
    section: "ActivityReports",
  },
  {
    videoTitle: "Approve an Agency Activity Report",
    videoUrl: "/videos/activity-reports/approve-report.mp4",
    userContexts: ["DivisionAdmin", "DivisionUser"],
    section: "ActivityReports",
  },
  {
    videoTitle: "Create and Submit a Goal Plan",
    videoUrl: "/videos/goal-plans/submit-goal-plan.mp4",
    userContexts: ["AgencyAdmin", "AgencyUser"],
    section: "GoalPlans",
  },
  {
    videoTitle: "Assign a User to a Goal Plan",
    videoUrl: "/videos/goal-plans/assign-a-goal-plan.mp4",
    userContexts: ["DivisionAdmin", "DivisionUser"],
    section: "GoalPlans",
  },
  {
    videoTitle: "Mark an Agency Goal Plan Deficient",
    videoUrl: "/videos/goal-plans/reject-goal-plan.mp4",
    userContexts: ["DivisionAdmin", "DivisionUser"],
    section: "GoalPlans",
  },
  {
    videoTitle: "Revise a Submitted Goal Plan",
    videoUrl: "/videos/goal-plans/resubmit-goal-plan.mp4",
    userContexts: ["AgencyAdmin", "AgencyUser"],
    section: "GoalPlans",
  },
  {
    videoTitle: "Approve an Agency Goal Plan",
    videoUrl: "/videos/goal-plans/approve-goal-plan.mp4",
    userContexts: ["DivisionAdmin", "DivisionUser"],
    section: "GoalPlans",
  },
  {
    videoTitle: "Create and Submit Narrative Statement",
    videoUrl: "/videos/narrative-statements/create-narrative-statement.mp4",
    userContexts: ["AgencyAdmin", "AgencyUser"],
    section: "NarrativeStatements",
  },
  {
    videoTitle: "Assign a User to a Narrative Statement",
    videoUrl: "/videos/narrative-statements/assign-narrative-statement.mp4",
    userContexts: ["DivisionAdmin"],
    section: "NarrativeStatements",
  },
  {
    videoTitle: "Mark an Agency Narrative Statement Deficient",
    videoUrl: "/videos/narrative-statements/reject-narrative-statement.mp4",
    userContexts: ["DivisionAdmin", "DivisionUser"],
    section: "NarrativeStatements",
  },
  {
    videoTitle: "Revise a Submitted Narrative Statement",
    videoUrl: "/videos/narrative-statements/resubmit-narrative-statement.mp4",
    userContexts: ["AgencyAdmin", "AgencyUser"],
    section: "NarrativeStatements",
  },
  {
    videoTitle: "Approve an Agency Narrative Statement",
    videoUrl: "/videos/narrative-statements/approve-narrative-statement.mp4",
    userContexts: ["DivisionAdmin", "DivisionUser"],
    section: "NarrativeStatements",
  },
  {
    videoTitle: "Grant an Extension Request",
    videoUrl: "/videos/activity-reports/grant-extension.mp4",
    userContexts: ["DivisionAdmin", "DivisionUser"],
    section: "ActivityReports",
  },
  /// END START AGENCY SUBMISSIONS SECTION
  /// START DIVISION APPLICATION SECTION - (0)
  {
    videoTitle: "Working applications",
    videoUrl: "/videos/application-management/working-an-application.mp4",
    userContexts: ["DivisionAdmin", "DivisionUser"],
    section: "ApplicationManagement",
  },
  {
    videoTitle: "Pausing Application Timers",
    videoUrl: "/videos/application-management/pausing-application-timers.mp4",
    userContexts: ["DivisionAdmin"],
    section: "ApplicationManagement",
  },
  {
    videoTitle: "Moving to Indepth Review",
    videoUrl: "/videos/application-management/moving-toindepth-review.mp4",
    userContexts: ["DivisionAdmin", "DivisionUser"],
    section: "ApplicationManagement",
  },
  {
    videoTitle: "Marking an Application Deficient",
    videoUrl: "/videos/application-management/marking-application-deficient.mp4",
    userContexts: ["DivisionAdmin", "DivisionUser"],
    section: "ApplicationManagement",
  },
  {
    videoTitle: "Complete In-depth review & Certify",
    videoUrl: "/videos/application-management/complete-indepth-review.mp4",
    userContexts: ["DivisionAdmin", "DivisionUser"],
    section: "ApplicationManagement",
  },
  {
    videoTitle: "Deny an Application",
    videoUrl: "/videos/application-management/deny-application.mp4",
    userContexts: ["DivisionAdmin", "DivisionUser"],
    section: "ApplicationManagement",
  },
  {
    videoTitle: "Handling Appeals",
    videoUrl: "/videos/application-management/handling-appeals.mp4",
    userContexts: ["DivisionAdmin", "DivisionUser"],
    section: "ApplicationManagement",
  },
  {
    videoTitle: "Handling Final Denial",
    videoUrl: "/videos/application-management/handle-final-denial.mp4",
    userContexts: ["DivisionAdmin", "DivisionUser"],
    section: "ApplicationManagement",
  },
  /// END DIVISION APPLICATION SECTION
  /// START DIVISION PORTAL MANAGEMENT SECTION  (3)
  {
    videoTitle: "Manage Users for an Agency",
    videoUrl: "/videos/system/manage-agency-users.mp4",
    userContexts: ["DivisionAdmin", "DivisionUser"],
    section: "UserManagement",
  },
  {
    videoTitle: "Vendor Management Unit (VMU) Process and Configuration",
    videoUrl: "/videos/system/vmu-export-process.mp4",
    userContexts: ["DivisionAdmin", "DivisionUser"],
    section: "System",
  },
  {
    videoTitle: "Set Goal Plan Additional Information Text",
    videoUrl: "/videos/system/set-goal-plan-text.mp4",
    userContexts: ["DivisionAdmin", "DivisionUser"],
    section: "System",
  },
  {
    videoTitle: "Creating an Account for the Portal",
    videoUrl: "/videos/authentication/create-account.mp4",
    videoId: "6sYVBE5fXOY",
    userContexts: ["None"],
    section: "Authentication",
  },
  {
    videoTitle: "Manage Friendly Names for Centralized Contracts",
    videoUrl: "/videos/system/manage-centralized-contracts.mp4",
    userContexts: ["DivisionAdmin", "DivisionUser"],
    section: "ManageBusiness",
  },
  {
    videoTitle: "Correspond with the Agency about a Contract",
    videoUrl: "/videos/correspondence/business-agency-correspondence.mp4",
    userContexts: ["Business", "SDVOB"],
    section: "Correspondence",
  },
  {
    videoTitle: "Correspond with the Prime Contractor on the Award",
    videoUrl: "/videos/correspondence/agency-business-correspondence.mp4",
    userContexts: ["AgencyAdmin", "AgencyUser"],
    section: "Correspondence",
  },
  /// END DIVISION PORTAL MANAGEMENT SECTION
  // START REPORTING SECTION - (7)
  {
    videoTitle: "Custom Reporting Overview",
    videoUrl: "/videos/reporting/custom-reports.mp4",
    userContexts: ["AgencyAdmin", "DivisionAdmin", "DivisionUser"],
    section: "Reporting",
  },
  {
    videoTitle: "Report Types",
    videoUrl: "/videos/reporting/report-types.mp4",
    userContexts: ["AgencyAdmin", "DivisionAdmin", "DivisionUser"],
    section: "Reporting",
  },
  {
    videoTitle: "Running and Downloading a Report",
    videoUrl: "/videos/reporting/run-report.mp4",
    userContexts: ["AgencyAdmin", "DivisionAdmin", "DivisionUser"],
    section: "Reporting",
  },
  {
    videoTitle: "Filter a Report",
    videoUrl: "/videos/reporting/filter-report.mp4",
    userContexts: ["AgencyAdmin", "DivisionAdmin", "DivisionUser"],
    section: "Reporting",
  },
  {
    videoTitle: "Save a Custom Report Query",
    videoUrl: "/videos/reporting/save-report.mp4",
    userContexts: ["AgencyAdmin", "DivisionAdmin", "DivisionUser"],
    section: "Reporting",
  },
  {
    videoTitle: "Modify or Copy a Saved Report Query",
    videoUrl: "/videos/reporting/saveas-report.mp4",
    userContexts: ["AgencyAdmin", "DivisionAdmin", "DivisionUser"],
    section: "Reporting",
  },
  {
    videoTitle: "Share a Custom Report Query",
    videoUrl: "/videos/reporting/share-report.mp4",
    userContexts: ["AgencyAdmin", "DivisionAdmin", "DivisionUser"],
    section: "Reporting",
  },
  {
    videoTitle: "Delete a Custom Report Query",
    videoUrl: "/videos/reporting/delete-report.mp4",
    userContexts: ["AgencyAdmin", "DivisionAdmin", "DivisionUser"],
    section: "Reporting",
  },
  // END REPORTING SECTION
];

export const trainingSections = [
  {
    id: "Directory",
    sectionTitle: "Service-Disabled Veterans' Owned Business Directory",
    description: "Learn how to use the Public Directory of Service-Disabled Veteran's Owned Businesses.",
  },
  {
    id: "Authentication",
    sectionTitle: "Authentication",
    description: "Learn how to create accounts and log in to the Portal.",
  },
  {
    id: "Register",
    sectionTitle: "Register a Business",
    description: "Learn how to register or claim your business on the portal.",
  },
  {
    id: "PortalBasics",
    sectionTitle: "Portal Basics",
    description: "Learn what's available on the portal",
  },
  {
    id: "Application",
    sectionTitle: "Apply for Service-Disabled Veteran Owned Business Status",
    description: "Learn how to  apply to become a certified Service-Disabled Veteran Owned Business or fill out an application to recertify.",
  },
  {
    id: "ApplicationManagement",
    sectionTitle: "Working the Application Process",
    description: "Learn how to work applications for new businesses and recertifications.",
  },
  {
    id: "ManageBusiness",
    sectionTitle: "Manage your Business Listing",
    description: "Learn how to manage your business information.",
  },
  {
    id: "Procurements",
    sectionTitle: "Managing Procurements and Contracts",
    description: "Learn how to set-up procurements to accept Utilization Plans and Report to the DSDVBD",
  },
  {
    id: "UtilizationPlans",
    sectionTitle: "Utilization Plans",
    description: "Learn how to submit Utilization Plans for New York State Contracts",
  },
  {
    id: "Awards",
    sectionTitle: "Awards & Contracts",
    description: "Learn how to work with Contract Awards.",
  },
  {
    id: "Payments",
    sectionTitle: "Contract Payments",
    description: "Learn how to record Payments made to SDVOB Contractors.",
  },
  {
    id: "ComplianceReports",
    sectionTitle: "Compliance Reports",
    description: "Learn how to submit Monthly Compliance Reports.",
  },
  {
    id: "Correspondence",
    sectionTitle: "Correspondence",
    description: "Learn how to correspond with other system users.",
  },
  {
    id: "ActivityReports",
    sectionTitle: "Activity Reports",
    description: "Learn how to submit Quarterly Activity Reports to the DSDVBD",
  },
  {
    id: "GoalPlans",
    sectionTitle: "Goal Plans",
    description: "Learn how to submit Annual Goal Plans to the DSDVBD",
  },
  {
    id: "NarrativeStatements",
    sectionTitle: "Narrative Statements",
    description: "Learn how to submit Annual Narrative Statements to the DSDVBD",
  },
  {
    id: "UserManagement",
    sectionTitle: "User Management",
    description: "Learn how to manage what users can do.",
  },
  {
    id: "Import",
    sectionTitle: "Importing Contracts and Payments to Primes",
    description: "Learn how to for agencies / authorities that do not use SFS, import contract information into SDVES.",
  },
  {
    id: "Reporting",
    sectionTitle: "Reporting",
    description: "Learn how to run pre-defined and ad-hoc reports.",
  },
  {
    id: "System",
    sectionTitle: "System Administration",
    description: "Learn how to configure SDVES.",
  },
];

import React, { useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import get from "lodash/get";
import DatePicker from "react-date-picker";
import Button from "react-bootstrap/Button";
import { handleFileUpload } from "../../../util/api";
import DateTimePicker from "react-datetime-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import SignatureCanvas from "react-signature-canvas";
import InputComponentFormatted from "./InputComponentFormatted";
import { EMPTY_GUID } from "../../../util/typelistHelper";
import { parseErrors } from "../../../util/errorHelper";
import { getBackendErrors, handleDocumentClick } from "../../../util/helpers";
import InputComponentCheckboxRadio from "./InputComponentCheckboxRadio";

const InputComponent = (fieldsProps) => {
  const [sigPad, setSigPad] = useState({});
  const [showSigPad, setShowSigPad] = useState(true);
  const [fileLoading, setFileLoading] = useState(false);
  const [fileError, setFileError] = useState("");
  const {
    wrapperClass,
    documentType,
    // accept,
    type,
    isTypeheadCodeAnInteger,
    isTypeaheadASimpleArray,
    label,
    hideLabel,
    placeholder,
    filterBy,
    allowNew,
    options,
    className,
    childClass,
    inline,
    readOnly,
    required,
    subLabel,
    labelClass,
    disabled,
    readonly,
    labelWrapperClass,
    inputWrapperClass,
    min,
    onlyId,
    index,
    max,
    field,
    isSwitch,
    onChange,
    onFileUpload,
    shouldSelectHint,
    uniqueSelect,
    multiple,
    selected,
    form: { touched, errors, values, setFieldValue, setFieldTouched, setFieldError, dirty }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  } = fieldsProps;

  // console.log(setFieldValue);

  let { isdisabled } = fieldsProps;
  // isdisabled = (true) ? true : false;

  const { name, onBlur } = field;
  const apiBase = process.env.REACT_APP_API_BASE;
  const error = _.get(errors, name);
  const touch = _.get(touched, name);
  const value = _.get(values, name);

  // console.log({ error, errors, name });

  const showErrorsWithTouch = () => {
    return touch && error;
  };

  const showErrorsWithoutTouch = () => {
    return error ? true : false;
  };

  // https://gist.github.com/davoclavo/4424731
  const dataURItoBlob = (dataURI) => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(",")[1]);
    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var dw = new DataView(ab);
    for (var i = 0; i < byteString.length; i++) {
      dw.setUint8(i, byteString.charCodeAt(i));
    }
    // write the ArrayBuffer to a blob, and you're done
    return new Blob([ab], { type: mimeString });
  };

  const handleOnChangeNumber = (e) => {
    // https://stackoverflow.com/questions/64138536/react-input-type-number-fields-dont-trigger-onchange
    const symbolsArr = ["e", "E", "+", "-", "."];
    if (!symbolsArr.includes(e.key)) {
      let value = e.target.value;
      // console.log(e.target);
      if (value === "") {
        value = undefined;
        setFieldValue(name, value);
      }
    }
  };

  // const handleOnChangeRadio = (e) => {
  //   let value = e.target.value;
  //   if (value === "true") {
  //     value = true;
  //   } else if (value === "false") {
  //     value = false;
  //   }
  //   if (e.target.type === "radio") {
  //     if (onChange) onChange(e);
  //     setFieldValue(name, value);
  //   } else if (e.target.type === "checkbox") {
  //     let fieldValue;
  //     if (isBoolean) {
  //       // reverse the value for boolean checkboxes that become unchecked
  //       fieldValue = !e.target.checked && isBoolean ? !value : value;
  //     } else {
  //       fieldValue = _.get(values, name) ? _.get(values, name) : [];

  //       if (e.target.checked) {
  //         if (!fieldValue.includes(value)) {
  //           fieldValue.push(value);
  //         }
  //       } else {
  //         fieldValue = fieldValue.filter((item) => item !== value);
  //       }
  //     }
  //     setFieldValue(name, fieldValue);
  //     if (onChange) onChange(e);
  //   }
  // };

  // const displayValues = () => {
  //   const result = options.filter((option) => field.value?.includes(parseInt(option.code)));
  //   return result;
  // };

  // const handleTypeaheadChanges = (options1) => {
  //   // console.log(options1);
  //   const results = options1.map((option) => option.code);
  //   setFieldValue(name, results);
  // };

  const handleSignatureClear = (event) => {
    sigPad.clear();
  };

  const handleSignatureUpload = async (event) => {
    const dataUrl = sigPad.getTrimmedCanvas().toDataURL("image/png");
    const blob = dataURItoBlob(dataUrl);
    var documentFormData = new FormData();
    const description = "signature";
    documentFormData.append("description", description);
    documentFormData.append("document", blob);
    const url = value?.documentId && value?.documentId !== EMPTY_GUID ? `${apiBase}/documents/${value?.documentId}` : `${apiBase}/documents`;
    let result = await handleFileUpload(url, documentFormData);
    result = {
      ...result,
      description: description,
      type: "signature",
    };
    setFieldValue(name, result);
    setShowSigPad(false);
  };
  // Bind inputs to the form component state
  const handleDocumentUpload = async (event, onlyId, fileTypes) => {
    const typesArr = fileTypes ? fileTypes.split(",") : null;
    setFileLoading(true);
    setFileError("");
    if (event.target.files.length > 0) {
      const document = event.target.files[0];
      const description = event.target.files[0].name;
      // console.log({ typesArr, description, document });
      const fileIsRel = !typesArr?.length || typesArr.filter((type) => description.endsWith(type)).length ? true : false;
      // console.log(fileIsRel);
      if (description.endsWith) setFieldValue(name, { ...value });

      if (fileIsRel) {
        // documentFormData Object is needed due to file upload.
        let documentFormData = new FormData();
        documentFormData.append("description", description);
        documentFormData.append("document", document);
        const url = value?.documentId && value?.documentId !== EMPTY_GUID ? `${apiBase}/documents/${value?.documentId}` : `${apiBase}/documents`;
        try {
          let result = await handleFileUpload(url, documentFormData);
          if (!onlyId) {
            result = {
              ...result,
              description: description,
            };
            if (documentType) {
              result = {
                ...result,
                type: documentType,
              };
            }
          }
          setFieldValue(name, { ...value, ...result, exists: true });
          setFileError("");
          if (onFileUpload) {
            onFileUpload({ ...value, ...result });
          }
        } catch (e) {
          const erroMessage = getBackendErrors(e);
          setFileError(erroMessage);
        } finally {
          setFileLoading(false);
        }
      }
    } else {
      setFileError("File not correct type");
    }
    setFieldTouched(name, true);
  };
  const handleDocumentRemoval = (event) => {
    // console.log(event.target.getAttribute("data-target"));
    let value = { ...field.value, documentId: EMPTY_GUID, description: null };
    setFieldValue(name, value);
  };

  let InputEl = <input />;
  switch (type) {
    case "select":
      // console.log(fieldsProps);
      // const { form: { setFieldValue } } = fieldsProps;
      // console.log(onChange);
      const handleChange = onChange ? onChange : (event) => setFieldValue(event.target.name, event.target.value);

      InputEl = (
        <>
          <div id={`${name}-wrapper`} className={`fieldWrapper ${name}-wrapper ${wrapperClass ? wrapperClass : ""}`}>
            <label htmlFor={name} className={labelClass ? labelClass : null}>
              {label} {required !== false ? <span className="required text-danger">*</span> : null}
            </label>
            <select id={name} name={name} className={`w-100 ${props.className}`} disabled={isdisabled} {...field} value={field.value != null ? field.value : ""} {...props} onChange={handleChange}>
              {options.map((option, index) => {
                const selected = option[0] === value ? "selected" : "";
                return (
                  <option key={`${name}-${index}`} value={option[0]}>
                    {option[1]}
                  </option>
                );
              })}
            </select>
            {showErrorsWithTouch() && (
              <div id={`${name}-error`} className="error text-danger pt-2 small">
                {parseErrors(error)}
              </div>
            )}
          </div>
        </>
      );
      break;
    case "typeahead":
      const isOptionAnObject = Object.keys(options).length > 0 && typeof options[0] === "object" ? true : false;

      const onChangeLocal = (options1) => {
        if (isOptionAnObject) {
          const results = options1.map((option) => option.id);
          setFieldValue(name, results);
        } else {
          const results = options1.map((option) => (option.customOption ? option.label : option));
          const u_results = uniqueSelect ? _.uniq(results) : results;
          setFieldValue(name, u_results);
        }
        if (onChange) {
          onChange(options1);
        }
      };

      InputEl = (
        <div id={`${name}-typeahead`}>
          {label ? (
            <label htmlFor={name} className={labelClass ? labelClass : null}>
              {label} {required !== false ? <span className="required text-danger">*</span> : null}
            </label>
          ) : null}
          <Typeahead
            id={name}
            name={name}
            labelKay={name}
            allowNew={allowNew}
            className={wrapperClass}
            options={options}
            maxResults={10000}
            multiple={multiple}
            filterBy={filterBy}
            disabled={isdisabled}
            selected={isOptionAnObject ? options.filter((option) => value?.includes(option.id)) : value}
            placeholder={placeholder}
            onBlur={() => setFieldTouched(name, true)}
            onChange={onChangeLocal}
            inputProps={{ "aria-describedby": `${name}-typeahead-desc` }}
          />

          <div className="sr-only" id={`${name}-typeahead-desc`}>
            There are {options?.length} options available in this input. When typeahead results are available, you can use up and down arrows to navigate through the items, and enter to select. Tabs
            will navigate through the items in your selection where you can hit delete to remove from your selection.
          </div>
          {showErrorsWithTouch() && (
            <div id={`${name}-error`} className="error text-danger pt-2 small">
              {parseErrors(error)}
            </div>
          )}
        </div>
      );
      break;
    case "checkbox":
    case "radio":
      InputEl = (
        <div id={`${name}-wrapper`} className={`${name}-wrapper ${type}-wrapper ${wrapperClass ? wrapperClass : ""}`}>
          <InputComponentCheckboxRadio {...fieldsProps} isdisabled={isdisabled}></InputComponentCheckboxRadio>
          {showErrorsWithTouch() && <div className="error text-danger pt-2 small">{parseErrors(error)}</div>}
        </div>
      );
      break;
    case "dateTimePicker":
      InputEl = (
        <div id={`${name}-wrapper`} className={`fieldWrapper ${name}-wrapper ${type}-wrapper ${wrapperClass ? wrapperClass : ""}`}>
          <div className="mb-2">{label}</div>
          <DateTimePicker {...fieldsProps} isdisabled={isdisabled}></DateTimePicker>
          {showErrorsWithTouch() && <div className="error text-danger pt-2 small">{parseErrors(error)}</div>}
        </div>
      );
      break;
    case "fileonly":
      InputEl = (
        <div id={`${name}-wrapper`} className={`fieldWrapper type-file ${wrapperClass ? wrapperClass : null} `}>
          <label htmlFor={name}>
            <FontAwesomeIcon className="" icon={["fas", "plus"]} /> {label}
          </label>
          <input
            type={"file"}
            name={name}
            id={name}
            className={`w - 100 ${props.className} `}
            onClick={() => {
              setFieldValue("temp_field", "");
            }}
            onChange={(event) => {
              console.log("on change is firing");
              handleDocumentUpload(event, onlyId, props?.accept);
            }}
            {...props}
          />
          {showErrorsWithTouch() && <div className="error text-danger pt-2 small">{error?.description}</div>}
        </div>
      );
      break;
    case "file":
      // console.log(field.value);
      InputEl = (
        <div id={`${name}-wrapper`} className={`fieldWrapper type-${type ? type : "text"} ${wrapperClass ? wrapperClass : null} `}>
          {field.value?.description && field.value?.documentId && field.value?.documentId !== EMPTY_GUID ? (
            <div className="faux-document">
              <FontAwesomeIcon className="mr-3 text-primary" icon={["fas", "times"]} onClick={handleDocumentRemoval} data-target={name} />
              <span className="faux-link inline" onClick={() => handleDocumentClick(field.value?.documentId)}>
                {field.value.description}
              </span>
            </div>
          ) : (
            <>
              {fileLoading ? (
                <span className="loader loader-pulse">Loading...</span>
              ) : (
                <>
                  {isdisabled ? "" : <label htmlFor={name}>Upload Document</label>}
                  <input
                    type={type}
                    name={name}
                    min={min}
                    max={max}
                    id={name}
                    className={`w - 100 ${props.className} `}
                    onChange={(event) => {
                      handleDocumentUpload(event, onlyId);
                    }}
                    onBlur={onBlur}
                    {...props}
                  />
                  {subLabel ? <div className="sub-label small mt-2">{subLabel}</div> : null}
                  {showErrorsWithTouch() && value?.documentationExists !== false && !value?.documentId && <div className="error text-danger pt-2 small">{parseErrors(error.description)}</div>}
                  {fileError && <div className="error text-danger pt-2 small">{fileError}</div>}
                </>
              )}
            </>
          )}
        </div>
      );
      break;
    case "signature":
      InputEl = (
        <>
          <div id={`${name}-wrapper`} className={`fieldWrapper flex-column justify-content-end type-text ${wrapperClass ? wrapperClass : null} `}>
            <div>
              <label className="mb-1" htmlFor={name}>
                {`Signature`} {required !== false ? <span className="required text-danger">*</span> : null}
              </label>
            </div>
            {!value ? (
              <div className="row">
                <div className="col-md-7">
                  <p>
                    <SignatureCanvas
                      penColor="black"
                      backgroundColor="white"
                      canvasProps={{ width: 500, height: 200, className: "sigPad" }}
                      ref={(ref) => setSigPad(ref)}
                      disabled={true}
                      clearOnResize={true}
                    />
                  </p>
                  <div className="d-flex flex-row justify-content-between">
                    <div>
                      {/* <Button onClick={() => setShowSigPad(false)} className="faux-link small font-weight-normal small mr-md-4">
                        Cancel
                      </Button> */}
                      <Button onClick={handleSignatureClear} className="faux-link small font-weight-normal small mr-md-4">
                        Clear
                      </Button>
                    </div>
                    <div>
                      <Button onClick={handleSignatureUpload} className="faux-link small font-weight-normal small">
                        Save Signature
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {value && <>Signature received</>}
                <br />
                <Button onClick={() => setFieldValue(name)}>Delete Signature</Button>
              </>
            )}
            {/* <button onClick={this.trim}>
              Sign
            </button> */}
            {/* <button onClick={handleSignatureClear}>
              Clear
            </button> */}
          </div>
        </>
      );
      break;
    case "textarea":
      InputEl = (
        <div id={`${name}-wrapper`} className={`fieldWrapper d-flex flex-column justify-content-end type-${type ? type : "text"} ${wrapperClass ? wrapperClass : null} `}>
          <div>
            <label htmlFor={name} className={labelClass ? labelClass : null}>
              {label} {required !== false ? <span className="required text-danger">*</span> : null}
              <textarea type={type} name={name} id={name} disabled={isdisabled} className={`w-100 ${props.className} `} {...field} value={field.value != null ? field.value : ""} {...props} />
              {subLabel ? <div className="sub-label small mt-2">{subLabel}</div> : null}
              {showErrorsWithTouch() && (
                <div id={`${name}-error`} className="error text-danger pt-2 small">
                  {parseErrors(error)}
                </div>
              )}
            </label>
          </div>
        </div>
      );
      break;
    case "datepicker":
      InputEl = (
        <div id={`${name}-wrapper`} className={`fieldWrapper d-flex flex-column justify-content-end type-${type ? type : "text"} ${wrapperClass ? wrapperClass : null} `}>
          <div>
            <label className={labelClass ? labelClass : null}>
              {label} {required !== false ? <span className="required text-danger">*</span> : null}
              <DatePicker
                onChange={(value) => {
                  setFieldValue(name, value);
                  // setFieldTouched(name, true);
                }}
                // onBlur={setFieldTouched(name, true)}
                value={get(values, name)}
                {...props}
              />
              {showErrorsWithTouch() && (
                <div id={`${name}-error`} className="error text-danger pt-2 small">
                  {parseErrors(error)}
                </div>
              )}
            </label>
          </div>
        </div>
      );
      break;
    case "inputnumeral":
    case "inputdate":
    case "inputphone":
    case "inputFEIN":
    case "inputZip":
    case "inputphoneextension":
      InputEl = (
        <div id={`${name}-wrapper`} className={`fieldWrapper d-flex flex-column justify-content-end type-${type ? type : "text"} ${wrapperClass ? wrapperClass : null} `}>
          <div>
            <label htmlFor={name} className={labelClass ? labelClass : null}>
              {label} {required !== false ? <span className="required text-danger">*</span> : null}
            </label>
          </div>
          <div>
            <InputComponentFormatted isdisabled={isdisabled} className={`w-100 ${props.className} `} {...fieldsProps} onBlur={() => setFieldTouched(name, true)} />
            {subLabel ? <div className="sub-label small mt-2">{subLabel}</div> : null}
            {showErrorsWithTouch() && (
              <div id={`${name}-error`} className="error text-danger pt-2 small">
                {parseErrors(error)}
              </div>
            )}
          </div>
        </div>
      );
      break;
    case "number":
      InputEl = (
        <div
          id={`${name}-wrapper`}
          disabled={isdisabled}
          className={`fieldWrapper ${inline ? "row" : "d-flex flex-column justify-content-end"} type-${type ? type : "text"} ${wrapperClass ? wrapperClass : null} `}
        >
          <div className={labelWrapperClass ? labelWrapperClass : null}>
            <label htmlFor={name} className={labelClass ? labelClass : null}>
              {label} {required !== false ? <span className="required text-danger">*</span> : null}
            </label>
          </div>
          <div className={inputWrapperClass ? inputWrapperClass : null}>
            <input
              type={type}
              name={name}
              id={name}
              min={min}
              max={max}
              disabled={isdisabled}
              onKeyDown={handleOnChangeNumber}
              readOnly={readonly}
              placeholder={placeholder}
              className={`w - 100 ${props.className} `}
              {...field}
              value={field.value != null ? field.value : ""}
              {...props}
            />
            {subLabel ? <div className="sub-label small mt-2">{subLabel}</div> : null}
            {showErrorsWithTouch() && (
              <div id={`${name}-error`} className="error text-danger pt-2 small">
                {parseErrors(error)}
              </div>
            )}
          </div>
        </div>
      );
      break;
    case "eInput":
      InputEl = (
        <div id={`${name}-wrapper`} className={`fieldWrapper ${inline ? "row" : "d-flex flex-column justify-content-end"} type-${type ? type : "text"} ${wrapperClass ? wrapperClass : null} `}>
          {label && (
            <div className={labelWrapperClass ? labelWrapperClass : null}>
              <label htmlFor={name} className={labelClass ? labelClass : null}>
                {label} {required !== false ? <span className="required text-danger">*</span> : null}
              </label>
            </div>
          )}
          <div className={inputWrapperClass ? inputWrapperClass : null}>
            <input
              type={type}
              name={name}
              id={name}
              placeholder={placeholder}
              disabled={true}
              className={`w - 100 ${props.className} `}
              {...field}
              value={field.value != null ? field.value : ""}
              {...props}
            />
            <a href="/business-search" className="text-secondary">
              Edit <FontAwesomeIcon className="mr-2" icon={"question-circle"} size="lg" />
            </a>
            {subLabel ? <div className="sub-label small mt-2">{subLabel}</div> : null}
            {showErrorsWithTouch() && <div className="error text-danger pt-2 small">{parseErrors(error)}</div>}
          </div>
        </div>
      );
      break;
    default:
      InputEl = (
        <>
          <div id={`${name}-wrapper`} className={`fieldWrapper ${inline ? "" : "d-flex flex-column justify-content-end"} type-${type ? type : "text"} ${wrapperClass ? wrapperClass : null} `}>
            {label && (
              <div className={labelWrapperClass ? labelWrapperClass : null}>
                <label htmlFor={name} className={labelClass ? labelClass : null}>
                  {label} {required !== false ? <span className="required text-danger">*</span> : null}
                </label>
              </div>
            )}
            <div className={inputWrapperClass ? inputWrapperClass : null}>
              <input
                disabled={isdisabled}
                type={type}
                name={name}
                id={name}
                placeholder={placeholder}
                className={`w - 100 ${props.className} `}
                {...field}
                value={field.value != null ? field.value : ""}
              />
              {subLabel ? <div className="sub-label small mt-2">{subLabel}</div> : null}
              {showErrorsWithTouch() && (
                <div id={`${name}-error`} className="error text-danger pt-2 small">
                  {parseErrors(error)}
                </div>
              )}
            </div>
          </div>
        </>
      );
      break;
  }
  return InputEl;
};
export default InputComponent;

import React from "react";
import * as Yup from "yup";
import pickBy from "lodash/pickBy";
import { EMPTY_GUID, globalApplicationDocumentTypes, ownershipTypes, VetsFirstCertification, VetsFirstAltDocs, NGB22, DD214, goalPlanDocumentTypes } from "./typelistHelper";
import get from "lodash/get";
import _ from "lodash";
import { getTotalOwnership, getLocalDate } from "./helpers";

const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
const URL = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const testIfDocInvalid = (document) => {
  const { documentId, description } = document;
  return (!documentId || documentId === EMPTY_GUID) && !description;
};

export const parseErrors = (error) => {
  let message = error;
  // console.log(error);
  try {
    message = typeof JSON.parse(error) === "object" ? JSON.parse(error).message : error;
  } catch (e) {
    // console.error(`Error parsing message: ${e}`)
    // message = JSON.stringify(error);
  }
  return message;
};

export const buildErrorMessage = (message, slide) => {
  // return `{"message":"${message} \${path}", "slide":${slide}}`
  if (slide) {
    return `{"message":"${message}", "slide":${slide}}`;
  } else {
    return `{"message":"${message}", "slide":0}`;
  }
};

function buildErrorMessageWithOwnershipType(value) {
  const ownershipType = get(this.options.context, "ownershipType");
  if (ownershipType === "Coproration" && value === undefined) {
    return this.createError({
      message: buildErrorMessage(`Required ${this.path}`, 2),
      path: `${this.path}`, // Fieldname
    });
  } else {
    return true;
  }
}

const addressSchemaBuilder = (slide) => {
  return Yup.object().shape({
    // line1: Yup.string().required(buildErrorMessage("Address line 1 is required",slide)).nullable(),
    // city: Yup.string().required(buildErrorMessage("Address city is rquired",slide)).nullable(),
    // state: Yup.string().required(buildErrorMessage("Address state is required",slide)).nullable(),
    // zipCode: Yup.string().required(buildErrorMessage("Address zip code is required",slide)).nullable(),
    line1: Yup.string().test(function (value) {
      return value
        ? true
        : this.createError({
            message: buildErrorMessage(`Address Line 1 for ${this.parent.type} address is required`, slide),
            path: `${this.path}`, // Fieldname
          });
    }),
    city: Yup.string().test(function (value) {
      return value
        ? true
        : this.createError({
            message: buildErrorMessage(`City for ${this.parent.type} address is required`, slide),
            path: `${this.path}`, // Fieldname
          });
    }),
    state: Yup.string()
      .test(function (value) {
        return value && value !== ""
          ? true
          : this.createError({
              message: buildErrorMessage(`State for ${this.parent.type} address is required`, slide),
              path: `${this.path}`, // Fieldname
            });
      })
      .nullable(),
    zipCode: Yup.string().test(function (value) {
      if (!value) {
        return value
          ? true
          : this.createError({
              message: buildErrorMessage(`Zip for ${this.parent.type} address is required`, slide),
              path: `${this.path}`, // Fieldname
            });
      } else if (value.length !== 5) {
        return value
          ? true
          : this.createError({
              message: buildErrorMessage(`Zip for ${this.parent.type} is invalid`, slide),
              path: `${this.path}`, // Fieldname
            });
      } else {
        return true;
      }
    }),
  });
};

const businessAddressSchema = addressSchemaBuilder(2);

export const documentMap = {
  VetsFirstCertification: {
    DisplayName: "Vets First Certification",
    slide: 3,
  },
  VeteransAdministrationServiceConnectedDisabilityRating: {
    DisplayName: "Veterans Administration Service Connected Disability Rating",
    slide: 4,
  },
  FederalIdOrSocialSecurityNumberVerification: {
    DisplayName: "Federal ID Or Social Security Number Verification",
    slide: 4,
  },
  BusinessLicense: {
    DisplayName: "Business License",
    slide: 4,
  },
  Resume: {
    DisplayName: "Resume",
    slide: 4,
  },
  FranchiseTrustAgreementAmendments: {
    DisplayName: "Franchise Trust Agreement Amendments",
    slide: 4,
  },
  ArticlesOfOrganization: {
    DisplayName: "Articles of Organization",
    slide: 4,
  },
  OperatingAgreementAmendments: {
    DisplayName: "Operating Agreement Amendments",
    slide: 4,
  },
  ArticlesOfIncorporation: {
    DisplayName: "Articles of Incorporation",
    slide: 4,
  },
  CorporateMeetingMinutes: {
    DisplayName: "Corporate Meeting Minutes",
    slide: 4,
  },
  CorporateBylawsAmendments: {
    DisplayName: "Corporate Bylaws & Amendments",
    slide: 4,
  },
  StockTransferLedger: {
    DisplayName: "Stock Transfer Ledger",
    slide: 4,
  },
  PartnershipAgreementAmendments: {
    DisplayName: "Partnership Agreement Amendments",
    slide: 4,
  },
  LimitedLiabilityPartnershipRegistration: {
    DisplayName: "Limited Liability Partnership Registration",
    slide: 4,
  },
  TaxReturn: {
    DisplayName: "Tax Return",
    slide: 4,
  },
};

export const parseApplicationErrors = (errors) => {
  try {
    const { message, slide } = JSON.parse(errors);
    return { message, slide };
  } catch (e) {
    return { message: errors };
  }
};

const ownerAddressSchema = addressSchemaBuilder(1);

const ownerSchema = Yup.object().shape({
  name: Yup.string().required(buildErrorMessage("Owner name is required", 1)).nullable(),
  // addresses: Yup.array().of(addressSchema),
  title: Yup.string().required(buildErrorMessage("Owner title is required", 1)).nullable(),
  role: Yup.string().required(buildErrorMessage("Owner primary business function is required", 1)).nullable(),
  percentageOwnership: Yup.number()
    .min(1, "Ownership percentage must be greater than zero")
    .max(100, "Ownership percentage can not exceed 100%")
    .required(buildErrorMessage("Ownership percentage is required", 1))
    .test(ownerPercent100)
    .nullable(),
  isServiceDisabled: Yup.bool().required(buildErrorMessage("Owner's Service-disabled status is required", 1)).nullable(),
  percentageServiceDisabled: Yup.number().when("isServiceDisabled", {
    is: true,
    then: Yup.number().max(100, buildErrorMessage("Percentage of disability can not exceed 100", 1)).required(buildErrorMessage("Percentage of disability is required", 1)).nullable(),
  }),
  addresses: Yup.array().of(ownerAddressSchema),
  emailAddress: Yup.string().email(buildErrorMessage("Owner Email is not valid", 1)).required(buildErrorMessage("Owner Email is required", 1)).test(ownerEmailUniqueness).nullable(),
  phoneNumber: Yup.string().max(10, buildErrorMessage("Owner Phone Number may not be longer than ten digits", 1)).required(buildErrorMessage("Owner Phone Number is required", 1)).nullable(),
});

const primeContractorSignupSchemaShape = {
  legalName: Yup.string().when("isNewVendor", {
    is: true,
    then: Yup.string().required("Legal name is required").nullable(),
  }),
  federalId: Yup.number().required("Federal ID is required").nullable(),
  // nysVendorId: Yup.number().required("NYS Vendor ID is required").nullable(),
  // phoneNumber: Yup.number().when("isNewVendor", { is: true, then: Yup.number().required("Phone Number is required") }),
  phoneNumber: Yup.string()
    .when("isNewVendor", { is: true, then: Yup.string().max(10, buildErrorMessage("Phone Number may not be longer than ten digits", 1)).required(buildErrorMessage("Phone Number is required", 1)) })
    .nullable(),
  emailAddress: Yup.string().when("isNewVendor", { is: true, then: Yup.string().required("Business Email Address is required") }),
  addresses: Yup.array().when("isNewVendor", { is: true, then: Yup.array().of(ownerAddressSchema) }),
};

const businessLookupSchemaShape = {
  legalName: Yup.string().required("Legal name is required").nullable(),
  federalId: Yup.number().required("Federal ID is required"),
};

const editBusinessAsOwnerSchemaShape = {
  // phoneNumber: Yup.number().required(buildErrorMessage("Phone number is required", 1)).nullable(),
  phoneNumber: Yup.string().max(10, buildErrorMessage("Phone Number may not be longer than ten digits", 1)).required(buildErrorMessage("Phone Number is required", 1)).nullable(),
  emailAddress: Yup.string().required(buildErrorMessage("Business Email address is required", 1)).nullable(),
  addresses: Yup.array().of(ownerAddressSchema),
  counties: Yup.array().when(["isStatewide", "isCertifiedSdvob"], (isStatewide, isCertifiedSdvob) => {
    if (!isStatewide && isCertifiedSdvob) {
      return Yup.array().min(1, buildErrorMessage("You must choose at least one county or select 'state-wide'", 1));
    }
  }),
  countyLocatedIn: Yup.string()
    .when(["isStatewide", "isCertifiedSdvob"], (isStatewide, isCertifiedSdvob) => {
      if (!isStatewide && isCertifiedSdvob) {
        return Yup.string().required(buildErrorMessage("County where the business is located is required", 1)).nullable();
      }
    })
    .nullable(),
};

const editBusinessAsDivisionSchemaShape = {
  ...editBusinessAsOwnerSchemaShape,
  legalName: Yup.string().required("A legal name must be provided").nullable(),
  categories: Yup.array().min(1, buildErrorMessage("You must select at least one category", 8)),
  naicsCodes: Yup.array().min(1, buildErrorMessage("You must select at least one NAICS code", 8)),
};

const subcontractSchema = Yup.object().shape({
  contractorId: Yup.string().required(buildErrorMessage("A contractor must be selected", 1)).nullable(),
  description: Yup.string().required(buildErrorMessage("A description of work or supplies must be provided.", 1)).nullable(),
  term: Yup.string().nullable(),
  goal: Yup.number()
    .when(["$term"], (term) => {
      // console.log(term);
      if (term === "Term") {
        return Yup.number().required(buildErrorMessage("Subcontractor goal is required")).nullable();
      }
    })
    .nullable(),
  value: Yup.number()
    .when(["$term"], (term) => {
      // console.log(term);
      if (term === "Standard") {
        return Yup.number().required(buildErrorMessage("Subcontractor value is required")).nullable();
      }
    })
    .nullable(),
  // value: Yup.number().when(["term"], (term) => {
  //   if (term === "Standard") {
  //     return Yup.number().required(buildErrorMessage("Subcontractor value is required"));
  //   }
  // }),
  // goal: Yup.number().when("term", {
  //   is: "Term",
  //   then: Yup.number().required(buildErrorMessage("Subcontractor goal is required")),
  // }),
  // .test(function (value) {
  //   const term = get(this.options.context, "term");
  //   // return true;
  //   if (term === "Standard") {
  //     return this.createError({
  //       message: buildErrorMessage(`A dollar amount of anticipated subcontracts, supplies and services for this subcontractor.`),
  //       path: `${this.path}`, // Fieldname
  //     });
  //   } else {
  //     return true;
  //   }
  //   // .required(buildErrorMessage("A dollar amount of anticipated subcontracts, supplies and services for this subcontractor.", 1)).nullable(),
  // }),
});

const businessUtilizationPlanSchemaShape = {
  // goal: Yup.number().required("Goal % is required").nullable(),
  value: Yup.number().when("term", (term) => {
    if (term === "Standard") {
      return Yup.number().required("Value is required").nullable();
    }
  }),
  waiverDescription: Yup.string().when(["realGoal", "goal"], (realGoal, goal) => {
    if (realGoal < goal) {
      return Yup.string().required(`You must provide a waiver description if you can not meet a ${goal}% goal.`);
    }
  }),
  // waiverDescription: Yup.string().required("A Waiver description is required").nullable(),
  subcontracts: Yup.array().of(subcontractSchema),
};

const procurementSchemaShape = {
  solicitationNumber: Yup.string().required("Solicitation number is required").nullable(),
  startDate: Yup.date().when(["exclusionType"], (exclusionType) => {
    if (exclusionType !== "ExemptExcluded") {
      return Yup.date().required("Start Date is required");
    }
  }),
  endDate: Yup.date().when(["exclusionType"], (exclusionType) => {
    if (exclusionType !== "ExemptExcluded") {
      return Yup.date().required("End Date is required");
    }
  }),
  description: Yup.string().required("Description is required").nullable(),
  contractTerm: Yup.string().required("Compliance Calculation Type is required").nullable(),
};

const procurementUpdateSchemaShape = {
  ...procurementSchemaShape,
  // value: Yup.number().nullable(),
  currentValue: Yup.number().max(Yup.ref("value"), buildErrorMessage(`Contract Value for SDVOB Participation cannot exceed the contract value.`)),
};

function testYear(value) {
  // console.log(moment(value).year, value.getYear());
  if (new Date(value + "Z").getUTCFullYear() > new Date().getUTCFullYear()) {
    return this.createError({
      message: buildErrorMessage("Year cannot exceed current year", 9),
      path: `${this.path}`, // Fieldname
    });
  } else {
    return true;
  }
}

function testCentury(value) {
  const valueDate = new Date(value);
  const date2000 = new Date("2000-01-01T00:00:00.000");
  // console.log(JSON.stringify(value));
  if (parseInt(valueDate.toISOString().substring(0, 4)) < 1000) {
    return this.createError({
      message: buildErrorMessage("Must be a date, formatted as MM/DD/YYYY", null),
      path: `${this.path}`, // Fieldname
    });
  } else {
    return true;
  }
}

// function testTaxReceipt(value) {
//   if ((!this.parent.documentationExists || !this.parent.description)) {
//     return this.createError({
//       message: buildErrorMessage(`Receipt is required for tax year ending ${this.parent.yearNumber}`, 1),
//       path: `${this.path}`, // Fieldname
//     })
//   } else {
//     return true;
//   }
// }

function ownerPercent100(value) {
  const totalOwnership = getTotalOwnership(this.options.context);
  if (totalOwnership !== 100) {
    const message = totalOwnership > 100 ? "Total ownership cannot exceed 100%" : "Total ownership cannot be less than 100%";
    return this.createError({
      message: buildErrorMessage(message, 1),
      path: `${this.path}`, // Fieldname
    });
  } else {
    return true;
  }
}

function ownerEmailUniqueness(value) {
  const owners = this.options.context.owners;
  if (owners.filter((owner) => owner.emailAddress === value).length > 1) {
    const message = "This email is associated with another owner. The email addresses for owners must be unique to them.";
    return this.createError({
      message: buildErrorMessage(message, 1),
      path: `${this.path}`, // Fieldname
    });
  } else {
    return true;
  }
}

const applicationSchemaShape = {
  financialInformation: {
    taxReturns: Yup.array().of(
      Yup.object().shape({
        yearBeginning: Yup.string()
          .when("documentationExists", {
            is: false,
            otherwise: Yup.string().required(buildErrorMessage("Year beginning is required", 1)).min(8, buildErrorMessage("Invalid year", 1)).test(testYear),
          })
          .nullable(),
        yearEnding: Yup.string()
          .when("documentationExists", {
            is: false,
            otherwise: Yup.string().required(buildErrorMessage("Year ending is required", 1)).min(8, buildErrorMessage("Invalid year", 1)).test(testYear),
          })
          .nullable(),
        value: Yup.string()
          .when("documentationExists", {
            is: false,
            otherwise: Yup.string().required(buildErrorMessage("Gross Annual Receipt is required", 1)).nullable(),
          })
          .nullable(),
      })
    ),
  },

  businessInformation: {
    // needfieldPrev: Yup.bool().required(`< span > Required`).nullable(),
    legalName: Yup.string().required(buildErrorMessage("Legal name is required", 1)).nullable(),
    federalId: Yup.number(buildErrorMessage("Invalid number", 1))
      .required(buildErrorMessage("Federal ID is required", 1))
      // .min(100000000, buildErrorMessage("Invalid FEIN", 1))
      // .max(999999999, buildErrorMessage("Invalid FEIN", 1))
      .nullable(),
    addresses: Yup.array().of(businessAddressSchema).required(buildErrorMessage("Addresses are required", 2)),
    phoneNumber: Yup.string()
      // .matches(phoneRegExp, buildErrorMessage("Phone number is not valid", 2))
      .max(10, buildErrorMessage("Max 10 digits are allowed", 2))
      .required(buildErrorMessage("Phone Number is required", 2))
      .nullable(),
    emailAddress: Yup.string().email(buildErrorMessage("Email is not valid", 2)).required(buildErrorMessage("Email is required", 2)).nullable(),
    totalEmployees: Yup.number().min(1, buildErrorMessage("Total number of employees must be at least 1", 2)).required(buildErrorMessage("Total number of employees is required", 2)).nullable(),
    // isCertifiedVetsFirst: Yup.bool().required(buildErrorMessage("CVE status is required", 3)).nullable(),
    // cveCertEntities: Yup.string().when('isCertifiedVetsFirst', {
    //   is: true,
    //   then: Yup.string().required(buildErrorMessage("CVE certifying entities are required", 3)).nullable(),
    // }),
    countyLocatedIn: Yup.string().required(buildErrorMessage("County is required", 2)).nullable(),
    businessDescription: Yup.string().required(buildErrorMessage("Business description is required", 4)).nullable(),
    documents: Yup.array().of(
      Yup.object().shape({
        // https://github.com/jquense/yup/issues/222
        // https://github.com/formium/formik/issues/506
        description: Yup.string()
          .test(function (value) {
            const { documentId, description, type } = this.parent;
            let displayName = "";
            let slide = "";
            if (documentMap && type && documentMap[type]) {
              displayName = documentMap[type]?.DisplayName;
              slide = documentMap[type]?.slide;
            }

            const ownershipType = get(this.options.context, "ownershipType");
            const isCertifiedVetsFirst = get(this.options.context, "isCertifiedVetsFirst");
            const vetsFirstDoc = get(this.options.context, "documents").find((document) => document.type === VetsFirstCertification);
            const hasVetsFirstDoc = ![undefined, EMPTY_GUID].includes(vetsFirstDoc?.documentId);
            if (
              (ownershipType && !(isCertifiedVetsFirst && hasVetsFirstDoc) && ownershipTypes[ownershipType].documentTypes.includes(type)) ||
              globalApplicationDocumentTypes.includes(type) ||
              (isCertifiedVetsFirst && VetsFirstCertification === type)
              // (isCertifiedVetsFirst && !hasVetsFirstDoc && VetsFirstAltDocs.includes(type))
            ) {
              if ((!documentId || documentId === EMPTY_GUID) && !description) {
                return this.createError({
                  message: buildErrorMessage(`Document or description for ${displayName} is required`, slide),
                  path: `${this.path}`, // Fieldname
                });
              } else {
                return true;
              }
            } else {
              return true;
            }
          })
          .nullable(),
      })
    ),
    ownershipType: Yup.string().required(buildErrorMessage("Ownership type is required", 4)).nullable(),
    serviceTypes: Yup.array().min(1, buildErrorMessage("Business type is required", 5)).nullable(),
    isCertifiedMinorityOwned: Yup.bool().required(buildErrorMessage("MBE answer is required", 6)).nullable(),
    isCertifiedWomenOwned: Yup.bool().required(buildErrorMessage("WBE answer is required", 6)).nullable(),
    counties: Yup.array().when("isStatewide", {
      is: true,
      otherwise: Yup.array().min(1, buildErrorMessage("You must choose at least one county", 7)),
    }),
    categories: Yup.array().min(1, buildErrorMessage("You must have at least one category", 8)),
    naicsCodes: Yup.array().min(1, buildErrorMessage("You must have at least one NAICS code ", 8)),
    tags: Yup.array().min(1, buildErrorMessage("You must have at least one keyword", 8)),
    isEquipmentRenter: Yup.bool().required(buildErrorMessage("Equipment Renter must be answered", 9)).nullable(),
    isEquipmentOwner: Yup.bool()
      .test(function (value) {
        const isEquipmentRenter = get(this.options.context, "isEquipmentRenter");
        if (isEquipmentRenter && (value === undefined || value === null)) {
          return this.createError({
            message: buildErrorMessage("Equipment Owner must be answered", 9),
            path: `${this.path}`, // Fieldname
          });
        } else {
          return true;
        }
      })
      .nullable(),
    // homepageUrl: Yup.string().url().required(`< span > Required`).nullable(),
  },
  applicantInformation: {
    owners: Yup.array().of(ownerSchema),
    isOwnersResponsibleForSigningContracts: Yup.bool().required(buildErrorMessage("Owner is Responsible For Signing Contracts is required", 2)).nullable(),
    isOwnersResponsibleForFinancialContractExecution: Yup.bool().required(buildErrorMessage("Owner is Responsible For Financial Contract Execution is required", 2)).nullable(),
    isOwnersVotingPowersLimited: Yup.bool().required(buildErrorMessage("Owner's Voting Powers Limited is required", 2)).nullable(),
    isOwnersControlRestrictedByThirdParty: Yup.bool().required(buildErrorMessage("Owner's Control Restricted By Third Party is required", 2)).nullable(),
    isOwnersFieldQualified: Yup.bool().required(buildErrorMessage("Owner's Field Qualified is required", 2)).nullable(),
    isOwnersCompensationCommensurateWithStake: Yup.bool().required(buildErrorMessage("Owner's Compensation Commensurate With Stake is required", 2)).nullable(),
    isOwnersResponsibleForEquipment: Yup.bool().test(buildErrorMessageWithOwnershipType).nullable(),
    isOwnersResponsibleForMaterials: Yup.bool().test(buildErrorMessageWithOwnershipType).nullable(),
    isOwnersResponsibleForFacilities: Yup.bool().test(buildErrorMessageWithOwnershipType).nullable(),
    isOwnersReceiveDividendsMajority: Yup.bool().test(buildErrorMessageWithOwnershipType).nullable(),
    isOwnersControlBoardMajority: Yup.bool().test(buildErrorMessageWithOwnershipType).nullable(),
    isOwnersEntitledFullStockShareValue: Yup.bool().test(buildErrorMessageWithOwnershipType).nullable(),
    isOwnersResponsibleForSubcontractors: Yup.bool().required(buildErrorMessage("Owner is Responsible For Subcontractors is required", 2)).nullable(),
  },
};

export const getErrorsForTab = (errors, tabKey) => {
  if (applicationSchemaShape[tabKey]) {
    const errorTabKeys = Object.keys(applicationSchemaShape[tabKey]);
    const errorsForTab = pickBy(errors, (value, key) => errorTabKeys.includes(key));
    return errorsForTab;
  } else {
    return [];
  }
};

export const isErrorsInTab = (errors, tabKey) => {
  const errorsForTab = getErrorsForTab(errors, tabKey);
  // console.log(`${ tabKey } - ${ JSON.stringify(errorsForTab) }`);
  return Object.keys(errorsForTab).length === 0;
};

const attestationSchemaShape = {
  signature: Yup.object().shape({
    documentId: Yup.string().required(buildErrorMessage("Signature is required")),
  }),
  documents: Yup.array().of(
    Yup.object().shape({
      // https://github.com/jquense/yup/issues/222
      // https://github.com/formium/formik/issues/506
      type: Yup.string().test(function (value) {
        const { documentId, description, type } = this.parent;
        let displayName = "";
        const NGB22Doc = get(this.options.context, "documents").find((document) => document?.type === NGB22);
        const DD214Doc = get(this.options.context, "documents").find((document) => document?.type === DD214);
        // debugger;
        if (type === NGB22 || type === DD214) {
          if (testIfDocInvalid(DD214Doc) && testIfDocInvalid(NGB22Doc)) {
            return this.createError({
              message: "Document for NGB22 or DD214 is required",
            });
          } else {
            return true;
          }
        } else if (testIfDocInvalid(this.parent)) {
          return this.createError({
            message: `Document for ${_.startCase(type)} is required`,
          });
        } else {
          return true;
        }
      }),
    })
  ),
};

const editNarrativeStatementShape = {
  fiscalYear: Yup.number().required(buildErrorMessage("Fiscal year is required", 1)),
  commissionerName: Yup.string().when("narrativeStatementId", {
    is: (value) => value,
    then: Yup.string().required("Commissioner name is required").nullable(),
  }),
  chiefCounselName: Yup.string().when("narrativeStatementId", {
    is: (value) => value,
    then: Yup.string().required("Chief Counsel name is required").nullable(),
  }),
  chiefFinancialOfficerName: Yup.string().when("narrativeStatementId", {
    is: (value) => value,
    then: Yup.string().required("Chief Financial Officer name is required").nullable(),
  }),
  primarySDVOBComplianceContactName: Yup.string().when("narrativeStatementId", {
    is: (value) => value,
    then: Yup.string().required("Primary SDVOB Compliance Contact name is required").nullable(),
  }),
  primarySDVOBComplianceContactEmailAddress: Yup.string().when("narrativeStatementId", {
    is: (value) => value,
    then: Yup.string().required("Primary SDVOB Compliance email address is required").nullable(),
  }),
};

const createGoalPlanShape = {
  fiscalYear: Yup.string().required("Fiscal year is required"),
  totalAwards: Yup.number().when("goalPlanId", {
    is: (value) => value,
    then: Yup.number().required("Total Awards is required"),
  }),
  // discretionaries: Yup.number().when("goalPlanId", {
  //   is: (value) => value,
  //   then: Yup.number().required("Discrecionary is required"),
  // }),
  // sdvobSetAsides: Yup.number().when("goalPlanId", {
  //   is: (value) => value,
  //   then: Yup.number().required("SDVOB set asides is required"),
  // }),
  annualBudget: Yup.number().when("goalPlanId", {
    is: (value) => value,
    then: Yup.number().required("Annual Budget is required"),
  }),
  projectedSDVOBSpendingAmount: Yup.number().when("goalPlanId", {
    is: (value) => value,
    then: Yup.number().required("Projected SDVOB spending amount is required"),
  }),
};

const submitGoalPlanShape = {
  ...createGoalPlanShape,
  documents: Yup.array().of(
    Yup.object().shape({
      // https://github.com/jquense/yup/issues/222
      // https://github.com/formium/formik/issues/506
      type: Yup.string().test(function (value) {
        const { documentId, description, type } = this.parent;
        let displayName = "";
        if (testIfDocInvalid(this.parent)) {
          return this.createError({
            message: buildErrorMessage(`Document for ${type} is required`),
            path: `${this.path} `, // Fieldname
          });
        } else {
          return true;
        }
      }),
    })
  ),
  sdvobWebsiteContentUrl: Yup.string().when("goalPlanId", {
    is: (value) => value,
    then: Yup.string().matches(URL, buildErrorMessage("Must be a valid URL", 2)).nullable(),
  }),
  specificDocuments: Yup.array().when("goalPlanId", {
    is: (value) => value,
    then: Yup.array().of(
      Yup.object().shape({
        // https://github.com/jquense/yup/issues/222
        // https://github.com/formium/formik/issues/506
        type: Yup.string()
          .test(function (value) {
            const { sdvobWebsiteContentUrl } = this.from[1].value;
            const { documentId, description, type } = this.parent;
            if (testIfDocInvalid(this.parent) && (type !== "SDVOBWebsiteContentPlan" || !sdvobWebsiteContentUrl)) {
              return this.createError({ message: `Document for ${_.startCase(type)} is required` });
            } else {
              return true;
            }
          })
          .nullable(),
      })
    ),
  }),
};

const editActivityReportShape = {
  fiscalYear: Yup.number().required("Fiscal year is required"),
  fiscalQuarter: Yup.number().required("Fiscal quarter is required"),
  totalNumberOfAwards: Yup.number().when("activityReportId", {
    is: (value) => value,
    then: Yup.number().required("Total number of awards to SDVOBs is required"),
  }),
  totalValueOfAwards: Yup.number().when("activityReportId", {
    is: (value) => value,
    then: Yup.number().required("Total Value of Awards to SDVOBs is required"),
  }),
  totalDisbursementsToSdvobs: Yup.number().when("activityReportId", {
    is: (value) => value,
    then: Yup.number().required("Total disbursements to SDVOB vendors is required"),
  }),
  nonExemptNonExcludedPaymentTotal: Yup.number().when("activityReportId", {
    is: (value) => value,
    then: Yup.number().required("Non-exempt non-excluded payment total is required"),
  }),
  // utilizationPercentage: Yup.number().when("activityReportId", {
  //   is: (value) => value,
  //   then: Yup.number().required("Utilization percentage is required"),
  // }),
  totalNumberOfWaiversGranted: Yup.number().when("activityReportId", {
    is: (value) => value,
    then: Yup.number().required("Total number of waivers granted is required"),
  }),
  totalAdditionalSpendOnSdvobs: Yup.number().when("activityReportId", {
    is: (value) => value,
    then: Yup.number().required("Total additional speend on SDVOBs is required"),
  }),
};

const divisionReportSearch = {
  fiscalYear: Yup.number().required("Fiscal year is required").nullable(),
  status: Yup.string().required("Status is required").nullable(),
};

const primeInviteShape = {
  firstName: Yup.string().required("First Name is required").nullable(),
  lastName: Yup.string().required("Last Name is required").nullable(),
  emailAddress: Yup.string().email("Email address is not valid").required("An email address is required").nullable(),
};

const editCentralizedContractSchemaShape = {
  contractNumber: Yup.string().required("Contract number is required").nullable(),
  name: Yup.string().required("Contract name is required").nullable(),
  active: Yup.bool().required("Active status is required").nullable(),
};

const divisionActivityReportSearch = {
  ...divisionReportSearch,
  fiscalQuarter: Yup.number().required("Fiscal quarter is required").nullable(),
};

const systemPreferencesShape = {
  ogsvmuContactEmail: Yup.string().required("VMU Contact Email is required").nullable(),
  ogsvmuContactName: Yup.string().required("VMU Contact Name is required").nullable(),
};

const activityLogSchemaShape = {};
const changeRequestSchemaShape = {};
const correspondenceSchemaShape = {
  subject: Yup.string().when("correspondenceId", {
    is: (value) => !!value,
    otherwise: Yup.string().min(5, "Subject must be at least 5 characters").required("Subject is required"),
  }),
  message: Yup.string().min(5, "Message must be at least 5 characters").required("Message is required"),
};

const addPaymentSchemaShape = {
  contractorId: Yup.string()
    .matches(/^(?!Choose Contractor\b)/i, "A contractor must be selected")
    .required("A contractor must be selected"),
  amount: Yup.number().min(1, "Payment amount must be greater than zero").required("A payment must be entered"),
  // paidDate: Yup.string().required("Please enter the date of the payment"),
  paidDate: Yup.string().required("Please enter the date of the payment").test(testCentury),
};

const reportStatusFormShape = {
  status: Yup.string()
    .test("A status must be selected", "A status must be selected", (value) => ["reject", "accept"].includes(value))
    .required("A status must be selected"),
  message: Yup.string().when("status", {
    is: "reject",
    then: Yup.string().min(5, "Message must be at least 5 characters").required("Message is required"),
  }),
};

const dataImportFormShape = {
  importType: Yup.string()
    .test("Import type must be selected", "Import type must be selected", (value) => ["contracts", "activity"].includes(value))
    .required("Import type must be selected")
    .nullable(),
  importFile: Yup.object().shape({
    exists: Yup.bool().required("Document must be attached").nullable(),
    description: Yup.string()
      .test("File type must be CSV", "File type must be CSV", (value) => {
        console.log(value);
        return value && value.endsWith("csv");
      })
      .nullable(),
  }),
};

const addBusinessUserShape = {
  userId: Yup.string().required("You must select a user to add").nullable(),
};

export const schemas = {
  applicationSchema: Yup.object().shape({
    ...applicationSchemaShape.applicantInformation,
    ...applicationSchemaShape.businessInformation,
    ...applicationSchemaShape.financialInformation,
  }),
  applicationSchemaShape,
  attestationSchema: Yup.object().shape({
    ...attestationSchemaShape,
  }),
  businessUtilizationPlanSchema: Yup.object().shape({
    ...businessUtilizationPlanSchemaShape,
  }),
  procurementSchema: Yup.object().shape({
    ...procurementSchemaShape,
  }),
  procurementUpdateSchema: Yup.object().shape({
    ...procurementUpdateSchemaShape,
  }),
  activityLogSchema: Yup.object().shape({
    ...activityLogSchemaShape,
  }),
  changeRequestSchema: Yup.object().shape({
    ...changeRequestSchemaShape,
  }),
  correspondenceSchema: Yup.object().shape({
    ...correspondenceSchemaShape,
  }),
  primeContractorSignupSchema: Yup.object().shape({
    ...primeContractorSignupSchemaShape,
  }),
  businessLookupSchema: Yup.object().shape({
    ...businessLookupSchemaShape,
  }),
  editBusinessAsOwnerSchema: Yup.object().shape({
    ...editBusinessAsOwnerSchemaShape,
  }),
  editBusinessAsDivisionSchema: Yup.object().shape({
    ...editBusinessAsDivisionSchemaShape,
  }),
  addBusinessUserSchema: Yup.object().shape({
    ...addBusinessUserShape,
  }),
  systemPreferencesSchema: Yup.object().shape({
    ...systemPreferencesShape,
  }),
  editNarrativeStatementSchema: Yup.object().shape({
    ...editNarrativeStatementShape,
  }),
  // createGoalPlanSchema: Yup.object().shape({
  //   ...createGoalPlanShape,
  // }),
  submitGoalPlanSchema: Yup.object().shape({
    ...submitGoalPlanShape,
  }),
  editActivityReportSchema: Yup.object().shape({
    ...editActivityReportShape,
  }),
  addPaymentSchema: Yup.object().shape({
    ...addPaymentSchemaShape,
  }),
  divisionReportSearchSchema: Yup.object().shape({
    ...divisionReportSearch,
  }),
  divisionActivityReportSearchSchema: Yup.object().shape({
    ...divisionActivityReportSearch,
  }),
  reportStatusFormSchema: Yup.object().shape({
    ...reportStatusFormShape,
  }),
  editCentralizedContractSchema: Yup.object().shape({
    ...editCentralizedContractSchemaShape,
  }),
  dataImportFormSchema: Yup.object().shape({
    ...dataImportFormShape,
  }),
  primeInviteSchema: Yup.object().shape({
    ...primeInviteShape,
  }),
};

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getAgencies, getPlansBySubontractor, confirmPlanPresence } from "../../../util/api";
import { outputAwardStatus, outputMoney, outputPercentage } from "../../../util/helpers";
import { withFormikFormComponent } from "../../Forms/FormikForm";
import FilterTableForm from "../../Forms/Input/FilterTableForm";
import PlanPresenceStatusForm from "./PlanPresenceStatusForm";

const FilterListConfig = {
  primeContractorLegalName: {
    isJoin: false,
  },
  solicitationNumber: {
    isJoin: false,
  },
  contractNumber: {
    isJoin: false,
  },
  planInclusionAcknowledgementStatus: {
    isJoin: false,
  },
};

const Columns = [
  {
    Header: "Prime Contractor",
    accessor: "primeContractorLegalName", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "primeContractorLegalName",
  },
  {
    Header: "Solicitation Number",
    accessor: "solicitationNumber", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "solicitationNumber",
  },
  {
    Header: "Contract",
    accessor: "contractNumber", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "contractNumber",
  },
  {
    Header: "Contract Term",
    accessor: "contractTerm", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "contractTerm",
  },
  {
    Header: "Description",
    accessor: "description", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "description",
  },
  {
    Header: "Status",
    accessor: "planInclusionAcknowledgementStatus", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "planInclusionAcknowledgementStatus",
  },
  {
    Header: "Actions",
    accessor: "actions", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "actions",
  },
  {
    Header: "Goal/Value",
    accessor: "goalOrValue", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "goalOrValue",
  },
];

const BusinessUtilizationPlanListInternal = () => {
  const [utilizationPlans, setUtilizationPlans] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [updateCount, setUpdateCount] = useState(0);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      const plansRes = await getPlansBySubontractor();
      setUtilizationPlans(plansRes);
      setIsLoading(false);

      const agenciesRes = await getAgencies();
      setAgencies(agenciesRes.agencies);
    }
    fetchData();
  }, [updateCount]);

  const initialItems = utilizationPlans?.length
    ? utilizationPlans?.map((item, index) => {
        const handleSubmit = async (action) => {
          const resObj = await confirmPlanPresence(item.id, action);
          setUpdateCount(updateCount + 1);
        };
        return {
          ...item,
          contractNumber: item.contractNumber ? item.contractNumber : "Contract not yet awarded",
          agency: agencies.find((agency) => agency.id == item.agencyId)?.name,
          actions: <PlanPresenceStatusForm index={index} status={item.planInclusionAcknowledgementStatus} routeInitialValues={{}} onSubmit={handleSubmit} />,
          goalOrValue: item.contractTerm == "Standard" ? outputMoney(item.value) : outputPercentage(item.goal / 100),
        };
      })
    : [];

  return (
    <section id="utilization-plans-presence-list">
      <div>
        <h2>Utilization Plan Presence</h2>
        <p>Confirm Prime Contractor plans you participate in or identify plans where you appear but have not been notified</p>
        {initialItems.length ? (
          <FilterTableForm tableName="business-utilization-plans-presence-list" Columns={Columns} FilterListConfig={FilterListConfig} tableItems={initialItems} />
        ) : (
          <div>
            {isLoading ? (
              <span className="loader loader-pulse">Loading utilization plans on which you are listed as participating...</span>
            ) : (
              <span>You have no Utilization Plan participation to confirm</span>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

const BusinessUtilizationPlanList = withFormikFormComponent(BusinessUtilizationPlanListInternal);
export default BusinessUtilizationPlanList;

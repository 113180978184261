import React, { useState, useEffect } from "react";
import FilterTableForm from "../../Forms/Input/FilterTableForm";
import { procurementStatuses } from "../../../util/typelistHelper";
import { getAgencies } from "../../../util/api";

const BusinessProcurementsList = (props) => {
  const { initialItems, prime, isLoadingPrime } = props;
  const [agenciesOptions, setAgenciesOptions] = useState([]);
  const [agencies, setAgencies] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const resObj = await getAgencies();
      setAgenciesOptions(resObj.agencies.map((item) => ({ label: item.name, id: item.name })));
    };

    fetchData();
  }, []);

  const FilterListConfig = {
    description: {
      isJoin: false,
    },
    solicitationNumber: {
      isJoin: false,
    },
    agencyName: {
      type: "typeahead",
      options: agenciesOptions,
    },
    status: {
      isJoin: false,
      type: "typeahead",
      options: procurementStatuses,
    },
  };

  let Columns = [
    {
      Header: "Contract",
      accessor: "descriptionLink", // accessor is the "key" in the data
      disableSortBy: true,
      filterCode: "description",
    },
    {
      Header: "Status",
      accessor: "status", // accessor is the "key" in the data
      disableSortBy: true,
      filterCode: "status",
    },
    {
      Header: "Agency/Authority",
      accessor: "agencyName", // accessor is the "key" in the data
      // disableSortBy: true,
      filterCode: "agencyName",
    },
  ];

  Columns = prime
    ? [
        ...Columns,
        {
          Header: "Role",
          accessor: "role", // accessor is the "key" in the data
          disableSortBy: true,
          filterCode: "role",
        },
        {
          Header: "Active Period",
          accessor: "activePeriod", // accessor is the "key" in the data
          disableSortBy: true,
          filterCode: "activePeriod",
        },
        {
          Header: "Utilization Plan",
          accessor: "utilizationPlanLink", // accessor is the "key" in the data
          disableSortBy: true,
          filterCode: "plan",
        },
      ]
    : Columns;

  Columns = prime
    ? [
        {
          Header: "Solicitation",
          accessor: "solicitationNumber", // accessor is the "key" in the data
          disableSortBy: true,
          filterCode: "solicitationNumber",
        },
        ...Columns,
      ]
    : Columns;

  return (
    <section id="procurements-list">
      <div>
        {initialItems.length ? (
          <FilterTableForm tableName="business-procurement-list" Columns={Columns} FilterListConfig={FilterListConfig} tableItems={initialItems} />
        ) : (
          <h3>{isLoadingPrime ? <span className="loader loader-pulse">Loading your procurements...</span> : "There are currently no procurements assigned to your business"}</h3>
        )}
      </div>
    </section>
  );
};

export default BusinessProcurementsList;

import React from "react";

const NarrativeStatementDisplayData = (props) => {
  let { report, reportId, isVersion } = props;

  return (
    <>
      {report?.id ? (
        <>
          <div className="row mb-2">
            <div className="col-5">Commissioner Name</div>
            <div className="col-7">{report?.commissionerName}</div>
          </div>
          <div className="row mb-2">
            <div className="col-5">Chief Counsel Name</div>
            <div className="col-7">{report?.chiefCounselName}</div>
          </div>
          <div className="row mb-2">
            <div className="col-5">Chief Financial Officer Name</div>
            <div className="col-7">{report?.chiefFinancialOfficerName}</div>
          </div>
          <div className="row mb-2">
            <div className="col-5">Primary SDVOB Compliance Contact Name</div>
            <div className="col-7">{report?.primarySDVOBComplianceContactName}</div>
          </div>
          <div className="row mb-2">
            <div className="col-5">Primary SDVOB Compliance Email Address</div>
            <div className="col-7">{report?.primarySDVOBComplianceContactEmailAddress}</div>
          </div>
          <div className="row mb-2">
            <div className="col-5">Statement Text</div>
            <div className="col-7">{report?.statementText}</div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default NarrativeStatementDisplayData;

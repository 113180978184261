import React, { useEffect, useState } from "react";
import { getCentralizedContracts } from "../../../util/api";
import FilterTableForm from "../../Forms/Input/FilterTableForm";
import { Link, useLocation } from "react-router-dom";
import { tableSortByValue } from "../../../util/helpers";

const CentralizedContractList = (props) => {
  const { updateCount } = props;
  const { updateValues, setUpdateValues } = props;

  const sortByName = (rowA, rowB, name, other) => {
    return tableSortByValue(rowA, rowB, "name");
  };

  const FilterListConfig = {
    name: {},
    contractNumber: {},
  };

  const Columns = [
    {
      Header: "Name",
      accessor: "nameLink", // accessor is the "key" in the data
      // disableSortBy: true,
      filterCode: "nameLink",
      isVisible: true,
      sortType: sortByName,
    },
    {
      Header: "Name",
      accessor: "name", // accessor is the "key" in the data
      disableSortBy: true,
      filterCode: "name",
      isVisible: false,
    },
    {
      Header: "Contract Number",
      accessor: "contractNumber", // accessor is the "key" in the data
      disableSortBy: true,
      filterCode: "contractNumber",
    },
    {
      Header: "Active",
      accessor: "isActive", // accessor is the "key" in the data
      disableSortBy: true,
      filterCode: "isActive",
    },
  ];

  const [centralizedContracts, setCentralizedContracts] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const centralizedContractsRes = await getCentralizedContracts();
      const contracts = centralizedContractsRes.data.centralizedContracts.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1));
      setCentralizedContracts(contracts);
    }
    fetchData();
  }, [updateCount]);

  const initialItems = centralizedContracts?.length
    ? centralizedContracts.map((contract) => {
        return {
          ...contract,
          nameLink: <Link to={`/centralized-contracts/${contract.id}`}>{contract.name}</Link>,
          name: contract.name,
          contractNumber: contract.contractNumber,
          isActive: contract.isActive ? "Yes" : "No",
        };
      })
    : [];

  // console.log(reportId);
  return (
    <>
      {initialItems ? (
        <>
          <section id="goal-plan-list">
            <div>
              {initialItems.length > 0 && <FilterTableForm tableName="centralized-contracts-list" Columns={Columns} FilterListConfig={FilterListConfig} tableItems={initialItems}></FilterTableForm>}
            </div>
          </section>
        </>
      ) : (
        <span className="loader loader-pulse">Loading activity reports...</span>
      )}
    </>
  );
};

export default CentralizedContractList;

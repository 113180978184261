export default [
  {
    id: 111110,
    label: "111110 - Soybean Farming"
  },
  {
    id: 111120,
    label: "111120 - Oilseed (except Soybean) Farming"
  },
  {
    id: 111130,
    label: "111130 - Dry Pea and Bean Farming"
  },
  {
    id: 111140,
    label: "111140 - Wheat Farming"
  },
  {
    id: 111150,
    label: "111150 - Corn Farming"
  },
  {
    id: 111160,
    label: "111160 - Rice Farming"
  },
  {
    id: 111191,
    label: "111191 - Oilseed and Grain Combination Farming"
  },
  {
    id: 111199,
    label: "111199 - All Other Grain Farming"
  },
  {
    id: 111211,
    label: "111211 - Potato Farming"
  },
  {
    id: 111219,
    label: "111219 - Other Vegetable (except Potato) and Melon Farming"
  },
  {
    id: 111310,
    label: "111310 - Orange Groves"
  },
  {
    id: 111320,
    label: "111320 - Citrus (except Orange) Groves"
  },
  {
    id: 111331,
    label: "111331 - Apple Orchards"
  },
  {
    id: 111332,
    label: "111332 - Grape Vineyards"
  },
  {
    id: 111333,
    label: "111333 - Strawberry Farming"
  },
  {
    id: 111334,
    label: "111334 - Berry (except Strawberry) Farming"
  },
  {
    id: 111335,
    label: "111335 - Tree Nut Farming"
  },
  {
    id: 111336,
    label: "111336 - Fruit and Tree Nut Combination Farming"
  },
  {
    id: 111339,
    label: "111339 - Other Noncitrus Fruit Farming"
  },
  {
    id: 111411,
    label: "111411 - Mushroom Production"
  },
  {
    id: 111419,
    label: "111419 - Other Food Crops Grown Under Cover"
  },
  {
    id: 111421,
    label: "111421 - Nursery and Tree Production"
  },
  {
    id: 111422,
    label: "111422 - Floriculture Production"
  },
  {
    id: 111910,
    label: "111910 - Tobacco Farming"
  },
  {
    id: 111920,
    label: "111920 - Cotton Farming"
  },
  {
    id: 111930,
    label: "111930 - Sugarcane Farming"
  },
  {
    id: 111940,
    label: "111940 - Hay Farming"
  },
  {
    id: 111991,
    label: "111991 - Sugar Beet Farming"
  },
  {
    id: 111992,
    label: "111992 - Peanut Farming"
  },
  {
    id: 111998,
    label: "111998 - All Other Miscellaneous Crop Farming"
  },
  {
    id: 112111,
    label: "112111 - Beef Cattle Ranching and Farming"
  },
  {
    id: 112112,
    label: "112112 - Cattle Feedlots"
  },
  {
    id: 112120,
    label: "112120 - Dairy Cattle and Milk Production"
  },
  {
    id: 112130,
    label: "112130 - Dual-Purpose Cattle Ranching and Farming"
  },
  {
    id: 112210,
    label: "112210 - Hog and Pig Farming"
  },
  {
    id: 112310,
    label: "112310 - Chicken Egg Production"
  },
  {
    id: 112320,
    label: "112320 - Broilers and Other Meat Type Chicken Production"
  },
  {
    id: 112330,
    label: "112330 - Turkey Production"
  },
  {
    id: 112340,
    label: "112340 - Poultry Hatcheries"
  },
  {
    id: 112390,
    label: "112390 - Other Poultry Production"
  },
  {
    id: 112410,
    label: "112410 - Sheep Farming"
  },
  {
    id: 112420,
    label: "112420 - Goat Farming"
  },
  {
    id: 112511,
    label: "112511 - Finfish Farming and Fish Hatcheries"
  },
  {
    id: 112512,
    label: "112512 - Shellfish Farming"
  },
  {
    id: 112519,
    label: "112519 - Other Aquaculture"
  },
  {
    id: 112910,
    label: "112910 - Apiculture"
  },
  {
    id: 112920,
    label: "112920 - Horses and Other Equine Production"
  },
  {
    id: 112930,
    label: "112930 - Fur-Bearing Animal and Rabbit Production"
  },
  {
    id: 112990,
    label: "112990 - All Other Animal Production"
  },
  {
    id: 113110,
    label: "113110 - Timber Tract Operations"
  },
  {
    id: 113210,
    label: "113210 - Forest Nurseries and Gathering of Forest Products"
  },
  {
    id: 113310,
    label: "113310 - Logging"
  },
  {
    id: 114111,
    label: "114111 - Finfish Fishing"
  },
  {
    id: 114112,
    label: "114112 - Shellfish Fishing"
  },
  {
    id: 114119,
    label: "114119 - Other Marine Fishing"
  },
  {
    id: 114210,
    label: "114210 - Hunting and Trapping"
  },
  {
    id: 115111,
    label: "115111 - Cotton Ginning"
  },
  {
    id: 115112,
    label: "115112 - Soil Preparation, Planting, and Cultivating"
  },
  {
    id: 115113,
    label: "115113 - Crop Harvesting, Primarily by Machine"
  },
  {
    id: 115114,
    label: "115114 - Postharvest Crop Activities (except Cotton Ginning)"
  },
  {
    id: 115115,
    label: "115115 - Farm Labor Contractors and Crew Leaders"
  },
  {
    id: 115116,
    label: "115116 - Farm Management Services"
  },
  {
    id: 115210,
    label: "115210 - Support Activities for Animal Production"
  },
  {
    id: 115310,
    label: "115310 - Support Activities for Forestry"
  },
  {
    id: 211120,
    label: "211120 - Crude Petroleum Extraction"
  },
  {
    id: 211130,
    label: "211130 - Natural Gas Extraction"
  },
  {
    id: 212111,
    label: "212111 - Bituminous Coal and Lignite Surface Mining"
  },
  {
    id: 212112,
    label: "212112 - Bituminous Coal Underground Mining"
  },
  {
    id: 212113,
    label: "212113 - Anthracite Mining"
  },
  {
    id: 212210,
    label: "212210 - Iron Ore Mining"
  },
  {
    id: 212221,
    label: "212221 - Gold Ore Mining"
  },
  {
    id: 212222,
    label: "212222 - Silver Ore Mining"
  },
  {
    id: 212230,
    label: "212230 - Copper, Nickel, Lead, and Zinc Mining"
  },
  {
    id: 212291,
    label: "212291 - Uranium-Radium-Vanadium Ore Mining"
  },
  {
    id: 212299,
    label: "212299 - All Other Metal Ore Mining"
  },
  {
    id: 212311,
    label: "212311 - Dimension Stone Mining and Quarrying"
  },
  {
    id: 212312,
    label: "212312 - Crushed and Broken Limestone Mining and Quarrying"
  },
  {
    id: 212313,
    label: "212313 - Crushed and Broken Granite Mining and Quarrying"
  },
  {
    id: 212319,
    label: "212319 - Other Crushed and Broken Stone Mining and Quarrying"
  },
  {
    id: 212321,
    label: "212321 - Construction Sand and Gravel Mining"
  },
  {
    id: 212322,
    label: "212322 - Industrial Sand Mining"
  },
  {
    id: 212324,
    label: "212324 - Kaolin and Ball Clay Mining"
  },
  {
    id: 212325,
    label: "212325 - Clay and Ceramic and Refractory Minerals Mining"
  },
  {
    id: 212391,
    label: "212391 - Potash, Soda, and Borate Mineral Mining"
  },
  {
    id: 212392,
    label: "212392 - Phosphate Rock Mining"
  },
  {
    id: 212393,
    label: "212393 - Other Chemical and Fertilizer Mineral Mining"
  },
  {
    id: 212399,
    label: "212399 - All Other Nonmetallic Mineral Mining"
  },
  {
    id: 213111,
    label: "213111 - Drilling Oil and Gas Wells"
  },
  {
    id: 213112,
    label: "213112 - Support Activities for Oil and Gas Operations"
  },
  {
    id: 213113,
    label: "213113 - Support Activities for Coal Mining"
  },
  {
    id: 213114,
    label: "213114 - Support Activities for Metal Mining"
  },
  {
    id: 213115,
    label: "213115 - Support Activities for Nonmetallic Minerals (except Fuels) Mining"
  },
  {
    id: 221111,
    label: "221111 - Hydroelectric Power Generation"
  },
  {
    id: 221112,
    label: "221112 - Fossil Fuel Electric Power Generation"
  },
  {
    id: 221113,
    label: "221113 - Nuclear Electric Power Generation"
  },
  {
    id: 221114,
    label: "221114 - Solar Electric Power Generation"
  },
  {
    id: 221115,
    label: "221115 - Wind Electric Power Generation"
  },
  {
    id: 221116,
    label: "221116 - Geothermal Electric Power Generation"
  },
  {
    id: 221117,
    label: "221117 - Biomass Electric Power Generation"
  },
  {
    id: 221118,
    label: "221118 - Other Electric Power Generation"
  },
  {
    id: 221121,
    label: "221121 - Electric Bulk Power Transmission and Control"
  },
  {
    id: 221122,
    label: "221122 - Electric Power Distribution"
  },
  {
    id: 221210,
    label: "221210 - Natural Gas Distribution"
  },
  {
    id: 221310,
    label: "221310 - Water Supply and Irrigation Systems"
  },
  {
    id: 221320,
    label: "221320 - Sewage Treatment Facilities"
  },
  {
    id: 221330,
    label: "221330 - Steam and Air-Conditioning Supply"
  },
  {
    id: 236115,
    label: "236115 - New Single-Family Housing Construction (except For-Sale Builders)"
  },
  {
    id: 236116,
    label: "236116 - New Multifamily Housing Construction (except For-Sale Builders)"
  },
  {
    id: 236117,
    label: "236117 - New Housing For-Sale Builders"
  },
  {
    id: 236118,
    label: "236118 - Residential Remodelers"
  },
  {
    id: 236210,
    label: "236210 - Industrial Building Construction"
  },
  {
    id: 236220,
    label: "236220 - Commercial and Institutional Building Construction"
  },
  {
    id: 237110,
    label: "237110 - Water and Sewer Line and Related Structures Construction"
  },
  {
    id: 237120,
    label: "237120 - Oil and Gas Pipeline and Related Structures Construction"
  },
  {
    id: 237130,
    label: "237130 - Power and Communication Line and Related Structures Construction"
  },
  {
    id: 237210,
    label: "237210 - Land Subdivision"
  },
  {
    id: 237310,
    label: "237310 - Highway, Street, and Bridge Construction"
  },
  {
    id: 237990,
    label: "237990 - Other Heavy and Civil Engineering Construction"
  },
  {
    id: 238110,
    label: "238110 - Poured Concrete Foundation and Structure Contractors"
  },
  {
    id: 238120,
    label: "238120 - Structural Steel and Precast Concrete Contractors"
  },
  {
    id: 238130,
    label: "238130 - Framing Contractors"
  },
  {
    id: 238140,
    label: "238140 - Masonry Contractors"
  },
  {
    id: 238150,
    label: "238150 - Glass and Glazing Contractors"
  },
  {
    id: 238160,
    label: "238160 - Roofing Contractors"
  },
  {
    id: 238170,
    label: "238170 - Siding Contractors"
  },
  {
    id: 238190,
    label: "238190 - Other Foundation, Structure, and Building Exterior Contractors"
  },
  {
    id: 238210,
    label: "238210 - Electrical Contractors and Other Wiring Installation Contractors"
  },
  {
    id: 238220,
    label: "238220 - Plumbing, Heating, and Air-Conditioning Contractors"
  },
  {
    id: 238290,
    label: "238290 - Other Building Equipment Contractors"
  },
  {
    id: 238310,
    label: "238310 - Drywall and Insulation Contractors"
  },
  {
    id: 238320,
    label: "238320 - Painting and Wall Covering Contractors"
  },
  {
    id: 238330,
    label: "238330 - Flooring Contractors"
  },
  {
    id: 238340,
    label: "238340 - Tile and Terrazzo Contractors"
  },
  {
    id: 238350,
    label: "238350 - Finish Carpentry Contractors"
  },
  {
    id: 238390,
    label: "238390 - Other Building Finishing Contractors"
  },
  {
    id: 238910,
    label: "238910 - Site Preparation Contractors"
  },
  {
    id: 238990,
    label: "238990 - All Other Specialty Trade Contractors"
  },
  {
    id: 311111,
    label: "311111 - Dog and Cat Food Manufacturing"
  },
  {
    id: 311119,
    label: "311119 - Other Animal Food Manufacturing"
  },
  {
    id: 311211,
    label: "311211 - Flour Milling"
  },
  {
    id: 311212,
    label: "311212 - Rice Milling"
  },
  {
    id: 311213,
    label: "311213 - Malt Manufacturing"
  },
  {
    id: 311221,
    label: "311221 - Wet Corn Milling"
  },
  {
    id: 311224,
    label: "311224 - Soybean and Other Oilseed Processing"
  },
  {
    id: 311225,
    label: "311225 - Fats and Oils Refining and Blending"
  },
  {
    id: 311230,
    label: "311230 - Breakfast Cereal Manufacturing"
  },
  {
    id: 311313,
    label: "311313 - Beet Sugar Manufacturing"
  },
  {
    id: 311314,
    label: "311314 - Cane Sugar Manufacturing"
  },
  {
    id: 311340,
    label: "311340 - Nonchocolate Confectionery Manufacturing"
  },
  {
    id: 311351,
    label: "311351 - Chocolate and Confectionery Manufacturing from Cacao Beans"
  },
  {
    id: 311352,
    label: "311352 - Confectionery Manufacturing from Purchased Chocolate"
  },
  {
    id: 311411,
    label: "311411 - Frozen Fruit, Juice, and Vegetable Manufacturing"
  },
  {
    id: 311412,
    label: "311412 - Frozen Specialty Food Manufacturing"
  },
  {
    id: 311421,
    label: "311421 - Fruit and Vegetable Canning"
  },
  {
    id: 311422,
    label: "311422 - Specialty Canning"
  },
  {
    id: 311423,
    label: "311423 - Dried and Dehydrated Food Manufacturing"
  },
  {
    id: 311511,
    label: "311511 - Fluid Milk Manufacturing"
  },
  {
    id: 311512,
    label: "311512 - Creamery Butter Manufacturing"
  },
  {
    id: 311513,
    label: "311513 - Cheese Manufacturing"
  },
  {
    id: 311514,
    label: "311514 - Dry, Condensed, and Evaporated Dairy Product Manufacturing"
  },
  {
    id: 311520,
    label: "311520 - Ice Cream and Frozen Dessert Manufacturing"
  },
  {
    id: 311611,
    label: "311611 - Animal (except Poultry) Slaughtering"
  },
  {
    id: 311612,
    label: "311612 - Meat Processed from Carcasses"
  },
  {
    id: 311613,
    label: "311613 - Rendering and Meat Byproduct Processing"
  },
  {
    id: 311615,
    label: "311615 - Poultry Processing"
  },
  {
    id: 311710,
    label: "311710 - Seafood Product Preparation and Packaging"
  },
  {
    id: 311811,
    label: "311811 - Retail Bakeries"
  },
  {
    id: 311812,
    label: "311812 - Commercial Bakeries"
  },
  {
    id: 311813,
    label: "311813 - Frozen Cakes, Pies, and Other Pastries Manufacturing"
  },
  {
    id: 311821,
    label: "311821 - Cookie and Cracker Manufacturing"
  },
  {
    id: 311824,
    label: "311824 - Dry Pasta, Dough, and Flour Mixes Manufacturing from Purchased Flour"
  },
  {
    id: 311830,
    label: "311830 - Tortilla Manufacturing"
  },
  {
    id: 311911,
    label: "311911 - Roasted Nuts and Peanut Butter Manufacturing"
  },
  {
    id: 311919,
    label: "311919 - Other Snack Food Manufacturing"
  },
  {
    id: 311920,
    label: "311920 - Coffee and Tea Manufacturing"
  },
  {
    id: 311930,
    label: "311930 - Flavoring Syrup and Concentrate Manufacturing"
  },
  {
    id: 311941,
    label: "311941 - Mayonnaise, Dressing, and Other Prepared Sauce Manufacturing"
  },
  {
    id: 311942,
    label: "311942 - Spice and Extract Manufacturing"
  },
  {
    id: 311991,
    label: "311991 - Perishable Prepared Food Manufacturing"
  },
  {
    id: 311999,
    label: "311999 - All Other Miscellaneous Food Manufacturing"
  },
  {
    id: 312111,
    label: "312111 - Soft Drink Manufacturing"
  },
  {
    id: 312112,
    label: "312112 - Bottled Water Manufacturing"
  },
  {
    id: 312113,
    label: "312113 - Ice Manufacturing"
  },
  {
    id: 312120,
    label: "312120 - Breweries"
  },
  {
    id: 312130,
    label: "312130 - Wineries"
  },
  {
    id: 312140,
    label: "312140 - Distilleries"
  },
  {
    id: 312230,
    label: "312230 - Tobacco Manufacturing"
  },
  {
    id: 313110,
    label: "313110 - Fiber, Yarn, and Thread Mills"
  },
  {
    id: 313210,
    label: "313210 - Broadwoven Fabric Mills"
  },
  {
    id: 313220,
    label: "313220 - Narrow Fabric Mills and Schiffli Machine Embroidery"
  },
  {
    id: 313230,
    label: "313230 - Nonwoven Fabric Mills"
  },
  {
    id: 313240,
    label: "313240 - Knit Fabric Mills"
  },
  {
    id: 313310,
    label: "313310 - Textile and Fabric Finishing Mills"
  },
  {
    id: 313320,
    label: "313320 - Fabric Coating Mills"
  },
  {
    id: 314110,
    label: "314110 - Carpet and Rug Mills"
  },
  {
    id: 314120,
    label: "314120 - Curtain and Linen Mills"
  },
  {
    id: 314910,
    label: "314910 - Textile Bag and Canvas Mills"
  },
  {
    id: 314994,
    label: "314994 - Rope, Cordage, Twine, Tire Cord, and Tire Fabric Mills"
  },
  {
    id: 314999,
    label: "314999 - All Other Miscellaneous Textile Product Mills"
  },
  {
    id: 315110,
    label: "315110 - Hosiery and Sock Mills"
  },
  {
    id: 315190,
    label: "315190 - Other Apparel Knitting Mills"
  },
  {
    id: 315210,
    label: "315210 - Cut and Sew Apparel Contractors"
  },
  {
    id: 315220,
    label: "315220 - Mens and Boys Cut and Sew Apparel Manufacturing"
  },
  {
    id: 315240,
    label: "315240 - Womens, Girls, and Infants Cut and Sew Apparel Manufacturing"
  },
  {
    id: 315280,
    label: "315280 - Other Cut and Sew Apparel Manufacturing"
  },
  {
    id: 315990,
    label: "315990 - Apparel Accessories and Other Apparel Manufacturing"
  },
  {
    id: 316110,
    label: "316110 - Leather and Hide Tanning and Finishing"
  },
  {
    id: 316210,
    label: "316210 - Footwear Manufacturing"
  },
  {
    id: 316992,
    label: "316992 - Women's Handbag and Purse Manufacturing"
  },
  {
    id: 316998,
    label: "316998 - All Other Leather Good and Allied Product Manufacturing"
  },
  {
    id: 321113,
    label: "321113 - Sawmills"
  },
  {
    id: 321114,
    label: "321114 - Wood Preservation"
  },
  {
    id: 321211,
    label: "321211 - Hardwood Veneer and Plywood Manufacturing"
  },
  {
    id: 321212,
    label: "321212 - Softwood Veneer and Plywood Manufacturing"
  },
  {
    id: 321213,
    label: "321213 - Engineered Wood Member (except Truss) Manufacturing"
  },
  {
    id: 321214,
    label: "321214 - Truss Manufacturing"
  },
  {
    id: 321219,
    label: "321219 - Reconstituted Wood Product Manufacturing"
  },
  {
    id: 321911,
    label: "321911 - Wood Window and Door Manufacturing"
  },
  {
    id: 321912,
    label: "321912 - Cut Stock, Resawing Lumber, and Planing"
  },
  {
    id: 321918,
    label: "321918 - Other Millwork (including Flooring)"
  },
  {
    id: 321920,
    label: "321920 - Wood Container and Pallet Manufacturing"
  },
  {
    id: 321991,
    label: "321991 - Manufactured Home (Mobile Home) Manufacturing"
  },
  {
    id: 321992,
    label: "321992 - Prefabricated Wood Building Manufacturing"
  },
  {
    id: 321999,
    label: "321999 - All Other Miscellaneous Wood Product Manufacturing"
  },
  {
    id: 322110,
    label: "322110 - Pulp Mills"
  },
  {
    id: 322121,
    label: "322121 - Paper (except Newsprint) Mills"
  },
  {
    id: 322122,
    label: "322122 - Newsprint Mills"
  },
  {
    id: 322130,
    label: "322130 - Paperboard Mills"
  },
  {
    id: 322211,
    label: "322211 - Corrugated and Solid Fiber Box Manufacturing"
  },
  {
    id: 322212,
    label: "322212 - Folding Paperboard Box Manufacturing"
  },
  {
    id: 322219,
    label: "322219 - Other Paperboard Container Manufacturing"
  },
  {
    id: 322220,
    label: "322220 - Paper Bag and Coated and Treated Paper Manufacturing"
  },
  {
    id: 322230,
    label: "322230 - Stationery Product Manufacturing"
  },
  {
    id: 322291,
    label: "322291 - Sanitary Paper Product Manufacturing"
  },
  {
    id: 322299,
    label: "322299 - All Other Converted Paper Product Manufacturing"
  },
  {
    id: 323111,
    label: "323111 - Commercial Printing (except Screen and Books)"
  },
  {
    id: 323113,
    label: "323113 - Commercial Screen Printing"
  },
  {
    id: 323117,
    label: "323117 - Books Printing"
  },
  {
    id: 323120,
    label: "323120 - Support Activities for Printing"
  },
  {
    id: 324110,
    label: "324110 - Petroleum Refineries"
  },
  {
    id: 324121,
    label: "324121 - Asphalt Paving Mixture and Block Manufacturing"
  },
  {
    id: 324122,
    label: "324122 - Asphalt Shingle and Coating Materials Manufacturing"
  },
  {
    id: 324191,
    label: "324191 - Petroleum Lubricating Oil and Grease Manufacturing"
  },
  {
    id: 324199,
    label: "324199 - All Other Petroleum and Coal Products Manufacturing"
  },
  {
    id: 325110,
    label: "325110 - Petrochemical Manufacturing"
  },
  {
    id: 325120,
    label: "325120 - Industrial Gas Manufacturing"
  },
  {
    id: 325130,
    label: "325130 - Synthetic Dye and Pigment Manufacturing"
  },
  {
    id: 325180,
    label: "325180 - Other Basic Inorganic Chemical Manufacturing"
  },
  {
    id: 325193,
    label: "325193 - Ethyl Alcohol Manufacturing"
  },
  {
    id: 325194,
    label: "325194 - Cyclic Crude, Intermediate, and Gum and Wood Chemical Manufacturing"
  },
  {
    id: 325199,
    label: "325199 - All Other Basic Organic Chemical Manufacturing"
  },
  {
    id: 325211,
    label: "325211 - Plastics Material and Resin Manufacturing"
  },
  {
    id: 325212,
    label: "325212 - Synthetic Rubber Manufacturing"
  },
  {
    id: 325220,
    label: "325220 - Artificial and Synthetic Fibers and Filaments Manufacturing"
  },
  {
    id: 325311,
    label: "325311 - Nitrogenous Fertilizer Manufacturing"
  },
  {
    id: 325312,
    label: "325312 - Phosphatic Fertilizer Manufacturing"
  },
  {
    id: 325314,
    label: "325314 - Fertilizer (Mixing Only) Manufacturing"
  },
  {
    id: 325320,
    label: "325320 - Pesticide and Other Agricultural Chemical Manufacturing"
  },
  {
    id: 325411,
    label: "325411 - Medicinal and Botanical Manufacturing"
  },
  {
    id: 325412,
    label: "325412 - Pharmaceutical Preparation Manufacturing"
  },
  {
    id: 325413,
    label: "325413 - In-Vitro Diagnostic Substance Manufacturing"
  },
  {
    id: 325414,
    label: "325414 - Biological Product (except Diagnostic) Manufacturing"
  },
  {
    id: 325510,
    label: "325510 - Paint and Coating Manufacturing"
  },
  {
    id: 325520,
    label: "325520 - Adhesive Manufacturing"
  },
  {
    id: 325611,
    label: "325611 - Soap and Other Detergent Manufacturing"
  },
  {
    id: 325612,
    label: "325612 - Polish and Other Sanitation Good Manufacturing"
  },
  {
    id: 325613,
    label: "325613 - Surface Active Agent Manufacturing"
  },
  {
    id: 325620,
    label: "325620 - Toilet Preparation Manufacturing"
  },
  {
    id: 325910,
    label: "325910 - Printing Ink Manufacturing"
  },
  {
    id: 325920,
    label: "325920 - Explosives Manufacturing"
  },
  {
    id: 325991,
    label: "325991 - Custom Compounding of Purchased Resins"
  },
  {
    id: 325992,
    label: "325992 - Photographic Film, Paper, Plate, and Chemical Manufacturing"
  },
  {
    id: 325998,
    label: "325998 - All Other Miscellaneous Chemical Product and Preparation Manufacturing"
  },
  {
    id: 326111,
    label: "326111 - Plastics Bag and Pouch Manufacturing"
  },
  {
    id: 326112,
    label: "326112 - Plastics Packaging Film and Sheet (including Laminated) Manufacturing"
  },
  {
    id: 326113,
    label: "326113 - Unlaminated Plastics Film and Sheet (except Packaging) Manufacturing"
  },
  {
    id: 326121,
    label: "326121 - Unlaminated Plastics Profile Shape Manufacturing"
  },
  {
    id: 326122,
    label: "326122 - Plastics Pipe and Pipe Fitting Manufacturing"
  },
  {
    id: 326130,
    label: "326130 - Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing"
  },
  {
    id: 326140,
    label: "326140 - Polystyrene Foam Product Manufacturing"
  },
  {
    id: 326150,
    label: "326150 - Urethane and Other Foam Product (except Polystyrene) Manufacturing"
  },
  {
    id: 326160,
    label: "326160 - Plastics Bottle Manufacturing"
  },
  {
    id: 326191,
    label: "326191 - Plastics Plumbing Fixture Manufacturing"
  },
  {
    id: 326199,
    label: "326199 - All Other Plastics Product Manufacturing"
  },
  {
    id: 326211,
    label: "326211 - Tire Manufacturing (except Retreading)"
  },
  {
    id: 326212,
    label: "326212 - Tire Retreading"
  },
  {
    id: 326220,
    label: "326220 - Rubber and Plastics Hoses and Belting Manufacturing"
  },
  {
    id: 326291,
    label: "326291 - Rubber Product Manufacturing for Mechanical Use"
  },
  {
    id: 326299,
    label: "326299 - All Other Rubber Product Manufacturing"
  },
  {
    id: 327110,
    label: "327110 - Pottery, Ceramics, and Plumbing Fixture Manufacturing"
  },
  {
    id: 327120,
    label: "327120 - Clay Building Material and Refractories Manufacturing"
  },
  {
    id: 327211,
    label: "327211 - Flat Glass Manufacturing"
  },
  {
    id: 327212,
    label: "327212 - Other Pressed and Blown Glass and Glassware Manufacturing"
  },
  {
    id: 327213,
    label: "327213 - Glass Container Manufacturing"
  },
  {
    id: 327215,
    label: "327215 - Glass Product Manufacturing Made of Purchased Glass"
  },
  {
    id: 327310,
    label: "327310 - Cement Manufacturing"
  },
  {
    id: 327320,
    label: "327320 - Ready-Mix Concrete Manufacturing"
  },
  {
    id: 327331,
    label: "327331 - Concrete Block and Brick Manufacturing"
  },
  {
    id: 327332,
    label: "327332 - Concrete Pipe Manufacturing"
  },
  {
    id: 327390,
    label: "327390 - Other Concrete Product Manufacturing"
  },
  {
    id: 327410,
    label: "327410 - Lime Manufacturing"
  },
  {
    id: 327420,
    label: "327420 - Gypsum Product Manufacturing"
  },
  {
    id: 327910,
    label: "327910 - Abrasive Product Manufacturing"
  },
  {
    id: 327991,
    label: "327991 - Cut Stone and Stone Product Manufacturing"
  },
  {
    id: 327992,
    label: "327992 - Ground or Treated Mineral and Earth Manufacturing"
  },
  {
    id: 327993,
    label: "327993 - Mineral Wool Manufacturing"
  },
  {
    id: 327999,
    label: "327999 - All Other Miscellaneous Nonmetallic Mineral Product Manufacturing"
  },
  {
    id: 331110,
    label: "331110 - Iron and Steel Mills and Ferroalloy Manufacturing"
  },
  {
    id: 331210,
    label: "331210 - Iron and Steel Pipe and Tube Manufacturing from Purchased Steel"
  },
  {
    id: 331221,
    label: "331221 - Rolled Steel Shape Manufacturing"
  },
  {
    id: 331222,
    label: "331222 - Steel Wire Drawing"
  },
  {
    id: 331313,
    label: "331313 - Alumina Refining and Primary Aluminum Production"
  },
  {
    id: 331314,
    label: "331314 - Secondary Smelting and Alloying of Aluminum"
  },
  {
    id: 331315,
    label: "331315 - Aluminum Sheet, Plate, and Foil Manufacturing"
  },
  {
    id: 331318,
    label: "331318 - Other Aluminum Rolling, Drawing, and Extruding"
  },
  {
    id: 331410,
    label: "331410 - Nonferrous Metal (except Aluminum) Smelting and Refining"
  },
  {
    id: 331420,
    label: "331420 - Copper Rolling, Drawing, Extruding, and Alloying"
  },
  {
    id: 331491,
    label: "331491 - Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, and Extruding"
  },
  {
    id: 331492,
    label: "331492 - Secondary Smelting, Refining, and Alloying of Nonferrous Metal (except Copper and Aluminum)"
  },
  {
    id: 331511,
    label: "331511 - Iron Foundries"
  },
  {
    id: 331512,
    label: "331512 - Steel Investment Foundries"
  },
  {
    id: 331513,
    label: "331513 - Steel Foundries (except Investment)"
  },
  {
    id: 331523,
    label: "331523 - Nonferrous Metal Die-Casting Foundries"
  },
  {
    id: 331524,
    label: "331524 - Aluminum Foundries (except Die-Casting)"
  },
  {
    id: 331529,
    label: "331529 - Other Nonferrous Metal Foundries (except Die-Casting)"
  },
  {
    id: 332111,
    label: "332111 - Iron and Steel Forging"
  },
  {
    id: 332112,
    label: "332112 - Nonferrous Forging"
  },
  {
    id: 332114,
    label: "332114 - Custom Roll Forming"
  },
  {
    id: 332117,
    label: "332117 - Powder Metallurgy Part Manufacturing"
  },
  {
    id: 332119,
    label: "332119 - Metal Crown, Closure, and Other Metal Stamping (except Automotive)"
  },
  {
    id: 332215,
    label: "332215 - Metal Kitchen Cookware, Utensil, Cutlery, and Flatware (except Precious) Manufacturing"
  },
  {
    id: 332216,
    label: "332216 - Saw Blade and Handtool Manufacturing"
  },
  {
    id: 332311,
    label: "332311 - Prefabricated Metal Building and Component Manufacturing"
  },
  {
    id: 332312,
    label: "332312 - Fabricated Structural Metal Manufacturing"
  },
  {
    id: 332313,
    label: "332313 - Plate Work Manufacturing"
  },
  {
    id: 332321,
    label: "332321 - Metal Window and Door Manufacturing"
  },
  {
    id: 332322,
    label: "332322 - Sheet Metal Work Manufacturing"
  },
  {
    id: 332323,
    label: "332323 - Ornamental and Architectural Metal Work Manufacturing"
  },
  {
    id: 332410,
    label: "332410 - Power Boiler and Heat Exchanger Manufacturing"
  },
  {
    id: 332420,
    label: "332420 - Metal Tank (Heavy Gauge) Manufacturing"
  },
  {
    id: 332431,
    label: "332431 - Metal Can Manufacturing"
  },
  {
    id: 332439,
    label: "332439 - Other Metal Container Manufacturing"
  },
  {
    id: 332510,
    label: "332510 - Hardware Manufacturing"
  },
  {
    id: 332613,
    label: "332613 - Spring Manufacturing"
  },
  {
    id: 332618,
    label: "332618 - Other Fabricated Wire Product Manufacturing"
  },
  {
    id: 332710,
    label: "332710 - Machine Shops"
  },
  {
    id: 332721,
    label: "332721 - Precision Turned Product Manufacturing"
  },
  {
    id: 332722,
    label: "332722 - Bolt, Nut, Screw, Rivet, and Washer Manufacturing"
  },
  {
    id: 332811,
    label: "332811 - Metal Heat Treating"
  },
  {
    id: 332812,
    label: "332812 - Metal Coating, Engraving (except Jewelry and Silverware), and Allied Services to Manufacturers"
  },
  {
    id: 332813,
    label: "332813 - Electroplating, Plating, Polishing, Anodizing, and Coloring"
  },
  {
    id: 332911,
    label: "332911 - Industrial Valve Manufacturing"
  },
  {
    id: 332912,
    label: "332912 - Fluid Power Valve and Hose Fitting Manufacturing"
  },
  {
    id: 332913,
    label: "332913 - Plumbing Fixture Fitting and Trim Manufacturing"
  },
  {
    id: 332919,
    label: "332919 - Other Metal Valve and Pipe Fitting Manufacturing"
  },
  {
    id: 332991,
    label: "332991 - Ball and Roller Bearing Manufacturing"
  },
  {
    id: 332992,
    label: "332992 - Small Arms Ammunition Manufacturing"
  },
  {
    id: 332993,
    label: "332993 - Ammunition (except Small Arms) Manufacturing"
  },
  {
    id: 332994,
    label: "332994 - Small Arms, Ordnance, and Ordnance Accessories Manufacturing"
  },
  {
    id: 332996,
    label: "332996 - Fabricated Pipe and Pipe Fitting Manufacturing"
  },
  {
    id: 332999,
    label: "332999 - All Other Miscellaneous Fabricated Metal Product Manufacturing"
  },
  {
    id: 333111,
    label: "333111 - Farm Machinery and Equipment Manufacturing"
  },
  {
    id: 333112,
    label: "333112 - Lawn and Garden Tractor and Home Lawn and Garden Equipment Manufacturing"
  },
  {
    id: 333120,
    label: "333120 - Construction Machinery Manufacturing"
  },
  {
    id: 333131,
    label: "333131 - Mining Machinery and Equipment Manufacturing"
  },
  {
    id: 333132,
    label: "333132 - Oil and Gas Field Machinery and Equipment Manufacturing"
  },
  {
    id: 333241,
    label: "333241 - Food Product Machinery Manufacturing"
  },
  {
    id: 333242,
    label: "333242 - Semiconductor Machinery Manufacturing"
  },
  {
    id: 333243,
    label: "333243 - Sawmill, Woodworking, and Paper Machinery Manufacturing"
  },
  {
    id: 333244,
    label: "333244 - Printing Machinery and Equipment Manufacturing"
  },
  {
    id: 333249,
    label: "333249 - Other Industrial Machinery Manufacturing"
  },
  {
    id: 333314,
    label: "333314 - Optical Instrument and Lens Manufacturing"
  },
  {
    id: 333316,
    label: "333316 - Photographic and Photocopying Equipment Manufacturing"
  },
  {
    id: 333318,
    label: "333318 - Other Commercial and Service Industry Machinery Manufacturing"
  },
  {
    id: 333413,
    label: "333413 - Industrial and Commercial Fan and Blower and Air Purification Equipment Manufacturing"
  },
  {
    id: 333414,
    label: "333414 - Heating Equipment (except Warm Air Furnaces) Manufacturing"
  },
  {
    id: 333415,
    label: "333415 - Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment Manufacturing"
  },
  {
    id: 333511,
    label: "333511 - Industrial Mold Manufacturing"
  },
  {
    id: 333514,
    label: "333514 - Special Die and Tool, Die Set, Jig, and Fixture Manufacturing"
  },
  {
    id: 333515,
    label: "333515 - Cutting Tool and Machine Tool Accessory Manufacturing"
  },
  {
    id: 333517,
    label: "333517 - Machine Tool Manufacturing"
  },
  {
    id: 333519,
    label: "333519 - Rolling Mill and Other Metalworking Machinery Manufacturing"
  },
  {
    id: 333611,
    label: "333611 - Turbine and Turbine Generator Set Units Manufacturing"
  },
  {
    id: 333612,
    label: "333612 - Speed Changer, Industrial High-Speed Drive, and Gear Manufacturing"
  },
  {
    id: 333613,
    label: "333613 - Mechanical Power Transmission Equipment Manufacturing"
  },
  {
    id: 333618,
    label: "333618 - Other Engine Equipment Manufacturing"
  },
  {
    id: 333912,
    label: "333912 - Air and Gas Compressor Manufacturing"
  },
  {
    id: 333914,
    label: "333914 - Measuring, Dispensing, and Other Pumping Equipment Manufacturing"
  },
  {
    id: 333921,
    label: "333921 - Elevator and Moving Stairway Manufacturing"
  },
  {
    id: 333922,
    label: "333922 - Conveyor and Conveying Equipment Manufacturing"
  },
  {
    id: 333923,
    label: "333923 - Overhead Traveling Crane, Hoist, and Monorail System Manufacturing"
  },
  {
    id: 333924,
    label: "333924 - Industrial Truck, Tractor, Trailer, and Stacker Machinery Manufacturing"
  },
  {
    id: 333991,
    label: "333991 - Power-Driven Handtool Manufacturing"
  },
  {
    id: 333992,
    label: "333992 - Welding and Soldering Equipment Manufacturing"
  },
  {
    id: 333993,
    label: "333993 - Packaging Machinery Manufacturing"
  },
  {
    id: 333994,
    label: "333994 - Industrial Process Furnace and Oven Manufacturing"
  },
  {
    id: 333995,
    label: "333995 - Fluid Power Cylinder and Actuator Manufacturing"
  },
  {
    id: 333996,
    label: "333996 - Fluid Power Pump and Motor Manufacturing"
  },
  {
    id: 333997,
    label: "333997 - Scale and Balance Manufacturing"
  },
  {
    id: 333999,
    label: "333999 - All Other Miscellaneous General Purpose Machinery Manufacturing"
  },
  {
    id: 334111,
    label: "334111 - Electronic Computer Manufacturing"
  },
  {
    id: 334112,
    label: "334112 - Computer Storage Device Manufacturing"
  },
  {
    id: 334118,
    label: "334118 - Computer Terminal and Other Computer Peripheral Equipment Manufacturing"
  },
  {
    id: 334210,
    label: "334210 - Telephone Apparatus Manufacturing"
  },
  {
    id: 334220,
    label: "334220 - Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing"
  },
  {
    id: 334290,
    label: "334290 - Other Communications Equipment Manufacturing"
  },
  {
    id: 334310,
    label: "334310 - Audio and Video Equipment Manufacturing"
  },
  {
    id: 334412,
    label: "334412 - Bare Printed Circuit Board Manufacturing "
  },
  {
    id: 334413,
    label: "334413 - Semiconductor and Related Device Manufacturing"
  },
  {
    id: 334416,
    label: "334416 - Capacitor, Resistor, Coil, Transformer, and Other Inductor Manufacturing"
  },
  {
    id: 334417,
    label: "334417 - Electronic Connector Manufacturing"
  },
  {
    id: 334418,
    label: "334418 - Printed Circuit Assembly (Electronic Assembly) Manufacturing"
  },
  {
    id: 334419,
    label: "334419 - Other Electronic Component Manufacturing"
  },
  {
    id: 334510,
    label: "334510 - Electromedical and Electrotherapeutic Apparatus Manufacturing"
  },
  {
    id: 334511,
    label: "334511 - Search, Detection, Navigation, Guidance, Aeronautical, and Nautical System and Instrument Manufacturing"
  },
  {
    id: 334512,
    label: "334512 - Automatic Environmental Control Manufacturing for Residential, Commercial, and Appliance Use"
  },
  {
    id: 334513,
    label: "334513 - Instruments and Related Products Manufacturing for Measuring, Displaying, and Controlling Industrial Process Variables"
  },
  {
    id: 334514,
    label: "334514 - Totalizing Fluid Meter and Counting Device Manufacturing"
  },
  {
    id: 334515,
    label: "334515 - Instrument Manufacturing for Measuring and Testing Electricity and Electrical Signals"
  },
  {
    id: 334516,
    label: "334516 - Analytical Laboratory Instrument Manufacturing"
  },
  {
    id: 334517,
    label: "334517 - Irradiation Apparatus Manufacturing"
  },
  {
    id: 334519,
    label: "334519 - Other Measuring and Controlling Device Manufacturing"
  },
  {
    id: 334613,
    label: "334613 - Blank Magnetic and Optical Recording Media Manufacturing"
  },
  {
    id: 334614,
    label: "334614 - Software and Other Prerecorded Compact Disc, Tape, and Record Reproducing"
  },
  {
    id: 335110,
    label: "335110 - Electric Lamp Bulb and Part Manufacturing"
  },
  {
    id: 335121,
    label: "335121 - Residential Electric Lighting Fixture Manufacturing"
  },
  {
    id: 335122,
    label: "335122 - Commercial, Industrial, and Institutional Electric Lighting Fixture Manufacturing"
  },
  {
    id: 335129,
    label: "335129 - Other Lighting Equipment Manufacturing"
  },
  {
    id: 335210,
    label: "335210 - Small Electrical Appliance Manufacturing"
  },
  {
    id: 335220,
    label: "335220 - Major Household Appliance Manufacturing"
  },
  {
    id: 335311,
    label: "335311 - Power, Distribution, and Specialty Transformer Manufacturing"
  },
  {
    id: 335312,
    label: "335312 - Motor and Generator Manufacturing"
  },
  {
    id: 335313,
    label: "335313 - Switchgear and Switchboard Apparatus Manufacturing"
  },
  {
    id: 335314,
    label: "335314 - Relay and Industrial Control Manufacturing"
  },
  {
    id: 335911,
    label: "335911 - Storage Battery Manufacturing"
  },
  {
    id: 335912,
    label: "335912 - Primary Battery Manufacturing"
  },
  {
    id: 335921,
    label: "335921 - Fiber Optic Cable Manufacturing"
  },
  {
    id: 335929,
    label: "335929 - Other Communication and Energy Wire Manufacturing"
  },
  {
    id: 335931,
    label: "335931 - Current-Carrying Wiring Device Manufacturing"
  },
  {
    id: 335932,
    label: "335932 - Noncurrent-Carrying Wiring Device Manufacturing"
  },
  {
    id: 335991,
    label: "335991 - Carbon and Graphite Product Manufacturing"
  },
  {
    id: 335999,
    label: "335999 - All Other Miscellaneous Electrical Equipment and Component Manufacturing"
  },
  {
    id: 336111,
    label: "336111 - Automobile Manufacturing"
  },
  {
    id: 336112,
    label: "336112 - Light Truck and Utility Vehicle Manufacturing"
  },
  {
    id: 336120,
    label: "336120 - Heavy Duty Truck Manufacturing"
  },
  {
    id: 336211,
    label: "336211 - Motor Vehicle Body Manufacturing"
  },
  {
    id: 336212,
    label: "336212 - Truck Trailer Manufacturing"
  },
  {
    id: 336213,
    label: "336213 - Motor Home Manufacturing"
  },
  {
    id: 336214,
    label: "336214 - Travel Trailer and Camper Manufacturing"
  },
  {
    id: 336310,
    label: "336310 - Motor Vehicle Gasoline Engine and Engine Parts Manufacturing"
  },
  {
    id: 336320,
    label: "336320 - Motor Vehicle Electrical and Electronic Equipment Manufacturing"
  },
  {
    id: 336330,
    label: "336330 - Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing"
  },
  {
    id: 336340,
    label: "336340 - Motor Vehicle Brake System Manufacturing"
  },
  {
    id: 336350,
    label: "336350 - Motor Vehicle Transmission and Power Train Parts Manufacturing"
  },
  {
    id: 336360,
    label: "336360 - Motor Vehicle Seating and Interior Trim Manufacturing"
  },
  {
    id: 336370,
    label: "336370 - Motor Vehicle Metal Stamping"
  },
  {
    id: 336390,
    label: "336390 - Other Motor Vehicle Parts Manufacturing"
  },
  {
    id: 336411,
    label: "336411 - Aircraft Manufacturing"
  },
  {
    id: 336412,
    label: "336412 - Aircraft Engine and Engine Parts Manufacturing"
  },
  {
    id: 336413,
    label: "336413 - Other Aircraft Parts and Auxiliary Equipment Manufacturing"
  },
  {
    id: 336414,
    label: "336414 - Guided Missile and Space Vehicle Manufacturing"
  },
  {
    id: 336415,
    label: "336415 - Guided Missile and Space Vehicle Propulsion Unit and Propulsion Unit Parts Manufacturing"
  },
  {
    id: 336419,
    label: "336419 - Other Guided Missile and Space Vehicle Parts and Auxiliary Equipment Manufacturing"
  },
  {
    id: 336510,
    label: "336510 - Railroad Rolling Stock Manufacturing"
  },
  {
    id: 336611,
    label: "336611 - Ship Building and Repairing"
  },
  {
    id: 336612,
    label: "336612 - Boat Building"
  },
  {
    id: 336991,
    label: "336991 - Motorcycle, Bicycle, and Parts Manufacturing"
  },
  {
    id: 336992,
    label: "336992 - Military Armored Vehicle, Tank, and Tank Component Manufacturing"
  },
  {
    id: 336999,
    label: "336999 - All Other Transportation Equipment Manufacturing"
  },
  {
    id: 337110,
    label: "337110 - Wood Kitchen Cabinet and Countertop Manufacturing"
  },
  {
    id: 337121,
    label: "337121 - Upholstered Household Furniture Manufacturing"
  },
  {
    id: 337122,
    label: "337122 - Nonupholstered Wood Household Furniture Manufacturing"
  },
  {
    id: 337124,
    label: "337124 - Metal Household Furniture Manufacturing"
  },
  {
    id: 337125,
    label: "337125 - Household Furniture (except Wood and Metal) Manufacturing"
  },
  {
    id: 337127,
    label: "337127 - Institutional Furniture Manufacturing"
  },
  {
    id: 337211,
    label: "337211 - Wood Office Furniture Manufacturing"
  },
  {
    id: 337212,
    label: "337212 - Custom Architectural Woodwork and Millwork Manufacturing"
  },
  {
    id: 337214,
    label: "337214 - Office Furniture (except Wood) Manufacturing"
  },
  {
    id: 337215,
    label: "337215 - Showcase, Partition, Shelving, and Locker Manufacturing"
  },
  {
    id: 337910,
    label: "337910 - Mattress Manufacturing"
  },
  {
    id: 337920,
    label: "337920 - Blind and Shade Manufacturing"
  },
  {
    id: 339112,
    label: "339112 - Surgical and Medical Instrument Manufacturing"
  },
  {
    id: 339113,
    label: "339113 - Surgical Appliance and Supplies Manufacturing"
  },
  {
    id: 339114,
    label: "339114 - Dental Equipment and Supplies Manufacturing"
  },
  {
    id: 339115,
    label: "339115 - Ophthalmic Goods Manufacturing"
  },
  {
    id: 339116,
    label: "339116 - Dental Laboratories"
  },
  {
    id: 339910,
    label: "339910 - Jewelry and Silverware Manufacturing"
  },
  {
    id: 339920,
    label: "339920 - Sporting and Athletic Goods Manufacturing"
  },
  {
    id: 339930,
    label: "339930 - Doll, Toy, and Game Manufacturing"
  },
  {
    id: 339940,
    label: "339940 - Office Supplies (except Paper) Manufacturing"
  },
  {
    id: 339950,
    label: "339950 - Sign Manufacturing"
  },
  {
    id: 339991,
    label: "339991 - Gasket, Packing, and Sealing Device Manufacturing"
  },
  {
    id: 339992,
    label: "339992 - Musical Instrument Manufacturing"
  },
  {
    id: 339993,
    label: "339993 - Fastener, Button, Needle, and Pin Manufacturing"
  },
  {
    id: 339994,
    label: "339994 - Broom, Brush, and Mop Manufacturing"
  },
  {
    id: 339995,
    label: "339995 - Burial Casket Manufacturing"
  },
  {
    id: 339999,
    label: "339999 - All Other Miscellaneous Manufacturing"
  },
  {
    id: 423110,
    label: "423110 - Automobile and Other Motor Vehicle Merchant Wholesalers"
  },
  {
    id: 423120,
    label: "423120 - Motor Vehicle Supplies and New Parts Merchant Wholesalers"
  },
  {
    id: 423130,
    label: "423130 - Tire and Tube Merchant Wholesalers"
  },
  {
    id: 423140,
    label: "423140 - Motor Vehicle Parts (Used) Merchant Wholesalers"
  },
  {
    id: 423210,
    label: "423210 - Furniture Merchant Wholesalers"
  },
  {
    id: 423220,
    label: "423220 - Home Furnishing Merchant Wholesalers"
  },
  {
    id: 423310,
    label: "423310 - Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers"
  },
  {
    id: 423320,
    label: "423320 - Brick, Stone, and Related Construction Material Merchant Wholesalers"
  },
  {
    id: 423330,
    label: "423330 - Roofing, Siding, and Insulation Material Merchant Wholesalers"
  },
  {
    id: 423390,
    label: "423390 - Other Construction Material Merchant Wholesalers"
  },
  {
    id: 423410,
    label: "423410 - Photographic Equipment and Supplies Merchant Wholesalers"
  },
  {
    id: 423420,
    label: "423420 - Office Equipment Merchant Wholesalers"
  },
  {
    id: 423430,
    label: "423430 - Computer and Computer Peripheral Equipment and Software Merchant Wholesalers"
  },
  {
    id: 423440,
    label: "423440 - Other Commercial Equipment Merchant Wholesalers"
  },
  {
    id: 423450,
    label: "423450 - Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers"
  },
  {
    id: 423460,
    label: "423460 - Ophthalmic Goods Merchant Wholesalers"
  },
  {
    id: 423490,
    label: "423490 - Other Professional Equipment and Supplies Merchant Wholesalers"
  },
  {
    id: 423510,
    label: "423510 - Metal Service Centers and Other Metal Merchant Wholesalers"
  },
  {
    id: 423520,
    label: "423520 - Coal and Other Mineral and Ore Merchant Wholesalers"
  },
  {
    id: 423610,
    label: "423610 - Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers"
  },
  {
    id: 423620,
    label: "423620 - Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers"
  },
  {
    id: 423690,
    label: "423690 - Other Electronic Parts and Equipment Merchant Wholesalers"
  },
  {
    id: 423710,
    label: "423710 - Hardware Merchant Wholesalers"
  },
  {
    id: 423720,
    label: "423720 - Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers"
  },
  {
    id: 423730,
    label: "423730 - Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers"
  },
  {
    id: 423740,
    label: "423740 - Refrigeration Equipment and Supplies Merchant Wholesalers"
  },
  {
    id: 423810,
    label: "423810 - Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers"
  },
  {
    id: 423820,
    label: "423820 - Farm and Garden Machinery and Equipment Merchant Wholesalers"
  },
  {
    id: 423830,
    label: "423830 - Industrial Machinery and Equipment Merchant Wholesalers"
  },
  {
    id: 423840,
    label: "423840 - Industrial Supplies Merchant Wholesalers"
  },
  {
    id: 423850,
    label: "423850 - Service Establishment Equipment and Supplies Merchant Wholesalers"
  },
  {
    id: 423860,
    label: "423860 - Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers"
  },
  {
    id: 423910,
    label: "423910 - Sporting and Recreational Goods and Supplies Merchant Wholesalers"
  },
  {
    id: 423920,
    label: "423920 - Toy and Hobby Goods and Supplies Merchant Wholesalers"
  },
  {
    id: 423930,
    label: "423930 - Recyclable Material Merchant Wholesalers"
  },
  {
    id: 423940,
    label: "423940 - Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers"
  },
  {
    id: 423990,
    label: "423990 - Other Miscellaneous Durable Goods Merchant Wholesalers"
  },
  {
    id: 424110,
    label: "424110 - Printing and Writing Paper Merchant Wholesalers"
  },
  {
    id: 424120,
    label: "424120 - Stationery and Office Supplies Merchant Wholesalers"
  },
  {
    id: 424130,
    label: "424130 - Industrial and Personal Service Paper Merchant Wholesalers"
  },
  {
    id: 424210,
    label: "424210 - Drugs and Druggists' Sundries Merchant Wholesalers"
  },
  {
    id: 424310,
    label: "424310 - Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers"
  },
  {
    id: 424320,
    label: "424320 - Men's and Boys' Clothing and Furnishings Merchant Wholesalers"
  },
  {
    id: 424330,
    label: "424330 - Women's, Children's, and Infants' Clothing and Accessories Merchant Wholesalers"
  },
  {
    id: 424340,
    label: "424340 - Footwear Merchant Wholesalers"
  },
  {
    id: 424410,
    label: "424410 - General Line Grocery Merchant Wholesalers"
  },
  {
    id: 424420,
    label: "424420 - Packaged Frozen Food Merchant Wholesalers"
  },
  {
    id: 424430,
    label: "424430 - Dairy Product (except Dried or Canned) Merchant Wholesalers"
  },
  {
    id: 424440,
    label: "424440 - Poultry and Poultry Product Merchant Wholesalers"
  },
  {
    id: 424450,
    label: "424450 - Confectionery Merchant Wholesalers"
  },
  {
    id: 424460,
    label: "424460 - Fish and Seafood Merchant Wholesalers"
  },
  {
    id: 424470,
    label: "424470 - Meat and Meat Product Merchant Wholesalers"
  },
  {
    id: 424480,
    label: "424480 - Fresh Fruit and Vegetable Merchant Wholesalers"
  },
  {
    id: 424490,
    label: "424490 - Other Grocery and Related Products Merchant Wholesalers"
  },
  {
    id: 424510,
    label: "424510 - Grain and Field Bean Merchant Wholesalers"
  },
  {
    id: 424520,
    label: "424520 - Livestock Merchant Wholesalers"
  },
  {
    id: 424590,
    label: "424590 - Other Farm Product Raw Material Merchant Wholesalers"
  },
  {
    id: 424610,
    label: "424610 - Plastics Materials and Basic Forms and Shapes Merchant Wholesalers"
  },
  {
    id: 424690,
    label: "424690 - Other Chemical and Allied Products Merchant Wholesalers"
  },
  {
    id: 424710,
    label: "424710 - Petroleum Bulk Stations and Terminals"
  },
  {
    id: 424720,
    label: "424720 - Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals)"
  },
  {
    id: 424810,
    label: "424810 - Beer and Ale Merchant Wholesalers"
  },
  {
    id: 424820,
    label: "424820 - Wine and Distilled Alcoholic Beverage Merchant Wholesalers"
  },
  {
    id: 424910,
    label: "424910 - Farm Supplies Merchant Wholesalers"
  },
  {
    id: 424920,
    label: "424920 - Book, Periodical, and Newspaper Merchant Wholesalers"
  },
  {
    id: 424930,
    label: "424930 - Flower, Nursery Stock, and Florists' Supplies Merchant Wholesalers"
  },
  {
    id: 424940,
    label: "424940 - Tobacco and Tobacco Product Merchant Wholesalers"
  },
  {
    id: 424950,
    label: "424950 - Paint, Varnish, and Supplies Merchant Wholesalers"
  },
  {
    id: 424990,
    label: "424990 - Other Miscellaneous Nondurable Goods Merchant Wholesalers"
  },
  {
    id: 425110,
    label: "425110 - Business to Business Electronic Markets"
  },
  {
    id: 425120,
    label: "425120 - Wholesale Trade Agents and Brokers"
  },
  {
    id: 441110,
    label: "441110 - New Car Dealers"
  },
  {
    id: 441120,
    label: "441120 - Used Car Dealers"
  },
  {
    id: 441210,
    label: "441210 - Recreational Vehicle Dealers"
  },
  {
    id: 441222,
    label: "441222 - Boat Dealers"
  },
  {
    id: 441228,
    label: "441228 - Motorcycle, ATV, and All Other Motor Vehicle Dealers"
  },
  {
    id: 441310,
    label: "441310 - Automotive Parts and Accessories Stores"
  },
  {
    id: 441320,
    label: "441320 - Tire Dealers"
  },
  {
    id: 442110,
    label: "442110 - Furniture Stores"
  },
  {
    id: 442210,
    label: "442210 - Floor Covering Stores"
  },
  {
    id: 442291,
    label: "442291 - Window Treatment Stores"
  },
  {
    id: 442299,
    label: "442299 - All Other Home Furnishings Stores"
  },
  {
    id: 443141,
    label: "443141 - Household Appliance Stores"
  },
  {
    id: 443142,
    label: "443142 - Electronics Stores"
  },
  {
    id: 444110,
    label: "444110 - Home Centers"
  },
  {
    id: 444120,
    label: "444120 - Paint and Wallpaper Stores"
  },
  {
    id: 444130,
    label: "444130 - Hardware Stores"
  },
  {
    id: 444190,
    label: "444190 - Other Building Material Dealers"
  },
  {
    id: 444210,
    label: "444210 - Outdoor Power Equipment Stores"
  },
  {
    id: 444220,
    label: "444220 - Nursery, Garden Center, and Farm Supply Stores"
  },
  {
    id: 445110,
    label: "445110 - Supermarkets and Other Grocery (except Convenience) Stores"
  },
  {
    id: 445120,
    label: "445120 - Convenience Stores"
  },
  {
    id: 445210,
    label: "445210 - Meat Markets"
  },
  {
    id: 445220,
    label: "445220 - Fish and Seafood Markets"
  },
  {
    id: 445230,
    label: "445230 - Fruit and Vegetable Markets"
  },
  {
    id: 445291,
    label: "445291 - Baked Goods Stores"
  },
  {
    id: 445292,
    label: "445292 - Confectionery and Nut Stores"
  },
  {
    id: 445299,
    label: "445299 - All Other Specialty Food Stores"
  },
  {
    id: 445310,
    label: "445310 - Beer, Wine, and Liquor Stores"
  },
  {
    id: 446110,
    label: "446110 - Pharmacies and Drug Stores"
  },
  {
    id: 446120,
    label: "446120 - Cosmetics, Beauty Supplies, and Perfume Stores"
  },
  {
    id: 446130,
    label: "446130 - Optical Goods Stores"
  },
  {
    id: 446191,
    label: "446191 - Food (Health) Supplement Stores"
  },
  {
    id: 446199,
    label: "446199 - All Other Health and Personal Care Stores"
  },
  {
    id: 447110,
    label: "447110 - Gasoline Stations with Convenience Stores"
  },
  {
    id: 447190,
    label: "447190 - Other Gasoline Stations"
  },
  {
    id: 448110,
    label: "448110 - Men's Clothing Stores"
  },
  {
    id: 448120,
    label: "448120 - Women's Clothing Stores"
  },
  {
    id: 448130,
    label: "448130 - Children's and Infants' Clothing Stores"
  },
  {
    id: 448140,
    label: "448140 - Family Clothing Stores"
  },
  {
    id: 448150,
    label: "448150 - Clothing Accessories Stores"
  },
  {
    id: 448190,
    label: "448190 - Other Clothing Stores"
  },
  {
    id: 448210,
    label: "448210 - Shoe Stores"
  },
  {
    id: 448310,
    label: "448310 - Jewelry Stores"
  },
  {
    id: 448320,
    label: "448320 - Luggage and Leather Goods Stores"
  },
  {
    id: 451110,
    label: "451110 - Sporting Goods Stores"
  },
  {
    id: 451120,
    label: "451120 - Hobby, Toy, and Game Stores"
  },
  {
    id: 451130,
    label: "451130 - Sewing, Needlework, and Piece Goods Stores"
  },
  {
    id: 451140,
    label: "451140 - Musical Instrument and Supplies Stores"
  },
  {
    id: 451211,
    label: "451211 - Book Stores"
  },
  {
    id: 451212,
    label: "451212 - News Dealers and Newsstands"
  },
  {
    id: 452210,
    label: "452210 - Department Stores"
  },
  {
    id: 452311,
    label: "452311 - Warehouse Clubs and Supercenters"
  },
  {
    id: 452319,
    label: "452319 - All Other General Merchandise Stores"
  },
  {
    id: 453110,
    label: "453110 - Florists"
  },
  {
    id: 453210,
    label: "453210 - Office Supplies and Stationery Stores"
  },
  {
    id: 453220,
    label: "453220 - Gift, Novelty, and Souvenir Stores"
  },
  {
    id: 453310,
    label: "453310 - Used Merchandise Stores"
  },
  {
    id: 453910,
    label: "453910 - Pet and Pet Supplies Stores"
  },
  {
    id: 453920,
    label: "453920 - Art Dealers"
  },
  {
    id: 453930,
    label: "453930 - Manufactured (Mobile) Home Dealers"
  },
  {
    id: 453991,
    label: "453991 - Tobacco Stores"
  },
  {
    id: 453998,
    label: "453998 - All Other Miscellaneous Store Retailers (except Tobacco Stores)"
  },
  {
    id: 454110,
    label: "454110 - Electronic Shopping and Mail-Order Houses"
  },
  {
    id: 454210,
    label: "454210 - Vending Machine Operators"
  },
  {
    id: 454310,
    label: "454310 - Fuel Dealers"
  },
  {
    id: 454390,
    label: "454390 - Other Direct Selling Establishments"
  },
  {
    id: 481111,
    label: "481111 - Scheduled Passenger Air Transportation"
  },
  {
    id: 481112,
    label: "481112 - Scheduled Freight Air Transportation"
  },
  {
    id: 481211,
    label: "481211 - Nonscheduled Chartered Passenger Air Transportation"
  },
  {
    id: 481212,
    label: "481212 - Nonscheduled Chartered Freight Air Transportation"
  },
  {
    id: 481219,
    label: "481219 - Other Nonscheduled Air Transportation"
  },
  {
    id: 482111,
    label: "482111 - Line-Haul Railroads"
  },
  {
    id: 482112,
    label: "482112 - Short Line Railroads"
  },
  {
    id: 483111,
    label: "483111 - Deep Sea Freight Transportation"
  },
  {
    id: 483112,
    label: "483112 - Deep Sea Passenger Transportation"
  },
  {
    id: 483113,
    label: "483113 - Coastal and Great Lakes Freight Transportation"
  },
  {
    id: 483114,
    label: "483114 - Coastal and Great Lakes Passenger Transportation"
  },
  {
    id: 483211,
    label: "483211 - Inland Water Freight Transportation"
  },
  {
    id: 483212,
    label: "483212 - Inland Water Passenger Transportation"
  },
  {
    id: 484110,
    label: "484110 - General Freight Trucking, Local"
  },
  {
    id: 484121,
    label: "484121 - General Freight Trucking, Long-Distance, Truckload"
  },
  {
    id: 484122,
    label: "484122 - General Freight Trucking, Long-Distance, Less Than Truckload"
  },
  {
    id: 484210,
    label: "484210 - Used Household and Office Goods Moving"
  },
  {
    id: 484220,
    label: "484220 - Specialized Freight (except Used Goods) Trucking, Local"
  },
  {
    id: 484230,
    label: "484230 - Specialized Freight (except Used Goods) Trucking, Long-Distance"
  },
  {
    id: 485111,
    label: "485111 - Mixed Mode Transit Systems"
  },
  {
    id: 485112,
    label: "485112 - Commuter Rail Systems"
  },
  {
    id: 485113,
    label: "485113 - Bus and Other Motor Vehicle Transit Systems"
  },
  {
    id: 485119,
    label: "485119 - Other Urban Transit Systems"
  },
  {
    id: 485210,
    label: "485210 - Interurban and Rural Bus Transportation"
  },
  {
    id: 485310,
    label: "485310 - Taxi Service"
  },
  {
    id: 485320,
    label: "485320 - Limousine Service"
  },
  {
    id: 485410,
    label: "485410 - School and Employee Bus Transportation"
  },
  {
    id: 485510,
    label: "485510 - Charter Bus Industry"
  },
  {
    id: 485991,
    label: "485991 - Special Needs Transportation"
  },
  {
    id: 485999,
    label: "485999 - All Other Transit and Ground Passenger Transportation"
  },
  {
    id: 486110,
    label: "486110 - Pipeline Transportation of Crude Oil"
  },
  {
    id: 486210,
    label: "486210 - Pipeline Transportation of Natural Gas"
  },
  {
    id: 486910,
    label: "486910 - Pipeline Transportation of Refined Petroleum Products"
  },
  {
    id: 486990,
    label: "486990 - All Other Pipeline Transportation"
  },
  {
    id: 487110,
    label: "487110 - Scenic and Sightseeing Transportation, Land"
  },
  {
    id: 487210,
    label: "487210 - Scenic and Sightseeing Transportation, Water"
  },
  {
    id: 487990,
    label: "487990 - Scenic and Sightseeing Transportation, Other"
  },
  {
    id: 488111,
    label: "488111 - Air Traffic Control"
  },
  {
    id: 488119,
    label: "488119 - Other Airport Operations"
  },
  {
    id: 488190,
    label: "488190 - Other Support Activities for Air Transportation"
  },
  {
    id: 488210,
    label: "488210 - Support Activities for Rail Transportation"
  },
  {
    id: 488310,
    label: "488310 - Port and Harbor Operations"
  },
  {
    id: 488320,
    label: "488320 - Marine Cargo Handling"
  },
  {
    id: 488330,
    label: "488330 - Navigational Services to Shipping"
  },
  {
    id: 488390,
    label: "488390 - Other Support Activities for Water Transportation"
  },
  {
    id: 488410,
    label: "488410 - Motor Vehicle Towing"
  },
  {
    id: 488490,
    label: "488490 - Other Support Activities for Road Transportation"
  },
  {
    id: 488510,
    label: "488510 - Freight Transportation Arrangement"
  },
  {
    id: 488991,
    label: "488991 - Packing and Crating"
  },
  {
    id: 488999,
    label: "488999 - All Other Support Activities for Transportation"
  },
  {
    id: 491110,
    label: "491110 - Postal Service"
  },
  {
    id: 492110,
    label: "492110 - Couriers and Express Delivery Services"
  },
  {
    id: 492210,
    label: "492210 - Local Messengers and Local Delivery"
  },
  {
    id: 493110,
    label: "493110 - General Warehousing and Storage"
  },
  {
    id: 493120,
    label: "493120 - Refrigerated Warehousing and Storage"
  },
  {
    id: 493130,
    label: "493130 - Farm Product Warehousing and Storage"
  },
  {
    id: 493190,
    label: "493190 - Other Warehousing and Storage"
  },
  {
    id: 511110,
    label: "511110 - Newspaper Publishers"
  },
  {
    id: 511120,
    label: "511120 - Periodical Publishers"
  },
  {
    id: 511130,
    label: "511130 - Book Publishers"
  },
  {
    id: 511140,
    label: "511140 - Directory and Mailing List Publishers"
  },
  {
    id: 511191,
    label: "511191 - Greeting Card Publishers"
  },
  {
    id: 511199,
    label: "511199 - All Other Publishers"
  },
  {
    id: 511210,
    label: "511210 - Software Publishers"
  },
  {
    id: 512110,
    label: "512110 - Motion Picture and Video Production"
  },
  {
    id: 512120,
    label: "512120 - Motion Picture and Video Distribution"
  },
  {
    id: 512131,
    label: "512131 - Motion Picture Theaters (except Drive-Ins)"
  },
  {
    id: 512132,
    label: "512132 - Drive-In Motion Picture Theaters"
  },
  {
    id: 512191,
    label: "512191 - Teleproduction and Other Postproduction Services"
  },
  {
    id: 512199,
    label: "512199 - Other Motion Picture and Video Industries"
  },
  {
    id: 512230,
    label: "512230 - Music Publishers"
  },
  {
    id: 512240,
    label: "512240 - Sound Recording Studios"
  },
  {
    id: 512250,
    label: "512250 - Record Production and Distribution"
  },
  {
    id: 512290,
    label: "512290 - Other Sound Recording Industries"
  },
  {
    id: 515111,
    label: "515111 - Radio Networks"
  },
  {
    id: 515112,
    label: "515112 - Radio Stations"
  },
  {
    id: 515120,
    label: "515120 - Television Broadcasting"
  },
  {
    id: 515210,
    label: "515210 - Cable and Other Subscription Programming"
  },
  {
    id: 517311,
    label: "517311 - Wired Telecommunications Carriers"
  },
  {
    id: 517312,
    label: "517312 - Wireless Telecommunications Carriers (except Satellite)"
  },
  {
    id: 517410,
    label: "517410 - Satellite Telecommunications"
  },
  {
    id: 517911,
    label: "517911 - Telecommunications Resellers"
  },
  {
    id: 517919,
    label: "517919 - All Other Telecommunications"
  },
  {
    id: 518210,
    label: "518210 - Data Processing, Hosting, and Related Services"
  },
  {
    id: 519110,
    label: "519110 - News Syndicates"
  },
  {
    id: 519120,
    label: "519120 - Libraries and Archives"
  },
  {
    id: 519130,
    label: "519130 - Internet Publishing and Broadcasting and Web Search Portals"
  },
  {
    id: 519190,
    label: "519190 - All Other Information Services"
  },
  {
    id: 521110,
    label: "521110 - Monetary Authorities-Central Bank"
  },
  {
    id: 522110,
    label: "522110 - Commercial Banking"
  },
  {
    id: 522120,
    label: "522120 - Savings Institutions"
  },
  {
    id: 522130,
    label: "522130 - Credit Unions"
  },
  {
    id: 522190,
    label: "522190 - Other Depository Credit Intermediation"
  },
  {
    id: 522210,
    label: "522210 - Credit Card Issuing"
  },
  {
    id: 522220,
    label: "522220 - Sales Financing"
  },
  {
    id: 522291,
    label: "522291 - Consumer Lending"
  },
  {
    id: 522292,
    label: "522292 - Real Estate Credit"
  },
  {
    id: 522293,
    label: "522293 - International Trade Financing"
  },
  {
    id: 522294,
    label: "522294 - Secondary Market Financing"
  },
  {
    id: 522298,
    label: "522298 - All Other Nondepository Credit Intermediation"
  },
  {
    id: 522310,
    label: "522310 - Mortgage and Nonmortgage Loan Brokers"
  },
  {
    id: 522320,
    label: "522320 - Financial Transactions Processing, Reserve, and Clearinghouse Activities"
  },
  {
    id: 522390,
    label: "522390 - Other Activities Related to Credit Intermediation"
  },
  {
    id: 523110,
    label: "523110 - Investment Banking and Securities Dealing"
  },
  {
    id: 523120,
    label: "523120 - Securities Brokerage"
  },
  {
    id: 523130,
    label: "523130 - Commodity Contracts Dealing"
  },
  {
    id: 523140,
    label: "523140 - Commodity Contracts Brokerage"
  },
  {
    id: 523210,
    label: "523210 - Securities and Commodity Exchanges"
  },
  {
    id: 523910,
    label: "523910 - Miscellaneous Intermediation"
  },
  {
    id: 523920,
    label: "523920 - Portfolio Management"
  },
  {
    id: 523930,
    label: "523930 - Investment Advice"
  },
  {
    id: 523991,
    label: "523991 - Trust, Fiduciary, and Custody Activities"
  },
  {
    id: 523999,
    label: "523999 - Miscellaneous Financial Investment Activities"
  },
  {
    id: 524113,
    label: "524113 - Direct Life Insurance Carriers"
  },
  {
    id: 524114,
    label: "524114 - Direct Health and Medical Insurance Carriers"
  },
  {
    id: 524126,
    label: "524126 - Direct Property and Casualty Insurance Carriers"
  },
  {
    id: 524127,
    label: "524127 - Direct Title Insurance Carriers"
  },
  {
    id: 524128,
    label: "524128 - Other Direct Insurance (except Life, Health, and Medical) Carriers"
  },
  {
    id: 524130,
    label: "524130 - Reinsurance Carriers"
  },
  {
    id: 524210,
    label: "524210 - Insurance Agencies and Brokerages"
  },
  {
    id: 524291,
    label: "524291 - Claims Adjusting"
  },
  {
    id: 524292,
    label: "524292 - Third Party Administration of Insurance and Pension Funds"
  },
  {
    id: 524298,
    label: "524298 - All Other Insurance Related Activities"
  },
  {
    id: 525110,
    label: "525110 - Pension Funds"
  },
  {
    id: 525120,
    label: "525120 - Health and Welfare Funds"
  },
  {
    id: 525190,
    label: "525190 - Other Insurance Funds"
  },
  {
    id: 525910,
    label: "525910 - Open-End Investment Funds"
  },
  {
    id: 525920,
    label: "525920 - Trusts, Estates, and Agency Accounts"
  },
  {
    id: 525990,
    label: "525990 - Other Financial Vehicles"
  },
  {
    id: 531110,
    label: "531110 - Lessors of Residential Buildings and Dwellings"
  },
  {
    id: 531120,
    label: "531120 - Lessors of Nonresidential Buildings (except Miniwarehouses)"
  },
  {
    id: 531130,
    label: "531130 - Lessors of Miniwarehouses and Self-Storage Units"
  },
  {
    id: 531190,
    label: "531190 - Lessors of Other Real Estate Property"
  },
  {
    id: 531210,
    label: "531210 - Offices of Real Estate Agents and Brokers"
  },
  {
    id: 531311,
    label: "531311 - Residential Property Managers"
  },
  {
    id: 531312,
    label: "531312 - Nonresidential Property Managers"
  },
  {
    id: 531320,
    label: "531320 - Offices of Real Estate Appraisers"
  },
  {
    id: 531390,
    label: "531390 - Other Activities Related to Real Estate"
  },
  {
    id: 532111,
    label: "532111 - Passenger Car Rental"
  },
  {
    id: 532112,
    label: "532112 - Passenger Car Leasing"
  },
  {
    id: 532120,
    label: "532120 - Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing"
  },
  {
    id: 532210,
    label: "532210 - Consumer Electronics and Appliances Rental"
  },
  {
    id: 532281,
    label: "532281 - Formal Wear and Costume Rental"
  },
  {
    id: 532282,
    label: "532282 - Video Tape and Disc Rental"
  },
  {
    id: 532283,
    label: "532283 - Home Health Equipment Rental"
  },
  {
    id: 532284,
    label: "532284 - Recreational Goods Rental"
  },
  {
    id: 532289,
    label: "532289 - All Other Consumer Goods Rental"
  },
  {
    id: 532310,
    label: "532310 - General Rental Centers"
  },
  {
    id: 532411,
    label: "532411 - Commercial Air, Rail, and Water Transportation Equipment Rental and Leasing"
  },
  {
    id: 532412,
    label: "532412 - Construction, Mining, and Forestry Machinery and Equipment Rental and Leasing"
  },
  {
    id: 532420,
    label: "532420 - Office Machinery and Equipment Rental and Leasing"
  },
  {
    id: 532490,
    label: "532490 - Other Commercial and Industrial Machinery and Equipment Rental and Leasing"
  },
  {
    id: 533110,
    label: "533110 - Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)"
  },
  {
    id: 541110,
    label: "541110 - Offices of Lawyers"
  },
  {
    id: 541120,
    label: "541120 - Offices of Notaries"
  },
  {
    id: 541191,
    label: "541191 - Title Abstract and Settlement Offices"
  },
  {
    id: 541199,
    label: "541199 - All Other Legal Services"
  },
  {
    id: 541211,
    label: "541211 - Offices of Certified Public Accountants"
  },
  {
    id: 541213,
    label: "541213 - Tax Preparation Services"
  },
  {
    id: 541214,
    label: "541214 - Payroll Services"
  },
  {
    id: 541219,
    label: "541219 - Other Accounting Services"
  },
  {
    id: 541310,
    label: "541310 - Architectural Services"
  },
  {
    id: 541320,
    label: "541320 - Landscape Architectural Services"
  },
  {
    id: 541330,
    label: "541330 - Engineering Services"
  },
  {
    id: 541340,
    label: "541340 - Drafting Services"
  },
  {
    id: 541350,
    label: "541350 - Building Inspection Services"
  },
  {
    id: 541360,
    label: "541360 - Geophysical Surveying and Mapping Services"
  },
  {
    id: 541370,
    label: "541370 - Surveying and Mapping (except Geophysical) Services"
  },
  {
    id: 541380,
    label: "541380 - Testing Laboratories"
  },
  {
    id: 541410,
    label: "541410 - Interior Design Services"
  },
  {
    id: 541420,
    label: "541420 - Industrial Design Services"
  },
  {
    id: 541430,
    label: "541430 - Graphic Design Services"
  },
  {
    id: 541490,
    label: "541490 - Other Specialized Design Services"
  },
  {
    id: 541511,
    label: "541511 - Custom Computer Programming Services"
  },
  {
    id: 541512,
    label: "541512 - Computer Systems Design Services"
  },
  {
    id: 541513,
    label: "541513 - Computer Facilities Management Services"
  },
  {
    id: 541519,
    label: "541519 - Other Computer Related Services"
  },
  {
    id: 541611,
    label: "541611 - Administrative Management and General Management Consulting Services"
  },
  {
    id: 541612,
    label: "541612 - Human Resources Consulting Services"
  },
  {
    id: 541613,
    label: "541613 - Marketing Consulting Services"
  },
  {
    id: 541614,
    label: "541614 - Process, Physical Distribution, and Logistics Consulting Services"
  },
  {
    id: 541618,
    label: "541618 - Other Management Consulting Services"
  },
  {
    id: 541620,
    label: "541620 - Environmental Consulting Services"
  },
  {
    id: 541690,
    label: "541690 - Other Scientific and Technical Consulting Services"
  },
  {
    id: 541713,
    label: "541713 - Research and Development in Nanotechnology"
  },
  {
    id: 541714,
    label: "541714 - Research and Development in Biotechnology (except Nanobiotechnology)"
  },
  {
    id: 541715,
    label: "541715 - Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology)"
  },
  {
    id: 541720,
    label: "541720 - Research and Development in the Social Sciences and Humanities"
  },
  {
    id: 541810,
    label: "541810 - Advertising Agencies"
  },
  {
    id: 541820,
    label: "541820 - Public Relations Agencies"
  },
  {
    id: 541830,
    label: "541830 - Media Buying Agencies"
  },
  {
    id: 541840,
    label: "541840 - Media Representatives"
  },
  {
    id: 541850,
    label: "541850 - Outdoor Advertising"
  },
  {
    id: 541860,
    label: "541860 - Direct Mail Advertising"
  },
  {
    id: 541870,
    label: "541870 - Advertising Material Distribution Services"
  },
  {
    id: 541890,
    label: "541890 - Other Services Related to Advertising"
  },
  {
    id: 541910,
    label: "541910 - Marketing Research and Public Opinion Polling"
  },
  {
    id: 541921,
    label: "541921 - Photography Studios, Portrait"
  },
  {
    id: 541922,
    label: "541922 - Commercial Photography"
  },
  {
    id: 541930,
    label: "541930 - Translation and Interpretation Services"
  },
  {
    id: 541940,
    label: "541940 - Veterinary Services"
  },
  {
    id: 541990,
    label: "541990 - All Other Professional, Scientific, and Technical Services"
  },
  {
    id: 551111,
    label: "551111 - Offices of Bank Holding Companies"
  },
  {
    id: 551112,
    label: "551112 - Offices of Other Holding Companies"
  },
  {
    id: 551114,
    label: "551114 - Corporate, Subsidiary, and Regional Managing Offices"
  },
  {
    id: 561110,
    label: "561110 - Office Administrative Services"
  },
  {
    id: 561210,
    label: "561210 - Facilities Support Services"
  },
  {
    id: 561311,
    label: "561311 - Employment Placement Agencies"
  },
  {
    id: 561312,
    label: "561312 - Executive Search Services"
  },
  {
    id: 561320,
    label: "561320 - Temporary Help Services"
  },
  {
    id: 561330,
    label: "561330 - Professional Employer Organizations"
  },
  {
    id: 561410,
    label: "561410 - Document Preparation Services"
  },
  {
    id: 561421,
    label: "561421 - Telephone Answering Services"
  },
  {
    id: 561422,
    label: "561422 - Telemarketing Bureaus and Other Contact Centers"
  },
  {
    id: 561431,
    label: "561431 - Private Mail Centers"
  },
  {
    id: 561439,
    label: "561439 - Other Business Service Centers (including Copy Shops)"
  },
  {
    id: 561440,
    label: "561440 - Collection Agencies"
  },
  {
    id: 561450,
    label: "561450 - Credit Bureaus"
  },
  {
    id: 561491,
    label: "561491 - Repossession Services"
  },
  {
    id: 561492,
    label: "561492 - Court Reporting and Stenotype Services"
  },
  {
    id: 561499,
    label: "561499 - All Other Business Support Services"
  },
  {
    id: 561510,
    label: "561510 - Travel Agencies"
  },
  {
    id: 561520,
    label: "561520 - Tour Operators"
  },
  {
    id: 561591,
    label: "561591 - Convention and Visitors Bureaus"
  },
  {
    id: 561599,
    label: "561599 - All Other Travel Arrangement and Reservation Services"
  },
  {
    id: 561611,
    label: "561611 - Investigation Services"
  },
  {
    id: 561612,
    label: "561612 - Security Guards and Patrol Services"
  },
  {
    id: 561613,
    label: "561613 - Armored Car Services"
  },
  {
    id: 561621,
    label: "561621 - Security Systems Services (except Locksmiths)"
  },
  {
    id: 561622,
    label: "561622 - Locksmiths"
  },
  {
    id: 561710,
    label: "561710 - Exterminating and Pest Control Services"
  },
  {
    id: 561720,
    label: "561720 - Janitorial Services"
  },
  {
    id: 561730,
    label: "561730 - Landscaping Services"
  },
  {
    id: 561740,
    label: "561740 - Carpet and Upholstery Cleaning Services"
  },
  {
    id: 561790,
    label: "561790 - Other Services to Buildings and Dwellings"
  },
  {
    id: 561910,
    label: "561910 - Packaging and Labeling Services"
  },
  {
    id: 561920,
    label: "561920 - Convention and Trade Show Organizers"
  },
  {
    id: 561990,
    label: "561990 - All Other Support Services"
  },
  {
    id: 562111,
    label: "562111 - Solid Waste Collection"
  },
  {
    id: 562112,
    label: "562112 - Hazardous Waste Collection"
  },
  {
    id: 562119,
    label: "562119 - Other Waste Collection"
  },
  {
    id: 562211,
    label: "562211 - Hazardous Waste Treatment and Disposal"
  },
  {
    id: 562212,
    label: "562212 - Solid Waste Landfill"
  },
  {
    id: 562213,
    label: "562213 - Solid Waste Combustors and Incinerators"
  },
  {
    id: 562219,
    label: "562219 - Other Nonhazardous Waste Treatment and Disposal"
  },
  {
    id: 562910,
    label: "562910 - Remediation Services"
  },
  {
    id: 562920,
    label: "562920 - Materials Recovery Facilities"
  },
  {
    id: 562991,
    label: "562991 - Septic Tank and Related Services"
  },
  {
    id: 562998,
    label: "562998 - All Other Miscellaneous Waste Management Services"
  },
  {
    id: 611110,
    label: "611110 - Elementary and Secondary Schools"
  },
  {
    id: 611210,
    label: "611210 - Junior Colleges"
  },
  {
    id: 611310,
    label: "611310 - Colleges, Universities, and Professional Schools"
  },
  {
    id: 611410,
    label: "611410 - Business and Secretarial Schools"
  },
  {
    id: 611420,
    label: "611420 - Computer Training"
  },
  {
    id: 611430,
    label: "611430 - Professional and Management Development Training"
  },
  {
    id: 611511,
    label: "611511 - Cosmetology and Barber Schools"
  },
  {
    id: 611512,
    label: "611512 - Flight Training"
  },
  {
    id: 611513,
    label: "611513 - Apprenticeship Training"
  },
  {
    id: 611519,
    label: "611519 - Other Technical and Trade Schools"
  },
  {
    id: 611610,
    label: "611610 - Fine Arts Schools"
  },
  {
    id: 611620,
    label: "611620 - Sports and Recreation Instruction"
  },
  {
    id: 611630,
    label: "611630 - Language Schools"
  },
  {
    id: 611691,
    label: "611691 - Exam Preparation and Tutoring"
  },
  {
    id: 611692,
    label: "611692 - Automobile Driving Schools"
  },
  {
    id: 611699,
    label: "611699 - All Other Miscellaneous Schools and Instruction"
  },
  {
    id: 611710,
    label: "611710 - Educational Support Services"
  },
  {
    id: 621111,
    label: "621111 - Offices of Physicians (except Mental Health Specialists)"
  },
  {
    id: 621112,
    label: "621112 - Offices of Physicians, Mental Health Specialists"
  },
  {
    id: 621210,
    label: "621210 - Offices of Dentists"
  },
  {
    id: 621310,
    label: "621310 - Offices of Chiropractors"
  },
  {
    id: 621320,
    label: "621320 - Offices of Optometrists"
  },
  {
    id: 621330,
    label: "621330 - Offices of Mental Health Practitioners (except Physicians)"
  },
  {
    id: 621340,
    label: "621340 - Offices of Physical, Occupational and Speech Therapists, and Audiologists"
  },
  {
    id: 621391,
    label: "621391 - Offices of Podiatrists"
  },
  {
    id: 621399,
    label: "621399 - Offices of All Other Miscellaneous Health Practitioners"
  },
  {
    id: 621410,
    label: "621410 - Family Planning Centers"
  },
  {
    id: 621420,
    label: "621420 - Outpatient Mental Health and Substance Abuse Centers"
  },
  {
    id: 621491,
    label: "621491 - HMO Medical Centers"
  },
  {
    id: 621492,
    label: "621492 - Kidney Dialysis Centers"
  },
  {
    id: 621493,
    label: "621493 - Freestanding Ambulatory Surgical and Emergency Centers"
  },
  {
    id: 621498,
    label: "621498 - All Other Outpatient Care Centers"
  },
  {
    id: 621511,
    label: "621511 - Medical Laboratories"
  },
  {
    id: 621512,
    label: "621512 - Diagnostic Imaging Centers"
  },
  {
    id: 621610,
    label: "621610 - Home Health Care Services"
  },
  {
    id: 621910,
    label: "621910 - Ambulance Services"
  },
  {
    id: 621991,
    label: "621991 - Blood and Organ Banks"
  },
  {
    id: 621999,
    label: "621999 - All Other Miscellaneous Ambulatory Health Care Services"
  },
  {
    id: 622110,
    label: "622110 - General Medical and Surgical Hospitals"
  },
  {
    id: 622210,
    label: "622210 - Psychiatric and Substance Abuse Hospitals"
  },
  {
    id: 622310,
    label: "622310 - Specialty (except Psychiatric and Substance Abuse) Hospitals"
  },
  {
    id: 623110,
    label: "623110 - Nursing Care Facilities (Skilled Nursing Facilities)"
  },
  {
    id: 623210,
    label: "623210 - Residential Intellectual and Developmental Disability Facilities"
  },
  {
    id: 623220,
    label: "623220 - Residential Mental Health and Substance Abuse Facilities"
  },
  {
    id: 623311,
    label: "623311 - Continuing Care Retirement Communities"
  },
  {
    id: 623312,
    label: "623312 - Assisted Living Facilities for the Elderly"
  },
  {
    id: 623990,
    label: "623990 - Other Residential Care Facilities"
  },
  {
    id: 624110,
    label: "624110 - Child and Youth Services"
  },
  {
    id: 624120,
    label: "624120 - Services for the Elderly and Persons with Disabilities"
  },
  {
    id: 624190,
    label: "624190 - Other Individual and Family Services"
  },
  {
    id: 624210,
    label: "624210 - Community Food Services"
  },
  {
    id: 624221,
    label: "624221 - Temporary Shelters"
  },
  {
    id: 624229,
    label: "624229 - Other Community Housing Services"
  },
  {
    id: 624230,
    label: "624230 - Emergency and Other Relief Services"
  },
  {
    id: 624310,
    label: "624310 - Vocational Rehabilitation Services"
  },
  {
    id: 624410,
    label: "624410 - Child Day Care Services"
  },
  {
    id: 711110,
    label: "711110 - Theater Companies and Dinner Theaters"
  },
  {
    id: 711120,
    label: "711120 - Dance Companies"
  },
  {
    id: 711130,
    label: "711130 - Musical Groups and Artists"
  },
  {
    id: 711190,
    label: "711190 - Other Performing Arts Companies"
  },
  {
    id: 711211,
    label: "711211 - Sports Teams and Clubs"
  },
  {
    id: 711212,
    label: "711212 - Racetracks"
  },
  {
    id: 711219,
    label: "711219 - Other Spectator Sports"
  },
  {
    id: 711310,
    label: "711310 - Promoters of Performing Arts, Sports, and Similar Events with Facilities"
  },
  {
    id: 711320,
    label: "711320 - Promoters of Performing Arts, Sports, and Similar Events without Facilities"
  },
  {
    id: 711410,
    label: "711410 - Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures"
  },
  {
    id: 711510,
    label: "711510 - Independent Artists, Writers, and Performers"
  },
  {
    id: 712110,
    label: "712110 - Museums"
  },
  {
    id: 712120,
    label: "712120 - Historical Sites"
  },
  {
    id: 712130,
    label: "712130 - Zoos and Botanical Gardens"
  },
  {
    id: 712190,
    label: "712190 - Nature Parks and Other Similar Institutions"
  },
  {
    id: 713110,
    label: "713110 - Amusement and Theme Parks"
  },
  {
    id: 713120,
    label: "713120 - Amusement Arcades"
  },
  {
    id: 713210,
    label: "713210 - Casinos (except Casino Hotels)"
  },
  {
    id: 713290,
    label: "713290 - Other Gambling Industries"
  },
  {
    id: 713910,
    label: "713910 - Golf Courses and Country Clubs"
  },
  {
    id: 713920,
    label: "713920 - Skiing Facilities"
  },
  {
    id: 713930,
    label: "713930 - Marinas"
  },
  {
    id: 713940,
    label: "713940 - Fitness and Recreational Sports Centers"
  },
  {
    id: 713950,
    label: "713950 - Bowling Centers"
  },
  {
    id: 713990,
    label: "713990 - All Other Amusement and Recreation Industries"
  },
  {
    id: 721110,
    label: "721110 - Hotels (except Casino Hotels) and Motels"
  },
  {
    id: 721120,
    label: "721120 - Casino Hotels"
  },
  {
    id: 721191,
    label: "721191 - Bed-and-Breakfast Inns"
  },
  {
    id: 721199,
    label: "721199 - All Other Traveler Accommodation"
  },
  {
    id: 721211,
    label: "721211 - RV (Recreational Vehicle) Parks and Campgrounds"
  },
  {
    id: 721214,
    label: "721214 - Recreational and Vacation Camps (except Campgrounds)"
  },
  {
    id: 721310,
    label: "721310 - Rooming and Boarding Houses, Dormitories, and Workers' Camps"
  },
  {
    id: 722310,
    label: "722310 - Food Service Contractors"
  },
  {
    id: 722320,
    label: "722320 - Caterers"
  },
  {
    id: 722330,
    label: "722330 - Mobile Food Services"
  },
  {
    id: 722410,
    label: "722410 - Drinking Places (Alcoholic Beverages)"
  },
  {
    id: 722511,
    label: "722511 - Full-Service Restaurants"
  },
  {
    id: 722513,
    label: "722513 - Limited-Service Restaurants"
  },
  {
    id: 722514,
    label: "722514 - Cafeterias, Grill Buffets, and Buffets"
  },
  {
    id: 722515,
    label: "722515 - Snack and Nonalcoholic Beverage Bars"
  },
  {
    id: 811111,
    label: "811111 - General Automotive Repair"
  },
  {
    id: 811112,
    label: "811112 - Automotive Exhaust System Repair"
  },
  {
    id: 811113,
    label: "811113 - Automotive Transmission Repair"
  },
  {
    id: 811118,
    label: "811118 - Other Automotive Mechanical and Electrical Repair and Maintenance"
  },
  {
    id: 811121,
    label: "811121 - Automotive Body, Paint, and Interior Repair and Maintenance"
  },
  {
    id: 811122,
    label: "811122 - Automotive Glass Replacement Shops"
  },
  {
    id: 811191,
    label: "811191 - Automotive Oil Change and Lubrication Shops"
  },
  {
    id: 811192,
    label: "811192 - Car Washes"
  },
  {
    id: 811198,
    label: "811198 - All Other Automotive Repair and Maintenance"
  },
  {
    id: 811211,
    label: "811211 - Consumer Electronics Repair and Maintenance"
  },
  {
    id: 811212,
    label: "811212 - Computer and Office Machine Repair and Maintenance"
  },
  {
    id: 811213,
    label: "811213 - Communication Equipment Repair and Maintenance"
  },
  {
    id: 811219,
    label: "811219 - Other Electronic and Precision Equipment Repair and Maintenance"
  },
  {
    id: 811310,
    label: "811310 - Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance"
  },
  {
    id: 811411,
    label: "811411 - Home and Garden Equipment Repair and Maintenance"
  },
  {
    id: 811412,
    label: "811412 - Appliance Repair and Maintenance"
  },
  {
    id: 811420,
    label: "811420 - Reupholstery and Furniture Repair"
  },
  {
    id: 811430,
    label: "811430 - Footwear and Leather Goods Repair"
  },
  {
    id: 811490,
    label: "811490 - Other Personal and Household Goods Repair and Maintenance"
  },
  {
    id: 812111,
    label: "812111 - Barber Shops"
  },
  {
    id: 812112,
    label: "812112 - Beauty Salons"
  },
  {
    id: 812113,
    label: "812113 - Nail Salons"
  },
  {
    id: 812191,
    label: "812191 - Diet and Weight Reducing Centers"
  },
  {
    id: 812199,
    label: "812199 - Other Personal Care Services"
  },
  {
    id: 812210,
    label: "812210 - Funeral Homes and Funeral Services"
  },
  {
    id: 812220,
    label: "812220 - Cemeteries and Crematories"
  },
  {
    id: 812310,
    label: "812310 - Coin-Operated Laundries and Drycleaners"
  },
  {
    id: 812320,
    label: "812320 - Drycleaning and Laundry Services (except Coin-Operated)"
  },
  {
    id: 812331,
    label: "812331 - Linen Supply"
  },
  {
    id: 812332,
    label: "812332 - Industrial Launderers"
  },
  {
    id: 812910,
    label: "812910 - Pet Care (except Veterinary) Services"
  },
  {
    id: 812921,
    label: "812921 - Photofinishing Laboratories (except One-Hour)"
  },
  {
    id: 812922,
    label: "812922 - One-Hour Photofinishing"
  },
  {
    id: 812930,
    label: "812930 - Parking Lots and Garages"
  },
  {
    id: 812990,
    label: "812990 - All Other Personal Services"
  },
  {
    id: 813110,
    label: "813110 - Religious Organizations"
  },
  {
    id: 813211,
    label: "813211 - Grantmaking Foundations"
  },
  {
    id: 813212,
    label: "813212 - Voluntary Health Organizations"
  },
  {
    id: 813219,
    label: "813219 - Other Grantmaking and Giving Services"
  },
  {
    id: 813311,
    label: "813311 - Human Rights Organizations"
  },
  {
    id: 813312,
    label: "813312 - Environment, Conservation and Wildlife Organizations"
  },
  {
    id: 813319,
    label: "813319 - Other Social Advocacy Organizations"
  },
  {
    id: 813410,
    label: "813410 - Civic and Social Organizations"
  },
  {
    id: 813910,
    label: "813910 - Business Associations"
  },
  {
    id: 813920,
    label: "813920 - Professional Organizations"
  },
  {
    id: 813930,
    label: "813930 - Labor Unions and Similar Labor Organizations"
  },
  {
    id: 813940,
    label: "813940 - Political Organizations"
  },
  {
    id: 813990,
    label: "813990 - Other Similar Organizations (except Business, Professional, Labor, and Political Organizations)"
  },
  {
    id: 814110,
    label: "814110 - Private Households"
  },
  {
    id: 921110,
    label: "921110 - Executive Offices"
  },
  {
    id: 921120,
    label: "921120 - Legislative Bodies"
  },
  {
    id: 921130,
    label: "921130 - Public Finance Activities"
  },
  {
    id: 921140,
    label: "921140 - Executive and Legislative Offices, Combined"
  },
  {
    id: 921150,
    label: "921150 - American Indian and Alaska Native Tribal Governments"
  },
  {
    id: 921190,
    label: "921190 - Other General Government Support"
  },
  {
    id: 922110,
    label: "922110 - Courts"
  },
  {
    id: 922120,
    label: "922120 - Police Protection"
  },
  {
    id: 922130,
    label: "922130 - Legal Counsel and Prosecution"
  },
  {
    id: 922140,
    label: "922140 - Correctional Institutions"
  },
  {
    id: 922150,
    label: "922150 - Parole Offices and Probation Offices"
  },
  {
    id: 922160,
    label: "922160 - Fire Protection"
  },
  {
    id: 922190,
    label: "922190 - Other Justice, Public Order, and Safety Activities"
  },
  {
    id: 923110,
    label: "923110 - Administration of Education Programs"
  },
  {
    id: 923120,
    label: "923120 - Administration of Public Health Programs"
  },
  {
    id: 923130,
    label: "923130 - Administration of Human Resource Programs (except Education, Public Health, and Veterans' Affairs Programs)"
  },
  {
    id: 923140,
    label: "923140 - Administration of Veterans' Affairs"
  },
  {
    id: 924110,
    label: "924110 - Administration of Air and Water Resource and Solid Waste Management Programs"
  },
  {
    id: 924120,
    label: "924120 - Administration of Conservation Programs"
  },
  {
    id: 925110,
    label: "925110 - Administration of Housing Programs"
  },
  {
    id: 925120,
    label: "925120 - Administration of Urban Planning and Community and Rural Development"
  },
  {
    id: 926110,
    label: "926110 - Administration of General Economic Programs"
  },
  {
    id: 926120,
    label: "926120 - Regulation and Administration of Transportation Programs"
  },
  {
    id: 926130,
    label: "926130 - Regulation and Administration of Communications, Electric, Gas, and Other Utilities"
  },
  {
    id: 926140,
    label: "926140 - Regulation of Agricultural Marketing and Commodities"
  },
  {
    id: 926150,
    label: "926150 - Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors"
  },
  {
    id: 927110,
    label: "927110 - Space Research and Technology"
  },
  {
    id: 928110,
    label: "928110 - National Security"
  },
  {
    id: 928120,
    label: "928120 - International Affairs"
  }
];

import React, { useEffect, useState } from "react";
import { getAgencyDashboardData } from "../../util/api";
import { useParams, useHistory } from "react-router-dom";
import { getDocumentDownloadUrl } from "../../util/api";

const DocumentDownload = (props) => {
  const { documentId } = useParams();

  useEffect(() => {
    const fetchData = async (documentId) => {
      const tokenId = await getDocumentDownloadUrl(documentId);
      // window.open(tokenId, "_blank");
      window.location.assign(tokenId);
    };
    if (documentId) {
      fetchData(documentId);
    }
  }, []);

  return <div className="document-download">{documentId}</div>;
};

export default DocumentDownload;

import React, { useEffect } from "react";
import { withFormikFormComponent } from "../Forms/FormikForm";
import ReactiveButton from "../Forms/Input/ReactiveButton";
import { FastField, Form } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputComponent from "../Forms/Input/InputComponent";
import DocumentWrapper from "../Forms/helpers/DocumentWrapper";
import DebugModal from "../Debug/DebugModal";
import { handleDocumentClick } from "../../util/helpers";
import { schemas } from "../../util/errorHelper";
import { apiBase } from "../../util/api";

const SettingsFormInternal = (props) => {
  const { formikBag } = props;
  const { values, isValid, isSubmitting, setFieldValue, dirty } = formikBag;

  const selectionText = (selection) => {
    switch (selection) {
      case "contracts":
        return (
          <div className="col-12 col-lg-6 border pt-2 mb-3">
            <p>
              <strong>For Agencies that do not use the State Financial System</strong>
            </p>
            <br />
            <p>Upload a file containing information about awarded contracts and payments to the prime contractor using a comma-separated file in the format listed below:</p>
            <br />
            <p>
              <a href={`${apiBase}/import/documents/samplecontractuploadtemplate`}>Contract / Payment File Format </a>
              (CSV)
            </p>
            <br />
            <p>
              <a href={`${apiBase}/import/documents/samplecontractuploadinstructions`}>Instructions for creating the Contract and Prime Payments upload file </a>
              (PDF)
            </p>
          </div>
        );
      case "activity":
        return (
          <div className="col-12 col-lg-6 border pt-2 mb-3">
            <p>
              <strong>For agencies not using compliance reports OR agencies wishing to add additional subcontractor payments</strong>
            </p>
            <br />
            <p>Upload a file containing payments to SDVOB Subcontractors on awarded contracts using a comma-separated file in the format listed below:</p>
            <br />
            <p>
              <a href={`${apiBase}/import/documents/sampleactivityreportuploadtemplate`}> Subcontractor Payment Format </a>
              (CSV)
            </p>
            <br />
            <p>
              <a href={`${apiBase}/import/documents/sampleactivityreportuploadinstructions`}> Instructions for creating the Subcontract Payment upload file </a>
              (PDF)
            </p>
          </div>
        );
      default:
        break;
    }
  };

  return (
    <Form>
      <DebugModal formikBag={formikBag} />
      <div className="field-group row">
        <div className="col-12 d-relative d-lg-flex">
          <FastField
            wrapperClass="col-12 col-lg-6 mb-4"
            label="Import Type"
            type="radio"
            component={InputComponent}
            name="importType"
            options={[
              ["contracts", "Prime Contractors, Contracts, & Payments to Primes"],
              ["activity", "Payments to SDVOB Sub Contractors"],
            ]}
            inline={true}
          />
          {selectionText(values?.importType)}
        </div>
        {values?.importType === "url" ? (
          <>
            <FastField wrapperClass="col-lg-6" label="SFS Feed URL" type="text" name={`sourceUrl`} component={InputComponent} placeholder="SFS Feed URL" />
          </>
        ) : null}

        {values?.importType && (
          <div className="col-12">
            {values?.importFile?.documentId ? (
              <div className="faux-document document-sm mb-4 ">
                <FontAwesomeIcon className="mr-3 text-secondary" icon={["fas", "times"]} size="sm" onClick={() => setFieldValue("importFile", {})} data-target={"importFile"} />
                <span className="faux-link inline" onClick={() => handleDocumentClick(values?.importFile?.documentId)}>
                  {values?.importFile?.description}
                </span>
              </div>
            ) : (
              <>
                <p>Only CSV file format will be accepted</p>
                <div className="row">
                  <FastField
                    name={`importFile`}
                    wrapperClass="col-sm-6 col-md-4 col-xl-3 file-sm"
                    // label={<FontAwesomeIcon className="" icon={['fas', 'paperclip']} size="lg" />}
                    label={`Upload Document`}
                    type="fileonly"
                    accept=".csv"
                    component={InputComponent}
                    // onFileUpload={(document) => {
                    //   arrayHelpers.push({ ...document });
                    // }}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>

      <ReactiveButton type="submit" className="btn btn-secondary margin-auto" label="Submit" disabled={!isValid || !dirty} processing={isSubmitting} />
      <p>
        Agency Uploads are handled asynchronously. When the upload process is completed, you will receive a notification via email indicating whether the import was successful. Unsuccessful imports
        will also include details about why the import failed. You may also return to this screen at any time to see the status of current and historic imports.
      </p>
    </Form>
  );
};

const SettingsForm = withFormikFormComponent(SettingsFormInternal, schemas.dataImportFormSchema);
export default SettingsForm;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { last } from "lodash";
import React, { useEffect, useState } from "react";
import { getApplications, getDivisionUsers, assignApplicationUser } from "../../../util/api";
import { daysLeftColorize, getRelevantApplicationTimer, outputPhase } from "../../../util/helpers";
import { loadUserRole, roles, getUserFullName } from "../../../util/userHelper";
import FilterTableForm from "../../Forms/Input/FilterTableForm";
import AssignDropdown from "../shared/AssignDropdown";

let Columns = [
  {
    Header: "Business Name",
    accessor: "applicationNameLink", // accessor is the "key" in the data
    defaultCanSort: true,
    sortType: "basic",
    filterCode: "legalName",
  },
  {
    Header: "Home Region",
    accessor: "regionLocatedIn",
    defaultCanSort: true,
    sortType: "basic",
  },
  {
    Header: "Status",
    accessor: "status",
    defaultCanSort: true,
    sortType: "basic",
    filterCode: "status",
  },
  {
    Header: "Phase",
    accessor: "phase",
    defaultCanSort: true,
    sortType: "basic",
    filterCode: "phase",
  },
  {
    Header: "Days Left",
    accessor: "businessDaysLeft",
    defaultCanSort: true,
    sortType: "basic",
    filterCode: "businessDaysLeft",
  },
  // {
  //   Header: 'Last Action',
  //   accessor: 'lastAction',
  //   defaultCanSort: true,
  //   sortType: 'basic',
  //   filterCode: "lastAction"
  // }
];

if (loadUserRole() === roles.DivisionAdmin) {
  Columns.splice(3, 0, {
    Header: "Assigned",
    accessor: "assigned",
    defaultCanSort: true,
    sortType: "basic",
    filterCode: "assignedUser",
  });
}

const ApplicationList = ({ userInfo }) => {
  const [applications, setApplications] = useState([]);
  const [divisionUsers, setDivisionUsers] = useState([]);
  const [divisionUser, setDivisionUser] = useState({});
  const [updateCount, setUpdateCount] = useState(0);

  const makeDivisionUserOption = (user) => {
    return {
      ...user,
      label: `${user.userId} - ${user.firstName} ${user.lastName}`,
      filterLabel: getUserFullName(user),
    };
  };

  useEffect(() => {
    const loadDivisionUsers = async () => await getDivisionUsers();
    const loadApplications = async () => await getApplications();

    loadDivisionUsers().then(({ users }) => {
      const userOptions = users.map((user) => makeDivisionUserOption(user)).sort((x, y) => x.label.localeCompare(y.label));
      setDivisionUsers([{ label: "Unassigned", filterLabel: "Unassigned", id: "" }, ...userOptions]);
    });

    loadApplications().then(({ applications }) => setApplications(applications));
  }, [updateCount]);

  const getStatusName = (application) => {
    const { phase, status } = application;
    if (["InDepthReview"].includes(phase) && ["Deficient"].includes(status)) {
      return "Additional Info";
    } else {
      return status;
    }
  };

  const assignDropdownOnChange = async (e, applicationId) => {
    const divisionUserId = e.target.value;
    setDivisionUser({ applicationId, divisionUserId });
    await assignApplicationUser(applicationId, divisionUserId);
    setUpdateCount(updateCount + 1);
    setDivisionUser({});
  };

  const initialItems = applications?.map((application) => {
    const { reviewer, reviewerId, businessPreviouslyCertified } = application;
    const relTimer = getRelevantApplicationTimer(application) || {};
    const daysLeftColorized = daysLeftColorize(relTimer);
    const lastAction = application?.activityLogs && application?.activityLogs?.length ? last(application?.activityLogs)?.activity : "UNKNOWN";

    return {
      ...application,
      status: getStatusName(application),
      assignedUser: getUserFullName(reviewer),
      assigned:
        divisionUser.applicationId === application.id ? (
          <FontAwesomeIcon icon={"spinner"} className="fa-spin" />
        ) : (
          <AssignDropdown reportId={application.id} reviewerId={reviewerId} divisionUsers={divisionUsers} onChange={assignDropdownOnChange} />
        ),
      processingAssigned: `${divisionUser.applicationId} - ${divisionUser.applicationId === application.id}`,
      // businessDaysLeft: "60",
      phase: outputPhase(application.phase),
      timerType: relTimer?.type,
      businessDaysLeft: daysLeftColorized,
      lastAction: <span className="small">{lastAction}</span>,
      applicationNameLink: (
        <div className="d-flex flex-row">
          {businessPreviouslyCertified ? <FontAwesomeIcon icon={["fa", "award"]} size={"lg"} className="mr-2 mt-1 text-secondary" /> : null}
          <a href={`/application/${application.id}/review`}>
            {/* <span className={`status-indicator mr-3 bg-${daysLeftClass(relTimer)}`}></span> */}
            {application.legalName}
          </a>
        </div>
      ),
      // assigned: application?.users.filter(user => user?.role === "Division").length ? application?.users.filter(user => user?.role === "Division")[0].userId : 'Not Assigned',
    };
  });

  let FilterListConfig = {
    legalName: {},
    status: {},
    assignedUser: {
      type: "typeahead",
      options: divisionUsers?.map((user) => ({ id: user.filterLabel, label: user.filterLabel })),
    },
  };

  // if (loadUserRole() === roles.DivisionAdmin) {
  //   FilterListConfig = {
  //     ...FilterListConfig,
  //     assigned: {
  //       isJoin: false,
  //       type: "typeahead",
  //       options: divisionUsers,
  //     },
  //   };
  // }

  return (
    <section id="messages">
      <div>
        <h2>Applications Queue</h2>
        <p>Hi {userInfo?.user?.firstName},</p>
        <p>Please select the application you would like to work on today.</p>
        <div>
          {applications?.length > 0 ? (
            <FilterTableForm tableName="application-list" Columns={Columns} FilterListConfig={FilterListConfig} tableItems={initialItems} />
          ) : (
            <h2>You have no applications currently assigned to you</h2>
          )}
        </div>
      </div>
    </section>
  );
};

export default ApplicationList;

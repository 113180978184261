import { FastField } from "formik";
import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { NextIcon, PrevIcon } from "../../../util/helpers";
import { YES_NO_RADIO } from "../../../util/typelistHelper";
import { useTabValidStatus } from "../../Forms/FormikForm";
import ArrayWrapperDynamic from "../../Forms/helpers/ArrayWrapperDynamic";
import InputComponent from "../../Forms/Input/InputComponent";
import ProgressBar from "../components/ProgressBar";
import OwnerForm from "./sections/OwnerForm";
import SectionCompleteForm from "./sections/SectionCompleteForm";

const INITIAL_VALUES_OWNERS = {
  addresses: [
    { type: "Mailing", line1: '', city: '', state: '', zipCode: '' },
    { type: "Home", line1: '', city: '', state: '', zipCode: '' }
  ]
}


const ApplicantInformationFormInternal = ({ name, appBag, formikBag }) => {
  const { values, errors, validateForm } = formikBag;
  const { onTabChange, tabs, title, tabKey } = appBag;
  let tab = tabs.tabs[tabKey];
  useTabValidStatus(tabs.tabKeyActive, validateForm);

  return (
    <>
      <Carousel
        activeIndex={tab.activeSlide}
        wrap={false}
        prevIcon={PrevIcon}
        nextIcon={NextIcon}
        interval={null}
        fade={false}
        keyboard={false}
        slide={false}
        onSelect={(activeSlide) => onTabChange(tabKey, { activeSlide }, false)}
      >
        <Carousel.Item>
          <div className="form-tab-intro">
            <h2 id="applicant-ownership-section-heading" className="carousel-section-heading">Applicant's Ownership</h2>
          </div>
          <p>
            Complete the form below for each owner, partner, LLC member, LLC manager, shareholder, and/or officer of the business. Once all the information has been added for one individual, click the
            “Add Another Owner” button below the form to move on to the next owner. Once all owners have been entered, you can click “Next Page.”
          </p>

          <p>Important notes:</p>
          <ul>
            <li>When another business is an owner, enter the complete principal office address of the parent company in the home address box. </li>
            <li>If you are a corporation and do not have a vice-president, enter “no VP” in the title column. </li>
            <li>If an individual holds multiple titles, list all titles held.</li>
            <li>Check the service-disabled veteran box to identify each qualifying service-disabled veteran owner.</li>
          <li>Significant changes to an owner's information will require reattestation.</li>

          </ul>

          <p>Additional owners, besides the preparer, must be added via an emailed link. See "Add Another Owner" button below to add additional owners.</p>

          <p>
            <strong>SDVOB Applicants:</strong> Check the "Service-disabled?" box to identify each qualifying disabled veteran
          </p>
          <div className="field-group">
            <ArrayWrapperDynamic name={`owners`} additionLabel="Add Another Owner" removalLabel="Remove Owner" component={OwnerForm} formikBag={formikBag} atLeastOne={true} initialValues={INITIAL_VALUES_OWNERS} />
            {/* {getTotalOwnership(values) > 100 ?
              <p className="alert alert-danger">Combined ownership percentage can not surpass 100%. Your current value is {getTotalOwnership(values)}</p>
              : null} */}
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="form-tab-intro">
            <h2>Management and Control</h2>
            <p>Please answer the following questions as they apply to managerial control of your business.</p>
          </div>

          <div className="field-group ">
            <FastField
              wrapperClass=""
              label="Are the disabled veteran owners or managers responsible for the negotiation, execution, and signature of contracts?"
              type="radio"
              name={`isOwnersResponsibleForSigningContracts`}
              options={YES_NO_RADIO}
              placeholder=""
              component={InputComponent}
              required={true}
            />

            <FastField
              wrapperClass=""
              label="Are the disabled veteran owners or managers responsible for the execution of financial transactions and agreements (credit, banking, and bonding) operations?"
              type="radio"
              name={`isOwnersResponsibleForFinancialContractExecution`}
              options={YES_NO_RADIO}
              placeholder=""
              component={InputComponent}
              required={true}
            />

            <FastField
              wrapperClass=""
              label="Are there any formal or informal restrictions limiting the voting power or control of the disabled veteran owners and/or disabled veteran managers?"
              type="radio"
              name={`isOwnersVotingPowersLimited`}
              options={YES_NO_RADIO}
              placeholder=""
              component={InputComponent}
              required={true}
            />

            <FastField
              wrapperClass=""
              label="Are there any third-party agreements restricting the control of the disabled veteran owners and/or disabled veteran managers?"
              type="radio"
              name={`isOwnersControlRestrictedByThirdParty`}
              options={YES_NO_RADIO}
              placeholder=""
              component={InputComponent}
              required={true}
            />

            <FastField
              wrapperClass=""
              label="Do the disabled veteran owners or disabled-veteran managers possess the requisite experience, education, knowledge, and qualifications in the applicant firm’s field of operations?"
              type="radio"
              name={`isOwnersFieldQualified`}
              options={YES_NO_RADIO}
              placeholder=""
              component={InputComponent}
              required={true}
            />

            <FastField
              wrapperClass=""
              label="Are the salary/profits of the disabled veteran owners and disabled veteran managers commensurate (proportionate) with their ownership interest?"
              type="radio"
              name={`isOwnersCompensationCommensurateWithStake`}
              options={YES_NO_RADIO}
              placeholder=""
              component={InputComponent}
              required={true}
            />


            <FastField
              wrapperClass=""
              label="Do the disabled veteran owners or disabled veteran managers have direct responsibility for subcontractors, if any?"
              type="radio"
              name={`isOwnersResponsibleForSubcontractors`}
              options={YES_NO_RADIO}
              placeholder=""
              component={InputComponent}
              required={true}
            />

            {values?.ownershipType === "Corporation" ?
              <>
                <div className="form-tab-intro mt-4">
                  <h2>Management and Control for Corporations</h2>
                  <p>Please answer the following questions as they apply to managerial control for your corporation. </p>
                </div>
                <FastField
                  wrapperClass=""
                  label="Do the disabled veteran owners or disabled veteran managers have direct responsibility for the applicant firm’s equipment?"
                  type="radio"
                  name={`isOwnersResponsibleForEquipment`}
                  options={YES_NO_RADIO}
                  placeholder=""
                  component={InputComponent}
                  required={true}
                />

                <FastField
                  wrapperClass=""
                  label="Do the disabled veteran owners or disabled veteran managers have direct responsibility for the applicant firm’s materials?"
                  type="radio"
                  name={`isOwnersResponsibleForMaterials`}
                  options={YES_NO_RADIO}
                  placeholder=""
                  component={InputComponent}
                  required={true}
                />

                <FastField
                  wrapperClass=""
                  label="Do the disabled veteran owners or disabled veteran managers have direct responsibility for the applicant firm’s facilities (office/yard)?"
                  type="radio"
                  name={`isOwnersResponsibleForFacilities`}
                  options={YES_NO_RADIO}
                  placeholder=""
                  component={InputComponent}
                  required={true}
                />

                <FastField
                  wrapperClass=""
                  label="Do the disabled veteran owners receive at least 51% of any dividends paid by the firm, including distribution upon liquidation?"
                  type="radio"
                  name={`isOwnersReceiveDividendsMajority`}
                  options={YES_NO_RADIO}
                  placeholder=""
                  component={InputComponent}
                  required={true}
                />

                <FastField
                  wrapperClass=""
                  label="Do the disabled veteran owners have the ability to appoint or elect and remove the majority of the board of directors?"
                  type="radio"
                  name={`isOwnersControlBoardMajority`}
                  options={YES_NO_RADIO}
                  placeholder=""
                  component={InputComponent}
                  required={true}
                />

                <FastField
                  wrapperClass=""
                  label="Are the disabled veteran owners entitled to 100% of the value of each share of stock they hold?"
                  type="radio"
                  name={`isOwnersEntitledFullStockShareValue`}
                  options={YES_NO_RADIO}
                  placeholder=""
                  component={InputComponent}
                  required={true}
                />
              </>
              : ``}
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <SectionCompleteForm errors={errors} onTabChange={onTabChange} tabs={tabs} tabKey={tabKey} />
        </Carousel.Item>
      </Carousel>
      <ProgressBar tab={tab} title={title} slideCount={3} />
    </>
  );
};

// const ApplicantInformationForm = withFormikComponent(ApplicantInformationInternal, schemas.applicantInformationSchema);
export default ApplicantInformationFormInternal;

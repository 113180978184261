import React from "react";
import "./ReportingOptions.scss";

const ReportingOptions = (props) => {
  const { userDefinedReports } = props;

  function loadExistingReport(event) {
    let report = undefined;
    const reportId = event.target.value;

    for (let i = 0; i < userDefinedReports.length; ++i) {
      if (userDefinedReports[i].id === reportId) {
        report = userDefinedReports[i];
        break;
      }
    }

    if (report) {
      props.loadExistingReport(report);
    }
  }

  return <>
    <div className="reporting-options">
      <div className="report-type-select-container">
        <label htmlFor="report-types-select">
          Report Type
        </label>
        <select
          id="report-types-select"
          value={props.selectedReportType.reportType}
          onChange={props.onChangeReportType}
        >
          {
            props.reportTypes.map((reportType) => {
              const id = `${reportType.name}-report`;

              return <option 
                key={id}
                value={reportType.reportType}
              >
                {reportType.name}
              </option>;
            })
          }
        </select>
      </div>
      <div className="report-type-select-container">
        <label htmlFor="existing-report-select">
          User Defined Reports
        </label>
        <select
          id="existing-report-select"
          value={props.existingReportId || ''}
          onChange={loadExistingReport}
        >
          {
            userDefinedReports.map((userDefinedReport) => {
              return <option
                key={userDefinedReport.id}
                value={userDefinedReport.id}
              >
                {userDefinedReport.name}
              </option>
            })
          }
        </select>
      </div>
    </div>
  </>
};

export default ReportingOptions;
import React from "react";
import { createNavLinks } from "../../util/helpers";
import { loadUserToken } from "../../util/userHelper";
import { Navbar } from "react-bootstrap";

const FallbackNav = (props) => {
  const items = [
    // ["", <div className="nav-header py-4 px-3">Need Help?</div>, "bg-secondary"],
    // [
    //   "",
    //   <div className="p-3">
    //     <small className="text-break">
    //       If you need assistance with your application, <strong>call</strong> <span className="text-nowrap">(518) 474-2015</span> or <strong>email</strong> veteransdevelopment@ogs.ny.gov.
    //     </small>
    //   </div>,
    //   "bg-light",
    //   false,
    // ],
    ["/business-search", "SDVOB Search"],
  ];

  return (
    <Navbar collapseOnSelect expand="md" className="p-0 d-block">
      <div className="nav-header bg-black p-4 d-flex justify-content-between align-items-center">
        Navigation
        <Navbar.Toggle aria-controls="sidebar-nav" />
      </div>
      <Navbar.Collapse id="sidebar-nav">{createNavLinks(items)}</Navbar.Collapse>
    </Navbar>
  );
};

export default FallbackNav;

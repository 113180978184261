import React from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { FastField } from "formik";
import InputComponent from "../../../Forms/Input/InputComponent";
import states from "../../../../util/sourceData/states";
import { Button } from "react-bootstrap";
import { get, omit } from "lodash"

const AddressForm = (props) => {
  const { name, type, setFieldValue, addressFromName, values, copyButtonType } = props;
  const copyAddressFromName = () => {
    const fromAddress = omit(get(values, addressFromName), ['id', 'type']);
    const toAddress = get(values, name);
    const newAddress = { ...toAddress, ...fromAddress };
    setFieldValue(name, newAddress);
  }

  const setCountyByState = (event) => {
    const { target } = event;
    const { name, value } = target;
    console.log(type, target.name, target.value);

    if (type === "Physical" && !name.includes('owner') && value !== "NY") {
      setFieldValue('countyLocatedIn', 'Out-Of-State');
    }
    setFieldValue(name, value);
  }

  const labels = {
    Physical: {
      addressLabel: "Physical Address / Principal Place of Business",
      copyButtonLabel: "Physical Address"
    },
    Mailing: {
      addressLabel: "Mailing Address",
      copyButtonLabel: "Mailing Address"
    },
    Home: {
      addressLabel: "Home Address",
      copyButtonLabel: "Home Address"
    },
  };

  return (
    <div>
      <div className="field-group row address-group">
        {addressFromName &&
          <div className="col-12"><Button id={`${name}-CopyButton`} onClick={copyAddressFromName} className="btn-sm">Copy From {labels[copyButtonType]?.copyButtonLabel}</Button></div>
        }
        <FastField wrapperClass="col-12" label={labels[type] ? labels[type].addressLabel : `${type} Address Line1`} type="text" name={`${name}.line1`} placeholder="" component={InputComponent} />
        <FastField wrapperClass="col-12" label="Address Line 2" type="text" name={`${name}.line2`} placeholder="" component={InputComponent} required={false} />
        <FastField wrapperClass="col-md-6" label="City" type="text" name={`${name}.city`} component={InputComponent} />
        <FastField wrapperClass="col-md-2" label="State" type="select" name={`${name}.state`} options={states} component={InputComponent}
          onChange={setCountyByState}

        />
        <FastField wrapperClass="col-md-4" label="ZIP Code" type="inputZip" name={`${name}.zipCode`} placeholder="" component={InputComponent} />
      </div>
    </div>
  );
};

export default AddressForm;

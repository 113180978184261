import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { NextIcon, PrevIcon } from "../../../util/helpers";
import { useTabValidStatus } from "../../Forms/FormikForm";
import ProgressBar from "../components/ProgressBar";
import ApplicationReviewComponent from "./review/ApplicationReviewComponent";
import ReviewSubmitButton from "./review/ReviewSubmitButton";

const ReviewSubmitFormInternal = ({ name, appBag, formikBag }) => {
  const { values, errors, isValid, validateForm, initialValues } = formikBag;
  const { onTabChange, tabs, title, tabKey } = appBag;
  let tab = tabs.tabs[tabKey];
  useTabValidStatus(tabs.tabKeyActive, validateForm);

  return (
    <>
      <Carousel
        activeIndex={tab.activeSlide}
        wrap={false}
        prevIcon={PrevIcon}
        nextIcon={NextIcon}
        interval={null}
        fade={false}
        keyboard={false}
        slide={false}
        onSelect={(activeSlide) => onTabChange(tabKey, { activeSlide }, false)}
      >
        <Carousel.Item>
          <div className="form-tab-intro">
            <h5>Certifications &amp; Review</h5>
            <h2>Review Application</h2>

            {!errors ? (
              <p className="text-primary">
                Incomplete application: It appears that your application is missing required information or documentation. Please address the sections in red below, then submit the completed
                application.{" "}
              </p>
            ) : (
              ""
            )}

            <p>Before you submit your application, please read to ensure:</p>

            <ul>
              <li>All fields in this application are complete and accurate</li>
              <li>All business owners have provided their signatures</li>
              <li>All supporting documents are uploaded</li>
            </ul>

            <p>
              All applications are subject to verification of status at any time. Failure by a business to provide requested information that supports its eligibility by the date and time specified
              may be grounds for denial or declaration.
            </p>
          </div>
          <ApplicationReviewComponent {...values}></ApplicationReviewComponent>
          <div>
            <ReviewSubmitButton formikBag={formikBag} appBag={appBag} />
          </div>
        </Carousel.Item>
      </Carousel>
      <ProgressBar tab={tab} title={title} slideCount={1} />
    </>
  );
};

// const ReviewSubmitForm = withFormikComponent(ReviewSubmitFormInternal, schema);
export default ReviewSubmitFormInternal;

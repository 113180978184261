import React, { useEffect, useState } from "react";
import { Field } from "formik";
import { withFormikFormComponent } from "../../Forms/FormikForm";
import FilterTableField from "../../Forms/Input/FilterTableField";
import { getApplications, handleApplicationTimerStatus } from "../../../util/api";
import { daysLeftColorize, getRelevantApplicationTimer, outputTimer } from "../../../util/helpers";
import _ from "lodash";
import DebugModal from "../../Debug/DebugModal";
import FilterTableForm from "../../Forms/Input/FilterTableForm";
import { string } from "yup";

function CertificationTimerListInternal(props) {
  const { formikBag } = props;
  const { setFieldValue, values } = formikBag;

  //Get the application and business information
  const [applications, setApplications] = useState([]);
  const [applicationsUpdated, setApplicationsUpdated] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const myApplications = await getApplications();
      setApplications(myApplications?.applications);
    }
    fetchData();
  }, [applicationsUpdated]);

  // only use the applications with timers
  const initialItems = applications.filter((application) => application.timers.length);

  const FilterListConfig = {
    legalName: {
      isJoin: false,
    },
    status: {
      isJoin: false,
    },
    daysLeft: {
      isJoin: false,
    },
    timerRunning: {
      isJoin: false,
    },
    endDate: {
      isJoin: false,
    },
  };

  const Columns = [
    {
      Header: "Business",
      accessor: "legalName", // accessor is the 'key' in the data
      // disableSortBy: true,
      sortType: "basic",
      filterCode: "legalName",
    },
    {
      Header: "Status",
      accessor: "status",
      // defaultCanSort: true,
      sortType: "basic",
      filterCode: "status",
    },
    {
      // Header: 'Business Days Left',
      Header: "Business Days Left",
      accessor: "businessDaysLeft", // accessor is the 'key' in the data
      // disableSortBy: true,
      sortType: "basic",
      filterCode: "businessDaysLeft",
    },
    {
      Header: "Timer",
      accessor: "timerRunning", // accessor is the 'key' in the data
      // disableSortBy: true,
      sortType: "basic",
      filterCode: "timerRunning",
    },
    // {
    //   Header: 'Due Date',
    //   accessor: 'endDate', // accessor is the 'key' in the data
    //   // disableSortBy: true,
    //   sortType: 'basic',
    //   filterCode: 'endDate'
    // },
  ];

  const modifiedTimers = initialItems?.map((item) => {
    const relTimer = getRelevantApplicationTimer(item);
    const daysLeft = relTimer?.minutesLeft;
    const daysLeftColorized = daysLeftColorize(relTimer);
    const stopped = _.get(values, `timer-${item.id}`) === undefined ? relTimer?.isStopped : _.get(values, `timer-${item.id}`);
    const name = `timer-${item.id}`;

    return {
      ...item,
      legalName: item.legalName,
      endDate: new Date(relTimer?.endDate).toDateString(),
      businessDaysLeft: daysLeftColorized,
      daysLeft: string(item.daysLeft),
      timerRunning: (
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            className="custom-control-input"
            checked={!stopped}
            id={name}
            name={name}
            onChange={async (e) => {
              console.log(_.get(values, `timer-${item.id}`), values);
              if (stopped) {
                const resObj = await handleApplicationTimerStatus(relTimer.id, "start");
                if (resObj.status === 200) {
                  console.log("we started the timer");
                  setFieldValue(`timer-${item.id}`, false);
                }
              } else {
                const resObj = await handleApplicationTimerStatus(relTimer.id, "stop");
                if (resObj.status === 200) {
                  console.log("we stopped the timer");
                  setFieldValue(`timer-${item.id}`, true);
                }
              }
              setApplicationsUpdated(applicationsUpdated + 1);
            }}
          />
          <label className="custom-control-label" htmlFor={`timer-${item.id}`}>
            <span className="sr-only">In-Depth Review Complete</span>
          </label>
        </div>
      ),
    };
  });

  return (
    <>
      <h2>Application Timers</h2>
      <p>Review statuses of timers associated with SDVOB applications.</p>

      <div>
        {modifiedTimers?.length ? (
          <FilterTableForm tableName="certification-timer-list" Columns={Columns} FilterListConfig={FilterListConfig} tableItems={modifiedTimers} />
        ) : (
          <div className="p-5 bg-light mt-4">
            <h2>No certification timers to display, currently.</h2>
          </div>
        )}
      </div>
    </>
  );
}
const CertificationTimerList = withFormikFormComponent(CertificationTimerListInternal);
export default CertificationTimerList;

import React from "react";
import { useHistory, matchPath, useLocation } from "react-router";
import { createNavLinks } from "../../util/helpers";
import { loadActiveOrgId, getActiveOrg } from "../../util/userHelper";
import { hasActiveCertification } from "../../util/helpers";
import Navbar from "react-bootstrap/Navbar";

const BusinessNav = (props) => {
  const { activeOrgId, userInfo } = props;
  // const hasBusinesses = props?.userInfo?.user?.businesses?.length;
  const location = useLocation().pathname.replace(/["']/g, "");
  const history = useHistory();
  const activeOrg = getActiveOrg(userInfo, activeOrgId);
  const onApp = matchPath(location, {
    path: "/application/:applicationId",
    exact: true,
    strict: false,
  });
  const isCertified = activeOrg && hasActiveCertification(activeOrg);

  const items = [
    activeOrg ? [`/business/${activeOrgId}`, "Business Info"] : null,
    activeOrg ? ["/", "SDVOB Certification"] : null,
    activeOrg ? ["/procurements-list", "NYS Awards"] : null,
    activeOrg ? ["/utilization-plans", "Utilization Plans"] : null,
    isCertified ? ["/plan-presence", "Utilization Plan Participation"] : null,
    // activeOrg ? ["/compliance-reports", "Reports"] : null,
    activeOrg ? [`/business/${loadActiveOrgId()}/correspondence`, "Division Correspondence"] : null,
    [`/business-search`, "SDVOB Search"],
    // ["/messages", <span>Messages <span className="h4"><span className="badge rounded-pill bg-secondary lg h3">${messageCount}</span></span></span>],
    // onApp || !activeOrg ? ["", <div className="nav-header py-4 px-3">Need Help?</div>, "bg-secondary"] : null,
    // onApp || !activeOrg
    //   ? [
    //       "",
    //       <div className="p-3">
    //         <small className="text-break">
    //           If you need assistance with your application, <strong>call</strong> <span className="text-nowrap">(518) 474-2015</span> or <strong>email</strong> veteransdevelopment@ogs.ny.gov.
    //         </small>
    //       </div>,
    //       "bg-light",
    //       false,
    //     ]
    //   : null,
  ];

  return (
    <Navbar collapseOnSelect expand="md" className="p-0 d-block">
      <div className="nav-header bg-black p-4 d-flex justify-content-between align-items-center">
        Portal Navigation <Navbar.Toggle aria-controls="sidebar-nav" />
      </div>
      <Navbar.Collapse id="sidebar-nav" className="">
        {createNavLinks(items, history)}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default BusinessNav;

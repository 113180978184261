import React from "react";
import ReviewSubmitFormLine from "./ReviewSubmitFormLine";
const ReviewSubmitFormAddressLines = (props) => {
  const {addresses} = props;
  return (
    <>
      {addresses?.map((address, index) => {
        return (
          <div key={index}>
            <ReviewSubmitFormLine name={`${address.type} Address`} value={address.line1}/>
            {address.line2 && <ReviewSubmitFormLine name={`Address Line 2`} value={address.line2}/>}
            <ReviewSubmitFormLine name={`City`} value={address.city}/>
            <ReviewSubmitFormLine name={`State`} value={address.state}/>
            <ReviewSubmitFormLine name={`Zip`} value={address.zipCode}/><br/>
          </div>
        )
      })}
    </>
  );
};
export default ReviewSubmitFormAddressLines;

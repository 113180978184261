import React, { useEffect, useState } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { getCentralizedContract } from "../../../util/api";
import FilterTableForm from "../../Forms/Input/FilterTableForm";
import CentralizedContractSubmitForm from "./CentralizedContractSubmitForm";
import CentralizedContractList from "./CentralizedContractList";

const CentralizedContracts = () => {
  const { centralizedContractId } = useParams();
  const [fetchedCentralizedContract, setCentralizedContract] = useState(null);
  const [updateValues, setUpdateValues] = useState(null);
  const [showForm, setShowForm] = useState(centralizedContractId ? true : false);
  const [updateCount, setUpdateCount] = useState(0);

  const initialValues = {
    centralizedContractId: centralizedContractId,
    name: fetchedCentralizedContract ? fetchedCentralizedContract.name : "",
    contractNumber: fetchedCentralizedContract ? fetchedCentralizedContract.contractNumber : "",
    active: fetchedCentralizedContract ? fetchedCentralizedContract.isActive : null,
  };

  useEffect(() => {
    async function fetchData() {
      const fetchedCentralizedContract = await getCentralizedContract(centralizedContractId);
      setCentralizedContract(fetchedCentralizedContract.data);
    }
    if (centralizedContractId) {
      fetchData();
    }
  }, [centralizedContractId, updateValues, updateCount]);

  return (
    <>
      {showForm ? (
        <>
          <CentralizedContractSubmitForm
            routeInitialValues={initialValues}
            updateCount={updateCount}
            setUpdateCount={setUpdateCount}
            updateValues={updateValues}
            setUpdateValues={setUpdateValues}
            validateOnChange={true}
            validateOnMount={true}
          />
          <hr />
        </>
      ) : (
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => {
            setShowForm(true);
          }}
        >
          Create Centralized Contract
        </button>
      )}
      {!centralizedContractId ? <CentralizedContractList updateCount={updateCount} setUpdateCount={setUpdateCount} updateValues={updateValues} setUpdateValues={setUpdateValues} /> : null}
    </>
  );
};

export default CentralizedContracts;

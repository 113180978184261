import React, { useEffect } from "react";
import { FastField, FieldArray } from "formik";
import { getPercentage, procurementCanHavePayments } from "../../../../util/helpers";
import InputComponent from "../../../Forms/Input/InputComponent";
import _ from "lodash";
import { formatAddress } from "../../../../util/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BusinessProcurementsList from "../../Views/BusinessProcurementsList";

const UtilizationPlanSubcontractor = (props) => {
  const { formikBag, businessesOptions, businesses, inputsDisabled, procurement } = props;
  const { values } = formikBag;

  // disable selected subcontractor options
  useEffect(() => {
    const selectedIds = values?.subcontracts.map((biz) => biz.contractorId);

    if (values?.subcontracts.length) {
      return values?.subcontracts.forEach((v, i) => {
        const options = document.getElementById(`subcontracts.${i}.contractorId`);

        if (options) {
          Array.from(options).forEach((option) => (selectedIds.includes(option.value) ? (option.disabled = true) : (option.disabled = false)));
        }
      });
    }
  }, [values?.subcontracts]);

  return (
    <>
      <FieldArray
        name="subcontracts"
        render={(arrayHelpers) => (
          <>
            {values.subcontracts && values?.subcontracts?.length > 0
              ? values.subcontracts.map((subcontractor, index) => {
                  // do this for scenario where shape of hydrating object doesn't match shape of object passed into dropdown
                  const value = subcontractor.usePercent ? (values.value / 100) * subcontractor.percentage : getPercentage(subcontractor.value, values.value);
                  const business = businesses?.find((item) => item.id === subcontractor?.contractorId);

                  let outputArr = [];
                  if (business) {
                    const { legalName, address, phoneNumber, emailAddress } = business;
                    outputArr = [
                      {
                        name: "Contact",
                        value: legalName,
                        ref: "contact",
                      },
                      {
                        name: "Address",
                        value: address,
                        ref: "address",
                      },
                      {
                        name: "Phone",
                        value: phoneNumber,
                        ref: "phoneNumber",
                      },
                      {
                        name: "Email",
                        value: emailAddress,
                        ref: "emailAddress",
                      },
                    ];
                  }

                  return (
                    <div className={`subcontractor-${index}`} key={`subcontractor-${index}`}>
                      <div className="field-group row">
                        {businessesOptions.length > 1 ? (
                          <FastField
                            wrapperClass="col-6"
                            name={`subcontracts.${index}.contractorId`}
                            component={InputComponent}
                            label={"Select Contractor"}
                            options={businessesOptions}
                            // options={businessesOptions.filter(option => !(!!values.subcontracts.find(subcontractor => subcontractor.contractorId === option[0])))}
                            type="select"
                            isdisabled={inputsDisabled}
                          />
                        ) : null}

                        {_.isEmpty(subcontractor) ? (
                          <button
                            type="button"
                            className="faux-link remove-dba-link text-primary"
                            onClick={() => {
                              arrayHelpers.remove(index);
                            }}
                          >
                            <FontAwesomeIcon className="" icon={["fas", "minus"]} size="xs" /> {`Remove contractor.`}
                          </button>
                        ) : null}
                      </div>

                      {business ? (
                        <div className="business-details mb-4 mx-4">
                          {outputArr.length
                            ? outputArr.map((item, index) => {
                                let val = "";
                                switch (item.ref) {
                                  case "address":
                                    val = item?.value ? formatAddress(item.value) : "Not Provided";
                                    break;
                                  case "tags":
                                    val = <span>{item.value.join(", ")}</span>;
                                    break;
                                  default:
                                    val = item?.value;
                                    break;
                                }
                                return (
                                  <div className="row" key={`business-detail-${index}`}>
                                    <div className="col-4 col-sm-3 col-md-2">
                                      <span className="font-weight-bold">{item.name}</span>
                                    </div>
                                    <div className="col-8 col-sm-9 col-md-10">{val}</div>
                                  </div>
                                );
                              })
                            : null}
                        </div>
                      ) : null}

                      {business ? (
                        <div className="mx-md-4">
                          <div className="field-group">
                            <FastField
                              wrapperClass=""
                              type="textarea"
                              name={`subcontracts.${index}.description`}
                              component={InputComponent}
                              label={"Description of Work or Supplies Provided"}
                              isdisabled={inputsDisabled}
                            />

                            <p>Enter the dollar value of subcontracts / supplies / services. When dollar value cannot be estimated, provide the estimated % of contract work the SDVOB will perform.</p>

                            <div className="row">
                              {values?.term === "Standard" ? (
                                <>
                                  <div className="col-6">
                                    <FastField wrapperClass="" type="number" name={`subcontracts.${index}.value`} component={InputComponent} label={"Dollar Value"} isdisabled={inputsDisabled} />
                                  </div>
                                  <div className="col-6">
                                    <>
                                      <p>Subcontractor Percentage</p>
                                      <p>{value ? `${value}%` : "0%"}</p>
                                    </>
                                    {/* } */}
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="col-6">
                                    <FastField wrapperClass="" type="number" name={`subcontracts.${index}.goal`} component={InputComponent} label={"Goal %"} isdisabled={inputsDisabled} />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <>
                            <button
                              type="button"
                              name={`subcontracts.${index}.remove`}
                              className="faux-link remove-dba-link text-primary"
                              onClick={() => {
                                console.log(index);
                                arrayHelpers.remove(index);
                              }}
                            >
                              <FontAwesomeIcon className="" icon={["fas", "minus"]} size="xs" /> {`Remove ${business.legalName}`}
                            </button>
                            <hr />
                          </>
                        </div>
                      ) : null}
                    </div>
                  );
                })
              : null}
            <button
              type="button"
              className="btn btn-light btn-sm"
              onClick={(index) => {
                // addSubcontractor(index);
                arrayHelpers.push({});
              }}
            >
              {/* show this when user has removed all items from the list */}
              <FontAwesomeIcon className="mr-2" icon={"plus"} size="lg" /> Add a subcontractor
            </button>
          </>
        )}
      />
    </>
  );
};

export default UtilizationPlanSubcontractor;

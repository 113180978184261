import React, { useEffect, useState } from "react";
import { useParams } from 'react-router';
import ActivityList from "./ActivityList";
import ActivityItemForm from "./ActivityItemForm";
import { getApplication, handleActivityLogSubmit, getActivityLogs } from "../../../util/api"
// import ApplicationNav from "../../components/DivisionPortal/ApplicationNav";

const ApplicationActivityLogs = ({ activityLogs, application, changeCount, setChangeCount }) => {
  const { applicationId } = useParams();
  // const [application, setApplication] = useState({});
  // const [businessId, setBusinessId] = useState("");
  // const [initialItems, setInitialItems] = useState(activityLogs);
  // const [submissionCount, setSubmissionCount] = useState(false);

  const handleLogSumitWithRefresh = (applicationId, activity, created) => {
    handleActivityLogSubmit("application", applicationId, activity, created);
    // setSubmissionCount(submissionCount + 1);
    setChangeCount(changeCount + 1);
  }

  // useEffect(() => {
  //   async function fetchData() {
  //     // const myApplication = await getApplication(applicationId);
  //     // setApplication(myApplication);
  //     // setBusinessId(myApplication?.businessId);

  //     // const myInitialItems = application ? await getActivityLogs('application', applicationId) : [];
  //     // setInitialItems(myInitialItems?.activityLogs);
  //   }
  //   fetchData();
  // }, [submissionCount]);


  return (
    <>
      <h2>Activity Log for {application?.legalName}</h2>

      <ActivityItemForm handleActivityLogSubmit={handleLogSumitWithRefresh} type="application" application={application} applicationId={applicationId} routeInitialValues={{ type: "Phone call made", created: "", notes: "" }} />

      <ActivityList activityLogs={activityLogs} />

    </>
  )
}

export default ApplicationActivityLogs;

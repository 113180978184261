import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { isErrorsInTab } from "../../util/errorHelper";
import { TabComponents } from "../../util/tabHelper";
import "../Forms/Form.scss";

const BusinessApplicationTabs = ({ formikBag, appBag }) => {
  const { isValid, errors } = formikBag;

  const { tabs, onTabChange } = appBag;

  const getTabStatusClass = (tabKey) => {
    const tab = tabs.tabs[tabKey];
    const isValidValue = tabKey === "reviewSubmit" ? isValid : isErrorsInTab(errors, tabKey);
    if (!tab.isTouched) {
      return "status-tab untouched";
    } else if (isValidValue) {
      return "status-tab complete";
    } else if (!isValidValue) {
      return "status-tab incomplete";
    }
  };

  return (
    <div>
      {tabs?.tabKeyActive && (
        <Tabs className="align-items-stretch" activeKey={tabs?.tabKeyActive} onSelect={(activeTab) => onTabChange(activeTab, { isTouched: true }, true)} transition={false} id="application-form-tabs">
          {Object.keys(tabs.tabs).map((tabKey) => {
            const { SectionComponent, title, titleExtra } = TabComponents[tabKey];

            let icon = "";

            switch (getTabStatusClass(tabKey)) {
              case "status-tab complete":
                icon = "check";
                break;
              case "status-tab incomplete":
                icon = "exclamation-circle";
                break;
              default:
                icon = "";
                break;
            }

            return (
              <Tab
                eventKey={tabKey}
                key={tabKey}
                title={
                  <>
                    {icon ? <FontAwesomeIcon className="text-white" icon={["fas", icon]} size="xs" /> : null}
                    <span>{title}</span> <span className="d-none d-lg-inline-block">{titleExtra}</span>
                  </>
                }
                tabClassName={getTabStatusClass(tabKey)}
              >
                <SectionComponent name={`application`} appBag={{ ...appBag, title, tabKey }} formikBag={formikBag} />
              </Tab>
            );
          })}
        </Tabs>
      )}
    </div>
  );
};
export default BusinessApplicationTabs;

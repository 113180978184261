import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, FieldArray } from "formik";
import React from "react";
import { handleDocumentClick } from "../../util/helpers";
import InputComponent from "./Input/InputComponent";
import { EMPTY_GUID } from "../../util/typelistHelper";
import startCase from "lodash/startCase";

function DocumentUpload(props) {
  const { name, arrayName, documents } = props;
  return (
    <div>
      <FieldArray
        id={`${name}.${arrayName}`}
        name={`${name}.${arrayName}`}
        render={(arrayHelpers) => {
          return (
            <div className="row">
              <Field
                name={`${name}.temp_field`}
                wrapperClass="col-12"
                label={`Upload an attachment`}
                type="fileonly"
                component={InputComponent}
                onFileUpload={(document) => {
                  console.log(document);
                  arrayHelpers.push({ ...document });
                }}
              />
              {documents?.map((document, index) => {
                return (
                  <div key={index}>
                    <div className="faux-document">
                      <FontAwesomeIcon className="mr-3 text-primary" icon={["fas", "times"]} onClick={() => arrayHelpers.remove(index)} data-target={`${arrayName}`} />
                      <DocumentLink document={document}></DocumentLink>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        }}
      />
    </div>
  );
}

const DocumentLink = ({ document }) =>
  document.documentId === EMPTY_GUID ? (
    <div className="row my-3">
      <div className="col-5">{startCase(document.type)}: </div>
      <div className="col-7">Reason for exclusion: {document.description}</div>
    </div>
  ) : (
    <div className="faux-document row my-3">
      <div className="col-5">{document.type === "Miscellaneous" ? "Document" : startCase(document.type)}: </div>
      <div className="col-7">
        <span className="faux-link inline btn" onClick={() => handleDocumentClick(document?.documentId)}>
          <FontAwesomeIcon className="" icon={["fas", "paperclip"]} /> {document?.description}
        </span>
      </div>
    </div>
  );

const DocumentsSection = (props) => {
  const { documents } = props;
  return (
    <div>
      {documents?.map((document, i) => (
        <DocumentLink key={i} document={document}></DocumentLink>
      ))}
    </div>
  );
};

export { DocumentUpload, DocumentLink, DocumentsSection };

import React from "react";
import { useParams } from "react-router-dom";
import { createTabNavLinks } from "../../util/helpers";

const ProcurementNav = (props) => {
  let items = [
    [`/procurements-list/open/`, "Open Procurements", "", "", true],
    [`/procurements-list/completed`, "Completed Procurements", "", "", false],
  ];

  return (
    <div className="procurements-list-navigation mb-5">
      <nav id="procurements-list-nav" className="nav nav-tabs no-bord">
        {createTabNavLinks(items)}
      </nav>
    </div>
  );
};

export default ProcurementNav;

import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import CorrespondenceForm from "./CorrespondenceForm";

const PartiesList = (props) => {
  const { correspondence } = props;
  const { participants } = correspondence;
  const [showParticipantsModal, setShowParticipantsModal] = useState(false);

  return (
    <>
      <p className="font-weight-bold">You are corresponding with</p>
      <div className="row">
        {/* {console.log(participants)} */}
        {participants?.length > 2 ? (
          <div className="col-md-8">
            <div className="row">
              {participants?.reduce(
                (accumulator, participant, index) => (
                  <>
                    {index < 3 ? (
                      <div className="col-md-4">
                        <div className="d-flex flex-row">
                          <div className="user-initial initial-small bg-secondary p-2 circle d-flex align-items-center justify-content-center mr-3">
                            <span className="small mb-0">{participant?.name.charAt(0)}</span>
                          </div>
                          <div>
                            <p>
                              {participant.name}
                              <br />
                              <span className="text-gray small">{participant.type ? `${participant.type}` : "Agency"}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {accumulator}
                  </>
                ),
                <></>
              )}
            </div>
          </div>
        ) : (
          <div className="col-12">
            <div className="d-flex flex-row justify-content-between">
              {participants?.reduce(
                (accumulator, participant) => (
                  <div className="col-md-4">
                    <div className="d-flex flex-row">
                      <div className="user-initial initial-small bg-secondary p-2 circle d-flex align-items-center justify-content-center mr-3">
                        <span className="small mb-0">{participant?.name.charAt(0)}</span>
                      </div>
                      <div>
                        <p>
                          {participant.name}
                          <br />
                          <span className="text-gray small">{participant.type ? `${participant.type}` : "Agency"}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                ),
                ""
              )}
            </div>
          </div>
        )}
        {participants && participants.length > 3 ? (
          <div className="col-md-4">
            <div className="faux-link text-secondary d-flex flex-row small" onClick={() => setShowParticipantsModal(true)}>
              <div className="user-initial initial-small bg-secondary p-2 circle d-flex flex-row align-items-center justify-content-center mr-3">
                <span className="small mb-0">+{participants.length - 3}</span>
              </div>
              See all correspondence
              <br className="d-none d-md-block" />
              participants
            </div>
          </div>
        ) : null}
      </div>
      <Modal show={showParticipantsModal} onHide={() => setShowParticipantsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Corresponence Participants</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {correspondence?.participants?.map((participant, i) => {
            return <div key={i}>{participant.name}</div>;
          })}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PartiesList;

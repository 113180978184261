import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { isUserAnOwner, isUserAPreparer, ownerHasAttested } from "../../../../util/helpers";
import { getUserId } from "../../../../util/userHelper";
import ArrayWrapperDynamic from "../../../Forms/helpers/ArrayWrapperDynamic";
import DocumentWrapper from "../../../Forms/helpers/DocumentWrapper";
import ReactiveButton from "../../../Forms/Input/ReactiveButton";

export default function ReviewSubmitButton({ formikBag }) {
  const { values, isSubmitting, dirty } = formikBag;
  const { status, phase, owners, lastSubmittedDate, updated } = values;
  const history = useHistory();

  const gotoAttestation = (applicationId) => {
    history.push(`/application-attest/${applicationId}`);
  };

  const isStale = !lastSubmittedDate || updated > lastSubmittedDate || dirty;
  const canSubmit = updated && (["New", "InProgress", "Deficient"].includes(status) || (phase === "Preparation" && isStale)) && isUserAPreparer(values);
  // console.log(updated, formikBag?.isValid, isUserAPreparer(values), (['New', 'InProgress', 'Deficient'].includes(status) || (phase === 'Preparation' && updated <= lastSubmittedDate)))
  let submitLabel = "Submit Application";

  switch (true) {
    case phase !== "Preparation" && values.allOwnersHaveAttested:
      submitLabel = "Update & Resubmit Application";
      break;
    case phase !== "Preparation" && !values.allOwnersHaveAttested:
      submitLabel = "Update Application";
      break;
    case isStale && lastSubmittedDate:
      submitLabel = "Submit Changes";
      break;
    case owners.find((owner) => owner.userId == getUserId())?.hasAttested || !isUserAnOwner(values):
      submitLabel = "Submit";
      break;
    case owners.length > 1 || !isUserAnOwner(values):
      submitLabel = "Submit & Collect Signatures";
      break;
    default:
      submitLabel = "Submit & Sign";
  }

  // const submitLabel = !isUserAnOwner(values) ? 'Request Signatures' : values?.owners.length > 1 ? `${submitTerm} Application & Request Signatures` : `${submitTerm} Application`;

  const SubmitButton = () => (
    <div className="">
      {formikBag?.isValid ? ((
        values.updated > values?.lastSubmittedDate || (dirty && values?.lastSubmittedDate)) ? null : (
          <p>Your application is ready for submission.</p>
        )
      ) : (
        <div className="mb-4 text-danger font-weight-bold">
          There are errors in your application please resolve before you can submit. Please check each tab in the application with the <FontAwesomeIcon icon={["fas", "exclamation-circle"]} /> to get a
          list of specific corrections that need to be made.
        </div>
      )}
      <ReactiveButton role="button" type="submit" className="small btn-secondary margin-auto" label={submitLabel} processing={isSubmitting} disabled={!formikBag?.isValid} />
    </div>
  );

  // console.log(values);
  // console.log(canSubmit);

  return (
    <div>
      {status === "Deficient" && isUserAPreparer(values) ? (
        <>
          <h3>Miscellaneous Change Request Documents</h3>

          <ArrayWrapperDynamic
            btnSize="small"
            name={`miscDocuments`}
            documentType="MiscellaneousApplication"
            additionLabel="Add Document"
            removalLabel="Remove Document"
            component={DocumentWrapper}
            formikBag={formikBag}
            atLeastOne={false}
            initialValues={[]}
          />
        </>
      ) : null}

      {canSubmit ? (
        <SubmitButton />
      ) : updated ? (
        <div>
          {isUserAnOwner(values) ? (
            <div>
              {ownerHasAttested(values) && phase === "Preparation" ? (
                <h2>Your attestation has been received. Waiting on all owners to attest.</h2>
              ) : (
                <>
                  {phase === "Preparation" && status === "Submitted" && (
                    <div className="">
                      <Button onClick={() => gotoAttestation(values.id)} className="small btn-secondary margin-auto">
                        Proceed to attestation
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          ) : (
            <>
              {isUserAPreparer(values) && phase === "Preparation" && status === "Submitted" && (
                <div className="">Your application has been submitted. Owners will be notified. Once everyone attests, the application will be placed under review with the state.</div>
              )}
            </>
          )}
        </div>
      ) : (
        <p>This is an unsaved application. Please save the application before progressing forward.</p>
      )}
    </div>
  );
}

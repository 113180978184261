import React from "react";
import { getLocalDate } from "../../../util/helpers";

const Time = (props) => {
  const { expectedDate, startDate, completionDate } = props.contract;

  return (
    <div>
      <div className="row">
        <div className="col-5 font-weight-bold">Start Date</div>
        <div className="col-7">{getLocalDate(startDate)}</div>
      </div>
      <div className="row">
        <div className="col-5 font-weight-bold">Expected Completion Date</div>
        <div className="col-7">{completionDate != null ? getLocalDate(completionDate) : getLocalDate(expectedDate)}</div>
      </div>
      <div className="row">
        <div className="col-5 font-weight-bold">Completion Date</div>
        <div className="col-7">{getLocalDate(completionDate)}</div>
      </div>
    </div>
  );
};

export default Time;

import React, { useState } from "react";
import { withFormikFormComponent } from "../../Forms/FormikForm";
import { Form } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PlanPresenceStatusFormInternal = (props) => {
  const { formikBag, status, onSubmit, index } = props;
  const { values, isSubmitting } = formikBag;
  const [confirmationStatus, setConfirmationStatus] = useState(status);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <section id="confirm-payments-form">
      <Form>
        <div className="d-flex">
          {isLoading ? (
            <FontAwesomeIcon icon={"spinner"} className="fa-spin" />
          ) : (
            <>
              <button
                id={`plan-${index}-confirm-action`}
                type="submit"
                className="faux-link btn btn-secondary btn-sm mb-0"
                onClick={async () => {
                  setIsLoading(true);
                  await onSubmit("confirm");
                  setIsLoading(false);
                  setConfirmationStatus("Confirmed");
                }}
              >
                <FontAwesomeIcon className="ml-2 mt-1 text-success" icon={"check"} size="" />
              </button>
              <button
                id={`plan-${index}-reject-action`}
                type="submit"
                className="faux-link btn btn-secondary btn-sm mb-0"
                onClick={async () => {
                  setIsLoading(true);
                  await onSubmit("reject");
                  setIsLoading(false);
                  setConfirmationStatus("Unconfirmed");
                }}
              >
                <FontAwesomeIcon className="ml-2 mt-1 text-danger" icon={"times"} size="" />
              </button>
            </>
          )}
        </div>
      </Form>
    </section>
  );
};

const PlanPresenceStatusForm = withFormikFormComponent(PlanPresenceStatusFormInternal);
export default PlanPresenceStatusForm;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { outputProcurementStatus } from "../../util/helpers";
import FilterTableForm from "../Forms/Input/FilterTableForm";
import { procurementStatuses } from "../../util/typelistHelper";
import { getCompletedProcurements } from "../../util/api";

function CompleteProcurementsList(props) {
  // const { procurements, isLoadingProcurements } = props;
  const [procurements, setProcurements] = useState(null);
  const [isLoadingProcurements, setIsLoadingProcurements] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingProcurements(true);
      const resObj = await getCompletedProcurements();
      setProcurements(resObj?.data?.procurements);
      setIsLoadingProcurements(false);
    };
    fetchData();
  }, []);

  const initialItems = procurements?.length
    ? procurements.map((item) => {
        // console.log(item);
        return {
          ...item,
          id: <Link to={`/procurements/${item.id}`}>{item.id}</Link>,
          solicitationNumberLink: <Link to={`/procurements/${item.id}`}>{item.solicitationNumber}</Link>,
          status: outputProcurementStatus(item.status),
          contract: item?.contract ? item.contractNumber : "Contract not yet assigned",
          isOGSCentralized: item?.contract?.isOGSCentralized ? (
            <FontAwesomeIcon className="mx-3 text-primary" icon={["fas", "check"]} />
          ) : (
            <FontAwesomeIcon className="ml-2 mt-1 text-primary" icon={["fa", "ban"]} />
          ),
          plan: item.approvedPlan ? "Has Plan" : item.planCount,
        };
      })
    : [];

  let FilterListConfig = {
    solicitationNumber: {},
    status: {
      type: "typeahead",
      options: procurementStatuses,
    },
    contract: {},
    description: {},
  };

  let Columns = [
    {
      Header: "Centralized",
      accessor: "isOGSCentralized", // accessor is the "key" in the data
      defaultCanSort: true,
      sortType: "basic",
      filterCode: "isOGSCentralized",
    },
    {
      Header: "Solicitation Number",
      accessor: "solicitationNumberLink", // accessor is the "key" in the data
      defaultCanSort: true,
      sortType: "number",
      filterCode: "solicitationNumberLink",
    },
    {
      Header: "Solicitation Number",
      accessor: "solicitationNumber", // accessor is the "key" in the data
      defaultCanSort: true,
      sortType: "number",
      filterCode: "solicitationNumber",
      isVisible: false,
    },
    {
      Header: "Status",
      accessor: "status", // accessor is the "key" in the data
      defaultCanSort: true,
      sortType: "basic",
      filterCode: "status",
    },
    {
      Header: "Description",
      accessor: "description", // accessor is the "key" in the data
      defaultCanSort: true,
      sortType: "basic",
      filterCode: "description",
    },
    {
      Header: "Contract",
      accessor: "contract", // accessor is the "key" in the data
      defaultCanSort: true,
      sortType: "basic",
      filterCode: "contract",
    },
    {
      Header: "# of Plans",
      accessor: "plan", // accessor is the "key" in the data
      defaultCanSort: true,
      sortType: "basic",
      filterCode: "plan",
    },
  ];

  // console.log(initialItems);
  return (
    <section id="completed-procurements">
      <div>
        <h2>Completed Procurements List</h2>
        <p>Please select the procurement you would like to work on today.</p>
        <div>
          {initialItems?.length > 0 ? (
            <FilterTableForm tableName="complete-procurements-list" Columns={Columns} FilterListConfig={FilterListConfig} tableItems={initialItems} />
          ) : (
            <h2>{isLoadingProcurements ? <span className="loader loader-pulse">Loading Procurements...</span> : "You have no procurements"}</h2>
          )}
        </div>
      </div>
    </section>
  );
}
export default CompleteProcurementsList;

import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import PlansAndWaiversBusiness from "../../Views/PlansAndWaiversBusiness";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getLocalDate } from "../../../../util/helpers";

const PlanHistory = (props) => {
  const { procurement, history, planToLoad } = props;
  const { contract, awardedPlan } = procurement;
  return (
    <div>
      <h3 className="">
        Utilization Plan Version History - <Link to={`/utilization-plans/${planToLoad?.id}/submit`}>Create revisions</Link>
      </h3>
      <Accordion className="d-block">
        {history && history.length > 1 ? (
          history.map((historyItem, index) => {
            {
              /* const version = history.length - index; */
            }
            const { version } = historyItem;

            return (
              <Fragment key={index}>
                <Accordion.Toggle as="h2" variant="link" eventKey={historyItem?.id} className="d-flex flex-row justify-content-between accordion accordion-box cursor-pointer py-3 pr-5">
                  <>
                    Plan version {version} - {historyItem?.status}: {getLocalDate(historyItem?.created)}
                    <FontAwesomeIcon className="text-secondary" icon={["fas", "angle-down"]} size="xs" />
                  </>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={historyItem?.id}>
                  {historyItem?.status === "Deficient" ? (
                    <>
                      <div className="alert alert-primary">
                        <p>Message: {historyItem?.message}</p>
                      </div>
                    </>
                  ) : (
                    <PlansAndWaiversBusiness contract={contract} planVersion={historyItem} isVersion={true} version={version} procurement={procurement} />
                  )}
                </Accordion.Collapse>
              </Fragment>
            );
          })
        ) : (
          <p>No historical plan versions to show.</p>
        )}
      </Accordion>

      {/* <div className="d-flex flex-row justify-content-center">
        <Link to={`/utilization-plans/${awardedPlan.id}/submit`} className="btn btn-secondary">
          Create Revision
        </Link>
      </div> */}
    </div>
  );
};

export default PlanHistory;

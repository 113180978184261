import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getApplications } from "../../components/../util/api";
import { daysLeftColorize, getLocalTime } from "../../util/helpers";
import TableV2 from "../TableV2/TableV2";

const DivisionPortal = (props) => {
  const { notifications } = props;
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [total, setTotal] = useState(0);
  const [sorted, setSorted] = useState([]);
  const sampleNotifications = notifications?.slice(0, 5);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const myApplications = await getApplications(pageIndex + 1, pageSize);
      const applicationList = myApplications?.applications?.map((item) => {
        return {
          ...item,
          nameLink: <Link to={`/application/${item.id}/review`}>{item.legalName}</Link>,
          received: daysLeftColorize(item?.timers?.find((timer) => timer?.type === "Received") ? item?.timers?.find((timer) => timer?.type === "Received") : "N/A"),
          deficient: daysLeftColorize(item?.timers?.find((timer) => timer?.type === "Deficiency") ? item?.timers?.find((timer) => timer?.type === "Deficiency") : "N/A"),
          AdditionalInfoNeeded: daysLeftColorize(item?.timers?.find((timer) => timer?.type === "AdditionalInfoNeeded") ? item?.timers?.find((timer) => timer?.type === "AdditionalInfoNeeded") : "N/A"),
          indepthReview: daysLeftColorize(item?.timers?.find((timer) => timer?.type === "InDepthReview") ? item?.timers?.find((timer) => timer?.type === "InDepthReview") : "N/A"),
          ableToAppeal: daysLeftColorize(item?.timers?.find((timer) => timer?.type === "AbleToAppeal") ? item?.timers?.find((timer) => timer?.type === "AbleToAppeal") : "N/A"),
        };
      });

      setTotal(myApplications?.total);
      setApplications(applicationList);
      setLoading(false);
    }
    fetchData();
  }, [pageIndex, pageSize]);

  const handlePageChange = (pageIndex) => {
    setPageIndex(pageIndex);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
    setPageIndex(0);
  };

  const handleSortChange = (sorted) => {
    setSorted(sorted);
  };

  const columns = [
    {
      Header: "Business",
      accessor: "nameLink",
      defaultCanSort: true,
      filterCode: "nameLink",
    },
    {
      Header: "Received",
      accessor: "received",
      defaultCanSort: true,
      filterCode: "received",
    },
    {
      Header: "Deficient",
      accessor: "deficient",
      defaultCanSort: true,
      filterCode: "deficient"
    },
    {
      Header: "In-Depth Review",
      accessor: "indepthReview",
      defaultCanSort: true,
      filterCode: "indepthReview",
    },
    {
      Header: "Additional Info",
      accessor: "AdditionalInfoNeeded",
      defaultCanSort: true,
      filterCode: "AdditionalInfoNeeded",
    },
    {
      Header: "Able to Appeal",
      accessor: "ableToAppeal",
      defaultCanSort: true,
      filterCode: "ableToAppeal",
    }
  ];

  return (
    <div>
      <Card className="mt-4">
        <Card.Body>
          <a href="/notifications">
            View All Notifications
            <FontAwesomeIcon className="ml-2" icon={"angle-right"} size="lg" />
          </a>
        </Card.Body>
        <Card.Footer>
          <div>
            <div className="row">
              <div className="col-12">
                <h5>RECENT NOTIFICATIONS</h5>
              </div>
            </div>
            {sampleNotifications && sampleNotifications.length ? (
              sampleNotifications.map((notification, index) => (
                <div key={`notification-${index}`}>
                  <div className="row">
                    <div className="col-6">
                      <p dangerouslySetInnerHTML={{ __html: notification?.body }}></p>
                    </div>
                    <div className="col-3">{getLocalTime(notification.timeStamp)}</div>
                    <div className="col-3">{notification.read ? "Read" : "Unread"}</div>
                  </div>
                  {sampleNotifications.length - 1 > index ? <hr /> : null}
                </div>
              ))
            ) : (
              <p>No recent notifications to show</p>
            )}
          </div>
        </Card.Footer>
      </Card>

      <h2 className="mt-4">Application Review Queue</h2>
      <Card>
        <Card.Body>
          <a href="/applications">
            View Your Applications
            <FontAwesomeIcon className="ml-2" icon={"angle-right"} size="lg" />
          </a>
        </Card.Body>
        <Card.Footer>
          {loading && <p className="text-center loader loader-pulse py-5 mt-5">Loading Applications...</p>}
          {!loading && applications.length === 0 && (
            <p className="text-center py-5 mt-5">No applications currently assigned to you</p>
          )}
          {!loading && applications.length > 0 && (
            <div className="application-table-wrapper">
              <TableV2
                data={applications}
                hideViewAll={true}
                columns={columns}
                pageSize={pageSize}
                pageIndex={pageIndex}
                total={total}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                onSortChange={handleSortChange}
              />
            </div>
          )}
        </Card.Footer>
      </Card>
      {/*
      <h2 className="mt-4">Administrator Actions</h2>
      <div className="row">
        <div className="col-6">
          <NotificationBox title="View Narrative Plans"></NotificationBox>
        </div>
        <div className="col-6">
          <NotificationBox title="View Goal Plans"></NotificationBox>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-6">
          <NotificationBox title="View Activity Plans"></NotificationBox>
        </div>
      </div>
            */}
    </div>
  );
};

export default DivisionPortal;

import React, { useState, useEffect } from "react";
import FilterTableForm from "../../Forms/Input/FilterTableForm";
import _ from "lodash";
import { getYearMonth } from "../../../util/helpers";
import { getComplianceReports } from "../../../util/api";

const FilterListConfig = {
  dateString: {
    isJoin: false,
  },
  status: {
    isJoin: false,
  },
};

const Columns = [
  {
    Header: "Compliance Date",
    accessor: "date", // accessor is the "key" in the data
    disableSortBy: false,
    filterCode: "dateString",
  },
  {
    Header: "Payment Count",
    accessor: "paymentCount", // accessor is the "key" in the data
    disableSortBy: false,
    filterCode: "paymentCount",
  },
  {
    Header: "Status",
    accessor: "status", // accessor is the "key" in the data
    disableSortBy: false,
    filterCode: "status",
  },
  /*
  {
    Header: "Compliance %",
    accessor: "complianceReportPercentage", // accessor is the "key" in the data
    disableSortBy: false,
    filterCode: "complianceReportPercentage",
  },
  */
];

const ComplianceReportList = (props) => {
  const { awardedPlan, procurement, procurementId } = props;
  const { contract } = procurement;
  const [complianceReports, setComplianceReports] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [initialItems, setInitialItems] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const reportsRes = await getComplianceReports(contract.id);
      setComplianceReports(reportsRes);
    }
    if (contract?.id) {
      setIsLoading(true);
      fetchData().then(() => setIsLoading(false));
    }
  }, [contract]);

  let initialItems = complianceReports?.length
    ? complianceReports?.map((item) => {
        let wItem = {};
        const { amount, id, documents, complianceDate } = item;
        wItem[item.id] = { amount, id, documents, complianceDate };

        return {
          ...item,
          date: <a href={`compliance-reports/${getYearMonth(item.yearMonth)}`}>{getYearMonth(item.yearMonth)}</a>,
          dateString: getYearMonth(item.yearMonth),
          paymentCount: item.payments.length,
          status: _.startCase(item.status),

          // confirmed: item.confirmed ? <FontAwesomeIcon className="mr-2 text-success" icon={faCheck} size="sm" /> : <FontAwesomeIcon className="mr-2 text-primary" icon={faTimes} size="sm" />,
        };
      })
    : [];
  return (
    <div>
      <h2>Compliance Reports</h2>
      {initialItems && initialItems?.length ? (
        <FilterTableForm tableName="compliance-report-list" Columns={Columns} FilterListConfig={FilterListConfig} tableItems={initialItems} />
      ) : (
        <>{isLoading ? <h3 className="loader loader-pulse">Loading Compliance Reports...</h3> : <h3>No Compliance Reports to show. </h3>}</>
      )}
    </div>
  );
};

export default ComplianceReportList;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FastField, Form } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Link, Prompt, useHistory, useParams } from "react-router-dom";
import { getApplication, handleAttestation } from "../../../util/api";
import { schemas } from "../../../util/errorHelper";
import { getArrayIndex, hydrateAttestation, ownerHasAttested, userCanAttest } from "../../../util/helpers";
import { documentTypes } from "../../../util/typelistHelper";
import { getUserId } from "../../../util/userHelper";
import DebugModal from "../../Debug/DebugModal";
import { withFormikFormComponent } from "../../Forms/FormikForm";
import DocumentWrapper from "../../Forms/helpers/DocumentWrapper";
import InputComponent from "../../Forms/Input/InputComponent";
import ReactiveButton from "../../Forms/Input/ReactiveButton";
import ChangeRequestsDisplay from "../components/ChangeRequestsDisplay";

// performce 400 Sunrise Avenue Roleville Suite 190

const AttestationFormInternal = ({ formikBag, appBag }) => {
  const { values, isSubmitting, isValid } = formikBag;
  const { application } = appBag;
  const owner = values.owners.find((owner) => owner.userId === getUserId());

  const getArrayIndexWrapper = (type, arrayName) => {
    const elements = _.get(values, arrayName);
    const index = getArrayIndex(elements, type);
    return `${arrayName}.${index}`;
  };

  const ownerIndex = getArrayIndex(values.owners, getUserId(), "userId");
  const attestDocs = owner?.isServiceDisabled ? ["Resume", "NGB22", "DD214", "DisabilityLetter"] : ["Resume"];

  return (
    <Form>
      <Prompt when={formikBag?.dirty && !formikBag?.isSubmitting} message={"You have unsaved changes, are you sure you want to leave?"} />
      <ChangeRequestsDisplay formikBag={formikBag} />
      <DebugModal formikBag={formikBag} name="Attestation Formik Bag" />

      {ownerHasAttested(application) && application?.status !== "Deficient" ? (
        <h2>Your attestation for this application has been received</h2>
      ) : (
        <>
          <div className="form-tab-intro">
            <h2>Uploaded Supporting Documentation</h2>
            <p>The following includes the supporting documentation you have uploaded in connection with your application. Any missing documents are indicated in red.</p>
          </div>
          {userCanAttest ? (
            <div className="p-4 bg-light">
              {attestDocs.map((documentType, index) => {
                const label = documentTypes.find((item) => item[0] === documentType)[1];
                return <DocumentWrapper key={index} label={label} documentType={documentType} name={`${getArrayIndexWrapper(documentType, "documents")}`} formikBag={formikBag} optional={true} />;
              })}
            </div>
          ) : null}

          <hr className="" />

          <div className="form-tab-intro">
            <h2>Certifications and Declarations</h2>
            <p>Please carefully review the following certification and declaration statements. Each owner must provide a signature below.</p>
          </div>

          <div className="p-4 bg-light">
            <div className="mb-5">
              <h3>CERTIFICATION/DECLARATION A: Penalty of Perjury Declaration</h3>
              <p>
                The undersigned states:
                <br />I certify (or declare) under penalty of perjury under the laws of the State of New York that all information submitted to the Division of Service-Disabled Veterans’ Business
                Development submitted in the SDVOB Application, and any additional information to determine eligibility, is true and correct.
              </p>
            </div>

            <div className="mb-5">
              <h3>CERTIFICATION/DECLARATION B: Federal Tax Return Transcript Acknowledgment</h3>
              <p>The undersigned acknowledges that upon request by the DSDVBD that the SDVOB applicant(s) must submit a specified federal tax form to release transcripts of tax returns.</p>
            </div>

            <div className="mb-5">
              <h3>CERTIFICATION/DECLARATION C: Commercially Useful Function</h3>
              <p>The undersigned certifies (or declare(s)) that a “Commercially Useful Function will be performed on each state contract.</p>
              <p>
                Commercially useful function: A SDVOB performs a commercially useful function when it is responsible for execution of the work of the contract and is carrying out its responsibilities
                by actually performing, managing, and supervising the work involved. To perform a commercially useful function, an SDVOB must, where applicable and in accordance with any State agency
                specifications, also be responsible, with respect to materials and supplies used on the contract, for ordering and negotiating price, determining quality and quantity, and installing.
                An SDVOB does not perform a commercially useful function if its role adds no substantive value and is limited to that of an extra participant in a transaction, contract, or project
                through which funds are passed in order to obtain the appearance of participation. Factors to be used in assessing whether an SDVOB is performing a commercially useful function
                include:
              </p>

              <ol>
                <li>the amount of work subcontracted;</li>
                <li>industry practices;</li>
                <li>whether the amount the SDVOB is to be paid under the contract is commensurate with the work it is to perform;</li>
                <li>the credit claimed towards SDVOB utilization goals for the performance of the work by the SDVOB; and</li>
                <li>any other relevant factors.</li>
              </ol>
            </div>

            <div className="mb-2">
              <h3>CERTIFICATION / DECLARATION D: SDVOB 51% Unconditional Ownership Acknowledgment</h3>
              <p>
                The undersigned acknowledges that applicant(s) must submit documents requested by the DSDVBD that establish at least 51% of the stock, voting stock, partnership, ownership, or
                membership interest is unconditionally owned by one or more disabled Veterans.
              </p>
              <p className="font-weight-bold">Authorized signature from each owner, corporate office, partner, or member/manager:</p>
            </div>

            {userCanAttest && <FastField wrapperClass="" label="Signature" type="signature" name={`signature`} placeholder="" component={InputComponent} />}
          </div>

          <hr />

          <Link id="back-to-application" className="mb-3 d-block" to={`/application/${application?.id}`}>
            <FontAwesomeIcon className="mr-2" icon={"arrow-left"} />
            Back to Application
          </Link>

          <div className="d-flex flex-column align-items-center">
            {!isValid ? (
              <h3>Please complete all required information</h3>
            ) : (
              <ReactiveButton type="submit" className="small btn-secondary margin-auto" label="Finish signing &amp; save for preparer submission" processing={isSubmitting} />
            )}
          </div>
          {/* {JSON.stringify(response)} */}
        </>
      )}
    </Form>
  );
};
const AttestationFormikForm = withFormikFormComponent(AttestationFormInternal, schemas.attestationSchema);

const AttestationForm = ({ userInfo, messages, routeInitialValues, formikBag }) => {
  const [application, setApplication] = useState();
  const history = useHistory();
  let { applicationId } = useParams();

  const onSubmitAttestation = async (values) => {
    const ownerId = application?.owners.find((owner) => owner.userId === getUserId())?.id;
    const applicationId = application.id;
    const attestation = { ...values, applicationId, ownerId };
    const outApplication = await handleAttestation(attestation);
    setApplication(outApplication);
    history.push(`/application/${applicationId}`);
  };

  useEffect(() => {
    async function loadAsync() {
      let application = await getApplication(applicationId);
      setApplication(application);
    }
    loadAsync();
  }, []);

  routeInitialValues = application ? application : {};
  if (Object.keys(routeInitialValues).length) {
    hydrateAttestation(routeInitialValues, userInfo.user);
  }
  // console.log(routeInitialValues);

  return (
    <div>
      {userCanAttest(application) ? (
        <>
          <AttestationFormikForm
            userInfo={userInfo}
            appBag={{ application }}
            onSubmit={onSubmitAttestation}
            validateOnMount={true}
            validateOnChange={true}
            routeInitialValues={routeInitialValues}
          ></AttestationFormikForm>
        </>
      ) : application?.phase !== "Preparation" ? (
        <h3>Your application has been submitted to the state for review.</h3>
      ) : (
        <h3>You can not attest at this time.</h3>
      )}
    </div>
  );
};
export default AttestationForm;

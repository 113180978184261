import React, { useState } from "react";
import { Field, Form } from "formik";
import { Button } from "react-bootstrap";
import { withFormikFormComponent } from "../../Forms/FormikForm";
import InputComponent from "../../Forms/Input/InputComponent";
import DateTimePicker from "react-datetime-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import get from "lodash/get";
import DebugModal from "../../Debug/DebugModal";
import { schemas } from "../../../util/errorHelper";

function ActivityItemFormInternal({ formikBag, handleActivityLogSubmit, ...props }) {
  const [formDisplayed, setFormDisplayed] = useState(false);
  const { values, setFieldValue } = formikBag;
  const { application } = props;

  return (
    <Form>
      <DebugModal formikBag={formikBag} name="Formik Bag" />

      {formDisplayed && (
        <div className="bg-light p-5">
          <div className="field-group">
            <div className="row">
              <div className="col-12">
                New Entry 1
                <FontAwesomeIcon
                  icon={["fas", "minus-circle"]}
                  className="ml-2"
                  onClick={() => {
                    setFormDisplayed(false);
                  }}
                />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-12">
                <Field
                  type="select"
                  name={`type`}
                  component={InputComponent}
                  label="What activity occured"
                  options={[
                    ["Phone call made", "Call Attempt"],
                    ["Email was sent", "Email Sent"],
                    ["Mail was sent", "Mail Sent"],
                    ["manual", "Manual"],
                  ]}
                ></Field>
              </div>
            </div>
            {get(values, `type`) === "manual" ? (
              <div className="row mb-4">
                <div className="col-12">
                  <Field type="textarea" label="Notes" name={`activity`} component={InputComponent}></Field>
                </div>
              </div>
            ) : null}
            <div className="row">
              <div className="col-md-9 mb-4">
                <DateTimePicker onChange={(value) => setFieldValue(`created`, value)} value={get(values, `created`)} maxDate={new Date()} />
              </div>
              <div className="col-md-3 mb-4">
                <Button
                  onClick={async () => {
                    const activityValue = get(values, `type`) === "manual" ? get(values, `activity`) : get(values, `type`);
                    // const utcDate = new Date(values.created);
                    // console.log(utcDate);
                    // console.log(values.created);
                    await handleActivityLogSubmit(application.id, activityValue, values.created);
                    setFormDisplayed(false);
                  }}
                  type="button"
                  variant="secondary"
                  className="btn-md btn-submit btn-block"
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="mt-4">
        <Button
          onClick={() => {
            setFormDisplayed(true);
          }}
          type="button"
          variant="light"
        >
          <FontAwesomeIcon className="mr-3" icon={["fas", "plus"]} /> New Activity Log Entry
        </Button>
      </div>
    </Form>
  );
}

const ActivityItemForm = withFormikFormComponent(ActivityItemFormInternal, schemas.activityLogSchema);
export default ActivityItemForm;

import React, { useState, useEffect } from "react";
import _ from "lodash";
import { loadUserRole, roles } from "../../../util/userHelper";
import ReportDetails from "./ReportDetails";
import ReportHistory from "../AgencyReviews/Reports/ReportHistory";
import ReportStatusUpdateWrapper from "../AgencyReviews/Reports/ReportStatusUpdateWrapper";

const ReportView = (props) => {
  const { report, reportId, reportType, reportTypeData, updateCount, setUpdateCount, hasSaved, setHasSaved } = props;
  const [reportReset, setReportReset] = useState(0);
  const isDivision = [roles.DivisionAdmin, roles.DivisionUser].includes(loadUserRole());
  const ReportUpdateForm = reportTypeData.formComponent;

  return (
    <>
      {report ? (
        <section id={`${reportType}-view-wrapper`}>
          {report?.message && report.status === "Deficient" ? (
            <div className="alert alert-primary">
              <p>Message: {report.message}</p>
            </div>
          ) : null}

          <div className="row">
            <div className={isDivision ? "col-md-8" : "col-12"}>
              {isDivision || !["Deficient", "Created"].includes(report?.status) ? (
                <>
                  <ReportDetails reportTypeData={reportTypeData} reportType={reportType} report={report} id={report?.id} />

                  <h2>{_.startCase(reportType)} Version History</h2>
                  <ReportHistory history={report?.history} reportType={reportType} reportTypeData={reportTypeData} />
                </>
              ) : (
                <>
                  <ReportUpdateForm
                    report={report}
                    reportId={reportId}
                    routeInitialValues={reportTypeData.initialValues}
                    updateCount={updateCount}
                    setUpdateCount={setUpdateCount}
                    hasSaved={hasSaved}
                    setHasSaved={setHasSaved}
                    validateOnMount={true}
                  />
                </>
              )}
            </div>

            {isDivision ? (
              <div className="col-md-4 bg-light p-3 py-4">
                <ReportStatusUpdateWrapper
                  reportReset={reportReset}
                  setReportReset={setReportReset}
                  report={report}
                  reportType={reportType}
                  reportId={reportId}
                  updateCount={updateCount}
                  setUpdateCount={setUpdateCount}
                />
              </div>
            ) : null}
          </div>
        </section>
      ) : null}
    </>
  );
};

export default ReportView;

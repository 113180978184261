// import the library
import { library, config } from "@fortawesome/fontawesome-svg-core";

import {
  faAngleRight as faAngleRightLight,
  faAngleDoubleRight as faAngleDoubleRightLight,
  faAngleLeft as faAngleLeftLight,
  faAngleDoubleLeft as faAngleDoubleLeftLight,
  faAngleUp as faAngleUpLight,
} from "@fortawesome/pro-light-svg-icons";
// import your icons
import {
  faExternalLink,
  faPlus,
  faMinus,
  faBell,
  faCheck,
  faQuestion,
  faBadgeCheck,
  faCircle,
  faTrash,
  faCheckSquare,
  faBan,
  faTimes,
  faAngleDown,
  faAngleUp,
  faAngleRight,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faAngleLeft,
  faArrowLeft,
  faArrowRight,
  faPhone,
  faEnvelope,
  faSpinner,
  faLink,
  faDownload,
  faExclamationCircle,
  faArrowAltCircleRight,
  faQuestionCircle,
  faMinusCircle,
  faAward,
  faWindowClose,
  faHourglassStart,
  faInfoCircle,
  faPencilAlt,
  faPaperclip,
  faToolbox,
  faWrench,
  faBuilding,
  faSync,
  faSearch,
} from "@fortawesome/pro-solid-svg-icons";

import { faFacebookF, faInstagram, faFlickr, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";

import { faSquare } from "@fortawesome/pro-light-svg-icons";

config.searchPseudoElements = true;

library.add(
  faExternalLink,
  faPlus,
  faMinus,
  faBell,
  faCheck,
  faQuestion,
  faBadgeCheck,
  faCircle,
  faTrash,
  faSquare,
  faCheckSquare,
  faBan,
  faTimes,
  faAngleUp,
  faAngleUpLight,
  faAngleDown,
  faAngleRight,
  faAngleDoubleRight,
  faAngleDoubleLeftLight,
  faAngleLeft,
  faArrowLeft,
  faArrowRight,
  faPhone,
  faEnvelope,
  faSpinner,
  faLink,
  faDownload,
  faArrowAltCircleRight,
  faExclamationCircle,
  faQuestionCircle,
  faMinusCircle,
  faAward,
  faWindowClose,
  faHourglassStart,
  faInfoCircle,
  faPencilAlt,
  faPaperclip,
  faToolbox,
  faWrench,
  faBuilding,
  faAngleRightLight,
  faAngleDoubleRightLight,
  faAngleLeftLight,
  faAngleDoubleLeft,
  faFacebookF,
  faInstagram,
  faFlickr,
  faTwitter,
  faYoutube,
  faSync,
  faSearch
);

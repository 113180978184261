import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { updateProcurement, updateProcurementUsers, getCentralizedContracts, handleRequestPlanFromPrime } from "../../../util/api";
import { getLocalDate, formatPhoneNumber, outputAddresses, outputMoney, plansExpected } from "../../../util/helpers";
import AssignContractInternal from "../Forms/AssignContract";
import FinancialProgress from "../Sections/FinancialProgress";
import Time from "../Sections/Time";
import ProcurementUpdateForm from "./ProcurementUpdateForm";
import ProcurementUpdateUsersForm from "./ProcurementUpdateUsersForm";

const ContractDetailsViewAgency = (props) => {
  let { procurement, updateCount, setUpdateCount, procurementId } = props;
  const { contract, awardedPlan } = procurement;
  const [centralizedContractsOptions, setCentralizedContractsOptions] = useState([]);
  const [requestingPlan, setRequestingPlan] = useState(false);
  const [planRequested, setPlanRequested] = useState(false);

  async function fetchData() {
    // const procurementRes = await getProcurement(procurementId);
    // setProcurement(procurementRes?.data);
  }

  useEffect(() => {
    // console.log(procurement);
    const fetchData = async () => {
      const resObj = await getCentralizedContracts();
      const centralizedContracts = resObj?.data?.centralizedContracts.map((item) => {
        return [item.id, item.name];
      });
      // console.log(centralizedContracts);
      setCentralizedContractsOptions([["", "Unassigned"], ...centralizedContracts]);
    };

    if (procurement?.contract?.isOGSCentralized) {
      fetchData();
    }
  }, []);

  // console.log(centralizedContractsOptions);

  const onSubmit = async (values, { resetForm }) => {
    const {
      id,
      agencyId,
      solicitationNumber,
      description,
      startDate,
      endDate,
      contractNumber,
      contractId,
      goal,
      exclusionType,
      contractTerm,
      contractCategory,
      contractServiceType,
      currentValueOverrideReason,
    } = values;

    let procurementUpdateObj = {
      id: id,
      agencyId: agencyId,
      solicitationNumber: solicitationNumber,
      description: description,
      startDate: startDate,
      endDate: endDate,
      contractNumber: contractNumber,
      contractId: contractId,
      goal: goal,
      exclusionType: exclusionType !== "" ? exclusionType : null,
      contractTerm: contractTerm !== "" ? contractTerm : null,
      contractCategory: contractCategory !== "" ? contractCategory : null,
      contractServiceType: contractServiceType !== "" ? contractServiceType : null,
      currentValueOverrideReason: currentValueOverrideReason,
    };
    if (exclusionType === "Eligible") {
      procurementUpdateObj.currentValue = values.currentValue;
    }

    await updateProcurement(procurementUpdateObj);
    setUpdateCount(updateCount + 1);
  };
  const onUserFormSubmit = async (values, { resetForm }) => {
    const { userIds } = values;
    await updateProcurementUsers(procurementId, userIds);
    window.scrollTo(0, 0);
    setUpdateCount(updateCount + 1);
    // resetForm();
  };

  return (
    <div id="contract-details-wrapper">
      <section id="plan-waiver">
        <div id="contract-details-wrapper">
          <div className="row">
            <div className="col-md-4 order-md-2 bg-light mt-3 sidebar-form">
              <h3 className="mt-4">Agency Review</h3>
              <hr />
              <ProcurementUpdateForm
                awardedPlan={awardedPlan}
                onSubmit={onSubmit}
                routeInitialValues={{
                  // id: procurement.id,
                  // contractNumber: procurement.contractNumber,
                  // contractId: procurement.contractId,
                  // goal: procurement.goal,
                  // agencyId: procurement.agencyId,
                  // solicitationNumber: procurement.solicitationNumber,
                  // exclusionType: procurement.exclusionType,
                  // contractTerm: procurement.contractTerm,
                  // contractCategory: procurement.contractCategory,
                  // contractServiceType: procurement.contractServiceType,
                  // description: procurement.description,
                  // centralizedContractId: procurement.centralizedContractId,
                  ...procurement,
                  startDate: procurement?.startDate ? new Date(procurement?.startDate) : null,
                  endDate: procurement?.endDate ? new Date(procurement?.endDate) : null,
                  currentValue: procurement?.contract?.currentValue,
                  value: procurement?.contract?.value,
                  currentValueOverrideReason: procurement?.contract?.currentValueOverrideReason,
                  // startDate: procurement?.startDate ? new Date(procurement?.startDate + ".000Z") : null,
                  // endDate: procurement?.endDate ? new Date(procurement?.endDate + ".000Z") : null,
                }}
                centralizedContractsOptions={centralizedContractsOptions}
              />
              <hr />
              {procurement ? <ProcurementUpdateUsersForm onSubmit={onUserFormSubmit} routeInitialValues={{ userIds: procurement?.users?.map((user) => user.id) }} /> : null}
            </div>
            <div className="col-md-8">
              {contract?.id ? (
                <>
                  <div className="row">
                    <div className="col-12">
                      <hr />
                      {/* <div className="row">
                        <div className="col-5 font-weight-bold">Contract Name</div>
                        <div className="col-7">{contract?.name}</div>
                      </div> */}
                      <div className="row">
                        <div className="col-5 font-weight-bold">Agency Contract #</div>
                        <div className="col-7">{contract?.agencyContractNumber}</div>
                      </div>
                      <div className="row">
                        <div className="col-5 font-weight-bold">SFS Contract #</div>
                        <div className="col-7">{contract.contractSource === "AgencyUpload" ? "Agency Uploaded" : contract?.contractNumber}</div>
                      </div>
                      <div className="row">
                        <div className="col-5 font-weight-bold">Centralized Contract</div>
                        <div className="col-7">{contract?.isOGSCentralized ? "Yes" : "No"}</div>
                      </div>

                      {/*
                      <div className="row">
                        <div className="col-5 font-weight-bold">Category</div>
                        <div className="col-7">{procurement?.contractCategory}</div>
                      </div>
                    */}
                    </div>
                  </div>
                  {/*
                  <div className="row">
                        <div className="col-5 font-weight-bold"># of SDVOBs Subcontractors</div>
                        <div className="col-7">{awardedPlan ? awardedPlan?.subcontracts?.length : "N/A"}</div>
                      </div>
                  */}
                  <div className="row">
                    <div className="col-12">
                      <hr />
                      <div className="row">
                        <div className="col-5 font-weight-bold">Original Contract Value</div>
                        <div className="col-7">{outputMoney(contract?.value)}</div>
                      </div>
                      <div className="row">
                        <div className="col-5 font-weight-bold">Amended Contract Value</div>
                        <div className="col-7">{outputMoney(contract?.adjustedValue)}</div>
                      </div>
                    </div>
                  </div>

                  <hr />

                  {awardedPlan && (
                    <FinancialProgress
                      contract={contract}
                      procurement={procurement}
                      procurementId={procurementId}
                      awardedPlan={awardedPlan}
                      disbursementsToPrimeToUse={contract?.disbursementsToPrime}
                      disbursementsToSDVOBsToUse={contract?.disbursementsToSDVOBs}
                      disbursementsDesc="Accepted Payments to SDVOBs"
                    />
                  )}

                  <Time contract={contract} />

                  {/* <div className="row">
                    <div className="col-12">
                      <hr />
                      <div className="row">
                        <div className="col-5 font-weight-bold">Start Date</div>
                        <div className="col-7">{getLocalDate(contract?.startDate)}</div>
                      </div>
                      <div className="row">
                        <div className="col-5 font-weight-bold">Expected Completion Date</div>
                        <div className="col-7">{getLocalDate(contract?.expectedDate)}</div>
                      </div>
                    </div>
                  </div> */}

                  <div className="row">
                    <div className="col-12">
                      {plansExpected(procurement) && (
                        <>
                          <hr />

                          <p>
                            <span className="font-weight-bold">Plan Status:</span> {awardedPlan ? awardedPlan?.status : "No plan chosen"}
                          </p>
                        </>
                      )}

                      {!awardedPlan && procurement?.exclusionType !== "ExemptExcluded" ? (
                        <div className="row">
                          <div className="col-md-6">
                            {requestingPlan ? (
                              <div className="loader loader-pulse">Sending Request</div>
                            ) : planRequested ? (
                              <span className="text-success">Plan Requested</span>
                            ) : (
                              <>
                                <button
                                  role="button"
                                  name="request-plan-for-prime"
                                  className="btn btn-secondary btn-sm btn-block"
                                  onClick={async () => {
                                    try {
                                      setRequestingPlan(true);
                                      const resObj = await handleRequestPlanFromPrime(procurementId);
                                      setPlanRequested(true);
                                      setRequestingPlan(false);
                                    } catch (e) {
                                      // setPlanRequested(false);
                                      setRequestingPlan(false);
                                    }
                                  }}
                                >
                                  Request Plan from Prime
                                </button>
                              </>
                            )}
                          </div>
                          <div className="col-md-6">
                            <Link role="link" name="submit-plan-for-prime" to={`/utilization-plans/agency-submit/${procurementId}`} className="btn btn-secondary btn-sm btn-block">
                              Submit plan for prime
                            </Link>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <hr />
                      <div className="row">
                        <div className="col-5 font-weight-bold">Contractor</div>
                        <div className="col-7">{contract?.primeContractor?.name}</div>
                      </div>
                      <div className="row">
                        <div className="col-5 font-weight-bold">Contact Number</div>
                        <div className="col-7">{formatPhoneNumber(contract?.primeContractor?.contactNumber)}</div>
                      </div>
                      {outputAddresses(contract?.primeContractor?.addresses, "row", "col-5 font-weight-bold", "col-7")}
                      <div className="row">
                        <div className="col-5 font-weight-bold">SDVOB Status</div>
                        <div className="col-7">{contract?.primeContractor?.isCertifiedSdvob ? "Certified" : "Not Certified"}</div>
                      </div>
                      {/* <div className="row">
                        <div className="col-12">
                          <a href="/business-search" className="text-secondary">
                            See Full Profile <FontAwesomeIcon className="mr-2" icon={faAngleRight} size="lg" />
                          </a>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <hr />
                      {/* <div className="row">
                        <div className="col-5 font-weight-bold">Type of Contract</div>
                        <div className="col-7">{contract?.contractTerm}</div>
                      </div> */}
                      {/*
                      <div className="row">
                        <div className="col-5 font-weight-bold">Dept / Business Unit</div>
                        <div className="col-7">{contract?.department}</div>
                      </div>
                      */}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <h2>Procurement {`${procurement?.description}`} Review</h2>
                  <AssignContractInternal procurement={procurement} updateCount={updateCount} setUpdateCount={setUpdateCount} />
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContractDetailsViewAgency;

import React from "react";
import get from "lodash/get";
import set from "lodash/set";
import omit from "lodash/omit";
import startCase from "lodash/startCase";

const TEMP_USER_INFO = "tempUserInfo";
const ACTIVE_ORG_ID = "activeOrgId";
const TEMP_ORG_PROMPT_SHOWN = "orgPromptShown";

export const noRoleOrgType = {
  singular: "none",
  plural: "none"
};

const orgTypes = {
  Business: {
    singular: "business",
    plural: "businesses",
  },
  AgencyAdmin: {
    singular: "agency",
    plural: "agencies",
  },
  AgencyUser: {
    singular: "agency",
    plural: "agencies",
  },
  DivisionAdmin: {
    singular: "division",
    plural: "divisions",
  },
  DivisionUser: {
    singular: "division",
    plural: "divisions",
  },
  ContractManager: {
    singular: "contract",
    plural: "contracts",
  },
};

export const getOrgType = (userRole) => {
  return userRole ? (orgTypes[userRole] ?? noRoleOrgType) : noRoleOrgType;
};

export const loadDefaultActiveOrgIndex = () => {
  const orgType = getOrgType(loadUserToken());
  console.log(loadUserToken());
  console.log(orgType);
  const activeOrgIndex = get(loadUserToken(), `user.active${startCase(orgType.singular)}`);
  return activeOrgIndex ? activeOrgIndex : 0;
};

export const getActiveOrg = (userInfo) => {
  const orgType = getOrgType(get(userInfo, "user.role"));

  if (typeof orgType === "undefined") return undefined;

  const orgs = get(userInfo, `user.${orgType.plural}`);

  let activeOrg;
  if (orgType.singular === "business") {
    activeOrg = orgs?.find((org) => org?.[orgType.singular]?.id === loadActiveOrgId());
    return activeOrg ? activeOrg[orgType.singular] : undefined;
  } else if (orgType.singular === "agency") {
    activeOrg = orgs?.find((org) => org?.id === loadActiveOrgId());
    return activeOrg ? activeOrg : undefined;
  }

  return undefined;
};

export const getFirstOrg = (userInfo) => {
  const orgType = getOrgType(get(userInfo, "user.role"));

  let firstOrg;
  if (orgType.singular === "business") {
    firstOrg = get(userInfo, `user.${orgType.plural}.0.${orgType.singular}`);
  } else if (orgType.singular === "agency") {
    firstOrg = get(userInfo, `user.${orgType.plural}.0`);
  }

  return firstOrg;
};

export const loadUserToken = () => {
  const userToken = localStorage.getItem(TEMP_USER_INFO) ? JSON.parse(localStorage.getItem(TEMP_USER_INFO)) : null;
  return userToken;
};

export const storeUserToken = (userToken) => {
  const orgType = getOrgType(userToken?.user?.role);

  if (userToken?.user?.[orgType?.plural]) {
    const userWithoutOrgs = omit(userToken?.user, [orgType.plural]);
    const userTokenWithoutOrgs = { token: userToken.token, user: userWithoutOrgs };
    localStorage.setItem(TEMP_USER_INFO, JSON.stringify(userTokenWithoutOrgs));
  } else {
    localStorage.setItem(TEMP_USER_INFO, JSON.stringify(userToken));
  }
};

export const makeBusinessPromptShown = () => {
  localStorage.setItem(TEMP_ORG_PROMPT_SHOWN, true);
};

export const isBusinessPromptShown = () => {
  const result = localStorage.getItem(TEMP_ORG_PROMPT_SHOWN);
  const response = result === "true" ? true : false;
  return response;
};

export const clearLocalStorage = () => {
  localStorage.removeItem(TEMP_USER_INFO);
  localStorage.removeItem(TEMP_ORG_PROMPT_SHOWN);
  localStorage.removeItem("tabs");
  removeActiveOrgId();
};

export const storeActiveOrgId = (activeOrgId) => {
  localStorage.setItem(ACTIVE_ORG_ID, activeOrgId);
};

export const removeActiveOrgId = () => {
  localStorage.removeItem(ACTIVE_ORG_ID);
};

export const loadActiveOrgId = () => {
  return localStorage.getItem(ACTIVE_ORG_ID);
};

export const loadUserRole = () => {
  const userToken = loadUserToken();
  return userToken?.user?.role;
};

export const loadApplication = (userInfo, activeOrgId) => {
  const application = get(getActiveOrg(userInfo, activeOrgId), "business.application.0");
  return application;
};

export const getBusinessLegalName = (business) => {
  const result = business && business?.legalName ? business?.legalName : business?.id ? business?.id : "";
  return result;
};

export const getAccessToken = () => {
  const userToken = loadUserToken();
  return userToken?.token?.accessToken;
};

export const getUserId = () => {
  const userToken = loadUserToken();
  return userToken?.user?.id;
};

export const getUserFullName = (user) => (user?.firstName ? [user?.firstName, user?.lastName].join(" ") : "Unassigned");

export const roles = {
  Business: "Business",
  DivisionUser: "DivisionUser",
  DivisionAdmin: "DivisionAdmin",
  AgencyAdmin: "AgencyAdmin",
  AgencyUser: "AgencyUser",
  ContractManager: "ContractManager",
};

// const getActiveOrgIndexPath = () => {
//   return `user.activeBusiness`
// }

// export const storeActiveBusinessIndex = (activeBusinessIndex) => {
//   const userInfo = loadUserToken();
//   set(userInfo, getActiveOrgIndexPath(), activeBusinessIndex);
//   storeUserInfo(userInfo);
// }
// const TEMP_APPLICATION_INFO = "tempApplicationInfo";
// const getActiveOrgPath = () => {
//   return `user.businesses[${loadActiveBusinessIndex()}].business`
// }

// const getApplicationPath = () => {
//   // return `user.businesses[${loadActiveBusinessIndex()}].business.applications.0`
// }

// export const loadActiveBusiness = () => {
//   return get(loadUserInfo(), getActiveOrgPath());
// }

// export const loadBusinessId = (userInfo) => {
//   const businessId = getActiveOrg(userInfo)?.id;
//   return businessId;
// }

// export const loadApplicationByUser = (user) => {
//   const application = get({ user }, getApplicationPath());
//   return application;
// }

import React from "react";
import "cleave.js/dist/addons/cleave-phone.us";
import Cleave from 'cleave.js/react';
import get from 'lodash/get'
/**
 * https://codepen.io/tutsplus/pen/KMWqRr
 * @param {*} props
 * @returns
 */
const InputComponentFormatted = (props) => {

  const { form: { setFieldValue, setFieldTouched, values }, field, type, isdisabled } = props;
  const { name } = field;
  const formikValue = get(values, name);

  const onChange = (event) => {
    // formatted pretty value
    // console.log(event.target.value);

    // // raw value
    // console.log(event.target.rawValue);
    if (type === 'inputnumeral' || type === 'inputphone' || type === 'inputFEIN') {
      setFieldValue(name, event.target.rawValue)
    } else {
      setFieldValue(name, event.target.value)
    }
  }

  const initSettings = (type) => {
    let settings;
    switch (type) {
      case "inputphoneextension":
        settings = {
          settings: {
            blocks: [6],
            numericOnly: true
          },
          placeholder: 'XXXXX'
        };
        break;
      case "inputZip":
        settings = {
          settings: {
            blocks: [5],
            numericOnly: true
          },
          placeholder: 'XXXXX'
        };
        break;
      case "inputphone":
        settings = {
          settings: {
            blocks: [3, 3, 4],
            numericOnly: true
          },
          placeholder: 'XXX XXX XXXX'
        };
        break;
      case "inputdate":
        settings = {
          settings: {
            date: true,
            datePattern: ['m', 'd', 'Y']
          },
          placeholder: 'MM/DD/YYYY'
        };
        break;
      case "inputnumeral":
        settings = {
          settings: {
            prefix: '$ ',
            rawValueTrimPrefix: true,
            numeral: true,
            numeralDecimalScale: 0,
            numeralThousandsGroupStyle: 'thousand'
          },
          placeholder: ""
        };
        break;
      case "inputFEIN":
        settings = {
          settings: {
            blocks: [2, 7],
            numericOnly: true
          },
          placeholder: "XX XXXXXXX"
        };
        break;
      default:
        settings = undefined;
    }
    return settings;
  }

  return (
    <Cleave
      id={name}
      placeholder={initSettings(type).placeholder}
      options={{ ...initSettings(type).settings }}
      onChange={onChange}
      onBlur={() => setFieldTouched(name, true)}
      disabled={isdisabled}
      value={formikValue}
    />
  );
}
export default InputComponentFormatted;

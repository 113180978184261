import React from "react";
import { Link } from "react-router-dom";
import BasicInfo from "../Sections/PlansAndWaivers/BasicInfo";
import SdvobInfo from "../Sections/PlansAndWaivers/SdvobInfo";
import Plan from "../Sections/PlansAndWaivers/Plan";
import ContractorInfo from "../Sections/PlansAndWaivers/ContractorInfo";
import PrimeContractorInfo from "../Sections/PlansAndWaivers/LEGACY_PrimeContractorInfo";
import PlanHistory from "../Sections/PlansAndWaivers/PlanHistory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { roles, loadUserRole } from "../../../util/userHelper";

const PlansAndWaiversBusiness = (props) => {
  const { contract, isVersion, planVersion, awardedPlan, procurement, procurementId } = props;
  const planToLoad = planVersion ? planVersion : awardedPlan;
  const { history } = planToLoad || [];
  const version = planToLoad?.version ? planToLoad?.version : planToLoad?.revisionCount;
  const canRevise = loadUserRole() === roles.Business && (planToLoad?.awardStatus === "Awarded" || planToLoad?.status === "Deficient");

  let planSections = [
    {
      id: "sdvob-info",
      component: SdvobInfo,
      heading: "Sdvob Info",
    },
    {
      id: "plan-details",
      component: Plan,
      heading: "Plan Details",
      props: {
        version: version,
        procurement: procurement,
      },
    },
    {
      id: "contractor-info",
      component: ContractorInfo,
      heading: "Contractor Information",
      props: {
        initialValues: {},
        contract: contract,
      },
    },
  ];

  if (contract) {
    planSections.splice(1, 0, {
      id: "basic-info",
      component: BasicInfo,
      heading: "Basic Info",
    });
  }

  if (isVersion !== true && ![roles.DivisionAdmin, roles.DivisionUser].includes(loadUserRole())) {
    planSections.push({
      id: "plan-history",
      component: PlanHistory,
      // heading: "Plan History",
      props: {
        history: history,
      },
    });
  }

  return (
    <section id="plan-waiver-wrapper">
      <div id="plans-waivers">
        <h2>
          Utilization Plan <span className="font-weight-normal">for {procurement?.description}</span>
        </h2>

        {["Denied", "Deficient"].includes(planToLoad?.status) && !isVersion && canRevise ? (
          <div className="alert alert-primary">
            <h3>Plan has been marked {planToLoad?.status}</h3>
            <p>{planToLoad?.messages?.length ? planToLoad?.messages[0].content : ""}</p>

            {canRevise ? (
              <Link to={`/utilization-plans/${planToLoad.id}/submit`} className="font-weight-bold">
                Create Revision <FontAwesomeIcon className="mt-1" icon={["fa", "angle-right"]} size="sm" />
              </Link>
            ) : null}
          </div>
        ) : null}

        {planSections.map((item) => {
          const Section = item.component;

          return (
            <div key={`plan-${item.id}`} className="contract-section mb-4">
              <p className="text-primary text-uppercase">{item.heading}</p>
              <Section
                procurement={procurement}
                procurementId={procurementId}
                contract={contract}
                planToLoad={planToLoad}
                awardedPlan={awardedPlan}
                heading={item.heading}
                id={item.id}
                {...item.props}
              />
              {/* <hr className="my-4" /> */}
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default PlansAndWaiversBusiness;

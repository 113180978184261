import React from "react";
import { getPercentage, outputMoney, outputPercentage } from "../../util/helpers";

const ProgressBar = (props) => {
  const { current, total, currentDesc, totalDesc, type, title, basedOnRemaining } = props;
  const completionPercentage = current && total ? getPercentage(current, total) : 0;
  const finalValue = basedOnRemaining ? total - current : total;

  return (
    <div className="progress-bar-wrapper">
      <p className="text-gray">{title}</p>
      <div className="progress-bar-wrapper">
        <div className="position-relative mb-2 overflow-hidden">
          <div className="progress-bar bg-primary-light pt-4 w-100 position-absolute"></div>
          <div className="stages pt-4 bg-success m1-4 position-relative" style={{ width: completionPercentage + "%" }}></div>
        </div>
        <div className="progress-bar-details">
          <div className="d-flex flex-row justify-content-between">
            <div className="">
              <p className="small">
                {currentDesc}
                <br />
                {["dollars", "percentage"].includes(type) ? (
                  <span className="text-success">
                    {outputMoney(current)} ({outputPercentage(completionPercentage / 100)})
                  </span>
                ) : null}
                {type === "time" ? <span className="text-success">{current?.toLocaleString()} days</span> : null}
              </p>
            </div>
            <div className="">
              <p className="small text-right">
                {totalDesc}
                <br />
                {type === "dollars" ? (
                  <span className={completionPercentage >= 100 ? "text-success" : "text-primary"}>
                    {completionPercentage >= 100 && "percentage" === type ? outputMoney(0) : outputMoney(finalValue)}
                  </span>
                ) : null}
                {type === "time" ? <span className="text-primary">{finalValue?.toLocaleString()} days</span> : null}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;

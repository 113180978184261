import React, { useState, useEffect } from "react";
import { FastField } from "formik";
import { Modal } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { markNotificationsRead, markNotificationsUnread, getNotifications } from "../../util/api";
import { getLocalTime } from "../../util/helpers";
import { withFormikFormComponent } from "../Forms/FormikForm";
import FilterTableForm from "../Forms/Input/FilterTableForm";

const NotificationListInternal = (props) => {
  const { userInfo } = props;
  const { notificationId } = useParams();
  const [notifications, setNotifications] = useState(null);
  const notification = notifications && notifications?.length && notificationId ? notifications.find((item) => item.id === notificationId) : null;
  const [modalStatus, setModalStatus] = useState(notificationId ? true : false);
  const [notificationsUpdateCount, setNotificationsUpdateCount] = useState(0);
  const history = useHistory();

  const setNotificationStatus = async (values) => {
    console.log(values);
    const { notificationIds } = values;
    await markNotificationsRead(notificationIds);
    setNotificationsUpdateCount(notificationsUpdateCount + 1);
  };

  useEffect(() => {
    const fetchNoticiations = async () => {
      const resObj = await getNotifications();
      setNotifications(resObj.data.notifications);
    };
    fetchNoticiations();
  }, [modalStatus, notificationsUpdateCount]);

  const FilterListConfig = {
    header: {},
  };

  const initialItems = notifications
    ?.sort((a, b) => new Date(b.timeStamp) - new Date(a.timeStamp))
    ?.map((item) => {
      // const initialItems = notifications?.map((item) => {
      return {
        ...item,
        // readStatus: item.read ? <FontAwesomeIcon icon={["fal", "check-square"]} className="mx-2" size="1x" className="text-secondary" /> : <FontAwesomeIcon icon={["fal", "square"]} className="mx-2" size="1x" className="text-secondary" />,
        // readStatus: item.read ? <FontAwesomeIcon icon={["fal", "check-square"]} className="mx-2" size="1x" className="text-secondary" /> : <FontAwesomeIcon icon={["fal", "square"]} className="mx-2" size="1x" className="text-secondary" />,
        selection: (
          <label htmlFor="notificationIds" className="">
            <span className="sr-only">Read?</span>
            <FastField type="checkbox" name={`notificationIds`} label="Read?" value={item.id} />
          </label>
        ),
        headerLink: (
          <p className={item.read ? "" : "font-weight-bold"}>
            <Link to={`/notifications/${item.id}`}>{item.header}</Link>
            <br />
            <span dangerouslySetInnerHTML={{ __html: item?.body }}></span>
          </p>
        ),
        date: getLocalTime(item.timeStamp),
      };
    });

  const Columns = [
    {
      Header: "Read?",
      accessor: "selection", // accessor is the "key" in the data
      disableSortBy: true,
      filterCode: "selection",
      totalWidth: 50,
    },
    {
      Header: "Message",
      accessor: "headerLink",
      defaultCanSort: true,
      filterCode: "header",
      sortType: "basic",
    },
    {
      Header: "Date Received",
      accessor: "date",
      defaultSorted: true,
      defaultCanSort: true,
      filterCode: "date",
      sortType: "basic",
    },
  ];

  return (
    <section id="notifications">
      <h2>Notifications</h2>
      <div>
        <p>
          Hi {userInfo?.user?.firstName},<br />
          Here are your notifications.
        </p>
      </div>
      {initialItems ? (
        <FilterTableForm
          tableName="notification-list"
          Columns={Columns}
          FilterListConfig={FilterListConfig}
          tableItems={initialItems}
          useDebugModal={false}
          action={setNotificationStatus}
          actionTerm={"Mark Read"}
        />
      ) : (
        <h3>There are currently no notifications currently waiting for you</h3>
      )}

      <Modal
        show={modalStatus}
        onHide={async () => {
          await markNotificationsRead([notificationId]);
          setNotificationsUpdateCount(notificationsUpdateCount + 1);
          history.push(`/notifications`);
        }}
        size="lg"
      >
        <Modal.Header className="bg-light" closeButton></Modal.Header>
        <Modal.Body>
          <p>
            <strong>{notification?.header}</strong>
          </p>
          {notification ? <p className="text-break" dangerouslySetInnerHTML={{ __html: notification?.body }}></p> : null}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="faux-link"
            type="button"
            onClick={async () => {
              await markNotificationsUnread([notificationId]);
              setNotificationsUpdateCount(notificationsUpdateCount + 1);
              history.push("/notifications");
            }}
          >
            Mark Unread
          </button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

const NotificationList = withFormikFormComponent(NotificationListInternal);
export default NotificationList;

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withFormikFormComponent } from "../../../Forms/FormikForm";
import { updateActivityReportStatus, updateGoalPlanStatus, updateNarrativeStatementStatus, requestReportExtension } from "../../../../util/api";
import ReportStatusUpdateForm from "./ReportStatusUpdateForm";
import ReportExtensionRequestForm from "./ReportExtensionRequestForm";
import { reportTypes } from "../../../../util/typelistHelper";
import { getLocalDate } from "../../../../util/helpers";

const ReportStatusUpdateWrapperInternal = ({ report, reportType, reportId, updateCount, setUpdateCount }) => {
  const [extensionSubmitting, setExtensionSubmitting] = useState(false);

  const handleExtensionRequest = async (values) => {
    console.log(values);
    const { extensionRequestedReason } = values;
    setExtensionSubmitting(true);
    const reportTypeFormatted = reportTypes[reportType];
    const resObj = await requestReportExtension(reportTypeFormatted, reportId, extensionRequestedReason);
    setExtensionSubmitting(false);
    setUpdateCount(updateCount + 1);
  };

  const handleSubmit = async (values) => {
    const { status, message } = values;

    let submitObj;
    switch (status) {
      case "accept":
        submitObj = { id: reportId };
        break;
      case "reject":
        submitObj = { id: reportId, message: message };
        break;
      default:
        console.log("report type error");
        break;
    }

    switch (reportType) {
      case "activity-report":
        await updateActivityReportStatus(submitObj, status);
        break;
      case "goal-plan":
        await updateGoalPlanStatus(submitObj, status);
        break;
      case "narrative-statement":
        await updateNarrativeStatementStatus(submitObj, status);
        break;
      default:
        console.log("report type error");
        break;
    }

    setUpdateCount(updateCount + 1);
  };

  return (
    <div>
      {["Approved", "Deficient", "Created"].includes(report?.status) ? (
        <h3>Status: {report?.status}</h3>
      ) : (
        <ReportStatusUpdateForm reportType={reportType} reportId={reportId} routeInitialValues={{ id: report?.id, status: report?.status, message: "" }} onSubmit={handleSubmit} />
      )}

      {/* Only show extension information/options if it hasn't been submitted yet */}
      {["Created"].includes(report?.status) ? (
        <>
          <hr />
          {report?.extensionRequested ? (
            <>
              <p>
                Extension recorded by {report?.extensionRequestedBy?.name} on {getLocalDate(report?.extensionRequested)}
              </p>
              <p>Extension request reason: {report?.extensionRequestedReason}</p>
            </>
          ) : extensionSubmitting ? (
            <FontAwesomeIcon icon={"spinner"} className="fa-spin" />
          ) : (
            <>
              <ReportExtensionRequestForm
                reportType={reportType}
                reportId={reportId}
                routeInitialValues={{ id: report?.id, status: report?.status, message: "", extensionRequestedReason: report?.extensionRequestedReason }}
                onSubmit={handleExtensionRequest}
              />
            </>
          )}
        </>
      ) : null}
    </div>
  );
};

const ReportStatusUpdateWrapper = withFormikFormComponent(ReportStatusUpdateWrapperInternal);
export default ReportStatusUpdateWrapper;

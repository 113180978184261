import { FastField, Form } from "formik";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { removeBusinessOwner } from "../../util/api";
import { withFormikFormComponent } from "../Forms/FormikForm";
import InputComponent from "../Forms/Input/InputComponent";

const BusinessOwnerFormInternal = ({ business, owner, formikBag, disabled, businessHasUpdated, setBusinessHasUpdated }) => {
  const { values } = formikBag;

  const handleSubmit = async () => {
    const { reason } = values;
    await removeBusinessOwner(business.id, reason, owner.userId);
    setBusinessHasUpdated(businessHasUpdated + 1);
  };

  return (
    <>
      <hr />
      <Row>
        <Col xs={12}>
          <h4>{owner?.name}</h4>
        </Col>
        <Col xs={9}>
          <Form>
            <FastField type="textarea" id="remove-reason" name="reason" component={InputComponent} label="Reason for removal" />
          </Form>
        </Col>
        <Col xs={3} className="d-flex align-items-center justify-content-center">
          <Button className="btn-sm px-3" onClick={() => handleSubmit()} disabled={disabled}>
            <span className="d-block">Remove</span>
            <span className="d-block">Owner</span>
          </Button>
        </Col>
      </Row>
    </>
  );
};

const BusinessOwnerForm = withFormikFormComponent(BusinessOwnerFormInternal);

export default BusinessOwnerForm;

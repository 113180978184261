import React from "react";

const NetworkError = () => {
  return (
    <div className="container mt-3">
      <p>**********Lorem ipsum this is placeholder text*********** We are currently experiencing network connectivity issues.</p>
    </div>
  );
};

export default NetworkError;

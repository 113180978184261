import React, { useEffect, useState, useCallback } from "react";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import InputComponent from "../Forms/Input/InputComponent";
import { withFormikFormComponent } from "../Forms/FormikForm";
import { recertifyBusiness } from "../../util/api";
import { getUtcDate } from "../../util/helpers";
import { Form, FastField } from "formik";
import * as Yup from "yup";

const manualRecertificationSchema = Yup.object().shape({
  effectiveDate: Yup.date().required('Effective Date is required'),
  sendEmail: Yup.bool()
});

const BusinessDetailRecertificationCardInternal = (props) => {
  const {
    formikBag,
    minDate,
    maxDate,
    business
  } = props;

  const { values, setFieldValue, isValid } = formikBag;
  const [isSaving, setIsSaving] = useState(false);

  async function handleRecertifyBusiness() {
    try {
      setIsSaving(true);
      
      await recertifyBusiness(business.id, values.effectiveDate, values.sendEmail);
      window.location.reload();
    } catch (e) {
      console.log(e);
    } finally {
      setIsSaving(false);
    }
  }

  return <Card className="mb-3">
          <Card.Header className="bg-primary">
            Manual Recertification
          </Card.Header>
          <Card.Body>
            {
              minDate.getTime() === maxDate.getTime()
               ? <p>
                  Recertifying a business manually will bypass the need for an application, the effective date for certification will be {getUtcDate(minDate)}.
                </p>
               : <p>
                  Recertifying a business manually will bypass the need for an application, the effective date can only be backdated as far back as the original certification expiration date of {getUtcDate(minDate)} and can only be as recent as today's date of {getUtcDate(maxDate)}. 
                </p>
            }
            <div hidden={isSaving}>
              <Form>
                <div hidden={minDate.getTime() === maxDate.getTime()}>
                  <FastField
                    type="datepicker"
                    label="Effective Date"
                    name={`effectiveDate`}
                    maxDate={maxDate}
                    minDate={minDate}
                    monthPlaceholder={"MM"}
                    dayPlaceholder={"DD"}
                    yearPlaceholder={"YYYY"}
                    component={InputComponent}
                    required
                  />
                </div>
                <FastField
                  name={'sendEmail'}
                  component={InputComponent}
                  type="checkbox"
                  options={[
                    [true, "Send Email"]
                  ]}
                  required={false}
                />
              </Form>
            </div>
            <FontAwesomeIcon
              hidden={!isSaving}
              icon={"spinner"}
              className="fa-spin"
            />
            <div class="pt-4">
              <Button
                variant="primary"
                onClick={handleRecertifyBusiness}
                disabled={isSaving || !isValid}
              >
                Recertify Business
              </Button>
            </div>
          </Card.Body>
        </Card>;
};

const BusinessDetailRecertificationCard = withFormikFormComponent(BusinessDetailRecertificationCardInternal, manualRecertificationSchema);
export default BusinessDetailRecertificationCard;
import React from "react";
import "./PlanCalculator.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgressBar from "../../../Charting/ProgressBar";
import { outputMoney } from "../../../../util/helpers";

const PlanCalculator = (props) => {
  const { realValue, realGoal, toggleCalculator, active, agencyGoal, values } = props;
  const { goal, value } = values;
  const statusColor = realGoal >= agencyGoal ? "success" : "danger";
  // console.log(realValue, realGoal);

  return (
    <div id="plan-calculator" className={`plan-calculator bg-light position-fixed py-3 ${active ? "active" : ""}`}>
      <div className="position-relative container-fluid">
        <div className="toggle" onClick={toggleCalculator}>
          {" "}
          <FontAwesomeIcon className={`text-secondary ${active ? "rotate-180" : ""}`} icon={"angle-up"} size="lg" />
        </div>
        <div className="container">
          <h2>Plan Calculator</h2>
          <div className="row ">
            <div className="col-md-7">
              <div className="row">
                <div className="col-md-4">
                  <div className="stat">
                    <div class="row">
                      <div class="col-3 col-md-12">
                        <p className="font-weight-light text-success">{outputMoney(value)}</p>
                      </div>
                      <div class="col-9 col-md-12">
                        <p className="font-weight-bold">Total Value of Contract</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="stat">
                    <div class="row">
                      <div class="col-3 col-md-12">
                        <p className="font-weight-light text-success">{agencyGoal}%</p>
                      </div>
                      <div class="col-9 col-md-12">
                        <p className="font-weight-bold">Plan Goal Percentage</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="stat">
                    <div class="row">
                      <div class="col-3 col-md-12">
                        <p className={`font-weight-light  text-${statusColor}`}>{outputMoney(realValue)}</p>
                      </div>
                      <div class="col-9 col-md-12">
                        <p className="font-weight-bold">Total Assessed Value to SDVOBs</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="row">
                {/* <div className="col-md-6">
                  <div class="row">
                  <div className="stat">
                  <div class="col-3 col-md-12">
                    <p className={`font-weight-light  text-${statusColor}`}>${realValue}</p>
                    </div>
                    <div class="col-9 col-md-12">
                    </div><p className="font-weight-bold">Current Plan Dollar Total</p>

                  iv>
                  </div>
                  </div>
                </div> */}
                <div className="col-md-6">
                  <div className="stat">
                    <div class="row">
                      <div class="col-3 col-md-12">
                        <p className={`font-weight-light  text-${statusColor}`}>{realGoal}%</p>
                      </div>
                      <div class="col-9 col-md-12">
                        <p className="font-weight-bold">Current Plan Percentage Goal</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-none d-md-block">
            <ProgressBar
              current={realValue}
              currentDesc="Goal Amount Designated"
              total={(value / 100) * goal}
              totalDesc="Goal Amount Undeclared"
              type="dollars"
              basedOnRemaining={true}
              title="SDVOB Goals Designated vs Undeclared"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanCalculator;

import React from "react";
import { getUserId } from "../../util/userHelper"
import { formatAddress } from "../../util/helpers"

const Profile = (props) => {
  const { activeBusiness } = props;
  console.log(activeBusiness);
  const mainContact = activeBusiness.owners ? activeBusiness?.owners.find(item => item.userId === getUserId()) : null;
  const mainAddress = activeBusiness?.addresses ? activeBusiness?.addresses.find(item => item.type === "Home") : null;
  const secondaryContacts = activeBusiness?.owners ? activeBusiness?.owners.filter(item => item.userId !== getUserId()) : [];

  return (
    <section>
      <div className="primary-info">
        <h2>User Profile</h2>
        <h3>Main Contact</h3>

        <p>
          Name: {mainContact?.firstName} {mainContact?.lastName}<br />
          Address: {formatAddress(mainAddress)}
          Phone: {mainContact?.phoneNumber}<br />
        </p>
      </div>

      {secondaryContacts?.length ?
        <div className="secondary-info">
          <h2>Secondary Contacts</h2>
          {secondaryContacts.map((item, index) => {
            return (
              <div className="owner-profile" key={`owner-${index}`}>
                <p>
                  Name: <br />
                  Address: <br />
                  Phone: <br />
                </p>
              </div>
            )
          })}
        </div>
        : null}
    </section>
  )
}

export default Profile;

import React from "react";
import AgencyAdminPortal from "./AgencyAdminPortal";
import BusinessPortal from "./BusinessPortal";
import DivisionPortal from "./DivisionPortal";
import Application404 from "../../pages/Application404";
import "./Portal.scss";
import { loadUserRole, roles, getOrgType, noRoleOrgType } from "../../util/userHelper";

const PortalHome = (props) => {
  const { userInfo, activeOrgId, notifications } = props;
  const userRole = loadUserRole();

  let PortalComponent;
  switch (userRole) {
    case roles.AgencyAdmin:
      PortalComponent = AgencyAdminPortal;
      break;
    case roles.AgencyUser:
      PortalComponent = AgencyAdminPortal;
      break;
    case roles.Business:
      PortalComponent = BusinessPortal;
      break;
    case roles.DivisionUser:
      PortalComponent = DivisionPortal;
      break;
    case roles.DivisionAdmin:
      PortalComponent = DivisionPortal;
      break;
    default:
      PortalComponent = Application404;
      break;
  }

  const showAccountAdministrationError = getOrgType(userRole) === noRoleOrgType;

  return (
    <>
      {
        showAccountAdministrationError
          ? <div class="mb-2 portal-error-message">
              <span>
                Your account is improperly configured and therefore could not be authenticated, please contact the Division of Service-Disabled Veterans' Business Development by either calling
              </span>
              <a class="pl-2 pr-2" href="tel:+15184742015">
                (518) 474-2015
              </a>
              <span>
                or emailing
              </span>
              <a class="pl-2 pr-2" href="mailto:veteransdevelopment@ogs.ny.gov">
                veteransdevelopment@ogs.ny.gov
              </a>
            </div>
          : <></>
      }
      {PortalComponent && (
        <section className="portal-wrapper">
          <PortalComponent notifications={notifications} userInfo={userInfo} activeOrgId={activeOrgId} />
        </section>
      )}
    </>
  );
};

export default PortalHome;

import React, { useEffect, useState } from "react";
import { useParams } from 'react-router';
import { getActivityLogs, getApplication } from "../../../util/api";
import { componentsFromRoutes } from "../../../util/helpers";
import ApplicationActivityLogs from "./ApplicationActivityLogs";
import ApplicationNav from "./ApplicationNav";
import ApplicationReview from "./ApplicationReview";

function ApplicationOverviewDivision(props) {
  // console.log('overview is loaded');
  const { applicationId } = useParams();
  const [application, setApplication] = useState({});
  const { phase } = application;
  const [changeCount, setChangeCount] = useState(0);
  const [activityLogs, setActivityLogs] = useState([]);

  useEffect(() => {
    // console.log(phase, changeCount);
    async function fetchData() {
      const myApplication = await getApplication(applicationId);
      setApplication(myApplication);
      // console.log(myApplication)

      const myInitialItems = application ? await getActivityLogs('application', applicationId) : [];
      setActivityLogs(myInitialItems?.activityLogs);
    }
    fetchData();
    // console.log(phase, changeCount);
  }, [changeCount]);

  const DivisionActivityRoutes = {
    routes: [
      {
        path: "/application/:applicationId/review",
        title: "Activity Report",
        exact: true,
        component: ApplicationReview,
      },
      {
        path: "/application/:applicationId/activity-log",
        title: "Activity Logs",
        exact: true,
        component: ApplicationActivityLogs,
      },
    ],
    globalProps: {
      application,
      activityLogs,
      changeCount,
      setChangeCount
    }
  };

  return (
    <div>
      <ApplicationNav />
      {componentsFromRoutes(DivisionActivityRoutes)}
    </div>
  )
}

export default ApplicationOverviewDivision;


import React from "react";
import { Link, matchPath, useLocation, useParams } from "react-router-dom";

const ChangeRequestsDisplay = ({ formikBag }) => {
  const { values } = formikBag;
  const { applicationId } = useParams();
  const { changes } = values;
  const location = useLocation().pathname.replace(/["']/g, "");
  const isAttestation = matchPath(location, {
    path: '/application-attest/:applicationId',
    // exact: true,
    // strict: false
  });

  return (
    <>
      {changes?.length && values?.status !== "Created" ?
        <>
          <div className="row">
            <p className="col-md-8">The Division has found issues or deficiencies within your application, and has requested the following changes listed below. Please address these changes, then click the final "Review &amp; Submit" tab to confirm your updates and send your application back to the division for review</p>
          </div>
          <div className="bg-light p-4 mb-4">
            <h3 className="font-weight-bold mb-3">Change Requests</h3>
            <div className="bg-white p-4">
              {changes.map((item, index) => {
                const { requester } = item;
                if (index === 0) {
                  return (
                    <div key={`cr-${index}`}>
                      <h3 className="h4">Change Request # {index + 1} ({requester.firstName} {requester.lastName})</h3>
                      <p>{item.description}</p>
                    </div>
                  )
                }
              })}
            </div>
            {!isAttestation ?
              <div className="mt-3">
                <p>To revise your owner documents, head to your <Link to={`/application-attest/${applicationId}`}>attestation page</Link>.</p>
              </div>
              : null}
          </div>
        </>
        : null}
    </>
  )
}

export default ChangeRequestsDisplay;

import React, { useEffect, useState, useCallback } from "react";
import { Modal } from "react-bootstrap";
import InputComponent from "../Forms/Input/InputComponent";
import Button from "react-bootstrap/Button";
import { saveReport, updateReport } from "../../util/api";
import { getUserId } from "../../util/userHelper";
import { withFormikFormComponent } from "../Forms/FormikForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, FastField } from "formik";
import * as Yup from "yup";

let otherReportNames = [];

const saveModalSchema = Yup.object().shape({
  reportName: Yup.string()
                  .min(2, 'Report Name must have at least 2 characters')
                  .required('Report Name is required')
                  .test('Unqiue Name', 'Report Name must be unique', function (value) {
                    return otherReportNames.indexOf(value) === -1;
                  })
});

const SaveReportModalInternal = (props) => {
  const {
    formikBag,
    show,
    reportId,
    reportType,
    reportName,
    reportQuery,
    reportColumns,
    onHide,
    agencyId
  } = props;

  const { values, setFieldValue, isValid } = formikBag;
  const [isSaving, setIsSaving] = useState(false);
  const [reportNameInput, setEeportNameInput] = useState(undefined);

  const modalBodyRef = useCallback((element) => {
    if (element && typeof element.querySelector === 'function') {
      setEeportNameInput(element.querySelector('input#reportName') || undefined);
    } else {
      setEeportNameInput(undefined);
    }
  });

  useEffect(() => {
    otherReportNames = props.userDefinedReports.map((userDefinedReport) => userDefinedReport.name).filter((userDefinedReportName) => userDefinedReportName !== props.reportName);
  }, [props.userDefinedReports, props.reportName]);

  useEffect(() => {
    if (props.show && reportNameInput) {
      reportNameInput.focus();
    }
  }, [props.show, reportNameInput])

  useEffect(() => {
    setFieldValue('reportName', reportName);
  }, [props.reportName]);

  const saveNewReport = async () => {
    if (!isValid) {
      // TODO
    }

    setIsSaving(true);

    try {
      const request = {
        agencyId,
        createdBy: getUserId(),
        name: values.reportName,
        reportQuery: JSON.stringify(reportQuery),
        reportColumns: JSON.stringify(reportColumns),
        reportType,
        publicReport: values.reportPublic
      };

      await saveReport(request);
      await onHide();
    } catch (e) {
      // TODO
      console.log(e);
    } finally {
      setIsSaving(false);
    }
  };

  const updateExistingReport = async () => {
    if (!isValid) {
      // TODO
    }

    setIsSaving(true);

    try {
      const request = {
        createdBy: getUserId(),
        name: values.reportName,
        reportQuery: JSON.stringify(reportQuery),
        reportColumns: JSON.stringify(reportColumns),
        publicReport: values.reportPublic
      };

      await updateReport(reportId, request);
      await onHide();
    } catch (e) {
      // TODO

      console.log(e);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Save Report
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div hidden={isSaving} ref={modalBodyRef}>
          <Form>

            <FastField
              name={'reportName'}
              component={InputComponent}
              label={"Report Name"}
              type="text"
            />
            <FastField
              name={'reportPublic'}
              component={InputComponent}
              label={"Public Report"}
              type="checkbox"
              options={[
                [true, "Query available to other users"],
              ]}
            />
          </Form>
        </div>
        <FontAwesomeIcon
          hidden={!isSaving}
          icon={"spinner"}
          className="fa-spin"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={reportId ? 'secondary' : 'primary'}
          onClick={saveNewReport}
          disabled={isSaving || !isValid || values.reportName === reportName}
        >
          {
            reportId
              ? 'Save As New Report'
              : 'Save'
          }
        </Button>
        <Button
          variant="primary"
          onClick={updateExistingReport}
          hidden={!reportId}
          disabled={isSaving || !isValid}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const SaveReportModal = withFormikFormComponent(SaveReportModalInternal, saveModalSchema);
export default SaveReportModal;

import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatPhoneNumber, formatAddress, outputMoney } from "../../../../util/helpers";

const ContractorInfo = (props) => {
  const { planToLoad, procurement, contract } = props;
  const { subcontracts } = planToLoad || [];
  const isTerm = procurement?.contractTerm === "Term";

  return (
    <div>
      <div className="mb-4">
        <p className="font-weight-bold">Prime Contractor Information</p>

        <div className="row mb-2">
          <div className="col-sm-5">Name</div>
          <div className="col-sm-7">{contract?.primeContractor?.name}</div>
        </div>

        <div className="row mb-2">
          <div className="col-sm-5">Contact Number</div>
          <div className="col-sm-7">{formatPhoneNumber(contract?.primeContractor?.contactNumber)}</div>
        </div>

        <div className="row mb-2">
          <div className="col-sm-5">Address</div>
          <div className="col-sm-7">{formatAddress(contract?.primeContractor?.addresses[0])}</div>
        </div>

        <Link to={`/business-search/${contract?.primeContractor?.id}`} className="text-secondary">
          See Full Profile <FontAwesomeIcon className="mr-2" icon={"angle-right"} size="lg" />
        </Link>
      </div>
      {subcontracts?.length
        ? subcontracts.map((subcontract, count) => {
            const valueToOutput = !isTerm ? outputMoney(subcontract?.value) : `${subcontract?.goal}%`;
            return (
              <div key={count} className="mb-4">
                <p className="font-weight-bold">Subcontractor Information</p>

                <div className="row mb-2">
                  <div className="col-sm-5">Name</div>
                  <div className="col-sm-7">{subcontract?.contractor?.name}</div>
                </div>

                <div className="row mb-2">
                  <div className="col-sm-5">Federal ID No.</div>
                  <div className="col-sm-7">{subcontract?.contractor?.federalId}</div>
                </div>

                <div className="row mb-2">
                  <div className="col-sm-5">Address</div>
                  <div className="col-sm-7">{formatAddress(subcontract?.contractor?.addresses[0])}</div>
                </div>

                <div className="row mb-2">
                  <div className="col-sm-5">Telephone Number</div>
                  <div className="col-sm-7">{formatPhoneNumber(subcontract?.contractor?.phone)}</div>
                </div>

                <div className="row mb-2">
                  <div className="col-sm-5">Email</div>
                  <div className="col-sm-7">{subcontract?.contractor?.email}</div>
                </div>

                <div className="row mb-2">
                  <div className="col-sm-5">Description of Services</div>
                  <div className="col-sm-7">{subcontract?.contractor?.description}</div>
                </div>

                <div className="row mb-2">
                  <div className="col-sm-5">{isTerm ? "Goal" : "Value"}</div>
                  <div className="col-sm-7">{valueToOutput}</div>
                </div>

                <Link to={`/business-search/${subcontract.contractor.id}`} target="_blank" className="text-secondary">
                  See Full Profile <FontAwesomeIcon className="mr-2" icon={"angle-right"} size="lg" />
                </Link>
              </div>
            );
          })
        : null}
    </div>
  );
};

export default ContractorInfo;

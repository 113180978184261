import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from 'react-bootstrap';
import { useParams } from 'react-router';
import ApplicationReviewComponent from '../../BusinessApplication/tabs/review/ApplicationReviewComponent';
import ApplicationChanges from "./ApplicationChanges"
import ApplicationReviewForm from "./ApplicationReviewForm"
import DebugModal from '../../Debug/DebugModal';

function ApplicationReview(props) {
  // console.log('review is loaded');
  const { application, activityLogs, formikBag, changeCount, setChangeCount } = props;
  const { applicationId } = useParams();
  const [activityModalShown, setActivityModalShown] = useState(false);

  //Remove first submission instance, find next instance, grab all saves in between
  const indexOfChangeRequest = activityLogs?.findIndex(item => item?.activity?.includes("Change request"));
  const sinceChangeRequest = activityLogs?.slice(0, indexOfChangeRequest)
  const savedLogs = sinceChangeRequest?.filter(item => ["Application Saved", "Application Owner Attested", "Application Updated"].includes(item.activity));
  // savedLogs?.reverse();
  let mergedDetail = [];
  if (savedLogs?.length) {
    mergedDetail = [...savedLogs[0].activityDetail];

    savedLogs.map(log => {
      const { activityDetail } = log;
      activityDetail.map(detail => {
        const detailName = detail.FieldName;
        const indexOfProperty = mergedDetail?.findIndex(mergedDetailItem => mergedDetailItem.FieldName == detailName);

        if (mergedDetail.length && indexOfProperty !== -1) {
          mergedDetail[indexOfProperty] = {
            ...mergedDetail[indexOfProperty],
            OldValue: detail.OldValue

          };
        } else {
          mergedDetail.push(detail);
        }
      })
    })
  }

  return (
    <div>
      <DebugModal formikBag={formikBag} name="Child Formik Bag" />

      <div className="field-group">

        <div className="row">
          <div className="col-7">
            <h2>Application Review for {application?.legalName}</h2>
            <div className="bg-light p-4 mb-3">
              {mergedDetail.length ? <button onClick={() => { setActivityModalShown(true) }} type="button" className="faux-link"><FontAwesomeIcon className="mr-2" icon={'info-circle'} size="lg" />See changes</button> : null}
              <Modal show={activityModalShown} size="xl" onHide={() => { setActivityModalShown(false) }}>
                <Modal.Header closeButton>
                  <Modal.Title><h2>Application Changes</h2></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <ApplicationChanges mergedDetail={mergedDetail} />
                </Modal.Body>
              </Modal>
              <ApplicationReviewComponent {...application}></ApplicationReviewComponent>
            </div>
          </div>
          <div className="col-5">
            <ApplicationReviewForm application={application} applicationId={applicationId} changeCount={changeCount} setChangeCount={setChangeCount} routeInitialValues={application} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default ApplicationReview;


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useHistory, useParams, useLocation, Link } from "react-router-dom";
import { getBusiness } from "../../util/api";
import { componentsFromRoutes, hasActiveCertification, outputApplicationStatus } from "../../util/helpers";
import "./BusinessDetail.scss";
import BusinessDetailCards from "./BusinessDetailCards";
import BusinessDetailNav from "./BusinessDetailNav";
import BusinessEdit from "./BusinessEdit";
import { roles, loadUserRole, loadActiveOrgId } from "../../util/userHelper";

const BusinessDetailOverview = () => {
  let { businessId } = useParams();
  const history = useHistory();
  const [business, setBusiness] = useState({});
  const [businessHasUpdated, setBusinessHasUpdated] = useState(0);
  const [userCanEdit, setUserCanEdit] = useState(false);
  const location = useLocation()?.pathname?.includes("business-search") ? "/business-search" : "/maintenance-timers";
  // console.log(location);

  useEffect(() => {
    const getAndSetBusiness = async () => await getBusiness(businessId).then((res) => setBusiness(res.data));
    getAndSetBusiness();
  }, [businessId, businessHasUpdated]);

  useEffect(() => {
    setUserCanEdit((loadUserRole() === roles.DivisionAdmin && business?.isCertifiedSdvob) || (loadUserRole() === roles.Business && businessId === loadActiveOrgId()));
  }, [business]);

  const BusinessDetailRoutes = {
    routes: [
      {
        path: "/business/:businessId/edit",
        title: "Edit Business",
        exact: true,
        component: BusinessEdit,
        props: { business, businessHasUpdated, setBusinessHasUpdated },
      },
      {
        path: "/business/:businessId",
        title: "View Business",
        exact: true,
        component: BusinessDetailCards,
        props: { business },
      },
      {
        path: "/business-search/:businessId",
        title: "View Business",
        exact: true,
        component: BusinessDetailCards,
        props: { business },
      },
    ],
  };

  return (
    <section id="business-details" className="page-section">
      <div className="">
        {userCanEdit ? (
          <BusinessDetailNav business={business} />
        ) : (
          <div className="mb-3">
            <Link to={location}>
              <FontAwesomeIcon className="mr-2" icon={"angle-left"} size="lg" /> Back to Directory Search
            </Link>
          </div>
        )}

        {business?.isActive || userCanEdit ? (
          <>
            <div id="business-detail-header" className="my-4 my-md-3">
              <h2>{business?.legalName}</h2>
              <h3>Certification Status: {outputApplicationStatus(business)}</h3>
            </div>

            {business?.isActive && (
              <>
                <hr />
                {componentsFromRoutes(BusinessDetailRoutes)}
              </>
            )}
          </>
        ) : // Now check that the business has actually loaded
        business?.id ? (
          <h2>This business is not currently active.</h2>
        ) : (
          <h2 className="loader loader-pulse">Loading Business Information.</h2>
        )}
      </div>
    </section>
  );
};
export default BusinessDetailOverview;

import React from "react";
import { useParams } from "react-router-dom";
import { withFormikFormComponent } from "../../Forms/FormikForm";
import PlanSummary from "../Sections/PlanSummary";
import Reporting from "../Sections/Reporting";
import FinancialProgress from "../Sections/FinancialProgress";
import Time from "../Sections/Time";
import { procurementCanHavePayments } from "../../../util/helpers";
// import Contact from "../Sections/Contact"
// import Description from "../Sections/Description"

const ContractDetailsViewBusiness = (props) => {
  const { procurementId } = useParams();
  const { contract, awardedPlan, procurement } = props;
  const { disbursementsToSDVOBs, disbursementsToPrime } = contract;

  let contractSections = [
    {
      id: "plan-summary",
      component: PlanSummary,
      heading: "Utilization Plan Summary",
    },
    {
      id: "financial-progress",
      component: FinancialProgress,
      heading: "Summary of Financial Progress",
    },
    {
      id: "time",
      component: Time,
      heading: "Time",
    },
    // {
    //   id: 'contact',
    //   component: Contact,
    //   heading: 'Address inquiries to',
    // },
    // {
    //   id: 'description',
    //   component: Description,
    //   heading: 'Contract Description',
    // }
  ];

  if (procurementCanHavePayments(procurement) && contract?.id) {
    contractSections.splice(1, 0, {
      id: "reporting",
      component: Reporting,
      heading: "Reporting",
    });
  }

  return (
    <section id="contract-details-wrapper">
      <div id="contract-details">
        {/* <h2>Procurement: <span className="font-weight-normal">{procurement?.description}</span><br />
          Contract: <span className="font-weight-normal">{contract?.contractCode} - {contract?.name}</span>
        </h2> */}

        {contractSections.map((item) => {
          const Section = item.component;

          return (
            <div key={item.id} className="contract-section mb-3">
              <p className="text-primary text-uppercase">{item?.heading}</p>
              {contract?.id ? (
                <Section
                  contract={contract}
                  procurement={procurement}
                  procurementId={procurementId}
                  heading={item?.heading}
                  id={item?.id}
                  awardedPlan={awardedPlan}
                  disbursementsToPrimeToUse={disbursementsToPrime}
                  disbursementsToSDVOBsToUse={disbursementsToSDVOBs}
                  disbursementsDesc="Accepted Payments to SDVOBs"
                />
              ) : null}
              <hr className="my-4" />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default ContractDetailsViewBusiness;

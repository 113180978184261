import { FastField, Form } from "formik";
import get from "lodash/get";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { schemas } from "../../util/errorHelper";
import { businessTypes, contractCategories, contractTerms, exclusionTypes } from "../../util/typelistHelper";
import DebugModal from "../Debug/DebugModal";
import { withFormikFormComponent } from "../Forms/FormikForm";
import InputComponent from "../Forms/Input/InputComponent";
import ReactiveButton from "../Forms/Input/ReactiveButton";
import DatePicker from "react-date-picker";
import { FormLabel } from "react-bootstrap";

const CreateProcurementsFormInternal = (props) => {
  const { formikBag } = props;
  const { values, setFieldValue, isValid, isSubmitting } = formikBag;

  useEffect(() => {
    if (values?.exclusionType === "ExemptExcluded") {
      setFieldValue("goal", 0);
    }
  }, [values.exclusionType]);

  return (
    <section id="procurement-submit">
      <DebugModal formikBag={formikBag} name="Formik Bag" />

      <Form>
        <div className="field-group row">
          {/* {agenciesOptions?.length > 1 ?
            <FastField wrapperClass="col-md-4" name={"agencyId"} component={InputComponent} label={"Select Agency"} options={[['', 'Select Agency'], ...agenciesOptions]} type="select"></FastField>
            : null} */}

          <FastField wrapperClass="col-md-4" name={"solicitationNumber"} component={InputComponent} label={"Solicitation Number"} type="text"></FastField>

          {values?.exclusionType !== "ExemptExcluded" && (
            <FastField
              wrapperClass="col-md-4"
              name={"goal"}
              component={InputComponent}
              label={"Contract SDVOB Goal"}
              type="number"
              min={0}
              max={100}
              isdisabled={values?.exclusionType === "ExemptExcluded"}
            ></FastField>
          )}

          <FastField wrapperClass="col-md-4" name={"contractNumber"} component={InputComponent} required={false} label={"Contract Number"} type="text"></FastField>

          <FastField wrapperClass="col-md-6" name={"contractTerm"} component={InputComponent} label={"Compliance Calculation Type"} type="select" options={contractTerms} />

          <FastField
            wrapperClass="col-md-6"
            name={"exclusionType"}
            component={InputComponent}
            required={false}
            label={"SDVOB Participation"}
            type="select"
            options={[["", "Choose SDVOB Participation"], ...exclusionTypes]}
          />

          <FastField
            wrapperClass="col-md-6"
            name={"contractCategory"}
            component={InputComponent}
            required={false}
            label={"Procurement Type"}
            type="select"
            options={[["", "Choose Procurement Type"], ...contractCategories]}
          />

          <FastField
            wrapperClass="col-md-6"
            name={"contractServiceType"}
            component={InputComponent}
            required={false}
            label={"Classification"}
            type="select"
            options={[["", "Choose Classification"], ...businessTypes]}
          />

          {values?.exclusionType !== "ExemptExcluded" && (
            <>
              <div name="start-date-picker"  className="col-md-6 mb-2">
                <label htmlFor="startDate">
                  Plan Submission Start Date <span class="required text-danger">*</span>
                </label>
                <DatePicker name={"startDate"} onChange={(value) => setFieldValue(`startDate`, value)} value={get(values, `startDate`)} maxDate={new Date()} required />
              </div>

              <div name="end-date-picker" className="col-md-6 mb-2">
                <label htmlFor="endDate">
                  Plan Submission End Date <span class="required text-danger">*</span>
                </label>
                <DatePicker name={"endDate"} onChange={(value) => setFieldValue(`endDate`, value)} value={get(values, `endDate`)} minDate={new Date()} required />
              </div>
            </>
          )}
          <FastField wrapperClass="col-12 py-3" name={"description"} component={InputComponent} label={"Description"} type="textarea"></FastField>
        </div>

        {!isValid ? (
          <h3>Please complete all required information</h3>
        ) : (
          <ReactiveButton type="submit" className="btn-md btn-block btn-secondary margin-auto" label="Create" processing={isSubmitting} />
        )}
      </Form>
    </section>
  );
};

const CreateProcurementsForm = withFormikFormComponent(CreateProcurementsFormInternal, schemas.procurementSchema);
export default CreateProcurementsForm;

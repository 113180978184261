import React from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { loadUserRole, roles } from "../../util/userHelper";
import UpdateBusinessInfo from "../Signup/UpdateBusinessInfo";
import BusinessEditOwnerForm from "./BusinessEditOwnerForm";
import BusinessAddUserForm from "./BusinessAddUserForm";
import { assignBusinessUser, removeBusinessUser } from "../../util/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BusinessEdit = (props) => {
  const { business, businessHasUpdated, setBusinessHasUpdated } = props;
  const { owners, users } = business;
  const { businessId } = useParams();

  const addUserToBusiness = async (values, { resetForm }) => {
    console.log(values.userId, businessId);
    await assignBusinessUser(values.userId, businessId);
    setBusinessHasUpdated(businessHasUpdated + 1);
    resetForm();
  };

  const handleRemoveBusinessUser = async (userId, businessId) => {
    const resObj = await removeBusinessUser(userId, businessId);
    setBusinessHasUpdated(businessHasUpdated + 1);
  };
  return (
    <>
      <UpdateBusinessInfo business={business} routeInitialValues={business} businessHasUpdated={businessHasUpdated} setBusinessHasUpdated={setBusinessHasUpdated} />
      {[roles.DivisionAdmin, roles.DivisionUser].includes(loadUserRole()) ? (
        <Container className="bg-light my-5 py-3">
          <h2>Remove Business Owners</h2>
          <small>A business requires at least one active owner.</small>
          {owners?.length
            ? owners.map((owner, i) => (
                <BusinessEditOwnerForm
                  key={i}
                  business={business}
                  owner={owner}
                  businessHasUpdated={businessHasUpdated}
                  setBusinessHasUpdated={setBusinessHasUpdated}
                  disabled={owners.length === 1 ? true : false}
                />
              ))
            : null}

          <hr />
          <h2>Business Users Management</h2>
          {users?.length ? (
            <div className="mb-4 mb-md-5">
              {users.map((user, i) => (
                <div className="d-flex" key={`remove-${user.userId}-block`}>
                  <p className="h5">
                    {user.name}
                    <button
                      type="button"
                      className="faux-link text-primary"
                      onClick={() => {
                        handleRemoveBusinessUser(user.userId, businessId);
                      }}
                      key={`remove-${user.userId}`}
                    >
                      <FontAwesomeIcon className="ml-2 mt-1 text-primary" icon={["fa", "times"]} /> Remove
                    </button>
                  </p>
                </div>
              ))}
            </div>
          ) : null}
          <BusinessAddUserForm business={business} businessHasUpdated={businessHasUpdated} setBusinessHasUpdated={setBusinessHasUpdated} onSubmit={addUserToBusiness} />
        </Container>
      ) : null}
    </>
  );
};

export default BusinessEdit;

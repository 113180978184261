import React, { Fragment } from "react";
import ReportDetails from "../../Shared/ReportDetails";
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getLocalDate } from "../../../../util/helpers";

const ReportHistory = (props) => {
  const { history, reportType, reportTypeData } = props;
  return (
    <>
      {history?.length ? (
        <Accordion className="d-block">
          {history.map((historyItem, index) => {
            const version = history?.length - index;
            const plan = historyItem[index];

            return (
              <Fragment key={version}>
                <Accordion.Toggle as="h3" variant="link" eventKey={`history-${version}`} className="d-flex flex-row justify-content-between accordion accordion-box cursor-pointer py-3 pr-5">
                  <>
                    Version {historyItem?.version} - {historyItem?.status}: {getLocalDate(historyItem?.created)}
                    <FontAwesomeIcon className="text-secondary" icon={["fas", "angle-down"]} size="xs" />
                  </>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={`history-${version}`}>
                  {historyItem.status === "Deficient" ? (
                    <div className="alert alert-primary">
                      <p>
                        <strong>Reviewed:</strong> {getLocalDate(historyItem?.reviewed)}
                      </p>
                      <p>
                        <strong>Reviewer:</strong> {historyItem?.reviewer?.name}
                      </p>
                      <p>
                        <strong>Message:</strong> {historyItem?.message}
                      </p>
                    </div>
                  ) : (
                    <>
                      <ReportDetails report={historyItem} reportType={reportType} isVersion={true} reportTypeData={reportTypeData} />
                    </>
                  )}
                </Accordion.Collapse>
              </Fragment>
            );
          })}
        </Accordion>
      ) : (
        <h3>There are no previous versions of this report.</h3>
      )}
    </>
  );
};

export default ReportHistory;

import { Form, FastField, Field, FieldArray } from "formik";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { schemas } from "../../../util/errorHelper";
import DebugModal from "../../Debug/DebugModal";
import { withFormikFormComponent } from "../../Forms/FormikForm";
import InputComponent from "../../Forms/Input/InputComponent";
import { DocumentUpload } from "../../Forms/DocumentHelper";
// import { Modes } from './CorrespondenceWrapper';
import ReactiveButton from "../../Forms/Input/ReactiveButton";
import ArrayWrapperDocument from "../../Forms/helpers/ArrayWrapperDocument";
import ArrayWrapperDynamic from "../../Forms/helpers/ArrayWrapperDynamic";
import DocumentWrapper from "../../Forms/helpers/DocumentWrapper";

const CorrespondenceFormInternal = ({ formikBag, context, showForm, correspondenceId, level, creatorName }) => {
  const { values, setFieldValue, isSubmitting, isValid } = formikBag;
  const categoryOptions =
    context === "business"
      ? [[0, "Miscellaneous"]]
      : [
          [null, "Choose Category"],
          ["plan", "Utilization Plan"],
          ["payment", "Payment"],
          ["other", "Other"],
        ];
  return showForm ? (
    <Form className={`bg-light p-4 mb-5 ${level === 2 ? "" : ""}`}>
      <DebugModal formikBag={formikBag} name="Formik Bag" />

      {!correspondenceId && (
        <div className="mt-4">
          {context !== "business" && (
            <div className="field-group">
              <FastField label="What is the message regarding" name={`category`} component={InputComponent} options={categoryOptions} type="select" />
            </div>
          )}
          <div className="field-group">
            <Field id={`subject`} label="Subject" name={`subject`} component={InputComponent} type="text" />
          </div>
        </div>
      )}

      <div className="field-group">
        <Field id={`message`} label={!correspondenceId ? `Message` : `Reply to ${creatorName}'s message`} name={`message`} component={InputComponent} type="textarea" />
      </div>
      <div className="post-reply-action justify-content-between">
        <ArrayWrapperDynamic
          btnSize="small"
          name={`attachments`}
          documentType="Miscellaneous"
          additionLabel="Add Document"
          removalLabel="Remove Document"
          component={DocumentWrapper}
          formikBag={formikBag}
          atLeastOne={false}
          initialValues={[]}
        />

        <ReactiveButton type="submit" className="small btn-secondary margin-auto" label={"Send Message"} processing={isSubmitting} disabled={!isValid} />
      </div>
    </Form>
  ) : (
    <></>
  );
};
const CorrespondenceForm = withFormikFormComponent(CorrespondenceFormInternal, schemas.correspondenceSchema);
export default CorrespondenceForm;

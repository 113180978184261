import axios from "axios";
import { cleanNull, handleDownloadCSV } from "./helpers";
import { EMPTY_GUID } from "./typelistHelper";
import { getAccessToken, getUserId, loadActiveOrgId, loadUserRole, loadUserToken, roles } from "./userHelper";

export const applicationOwnerAssociationIdLocalStorageKey = "applicationOwnerAssociationId";
export const returnPathLocalStorageKey = "returnPath";

const getAuthHeader = () => {
  switch (loadUserRole()) {
    case roles.Business:
      return { sdvesBusiness: loadActiveOrgId() };
    case roles.AgencyUser:
    case roles.AgencyAdmin:
      return { sdvesAgency: loadActiveOrgId() };
    default:
      return {};
  }
};

const qs = require("qs");
export const apiBase = process.env.REACT_APP_API_BASE;

export const batchGetAllRecords = async (pageSize, fn, listFn, updatefn, modifyFn) => {
  if (!modifyFn)
    modifyFn = (val) => {
      return val;
    };
  var pageNum = 1;
  var allRes = [];
  var res = await fn(pageNum, pageSize);

  do {
    var resModified = modifyFn(listFn(res));
    allRes = allRes.concat(resModified);
    updatefn(allRes);

    pageNum += 1;
    res = await fn(pageNum, pageSize);
  } while (pageNum == 1 || listFn(res).length > 0);
};

export const loadTags = async () => {
  const res = await window.session.get(`${apiBase}/businesses/tags/`);
  return res;
};

export const getApiToken2 = async (oktaAccessToken) => {
  const applicationOwnerAssociationId = localStorage.getItem(applicationOwnerAssociationIdLocalStorageKey) || undefined;

  const result = await window.session.post(
    `${apiBase}/authentication/`,
    {
      token: oktaAccessToken,
      applicationOwnerAssociationId,
    },
    {
      headers: {
        // Still need to pass existing token from okta for access - might be removed later.
        Authorization: `Bearer ${oktaAccessToken}`,
      },
    }
  );

  localStorage.removeItem(applicationOwnerAssociationIdLocalStorageKey);

  return result.data;
};

export const associateApplicationOwner = async (userId) => {
  const applicationOwnerAssociationId = localStorage.getItem(applicationOwnerAssociationIdLocalStorageKey)?.trim() || undefined;

  if (!applicationOwnerAssociationId) {
    return;
  }

  const result = await window.session.put(
    `${apiBase}/applications/associate`,
    {
      userId,
      applicationOwnerAssociationId,
    },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );

  localStorage.removeItem(applicationOwnerAssociationIdLocalStorageKey);

  return result.data;
};

export const resendApplicationOwnerInvitation = async (applicationId, applicationOwnerId) => {
  const result = await window.session.post(
    `${apiBase}/applications/resend`,
    {
      applicationId,
      applicationOwnerId,
    },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
        SdvesApplication: applicationId,
      },
    }
  );

  return result.data;
};

export const handleRevokeCertification = async (businessId, reason) => {
  const res = await window.session.put(
    `${apiBase}/businesses/revoke`,
    { businessId: businessId, reason: reason },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const getApplication = async (applicationId) => {
  const res = await window.session.get(`${apiBase}/applications/${applicationId}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
      SdvesApplication: applicationId,
    },
  });
  return res.data;
};

export const handleSaveApplication = async (values) => {
  const oldFormData = { ...values };
  const newFormData = { application: { ...cleanNull(oldFormData) } };
  const token = getAccessToken();
  const res = await window.session.put(
    `${apiBase}/applications/save`,
    { ...newFormData },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        SdvesApplication: values.id,
      },
    }
  );
  return res.data;
};

export const handleSubmitApplication = async (values) => {
  // console.log('we submitted');
  const oldFormData = { ...values, status: "Submitted" };
  const newFormData = { application: { ...cleanNull(oldFormData) } };
  const res = await window.session.put(`${apiBase}/applications`, newFormData, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
      SdvesApplication: values.id,
    },
  });
  return res.data;
};

export const handleAttestation = async (values) => {
  const res = await window.session.put(
    `${apiBase}/applications/attest`,
    { applicationId: values?.applicationId, signature: values?.signature, documents: values?.documents, ownerId: values?.ownerId },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
        SdvesApplication: values.id,
      },
    }
  );
  return res.data;
};

export const handlePreparationCompletion = async (applicationId) => {
  const res = await window.session.put(
    `${apiBase}/applications/initialreview`,
    { applicationId: applicationId },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const handleInitialReviewCompletion = async (applicationId) => {
  const res = await window.session.put(
    `${apiBase}/applications/indepthreview`,
    { applicationId: applicationId },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const handleInDepthReviewCompletion = async (applicationId) => {
  const res = await window.session.put(
    `${apiBase}/applications/directorreview`,
    { applicationId: applicationId },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const handleApplicationApproval = async (applicationId) => {
  const res = await window.session.put(
    `${apiBase}/applications/approve`,
    { applicationId: applicationId },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const handleApplicationDenial = async (applicationId) => {
  const res = await window.session.put(
    `${apiBase}/applications/deny`,
    { applicationId: applicationId },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const handleApplicationWithdrawal = async (applicationId) => {
  const res = await window.session.put(
    `${apiBase}/applications/withdraw`,
    { applicationId: applicationId },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
        SdvesApplication: applicationId,
      },
    }
  );
  return res;
};

export const handleApplicationAppeal = async (applicationId) => {
  const res = await window.session.put(
    `${apiBase}/applications/appeal`,
    { applicationId: applicationId },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
        SdvesApplication: applicationId,
      },
    }
  );
  return res;
};

export const handleApplicationAppealDenial = async (applicationId) => {
  const res = await window.session.put(
    `${apiBase}/applications/appeal/deny`,
    { applicationId: applicationId },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const handleApplicationAppealAllow = async (applicationId) => {
  const res = await window.session.put(
    `${apiBase}/applications/appeal/allow`,
    { applicationId: applicationId },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const handleApplicationCertification = async (applicationId, includedFormValues) => {
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const effectiveDate = includedFormValues?.effectiveDate ?? today;

  const res = await window.session.put(
    `${apiBase}/applications/certify`,
    {
      applicationId,
      effectiveDate,
    },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const DeactivateCertification = async (businessId, reason) => {
  const res = await window.session.put(
    `${apiBase}/businesses/deactivate`,
    { businessId: businessId, reason: reason },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const ReactivateCertification = async (businessId, reason) => {
  const res = await window.session.put(
    `${apiBase}/businesses/reactivate`,
    { businessId: businessId, reason: reason },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const handleVisitationStatus = async (applicationId, status) => {
  const res = await window.session.put(
    `${apiBase}/applications/${applicationId}/visitation-status`,
    { applicationId: applicationId, status: status },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const handleSigntaureRequest = async (applicationId) => {
  const res = await window.session.put(
    `${apiBase}/applications/signature-request`,
    { applicationId },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res.data;
};

export const assignApplicationUser = async (applicationId, divisionUserId) => {
  const res = await window.session.put(
    `${apiBase}/applications/division-users/assign`,
    {
      applicationId: applicationId,
      divisionUserId: divisionUserId ? divisionUserId : EMPTY_GUID,
    },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res.data;
};

export const createApplication = async () => {
  const businessId = loadActiveOrgId();

  const res = await window.session.post(
    `${apiBase}/applications/`,
    { businessId: businessId },
    {
      headers: {
        // Still need to pass existing token from okta for access - might be removed later.
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const handleFileUpload = async (url, documentFormData) => {
  let resObj = {};
  const res = await window.session.post(url, documentFormData, {
    headers: {
      "Content-Type": "multipart/form-data", // Necessary because of the form upload
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return { documentId: res.data.id };
};

export const getUserUploadStatus = async () => {
  const res = await window.session.get(`${apiBase}/import/myimports/${loadActiveOrgId()}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res.data;
};

export const handleLogin = async (username, password) => {
  const res = await window.session.post(`${apiBase}/authentication/credentials`, {
    username,
    password,
  });
  return res;
};

export const handleChangeRequestSubmit = async (applicationId, description, preparerId, requesterId, status) => {
  let resObj = {};
  const res = await window.session.post(
    `${apiBase}/changeRequests`,
    {
      applicationId: applicationId,
      description: description,
      preparerId: preparerId,
      requesterId: requesterId,
      status: status,
    },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const handleActivityLogSubmit = async (type, relevantId, activity, created) => {
  const res = await window.session.post(
    `${apiBase}/${type}activitylogs`,
    {
      businessId: relevantId,
      applicationId: relevantId,
      activity: activity,
      created: created,
    },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res.data;
};

export const getActivityLogs = async (type, relevantId) => {
  const res = await window.session.get(`${apiBase}/${type}activitylogs/${type}/${relevantId}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res.data;
};

export const getUser = async () => {
  const res = await window.session.get(`${apiBase}/users/${getUserId()}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res;
};

export const getNotifications = async () => {
  const res = await window.session.get(`${apiBase}/notifications/`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      userId: getUserId(),
      page: 1,
      pageSize: 100000,
    },
  });

  return res;
};

export const getNotificationsCount = async () => {
  const res = await window.session.get(`${apiBase}/notifications/unread/count`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      userId: getUserId(),
      page: 1,
      pageSize: 100000,
    },
  });

  return res;
};

export const markNotificationsRead = async (notificationIds) => {
  const res = await axios.post(
    `${apiBase}/notifications/read`,
    { notificationIds: notificationIds },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const markNotificationsUnread = async (notificationIds) => {
  const res = await axios.post(
    `${apiBase}/notifications/unread`,
    { notificationIds: notificationIds },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const getBusinesses = async (statuses) => {
  const res = await window.session.get(`${apiBase}/businesses`, {
    params: {
      page: 1,
      pageSize: 1500,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  return res.data;
};

export const recertifyBusiness = async (businessId, effectiveDate, shouldSendEmail) => {
  const res = await window.session.put(
    `${apiBase}/businesses/recertify`,
    {
      businessId,
      effectiveDate,
      shouldSendEmail,
    },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );

  return res.data;
};

export const batchGetAgencies = async (statuses, updateFn) => {
  await batchGetAllRecords(
    50,
    async (page, pageSize) => {
      return getAgencies(statuses, page, pageSize);
    },
    (results) => {
      return results.agencies;
    },
    (response) => {
      updateFn(response);
    }
  );
};

export const getAgencies = async (statuses, page, pageSize) => {
  page = page ?? 1;
  pageSize = pageSize ?? 100000;

  const res = await window.session.get(`${apiBase}/agencies`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      page: page,
      pageSize: pageSize,
      statuses: statuses,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  return res.data;
};

export const getAllAgencies = async (statuses) => {
  const res = await window.session.get(`${apiBase}/agencies`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res.data;
};

export const getContract = async (id) => {
  const res = await window.session.get(`${apiBase}/contracts/${id}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res;
};

export const assignProcurementUser = async (procurementId, userId) => {
  const res = await window.session.post(
    `${apiBase}/procurements/user/assign`,
    { procurementId, userId },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res?.data;
};

export const updateProcurementUsers = async (procurementId, userIds) => {
  const res = await window.session.post(
    `${apiBase}/procurements/users`,
    { procurementId, userIds },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res?.data;
};

export const removeProcurementUser = async (procurementId, userId) => {
  const res = await window.session.post(
    `${apiBase}/procurements/user/remove`,
    { procurementId, userId },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res?.data;
};

export const getProcurementsByPrime = async () => {
  const res = await window.session.get(`${apiBase}/procurements/awarded/${loadActiveOrgId()}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      page: 1,
      pageSize: 100000,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  return res.data;
};

export const getProcurementsBySub = async () => {
  const res = await window.session.get(`${apiBase}/procurements/subcontracted/${loadActiveOrgId()}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      page: 1,
      pageSize: 100000,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  return res.data;
};

export const getUnassignedContracts = async (agencyId) => {
  const agencyToUse = agencyId ? agencyId : loadActiveOrgId();

  const res = await window.session.get(`${apiBase}/contracts/agency/${agencyToUse}/unassigned`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      page: 1,
      pageSize: 100000,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  return res.data;
};

export const getAllContracts = async () => {
  const res = await window.session.get(`${apiBase}/contracts/`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      page: 1,
      pageSize: 100000,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });

  return res.data;
};

export const assignContract = async (procurementId, contractNumber) => {
  const res = await window.session.put(
    `${apiBase}/procurements/contract/assign`,
    { procurementId: procurementId, contractNumber: contractNumber },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const getPlansByContractor = async () => {
  const res = await window.session.get(`${apiBase}/plans/contractors/${loadActiveOrgId()}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      page: 1,
      pageSize: 100000,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  return res.data;
};

export const getPlansBySubontractor = async () => {
  const res = await window.session.get(`${apiBase}/plans/subcontracts/${loadActiveOrgId()}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      page: 1,
      pageSize: 100,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  return res.data;
};

export const getPlan = async (planId) => {
  const res = await window.session.get(`${apiBase}/plans/${planId}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {},
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  return res.data;
};

export const getPlans = async (procurementId) => {
  const res = await window.session.get(`${apiBase}/plans/procurement/${procurementId}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      procurementId: procurementId,
      page: 1,
      pageSize: 100000,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  return res.data;
};

export const awardPlan = async (procurementId, planId) => {
  const res = await window.session.post(
    `${apiBase}/procurements/award`,
    { procurementId, procurementId, planId: planId },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const setPlanStatus = async (procurementId, planId, status, message) => {
  const res = await window.session.post(
    `${apiBase}/plans/${status}`,
    { procurementId, procurementId, planId: planId, message: message },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res.data;
};

export const getPlansByProcurement = async (procurementId) => {
  const res = await window.session.get(`${apiBase}/plans/procurement/${procurementId}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res.data;
};

export const handleUtilizationPlanSubmit = async (plan) => {
  console.log(plan);
  const businessId = loadUserRole() === "Business" ? loadActiveOrgId() : plan?.primeContractorId;
  const submitObj = loadUserRole() == roles.Business ? { ...plan, primeContractorId: businessId } : plan;
  const res = await window.session.post(`${apiBase}/plans`, submitObj, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res;
};

export const handleUtilizationPlanForContractSubmit = async (plan, contractId) => {
  const businessId = loadActiveOrgId();
  const res = await window.session.post(
    // `${apiBase}/contracts/${contractId}/plan`,
    `${apiBase}/plans`,
    { ...plan, contractId: contractId, primeContractorId: businessId },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const handleUtilizationPlanUpdate = async (plan) => {
  const businessId = loadUserRole() === "Business" ? loadActiveOrgId() : plan?.primeContractorId;
  const res = await window.session.patch(
    `${apiBase}/plans`,
    { ...plan, primeContractorId: businessId },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const getPaymentsByContractor = async () => {
  const res = await window.session.get(`${apiBase}/contractors/${loadActiveOrgId()}/payments`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      page: 1,
      pageSize: 100000,
    },
  });

  return res;
};

export const handlePaymentDelete = async (paymentId) => {
  const res = await window.session.delete(`${apiBase}/contracts/payments/${paymentId}`, {
    data: {
      paymentId: paymentId,
    },
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });

  return res;
};

export const handlePaymentSubmit = async (paymentObj) => {
  console.log(paymentObj);
  const { amount, contractId, contractorId, paidDate, paymentId, documents } = paymentObj;
  const res = await window.session.post(
    `${apiBase}/contracts/payments`,
    {
      amount,
      contractId,
      contractorId,
      paidDate,
      paymentId,
      documents,
    },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );

  return res;
};

export const handlePaymentUpdate = async (paymentObj) => {
  // console.log(paymentObj);
  const { amount, contractId, contractorId, paidDate, paymentId, documents } = paymentObj;
  const res = await window.session.put(
    `${apiBase}/contracts/payments`,
    {
      amount,
      contractId,
      contractorId,
      paidDate,
      paymentId,
      documents,
    },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );

  return res;
};

export const confirmPayment = async (payment, action) => {
  const res = await window.session.put(
    `${apiBase}/contracts/payments/${action}`,
    { ...payment, contractorId: loadActiveOrgId() },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const confirmPlanPresence = async (subcontractId, action) => {
  const res = await window.session.put(
    `${apiBase}/plans/subcontracts/${action}`,
    { businessId: loadActiveOrgId(), subcontractId: subcontractId },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const submitComplianceReport = async (report) => {
  const res = await window.session.post(
    `${apiBase}/contracts/compliancereports/submitbymonthyear`,
    { ...report },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const getComplianceReports = async (contractId) => {
  const res = await window.session.get(`${apiBase}/contracts/${contractId}/compliancereports`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      contractId: contractId,
      page: 1,
      pageSize: 100000,
    },
  });
  return res.data.complianceReports;
};

export const getComplianceReport = async (contractId, year, month) => {
  const res = await window.session.get(`${apiBase}/contracts/${contractId}/compliancereports`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      year: year,
      month: month,
    },
  });
  return res;
};

export const getCompliancePayments = async (contractId, year, month) => {
  const res = await window.session.get(`${apiBase}/contracts/${contractId}/payments/${year}/${month}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      contractId: contractId,
      year: year,
      month: month,
      page: 1,
      pageSize: 100000,
    },
  });
  return res;
};

export const getBusiness = async (businessId) => {
  const res = await window.session.get(`${apiBase}/businesses/${businessId}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res;
};

export const updateBusiness = async (business) => {
  const res = await window.session.put(
    `${apiBase}/businesses`,
    { ...business, businessId: business.id },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const getApplications = async (page = 1, pageSize = 10000) => {
  const reviewerId = loadUserRole() === "DivisionUser" ? loadUserToken()?.user?.id : "";
  const res = await window.session.get(`${apiBase}/applications`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      page: page,
      pageSize: pageSize,
      // statuses: statuses,
      statuses: [
        "New",
        "InProgress",
        "Submitted",
        "Deficient",
        "Appealed",
        // "Withdrawn",
        "Denied",
        "Approved",
      ],
      phases: ["InitialReview", "InDepthReview", "DirectorReview"],
      reviewerId: reviewerId,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  return res.data;
};

export const getCertifiedApplications = async (statuses) => {
  const res = await window.session.get(`${apiBase}/applications`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      page: 1,
      pageSize: 1500,
      statuses: ["Certified"],
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  return res.data;
};

export const getCertifiedBusinesses = async (statuses, page, pageSize) => {
  page = page ?? 1;
  pageSize = pageSize ?? 100000;
  const res = await window.session.get(`${apiBase}/businesses/public`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      page: page,
      pageSize: pageSize,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  return res.data;
};

export const getDivisionUsers = async () => {
  const res = await window.session.get(`${apiBase}/users/division`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      page: 1,
      pageSize: 100000,
    },
  });

  return res.data;
};

export const getAgencyUsers = async () => {
  const res = await window.session.get(`${apiBase}/users/agencies/`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      page: 1,
      pageSize: 200,
    },
  });
  return res?.data;
};

export const getBusinessUsersByUserName = async (userId) => {
  const res = await window.session.get(`${apiBase}/users/`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      username: userId,
    },
  });
  return res?.data;
};

export const assignBusinessUser = async (userId, businessId) => {
  const res = await window.session.post(
    `${apiBase}/users/${userId}/assignbusinessuser`,
    { userId, businessId, role: "AdditionalBusinessUser" },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res?.data;
};

export const removeBusinessUser = async (userId, businessId) => {
  const res = await window.session.post(
    `${apiBase}/users/${userId}/removebusinessuser`,
    { userId, businessId, role: "AdditionalBusinessUser" },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res?.data;
};

export const getUsersByAgency = async () => {
  const res = await window.session.get(`${apiBase}/users/agencies/${loadActiveOrgId()}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      page: 1,
      pageSize: 200,
    },
  });
  return res?.data;
};

export const assignAgencyUser = async (agencyId, userId) => {
  const res = await window.session.post(
    `${apiBase}/agencies/user/assign`,
    { agencyId, userId },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res?.data;
};

export const removeAgencyUser = async (agencyId, userId) => {
  const res = await window.session.post(
    `${apiBase}/agencies/user/remove`,
    { agencyId, userId },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res?.data;
};

export const removeBusinessOwner = async (businessId, reason, userId) => {
  const res = await window.session.put(
    `${apiBase}/businesses/removeowner`,
    { businessId, reason, userId },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res?.data;
};

export const handleApplicationTimerStatus = async (timerId, endState) => {
  const res = await window.session.put(
    `${apiBase}/applicationtimers/${endState}`,
    { timerId: timerId },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );

  return res;
};

export const handleBusinessTimerStatus = async (timerId, endState) => {
  console.log(timerId, endState);
  const res = await window.session.put(
    `${apiBase}/businesstimers/${endState}`,
    { timerId: timerId },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );

  return res;
};

export const getBusinessTimers = async () => {
  const res = await window.session.get(`${apiBase}/businesstimers/all`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      page: 1,
      pageSize: 100000,
    },
  });
  return res;
};

// Procurement piece requests
export const getAnnotationsLogs = async (procurementId) => {
  const res = await window.session.get(`${apiBase}/procurements/${procurementId}/annotations`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      page: 1,
      pageSize: 100000,
    },
  });
  return res;
};

export const getChangeLogs = async (contractId) => {
  const res = await window.session.get(`${apiBase}/contracts/${contractId}/revisions`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      page: 1,
      pageSize: 100000,
    },
  });
  return res;
};

export const getPayments = async (contractId) => {
  const res = await window.session.get(`${apiBase}/contracts/${contractId}/payments`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      page: 1,
      pageSize: 100000,
    },
  });
  return res;
};

// Correspondence Requests
export const getPotentialCorrespondenceParticipants = async (procurementId) => {
  const res = await window.session.get(`${apiBase}/correspondences/potentialparticipants/`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      procurementId,
    },
  });
  return res;
};

export const getCorrespondenceList = async (procurementId) => {
  const res = await window.session.get(`${apiBase}/correspondences/`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      procurementId: procurementId,
    },
  });
  return res;
};

export const handleCorrespondenceCreate = async (correspondence) => {
  console.log(correspondence);
  const res = await window.session.post(
    `${apiBase}/correspondences`,
    { ...correspondence, procurementId: correspondence.contextId },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res.data;
};

export const handleCorrespondenceClose = async (correspondenceId) => {
  const res = await window.session.put(
    `${apiBase}/correspondences/close`,
    { correspondenceId: correspondenceId },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res.data;
};

export const handleCorrespondenceReopen = async (correspondenceId) => {
  const res = await window.session.put(
    `${apiBase}/correspondences/reopen`,
    { correspondenceId: correspondenceId },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res.data;
};

export const handleCorrespondenceUpdate = async (correspondence) => {
  const res = await window.session.put(`${apiBase}/correspondences`, correspondence, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res.data;
};

export const getCorrespondence = async (correspondenceId) => {
  const res = await window.session.get(`${apiBase}/correspondences/${correspondenceId}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res;
};

export const handleCorrespondencesReply = async (reply) => {
  const res = await window.session.post(
    `${apiBase}/correspondences/reply`,
    { ...reply, userId: getUserId() },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res.data;
};

export const handleCorrespondencesReplyReply = async (replyReply) => {
  const res = await window.session.post(
    `${apiBase}/correspondences/replytoreply`,
    { ...replyReply, userId: getUserId() },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res.data;
};

export const handlePrimeContractorSignup = async (values) => {
  const res = await window.session.post(`${apiBase}/businesses/prime`, values, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res;
};

export const handlePrimeLookup = async (values) => {
  const { legalName, federalId, nysVendorId } = values;
  const res = await window.session.post(
    `${apiBase}/businesses/assign`,
    { legalName: legalName, federalId: federalId },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const getDocumentDownloadUrl = async (documentId) => {
  const res = await window.session
    .post(
      `${apiBase}/documents/${documentId}/token`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          ...getAuthHeader(),
        },
      }
    );

  const document = await fetch(
    `${apiBase}/documents/${res.data.id}/file`,
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader()
      }
    }
  );

  return await document.blob();
};

export const updateComplianceReportStatus = async (values, contractId) => {
  console.log(values);
  const res = await window.session.put(
    `${apiBase}/contracts/compliancereports/${values.status}`,
    { ...values },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res.data;
};

export const handleInvitePrime = async (firstName, lastName, emailAddress) => {
  const res = await window.session.post(
    `${apiBase}/contractors/invite`,
    { firstName: firstName, lastName: lastName, emailAddress: emailAddress },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const handleRequestPlanFromPrime = async (procurementId) => {
  const res = await window.session.post(
    `${apiBase}/procurements/${procurementId}/primecontractor/notify/plan/create`,
    {},
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const createProcurement = async (procurement) => {
  const res = await window.session.post(
    `${apiBase}/procurements`,
    { ...procurement, agencyId: loadActiveOrgId() },
    {
      headers: {
        // Still need to pass existing token from okta for access - might be removed later.
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const updateProcurement = async (procurement) => {
  const res = await window.session.put(`${apiBase}/procurements`, procurement, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res;
};

export const getProcurements = async (agencyId, page, pageSize) => {
  const agencyToUse = agencyId ? agencyId : loadActiveOrgId();
  page = page ?? 1;
  pageSize = pageSize ?? 100000;

  const res = await window.session.get(`${apiBase}/procurements/open`, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      page: page,
      pageSize: pageSize,
      agencyId: agencyToUse,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  return res;
};

export const getCompletedProcurements = async (agencyId) => {
  const agencyToUse = agencyId ? agencyId : loadActiveOrgId();

  const res = await window.session.get(`${apiBase}/procurements/complete`, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      page: 1,
      pageSize: 100000,
      agencyId: agencyToUse,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  return res;
};

export const getOpenProcurements = async (agencyId) => {
  const agencyToUse = agencyId ? agencyId : loadActiveOrgId();

  const res = await window.session.get(`${apiBase}/procurements/agency/${agencyId}/open`, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      page: 1,
      pageSize: 100000,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  return res;
};

export const getProcurementsByUser = async () => {
  const res = await window.session.get(`${apiBase}/procurements/user/${getUserId()}`, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      page: 1,
      pageSize: 100000,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  return res;
};

export const getProcurement = async (procurementId) => {
  // Just for temp testing purposes - Alleghany
  const res = await window.session.get(`${apiBase}/procurements/${procurementId}`, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res;
};

// Request extension
export const requestReportExtension = async (reportType, reportId, extensionRequestedReason) => {
  const res = await window.session.put(
    `${apiBase}/${reportType}/requestextension/${reportId}`,
    { extensionRequestedReason: extensionRequestedReason },
    {
      headers: {
        // Still need to pass existing token from okta for access - might be removed later.
        Authorization: `Bearer ${getAccessToken()}`,
      },
    }
  );
  return res;
};

// Goal Plan Endpoints
export const createGoalPlan = async (goalPlan) => {
  const res = await window.session.post(`${apiBase}/goalplan`, goalPlan, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res;
};

//
export const createSeedData = async (agencyId) => {
  const res = await window.session.post(
    `${apiBase}/test`,
    { agencyId },
    {
      headers: {
        // Still need to pass existing token from okta for access - might be removed later.
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const getGoalPlans = async () => {
  const res = await window.session.get(`${apiBase}/goalplan`, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      agencyId: loadActiveOrgId(),
    },
  });
  return res;
};

export const getGoalPlansDivision = async (fiscalYear, status) => {
  const res = await window.session.get(`${apiBase}/goalplan/${fiscalYear}/${status}`, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res;
};

export const getAssignedGoalPlansDivisionUser = async () => {
  const res = await window.session.get(`${apiBase}/goalplan/assigned`, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      page: 1,
      pageSize: 100000,
      agencyId: loadActiveOrgId(),
    },
  });
  return res;
};

export const updateGoalPlanStatus = async (submitObj, status) => {
  const res = await window.session.put(`${apiBase}/goalplan/${status}`, submitObj, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res;
};

export const getGoalPlan = async (id) => {
  const res = await window.session.get(`${apiBase}/goalplan/${id}`, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      goalPlanId: id,
      id: id,
    },
  });
  return res;
};

export const saveGoalPlan = async (goalPlan) => {
  const res = await window.session.put(`${apiBase}/goalplan/`, goalPlan, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res;
};

export const submitGoalPlan = async (goalPlan) => {
  const res = await window.session.put(`${apiBase}/goalplan/submit`, goalPlan, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res;
};

export const assignGoalPlanUser = async (planId, divisionUserId) => {
  const res = await window.session.put(
    `${apiBase}/goalplan/assign`,
    {
      id: planId,
      userId: divisionUserId ? divisionUserId : null,
    },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res.data;
};

// Narrative Statement Endpoints
export const createNarrativeStatement = async (narrativeStatement) => {
  const res = await window.session.post(`${apiBase}/narrativestatement`, narrativeStatement, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res;
};

export const getAllNarrativeStatements = async () => {
  const res = await window.session.get(`${apiBase}/narrativestatement`, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      agencyId: loadActiveOrgId(),
    },
  });
  return res;
};

export const getNarrativeStatementsByAgency = async () => {
  const res = await window.session.get(`${apiBase}/narrativestatement`, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      agencyId: loadActiveOrgId(),
    },
  });
  return res;
};

export const getNarrativeStatementsDivision = async (fiscalYear, status) => {
  const res = await window.session.get(`${apiBase}/narrativestatement/${fiscalYear}/${status}`, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res;
};

export const getAssignedNarrativeStatementsDivisionUser = async () => {
  const res = await window.session.get(`${apiBase}/narrativestatement/assigned`, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      page: 1,
      pageSize: 100000,
      agencyId: loadActiveOrgId(),
    },
  });
  return res;
};

export const getNarrativeStatement = async (id) => {
  const res = await window.session.get(`${apiBase}/narrativestatement/${id}`, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      narrativeStatementId: id,
      id: id,
    },
  });
  return res;
};

export const saveNarrativeStatement = async (narrativeStatement) => {
  const res = await window.session.put(`${apiBase}/narrativestatement/`, narrativeStatement, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res;
};

export const submitNarrativeStatement = async (narrativeStatement) => {
  const res = await window.session.put(`${apiBase}/narrativestatement/submit`, narrativeStatement, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res;
};

export const setNarrativeStatementStatus = async (values) => {
  console.log(values);
  const res = await window.session.put(`${apiBase}/narrativestatement/${values.status}`, values, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res.data;
};

export const updateNarrativeStatementStatus = async (submitObj, status) => {
  const res = await window.session.put(`${apiBase}/narrativestatement/${status}`, submitObj, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res;
};

export const assignNarrativeStatementUser = async (reportId, divisionUserId) => {
  const res = await window.session.put(
    `${apiBase}/narrativestatement/assign`,
    {
      id: reportId,
      userId: divisionUserId ? divisionUserId : null,
    },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res.data;
};

// Activity Report Endpoints
export const handleActivityReportImport = async (file) => {
  const res = await window.session.post(
    `${apiBase}/activityreport/upload`,
    { importFile: file, agencyId: loadActiveOrgId() },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const createActivityReport = async (activityReport) => {
  const res = await window.session.post(`${apiBase}/activityReport`, activityReport, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res;
};

export const resetActivityReport = async (activityReportId, canForce) => {
  const res = await window.session.get(`${apiBase}/activityReport/reset/${activityReportId}`, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: { force: canForce },
  });
  return res;
};

// Activity Report Endpoints
export const getActivityReports = async () => {
  const res = await window.session.get(`${apiBase}/activityReport`, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      agencyId: loadActiveOrgId(),
    },
  });
  return res;
};

export const updateActivityReportStatus = async (submitObj, status) => {
  const res = await window.session.put(`${apiBase}/activityreport/${status}`, submitObj, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res.data;
};

export const getActivityReportsDivision = async (fiscalYear, fiscalQuarter, status) => {
  const res = await window.session.get(`${apiBase}/activityReport/${fiscalYear}/${fiscalQuarter}/${status}`, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res;
};

export const assignActivityReportUser = async (planId, divisionUserId) => {
  const res = await window.session.put(
    `${apiBase}/activityreport/assign`,
    {
      id: planId,
      userId: divisionUserId ? divisionUserId : null,
    },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res.data;
};

export const getAssignedActivityReportsDivisionUser = async () => {
  console.log("by user");
  const res = await window.session.get(`${apiBase}/activityreport/assigned`, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      page: 1,
      pageSize: 100000,
      agencyId: loadActiveOrgId(),
    },
  });
  return res;
};

// Activity Report Endpoints
export const getActivityReport = async (id) => {
  const res = await window.session.get(`${apiBase}/activityReport/${id}`, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      ActivityReportId: id,
      id: id,
    },
  });
  return res;
};

// Activity Report Endpoints
export const getActivityReportDetail = async (id) => {
  const res = await window.session.get(`${apiBase}/activityReport/detail/${id}`, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      page: 1,
      pageSize: 100000,
    },
  });
  return res;
};

// Activity Report Endpoints
export const saveActivityReport = async (activityReport) => {
  const res = await window.session.put(`${apiBase}/activityReport/`, activityReport, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res;
};

// Activity Report Endpoints
export const submitActivityReport = async (activityReport) => {
  const res = await window.session.put(`${apiBase}/activityReport/submit`, activityReport, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res;
};

export const handleContractImport = async (file) => {
  const res = await window.session.post(
    `${apiBase}/import`,
    { importFile: file, agencyId: loadActiveOrgId() },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const getSystemData = async () => {
  const res = await window.session.get(`${apiBase}/system`, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res;
};

export const updateSystemData = async (values) => {
  const res = await window.session.put(`${apiBase}/system`, values, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res;
};

//Centralized Contract endpoints
export const getCentralizedContracts = async () => {
  const res = await window.session.get(`${apiBase}/centralizedcontract`, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res;
};

export const createCentralizedContract = async (centralizedContract) => {
  const res = await window.session.post(`${apiBase}/centralizedcontract`, centralizedContract, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res;
};

export const saveCentralizedContract = async (centralizedContract) => {
  const res = await window.session.put(`${apiBase}/centralizedcontract`, centralizedContract, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res;
};

export const getCentralizedContract = async (centralizedContractId) => {
  const res = await window.session.get(`${apiBase}/centralizedcontract/${centralizedContractId}`, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
    params: {
      centralizedContractId: centralizedContractId,
      id: centralizedContractId,
    },
  });
  return res;
};

// Business Correspondence
export const getBusinessCorrespondenceList = async (businessId) => {
  if ([roles.DivisionAdmin, roles.DivisionUser].includes(loadUserRole())) {
    const res = await window.session.get(`${apiBase}/businesscorrespondences/open`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
      params: {
        page: 1,
        pageSize: 100000,
      },
    });
    return res;
  } else {
    const res = await window.session.get(`${apiBase}/businesscorrespondences`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
      params: {
        businessId: businessId,
      },
    });
    return res;
  }
};

export const handleBusinessCorrespondenceCreate = async (correspondence) => {
  console.log(correspondence);
  const res = await window.session.post(
    `${apiBase}/businesscorrespondences`,
    { ...correspondence, businessId: correspondence.contextId, category: 0 },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res.data;
};

export const handleBusinessCorrespondenceClose = async (correspondenceId) => {
  const res = await window.session.put(
    `${apiBase}/businesscorrespondences/close`,
    { correspondenceId: correspondenceId },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res.data;
};

export const handleBusinessCorrespondenceReopen = async (correspondenceId) => {
  const res = await window.session.put(
    `${apiBase}/businesscorrespondences/reopen`,
    { correspondenceId: correspondenceId },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res.data;
};

export const handleBusinessCorrespondenceUpdate = async (correspondence) => {
  const res = await window.session.put(`${apiBase}/businesscorrespondences`, correspondence, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res.data;
};

export const getBusinessCorrespondence = async (correspondenceId) => {
  const res = await window.session.get(`${apiBase}/businesscorrespondences/${correspondenceId}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res;
};

export const handleBusinessCorrespondencesReply = async (reply) => {
  const res = await window.session.post(
    `${apiBase}/businesscorrespondences/reply`,
    { ...reply, userId: getUserId() },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res.data;
};

export const handleBusinessCorrespondencesReplyReply = async (replyReply) => {
  const res = await window.session.post(
    `${apiBase}/businesscorrespondences/replytoreply`,
    { ...replyReply, userId: getUserId() },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res.data;
};

export const handleFilteredExport = async (request) => {
  const res = await window.session.post(
    `${apiBase}/businesses/filteredexport`,
    { ...request, counties: request.countiesLocatedIn, tags: request?.tags ? [request.tags] : null },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );

  handleDownloadCSV(res.data, "SDVOB Public Directory Export");
};

// Dashboard Endpoints

export const getAgencyDashboardData = async () => {
  const res = await window.session.get(`${apiBase}/agencies/${loadActiveOrgId()}/dashboard`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res.data;
};

// REPORTING

export const getExistingReport = async (reportId) => {
  const res = await window.session.get(`${apiBase}/reporting/${reportId}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res;
};

export const getExistingReports = async () => {
  const res = await window.session.get(`${apiBase}/reporting/`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res.data;
};

export const generateReport = async (reportData, reportColumns, reportType, pageSize, page, agencyId) => {
  const res = await window.session.post(
    `${apiBase}/reporting/generate/json`,
    {
      reportType,
      reactQueryBuilderJson: reportData,
      reportColumnsJson: reportColumns,
      pageSize,
      page,
      agencyId,
    },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res.data;
};

export const generateReportCsv = async (reportData, reportColumns, reportType, agencyId) => {
  const res = await window.session.post(
    `${apiBase}/reporting/generate/csv`,
    {
      reportType,
      reactQueryBuilderJson: reportData,
      reportColumnsJson: reportColumns,
      agencyId,
    },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res.data;
};

export const saveReport = async (reportData) => {
  const res = await window.session.post(
    `${apiBase}/reporting/save`,
    { ...reportData },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );
  return res;
};

export const deleteReport = async (reportId) => {
  const response = await window.session.post(`${apiBase}/reporting/delete/${reportId}`, undefined, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });

  return response;
};

export const updateReport = async (reportId, reportData) => {
  const response = await window.session.post(
    `${apiBase}/reporting/update/${reportId}`,
    { ...reportData },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        ...getAuthHeader(),
      },
    }
  );

  return response;
};

export const getReportTypes = async () => {
  const url = `${apiBase}/reporting/reportTypes`;

  const response = await window.session.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });

  return response.data;
};

// Log out end point
export const logoutSdves = async () => {
  const res = await window.session.post(`${apiBase}/users/logout`, undefined, {
    headers: {
      // Still need to pass existing token from okta for access - might be removed later.
      Authorization: `Bearer ${getAccessToken()}`,
      ...getAuthHeader(),
    },
  });
  return res;
};

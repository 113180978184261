import React from "react"
import { Link } from "react-router-dom";
import { getPlanType } from "../../../util/helpers"

const PlanSummary = (props) => {
  const { contract, awardedPlan, procurementId, procurement } = props;
  const type = getPlanType(procurement?.goal, awardedPlan?.goal);

  return (
    <div>
      <div className="row">
        <div className="col-5">Plan</div>
        <div className="col-7">{awardedPlan ? <Link to={`/procurements/${procurementId}/plans-waivers`}>See {type}</Link> : <Link href={`/procurements/${procurementId}/plans-waivers/submit`} className="btn btn-secondary">Submit Plan</Link>}</div>
      </div>
      <div className="row">
        <div className="col-5">Plan Status</div>
        <div className="col-7">{awardedPlan ? awardedPlan?.status : "N/A"}</div>
      </div>
    </div>
  )
}

export default PlanSummary;

import React, { useState } from "react";
import { withFormikFormComponent } from "../../Forms/FormikForm";
import { Form } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ConfirmPaymentsFormInternal = (props) => {
  const { formikBag, confirmed, onSubmit, index } = props;
  const { values, isSubmitting } = formikBag;
  const { contractId, contractorId, paymentId } = values;
  const [paymentConfirmed, setPaymentConfirmed] = useState(confirmed);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <section id="confirm-payments-form">
      <Form>
        <div className="d-flex">
          {isLoading ? (
            <FontAwesomeIcon icon={"spinner"} className="fa-spin" />
          ) : (
            <>
              <button
                id={`payment-${index}-confirm-action`}
                type="submit"
                className="faux-link btn btn-secondary btn-sm mb-0"
                onClick={async () => {
                  setIsLoading(true);
                  await onSubmit(values, "confirm");
                  setIsLoading(false);
                  setPaymentConfirmed(true);
                }}
              >
                <FontAwesomeIcon className="ml-2 mt-1 text-success" icon={"check"} size="" />
              </button>
              <button
                id={`payment-${index}-reject-action`}
                type="submit"
                className="faux-link btn btn-secondary btn-sm mb-0"
                onClick={async () => {
                  setIsLoading(true);
                  await onSubmit(values, "reject");
                  setIsLoading(false);
                  setPaymentConfirmed(true);
                }}
              >
                <FontAwesomeIcon className="ml-2 mt-1 text-danger" icon={"times"} size="" />
              </button>
            </>
          )}
        </div>
      </Form>
    </section>
  );
};

const ConfirmPaymentsForm = withFormikFormComponent(ConfirmPaymentsFormInternal);
export default ConfirmPaymentsForm;

import React from "react";
import { outputMoney, outputPercentage } from "../../../util/helpers";

const Summary = (props) => {
  const { monthPaymentsTotal, complianceReport, totalSalesForMonth } = props;

  return (
    <div>
      <p>Total amount disbursed to all subcontractors and suppliers this month</p>
      <div className="row">
        <div className="col-md-5">
          <strong>Total Payments to SDVOBs for Month</strong>
        </div>
        <div className="col-md-7">{outputMoney(monthPaymentsTotal)}</div>
      </div>

      <div className="row">
        <div className="col-md-5">
          <strong>{complianceReport?.isOGSCentralized ? "Total Contractor Reported Sales for Month" : "Total Payments to Prime Contractor for Month"}</strong>
        </div>
        <div className="col-md-7">{outputMoney(complianceReport?.isOGSCentralized ? totalSalesForMonth : complianceReport?.totalPrimePaymentsForMonth)}</div>
      </div>
    </div>
  );
};

export default Summary;

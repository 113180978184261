import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { outputProcurementStatus, truncate } from "../../util/helpers";
import { loadUserRole, roles } from "../../util/userHelper";
import FilterTableForm from "../Forms/Input/FilterTableForm";
import { procurementStatuses } from "../../util/typelistHelper";

function ProcurementsList(props) {
  const { procurements, isLoadingProcurements } = props;

  const initialItems = procurements?.length
    ? procurements.map((item) => {
        return {
          ...item,
          id: <Link to={`/procurements/${item.id}`}>{item.id}</Link>,
          solicitationNumberLink: <Link to={`/procurements/${item.id}`}>{item.solicitationNumber}</Link>,
          status: item.exclusionType === "ExemptExcluded" ? "Exempt/Excluded" : outputProcurementStatus(item.status),
          descriptionTruncated: truncate(item?.description),
          contractId: item.displayContractNumber ? item.displayContractNumber : "Contract not yet assigned",
          contract: item?.contract ? <span className="text-nowrap" dangerouslySetInnerHTML={{ __html: item.displayContractNumber.replace("(", "<br>(") }}></span> : "Contract not yet assigned",
          isOGSCentralized: item?.contract?.isOGSCentralized ? "Yes" : "No",
          isOGSCentralizedIcon: item?.contract?.isOGSCentralized ? (
            <FontAwesomeIcon className="mx-3 text-primary" icon={["fas", "check"]} />
          ) : (
            <FontAwesomeIcon className="ml-2 mt-1 text-primary" icon={["fa", "ban"]} />
          ),
          plan: item.approvedPlan ? "Has Plan" : item.planCount,
        };
      })
    : [];

  let FilterListConfig = {
    solicitationNumber: {},
    status: {
      type: "typeahead",
      options: procurementStatuses,
    },
    isOGSCentralized: {
      type: "typeahead",
      options: [
        { id: "Yes", label: "Yes" },
        { id: "No", label: "No" },
      ],
    },
    contractId: {},
    description: {},
  };

  let Columns = [
    {
      Header: "Centralized",
      accessor: "contract.isOGSCentralized", // accessor is the "key" in the data
      defaultCanSort: true,
      sortType: "basic",
      filterCode: "isOGSCentralized",
      isVisible: false,
    },
    {
      Header: "Centralized",
      accessor: "isOGSCentralizedIcon", // accessor is the "key" in the data
      defaultCanSort: true,
      sortType: "basic",
      // filterCode: "isOGSCentralized",
    },
    {
      Header: "Solicitation Number",
      accessor: "solicitationNumberLink", // accessor is the "key" in the data
      defaultCanSort: true,
      sortType: "number",
      filterCode: "solicitationNumberLink",
    },
    {
      Header: "Solicitation Number",
      accessor: "solicitationNumber", // accessor is the "key" in the data
      defaultCanSort: true,
      sortType: "number",
      filterCode: "solicitationNumber",
      isVisible: false,
    },
    {
      Header: "Status",
      accessor: "status", // accessor is the "key" in the data
      defaultCanSort: true,
      sortType: "basic",
      filterCode: "status",
    },
    {
      Header: "Description",
      accessor: "descriptionTruncated", // accessor is the "key" in the data
      defaultCanSort: true,
      // filterCode: "description",
    },
    {
      Header: "Description",
      accessor: "description", // accessor is the "key" in the data
      defaultCanSort: true,
      filterCode: "description",
      isVisible: false,
    },
    {
      Header: "Contract",
      accessor: "contract", // accessor is the "key" in the data
      defaultCanSort: true,
      sortType: "basic",
      filterCode: "contractId",
    },
    {
      Header: "# of Plans",
      accessor: "plan", // accessor is the "key" in the data
      defaultCanSort: true,
      sortType: "basic",
      filterCode: "plan",
    },
  ];

  // console.log(initialItems);
  return (
    <section id="procurements">
      <div>
        <h2>Procurements List</h2>
        <div>
          {initialItems?.length > 0 ? (
            <>
              <p>Please select the procurement you would like to work on today.</p>
              <FilterTableForm tableName="procurements-list" Columns={Columns} FilterListConfig={FilterListConfig} tableItems={initialItems} />
            </>
          ) : (
            <h2>
              {isLoadingProcurements ? (
                <span className="loader loader-pulse">Loading Procurements...</span>
              ) : (
                `You have no procurements${loadUserRole() === roles.AgencyUser ? " assigned to you" : ""}.`
              )}
            </h2>
          )}
        </div>
      </div>
    </section>
  );
}
export default ProcurementsList;

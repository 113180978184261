import React, { useEffect, useState } from "react";
import { outputMoney } from "../../../util/helpers";
import { getActivityReportDetail } from "../../../util/api";
import ActivityReportDetails from "./ActivityReportDetail";

const ActivityReportDisplayData = (props) => {
  let { report, isVersion } = props;

  return (
    <>
      {report?.id ? (
        <>
          <div className="mb-4">
            <div className="row mb-2">
              <div className="col-5">Total Value of Contracts Awarded in Period</div>
              <div className="col-7">{outputMoney(report?.totalContractsValue)}</div>
            </div>
            <div className="row mb-2">
              <div className="col-5">Total Agency Spending</div>
              <div className="col-7">{outputMoney(report?.nonExemptNonExcludedPaymentTotal + report?.totalAdditionalSpendOnSdvobs)}</div>
            </div>
            <div className="row mb-2">
              <div className="col-5">Non-Exempt Non-Excluded Payment Total</div>
              <div className="col-7">{outputMoney(report?.nonExemptNonExcludedPaymentTotal)}</div>
            </div>
            <div className="row mb-2">
              <div className="col-5">Total Disbursements to SDVOB Vendors</div>
              <div className="col-7">{outputMoney(report?.totalDisbursementsToSdvobs)}</div>
            </div>
            <div className="row mb-2">
              <div className="col-5">Total Additional Spend on SDVOBs</div>
              <div className="col-7">{outputMoney(report?.totalAdditionalSpendOnSdvobs)}</div>
            </div>
            <div className="row mb-2">
              <div className="col-5">Total Value of Awards to SDVOBs</div>
              <div className="col-7">{outputMoney(report?.totalValueOfAwards)}</div>
            </div>
            <div className="row mb-2">
              <div className="col-5">Total Number of Awards to SDVOBs</div>
              <div className="col-7">{report?.totalNumberOfAwards}</div>
            </div>
            <div className="row mb-2">
              <div className="col-5">Total Number of Waivers Granted</div>
              <div className="col-7">{report?.totalNumberOfWaiversGranted}</div>
            </div>
            <div className="row mb-2">
              <div className="col-5">Utilization Percentage</div>
              <div className="col-7">{report?.utilizationPercentage}%</div>
            </div>
            <div className="row mb-2">
              <div className="col-5">Set-Asides</div>
              <div className="col-7">{(report?.totalSetAsides)}</div>
            </div>
            <div className="row mb-2">
              <div className="col-5">Discretionaries</div>
              <div className="col-7">{(report?.totalDiscretionaries)}</div>
            </div>

          </div>

          {!isVersion ? <ActivityReportDetails reportId={report.id} /> : null}
        </>
      ) : null}
    </>
  );
};

export default ActivityReportDisplayData;

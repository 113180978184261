import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { withFormikFormComponent } from "../../Forms/FormikForm";
import { Form, Field, FastField, setFieldValue } from "formik";
import ArrayWrapperDynamic from "../../Forms/helpers/ArrayWrapperDynamic";
import DocumentWrapper from "../../Forms/helpers/DocumentWrapper";
import InputComponent from "../../Forms/Input/InputComponent";
import UtilizationPlanSubcontractor from "../Sections/PlansAndWaiversSubmit/UtilizationPlanSubcontractor";
import PlanCalculator from "../Sections/PlansAndWaiversSubmit/PlanCalculator";
import { schemas } from "../../../util/errorHelper";
import DebugModal from "../../Debug/DebugModal";
import { getPercentage, getPlanType, outputMoney, truncate } from "../../../util/helpers";
import { getActiveOrg, loadUserRole, roles } from "../../../util/userHelper";
import { getProcurements, getOpenProcurements, getProcurement } from "../../../util/api";
import ReactiveButton from "../../Forms/Input/ReactiveButton";

const UtilizationPlanSubmitFormInternal = (props) => {
  const { plan, formikBag, agenciesOptions, businessesOptions, businesses, isRevision, setProcurement, primeIsCertified, procurement, onSubmit = { onSubmit } } = props;
  const { values, resetForm, setFieldValue, isValid, isSubmitting } = formikBag;
  const { agencyId, procurementId } = values;
  const [isCalculatorVisible, setIsCalculatorVisible] = useState(true);
  const [procurements, setProcurements] = useState([]);
  const [procurementsOptions, setProcurementsOptions] = useState([]);
  const [selectedProcurement, setSelectedProcurement] = useState(procurement);
  // const [realValues, setRealValues] = useState({ realGoal: 0, realValue: 0 });
  const toggleCalculator = () => {
    setIsCalculatorVisible(!isCalculatorVisible);
  };
  const agencyGoal = selectedProcurement?.goal ? selectedProcurement?.goal : 6;
  const [isLoading, setLoading] = useState(false);

  // Get procurements for dropdown, and refresh if agency is changed
  useEffect(() => {
    // console.log("agency id");
    async function fetchOpenAgencyProcurements() {
      const newProcurements = await getOpenProcurements(values.agencyId);
      const procurementsOrdered = newProcurements?.data?.procurements.sort((a, b) => a.description.localeCompare(b.description));
      setProcurements(procurementsOrdered);
    }

    if (values.agencyId && !isRevision && loadUserRole() === roles.Business) {
      setLoading(true);
      fetchOpenAgencyProcurements();
    }
  }, [values.agencyId]);

  useEffect(() => {
    setSelectedProcurement(procurement);
  }, [procurement]);

  // Set the procurement so we can know the goal once we know where procurement context is coming from
  useEffect(() => {
    // console.log("procurement id");
    if (!isRevision && roles.Business === loadUserRole()) {
      const procurementToUse = procurements.find((procurement) => procurement.id === values.procurementId);
      // console.log(procurementsOptions, procurementToUse, values.procurementId);
      setSelectedProcurement(procurementToUse);
      setFieldValue("goal", procurementToUse?.goal);
      setFieldValue("term", procurementToUse?.contractTerm);
      setFieldValue("value", procurementToUse?.contract?.currentValue);
    }
  }, [values.procurementId]);

  // Format procurements for dropdowns
  // Reset form so correct selections show
  useEffect(() => {
    // const procurementsAcceptingSubmissions = procurements.filter(item => item?.status === 'AcceptingSubmissions');
    let newProcurementsOptions = procurements?.length
      ? procurements.map((item) => {
          return [item?.id, item?.solicitationNumber];
        })
      : [];

    formikBag.resetForm();
    setFieldValue("agencyId", agencyId);
    setProcurementsOptions(newProcurementsOptions);

    setLoading(false);
  }, [procurements]);

  // Plan Calculator
  useEffect(() => {
    // console.log("calculate");
    const calculateForStandard = () => {
      const realValue = values?.subcontracts?.reduce((a, b) => a + (b.value ? parseInt(b.value) : 0), 0);
      const realGoal = getPercentage(realValue, values.value);
      // console.log(realGoal, realValue);
      // setRealValues({ realGoal: realGoal, realValue: realValue });
      setFieldValue("realGoal", realGoal);
      setFieldValue("realValue", realValue);
      setFieldValue("totalSdvob", values.subcontracts.length);
    };
    const calculateForTerm = () => {
      const realGoal = values?.subcontracts?.reduce((a, b) => a + (b.goal ? parseInt(b.goal) : 0), 0);
      // setRealValues({ realGoal: realGoal });
      setFieldValue("realGoal", realGoal);
      setFieldValue("totalSdvob", values.subcontracts.length);
    };
    if (!primeIsCertified && values?.term === "Term") {
      // console.log("term");
      calculateForTerm();
    } else if (!primeIsCertified) {
      // console.log("not term");
      calculateForStandard();
    }
  }, [values]);

  const selectProcurementMsg = () => {
    if (isLoading) {
      return "Loading procurements...";
    } else if (!isLoading && values?.agencyId && procurementsOptions?.length === 0) {
      return (
        <p>
          The agency is not accepting new submissions for any contracts. Check the <Link to="/utilization-plans">Utilization Plans</Link> screen to see the status of previously submitted plans.
        </p>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <DebugModal formikBag={formikBag} name="Formik Bag" />
      <Form>
        <div className="field-group row">
          {isRevision || (procurementId && [roles.AgencyAdmin, roles.AgencyUser].includes(loadUserRole())) ? (
            <div className="col-12">
              <h2>Procurement: {selectedProcurement?.description}</h2>
              <p>Agency: {selectedProcurement?.agency?.name}</p>

              {plan?.status === "Deficient" ? (
                <div className="alert alert-primary">
                  <p>Deficiency notes: {plan?.messages[0]?.content}</p>
                </div>
              ) : null}
            </div>
          ) : (
            <>
              {agenciesOptions?.length > 1 ? (
                <FastField wrapperClass="mb-3 col-md-6" name={"agencyId"} component={InputComponent} label={"Select Agency"} options={agenciesOptions} type="select"></FastField>
              ) : null}
              {procurementsOptions?.length && !isLoading ? (
                <Field
                  wrapperClass="mb-3 col-md-6"
                  name={"procurementId"}
                  component={InputComponent}
                  label={"Procurement"}
                  options={[["", "Select Procurement"], ...procurementsOptions]}
                  type="select"
                ></Field>
              ) : (
                <div className="col-12 mb-3 pt-3">{selectProcurementMsg()}</div>
              )}
            </>
          )}

          {/* {contractsOptions.length > 1 ?
            <FastField wrapperClass="mb-3" name={"contractId"} component={InputComponent} label={"Select Contract"} options={contractsOptions} type="select"></FastField>
            : null} */}
        </div>

        {selectedProcurement?.goal ? (
          <h3>
            Contract SDVOB Goal: <span>{selectedProcurement?.goal}%</span>
          </h3>
        ) : null}

        <hr />

        {values.procurementId ? (
          <>
            {values.term === "Standard" ? (
              <>
                <p className="text-uppercase text-primary">Plan Details</p>
                <div className="field-group row">
                  {/* <FastField wrapperClass="mb-3 col-md-6" name={"goal"} component={InputComponent} label={"Targeted Goal %"} type="number" /> */}
                  {selectedProcurement?.contract?.currentValue ? (
                    <div className="col">Value: {outputMoney(selectedProcurement?.contract?.currentValue)}</div>
                  ) : (
                    <FastField wrapperClass="mb-3 col-md-6" name={"value"} component={InputComponent} label={"Contract Value/Bid value"} type="number" />
                  )}

                  {/* {
                !primeIsCertified && (
                  <FastField wrapperClass="mb-3" name={"totalSdvob"} component={InputComponent} label={"Total SDVOB"} type="number" value={values?.subcontracts?.length} readonly={true} />
                )
              } */}

                  <FastField wrapperClass="mb-3 col-md-6" component={InputComponent} name={"realGoal"} label={"Goal"} type="hidden" />
                  <FastField wrapperClass="mb-3 col-md-6" component={InputComponent} name={"realValue"} label={"Value"} type="hidden" />
                </div>
                <hr />
              </>
            ) : null}

            {primeIsCertified ? (
              <>
                <p>
                  As a NYS Certified SDVOB, your firm's performance on the contract will count towards the SDVOB goal and you are not required to submit a utilization plan to include the participation
                  of additional NYS Certified SDVOBs.
                </p>
              </>
            ) : (
              <>
                <div>
                  <p className="text-uppercase text-primary">Subcontractors</p>
                  <p># of SDVOBs Subcontractors: {values?.totalSdvob}</p>
                  <UtilizationPlanSubcontractor
                    businesses={businesses}
                    businessesOptions={businessesOptions}
                    formikBag={formikBag}
                    isRevision={isRevision}
                    procurement={procurement}
                    atLeastOne={false}
                    removalLabel="Remove above subcontractor"
                  />
                </div>

                <hr />

                {values.realGoal < agencyGoal ? (
                  <>
                    <h4>Waiver submission notice</h4>
                    <p>
                      You aren't meeting a full SDVOB utilization plan goal of <strong>{agencyGoal}%</strong> set by the Agency on this procurement, and will be requesting a waiver. Try increasing
                      your distributions to SDVOBs for a full utilization plan or explain your good faith efforts
                    </p>
                    <FastField wrapperClass="" type="textarea" name={`waiverDescription`} component={InputComponent} label={"Tell us about your efforts in finding an SDVOB for this contract"} />
                    <p>Upload any supporting documentation you might have, demonstrating your efforts.</p>
                    <ArrayWrapperDynamic
                      btnSize="small"
                      name={`documents`}
                      documentType="AgencyGoalPlanDocument"
                      additionLabel="Add Document"
                      removalLabel="Remove Document"
                      component={DocumentWrapper}
                      formikBag={formikBag}
                      atLeastOne={false}
                      initialValues={[]}
                    />
                  </>
                ) : null}
              </>
            )}

            {!isValid ? <h3>Please complete all required information</h3> : <ReactiveButton type="submit" className="small btn-secondary margin-auto" label="Create" processing={isSubmitting} />}
          </>
        ) : null}
      </Form>

      {!primeIsCertified && values?.goal && values?.term == "Standard" ? (
        <PlanCalculator toggleCalculator={toggleCalculator} active={isCalculatorVisible} realValue={values.realValue} realGoal={values.realGoal} agencyGoal={agencyGoal} values={values} />
      ) : null}
    </>
  );
};

const UtilizationPlanSubmitForm = withFormikFormComponent(UtilizationPlanSubmitFormInternal, schemas.businessUtilizationPlanSchema);
export default UtilizationPlanSubmitForm;

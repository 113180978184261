import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPlan } from "../../../util/api";
import { loadActiveOrgId } from "../../../util/userHelper";
import PlansAndWaiversBusiness from "../Views/PlansAndWaiversBusiness";

const ViewUtilizationPlan = () => {
  const { planId } = useParams();
  const [plan, setPlan] = useState(null);

  const procurement = plan?.procurement;

  useEffect(() => {
    async function fetchData() {
      const planRes = await getPlan(planId);
      setPlan(planRes);
      console.log(planRes);
    }

    fetchData();
  }, [planId]);

  return (
    <>
      {plan ? (
        <PlansAndWaiversBusiness
          contract={procurement?.contract}
          procurement={procurement}
          procurementId={procurement?.id}
          activeBusinessId={loadActiveOrgId()}
          version={plan ? plan?.history?.length + 1 : 1}
          planVersion={plan}
        />
      ) : null}
    </>
  );
};

export default ViewUtilizationPlan;

import React from "react";
import BigButton from "../CTAs/BigButton";
import { useHistory } from "react-router-dom";
import { getBusinessLegalName, getActiveOrg } from "../../util/userHelper";
import { createApplication } from "../../util/api";
import { getLocalDate } from "../../util/helpers";

const BusinessPortal = ({ userInfo, activeOrgId }) => {
  const history = useHistory();
  const activeOrg = getActiveOrg(userInfo, activeOrgId);
  const { applications } = activeOrg || [];
  const application = applications?.find((application) => "Certified" !== application.status);
  // const certifiedApplication = applications?.find(application => ["Certified"].includes(application.status));
  // const recertTimer = activeOrg?.timers.find(timer => timer.type === "FiveYearCertification");
  const allowNew = () => {
    const result = !application && activeOrg.canRecertify;
    return result;
  };

  const handleCreateApplication = async () => {
    const resObj = await createApplication();
    if (resObj?.status === 201) {
      // history.push(`/application/${resObj.data.id}`)
      localStorage.removeItem("tabs");
      history.push(`/document-checklist/${resObj.data.id}`);
    }
  };

  const ApplicationButton = (application) => {
    let ApplicationButton = null;
    const {
      application: { phase, status, id, timers, isCertifiedSdvob, isActive },
    } = application;

    if (["Preparation"].includes(phase)) {
      ApplicationButton = (
        <BigButton
          icon={"pencil-alt"}
          iconColor="secondary"
          heading={getBusinessLegalName(activeOrg)}
          content="Application In Progress"
          ctaText="Continue"
          route={`/application/${id}`}
          id="application-in-progress"
        />
      );
    } else if (["InitialReview"].includes(phase) && ["New", "InProgress", "Submitted"].includes(status)) {
      ApplicationButton = (
        <BigButton
          icon={"hourglass-start"}
          iconColor="secondary"
          heading={getBusinessLegalName(activeOrg)}
          content="Application Submitted, Awaiting Review"
          ctaText="View Application"
          route={`/application/${id}`}
          id="application-awaiting-review"
        />
      );
    } else if (["InitialReview"].includes(phase) && ["Deficient"].includes(status)) {
      const relTimer = timers?.find((timer) => timer?.type === "Deficiency");
      const timeLeft = relTimer?.daysLeft ? relTimer?.daysLeft : null;

      ApplicationButton = (
        <BigButton
          icon={"hourglass-start"}
          iconColor="primary"
          heading={getBusinessLegalName(activeOrg)}
          content={`Initial Review: Deficient ${timeLeft ? "(" + timeLeft + " business days left)" : ""}`}
          ctaText="Submit Additional Info"
          route={`/application/${id}`}
          id="submit-additional-info"
        />
      );
    } else if (["InDepthReview"].includes(phase) && ["New", "InProgress", "Submitted"].includes(status)) {
      ApplicationButton = (
        <BigButton
          icon={"hourglass-start"}
          iconColor="primary"
          heading={getBusinessLegalName(activeOrg)}
          content="In-Depth Review"
          ctaText="View Application"
          route={`/application/${id}`}
          id="view-in-depth-review"
        />
      );
    } else if (["InDepthReview"].includes(phase) && ["Deficient"].includes(status)) {
      const relTimer = timers?.find((timer) => timer?.type === "AdditionalInfoNeeded");
      const timeLeft = relTimer?.daysLeft ? relTimer?.daysLeft : "Unknown";
      ApplicationButton = (
        <BigButton
          icon={"hourglass-start"}
          iconColor="primary"
          heading={getBusinessLegalName(activeOrg)}
          content={`In-Depth Review: Additional Info ${timeLeft ? "(" + timeLeft + " business days left)" : ""}`}
          ctaText="Submit Additional Info"
          route={`/application/${id}`}
          id="view-in-depth-deficient"
        />
      );
    } else if (["DirectorReview"].includes(phase) && ["Submitted", "New", "InProgress"].includes(status)) {
      ApplicationButton = (
        <BigButton
          icon={"hourglass-start"}
          iconColor="primary"
          heading={getBusinessLegalName(activeOrg)}
          content="Director Review"
          ctaText="View Application"
          route={`/application/${id}`}
          id="view-director-review"
        />
      );
    } else if (["DirectorReview"].includes(phase) && ["Deficient"].includes(status)) {
      const relTimer = timers?.find((timer) => timer?.type === "AdditionalInfoNeeded");
      const timeLeft = relTimer?.daysLeft ? relTimer?.daysLeft : "Unknown";
      ApplicationButton = (
        <BigButton
          icon={"hourglass-start"}
          iconColor="primary"
          heading={getBusinessLegalName(activeOrg)}
          content={`Director Review: Additional Info Needed ${timeLeft ? "(" + timeLeft + " business days left)" : ""}`}
          ctaText="Submit Additional Info"
          route={`/application/${id}`}
          id="directory-review-deficient"
        />
      );
    } else if (["Denied"].includes(status)) {
      const appealTimer = timers?.find((timer) => timer?.type === "AbleToAppeal");
      const timeLeft = appealTimer?.daysLeft ? appealTimer?.daysLeft : null;
      ApplicationButton = (
        <BigButton
          icon={"window-close"}
          iconColor="primary"
          heading={getBusinessLegalName(activeOrg)}
          content={`Denied<br/ >${timeLeft ? `${timeLeft} days left to appeal` : ""}`}
          ctaText="View Application"
          route={`/application/${id}`}
          id="reapply-denied"
        />
      );
    } else if (["FinalDenial", "AppealDenied"].includes(status)) {
      const deniedTimer = timers?.find((timer) => timer?.type === "Denied");
      const timeLeft = deniedTimer?.daysLeft ? deniedTimer?.daysLeft : null;
      ApplicationButton = (
        <BigButton
          icon={"window-close"}
          iconColor="primary"
          heading={getBusinessLegalName(activeOrg)}
          content={`Denied<br/ > ${timeLeft ? `Reapply in ${timeLeft} days` : ""}`}
          ctaText={timeLeft ? `` : "Reapply"}
          id="create-reapplication"
          onClick={timeLeft ? null : handleCreateApplication}
        />
      );
    } else if (["Appealed"].includes(status)) {
      ApplicationButton = (
        <BigButton
          icon={"window-close"}
          iconColor="primary"
          heading={getBusinessLegalName(activeOrg)}
          content={`Appealed<br/ >Under review`}
          ctaText="View Application"
          route={`/application/${id}`}
          id="appealed"
        />
      );
    } else if (["Withdrawn"].includes(status)) {
      const withdrawnTimer = timers?.find((timer) => timer?.type === "Withdraw");
      // const daysLeft = 0;
      const daysLeft = withdrawnTimer?.daysLeft ? withdrawnTimer?.daysLeft : "Unknown";
      ApplicationButton = (
        <BigButton
          icon={"window-close"}
          iconColor="gray"
          heading={getBusinessLegalName(activeOrg)}
          content="Application Withdrawn"
          ctaText={`Reapply ${daysLeft > 0 ? `in ${daysLeft} days` : ""}`}
          route={daysLeft > 0 ? `/application/${id}` : ``}
          onClick={daysLeft > 0 ? null : handleCreateApplication}
          id="reapply-withdrawn"
        />
      );
    } else if (isCertifiedSdvob && isActive) {
      ApplicationButton = (
        <BigButton
          icon={"award"}
          iconColor="secondary"
          heading={getBusinessLegalName(activeOrg)}
          content={`SDVOB Certified<br />expires on ${getLocalDate(activeOrg?.certificationExpDate)}`}
          ctaText="Update Business"
          route={`/business/${activeOrgId}`}
          id="view-certified"
        />
      );
    } else if (isCertifiedSdvob) {
      ApplicationButton = (
        <BigButton
          icon={"window-close"}
          iconColor="primary"
          heading={getBusinessLegalName(activeOrg)}
          content={`Certification Revoked`}
          ctaText="Update Business"
          route={`/business/${activeOrgId}`}
          id="view-certified"
        />
      );
    } else if (["Approved"].includes(status)) {
      ApplicationButton = (
        <BigButton
          icon={"award"}
          iconColor="secondary"
          heading={getBusinessLegalName(activeOrg)}
          content="Application Approved - Awaiting Director Action"
          ctaText="View Application"
          route={`/application/${id}`}
          id="view-certified"
        />
      );
    } else if (status === "Needs Re-cert") {
      ApplicationButton = (
        <BigButton
          icon={"award"}
          iconColor="primary"
          heading={getBusinessLegalName(activeOrg)}
          content="Re-certify by MM/DD//YYYY"
          ctaText="Review Application"
          route={`/application/${id}`}
          id="application-recertify"
        />
      );
    }
    return ApplicationButton;
  };

  return (
    <section>
      {activeOrg?.legalName ? <h2>Certification Actions for {activeOrg?.legalName} </h2> : null}
      <div className="row">
        {/* {activeOrg?.applications?.map((application, index) => (
          <ApplicationButton key={index} application={application} />
        ))} */}
        {activeOrg?.isCertifiedSdvob ? <ApplicationButton application={activeOrg} /> : null}
        {application && <ApplicationButton application={application} />}
        {activeOrg ? (
          <>
            {allowNew() && (
              <BigButton
                icon={"arrow-alt-circle-right"}
                iconColor="secondary"
                heading="New Application"
                ctaText={`${activeOrg?.isCertifiedSdvob ? `Apply to recertify your Service-Disabled Veteran Owned Business` : "Apply to become a Service-Disabled Veteran Owned Business"} `}
                id="create-application"
                onClick={handleCreateApplication}
              />
            )}
          </>
        ) : (
          <BigButton
            icon={"arrow-alt-circle-right"}
            iconColor="secondary"
            heading="New Business"
            ctaText={`I am a NYS Business setting up my contractor portal for the first time.`}
            id="create-application"
            route={`/signup/prime-contractor`}
          />
        )}
      </div>
    </section>
  );
};

export default BusinessPortal;

import React, { Component, useState } from "react";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { Security } from "@okta/okta-react";
import config from "../config";
import Home from "../Layouts/Base";
import Footer from "../components/Footer/Footer";
import "./App.scss";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import AlertFailureComponent from "../components/Forms/Input/AlertFailureComponent";
import Axios from "axios";
import ScrollToTop from "../components/ScrollToTop";

export const apiBase = process.env.REACT_APP_API_BASE;

const session = Axios.create({
  baseURL: apiBase,
});

window.session = session;

// Response interceptor for API calls
const oktaAuth = new OktaAuth(config.oidc);

const App = () => {
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    // Recommended by Okta but for some reason history comes back as null
    // if (history) {
    // history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    const url = toRelativeUrl(originalUri || "/", window.location.origin);
    window.location.pathname = url;
    // }
  };
  const [error1, setError1] = useState("");
  const [errorMessageTitle, setErrorMessageTitle] = useState("Your request could not be completed");
  const [hasNetworkError, setHasNetworkError] = useState(false);


   function cleanErrorMessage(errorMessage){
      var errToShow = errorMessage?.replaceAll('\\"','"') ?? '';

      if(errToShow.startsWith('"'))
        errToShow = errToShow.slice(1);

      if(errToShow.endsWith('"'))
        errToShow = errToShow.substring(0,errToShow.length-1);

      console.log('errToShow.startsWith()',errToShow.startsWith('"'))
      console.log('errToShow',errToShow)

      return errToShow;
   }

  session.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {




      const originalRequest = error.config;
      if (error?.response?.status === 401) {
        // && !originalRequest._retry
        // originalRequest._retry = true;
        // window.location.href = "/logout1";
        // return session(originalRequest);
      } else if ([200, 201].includes(error?.response?.status)) {
        return session(originalRequest);
      } else if (error?.response) {
        const errorText = error?.response?.data?.message ? error?.response?.data?.message : JSON.stringify(error?.response?.data);
        setError1(cleanErrorMessage(errorText));
      } else if (JSON.parse(JSON.stringify(error))?.message === "Network Error") {
        setHasNetworkError(true);
      } else {
        setError1(cleanErrorMessage(JSON.stringify(error)));
      }

      if (error?.response?.data?.messageTitle) {
        setErrorMessageTitle(error?.response?.data?.messageTitle);
      }

      return Promise.reject(error);
    }
  );

  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <AlertFailureComponent errorMessageTitle={errorMessageTitle} message={error1} setMessage={setError1} />
          <Home />
        </Security>
      </Router>
      <Footer />
    </div>
  );
};

export default App;

import React from "react";
import { withFormikFormComponent } from "../../Forms/FormikForm";
import { FastField, Form } from "formik";
import get from "lodash/get";
import { Button } from "react-bootstrap";
import DatePicker from "react-date-picker";
import InputComponent from "../../Forms/Input/InputComponent";
import { businessTypes, exclusionTypes, contractTerms, contractCategories } from "../../../util/typelistHelper";
import { schemas } from "../../../util/errorHelper";
import ReactiveButton from "../../Forms/Input/ReactiveButton";
import DebugModal from "../../Debug/DebugModal";

const ProcurementUpdateFormInternal = (props) => {
  const { awardedPlan, formikBag, centralizedContractsOptions } = props;
  const { values, isValid, isSubmitting, setFieldValue } = formikBag;

  return (
    <Form className="">
      <DebugModal formikBag={formikBag} name="Formik Bag" />
      <FastField
        wrapperClass=""
        name={"exclusionType"}
        component={InputComponent}
        required={false}
        label={"SDVOB Participation"}
        type="select"
        options={[["", "Choose SDVOB Participation"], ...exclusionTypes]}
      />

      {values?.exclusionType === "Eligible" && values?.contractTerm === "Standard" && (
        <FastField wrapperClass="" name={"currentValue"} component={InputComponent} required={false} label={"Contract Value for SDVOB Participation"} type="number" />
      )}

      {values?.contractTerm !== "Term" && (values?.contract?.value !== values?.contract?.currentValue || (values?.exclusionType === "Eligible" && values?.contract?.value !== values?.currentValue)) ? (
        <FastField wrapperClass="" name={"currentValueOverrideReason"} component={InputComponent} required={false} label={"Contract Value Reason"} type="textarea" />
      ) : null}

      <FastField wrapperClass="" name={"contractTerm"} component={InputComponent} label={"Compliance Calculation Type"} type="select" options={contractTerms} />

      <FastField
        wrapperClass=""
        name={"contractCategory"}
        component={InputComponent}
        required={false}
        label={"Procurement Type"}
        type="select"
        options={[["", "Choose Procurement Type"], ...contractCategories]}
      />

      <FastField
        wrapperClass=""
        name={"contractServiceType"}
        component={InputComponent}
        required={false}
        label={"Classification"}
        type="select"
        options={[["", "Choose Classification"], ...businessTypes]}
      />

      {values?.exclusionType !== "ExemptExcluded" && (
        <>
          <div name="start-date-picker" className="mb-2">
            <label htmlFor="startDate">
              Plan Submission Start Date <span className="required text-danger">*</span>
            </label>
            <DatePicker name={"startDate"} onChange={(value) => setFieldValue(`startDate`, value)} value={get(values, `startDate`)} maxDate={new Date()} />
          </div>

          <div name="end-date-picker" className="mb-2">
            <label htmlFor="endDate">
              Plan Submission End Date <span className="required text-danger">*</span>
            </label>
            <DatePicker name={"endDate"} onChange={(value) => setFieldValue(`endDate`, value)} value={get(values, `endDate`)} minDate={new Date()} />
          </div>
        </>
      )}

      <FastField wrapperClass="" name={"description"} component={InputComponent} required={true} label={"Description"} type="textarea" />

      {values?.contract?.isOGSCentralized && centralizedContractsOptions?.length ? (
        <FastField wrapperClass="" name={"centralizedContractId"} component={InputComponent} required={false} label={"Centralized Contract"} type="select" options={centralizedContractsOptions} />
      ) : null}

      {!isValid ? <h3>Please complete all required information</h3> : <ReactiveButton type="submit" className="btn-md btn-block btn-secondary margin-auto" label="Update" processing={isSubmitting} />}
    </Form>
  );
};
const ProcurementUpdateForm = withFormikFormComponent(ProcurementUpdateFormInternal, schemas.procurementUpdateSchema);
export default ProcurementUpdateForm;

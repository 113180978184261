import React from "react";
import { handleDocumentClick } from "../../../../util/helpers";

const ReviewSubmitFormLine = (props) => {
  const { name, value, type, downloadId } = props;
  // console.log(props);

  return (
    <>
      <div className="row mb-1">
        <hr />
        <div className="col-5 font-weight-bold">{name}</div>
        {downloadId ?
          <div className="col-7">
            <button className="faux-link" onClick={() => {
              handleDocumentClick(downloadId)
            }}>{value}</button>
          </div>
          : <div className="col-7" dangerouslySetInnerHTML={{ __html: type === "boolean" ? (value ? "Yes" : "No") : value }}></div>}
      </div>
    </>
  );
};
export default ReviewSubmitFormLine;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { createProcurement, getAgencies, getProcurements, getProcurementsByUser, batchGetAllRecords } from "../../util/api";
import { loadUserRole, roles } from "../../util/userHelper";
import CreateProcurementsForm from "./CreateProcurementsForm";
import ProcurementsList from "./ProcurementsList";

const AgencyProcurements = (props) => {
  const { planId } = useParams();
  const [updateCount, setUpdateCount] = useState(0);
  const [agencies, setAgencies] = useState([]);
  const [procurements, setProcurements] = useState([]);
  const [showProcurementsForm, setShowProcurementsForm] = useState(false);
  const [isLoadingProcurements, setIsLoadingProcurements] = useState(true);
  // const agencyId = agencies.length ? agencies[0]?.id : null;

  useEffect(() => {
    async function fetchData() {
      const agenciesRes = await getAgencies();
      setAgencies(agenciesRes.agencies);
    }
    fetchData();
  }, []);

  useEffect(() => {

    async function fetchData() {
      setIsLoadingProcurements(true);
      const procurementsRes = loadUserRole() === roles.AgencyUser ? await batchLoadProcurements(getProcurementsByUser) : await batchLoadProcurements(getProcurements);
     // setProcurements(procurementsRes.data.procurements);
      setIsLoadingProcurements(false);
    }
    if (agencies && agencies?.length) {
      fetchData();
    }
  }, [agencies, updateCount]);

  const batchLoadProcurements = async (procurementFn)=>{

     await batchGetAllRecords(20,
        async (page, pageSize) => {
          return procurementFn(null,page,pageSize)
        },
        (results) => {
          return results.data.procurements;
        },
        (response) => {
          setProcurements(response);
          setIsLoadingProcurements(false);
        });
  }
  const onSubmit = async (values, { resetForm }) => {
    const { agencyId, goal, solicitationNumber, description, startDate, endDate, contractNumber, contractTerm, contractCategory, contractServiceType, exclusionType } = values;

    let submitObj = {
      // "agencyId": agencyId,
      solicitationNumber: solicitationNumber,
      goal: goal,
      description: description,
      startDate: startDate,
      endDate: endDate,
      contractNumber: contractNumber,
      contractTerm: contractTerm !== "" ? contractTerm : null,
      contractCategory: contractCategory !== "" ? contractCategory : null,
      contractServiceType: contractServiceType !== "" ? contractServiceType : null,
      exclusionType: exclusionType !== "" ? exclusionType : null,
    };

    const resObj = await createProcurement(submitObj);
    setUpdateCount(updateCount + 1);
    resetForm();
    setShowProcurementsForm(false);

    // console.log(resObj);
    if (resObj?.status === 201) {
      // console.log("got it");
    }
  };

  return (
    <section id="agency-procurements">
      {showProcurementsForm ? (
        <CreateProcurementsForm onSubmit={onSubmit} validateOnChange={true} agencies={agencies} routeInitialValues={{ goal: 6, contractTerm: "Standard" }} />
      ) : (
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => {
            setShowProcurementsForm(true);
          }}
        >
          <FontAwesomeIcon icon={["fa", "plus"]} className="mr-3" size="md" />
          Create New Procurement
        </button>
      )}
      <ProcurementsList isLoadingProcurements={isLoadingProcurements} procurements={procurements} />
    </section>
  );
};
export default AgencyProcurements;

import ApplicantInformationForm from "../components/BusinessApplication/tabs/ApplicantInformationForm"
import BusinessInformationForm from "../components/BusinessApplication/tabs/BusinessInformationForm";
import FinancialInformationForm from "../components/BusinessApplication/tabs/FinancialInformationForm";
import ReviewSubmitForm from "../components/BusinessApplication/tabs/ReviewSubmitForm";

export const FormTabs_InitialValues = {
  tabs: {
    "businessInformation": {
      activeSlide: 0,
      isTouched: true,
      isValid: true,
    },
    "applicantInformation": {
      activeSlide: 0,
      isTouched: false,
      isValid: true,
    },
    "financialInformation": {
      activeSlide: 0,
      isTouched: false,
      isValid: true,
    },
    "reviewSubmit": {
      activeSlide: 0,
      isTouched: false,
      isValid: true,
    },
    // "certificates-signatures": {
    //   activeSlide: 0,
    //   isTouched: false,
    //   isValid: true,
    // },
  },
  tabKeyActive: "businessInformation",
};

export const TabComponents = {
  "businessInformation": {
    SectionComponent: BusinessInformationForm,
    title: "Business",
    titleExtra: "Information",
    tabKey: 1
  },
  "applicantInformation": {
    SectionComponent: ApplicantInformationForm,
    title: "Applicant",
    titleExtra: "Information",
    tabKey: 2
  },
  "financialInformation": {
    SectionComponent: FinancialInformationForm,
    title: "Financial",
    titleExtra: "Information",
    tabKey: 3
  },
  "reviewSubmit": {
    SectionComponent: ReviewSubmitForm,
    title: "Review",
    titleExtra: "& Submit",
    tabKey: 4
  },
  // "certificates-signatures": {
  //   SectionComponent: CertificatesSignaturesForm,
  //   title: "Certificates & Signatures"
  // },
}

export const getNextTabKey = (tabs, tabKey) => {
  const keys = Object.keys(tabs);
  const current = keys.indexOf(tabKey);
  const nextIndex = current + 1;
  return keys[nextIndex];
}

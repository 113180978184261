import React from "react";
import { Formik, validateYupSchema, yupToFormErrors } from "formik";
import _ from "lodash";
import { isErrorsInTab } from "../../util/errorHelper";
// import { addSavedCheckForm } from "../../util/helpers";

export const TabStatus = {
  UNTOUCHED: 0,
  INCOMPLETE: 1,
  COMPLETE: 2,
};

const isTouched = (touched) => {
  return touched && Object.keys(touched).length > 0;
};

// const buildTabStatus = (tabKey, isValid, tabTouched) => {
//   const tabStatus = {};
//   if (tabTouched) {
//     tabStatus[tabKey] = isValid ? TabStatus.COMPLETE : TabStatus.INCOMPLETE;
//   }
//   return tabStatus;
// };

const useTabValidStatus = (activeTabKey, validateForm) => {
  React.useEffect(() => {
    validateForm();
  }, [activeTabKey]);
};

/** This validation is very critical as we are re-initializing values
 * when the tab changes and propagating those changes to all tabs.
 * This will ensure that all tabs are revalidated in every such change
 */
const useValidateOnTab = (initialValues, validateForm) => {
  // React.useEffect(() => {
  //   validateForm();
  // }, [initialValues]);
};

const withFormikFormComponent = (Component, schema) => (props) => {
  const { routeInitialValues, initialErrors, initialTouched, enablereinitialize, onSubmit, appBag, validateOnMount, validateOnChange } = props;
  const initialValues = routeInitialValues ? routeInitialValues : {};

  return (
    <div>
      <Formik
        initialValues={initialValues}
        initialErrors={{}}
        initialTouched={{}}
        onSubmit={onSubmit}
        validate={(values) => {
          // https://github.com/formium/formik/issues/506
          if (schema) {
            try {
              validateYupSchema(values, schema, true, values);
            } catch (err) {
              const errors = yupToFormErrors(err); //for rendering validation errors
              if (Object.keys(errors).length === 0) {
                console.error(err);
                return { application: "Yup is unable to parse the error object", err: err.message };
              } else {
                // console.log(errors)
                return errors;
              }
            }
          }
          return {};
        }}
        // validationSchema={schema}
        validateOnChange={validateOnChange ? validateOnChange : true}
        validateOnMount={validateOnMount ? validateOnMount : true}
        enableReinitialize
        children={(formikBag) => {
          return (
            <>
              <Component formikBag={formikBag} appBag={appBag} {...props} />
            </>
          );
        }}
      />
    </div>
  );
};

export { withFormikFormComponent, useTabValidStatus, isTouched, useValidateOnTab };

import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { differenceInDays, outputMoney, outputPercentage } from "../../../util/helpers";
import ProgressBar from "../../Charting/ProgressBar";
import "./sections.scss";

const FinancialProgress = (props) => {
  const { procurementId, year, month } = useParams();
  const { contract, awardedPlan, procurement, complianceReport, values, totalPayments, disbursementsToPrimeToUse, disbursementsToSDVOBsToUse, disbursementsDesc } = props;
  const { isOGSCentralized } = contract;
  const { utilizationPercentage, adjustedValue, value, currentValue, startDate, expectedDate, disbursementsToSDVOBs, disbursementsToPrime } = contract;
  const { assessedValueOfPlanToSdvobs } = awardedPlan || 0;
  const goal = awardedPlan ? awardedPlan.goal / 100 : null;
  const currentUtilizationPercentage = utilizationPercentage ? utilizationPercentage / 100 : null;
  const daysPassed = differenceInDays(startDate);
  let totalDays = differenceInDays(startDate, expectedDate);
  totalDays = totalDays < 0 ? 0 : totalDays;


  return (
    <div>
      {contract?.currentValue !== awardedPlan?.value && procurement?.contractTerm === "Standard" && (
        <div className="alert alert-danger">
          The contract value for SDVOB participation or the utilization plan have been updated and are no longer the same. Please{" "}
          <Link to={`/procurements/${procurementId}/plans-waivers`}>submit a revised plan</Link> to record any changes to the plan SDVOBs and associated SDVOB spending.
          {/* The value on the awarded plan ({outputMoney(awardedPlan?.value)}) does not match the contract value ({outputMoney(contract?.currentValue)}). The{" "}
          <Link to={`/procurements/${procurementId}/plans-waivers`}> Utilization Plan</Link> should be revised to account for this. */}
        </div>
      )}
      <div className="row text-center mb-4 justify-content-center">
        {procurement?.contractTerm === "Standard" && (
          <div className="col-3">
            <p className="h3 text-success">{outputMoney(currentValue)}</p>
            <p className="small">CONTRACT VALUE FOR SDVOB PARTICIPATION</p>
          </div>
        )}
        <div className="col-3">
          <p className="h3 text-success">
            {outputPercentage(currentUtilizationPercentage)} of {outputPercentage(goal)}
          </p>
          <p className="small">SDVOB GOAL PERCENTAGE</p>
        </div>
        <div className="col-3">
          <p className="h3 text-success">{outputMoney(assessedValueOfPlanToSdvobs)}</p>
          <p className="small">TOTAL ASSESSED VALUE TO SDVOBS</p>
        </div>
        <div className="col-3">
          <p className="h3 text-success">{outputMoney(disbursementsToSDVOBsToUse)}</p>
          <p className="small text-uppercase">{disbursementsDesc}</p>
        </div>
      </div>

      <ProgressBar
        current={disbursementsToPrimeToUse}
        currentDesc={isOGSCentralized ? `Total Reported Sales` : `Payments to Prime Contractor`}
        total={procurement?.contractTerm === "Term" ? disbursementsToPrimeToUse : adjustedValue}
        totalDesc="Total Expected"
        type="dollars"
        title={isOGSCentralized ? `Reported Contract Disbursements` : `Contract Disbursements`}
      />
      <ProgressBar
        current={disbursementsToSDVOBsToUse}
        currentDesc={disbursementsDesc}
        total={assessedValueOfPlanToSdvobs}
        totalDesc="Total To be Disbursed"
        type="dollars"
        title="Progress towards goal"
      />

      {complianceReport?.status === "Created" ? <p className="text-primary">The payments identified below will not appear in this progress bar until the compliance report is submitted.</p> : null}
      {/* <ProgressBar current={daysPassed} currentDesc="Time passed" total={totalDays} basedOnRemaining={true} totalDesc="Time Left" type="time" title="Time elapsed" /> */}
    </div>
  );
};

export default FinancialProgress;

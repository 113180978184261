import React from 'react'
import { Button, Modal } from 'react-bootstrap';

function BusinessPrompt(props) {
  const { businessPromptShown, makeBusinessPromptToShownLocal, gotoBusinessInfo } = props;

  const handleClose = () => {
    makeBusinessPromptToShownLocal()
  }

  return (
    <>
      <Modal name="business-info-update-modal" show={!businessPromptShown} size={"lg"} onHide={handleClose} backdrop="static">
        <Modal.Header>
          <Modal.Title><h3>Business User and SDVOB Information Status</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2>Does your business information need to be updated?</h2>
          <span>Have you changed information about your business since the last time you've logged in?</span>
        </Modal.Body>
        <Modal.Footer className="d-block">
          <div className="row">
            <div className="col-md-6">
              <Button variant="primary small btn-block" onClick={gotoBusinessInfo}>
                YES, MY INFORMATION
                REQUIRES UPDATES
              </Button>
            </div>
            <div className="col-md-6">
              <Button variant="secondary small btn-block" onClick={makeBusinessPromptToShownLocal}>
                NO, MY INFORMATION
                Is UP TO DATE
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default BusinessPrompt

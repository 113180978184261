// src/Login.jsx

import React from "react";
import { Redirect } from "react-router-dom";

const Login = () => {
  // const { oktaAuth, authState } = useOktaAuth();
  // const history = useHistory();
  // useEffect( () => {
  //   if (authState) {
  //     if (authState.isAuthenticated) {
  //       history.push('/');
  //     } else if (oktaAuth) {
  //       oktaAuth.signInWithRedirect();
  //     }
  //   }
  // }, [authState, oktaAuth])
  return (
    <>
      <Redirect to={{ pathname: "/" }} />
    </>
  );
};
export default Login;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { withFormikFormComponent } from "../Forms/FormikForm";
import ProcurementNav from "./ProcurementNav";
import BusinessUtilizationPlans from "./UtilizationPlans/BusinessUtilizationPlans";
import CorrespondenceWrapper from "./Correspondence/CorrespondenceWrapper";
import AnnotationsLog from "./Annotations/AnnotationsLogList";
import ComplianceReport from "./ComplianceReports/ComplianceReport";
import ChangeLog from "./ChangeLogs/ChangeLogList";
import Payments from "./Payments/Payments";
import { loadUserRole, roles } from "../../util/userHelper";
import { isPrime, componentsFromRoutes } from "../../util/helpers";
import SubPayments from "./Payments/SubPayments";
import ContractDetailsViewAgency from "./Views/ContractDetailsViewAgency";
import ContractDetailsViewBusiness from "./Views/ContractDetailsViewBusiness";
import PlansAndWaiversAgency from "./Views/PlansAndWaiversAgency";
import PlansAndWaiversBusiness from "./Views/PlansAndWaiversBusiness";
import { getProcurement } from "../../util/api";
import LogsNav from "./LogsNav";

const ProcurementOverview = (props) => {
  let { procurementId } = useParams();
  const { userInfo, activeBusinessId } = props;
  const [procurement, setProcurement] = useState({});
  const [updateCount, setUpdateCount] = useState(0);
  const { contract, awardedPlan } = procurement;

  useEffect(() => {
    async function fetchData() {
      const procurementRes = await getProcurement(procurementId);
      setProcurement(procurementRes?.data);
      // console.log(mockProcurement);
      // setProcurement(mockProcurement);
    }

    fetchData();
  }, [procurementId, updateCount]);

  let userRoutes = [];

  if ([roles.DivisionAdmin, roles.DivisionUser].includes(loadUserRole())) {
    userRoutes = [
      {
        path: "/procurements/:procurementId/plans-waivers",
        title: "Plans & Waivers",
        exact: true,
        components: [
          [roles.DivisionAdmin, PlansAndWaiversAgency],
          [roles.DivisionUser, PlansAndWaiversAgency],
        ],
        props: {
          version: awardedPlan?.history?.length,
          planVersion: awardedPlan,
        },
      },
    ];
  } else {
    userRoutes =
      isPrime(awardedPlan) || [roles.AgencyUser, roles.AgencyAdmin].includes(loadUserRole())
        ? [
            {
              path: "/procurements/:procurementId",
              title: "Contract Details",
              exact: true,
              components: [
                [roles.AgencyAdmin, ContractDetailsViewAgency],
                [roles.AgencyUser, ContractDetailsViewAgency],
                [roles.Business, ContractDetailsViewBusiness],
              ],
            },
            {
              path: "/procurements/:procurementId/plans-waivers/submit",
              title: "Utilization Plans",
              exact: true,
              component: BusinessUtilizationPlans,
              routeInitialValues: {
                agencyId: contract?.agency?.id,
                solicitationNumber: contract?.solicitationNumber,
                contractId: contract?.id,
                goal: 7.5,
                status: "Received",
                value: contract?.currentValue,
                totalSdvob: 0,
              },
            },
            {
              path: "/procurements/:procurementId/plans-waivers/:planId",
              title: "Plans & Waivers",
              exact: true,
              components: [
                [roles.AgencyAdmin, PlansAndWaiversAgency],
                [roles.AgencyUser, PlansAndWaiversAgency],
                [roles.Business, PlansAndWaiversBusiness],
              ],
            },
            {
              path: "/procurements/:procurementId/plans-waivers",
              title: "Plans & Waivers",
              exact: true,
              components: [
                [roles.AgencyAdmin, PlansAndWaiversAgency],
                [roles.AgencyUser, PlansAndWaiversAgency],
                [roles.Business, PlansAndWaiversBusiness],
              ],
              props: {
                version: awardedPlan?.history?.length,
                planVersion: awardedPlan,
              },
            },
            {
              path: "/procurements/:procurementId/logs",
              title: "Annotations Log",
              exact: false,
              component: LogsNav,
            },
            // {
            //   path: "/procurements/:procurementId/logs",
            //   title: "Annotations Log",
            //   exact: true,
            //   component: AnnotationsLog,
            // },
            {
              path: "/procurements/:procurementId/logs/annotations-logs",
              title: "Annotations Log",
              exact: false,
              component: AnnotationsLog,
            },
            {
              path: "/procurements/:procurementId/logs/change-logs",
              title: "Change Log",
              exact: false,
              component: ChangeLog,
            },
            {
              path: "/procurements/:procurementId/correspondence",
              title: "Correspondence",
              exact: true,
              component: CorrespondenceWrapper,
            },
            {
              path: "/procurements/:procurementId/correspondence/:correspondenceId",
              title: "Correspondence",
              exact: true,
              component: CorrespondenceWrapper,
            },
            // {
            //   path: "/procurements/:procurementId/correspondence/:correspondenceId",
            //   title: "Correspondence",
            //   exact: true,
            //   component: CorrespondenceList,
            // },
            {
              path: "/procurements/:procurementId/payments/:paymentId",
              title: "Payments",
              exact: true,
              component: Payments,
            },
            {
              path: "/procurements/:procurementId/payments",
              title: "Payments",
              exact: true,
              component: Payments,
            },
            // {
            //   path: "/procurements/:procurementId/compliance-reports/",
            //   title: "ComplianceReportList",
            //   exact: true,
            //   component: ComplianceReportList,
            // },
            {
              path: "/procurements/:procurementId/compliance-reports",
              title: "Compliance Reports",
              exact: true,
              component: Payments,
            },
            {
              path: "/procurements/:procurementId/compliance-reports/:year/:month",
              title: "ComplianceReport",
              exact: true,
              component: ComplianceReport,
              // components: [
              //   [roles.AgencyAdmin, ComplianceReportsAgency],
              //   [roles.AgencyUser, ComplianceReportsAgency],
              //   [roles.Business, ComplianceReport],
              // ],
            },
          ]
        : [
            {
              path: "/procurements/:procurementId/payments",
              title: "Payments",
              exact: true,
              component: SubPayments,
            },
          ];
  }

  const procurementRoutes = {
    routes: userRoutes,
    globalProps: {
      contract: contract,
      procurement: procurement,
      procurementId: procurementId,
      activeBusinessId: activeBusinessId,
      awardedPlan: awardedPlan,
      updateCount: updateCount,
      setUpdateCount: setUpdateCount,
    },
  };

  return (
    <section id="contract-details-wrapper">
      <div id="contract-details">
        <h2 className="mb-4">
          Procurement: <span className="font-weight-normal">{procurement?.description}</span>
          <br />
          Solicitation Number: <span className="font-weight-normal">{procurement?.solicitationNumber}</span>
          {loadUserRole() === "Business" && (
            <>
              <br />
              Agency: <span className="font-weight-normal">{procurement?.agency?.name}</span>
            </>
          )}
        </h2>
        {[roles.DivisionUser, roles.DivisionAdmin].includes(loadUserRole()) ? null : (
          <ProcurementNav procurement={procurement} contract={contract} activeBusinessId={activeBusinessId} userInfo={userInfo} />
        )}

        {procurement?.id ? componentsFromRoutes(procurementRoutes) : <h2 className="loader loader-pulse">Loading Procurement...</h2>}
      </div>
    </section>
  );
};

export default withFormikFormComponent(ProcurementOverview);

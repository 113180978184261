import React from "react";
import _ from "lodash";
import BasicInfo from "./BasicInfo";

const ReportDetails = (props) => {
  const { report, reportType, isVersion, reportTypeData } = props;
  const Section = reportTypeData.displayComponent;

  return (
    <>
      <div key={`${reportType}-section`} className="report-section mb-3">
        <h3>Report Details</h3>
        <BasicInfo report={report} isVersion={isVersion} />
        <hr />
        <Section report={report} isVersion={isVersion} />
        <hr className="my-4" />
      </div>
    </>
  );
};

export default ReportDetails;

import { FastField } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { createActivityReport, getAgencies, saveActivityReport, submitActivityReport, resetActivityReport } from "../../../util/api";
import { schemas } from "../../../util/errorHelper";
import { outputPercentage, outputMoney } from "../../../util/helpers";
import DebugModal from "../../Debug/DebugModal";
import { withFormikFormComponent } from "../../Forms/FormikForm";
import InputComponent from "../../Forms/Input/InputComponent";
import ActivityReportDetails from "./ActivityReportDetail";

const ActivityReportSubmitFormInternal = (props) => {
  const { formikBag, reportId, hasSaved, setHasSaved, report, updateCount, setUpdateCount } = props;
  const { values, resetForm } = formikBag;
  const [showModal, setShowModal] = useState(false);
  const [canForce, setCanForce] = useState(false);
  const [utilizationPercentage, setUtilizationPercentage] = useState(values?.utilizationPercentage);
  const history = useHistory();
  const location = useLocation();

  const handleSubmit = async (data, action, force) => {
    const {
      fiscalQuarter,
      fiscalYear,
      totalNumberOfAwards,
      totalValueOfAwards,
      totalDisbursementsToSdvobs,
      nonExemptNonExcludedPaymentTotal,
      utilizationPercentage,
      totalNumberOfWaiversGranted,
      agencyId,
      activityReportId,
      totalAdditionalSpendOnSdvobs,
    } = values;

    let submitObj = {
      id: activityReportId,
      totalNumberOfAwards,
      totalValueOfAwards,
      totalDisbursementsToSdvobs,
      nonExemptNonExcludedPaymentTotal,
      // utilizationPercentage,
      totalNumberOfWaiversGranted,
      totalAdditionalSpendOnSdvobs,
    };

    if (action === "create") {
      submitObj = { agencyId, fiscalQuarter, fiscalYear };
      try {
        const resObj = await createActivityReport({ ...submitObj, force: canForce });
        history.push(`${location.pathname}/${resObj.data.id}`);
      } catch (e) {
        console.log(e.response);
        if (e?.response?.data?.data?.allowForce) {
          setCanForce(true);
        }
      }
    } else if (action === "save") {
      await saveActivityReport(submitObj);
      setHasSaved(`Report saved ${new Date().toLocaleString()}`);
    } else if (action === "submit") {
      await submitActivityReport(submitObj);
      history.push(`/agency-reporting/activity-report`);
    }
  };

  useEffect(() => {
    const { totalDisbursementsToSdvobs = 0, totalAdditionalSpendOnSdvobs = 0, nonExemptNonExcludedPaymentTotal = 0 } = values;
    const numerator = totalDisbursementsToSdvobs + totalAdditionalSpendOnSdvobs;
    const denominator = nonExemptNonExcludedPaymentTotal + totalAdditionalSpendOnSdvobs;
    const percentageToSet = denominator > 0 ? parseInt(numerator) / parseInt(denominator) : 0;
    setUtilizationPercentage(percentageToSet);
    setCanForce(false);
  }, [values]);

  const resetReport = async () => {
    try {
      const resObj = await resetActivityReport(values.activityReportId, canForce);
      resetForm({ values: { activityReportId: resObj?.data.id, ...resObj?.data } });
      setCanForce(false);
      setUpdateCount(updateCount + 1);
    } catch (e) {
      if (e?.response?.data?.data?.allowForce) {
        setCanForce(true);
      }
    }
    setShowModal(false);
  };

  return (
    <section id="activity-report-submit-form">
      {hasSaved ? (
        <div className="alert alert-secondary">
          <p>{hasSaved}</p>
        </div>
      ) : null}
      <DebugModal formikBag={formikBag} name="Formik Bag" />

      <div id="activity-report-submit-form-wrapper" className="bg-light p-4 mb-4">
        <div className="d-flex flex-row justify-content-between">
          <h3>Activity Report Submit Form</h3>
          {reportId && (
            <button
              className={`faux-link text-${canForce ? "primary" : "secondary"}`}
              onClick={() => {
                setShowModal(true);
              }}
            >
              <FontAwesomeIcon icon={["fa", "sync"]} className="mr-2" />
              {canForce ? "Force" : null} Reset
            </button>
          )}
        </div>
        <hr />
        <form>
          <div className="field-group row mb-2">
            <FastField wrapperClass="col-md-6" label="Fiscal Year" type="number" name={"fiscalYear"} component={InputComponent} placeholder="" isdisabled={reportId ? true : false} />
            <FastField
              wrapperClass="col-md-6"
              label="Fiscal Quarter"
              type="select"
              name={"fiscalQuarter"}
              component={InputComponent}
              options={[
                [1, 1],
                [2, 2],
                [3, 3],
                [4, 4],
              ]}
              isdisabled={reportId ? true : false}
            />
            {values?.activityReportId ? (
              <>
                <FastField wrapperClass="col-md-4" label="Total Number of Awards to SDVOBs" type="number" name={"totalNumberOfAwards"} component={InputComponent} placeholder="" />
                <FastField wrapperClass="col-md-4" label="Total Value of Awards to SDVOBs" type="number" name={"totalValueOfAwards"} component={InputComponent} placeholder="" />
                <FastField wrapperClass="col-md-4" label="Total Disbursements to SDVOB Vendors" type="number" name={"totalDisbursementsToSdvobs"} component={InputComponent} placeholder="" />
                <FastField wrapperClass="col-md-4" label="Non-exempt Non-excluded Payment Total" type="number" name={"nonExemptNonExcludedPaymentTotal"} component={InputComponent} placeholder="" />
                <FastField wrapperClass="col-md-4" label="Total Number of Waivers Granted" type="number" name={"totalNumberOfWaiversGranted"} component={InputComponent} placeholder="" />
                <FastField wrapperClass="col-md-4" label="Total Additional Spend on SDVOBs" type="number" name={"totalAdditionalSpendOnSdvobs"} component={InputComponent} placeholder="" />
                {/* <FastField wrapperClass="col-md-4" label="Utilization Percentage" type="number" name={"utilizationPercentage"} component={InputComponent} placeholder="" /> */}
                <div className="col">
                  <hr />
                  <p className="">
                    <strong>Utilization Percentage:</strong> {outputPercentage(utilizationPercentage)}
                    <br />
                    <strong>Total Agency Spending:</strong> {outputMoney(parseInt(values?.nonExemptNonExcludedPaymentTotal + values?.totalAdditionalSpendOnSdvobs))}
                    <br />
                    <strong>Set-Asides:</strong> {(report?.totalSetAsides)}
                    <br />
                    <strong>Discretionaries:</strong> {(report?.totalDiscretionaries)}
                    <br />
                    <strong>Total Value of Contracts Awarded in Period:</strong> {outputMoney(report?.totalContractsValue)}

                  </p>
                </div>
              </>
            ) : null}
          </div>

          <div className="row">
            {values?.activityReportId ? (
              <>
                <div className="col-md-6">
                  <Button
                    onClick={async (submitObj) => {
                      await handleSubmit(submitObj, "save");
                    }}
                    type="button"
                    variant="secondary"
                    className="btn-md btn-submit btn-block"
                    disabled={!formikBag.isValid}
                  >
                    Save
                  </Button>
                </div>
                <div className="col-md-6">
                  <Button
                    onClick={async (submitObj) => {
                      await handleSubmit(submitObj, "submit");
                    }}
                    type="button"
                    variant="secondary"
                    className="btn-md btn-submit btn-block"
                    disabled={!formikBag.isValid}
                  >
                    Submit
                  </Button>
                </div>
              </>
            ) : (
              <div className="col-md-6">
                <Button
                  onClick={async (submitObj) => {
                    await handleSubmit(submitObj, "create", canForce);
                  }}
                  type="button"
                  variant={canForce ? "primary" : "secondary"}
                  className="btn-md btn-submit btn-block"
                  disabled={!formikBag.isValid}
                >
                  {canForce ? "Force" : null} Create
                </Button>
              </div>
            )}
          </div>
        </form>
      </div>

      <ActivityReportDetails reportId={reportId} updateCount={updateCount} />

      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Refresh Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you would like to reset the activity report to the system-generated version? This cannot be undone.</Modal.Body>
        <Modal.Footer className="d-block">
          <div className="row">
            <div className="col-md-6">
              <Button
                className="btn-block btn-sm"
                variant="secondary"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Cancel
              </Button>
            </div>
            <div className="col-md-6">
              <Button className="btn-block btn-sm" variant="primary" onClick={resetReport}>
                Confirm
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

const ActivityReportSubmitForm = withFormikFormComponent(ActivityReportSubmitFormInternal, schemas.editActivityReportSchema);
export default ActivityReportSubmitForm;

import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import FilterTableForm from "../../../Forms/Input/FilterTableForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDivisionUsers, assignGoalPlanUser, assignNarrativeStatementUser, assignActivityReportUser } from "../../../../util/api";
import { loadUserRole, roles, getUserFullName } from "../../../../util/userHelper";
import AssignDropdown from "../../../DivisionPortal/shared/AssignDropdown";
import { getLocalDate } from "../../../../util/helpers";

const FilterListConfig = {
  fiscalYear: {
    isJoin: false,
  },
};

const ReportSearchList = (props) => {
  const { reports, reportType, refreshListCount, setRefreshListCount, divisionUsers, divisionUser, setDivisionUser } = props;
  const location = useLocation();

  const Columns = [
    {
      Header: "View Report",
      accessor: "viewReport", // accessor is the "key" in the data
      disableSortBy: true,
      filterCode: "viewReport",
    },
    {
      Header: "Agency",
      accessor: "agencyName", // accessor is the "key" in the data
      disableSortBy: true,
      filterCode: "agencyName",
    },
    {
      Header: "Fiscal Year",
      accessor: "fiscalYear", // accessor is the "key" in the data
      disableSortBy: true,
      filterCode: "fiscalYear",
      isVisible: true,
    },
    {
      Header: "Assigned",
      accessor: "assigned",
      defaultCanSort: true,
      sortType: "basic",
      filterCode: "assignedUser",
    },
    {
      Header: "Status",
      accessor: "status", // accessor is the "key" in the data
      disableSortBy: true,
      filterCode: "status",
    },
    {
      Header: "Submitted Date",
      accessor: "submittedDate",
      disableSortBy: false,
      filterCode: "submittedDate",
    },
    {
      Header: "Extension Request",
      accessor: "extensionRequested",
      disableSortBy: false,
      filterCode: "extensionRequested",
    },
  ];

  if (reportType === "activity-report") {
    Columns.splice(3, 0, {
      Header: "Fiscal Quarter",
      accessor: "fiscalQuarter",
      disableSortBy: true,
      filterCode: "fiscalQuarter",
    });
    Columns.splice(-1, 0, {
      Header: "Manually Updated",
      accessor: "manuallyUpdated", // accessor is the "key" in the data
      disableSortBy: true,
      filterCode: "manuallyUpdated",
    });
  }

  // if (loadUserRole() === roles.DivisionAdmin) {
  //   const spliceIndex = reportType === "activity-report" ? 4 : 3;
  //   Columns.splice(spliceIndex, 0, {
  //     Header: "Assigned",
  //     accessor: "assigned",
  //     defaultCanSort: true,
  //     sortType: "basic",
  //     filterCode: "assignedUser",
  //   });
  // }

  const assignDropdownOnChange = async (e, reportId) => {
    const divisionUserId = e.target.value;
    console.log(divisionUserId);
    setDivisionUser({ reportId, divisionUserId });

    switch (reportType) {
      case "activity-report":
        await assignActivityReportUser(reportId, divisionUserId);
        setRefreshListCount(refreshListCount + 1);
        break;
      case "narrative-statement":
        await assignNarrativeStatementUser(reportId, divisionUserId);
        setRefreshListCount(refreshListCount + 1);
        break;
      case "goal-plan":
        await assignGoalPlanUser(reportId, divisionUserId);
        setRefreshListCount(refreshListCount + 1);
        break;
      default:
        console.log("report type error");
        break;
    }

    setDivisionUser({});
  };

  const initialItems =
    divisionUsers.length && reports?.length
      ? reports.map((report) => {
          const { reviewer } = report;
          console.log(reviewer);

          return {
            ...report,
            viewReport: <Link role="link" to={`${location.pathname}/${report.id}`}>View Report</Link>,
            fiscalYear: report.fiscalYear.toString(),
            agencyName: report.agency.name,
            assignedUser: getUserFullName(reviewer),
            assigned:
              divisionUser.reportId === report.id ? (
                <FontAwesomeIcon icon={"spinner"} className="fa-spin" />
              ) : (
                <AssignDropdown reportId={report.id} reviewerId={reviewer?.id} divisionUsers={divisionUsers} onChange={assignDropdownOnChange} />
              ),
            processingAssigned: `${divisionUser.reportId} - ${divisionUser.reportId === report.id}`,
            submittedDate: report?.lastSubmittedDate && report?.status !== "Created" ? getLocalDate(report.lastSubmittedDate) : "N/A",
            manuallyUpdated: report.manuallyUpdated ? "Yes" : "No",
            extensionRequested: report.extensionRequested ? "Yes" : "No",
          };
        })
      : [];

  return (
    <>
      {initialItems ? (
        <section id={`${reportType}-list`}>
          <div>{initialItems.length > 0 && <FilterTableForm tableName="report-search-list" Columns={Columns} FilterListConfig={FilterListConfig} tableItems={initialItems}></FilterTableForm>}</div>
        </section>
      ) : (
        <span className="loader loader-pulse">Loading {reportType.replace("-", " ")}s...</span>
      )}
    </>
  );
};

export default ReportSearchList;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import orderBy from "lodash/orderBy";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import CorrespondenceForm from "./CorrespondenceForm";
import CorrespondenceReply from "./CorrespondenceReply";
import MainCorrespondence from "./MainCorrespondence";

const CorrespondenceDetail = ({ correspondence, correspondenceId, handleClose, handleReopen, handleReply, handleReplyToReply, updateCount, setUpdateCount }) => {
  const history = useHistory();
  const location = useLocation();
  const { isClosed } = correspondence;
  const [showForm, setShowForm] = useState(!isClosed);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (data, { resetForm }) => {
    const replyRes = await handleReply(data);
    setUpdateCount(updateCount + 1);
    setShowForm(false);
    resetForm();
  };

  return (
    <>
      <MainCorrespondence correspondenceId={correspondenceId} correspondence={correspondence} />

      {correspondence?.correspondenceReplies?.length ? (
        <>
          <hr />
          <p className="h2 mb-4">
            {correspondence?.correspondenceReplies.length} {correspondence?.correspondenceReplies.length === 1 ? "Reply" : "Replies"}
          </p>

          {orderBy(correspondence?.correspondenceReplies, "created", "desc").map((reply, index) => {
            return (
              <div key={index}>
                <>
                  <CorrespondenceReply
                    correspondenceId={correspondenceId}
                    correspondence={correspondence}
                    reply={reply}
                    updateCount={updateCount}
                    setUpdateCount={setUpdateCount}
                    handleReplyToReply={handleReplyToReply}
                    // onCorrespondenceUpdate={setCorrespondence}
                  />
                </>
              </div>
            );
          })}
        </>
      ) : null}

      <CorrespondenceForm
        correspondenceId={correspondenceId}
        routeInitialValues={{ correspondenceId: correspondenceId }}
        onSubmit={handleSubmit}
        level={1}
        creatorName={correspondence?.creatorName}
        showForm={!isClosed}
      />

      {correspondenceId && !correspondence?.isClosed ? (
        <>
          <h2 className="my-5">Were you able to get the information you need to close out this correspondence thread?</h2>

          <div className="d-flex flex-row justify-content-center">
            <button
              onClick={async () => {
                setIsLoading(true);
                await handleClose(correspondenceId);
                history.push(location.pathname.substring(0, location.pathname.lastIndexOf("/")));
                setIsLoading(false);
              }}
              className="btn btn-secondary"
            >
              Yes, close this thread &amp; resolve
            </button>
          </div>

          <hr className="mb-4" />

          <Link role="link" to={location.pathname.substring(0, location.pathname.lastIndexOf("/"))}>
            <FontAwesomeIcon icon={["fa", "angle-left"]} className="mr-2" />
            Back to Correspondence List
          </Link>
        </>
      ) : (
        <div className="">
          <h2 className="mb-3">This correspondence thread has been marked resolved.</h2>
          <div className="d-flex flex-row justify-content-center">
            <button
              onClick={async () => {
                setIsLoading(true);
                await handleReopen(correspondenceId);
                setUpdateCount(updateCount + 1);
                setIsLoading(false);
              }}
              className="btn btn-secondary"
            >
              {!isLoading ? "Reopen correspondence thread" : <span className="loader loader-pulse mb-4">Loading...</span>}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CorrespondenceDetail;

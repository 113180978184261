import React, { useState } from "react";
import { formatPhoneNumber, hasDocumentType, outputMoney } from "../../../../util/helpers";
import { businessTypes, EMPTY_GUID, ownershipTypes, VetsFirstCertification } from "../../../../util/typelistHelper";
import { roles, loadUserRole } from "../../../../util/userHelper";
import ReviewSubmitFormAddressLines from "./ReviewSubmitFormAddressLines";
import ReviewSubmitFormDocumentLines from "./ReviewSubmitFormDocumentLines";
import ReviewSubmitFormLine from "./ReviewSubmitFormLine";
import { resendApplicationOwnerInvitation } from "../../../../util/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const resentApplicationOwnerStatuses = Object.freeze({
  none: "none",
  sending: "sending",
  sent: "sent",
  error: "error",
  alreadyAssociated: "alreadyAssociated",
});

function ApplicationReviewComponent(values) {
  const [resentApplicationOwnerIds, setResentApplicationOwnerIds] = useState({});

  async function resendApplicationOwnerInvitationEmail(applicationId, applicationOwnerId) {
    let updatedResentApplicationOwnerIds = { ...resentApplicationOwnerIds };

    updatedResentApplicationOwnerIds[applicationOwnerId] = resentApplicationOwnerStatuses.sending;

    setResentApplicationOwnerIds(updatedResentApplicationOwnerIds);

    try {
      updatedResentApplicationOwnerIds = { ...updatedResentApplicationOwnerIds };
      const wasSent = await resendApplicationOwnerInvitation(applicationId, applicationOwnerId);

      updatedResentApplicationOwnerIds[applicationOwnerId] = wasSent ? resentApplicationOwnerStatuses.sent : resentApplicationOwnerStatuses.alreadyAssociated;
    } catch (e) {
      updatedResentApplicationOwnerIds[applicationOwnerId] = resentApplicationOwnerStatuses.error;
    } finally {
      setResentApplicationOwnerIds(updatedResentApplicationOwnerIds);
    }
  }

  let selectedDocumentTypes = ["FederalIdOrSocialSecurityNumberVerification", "BusinessLicense"];

  if (ownershipTypes[values?.ownershipType]?.documentTypes && !(values.isCertifiedVetsFirst && hasDocumentType(VetsFirstCertification, values?.documents))) {
    selectedDocumentTypes = [...selectedDocumentTypes, ...ownershipTypes[values?.ownershipType]?.documentTypes];
  }
  // console.log(values.documents, values.miscDocuments);
  // console.log(values.owners);

  const selectedAttestationTypes = ["DD214", "NGB22", "Resume", "DisabilityLetter"];
  return (
    <>
      <div className="form-output-simple">
        <div className="form-output-section">
          <h4 className="text-primary h5">Business Information</h4>
          <hr />

          <ReviewSubmitFormLine name={"Legal Business Name"} value={values.legalName} />
          <ReviewSubmitFormLine name={"Federal Employer Identification Number (FEIN)"} value={values.federalId} />

          {[roles.DivisionAdmin, roles.DivisionUser].includes(loadUserRole()) && <ReviewSubmitFormLine name={"Control Number"} value={values?.controlNumber} />}

          {values.dbaNames?.map((dbaName, index) => {
            return <ReviewSubmitFormLine key={index} name={"Doing Business As (DBA) Name(s) (If Applicable)"} value={dbaName?.name} />;
          })}
          <hr />
          <ReviewSubmitFormAddressLines addresses={values.addresses} />
          <ReviewSubmitFormLine name={"Phone"} value={`${formatPhoneNumber(values.phoneNumber)} ${values?.phoneNumberExtension ? `ext: ${values?.phoneNumberExtension}` : ""}`} />
          <ReviewSubmitFormLine name={"Fax"} value={formatPhoneNumber(values.faxNumber)} />
          <ReviewSubmitFormLine name={"Email"} value={values.emailAddress} />
          <ReviewSubmitFormLine name={"Business Website Address"} value={values.homepageUrl} />
          <ReviewSubmitFormLine name={"Number of employees (Average Last 4 Quarters)"} value={values.totalEmployees} />

          <ReviewSubmitFormLine name={"SDVOB Vets First Verification Program"} value={values.isCertifiedVetsFirst ? "Yes" : "No"} />
          {values.isCertifiedVetsFirst && <ReviewSubmitFormDocumentLines documents={values.documents} types={["VetsFirstCertification"]} />}
          <hr />
          <ReviewSubmitFormLine name={"Ownership Type"} value={values.ownershipType && ownershipTypes[values.ownershipType]?.label} />
          <ReviewSubmitFormLine name={"Description of Business"} value={values.businessDescription} />
          <ReviewSubmitFormDocumentLines documents={values.documents} types={selectedDocumentTypes} />
          {values?.owners?.map((owner, index) => {
            {
              /* console.log(owner);
            console.log(values.documents); */
            }
            return (
              owner.hasAttested && (
                <div key={`owner-${owner?.id}`}>
                  <>
                    <hr />
                  </>
                  <b>Attested documents for {owner.name}</b>
                  <br />
                  <br />
                  <ReviewSubmitFormDocumentLines documents={values.documents?.filter((document) => document?.userId === owner?.userId)} types={selectedAttestationTypes} />
                </div>
              )
            );
          })}
          {/* {values?.documents?.find(document => selectedAttestationTypes.includes(document.type)) &&
            <>
              <hr />
              <ReviewSubmitFormDocumentLines
                documents={values.documents}
                types={selectedAttestationTypes}
              />
            </>
          } */}
          <hr />
          <ReviewSubmitFormLine
            name={"Business Type"}
            value={values?.serviceTypes?.length ? values?.serviceTypes?.map((serviceType) => businessTypes.find((item) => item[0] === serviceType)[1]).join(", ") : null}
          />
          <hr />
          <ReviewSubmitFormLine name={"Is the business currently certified as a Minority-Owned Business (MBE)?"} type={"boolean"} value={values.isCertifiedMinorityOwned} />
          <ReviewSubmitFormLine name={"Is the business currently certified as a Women-Owned Business (WBE)?"} type={"boolean"} value={values.isCertifiedWomenOwned} />
          <hr />
          <ReviewSubmitFormLine name={"Does your business provide goods or services statewide?"} type={"boolean"} value={values.isStatewide} />
          {!values.isStatewide && <ReviewSubmitFormLine name={"Counties served"} value={values?.counties?.join(", ")} />}
          <hr />
          <ReviewSubmitFormLine name={"Enter categories that best describe your business."} value={values.categories?.join(", ")} />
          <ReviewSubmitFormLine name={"Enter up to six NAICS codes that best describe your products and/or services."} value={values.naicsCodes?.join(", ")} />
          <ReviewSubmitFormLine name={"Enter keywords that best describe your business."} value={values.tags?.join(", ")} />
          <hr />
          <ReviewSubmitFormLine name={"Will the applicant business rent equipment to the state?"} type={"boolean"} value={values.isEquipmentRenter} />
          {values.isEquipmentRenter ? (
            <ReviewSubmitFormLine
              name={"Does the disabled veteran owner own at least 51% of the quantity and value of each piece of equipment that will be provided under a contract?"}
              type={"boolean"}
              value={values.isEquipmentRenter}
            />
          ) : null}
        </div>
        <hr />
        <div className="form-output-section">
          <h3 className="text-primary h5">Applicant Form</h3>
          <hr />
          {values.owners?.map((owner, index) => {
            return (
              <div key={index}>
                <ReviewSubmitFormLine name={"Name of Individual, Owner, Partner, LLC Member, LLC Manager, Shareholder, or Officer *"} value={owner.name} />
                <ReviewSubmitFormLine name={"Title"} value={owner.title} />
                <ReviewSubmitFormLine name={"Owner's Primary Function in the Business"} value={owner.role} />
                <ReviewSubmitFormLine name={"Percentage of ownership"} value={owner.percentageOwnership} />
                <ReviewSubmitFormLine name={"Service-disabled?"} value={owner.isServiceDisabled ? "Yes" : "No"} />
                {owner.isServiceDisabled && <ReviewSubmitFormLine name={"Percentage Service Disability ?"} value={owner.percentageServiceDisabled} />}
                <ReviewSubmitFormLine name={"Phone"} value={`${formatPhoneNumber(owner.phoneNumber)} ${owner?.phoneNumberExtension ? `ext: ${owner?.phoneNumberExtension}` : ""}`} />
                <ReviewSubmitFormLine name={"Email"} value={owner.emailAddress} />
                <ReviewSubmitFormAddressLines addresses={owner.addresses} />
              </div>
            );
          })}
          <hr />
          <ReviewSubmitFormLine
            name={"Are the disabled veteran owners or managers responsible for the negotiation, execution, and signature of contracts?"}
            type={"boolean"}
            value={values.isOwnersResponsibleForSigningContracts}
          />
          <ReviewSubmitFormLine
            name={"Are the disabled veteran owners or managers responsible for the execution of financial transactions and agreements (credit, banking, and bonding) operations?"}
            type={"boolean"}
            value={values.isOwnersResponsibleForFinancialContractExecution}
          />
          <ReviewSubmitFormLine
            name={"Are there any formal or informal restrictions limiting the voting power or control of the disabled veteran owners and/or disabled veteran managers?"}
            type={"boolean"}
            value={values.isOwnersVotingPowersLimited}
          />
          <ReviewSubmitFormLine
            name={"Are there any third-party agreements restricting the control of the disabled veteran owners and/or disabled veteran managers?"}
            type={"boolean"}
            value={values.isOwnersControlRestrictedByThirdParty}
          />
          <ReviewSubmitFormLine
            name={"Do the disabled veteran owners or disabled-veteran managers possess the requisite experience, education, knowledge, and qualifications in the applicant firm’s field of operations?"}
            type={"boolean"}
            value={values.isOwnersFieldQualified}
          />
          <ReviewSubmitFormLine
            name={"Are the salary/profits of the disabled veteran owners and disabled veteran managers commensurate (proportionate) with their ownership interest?"}
            type={"boolean"}
            value={values.isOwnersCompensationCommensurateWithStake}
          />
          <ReviewSubmitFormLine
            name={"Do the disabled veteran owners or disabled veteran managers have direct responsibility for subcontractors, if any?"}
            type={"boolean"}
            value={values.isOwnersResponsibleForSubcontractors}
          />
          {values.ownershipType === "Corporation" ? (
            <>
              <hr />
              <ReviewSubmitFormLine
                name={"Do the disabled veteran owners or disabled veteran managers have direct responsibility for the applicant firm’s equipment?"}
                value={values.isOwnersResponsibleForEquipment}
                type={"boolean"}
              />
              <ReviewSubmitFormLine
                name={"Do the disabled veteran owners or disabled veteran managers have direct responsibility for the applicant firm’s materials?"}
                value={values.isOwnersResponsibleForMaterials}
                type={"boolean"}
              />
              <ReviewSubmitFormLine
                name={"Do the disabled veteran owners or disabled veteran managers have direct responsibility for the applicant firm’s facilities (office/yard)?"}
                value={values.isOwnersResponsibleForFacilities}
                type={"boolean"}
              />
              <ReviewSubmitFormLine
                name="Do the disabled veteran owners receive at least 51% of any dividends paid by the firm, including distribution upon liquidation?"
                value={values.isOwnersReceiveDividendsMajority}
                type={"boolean"}
              />
              <ReviewSubmitFormLine
                name="Do the disabled veteran owners have the ability to appoint or elect and remove the majority of the board of directors?"
                value={values.isOwnersControlBoardMajority}
                type={"boolean"}
              />
              <ReviewSubmitFormLine
                name="Are the disabled veteran owners entitled to 100% of the value of each share of stock they hold?"
                value={values.isOwnersEntitledFullStockShareValue}
                type={"boolean"}
              />
            </>
          ) : null}
        </div>
        <hr />
        <div className="form-output-section">
          <h3 className="text-primary h5">Financial Form</h3>
          <hr />
          {values?.taxReturns &&
            values?.taxReturns?.map((taxReturn, index) => {
              const { value, yearBeginning, yearEnding, yearNumber, documentId, description } = taxReturn;
              return taxReturn?.documentationExists ? (
                <div key={index}>
                  <ReviewSubmitFormLine name={"Gross Annual Receipts Less Returns and Allowances (See Above for Assistance Locating This Line Item)"} value={outputMoney(value)} />
                  <ReviewSubmitFormLine name={`Tax Year ${yearNumber}, Beginning`} value={yearBeginning} />
                  <ReviewSubmitFormLine name={`Tax Year ${yearNumber}, Ending`} value={yearEnding} />
                  {documentId === EMPTY_GUID || !documentId ? (
                    <ReviewSubmitFormLine name={`Tax year ${yearNumber} Reason for exclusion`} value={`${description ? description : "Not Provided"}`} />
                  ) : (
                    <ReviewSubmitFormLine name={`Tax year ${yearNumber} Return filename`} value={description} downloadId={documentId !== EMPTY_GUID ? documentId : false} />
                  )}
                </div>
              ) : (
                <ReviewSubmitFormLine key={`tax-return-${taxReturn?.yearNumber}`} name={`Tax Year ${taxReturn?.yearNumber}`} value={`Not provided: ${description ? description : ""}`} />
              );
            })}
        </div>
        {values?.miscDocuments?.length ? (
          <>
            <hr />
            <div className="form-output-section">
              <h3 className="text-primary h5">Miscellaneous Change Request Documents</h3>
              <hr />
              <ReviewSubmitFormDocumentLines documents={values?.miscDocuments} types={["MiscellaneousApplication"]} />
            </div>
          </>
        ) : null}
      </div>
      <div id="signature-status">
        <h3>Signature Statuses</h3>
        {values?.owners
          ? values?.owners.map((item, i) => {
              let resendApplicationOwnerInvitationHtml = <></>;

              if (item.userId === EMPTY_GUID) {
                const resentApplicationOwnerStatus = resentApplicationOwnerIds[item.id] || resentApplicationOwnerStatuses.none;

                switch (resentApplicationOwnerStatus) {
                  case resentApplicationOwnerStatuses.sent:
                    resendApplicationOwnerInvitationHtml = <span>(Invitation email was sucessfully resent)</span>;
                    break;
                  case resentApplicationOwnerStatuses.error:
                    resendApplicationOwnerInvitationHtml = <span>(An error occured resending the invitation)</span>;
                    break;
                  case resentApplicationOwnerStatuses.alreadyAssociated:
                    resendApplicationOwnerInvitationHtml = <span>(User has already accepted the invitation)</span>;
                  case resentApplicationOwnerStatuses.sending:
                    resendApplicationOwnerInvitationHtml = (
                      <span>
                        <span>(Resending invitation email ...</span>
                        <FontAwesomeIcon icon={"spinner"} className="fa-spin" />)
                      </span>
                    );
                    break;
                  case resentApplicationOwnerStatuses.none:
                  default:
                    resendApplicationOwnerInvitationHtml = (
                      <button className="invisible-button cursor-pointer underline" onClick={() => resendApplicationOwnerInvitationEmail(values.id, item.id)}>
                        (Resend Email Invitation)
                      </button>
                    );
                    break;
                }
              }

              return (
                <div key={`owner-attest-${i}`} className="row">
                  <div className="col-sm-6">
                    <strong>{item?.name}</strong>
                  </div>
                  <div className="col-sm-6">
                    <div>
                      {item?.hasAttested ? "Signed" : "Not Signed"}
                      <span className="p-2">{resendApplicationOwnerInvitationHtml}</span>
                    </div>
                  </div>
                </div>
              );
            })
          : null}
      </div>

      <hr />
    </>
  );
}

export default ApplicationReviewComponent;

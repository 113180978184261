import React from 'react'
import { Button } from 'react-bootstrap'

function ReactiveButton(props) {
  const { label, className, processing, type, disabled } = props;

  return (
    <div>
      {!processing ? <Button type={type} className={className} disabled={disabled}>
        {label}
      </Button> : <span className="loader loader-pulse mb-4">Loading...</span>}
    </div>
  )
}

export default ReactiveButton

import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../../../Layouts/Base";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import CorrespondenceForm from "./CorrespondenceForm";
import { roles, loadUserRole } from "../../../util/userHelper";
import {
  getCorrespondence,
  getCorrespondenceList,
  handleCorrespondenceCreate,
  handleCorrespondenceClose,
  handleCorrespondenceReopen,
  handleCorrespondencesReply,
  handleCorrespondencesReplyReply,
  getBusinessCorrespondence,
  getBusinessCorrespondenceList,
  handleBusinessCorrespondenceCreate,
  handleBusinessCorrespondenceClose,
  handleBusinessCorrespondenceReopen,
  handleBusinessCorrespondencesReply,
  handleBusinessCorrespondencesReplyReply,
  getPotentialCorrespondenceParticipants
} from "../../../util/api";
import CorrespondenceList from "./CorrespondenceList";
import CorrespondenceDetail from "./CorrespondenceDetail";

const Correspondence = ({ messages, routeInitialValues, contract }) => {
  const { correspondenceId, procurementId, businessId } = useParams();
  const userInfo = useContext(UserContext);
  const context = businessId || [roles.DivisionAdmin, roles.DivisionUser].includes(userInfo?.user?.role) ? "business" : "procurement";
  const contextId = businessId ? businessId : procurementId;
  const [showForm, setShowForm] = useState(!!correspondenceId);
  const [correspondenceList, setCorrespondenceList] = useState(null);
  const [correspondence, setCorrespondence] = useState(null);
  const [updateCount, setUpdateCount] = useState(0);
  const [potentialParticipants, setPotentialParticipants] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();
  const location = useLocation();

  const RequestController = {
    getPotentialCorrespondenceParticipants: { business: null, procurement: getPotentialCorrespondenceParticipants },
    getCorrespondences: { business: getBusinessCorrespondenceList, procurement: getCorrespondenceList },
    getCorrespondence: { business: getBusinessCorrespondence, procurement: getCorrespondence },
    createCorrespondence: { business: handleBusinessCorrespondenceCreate, procurement: handleCorrespondenceCreate },
    closeCorrespondence: { business: handleBusinessCorrespondenceClose, procurement: handleCorrespondenceClose },
    reopenCorrespondence: { business: handleBusinessCorrespondenceReopen, procurement: handleCorrespondenceReopen },
    replyToCorrespondence: { business: handleBusinessCorrespondencesReply, procurement: handleCorrespondencesReply },
    replyToCorrespondenceReply: { business: handleBusinessCorrespondencesReplyReply, procurement: handleCorrespondencesReplyReply },
  };

  useEffect(() => {
    const fetchPotentialCorrespondenceParticipants = async () => {      
      const requestMethod = RequestController.getPotentialCorrespondenceParticipants[context];

      if (typeof requestMethod === 'function') {
        setIsLoading(true);

        try {
          const potentialParticipantsResponse = await requestMethod(contextId);

          setPotentialParticipants(potentialParticipantsResponse?.data);
        } catch (e) {
          console.log(e);

          setPotentialParticipants({
            businessParticipants: [],
            agencyParticipants: []
          });
        } finally {
          setIsLoading(false);
        }
      } else {
        setPotentialParticipants(null);
        setIsLoading(false);
      }
    };

    fetchPotentialCorrespondenceParticipants();
  }, []);

  useEffect(() => {
    const fetchCorrespondence = async () => {
      // console.log("fetching single correspondence");
      const correspondenceRes = await RequestController.getCorrespondence[context](correspondenceId);
      // console.log(correspondenceRes.data);
      setCorrespondence(correspondenceRes?.data);
    };

    const fetchCorrespondenceList = async () => {
      const correspondenceListRes = await RequestController.getCorrespondences[context](contextId);
      // console.log(correspondenceListRes.data);
      setCorrespondenceList(correspondenceListRes?.data?.correspondences);
    };
    if (correspondenceId) {
      fetchCorrespondence();
    } else {
      fetchCorrespondenceList();
    }
  }, [updateCount]);

  const handleSubmit = async (data, { resetForm }) => {
    const correspondenceRes = await RequestController.createCorrespondence[context](data);
    resetForm();
    setUpdateCount(updateCount + 1);
    history.push(`${location.pathname}/${correspondenceRes?.id}`);
    // setCorrespondence({ ...savedCorrespondence });
  };

  return (
    <div>
      {correspondence?.id ? (
        <>
          <CorrespondenceDetail
            handleClose={RequestController.closeCorrespondence[context]}
            handleReopen={RequestController.reopenCorrespondence[context]}
            handleReply={RequestController.replyToCorrespondence[context]}
            handleReplyToReply={RequestController.replyToCorrespondenceReply[context]}
            correspondence={correspondence}
            correspondenceId={correspondenceId}
            updateCount={updateCount}
            setUpdateCount={setUpdateCount}
            validateOnMount={true}
          />
        </>
      ) : (
        <>
          {
            !isLoading && potentialParticipants && !potentialParticipants.businessParticipants.length
            ? <p className="mb-2">
              You cannot start a correspondence with the Prime Contractor because the business is not registered in SDVES. Contact the Prime Contractor directly and request they create an account and register their business.
            </p>
            : <></>
          }
          {![roles.DivisionAdmin, roles.DivisionUser].includes(loadUserRole()) && (
            <Button
              onClick={() => {
                setShowForm(true);
              }}
              type="button"
              variant="light"
              disabled={isLoading || (potentialParticipants && !potentialParticipants.businessParticipants.length)}
            >
              <FontAwesomeIcon className="mr-3" icon={["fas", "plus"]} /> Start a new correspondence
            </Button>
          )}
          <CorrespondenceForm
            routeInitialValues={{ contextId: contextId }}
            onSubmit={handleSubmit}
            // name={correspondenceId}
            correspondenceId={correspondenceId}
            showForm={showForm}
            setShowForm={setShowForm}
            // contract={contract}
            // procurement={procurement}
            context={context}
            validateOnMount={true}
            level={0}
          />

          <CorrespondenceList
            context={context}
            correspondenceList={correspondenceList}
            // procurement={procurement}
            procurementId={procurementId}
          />
        </>
      )}
    </div>
  );
};
export default Correspondence;

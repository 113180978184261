import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useHistory } from "react-router-dom";
import { getActiveOrg, getBusinessLegalName, loadUserRole, roles, getOrgType } from "../../util/userHelper";
import { trimName } from "../../util/helpers";

const UserInfo = (props) => {
  const { userInfo, activeOrgId, onActiveOrgId } = props;
  const history = useHistory();

  const activeOrg = getActiveOrg(userInfo, activeOrgId);
  const orgType = getOrgType(loadUserRole());
  let orgItems = userInfo?.user?.[orgType.plural] ? userInfo?.user?.[orgType.plural] : [];
  let orgItemsAlpha = [];

  switch (loadUserRole()) {
    case "Business":
      // orgItemsAlpha = orgItems?.length ? orgItems.sort((a, b) => a?.business?.legalName.localeCompare(b?.business?.legalName)) : [];
      orgItemsAlpha = orgItems;
      break;
    case "AgencyUser":
    case "AgencyAdmin":
      orgItemsAlpha = orgItems?.length ? orgItems.sort((a, b) => a.name.localeCompare(b.name)) : [];
      // console.log(orgItemsAlpha);
      break;
    default:
      break;
  }

  const dropdownItems = orgItemsAlpha?.map((org, index) => {
    // console.log(org, index);
    // if (index > 5) return;
    const userRole = loadUserRole();

    let currentOrg;
    if (orgType.singular === "business") {
      currentOrg = org[orgType.singular];
    } else if (orgType.singular === "agency") {
      // console.log('singular agency');
      currentOrg = org;
    } else {
      return;
    }

    return (
      <div key={`user-action-${index}`}>
        <Dropdown.Item
          onClick={() => {
            onActiveOrgId(currentOrg.id);
            history.push("/");
          }}
          id={`${orgType.singular}-select-${index}`}
          role="button"
          className={`py-2 ${currentOrg.id === activeOrgId ? "active" : ""}`}
        >
          {userRole === roles.Business ? (
            <>
              {currentOrg?.isCertifiedSdvob ? "* " : currentOrg?.applications?.length ? "- " : ""}
              {trimName(currentOrg.legalName, 40)}
            </>
          ) : null}
          {[roles.AgencyAdmin, roles.AgencyUser].includes(userRole) ? currentOrg.name : null}
        </Dropdown.Item>
      </div>
    );
  });

  const dropdownToggle = () => {
    switch (loadUserRole()) {
      case "Business":
        if (getBusinessLegalName(activeOrg)) {
          return trimName(getBusinessLegalName(activeOrg), 35);
        } else if (userInfo) {
          return userInfo?.user?.userId;
        } else {
          return "";
        }
      case "AgencyAdmin":
      case "AgencyUser":
        if (activeOrg?.name) {
          return activeOrg.name;
        } else if (userInfo) {
          return userInfo?.user?.userId;
        } else {
          return "";
        }
      default:
        return userInfo ? userInfo?.user?.userId : "";
    }
  };

  // const activeBusinessName = activeOrg ? activeOrg?.business?.legalName : "No Businesses Assigned";
  return (
    <Dropdown id="user-action-panel-wrapper" className="user-action-panel-wrapper">
      <Dropdown.Toggle variant="white-trans btn-round" id="user-action-panel" className="mb-0">
        {dropdownToggle()}
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-3 dropdown-menu-md-end dropdown-menu-right" id="user-action-org-names">
        <div className="basic-info d-flex flex-row mb-3 align-items-center">
          <div className="user-initial bg-secondary p-2 circle d-flex align-items-center justify-content-center mr-3">
            <span className="h2 mb-0">{userInfo?.user?.firstName?.charAt(0)}</span>
          </div>
          <p className="text-dark mb-0">
            {userInfo?.user?.firstName} {userInfo?.user?.lastName}
            <br />
            {userInfo?.user?.emailAddress}
          </p>
        </div>
        {[roles.Business, roles.AgencyUser, roles.AgencyAdmin].includes(loadUserRole()) ? (
          <div className="businesses mb-3">
            {dropdownItems}
            {loadUserRole() === roles.Business ? (
              <Dropdown.Item className="addNewBusiness" role="button" href={"/signup/prime-contractor"}>
                <FontAwesomeIcon className="mr-2 text-secondary" icon={"plus"} /> Add a New Business
              </Dropdown.Item>
            ) : (
              ""
            )}
          </div>
        ) : null}
        {props.children}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UserInfo;

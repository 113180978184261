import React from "react";
import ReactiveButton from "../../../Forms/Input/ReactiveButton";
import DebugModal from "../../../Debug/DebugModal";
import _ from "lodash";
import { Form, FastField } from "formik";
import { withFormikFormComponent } from "../../../Forms/FormikForm";
import InputComponent from "../../../Forms/Input/InputComponent";
import { schemas } from "../../../../util/errorHelper";

const ReportExtensionRequestFormInternal = (props) => {
  const { reportType, formikBag, reportId } = props;
  const { values, isSubmitting } = formikBag;

  return (
    <>
      <DebugModal formikBag={formikBag} name="Formik Bag" />
      <div id={`${reportType}-extension-request-form-wrapper`} className="bg-light">
        <h3>{_.startCase(reportType)} Extension Request</h3>
        <hr />
        <Form className="">
          <FastField wrapperClass="w-100" label="Request Extension Reason" type="textarea" name={"extensionRequestedReason"} component={InputComponent} />

          <ReactiveButton type="submit" className="btn btn-md btn-block btn-secondary px-5" label="Request Extension" processing={isSubmitting} disabled={!values?.extensionRequestedReason} />
        </Form>
      </div>
    </>
  );
};

const ReportExtensionRequestForm = withFormikFormComponent(ReportExtensionRequestFormInternal);
export default ReportExtensionRequestForm;

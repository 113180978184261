import { LoginCallback, SecureRoute, useOktaAuth } from "@okta/okta-react";
import React, { createContext, useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import Header from "../components/Header/Header";
import Mainsidebar from "../components/MainSidebar/MainSidebar";
import Application404 from "../pages/Application404";
import Login from "../pages/Login";
import Logout from "../pages/Logout";
import { open_routes, secure_routes } from "../router/routes";
import { getApiToken2, getUser, returnPathLocalStorageKey, associateApplicationOwner, logoutSdves } from "../util/api";
import { hasSidebar, isExtraWide, setApplicationOwnerAssociationId } from "../util/helpers";
import {
  clearLocalStorage,
  getAccessToken,
  getActiveOrg,
  getFirstOrg,
  loadActiveOrgId,
  loadUserToken,
  removeActiveOrgId,
  roles,
  storeActiveOrgId,
  storeUserToken,
  makeBusinessPromptShown,
  isBusinessPromptShown,
  getUserId
} from "../util/userHelper";
import NetworkError from "../pages/NetworkError";
import BusinessPrompt from "../components/Signup/BusinessPrompt";

const getSecureRoleRoutes = (userRole) => {
  const secureRoleRoutes = secure_routes.filter((route) => route?.roles?.includes(userRole) || !route.roles);
  return secureRoleRoutes;
};

let userWasAssociatedPromise = null;

const Home = (props) => {
  const ENVIRONMENT = process.env.NODE_ENV;
  const { oktaAuth, authState } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(false);
  const [notifications, setNotifications] = useState();
  const [businessPromptShown, setBusinessPromptShown] = useState(true);
  const [activeOrgId, setActiveOrgId] = useState();
  const [activeOrg, setActiveOrg] = useState({});
  const location = useLocation().pathname.replace(/["']/g, "");
  const history = useHistory();
  const [userInfoUpdates, setUserInfoUpdates] = useState(0);
  const [loginLoading, setLoginLoading] = useState(true);

  // Ensure we know the active organization for user roles that can have many (Agency and Business)
  // If it hasn't been manaully chosen already, defer to first in the returned list
  const initOrgData = async (newUserInfo) => {
    // console.log("init org data", newUserInfo);
    let activeOrgId = loadActiveOrgId();
    if (!activeOrgId) {
      const org = getFirstOrg(newUserInfo);
      if (org) {
        activeOrgId = org.id;
        storeActiveOrgId(org.id);
      } else {
        activeOrgId = "";
        removeActiveOrgId();
      }
    }

    setActiveOrgId(activeOrgId);
    setActiveOrg(getActiveOrg(newUserInfo, activeOrgId));
  };

  const makeBusinessPromptToShownLocal = () => {
    makeBusinessPromptShown();
    setBusinessPromptShown(isBusinessPromptShown());
  };

  const gotoBusinessInfo = () => {
    const url = `/business/${activeOrgId}/edit`;
    history.push(url);
    makeBusinessPromptShown();
    setBusinessPromptShown(isBusinessPromptShown());
  };

  // Return users to their original route in case they were not authenticated on first visit
  const loginAndReturn = () => {
    localStorage.setItem(returnPathLocalStorageKey, history.location.pathname);

    setApplicationOwnerAssociationId();

    if (loadUserToken()) {
      const logoutAndLogin = async () => {
        await logOutLocal();
        history.push("/login");
      };
      logoutAndLogin();
    } else {
      history.push("/login");
    }
  };

  const setupUser = async () => {

    if (userWasAssociatedPromise) return;

    // Get the SDVES-specific user information like organizations they belong to and role
    setUserInfo({});

    if (!userWasAssociatedPromise && setApplicationOwnerAssociationId()) {
      try {
        userWasAssociatedPromise = associateApplicationOwner(getUserId());
        await userWasAssociatedPromise;

      } catch {
        //ignore ?
      } finally {
        userWasAssociatedPromise = null;
      }
    }

    const userRes = await getUser();
    const user = userRes.data;
    setUserInfo({ user });

    // Show business user information correctness prompt after every login
    if (user?.role === roles.Business) {
      initOrgData({ user });
      if (user?.businesses?.length) {
        if (user?.businesses?.find((i) => i.business.isCertifiedSdvob && i.business.isActive)) {
          setBusinessPromptShown(isBusinessPromptShown());
        }
        // Allow business user without claimed business to use the search, but otherwise they have nothing they can do so force to the signup screen.
      } else if (!location?.includes("business-search")) {
        history.push("/signup/prime-contractor");
      }
    } else if ([roles.AgencyAdmin, roles.AgencyUser].includes(user?.role)) {
      initOrgData({ user });
    }
  };

  // authentication and setting user info
  useEffect(() => {
    const checkUserAuth = async () => {
      // console.log("checking user auth");
      try {
        setLoginLoading(true);
        if (loadUserToken() && (ENVIRONMENT === "development" || authState?.isAuthenticated === true)) {
          await setupUser();
        } else if (authState?.isAuthenticated === undefined) {
          // console.log("else if okta undefined");
          // console.log("Okta Auth is not ready");
        } else if (authState?.isAuthenticated === false) {
          // console.log("else if okta false");
          if (loadUserToken()) {
            logOutLocal();
          } else {
            // console.log("Okta Auth is not ready but use token is false. Wait for user to click login manully");
            // do not so anything wait for the user to click on the login button
          }
        }
        // Successful verification of OKTA auth
        // Proceed checking for SDVES-issued auth and get user info
        else if (authState?.isAuthenticated === true) {
          // console.log("else if okta true");
          // if (!loadUserToken()) {
          const acccessToken = await oktaAuth.getAccessToken();
          const result = await getApiToken2(acccessToken);
          storeUserToken(result);
          history.push(localStorage.getItem(returnPathLocalStorageKey));
          await setupUser();
          // }
        }
      } finally {
        setLoginLoading(false);
      }
    };
    checkUserAuth();
  }, [authState, userInfoUpdates]);

  // Agencies and Business users can belong to many entities, so this determines the organization they are currently acting on behalf of
  const handleActiveOrgId = async (orgId) => {
    const userRes = await getUser();
    const user = userRes.data;
    setUserInfo({ user });
    setActiveOrgId(orgId);
    storeActiveOrgId(orgId);
    setActiveOrg(getActiveOrg({ user }, orgId));
  };

  // Ensure that all SDVES-derived information is cleared since Okta logout will not do this
  const logOutLocal = async () => {
    setUserInfo(null);
    setNotifications(null);
    setActiveOrgId(null);
    setActiveOrg(getActiveOrg(null));
    clearLocalStorage();
  };

  // This will both log us out at Okta, as well as clear our storage which will have bearer tokens and user information
  const logOut = async () => {
    try {
      setLoginLoading(true);
      await logoutSdves();
      await oktaAuth.signOut();
      await history.push("/");
      logOutLocal();
    } catch (e) {
      const message = e.name || e.message;
      props.setError1(message);
    } finally {
      setLoginLoading(false);
    }
  };

  // const handleLogin = (userToken) => {
  //   initUserData();
  // }

  let mainClass = "col-12";
  if (hasSidebar(location)) {
    if (isExtraWide(location)) {
      mainClass = "col-md-8 col-lg-9 col-xl-10 py-md-5";
    } else {
      mainClass = "col-md-8 col-lg-9 col-xl-10 py-md-5";
    }
  }

  return (
    <>
      <UserContext.Provider value={userInfo}>
        <Header logOut={logOut} loginAndReturn={loginAndReturn} onActiveOrgId={handleActiveOrgId} activeOrgId={activeOrgId} />
        <BusinessPrompt businessPromptShown={businessPromptShown} makeBusinessPromptToShownLocal={makeBusinessPromptToShownLocal} gotoBusinessInfo={gotoBusinessInfo} />
        <main id="main-content" className="main-content position-relative">
          {props.hasNetworkError ? <NetworkError /> : null}

          {!props.hasNetworkError && (
            <div className={`container-fluid`}>
              {/* <div className={`container${hasSidebar(location) ? `-fluid` : ``}`}> */}
              <div className="row">
                {hasSidebar(location) ? <Mainsidebar activeOrg={activeOrg} userInfo={userInfo} activeOrgId={activeOrgId} /> : null}

                <section className={mainClass}>
                  {loginLoading || (userInfo && !userInfo?.user?.role) ? (
                    <div className="text-center">Loading your content...</div>
                  ) : (
                    <>
                      {/* Load the public routes, which don't care about user role or auth status */}
                      <Switch>
                        {open_routes.map((route) => {
                          const { path, exact } = route;
                          const Component = route.component;
                          return (
                            <Route
                              key={path}
                              path={path}
                              exact={exact}
                              render={(props) => <Component routeInitialValues={route.initialValues} userInfo={userInfo} activeOrgId={activeOrgId} activeOrg={activeOrg} />}
                            />
                          );
                        })}

                        {/* IF they have a role assigned, load the appropriate secure routes for the role */}
                        {userInfo?.user?.role
                          ? getSecureRoleRoutes(userInfo.user.role)?.map((route) => {
                            const { path, exact } = route;
                            const Component = route.component;
                            return (
                              <Route
                                key={path}
                                path={path}
                                exact={exact}
                                render={(props) => (
                                  <Component
                                    routeInitialValues={route.initialValues}
                                    validateOnChange={route.validateOnChange ? route.validateOnChange : false}
                                    userInfo={userInfo}
                                    onActiveOrgId={handleActiveOrgId}
                                    activeOrgId={activeOrgId}
                                    activeOrg={activeOrg}
                                    makeBusinessPromptToShownLocal={makeBusinessPromptToShownLocal}
                                    userInfoUpdates={userInfoUpdates}
                                    setUserInfoUpdates={setUserInfoUpdates}
                                  />
                                )}
                              />
                            );
                          })
                          : null}
                        <SecureRoute
                          path={"/login"}
                          exact={true}
                          render={(props) => (
                            <Login routeInitialValues={{}} userInfo={userInfo} activeOrgId={activeOrgId} activeOrg={activeOrg} makeBusinessPromptToShownLocal={makeBusinessPromptToShownLocal} />
                          )}
                        />
                        <Route path="/implicit/callback" component={LoginCallback} />
                        <Route path="/logout1" render={() => <Logout logOut={logOut} />} />
                        {(userInfo === false || userInfo?.user?.role) && <Route path="*" render={() => <Application404 userInfo={userInfo} loginAndReturn={loginAndReturn} />} />}
                      </Switch>
                    </>
                  )}
                </section>
              </div>
            </div>
          )}
        </main>
      </UserContext.Provider>
    </>
  );
};

export default Home;
// export default withOktaAuth(Home);
export const UserContext = createContext();

import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FastField, Field } from "formik";
import _ from "lodash";
import { Button } from "react-bootstrap";
import { updateInitialItems } from "../../../util/helpers";
import DebugModal from "../../Debug/DebugModal";
import Table from "../../Table/Table";
import { withFormikFormComponent } from "../FormikForm";
import InputComponent from "./InputComponent";

const FilterTableFormInternal = (props) => {
  const { FilterListConfig, Columns, formikBag, tableItems, action, actionTerm, hasDownload, downloadLabel, downloadCall, tableName } = props;
  const { values, setFieldValue } = formikBag;
  const tableItems1 = updateInitialItems(tableItems, FilterListConfig);
  const [filters, setFilters] = useState([]);
  const [filterList, setFilterList] = useState(Object.keys(FilterListConfig));
  const [queryObj, setQueryObj] = useState({});
  // const value = _.get(values, `filterValue`);

  useEffect(() => {
    // console.log(filters);
    let newQueryObj = {};
    filters.map((filter) => {
      newQueryObj[filter.filterCode] = filter.filterValue;
    });
    // console.log(newQueryObj);
    setQueryObj(newQueryObj);
  }, [filters]);

  const getLabelOfId = (filterValueItem, options) => {
    // return options?.find(option => option.id === filterValueItem)?.label?.toLowerCase();
    return filterValueItem?.toLowerCase();
  };

  const getFilteredItems = () => {
    let items1 = tableItems1 ? [...tableItems1] : [];

    // iterate through each top level filter
    filters.forEach((filter) => {
      // filter full data for table
      items1 = items1.filter((item) => {
        const { filterCode, filterValue, options, isExact } = filter;

        // The actual column of data corresponding to our current filter
        //itemDisplayValue is always a string
        let itemDisplayValue = item[filterCode];

        // Split into array of values if we have to check for exact matches
        const itemDisplayValueArr = itemDisplayValue.split(",");

        // isExact is used for some scenarios where we are filtering with well-known values/enums
        // we will use it when some of those values are partial matches of other values
        // Example would be "classifications" where options include "Construction" and "Construction Professional Services"
        if (isExact && filterValue) {
          for (let i = 0; i < filterValue.length; i++) {
            // console.log(filterValue[i]);
            if (itemDisplayValueArr.includes(filterValue[i])) {
              return true;
            }
          }
        } else if (filterValue) {
          if (Array.isArray(filterValue)) {
            // use this for partial match
            const result = filterValue.find((filterValueItem) => itemDisplayValue?.toLowerCase()?.includes(getLabelOfId(filterValueItem, options)));
            // Use this for exact match
            // const result = filterValue.find((filterValueItem) => itemDisplayValue?.toLowerCase() === getLabelOfId(filterValueItem, options));
            return result;
          } else {
            // filterValue is not array
            return String(itemDisplayValue)?.toLowerCase()?.includes(String(filterValue)?.toLowerCase());
          }
        }
      });
    });
    return items1;
  };

  const updateInitalItemsFromFilters = () => {
    // only filter out value filters on select
    const filterList = Object.keys(FilterListConfig).filter((filterCode) => !filters.find((filter) => filter.filterCode === filterCode));
    setFilterList([...filterList]);
    if (filterList?.length > 0) {
      // setFieldValue(`filterCode`, filterList[0]);
    }
  };

  // useEffect(() => {
  //   const items1 = updateInitialItems(tableItems, FilterListConfig);
  //   setItems(items1);
  // }, [tableItems])

  useEffect(() => {
    updateInitalItemsFromFilters(filters);
  }, [filters]);

  const addFilters = (e) => {
    e.preventDefault();
    const { filterCode, filterValue } = values;
    const { options, isExact } = FilterListConfig[filterCode];
    setFilters((filters) => {
      const newItems = [...filters];
      if (filterValue) {
        newItems.push({ filterCode, filterValue, options, isExact });
        setFieldValue(`filterValue`, "");
        setFieldValue(`filterCode`, null);
      }
      return newItems;
    });
  };

  const removeFilters = (index) => {
    let newValues = [...filters];

    newValues.splice(index, 1);
    // console.log(newValues);
    setFilters(newValues);
  };

  const filterValueDisplay = (filterValue) => {
    return Array.isArray(filterValue) ? filterValue.join(" OR ") : filterValue;
  };

  // console.log(getFilteredItems());

  // const filterValueEncoded = (filterValue) => {
  //   // return Array.isArray(filterValue) ? filterValue.reduce((str, filterValue) => `${str},${encodeURIComponent(filterValue.filterCode)}`, '') : filterValue;
  //   // return Array.isArray(filterValue) ? filterValue.reduce((str, filterValue) => `${str},${encodeURIComponent(filterValue.filterCode)}`, '') : filterValue;
  // };

  const fieldAtts = _.get(FilterListConfig, values?.filterCode);
  return (
    <section id="messages">
      <div>
        <div className="field-group table-filter-wrapper">
          <div className="form-row align-items-start mx-0">
            <Field
              label="Filter by..."
              wrapperClass="col-lg-3 px-0 filter-wrapper fieldWrapper mb-3"
              name={`filterCode`}
              component={InputComponent}
              type="select"
              // setFieldValue={setFieldValue}
              options={[["", "Select Filter"], ...filterList.map((code) => [code, Columns.find((column) => column.filterCode === code)?.Header])]}
              required={false}
              // onChange={(e) => setFieldValue(`filterCode`, "")}
              onChange={(e) => {
                setFieldValue(`filterCode`, e.target.value);
                setFieldValue(`filterValue`, "");
              }}
            />
            {values.filterCode ? (
              <>
                {fieldAtts?.type === "typeahead" ? (
                  <div class="col-lg-7 px-0 typeahead-wrapper mt-4 pt-1">
                    <Field
                      label={`Search for Term - There are ${fieldAtts?.options?.length} options available in this list.`}
                      placeholder="Search Term"
                      wrapperClass=""
                      name={`filterValue`}
                      component={InputComponent}
                      required={false}
                      type="typeahead"
                      // setFieldValue={setFieldValue}
                      filterBy={["id"]}
                      multiple={true}
                      options={fieldAtts?.options}
                      labelClass="sr-only"
                    />
                  </div>
                ) : fieldAtts?.type === "select" ? (
                  <Field
                    label="Search Term"
                    placeholder="Search Term"
                    wrapperClass="col-lg-7 px-0 mb-3 mt-4 pt-1"
                    name={`filterValue`}
                    component={InputComponent}
                    required={false}
                    type="select"
                    // setFieldValue={setFieldValue}
                    options={fieldAtts?.options}
                    labelClass="sr-only"
                  />
                ) : (
                  <FastField
                    label="Search Term"
                    placeholder="Search Term"
                    wrapperClass="col-lg-7 px-0 mb-3 mt-4 pt-1"
                    name={`filterValue`}
                    component={InputComponent}
                    required={false}
                    type="text"
                    labelClass="sr-only"
                  />
                )}
              </>
            ) : null}
            <div className="col-lg-2 px-0 mt-4 pt-1">
              <Button className="btn-md btn-block mb-2" variant="secondary" onClick={addFilters} disabled={[null, ""].includes(values?.filterCode) || !values?.filterCode}>
                Apply Filter
              </Button>
            </div>
          </div>

          <hr />

          <div className="row align-items-center">
            <div className="col-lg-10 d-flex flex-row flex-wrap mb-3 mb-lg-0">
              {filters.map((filter, index) => {
                const { filterValue, filterCode, options } = filter;
                const column = Columns.find((column) => column.filterCode === filterCode);

                return (
                  <button
                    key={index}
                    variant="secondary"
                    className="btn btn-secondary btn-sm btn-small p-3 mr-2 mb-2 no-round"
                    onClick={() => {
                      removeFilters(index);
                    }}
                  >
                    {column?.Header}: {filterValueDisplay(filterValue, options)} <FontAwesomeIcon className="ml-3 cursor-pointer" icon={["fas", "times"]} />
                    <span className="sr-only">{filterValueDisplay(filterValue, options)}</span>
                  </button>
                );
              })}
            </div>
            <div className="col-lg-2">
              {filters?.length > 0 && (
                <button className="faux-link" onClick={() => setFilters([])}>
                  <FontAwesomeIcon className="mr-2 cursor-pointer" icon={["fas", "times"]} /> Clear filters
                  <hr class="d-lg-none" />
                </button>
              )}
            </div>
          </div>
        </div>

        {action ? (
          <button
            type="button"
            className="btn btn-sm btn-secondary"
            onClick={() => {
              action(values);
            }}
          >
            {actionTerm ? actionTerm : `Action`}{" "}
          </button>
        ) : null}

        <DebugModal formikBag={formikBag} />
        <div className="application-table-wrapper">
          <Table tableName={tableName} data={getFilteredItems()} columns={Columns} size={tableItems?.length} />

          {hasDownload && (
            <div className="py-3 text-right">
              <button
                className="faux-link"
                onClick={async () => {
                  await downloadCall(queryObj);
                }}
              >
                <FontAwesomeIcon icon={["fa", "download"]} size="xs" /> {downloadLabel ? downloadLabel : "Download Results"}
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
const FilterTableForm = withFormikFormComponent(FilterTableFormInternal);
export default FilterTableForm;

import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import BasicInfo from "../Sections/PlansAndWaivers/BasicInfo";
import PaymentsSummary from "../Sections/ComplianceReports/CompliancePaymentsSummary";
import ComplianceReportSubmit from "../Sections/ComplianceReports/ComplianceReportSubmit";
import Time from "../Sections/Time";
import Summary from "../Sections/Summary";
import { months } from "../../../util/typelistHelper";
import { submitComplianceReport, getComplianceReport, getCompliancePayments, updateComplianceReportStatus } from "../../../util/api";
import ComplianceReportsReviewForm from "../Views/ComplianceReportsReviewForm";
import { loadUserRole, roles } from "../../../util/userHelper";

const ComplianceReport = (props) => {
  const { contract, awardedPlan, procurementId, procurement, setUpdateCount, updateCount } = props;
  const { payments } = contract;
  const { year, month } = useParams();
  const [monthPayments, setMonthPayments] = useState([]);
  const [monthPaymentsTotal, setMonthPaymentsTotal] = useState(0);
  const [complianceReport, setComplianceReport] = useState({});
  const [totalPayments, setTotalPayments] = useState(0);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const canSubmit = currentYear > year || (currentYear == year && currentMonth >= month);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [complianceChangeCount, setComplianceChangeCount] = useState(0);
  const isBusiness = roles.Business === loadUserRole();
  const [totalSalesForMonth, setTotalSalesForMonth] = useState(complianceReport?.totalSalesForMonth);

  const handleSubmit = async (values) => {
    const report = {
      contractId: contract.id,
      // id: complianceReport.id,
      month: month,
      year: year,
      totalSalesForMonth: values?.totalSalesForMonth ? values?.totalSalesForMonth : 0,
    };
    setIsLoading(true);
    await submitComplianceReport(report);
    setIsLoading(false);
    setUpdateCount(updateCount + 1);
    history.push(`/procurements/${procurementId}/compliance-reports`);
  };

  const complianceSections = [
    {
      id: "basic-info",
      component: BasicInfo,
      heading: "Basic Info",
    },
    {
      id: "payments-summary",
      component: PaymentsSummary,
      heading: "Goal Summary",
      props: {
        contractTerm: procurement.contractTerm,
        awardedPlan: awardedPlan,
        monthPaymentsTotal: monthPaymentsTotal,
        payments: payments,
        complianceReport: complianceReport,
      },
    },
    {
      id: "time",
      component: Time,
      heading: "Time",
    },
    {
      id: "summary",
      component: Summary,
      heading: `${month}/${year} Summary`,
      props: {
        complianceReport: complianceReport,
        monthPaymentsTotal: monthPaymentsTotal,
        totalSalesForMonth: totalSalesForMonth,
      },
    },
    {
      id: "report-submit",
      component: ComplianceReportSubmit,
      heading: "",
      props: {
        monthPayments: monthPayments,
        monthPaymentsTotal: monthPaymentsTotal,
        year: year,
        month: month,
        complianceReport: complianceReport,
        awardedPlan: awardedPlan,
        procurement: procurement,
        canSubmit: canSubmit,
        onSubmit: handleSubmit,
        isLoading: isLoading,
        totalPayments: totalPayments,
        routeInitialValues: { totalSalesForMonth: complianceReport?.totalSalesForMonth },
        setTotalSalesForMonth: setTotalSalesForMonth,
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const reportRes = await getComplianceReport(contract.id, year, month);
      let monthPayments = [];

      if (reportRes?.data?.complianceReports[0]?.id) {
        setMonthPayments(reportRes?.data?.complianceReports[0]?.payments);
        setMonthPaymentsTotal(reportRes?.data?.complianceReports[0]?.totalSdvobPaymentsForMonth);
        setComplianceReport(reportRes?.data?.complianceReports[0]);
        setTotalPayments(reportRes?.data?.totalPayments);
      } else {
        const paymentsRes = await getCompliancePayments(contract.id, year, month);
        setMonthPayments(paymentsRes?.data.payments);
        setMonthPaymentsTotal(reportRes?.data?.complianceReports[0]?.totalSdvobPaymentsForMonth);
      }
      // const report = reports.data.find(report => report?.yearMonth.includes(`${year}-${month}`))
    };
    fetchData();
  }, [updateCount]);


  const setStatus = async (values) => {
    const response = await updateComplianceReportStatus({ ...values, year, month }, values.complianceReportId);
    setUpdateCount(updateCount + 1);
  };

  // const handleSubmitComplianceReport = async (values) => {
  //   await submitComplianceReport({ contractId: contract?.id, year: year, month: month, totalSalesForMonth: values?.totalSalesForMonth });
  //   setComplianceChangeCount(complianceChangeCount + 1);
  // };

  return (
    <section id="compliance-details-wrapper">
      <div id="compliance-details">
        <h2>
          Compliance Report for period: {`${month}/${year}`}
          <br />
          <span className="font-weight-normal">for contract {contract.name}</span>
        </h2>
        <h3>
          Status: {complianceReport?.status ? complianceReport?.status : "Unsubmitted"} {complianceReport?.enteredByAgency ? <span>- (Submitted by Agency)</span> : null}
        </h3>
        {complianceReport?.status === "Deficient" ? (
          <div className="alert alert-primary">
            <p>Notes: {complianceReport?.notes}</p>
          </div>
        ) : null}
        <p>
          {complianceReport?.status === "Created" ? (
            <>Prime Contractors must submit monthly compliance reports as evidence of their effort to achieve the SDVOB goal on the contract</>
          ) : (
            <>You are viewing the monthly compliance report submitted as evidence of efforts to achieve the SDVOB goal on the contract </>
          )}
          <p>
            <strong>
              This report is due {month == 12 ? months[0] : months[parseInt(month)]} 10, {month == 12 ? parseInt(year) + 1 : year}
            </strong>
          </p>
        </p>

        <div className="row">
          <div className={isBusiness ? "col-12" : "col-md-8"}>
            {complianceSections.map((item) => {
              const Section = item.component;

              return (
                <div key={item.id} className="compliance-section mb-3">
                  {item.heading && <p className="text-primary text-uppercase">{item.heading}</p>}
                  {contract.id ? <Section contract={contract} heading={item.heading} id={item.id} {...item.props} /> : null}
                  <hr className="my-4" />
                </div>
              );
            })}
          </div>
          {!isBusiness && (
            <div className="col-4 bg-light mt-3">
              <ComplianceReportsReviewForm
                // handleSubmitComplianceReport={handleSubmitComplianceReport}
                complianceReport={complianceReport}
                year={year}
                month={month}
                setStatus={setStatus}
                contract={contract}
                routeInitialValues={{ id: complianceReport.id, contractId: contract.id, status: complianceReport.status }}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ComplianceReport;

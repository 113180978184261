import React from "react";
import ReactiveButton from "../../../Forms/Input/ReactiveButton";
import DebugModal from "../../../Debug/DebugModal";
import _ from "lodash";
import { Form, FastField } from "formik";
import { withFormikFormComponent } from "../../../Forms/FormikForm";
import InputComponent from "../../../Forms/Input/InputComponent";
import { schemas } from "../../../../util/errorHelper";

const ReportStatusUpdateFormInternal = (props) => {
  const { reportType, formikBag, reportId } = props;
  const { values, isSubmitting } = formikBag;

  return (
    <>
      <DebugModal formikBag={formikBag} name="Formik Bag" />
      <div id={`${reportType}-update-status-form-wrapper`} className="bg-light">
        <h3>Update {_.startCase(reportType)} Status</h3>
        <hr />
        <Form className="">
          <FastField
            wrapperClass="w-100"
            label="Status"
            type="select"
            name={"status"}
            component={InputComponent}
            options={[
              [false, "Choose Status"],
              ["accept", "Approved"],
              ["reject", "Deficient"],
            ]}
          />
          {values?.status === "reject" ? <FastField wrapperClass="w-100" label="Message" type="textarea" name={"message"} component={InputComponent} /> : null}

          <ReactiveButton type="submit" className="btn btn-md btn-block btn-secondary px-5" label="Submit" processing={isSubmitting} disabled={["Submitted", "false"].includes(values?.status)} />
        </Form>
      </div>
    </>
  );
};

const ReportStatusUpdateForm = withFormikFormComponent(ReportStatusUpdateFormInternal, schemas.reportStatusFormSchema);
export default ReportStatusUpdateForm;

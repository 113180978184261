import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { getCorrespondenceList } from "../../../util/api";
import { getLocalTime } from "../../../util/helpers";
import { withFormikFormComponent } from "../../Forms/FormikForm";
import FilterTableForm from "../../Forms/Input/FilterTableForm";
import { loadUserRole, roles } from "../../../util/userHelper";

const CorrespondenceListInternal = (props) => {
  const { context, procurement, correspondenceList, setCorrespondenceList, correspondence, correspondenceUpdates, setCorrespondenceUpdates } = props;
  const location = useLocation();
  // const [correspondence, setCorrespondence] = useState(null);
  const [showCorrespondence, setShowCorrespondence] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // api call
    };
    fetchData();
  }, [correspondenceUpdates]);

  let FilterListConfig = {
    creatorName: {},
    category: {},
    messageFull: {},
  };

  let Columns = [
    {
      Header: "Message",
      accessor: "messageLink",
      defaultCanSort: true,
      sortType: "basic",
      filterCode: "messageLink",
    },
    {
      Header: "Message",
      accessor: "messageFull",
      defaultCanSort: true,
      sortType: "basic",
      filterCode: "messageFull",
      isVisible: false,
    },
    {
      Header: "Started By",
      accessor: "creatorName", // accessor is the "key" in the data
      disableSortBy: true,
      sortType: "basic",
      filterCode: "creatorName",
    },
    {
      Header: "Category",
      accessor: "category",
      defaultCanSort: true,
      sortType: "basic",
      filterCode: "category",
    },
    {
      Header: "# of replies",
      accessor: "numberOfReplies",
      defaultCanSort: true,
      sortType: "basic",
      filterCode: "numberOfReplies",
    },
    {
      Header: "Last Reply Date",
      accessor: "lastReplyDate",
      defaultCanSort: true,
      sortType: "basic",
      filterCode: "lastReplyDate",
    },
    {
      Header: "Resolved",
      accessor: "isClosed",
      defaultCanSort: true,
      sortType: "basic",
      filterCode: "isClosed",
    },
  ];

  if ([roles.DivisionUser, roles.DivisionAdmin].includes(loadUserRole())) {
    Columns.splice(2, 0, {
      Header: "Business",
      accessor: "businessName",
      disableSortBy: false,
      sortType: "basic",
      filterCode: "businessName",
    });
    FilterListConfig = { ...FilterListConfig, businessName: {} };
  }

  const initialItems = correspondenceList
    ? correspondenceList?.map((item, index) => {
        const { correspondenceReplies } = item;

        const items = {
          ...item,
          owner: item.userId,
          replyCount: correspondenceReplies?.length,
          lastReplyDate: item?.lastReplyDate ? getLocalTime(item.lastReplyDate) : "N/A",
          isClosed: item.isClosed ? "Yes" : "No",
          businessName: item.business?.legalName,
          messageLink: (
            <>
              <Link to={`${location.pathname}/${item.id}`}>{item.subject}</Link>
              <br />
              <span>{item.message}</span>
            </>
          ),
          messageFull: `${item.subject} - ${item.message}`,
        };

        if (loadUserRole() === roles.DivisionUser) {
          items["businessName"] = item?.business?.legalName;
        }

        return items;
      })
    : null;

  return (
    <>
      {initialItems ? (
        initialItems?.length > 0 ? (
          <FilterTableForm tableName="correspondence-list" Columns={Columns} FilterListConfig={FilterListConfig} tableItems={initialItems} />
        ) : (
          <h2>There are currently no open correspondence threads on this {context}</h2>
        )
      ) : (
        <>
          <h2>Loading...</h2>
        </>
      )}
    </>
  );
};
const CorrespondenceList = withFormikFormComponent(CorrespondenceListInternal);
export default CorrespondenceList;

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getGoalPlans } from "../../../util/api";
import { loadActiveOrgId } from "../../../util/userHelper";
import FilterTableForm from "../../Forms/Input/FilterTableForm";
import { getLocalDate } from "../../../util/helpers";

const FilterListConfig = {
  fiscalYear: {
    isJoin: false,
  },
};

const Columns = [
  {
    Header: "Fiscal Year",
    accessor: "fiscalYear", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "fiscalYear",
    isVisible: false,
  },
  {
    Header: "Fiscal Year",
    accessor: "fiscalYearLink", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "fiscalYearLink",
  },
  {
    Header: "Status",
    accessor: "status", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "status",
  },
  {
    Header: "Reviewed",
    accessor: "reviewed", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "reviewed",
  },
];

const GoalPlanList = (props) => {
  const { reportList } = props;
  const location = useLocation();

  const initialItems = reportList.map((report) => {
    const reportReviewed = report.reviewed !== null ? getLocalDate(report.reviewed) : report.status !== "Created" ? "Pending Review" : "Not Submitted";

    return {
      ...report,
      fiscalYearLink: <Link to={`${location.pathname}/${report.id}`}>{report.fiscalYear}</Link>,
      fiscalYear: report.fiscalYear.toString(),
      reviewed: reportReviewed,
    };
  });

  return (
    <>
      {initialItems ? (
        <section id="goal-plan-list">
          <div>{initialItems.length > 0 && <FilterTableForm tableName="goal-plans-list" Columns={Columns} FilterListConfig={FilterListConfig} tableItems={initialItems}></FilterTableForm>}</div>
        </section>
      ) : (
        <span className="loader loader-pulse">Loading goal plans...</span>
      )}
    </>
  );
};

export default GoalPlanList;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getLocalDate, outputMoney, isPrime } from "../../../util/helpers";
import FilterTableForm from "../../Forms/Input/FilterTableForm";
import PaymentCreateForm from "./PaymentCreateForm";

const FilterListConfig = {
  subcontractor: {
    isJoin: false,
  },
  paidDate: {
    isJoin: false,
  },
};

let Columns = [
  {
    Header: "Paid Date",
    accessor: "paidDate", // accessor is the "key" in the data
    disableSortBy: false,
    filterCode: "paidDate",
  },
  {
    Header: "Subcontractor",
    accessor: "subcontractor", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "subcontractor",
  },
  {
    Header: "Amount",
    accessor: "amount", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "amount",
  },
  {
    Header: "Paid by",
    accessor: "paidBy", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "paidBy",
  },
];

const CompliancePaymentsList = (props) => {
  const { payments } = props;
  const initialItems = payments
    ? payments?.map((item) => {
        return {
          ...item,
          paidBy: item.paidBy ?? "Prime",
          paidDate: getLocalDate(item.paidDate),
          subcontractor: item.contractor.name,
          amount: (
            <>
              {outputMoney(item.amount)}{" "}
              {item.confirmed ? (
                <FontAwesomeIcon className="ml-2 mt-1 text-success" icon={["fa", "check"]} size="sm" />
              ) : (
                <FontAwesomeIcon className="ml-2 mt-1 text-primary" icon={["fa", "ban"]} size="sm" />
              )}
            </>
          ),
        };
      })
    : null;

  return (
    <div className="my-4">
      <h2>Payments</h2>
      {initialItems ? (
        initialItems?.length ? (
          <FilterTableForm tableName="compliance-payment-list" Columns={Columns} FilterListConfig={FilterListConfig} tableItems={initialItems} />
        ) : (
          <h3>No payments to show.</h3>
        )
      ) : (
        <h2>Loading payments...</h2>
      )}
    </div>
  );
};

export default CompliancePaymentsList;

import React from "react";
import { FastField } from "formik";
import InputComponent from "../../Forms/Input/InputComponent";
import _ from "lodash";
import { EMPTY_GUID } from "../../../util/typelistHelper";
import { parseErrors } from "../../../util/errorHelper";

const DocumentWrapper = ({ label, documentType, name, formikBag, onlyId, optional, subLabel, embeddedDoc }) => {
  const { setFieldValue } = formikBag;
  const value = _.get(formikBag.values, name);
  const error = _.get(formikBag.errors, name);
  const touch = _.get(formikBag.touched, name);
  const { documentId } = value ? value : {};
  const docName = onlyId === true ? `${name}.documentId` : name;

  return (
    <div docName={docName} documentType={documentType} className="document-group-wrapper row mb-3">
      {label && (
        <p className="col-12">
          <strong>{label}</strong>
        </p>
      )}
      {subLabel && (
        <div className="col-12">
          <small>{subLabel}</small>
        </div>
      )}
      <FastField wrapperClass="col-sm-11" label={label} type="file" documentType={documentType} name={docName} component={InputComponent} onlyId={onlyId} />
      {optional && (!documentId || documentId === EMPTY_GUID) && (
        <FastField
          wrapperClass="col-sm-6 col-md-8"
          type="checkbox"
          name={`${name}.documentId`}
          component={InputComponent}
          options={[[EMPTY_GUID, "This document is not available to me"]]}
          required={false}
          value={value?.documentId}
          onChange={(e) => {
            if (!e.target.checked) {
              // embeddedDoc is for when document values are immediately inside parent object,
              // and not contained in a separate "document".
              // We want to prevent clearing out the entire object *beyond document data)
              // when toggling the availability checkbox.
              // This is particularly useful for tax documents
              if (embeddedDoc) {
                setFieldValue(name, { ...value, documentId: null, type: value.type });
              } else {
                setFieldValue(name, { type: value.type });
              }
            }
          }}
        />
      )}
      {optional && [EMPTY_GUID].includes(value?.documentId) ? (
        <FastField wrapperClass="col-md-7 mb-5" type="textarea" label="Please explain why you cannot provide the requested document above" name={`${name}.description`} component={InputComponent} />
      ) : null}
      {error && touch && <div className="error text-danger pt-2 small col-12">{parseErrors(error?.type)}</div>}
    </div>
  );
};

export default DocumentWrapper;

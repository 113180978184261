import React, { useEffect, useState } from "react";
import { handleContractImport, handleActivityReportImport, getUserUploadStatus } from "../../util/api";
import SettingsForm from "./SettingsForm";
import SettingsImportHistoryList from "./SettingsImportHistoryList";

const Settings = (props) => {
  const [importHistory, setImportHistory] = useState([]);
  const [updateCount, setUpdateCount] = useState(0);

  const onSubmit = async (values, { resetForm }) => {
    switch (values.importType) {
      case "contracts":
        await handleContractImport(values?.importFile);
        break;
      case "activity":
        await handleActivityReportImport(values?.importFile);
        break;
      default:
        break;
    }
    resetForm();

    setUpdateCount(updateCount + 1);
  };

  useEffect(() => {
    const fetchData = async () => {
      const resObj = await getUserUploadStatus();
      setImportHistory(resObj?.importFiles);
    };

    fetchData();
  }, [updateCount]);

  return (
    <>
      <section id="settings" className="pb-5">
        <h2>Data Import</h2>

        <div id="settings-form-wrapper" className="bg-light p-4">
          <SettingsForm onSubmit={onSubmit} validateOnMount={true} validateOnChange={true} validateOnBlur={true} initialValues={{}} />
        </div>
      </section>
      <SettingsImportHistoryList importHistory={importHistory} />
    </>
  );
};
export default Settings;

import React, { useEffect, useState } from "react";
import { FastField } from 'formik';
import InputComponent from '../../Forms/Input/InputComponent';
import { Button, Modal } from 'react-bootstrap';

const ModalActions = (props) => {
  const { application, showModal, setShowModal, confirmations, modalChoice, setModalChoice, formikBag, setChangeCount, changeCount } = props;
  const { setFieldValue, values } = formikBag;
  return (
    <Modal name={`${confirmations[modalChoice]?.heading.toLowerCase().replace(' ','-')}-modal`} show={showModal} onHide={() => {
      setShowModal(false);
      setFieldValue(`${modalChoice}2`, null)
      setFieldValue(modalChoice, null)
    }}>
      <Modal.Header closeButton>
        <Modal.Title>{confirmations[modalChoice]?.heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mx-3">
          {confirmations[modalChoice]?.condition ?
            <FastField
              wrapperClass=""
              label={confirmations[modalChoice]?.message}
              type="checkbox"
              name={modalChoice}
              options={[[true, "Yes"]]}
              placeholder=""
              component={InputComponent}
            />
            : confirmations[modalChoice]?.message
          }
          {confirmations[modalChoice]?.secondCondition ?
            <FastField
              wrapperClass=""
              label={confirmations[modalChoice]?.secondConditionMessage}
              type="checkbox"
              name={`${modalChoice}2`}
              options={[[true, "Yes"]]}
              placeholder=""
              component={InputComponent}
            /> : null}
        </div>
      </Modal.Body>
      {(!confirmations[modalChoice]?.condition || formikBag?.values[modalChoice]) && (!confirmations[modalChoice]?.secondCondition || formikBag?.values[`${modalChoice}2`]) ?
        <Modal.Footer className="d-block">
          <div className="row">
            <div className="col-lg-6">
              <Button className="btn-block btn-sm" variant="secondary" onClick={() => {
                setShowModal(false)
                setFieldValue(`${modalChoice}2`, null)
                setFieldValue(modalChoice, null)
              }}>
                Cancel
              </Button>
            </div>
            <div className="col-lg-6">
              <Button className="btn-block btn-sm" variant="primary" onClick={async () => {
                const confirmation = confirmations[modalChoice];
                let resObj = undefined;

                if (!confirmation) {
                  return;
                }

                if (Array.isArray(confirmation.includedFormValues)) {
                  const includedFormValues = {};

                  confirmation.includedFormValues.forEach((fv) => {
                    includedFormValues[fv] = values[fv];
                  });

                  resObj = await confirmations[modalChoice]?.action(application?.id, includedFormValues);
                } else {
                  resObj = await confirmations[modalChoice]?.action(application?.id);
                }

                if (resObj.status === 200) {
                  setChangeCount(changeCount + 1);
                  setShowModal(false);
                  setFieldValue(`${modalChoice}2`, null)
                  setFieldValue(modalChoice, null)
                }
              }
              }>
                Confirm
              </Button>
            </div>
          </div>
        </Modal.Footer>
        : null}
    </Modal>
  )
}
export default ModalActions;

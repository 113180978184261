import React from "react";
import { useParams } from "react-router-dom";
import { createTabNavLinks, isPrime, plansExpected, procurementCanHavePayments } from "../../util/helpers";
import { loadUserRole, roles } from "../../util/userHelper";

const ProcurementNav = (props) => {
  let { procurementId } = useParams();
  const { procurement } = props;
  const { contract } = procurement;
  const { awardedPlan } = procurement;

  // const isPrime = true
  // debugger;
  let items =
    isPrime(awardedPlan) || [roles.AgencyUser, roles.AgencyAdmin].includes(loadUserRole())
      ? [
          [`/procurements/${procurementId}/`, "Contract", "", "", true],
          [`/procurements/${procurementId}/logs/annotations-logs`, "Logs", "", "", false],
          // [`/procurements/${procurementId}/change-log`, "Change Log"],
          [`/procurements/${procurementId}/correspondence`, "Correspondence"],
        ]
      : [];

  if (procurementCanHavePayments(procurement) && contract?.id) {
    items.push([`/procurements/${procurementId}/payments`, "Payments"]);
    items.push([`/procurements/${procurementId}/compliance-reports`, "Compliance Reports"]);
  }

  // console.log(loadUserRole(), roles.AgencyAdmin);

  if ((isPrime(awardedPlan) || [roles.AgencyUser, roles.AgencyAdmin].includes(loadUserRole())) && plansExpected(procurement)) {
    items.splice(1, 0, [`/procurements/${procurementId}/plans-waivers`, "Plans & Waivers"]);
  }

  return (
    <div className="contract-navigation mb-5">
      <nav id="contract-nav" className="nav nav-tabs no-bord">
        {createTabNavLinks(items)}
      </nav>
    </div>
  );
};

export default ProcurementNav;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCertifiedBusinesses, loadTags, handleFilteredExport, batchGetAllRecords } from "../../components/../util/api";
import { withFormikFormComponent } from "../Forms/FormikForm";
import { outputAddresses, formatPhoneNumber, transformToUrl, tableSortByValue } from "../../util/helpers";
import "./BusinessSearch.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { businessTypes } from "../../util/typelistHelper";
import categories from "../../util/sourceData/categories";
import regionsValues from "../../util/sourceData/regions";
import counties from "../../util/sourceData/counties";
import naicsCodes from "../../util/sourceData/naicsCodes";
import { shuffle } from "lodash";
import FilterTableForm from "../Forms/Input/FilterTableForm";

const ENVIRONMENT = process.env.NODE_ENV;
// const countyRegionMap = countyRegionMapFlat()

const BusinessSearchInternal = (props) => {
  const [businesses, setBusinesses] = useState([]);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userInfo } = props;

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      await batchGetAllRecords(
        1500,
        async (page, pageSize) => {
          return getCertifiedBusinesses(null, page, pageSize);
        },
        (results) => {
          return results.businesses;
        },
        (response) => {
          setBusinesses(response);
          setLoading(false);
        },
        (response) => {
          return shuffle(response);
        }
      );

      const businessTagsRes = await loadTags();
      setTags(businessTagsRes.data.tags);
      setLoading(false);
    }
    fetchData();
  }, []);

  // Setup Filter Options Data
  // let categoriesArr = categories.map(cat => {
  //   return [cat.code, cat.label];
  // })
  // categoriesArr.unshift(['', 'Choose Category']);

  // let regionsArr = regions.map(reg => {
  //   return [reg.code, reg.label];
  // })
  // regionsArr.unshift(['', 'Choose Region']);

  // let tagsArr = tags.map(tag => {
  //   return [tag.value, tag.value];
  // })
  // tagsArr.unshift(['', 'Choose Keyword']);

  // let naicsCodesArr = naicsCodes.map(naicsCode => {
  //   return [naicsCode.code, naicsCode.code];
  // })
  // naicsCodesArr.unshift(['', 'Choose NAICS Code']);

  const sortByName = (rowA, rowB, name, other) => {
    return tableSortByValue(rowA, rowB, "legalName");
  };

  const FilterListConfig = {
    legalName: {},
    owners: {},
    categories: {
      type: "typeahead",
      options: categories,
    },
    serviceTypes: {
      type: "typeahead",
      options: businessTypes.map((businessType) => {
        return { label: businessType[1], id: businessType[0] };
      }),
      isExact: true,
    },
    regions: {
      type: "typeahead",
      options: regionsValues.map((item) => ({ label: item.label, id: item.label })),
    },
    naicsCodes: {
      type: "typeahead",
      options: naicsCodes.map((code) => ({ id: `${code.id}`, label: `${code.id}` })),
      Header: "NAICS",
    },
    tags: {
      // type: 'typeahead',
      // options: tags.map(code => ({ id: `${code.value}`, label: `${code.value}` }))
    },
    countyLocatedIn: {
      type: "typeahead",
      options: counties.map((code) => ({ id: code[0], label: code[1] })),
    },
  };

  const Columns = [
    {
      Header: "Business Name",
      accessor: "businessNameLink", // accessor is the "key" in the data
      sortType: sortByName,
      // filterCode: "legalName"
    },
    {
      Header: "Business Name",
      accessor: "legalName", // accessor is the "key" in the data
      disableSortBy: true,
      isVisible: false,
      filterCode: "legalName",
    },
    {
      Header: "Owners",
      accessor: "owners", // accessor is the "key" in the data
      disableSortBy: true,
      isVisible: false,
      filterCode: "owners",
    },
    {
      Header: "NAICS",
      accessor: "naicsCodes", // accessor is the "key" in the data
      disableSortBy: true,
      filterCode: "naicsCodes",
      isVisible: false,
    },
    {
      Header: "Business Description",
      accessor: "specificFunction",
      defaultCanSort: true,
      sortType: "basic",
      filterCode: "specificFunction",
    },
    {
      Header: "Category",
      accessor: "categories",
      defaultCanSort: true,
      sortType: "basic",
      filterCode: "categories",
    },
    {
      Header: "Address",
      accessor: "address",
      defaultCanSort: false,
      disableSortBy: true,
      sortType: "basic",
      filterCode: "address",
    },
    {
      Header: "Keywords",
      accessor: "tags", // accessor is the "key" in the data
      disableSortBy: true,
      filterCode: "tags",
      isVisible: false,
    },
    {
      Header: "Classifications",
      accessor: "serviceTypes", // accessor is the "key" in the data
      disableSortBy: true,
      filterCode: "serviceTypes",
      isVisible: false,
    },
    {
      Header: "Contact",
      accessor: "contact",
      defaultCanSort: false,
      disableSortBy: true,
      sortType: "basic",
      filterCode: "contact",
    },
    {
      Header: "Home Region",
      accessor: "regionLocatedIn",
      defaultCanSort: true,
      sortType: "basic",
    },
    {
      Header: "Home Region",
      accessor: "regions",
      defaultCanSort: true,
      sortType: "basic",
      filterCode: "regions",
      isVisible: false,
    },
    {
      Header: "Home County",
      accessor: "countyLocatedIn",
      defaultCanSort: true,
      sortType: "basic",
      filterCode: "countyLocatedIn",
      isVisible: false,
    },
    {
      Header: "NYS Centralized Contract Title",
      accessor: "centralizedContracts",
      defaultCanSort: true,
      sortType: "basic",
    },
  ];

  //  Should be noted this this is outputting the enum, and not the commonly-used label
  // this is because this column is hidden.
  // If we want to show this column, we should duplicate and create another with the labels to surface
  const mapServiceTypesToLabel = (serviceType) => {
    const businessType = businessTypes.find((businessType) => businessType[0] === serviceType);
    return businessType ? businessType[0] : "";
  };

  const initialItems = businesses?.map((item) => {
    const websiteUrl = item?.homepageUrl && !item?.homepageUrl.includes("http") ? `http://${item.homepageUrl}` : item?.homepageUrl;
    return {
      ...item,
      businessNameLink: (
        <a href={`/business-search/${item.id}`} target="_blank">
          {item.legalName}
        </a>
      ),
      owners: item.owners.map((owner) => owner.name),
      address: outputAddresses(item.addresses, "", "font-weight-bold"),
      // phoneNumber: item?.phoneNumber,
      // emailAddress: item?.emailAddress,
      countyLocatedIn: item?.countyLocatedIn,
      regions: item?.regionLocatedIn ? item?.regionLocatedIn : "N/A",
      regionLocatedIn: (
        <div>
          {" "}
          <span className="text-nowrap text-break"> {item?.regionLocatedIn ? item?.regionLocatedIn : "N/A"}</span>{" "}
        </div>
      ),
      categories: item?.categories?.length ? item.categories.join(", ") : "",
      specificFunction: item?.description,
      nyc_contract_title: "N/A",
      contact: (
        <div>
          {item.phoneNumber && (
            <span className="text-nowrap text-break">
              <FontAwesomeIcon icon={["fas", "phone"]} />
              &nbsp;{formatPhoneNumber(item.phoneNumber)}
            </span>
          )}
          <br />
          {item.emailAddress && (
            <span className="text-nowrap text-break">
              <FontAwesomeIcon icon={["fas", "envelope"]} />
              &nbsp;{item.emailAddress}
            </span>
          )}
          <br />
          {item.homepageUrl && (
            <span className="text-nowrap text-break">
              <FontAwesomeIcon icon={["fas", "link"]} />
              &nbsp;
              <a href={transformToUrl(item.homepageUrl)} target="_blank">
                {transformToUrl(item.homepageUrl)}
              </a>
            </span>
          )}
        </div>
      ),
      naicsCodes: item?.naicsCodes?.length ? item.naicsCodes.join(", ") : "",
      tags: item?.tags?.length ? item.tags.join(", ") : "",
      serviceTypes: item?.serviceTypes?.map((serviceType) => mapServiceTypesToLabel(serviceType)),
      centralizedContracts: item?.centralizedContracts?.length ? item.centralizedContracts.join(", ") : "N/A",
    };
  });

  return (
    <section id="businesses">
      <div className="py-4">
        <p className="py-3 h4">
          <Link to="/">
            <FontAwesomeIcon icon={["fas", "angle-left"]} className="mr-2" />
            Back to portal
          </Link>
        </p>

        <h2 className="py-3">Service-Disabled Veteran-Owned Business Directory</h2>

        {/* <DebugModal formikBag={formikBag} name="Formik Bag" /> */}
        {loading && <h2 className="text-center loader loader-pulse py-5 mt-5">Loading SDVOB Directory...</h2>}
        {!loading && initialItems && initialItems.length > 0 ? (
          <FilterTableForm
            tableName="business-search-list"
            Columns={Columns}
            FilterListConfig={FilterListConfig}
            tableItems={initialItems}
            hasDownload={true}
            downloadLabel={"Download Search Results"}
            downloadCall={handleFilteredExport}
          />
        ) : !loading ? (
          <h2 className="text-center py-5 mt-5">There are no matching businesses in directory</h2>
        ) : null}

        {/* <div className="py-3 text-right">
          <a href={`${apiBase}/businesses/export`} download><FontAwesomeIcon icon={faDownload} size="xs" /> Download Directory</a>
        </div> */}
      </div>
    </section>
  );
};
const BusinessSearch = withFormikFormComponent(BusinessSearchInternal);
export default BusinessSearch;

import React from "react";
import { outputMoney, getLocalDate, outputPercentage, transformToUrl } from "../../../util/helpers";
import { DocumentsSection, DocumentLink } from "../../Forms/DocumentHelper";

const GoalPlanDisplayData = (props) => {
  let { report, reportId, isVersion } = props;
  console.log(report?.projectedSDVOBSpendingAmount, report?.budgetMinusExclusions);

  return (
    <>
      {report?.id ? (
        <>
          <div className="mb-4">
            {/* <div className="row mb-2">
              <div className="col-5">Total Spending Amount</div>
              <div className="col-7">{outputMoney(report?.totalSpendingAmount)}</div>
            </div> */}

            <div className="row mb-2">
              <div className="col-5">Total Awards</div>
              <div className="col-7">{report?.totalAwards}</div>
            </div>

            <div className="row mb-2">
              <div className="col-5">Annual Budget</div>
              <div className="col-7">{outputMoney(report?.annualBudget)}</div>
            </div>

            <div className="row mb-2">
              <div className="col-5">Annual Budget minus Exemptions and Exclusions</div>
              <div className="col-7">{outputMoney(report?.budgetMinusExclusions)}</div>
            </div>

            <div className="row mb-2">
              <div className="col-5">Projected SDVOB Spending Amount</div>
              <div className="col-7">{outputMoney(report?.projectedSDVOBSpendingAmount)}</div>
            </div>

            <div className="row mb-2">
              <div className="col-5">Goal</div>
              <div className="col-7">{outputPercentage(report?.goal)}</div>
            </div>

            <div className="row mb-2">
              <div className="col-5">SDVOB Set-asides</div>
              <div className="col-7">{report?.sdvobSetAsides}</div>
            </div>

            <div className="row mb-2">
              <div className="col-5">Discretionary</div>
              <div className="col-7">{report?.discretionaries}</div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 mb-4">
              <h5>Documents</h5>
              <hr />
              {report?.specificDocuments?.map((document, i) => {
                const { type } = document;
                return report?.sdvobWebsiteContentUrl && type === "SDVOBWebsiteContentPlan" ? (
                  <div className="row mb-2">
                    <div className="col-5">SDVOB Content on Agency/Authority's Website</div>
                    <div className="col-7">
                      <a href={transformToUrl(report?.sdvobWebsiteContentUrl)} target="blank">
                        {transformToUrl(report?.sdvobWebsiteContentUrl)}
                      </a>
                    </div>
                  </div>
                ) : (
                  <DocumentLink key={i} document={document}></DocumentLink>
                );
              })}
              {/* <DocumentsSection documents={report?.specificDocuments} /> */}
            </div>
            <div className="col-12">
              <h5>Miscellaneous Documents</h5>
              <hr />
              <DocumentsSection documents={report?.documents} />
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default GoalPlanDisplayData;

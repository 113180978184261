import React from "react";

const BasicInfo = (props) => {
  const { contract } = props;

  return (
    <div>
      {/* <div className="row">
        <div className="col-sm-5 font-weight-bold">Contract name</div>
        <div className="col-sm-7">{contract?.name}</div>
      </div> */}

      <div className="row">
        <div className="col-sm-5 font-weight-bold">SFS Contract #</div>
        <div className="col-sm-7">{contract.contractSource === "AgencyUpload" ? "Agency Uploaded" : contract?.contractNumber}</div>
      </div>

      <div className="row">
        <div className="col-sm-5 font-weight-bold">Agency Contract #</div>
        <div className="col-sm-7">{contract?.agencyContractNumber}</div>
      </div>
    </div>
  );
};

export default BasicInfo;

import React from "react";
import { schemas } from "../../../../util/errorHelper";
import _ from "lodash";
import DebugModal from "../../../Debug/DebugModal";
import { FastField, Form } from "formik";
import { withFormikFormComponent } from "../../../Forms/FormikForm";
import InputComponent from "../../../Forms/Input/InputComponent";
import ReactiveButton from "../../../Forms/Input/ReactiveButton";
import { loadUserRole, roles } from "../../../../util/userHelper";

const ReportSearchFormInternal = (props) => {
  const { reportType, formikBag } = props;
  const { isSubmitting } = formikBag;

  const statusOptions = () => {
    let statuses = [
      // [null, "Choose Status"],
      ["Created", "Created"],
      ["Submitted", "Submitted"],
      ["Deficient", "Deficient"],
    ];

    switch (loadUserRole()) {
      case roles.DivisionUser:
        return statuses;
      case roles.DivisionAdmin:
        statuses.push(["Approved", "Approved"]);
        return statuses;
      default:
        console.log("user role error");
        break;
    }
  };

  return (
    <section id={`${reportType}-search-form`} className="mb-3">
      <DebugModal formikBag={formikBag} name="Formik Bag" />
      <div id={`${reportType}-search-form-wrapper`} className="bg-light p-4">
        <h3>{_.startCase(reportType)} Search</h3>
        <hr />
        <Form>
          <div className="field-group row">
            <FastField wrapperClass="col-md-6" label="Fiscal Year" type="number" name={"fiscalYear"} component={InputComponent} placeholder="" />
            {reportType === "activity-report" ? (
              <FastField
                wrapperClass="col-md-6"
                label="Fiscal Quarter"
                type="select"
                name={"fiscalQuarter"}
                component={InputComponent}
                options={[
                  // [null, "Choose Quarter"],
                  [1, 1],
                  [2, 2],
                  [3, 3],
                  [4, 4],
                ]}
              />
            ) : null}

            <FastField wrapperClass="col-md-6" label="Status" type="select" name={"status"} component={InputComponent} options={statusOptions()} />
          </div>

          <div className="mt-4">
            <ReactiveButton type="submit" className="btn-md btn-secondary px-5" label="Submit" processing={isSubmitting} />
          </div>
        </Form>
      </div>
    </section>
  );
};

const ReportSearchForm = (props) => {
  const { reportType } = props;

  let formSchema;
  switch (reportType) {
    case "activity-report":
      formSchema = schemas.divisionActivityReportSearchSchema;
      break;
    default:
      formSchema = schemas.divisionReportSearchSchema;
      break;
  }

  const Component = withFormikFormComponent(ReportSearchFormInternal, formSchema);
  return <Component {...props} />;
};
export default ReportSearchForm;

import React, { useState, useEffect } from "react";
import { Link, useParams, Route, useHistory } from "react-router-dom";
import { getPayments, handlePaymentSubmit, handlePaymentUpdate, handlePaymentDelete } from "../../../util/api";
import PaymentsList from "./PaymentsList";
import PaymentsNav from "./PaymentsNav";
import ComplianceReportsList from "../ComplianceReports/ComplianceReportsList";
import { componentsFromRoutes, getLocalDate } from "../../../util/helpers";

const Payments = (props) => {
  const { awardedPlan, procurement, setUpdateCount, updateCount } = props;
  const { procurementId } = useParams();
  const { contract } = procurement;
  const { paymentId } = useParams();
  const [payment, setPayment] = useState({});
  const [payments, setPayments] = useState(null);
  const [paymentsUpdateCount, setPaymentsUpdateCount] = useState(0);
  const [initialPaymentValues, setInitialPaymentValues] = useState({});
  const history = useHistory();

  useEffect(() => {
    // console.log(paymentsUpdateCount);
    const fetchData = async () => {
      // console.log('running it again')
      const resObj = await getPayments(contract.id);
      setPayments(resObj.data.payments);
    };
    if (contract?.id) {
      fetchData();
    }
  }, [paymentsUpdateCount]);

  const onSubmit = async (values, { resetForm }) => {
    let dateString = values.paidDate.toISOString();
    if (dateString.toString().substring(0, 2) == "00") {
      dateString = "20" + dateString.substring(2);
    }
    values = { ...values, paymentId: values.id, contractId: contract.id, paidDate: dateString };
    const resObj = values?.id ? await handlePaymentUpdate(values) : await handlePaymentSubmit(values);
    // console.log(resObj);
    setPaymentsUpdateCount(paymentsUpdateCount + 1);
    setInitialPaymentValues({});
    resetForm();
    setUpdateCount(updateCount + 1);
    // setPayments(resObj?.data?.payments)
    history.push(`/procurements/${procurementId}/payments`);
  };

  const handleDelete = async (paymentId) => {
    await handlePaymentDelete(paymentId);
    setPaymentsUpdateCount(paymentsUpdateCount + 1);
  };

  useEffect(() => {
    const setupPayment = () => {
      const newPayment = payments?.length ? payments.find((payment) => payment.id === paymentId) : null;
      const paymentValues = {
        ...newPayment,
        contractorId: newPayment?.contractor?.id,
        paidDate: newPayment ? new Date(newPayment?.paidDate) : null,
        // paidDate: newPayment ? getLocalDate(newPayment?.paidDate) : null,
      };
      setPayment(newPayment);
      setInitialPaymentValues(paymentValues);
    };
    if (payments?.length) {
      setupPayment();
    }
  }, [paymentId, payments]);

  const PaymentsRoutes = {
    routes: [
      {
        path: "/procurements/:procurementId/payments",
        title: "Payments",
        exact: false,
        component: PaymentsList,
        props: {
          onSubmit: onSubmit,
          handleDelete: handleDelete,
          payments: payments,
          paymentId: paymentId,
          initialPaymentValues: initialPaymentValues,
          setInitialPaymentValues: setInitialPaymentValues,
        },
      },
      {
        path: "/procurements/:procurementId/compliance-reports",
        title: "Compliance Reports",
        exact: true,
        component: ComplianceReportsList,
        props: {},
      },
    ],
    globalProps: {
      procurement: procurement,
      awardedPlan: awardedPlan,
      procurementId: procurementId,
    },
  };

  return (
    <section id="payments">
      {/* <PaymentsNav procurementId={procurementId} /> */}

      {componentsFromRoutes(PaymentsRoutes)}

      {!awardedPlan ? <Link to={`/procurements/${procurementId}/plans-waivers/submit`}>Submit plan to add subcontractors to your contract</Link> : null}
    </section>
  );
};

export default Payments;

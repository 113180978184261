import React from "react";
import { roles, loadUserRole, getUserId } from "../../../util/userHelper";

const AssignDropdown = (props) => {
  // console.log(props);
  const { reportId, reviewerId, divisionUsers, onChange } = props;
  return (
    <div key={reportId}>
      <select type="select" value={reviewerId ? reviewerId : ""} className="w-100" name={"divisionUserAssignment"} onChange={(e) => onChange(e, reportId)}>
        {divisionUsers?.length > 0
          ? divisionUsers?.map((user) => {
              if (roles.DivisionAdmin === loadUserRole() || user?.id === "" || user?.id === getUserId()) {
                return (
                  <option key={user?.id} value={user?.id}>
                    {user?.label}
                  </option>
                );
              } else {
              }
            })
          : null}
      </select>
    </div>
  );
};

export default AssignDropdown;

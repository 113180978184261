import React from "react";

export const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";

export const ownershipTypes = {
  SoleProprietorship: {
    label: "Sole Proprietorship",
    documentTypes: ["FranchiseTrustAgreementAmendments"],
    // subLabel: "Please confirm that you are not an LLC",
  },
  Corporation: {
    label: "Corporation",
    documentTypes: ["FranchiseTrustAgreementAmendments", "ArticlesOfIncorporation", "CorporateMeetingMinutes", "CorporateBylawsAmendments", "StockTransferLedger"],
    // "A legal entity separate and distinct from the individual(s) who compose the business. A Certificate of Incorporation must be filed (signed by at least one incorporator) with the Department of State.",
  },
  LLC: {
    label: "Limited Liability Company",
    documentTypes: ["FranchiseTrustAgreementAmendments", "ArticlesOfOrganization", "OperatingAgreementAmendments"],
    // "A business entity that limits personal liability for its members. Requires the filing of Articles of Organization and a Certificate of Publication.",
  },
  Partnership: {
    label: "Partnership",
    documentTypes: ["FranchiseTrustAgreementAmendments", "PartnershipAgreementAmendments"],
    // "A business where personal liability is joint and individual for the general partners responsible for the obligations of the partnership. Requires the filing of a Certificate of Assumed Name following an agreement of the partners.",
  },
  LLP: {
    label: "Limited Liability Partnership",
    documentTypes: ["FranchiseTrustAgreementAmendments", "PartnershipAgreementAmendments", "LimitedLiabilityPartnershipRegistration"],
    // "Personal liability is joint and individual for the general partner(s) who are responsible for the obligations of the partnership; limited partner(s) are liable to the extent of their capital contributions to the partnership. Requires filing of Certificate of Limited Partnership and Certificate of Publication.",
  },
};

export const reportTypes = {
  "activity-report": "activityReport",
  "goal-plan": "goalPlan",
  "narrative-statement": "narrativeStatement",
};

export const sizeCategories = [
  ["NotDisclosed", "Not Disclosed"],
  ["LessThan100000", "Less than $100,000"],
  ["From100000To499999", "$100,000 - $499,999"],
  ["From500000To999999", "$500,000 - $999,999"],
  ["From1000000To4999999", "$1,000,000 - $4,999,999"],
  ["Over5Million", "More than $5,000,000"],
];

export const globalApplicationDocumentTypes = [
  // "VeteransAdministrationServiceConnectedDisabilityRating",
  // "FederalIdOrSocialSecurityNumberVerification",
  "BusinessLicense",
  "FederalIdOrSocialSecurityNumberVerification",
];

export const NGB22 = "NGB22";

export const DD214 = "DD214";

export const VetsFirstCertification = "VetsFirstCertification";

export const VetsFirstAltDocs = ["FederalIdOrSocialSecurityNumberVerification"];

export const businessTypes = [
  ["Commodities", "Commodities"],
  ["Construction", "Construction"],
  ["ConstructionProfessionalServices", "Construction Professional Services"],
  ["ConsultingAndOtherServices", "Consulting & Other Services"],
];

export const exclusionTypes = [
  ["Eligible", "Yes - Eligible"],
  ["ExemptExcluded", "No - Exempt/Exclude"],
];
export const contractTerms = [
  ["Standard", "Standard"],
  ["Term", "Term"],
];
export const contractCategories = [
  ["Discretionary", "Discretionary"],
  ["General", "General"],
  ["SetAside", "Set-Aside"],
];

// [Type, "Description", "The third parameter defines the condition that causes the document to display. This can be be used to reset the documents in the array if the flag is switched."]
export const documentTypes = [
  ["BusinessLicense", "Business License, if applicable", "Other"],
  ["Resume", "Resume which communicates the Disabled Veteran's experience, education, knowledge, and qualifications.", "ownershipType"],
  ["FranchiseTrustAgreementAmendments", "Franchise, Trust Agreement, and Amendments, as applicable", "ownershipType"],
  ["ArticlesOfOrganization", "Articles of Organization", "ownershipType"],
  ["OperatingAgreementAmendments", "Operating Agreement and amendments", "ownershipType"],
  ["ArticlesOfIncorporation", "Articles of Incorporation", "ownershipType"],
  ["CorporateMeetingMinutes", "Corporate meeting minutes listing current elected corporate officers and directors, or the most recent Statement of Information", "ownershipType"],
  ["CorporateBylawsAmendments", "Corporate bylaws and amendments", "ownershipType"],
  ["StockTransferLedger", "Stock Transfer Ledger and Stock Certificates for original applicants or changes in business structure", "ownershipType"],
  ["PartnershipAgreementAmendments", "Partnership agreement and amendments", "ownershipType"],
  ["LimitedLiabilityPartnershipRegistration", "Limited Liability Partnership Registration", "ownershipType"],

  ["VetsFirstCertification", "Proof of U.S. Department of Veterans Affairs Vets First Verification (CVE) program Certification", "Other"],
  [
    "FederalIdOrSocialSecurityNumberVerification",
    "Official Internal Revenue Service (IRS) documentation verifying the firm's Federal Employer Identification Number (FEIN) or Social Security Number (SSN) listed on the application",
    "Other",
  ],
  // ["VeteransAdministrationServiceConnectedDisabilityRating", "U.S. Veterans Administration documentation of service-connected disability rating which must be dated within one year of the date DSDVBD receives your SDVOB Certification application and must demonstrate a service-connected disability rating of at least 10%", "Other"],

  ["DD214", "DD214 (Only one of DD214 or NGB22 is required)", "Other"],
  ["NGB22", "NGB22 (Only one of DD214 or NGB22 is required)", "Other"],
  ["DisabilityLetter", "VA documentation dated within one year demonstrating a service-connected disability rating of at least 10%", "Other"],
  ["MiscellaneousApplication", "Miscellaneous Change Request Document", "Other"],
  // Goal plan documents //
  [
    "PlansEffortsAndWaivers",
    "Utilization Plans, Good Faith Efforts and Waivers",
    "Other",
    <>
      <p>Specifically address Agency/Authority's practices, policies, and procedures with respect to utilization plans, good faith efforts and waivers. This should include:</p>
      <ul>
        <li>Webpage link to Agency/Authority utilization plans</li>

        <li>Collection & posting of utilization plans </li>

        <li>Good faith efforts </li>

        <li>Approval/Denial of waivers </li>

        <li>Reporting of waivers to DSDVBD quarterly </li>

        <li>Any efforts to reach out to the DSDVBD for guidance on utilization plans, waivers and good faith efforts </li>
      </ul>
    </>,
  ],
  ["ExemptionsAndExclusionsMethodology", "Exemptions And Exclusions Methodology", "Other", <p>Detailed description of Agency/Authority's methodology for determining exemptions and exclusions</p>],
  [
    "Overview",
    "Agency Overview",
    "Other",
    <ul>
      <li>Mission & Goals</li>
      <li>Scope of Agency/Authority operations</li>
    </ul>,
  ],
  [
    "ProcurementStrategy",
    "Procurement Strategy",
    "Other",
    <ul>
      <li>
        Reflection of Previous Years' SDVOB Utilization
        <ul>
          <li>Compare your Agency/Authority's SDVOB utilization during the previous 4 quarters reported to the projected SDVOB use provided in your Master Goal Plan from last year </li>

          <li>Is SDVOB utilization the same, better, or worse than projected? Provide a brief explanation why </li>

          <li>Describe what is working/not working for your Agency/Authority </li>

          <li>Please refer to your previous year's Master Goal Plan and/or Narrative Statement to determine strengths & weaknesses</li>
        </ul>
      </li>

      <li>
        Strategic Objectives Regarding Utilization of SDVOBs
        <ul>
          <li>
            Specifically address contracting categories including commodities, construction, construction consultants, services/consultants, or other categories approved by the Division of
            Service-Disabled Veterans' Business Development (DSDVBD){" "}
          </li>
        </ul>
      </li>

      <li>
        Plans to Achieve Strategic Objectives
        <ul>
          <li>Practices, procedures, strategies or actions that may be implemented by the Agency/Authority to ensure contract participation by NYS Certified SDVOBs </li>
          <li>
            Plans to educate & train procurement personnel about SDVOB program compliance and requirements with Article 3 of the Veterans’ Services Law. Please include specific examples such as:
            <ul>
              <li>
                Inviting the DSDVBD to give training presentations to procurement personnel
                <ul>
                  <li>The DSDVBD will reach out to Agency/Authority to schedule presentations, if requested </li>
                </ul>
              </li>
              <li>Holding internal training events focused on the SDVOB program </li>
              <li>Requiring attendance in the SDVOB presentation at the annual OGS GovBuy</li>
              <li>Incorporating SDVOB training for all new procurement staff </li>
              <li>Requiring attendance at the annual Veterans in Economic Transition conference, VETCON </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>Explain any substantial variance in the numbers from your Agency/Authority's previous Master Goal Plan</li>
    </ul>,
  ],
  ["AnticipatedChallengesAndStrategies", "Anticipated Challenges and Strategies", "Other", <p>Anticipated Challenges of Meeting SDVOB Goal & Strategies to Address Each Challenge</p>],
  [
    "SDVOBWebsiteContentPlan",
    "",
    "Other",
    <p>
      Detailed plan to update one or more of the Agency/Authority’s web pages to include information about the SDVOB program and New York State’s 6% SDVOB participation goal. This plan should include
      an intended web page/location for the information and a summary of the information you plan to put on the web page
    </p>,
  ],
  [
    "BoilerplateLanguage",
    "Boilerplate Language",
    "Other",
    <p>
      Any standard language sent out with agency Requests for Proposals (RFPs) See updated sample SDVOB Contract Language available at{" "}
      <a href="https://ogs.ny.gov/Veterans/default.asp" target="_blank">
        ogs.ny.gov/Veterans
      </a>
    </p>,
  ],
  [
    "OperationsAndOrganizationalChart",
    "Operations and Organizational Chart",
    "Other",
    <p>Offices and/or personnel involved in reaching agency SDVOB contracting goals and their roles and responsibilities in the procurement process</p>,
  ],
  [
    "OutreachEfforts",
    "Outreach Efforts",
    "Other",
    <>
      <p>Summarize efforts to engage SDVOBs in procurement opportunities</p>
      <ul>
        <li>Specifically address how your outreach efforts will adapt to any major events, such as COVID-19, which may affect your activities.</li>
      </ul>
    </>,
  ],
  ["StandardizedForms", "Standardized Forms", "Other", "Any standardized forms used in the Agency's procurement efforts"],
];

export const countyRegionMap = {
  "Western New York": ["Allegany", "Cattaraugus", "Chautauqua", "Erie", "Niagara"],
  "Finger Lakes": ["Genesee", "Livingston", "Monroe", "Ontario", "Orleans", "Seneca", "Wayne", "Wyoming", "Yates"],
  "Southern Tier": ["Broome", "Chemung", "Chenango", "Delaware", "Schuyler", "Steuben", "Tioga", "Tompkins"],
  "Central New York": ["Cayuga", "Cortand", "Madison", "Onondaga", "Oswego"],
  "Mohawk Valley": ["Fulton", "Herkimer", "Montgomery", "Oneida", "Otsego", "Schoharie"],
  "North Country": ["Clinton", "Essex", "Franklin", "Hamilton", "Jefferson", "Lewis", "St.Lawrence"],
  "Capital Region": ["Albany", "Columbia", "Greene", "Saratoga", "Schenectady", "Rensselaer", "Warren", "Washington"],
  "Mid- Hudson": ["Dutchess", "Orange", "Putnam", "Rockland", "Sullivan", "Uister", "Westchester"],
  "New York City": ["Bronx", "Kings", "New York", "Richmond", "Queens"],
  "Long Island": ["Nassau", "Suffolk"],
};

export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export const correspondenceCategories = [
  ["Goal Progress", "Goal Progress"],
  ["Utilization Plans &amp; Waivers", "Utilization Plans &amp; Waivers"],
  ["Payments", "Payments"],
  ["Other", "Other"],
];

export const goalPlanDocumentTypes = [
  "Overview",
  "ProcurementStrategy",
  "PlansEffortsAndWaivers",
  "ExemptionsAndExclusionsMethodology",
  "AnticipatedChallengesAndStrategies",
  "SDVOBWebsiteContentPlan",
  "BoilerplateLanguage",
  "OperationsAndOrganizationalChart",
  "OutreachEfforts",
  "StandardizedForms",
];

export const countyRegionMapFlat = () => {
  const countRegionMap = {};
  Object.keys(countyRegionMap).forEach((key) => {
    countyRegionMap[key].forEach((county) => {
      countRegionMap[county] = key;
    });
  });
  return countRegionMap;
};

export const getOwnershipTypeDocs = (ownershipTypeKey) => {
  const checkedDocumentTypes = ownershipTypes[ownershipTypeKey]?.documentTypes || [];
  const documentTypes1 = documentTypes.filter((documentType) => documentType[2] === "ownershipType" || !checkedDocumentTypes.includes(documentType[0])).map((documentType) => documentType[0]);
  return documentTypes1;
};

export const YES_NO_RADIO = [
  [true, "Yes"],
  [false, "No"],
];

export const annotationsLogsTypes = {
  ContractorAdded: "Contractor Added",
  PaymentAdded: "Payment Added",
  PaymentDeleted: "Payment Deleted",
  PaymentConfirmed: "Payment Confirmed",
  ComplianceReportSubmitted: "Compliance Report Submitted",
  ComplianceReportAccepted: "Compliance Report Accepted",
  ComplianceReportDeficient: "Compliance Report Deficient",
  UtilizationPlanSubmitted: "Utilization Plan Submitted",
  UtilizationPlanDeficient: "Utilization Plan Deficient",
  UtilizationPlanApproved: "Utilization Plan Approved",
  UtilizationPlanDenied: "Utilization Plan Denied",
  CorrespondenceCreated: "Correspondence Created",
  CorrespondenceClosed: "Correspondence Closed",
  ComplianceReportCreated: "Compliance Report Created",
  ComplianceReportRequested: "Compliance Report Requested",
  UtilizationPlanAwarded: "Utilization Plan Awarded",
};

export const procurementStatuses = ["Created", "Accepting Submissions", "Evaluation", "Awarded", "Contract Assigned", "Reporting", "Complete"];

export const orderDirections = Object.freeze({
  none: "None",
  ascending: "Asc",
  descending: "Desc",
});

export const aggregates = Object.freeze({
  none: "None",
  max: "Max",
  min: "Min",
  sum: "Sum",
  average: "Average",
});

export const reportFieldTemplateTypes = Object.freeze({
  none: "None",
  url: "Url",
  document: "Document",
  multiLine: "MultiLine",
  address: "Address",
});

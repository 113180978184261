import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import AgencyUserList from "./AgencyUserList";
import AgencyUserForm from "./AgencyUserForm";

import { getAgencyUsers, getAgencies } from "../../../util/api";

const AgencyUserManagement = () => {
  const { agencyUserId } = useParams();

  const [users, setUsers] = useState([]);
  const [agencies, setAgencies] = useState([]);

  useEffect(() => {
    const getAndSetUsers = async () => await getAgencyUsers().then((data) => setUsers(data.users));
    const getAndSetAgencies = async () => await getAgencies().then((data) => setAgencies(data.agencies));

    getAndSetUsers();
    getAndSetAgencies();
  }, []);

  const user = agencyUserId ? users.find((user) => user.id === agencyUserId) : null;

  return (
    <section id="user-management">
      <div>
        <h2>User Management</h2>
        {user ? <AgencyUserForm user={user} agencies={agencies} routeInitialValues={user} /> : null}
        <AgencyUserList agencies={agencies} users={users} />
      </div>
    </section>
  );
};

export default AgencyUserManagement;

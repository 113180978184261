import React, { useEffect } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Table = (props) => {
  let { columns, data, size, tableName } = React.useMemo(() => props, [props.data]);
  const hiddenCols = columns.filter((item) => item.isVisible === false).map((item) => item.accessor);
  let { hidePagination, hideHeaders } = props;
  const initialPageSize = data?.length > 20 ? 20 : data?.length;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({ columns, data, autoResetPage: false, initialState: { pageIndex: 0, pageSize: initialPageSize, hiddenColumns: hiddenCols } }, useSortBy, usePagination);

  // console.log({ page, pageCount, pageSize, pageOptions });

  useEffect(() => {
    // console.log({ data });
    // console.log(data?.length);
    setPageSize(data?.length > 20 ? 20 : data?.length > 0 ? data.length : 1);
  }, [data.length]);

  return (
    <div className="table-wrapper table-responsive-xl">
      <table name={tableName} className="table table-hover table-striped" {...getTableProps()}>
        {!hideHeaders && (
          <thead>
            {headerGroups.map((headerGroup, headerGroupIndex) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(
                  (column, columnIndex) =>

                    column.isVisible && (
                      <th column-index={columnIndex} column-name={column.Header} {...column.getHeaderProps(column.getSortByToggleProps())} className=" position-relative">
                        {column.render("Header")}
                        {!column.disableSortBy ? (
                          <FontAwesomeIcon className="text-white ml-2 position-absolute" icon={["fas", column.isSorted ? (column.isSortedDesc ? "angle-up" : "angle-down") : "angle-down"]} size="sm" />
                        ) : null}
                      </th>
                    )
                )}
              </tr>
            ))}
          </thead>
        )}
        <tbody {...getTableBodyProps()}>
          {page.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr name={`r${rowIndex}`} {...row.getRowProps()}>
                {row.cells.map((cell, cellIndex) => {
                  return <td name={`c${cellIndex}`} column-index={cellIndex} {...cell.getCellProps()}> {cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      {!hidePagination && (
        <nav aria-label="Table Navigation" className="table-nav d-flex flex-row justify-content-between align-items-center p-3 ">
          <div>
            <span>
              {pageSize * (pageIndex + 1) > data?.length ? data.length : pageSize * (pageIndex + 1)} of {data?.length}
            </span>
            <span className="px-3">|</span>
            <button
              className="faux-link"
              onClick={() => {
                setPageSize(data.length);
              }}
            >
              View All
            </button>
          </div>
          <ul className="pagination table-pagination mb-0">
            <li className="page-item">
              <button className="btn btn-secondary btn-sm" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                <span aria-hidden="true">
                  <FontAwesomeIcon className="text-white" icon={["fal", "angle-double-left"]} size="lg" />
                </span>
                <span className="sr-only">First Page</span>
              </button>{" "}
            </li>
            <li className="page-item">
              <button className="btn btn-secondary btn-sm" onClick={() => previousPage()} disabled={!canPreviousPage}>
                <span aria-hidden="true">
                  <FontAwesomeIcon className="text-white" icon={["fal", "angle-left"]} size="lg" />
                </span>
                <span className="sr-only">Previous Page</span>
              </button>{" "}
            </li>
            <li>
              <span>
                <label htmlFor="table-page-set" className="sr-only">
                  Set Page
                </label>
                <input
                  name="table-page-set"
                  id="table-page-set"
                  type="number"
                  value={pageIndex + 1}
                  className="d-inline"
                  onChange={(e) => {
                    let num = e.target.value ? Number(e.target.value) - 1 : 0;
                    const page = num < 1 ? 1 : num;
                    gotoPage(page);
                  }}
                />
              </span>

              <span className="px-2">of {pageOptions.length}</span>
            </li>
            <li className="page-item">
              <button className="btn btn-secondary btn-sm" onClick={() => nextPage()} disabled={!canNextPage}>
                <span aria-hidden="true">
                  <FontAwesomeIcon className="text-white" icon={["fal", "angle-right"]} size="lg" />
                </span>
                <span className="sr-only">Next Page</span>
              </button>{" "}
            </li>
            <li className="page-item">
              <button className="btn btn-secondary btn-sm" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                <span aria-hidden="true">
                  <FontAwesomeIcon className="text-white" icon={["fal", "angle-double-right"]} size="lg" />
                </span>
                <span className="sr-only">Last Page</span>
              </button>{" "}
            </li>
            {/* <li>
              <select
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value))
                }}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </li>*/}
          </ul>
        </nav>
      )}
    </div>
  );
};

export default Table;

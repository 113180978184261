import React, { useState } from "react";
import { handleInvitePrime } from "../../util/api";
import { withFormikFormComponent } from "../Forms/FormikForm";
import InvitePrimeForm from "./InvitePrimeForm";

const InvitePrime = (props) => {
  const [submitted, setSubmitted] = useState(false);
  const onSubmit = async (values, { resetForm }) => {
    setSubmitted(false);
    const { firstName, lastName, emailAddress } = values;
    const resObj = await handleInvitePrime(firstName, lastName, emailAddress);
    resetForm();
    setSubmitted(true);
  };

  return (
    <section id="invite-prime" className="my-4">
      <h2>Invite a Prime Contractor</h2>
      <p>Invite a new Prime Contractor to SDVES.</p>

      <div id="invite-prime-form-wrapper" className="bg-light p-4">
        <h3 className="mt-2">Invite a New Prime Contractor</h3>

        <hr />

        {submitted ? <h2>The contractor's invitation has been sent</h2> : <InvitePrimeForm onSubmit={onSubmit} />}
      </div>
    </section>
  );
};

export default InvitePrime;

export default [
  { label: "Administrative & Technical", id: "Administrative & Technical" },
  { label: "Advertising, Graphic Arts, Marketing & Interior Design", id: "Advertising, Graphic Arts, Marketing & Interior Design" },
  { label: "Agriculture, Forestry, Gardening, Landscaping, Lawn Maintenance & Snow Removal", id: "Agriculture, Forestry, Gardening, Landscaping, Lawn Maintenance & Snow Removal" },
  { label: "Apparel, Textile & Leather", id: "Apparel, Textile & Leather" },
  { label: "Architectural, Engineering & Surveying", id: "Architectural, Engineering & Surveying" },
  { label: "Construction Horizontal: Highways & Roadways; Maintenance, Repair & New Construction", id: "Construction Horizontal: Highways & Roadways; Maintenance, Repair & New Construction" },
  { label: "Construction Vertical: Building Construction; Rehabilitation & New Construction", id: "Construction Vertical: Building Construction; Rehabilitation & New Construction" },
  { label: "Educational & Recreational", id: "Educational & Recreational" },
  { label: "Environmental", id: "Environmental" },
  { label: "Facilities, Maintenance, Repair & Building Operations", id: "Facilities, Maintenance, Repair & Building Operations" },
  { label: "Financial", id: "Financial" },
  { label: "Food, Beverages, Commissary & Vending", id: "Food, Beverages, Commissary & Vending" },
  { label: "Freight, Moving & Warehousing", id: "Freight, Moving & Warehousing" },
  { label: "Fuels & Lubricants", id: "Fuels & Lubricants" },
  { label: "Information Technology", id: "Information Technology" },
  { label: "Legal & Investigative Services", id: "Legal & Investigative Services" },
  { label: "Medical & Health Care", id: "Medical & Health Care" },
  { label: "Medical & Laboratory Equipment", id: "Medical & Laboratory Equipment" },
  { label: "Miscellaneous", id: "Miscellaneous" },
  { label: "Office & Residential Furniture, Supplies & Equipment", id: "Office & Residential Furniture, Supplies & Equipment" },
  { label: "Photography & Video", id: "Photography & Video" },
  { label: "Printing", id: "Printing" },
  { label: "Raw Materials for Manufacturing", id: "Raw Materials for Manufacturing" },
  { label: "Real Property, Office, Hotel, Conferences & Meeting Space", id: "Real Property, Office, Hotel, Conferences & Meeting Space" },
  { label: "Safety & Security", id: "Safety & Security" },
  { label: "Telecommunications", id: "Telecommunications" },
  { label: "Transportation, Bus, Rail, Marine & Aviation", id: "Transportation, Bus, Rail, Marine & Aviation" },
  { label: "Utilities & Green Energies", id: "Utilities & Green Energies" },
  { label: "Vehicles & Equipment", id: "Vehicles & Equipment" },
]

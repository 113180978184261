import React, { useState, useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import ProcurementsListNav from "./ProcurementsListNav";
import { componentsFromRoutes } from "../../util/helpers";
import ProcurementsList from "./ProcurementsList";
import AgencyProcurements from "./AgencyProcurements";
import CompleteProcurementsList from "./CompleteProcurementsList";

const AgencyProcurementsListOverview = (props) => {
  const procurementRoutes = {
    routes: [
      {
        path: "/procurements-list/",
        title: "Open Awards",
        exact: true,
        component: AgencyProcurements,
      },
      {
        path: "/procurements-list/open",
        title: "Open Awards",
        exact: true,
        component: AgencyProcurements,
      },
      {
        path: "/procurements-list/completed",
        title: "Completed Awards",
        exact: true,
        component: CompleteProcurementsList,
      },
    ],
    globalProps: {},
  };

  return (
    <section id="procurements-list-wrapper">
      <div id="procurements-list">
        <ProcurementsListNav />
        {componentsFromRoutes(procurementRoutes)}
      </div>
    </section>
  );
};

export default AgencyProcurementsListOverview;

import { Form } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, Prompt, useParams } from "react-router-dom";
import { getApplication, handleApplicationAppeal, handleApplicationWithdrawal, handleSaveApplication, handleSubmitApplication } from "../../util/api";
import { schemas } from "../../util/errorHelper";
import { formatAddress, formatPhoneNumber, hasExpired, hydrateApplication, isUserAPreparer, outputPhase, outputStatus, outputVisitStatus } from "../../util/helpers";
import { FormTabs_InitialValues } from "../../util/tabHelper";
import DebugModal from "../Debug/DebugModal";
import { withFormikFormComponent } from "../Forms/FormikForm";
import "./BusinessApplication.scss";
import BusinessApplicationTabs from "./BusinessApplicationTabs";
import ChangeRequestsDisplay from "./components/ChangeRequestsDisplay";
import ApplicationReviewComponent from "./tabs/review/ApplicationReviewComponent";

const BusinessApplicationInternal = ({ appBag, formikBag, userInfoUpdates, setUserInfoUpdates }) => {
  const { initialValues } = formikBag;
  const { onSave } = appBag;
  const [tabs, setTabs] = useState(FormTabs_InitialValues);

  useEffect(() => {
    const savedTabs = localStorage.getItem("tabs") ? JSON.parse(localStorage.getItem("tabs")) : null;
    if (isUserAPreparer(initialValues)) {
      setTabs(savedTabs ? savedTabs : FormTabs_InitialValues);
    } else {
      setTabs({ tabKeyActive: "reviewSubmit", tabs: { reviewSubmit: { ...FormTabs_InitialValues?.tabs?.reviewSubmit } } });
    }
  }, []);

  // console.log(FormTabs_InitialValues)

  let saveMessage = (
    <>
      {initialValues.updated ? (
        <p key={`save-${initialValues?.updated}`} className="small text-success success-animate" id="last-saved-timestamp">
          <span>
            <i>Last saved {new Date(initialValues?.updated).toLocaleString()}</i>
          </span>
        </p>
      ) : (
        <p key="unsaved-timestamp" className="small text-danger success-animate" id="last-saved-timestamp">
          Application not yet saved
        </p>
      )}
    </>
  );

  const handleSoftSave = (tabs) => {
    localStorage.setItem("tabs", JSON.stringify(tabs));
  };

  const handleTabChange = (tabKey, tab, isTabClicked) => {
    setTabs((oldTabs) => {
      const newTabs = { ...oldTabs.tabs };
      newTabs[tabKey] = { ...newTabs[tabKey], ...tab };
      let tabs;
      if (isTabClicked) {
        tabs = { tabs: { ...newTabs }, tabKeyActive: tabKey };
      } else {
        tabs = { tabs: { ...newTabs }, tabKeyActive: oldTabs.tabKeyActive };
      }
      handleSoftSave(tabs);
      window.scrollTo(0, 0);
      return tabs;
    });
  };

  const handleUnLoad = (e) => {
    if (formikBag.dirty) {
      const confirmationMessage = "confirmationMessage";
      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleUnLoad);
    // window.beforeunload = () => true;
    return function cleanup() {
      window.removeEventListener("beforeunload", handleUnLoad);
      // window.beforeunload = () => undefined;
    };
  });

  let changes = formikBag?.values?.changes;
  changes.sort((a, b) => (a.created < b.created ? 1 : -1));

  return (
    <div>
      <Prompt when={formikBag.dirty} message={"You have unsaved changes, are you sure you want to leave?"} />
      <Form className="application-form position-relative">
        <h2 id="vendor-certification-application-header">Vendor Certification Application</h2>
        <ChangeRequestsDisplay formikBag={formikBag} />

        <DebugModal formikBag={formikBag} name="Formik Bag" />

        <BusinessApplicationTabs
          name={`business`}
          formikBag={formikBag}
          appBag={{
            tabs,
            onTabChange: handleTabChange,
            ...appBag,
          }}
        />
        {isUserAPreparer(initialValues) ? (
          <>
            <div className="form-actions position-md-absolute pt-5">
              <Button variant="primary" onClick={() => onSave(formikBag.values)} className="mr-3 mb-3 mb-md-0" id="save-button">
                Save
              </Button>
            </div>

            {saveMessage}
          </>
        ) : null}
      </Form>
    </div>
  );
};
const BusinessApplicationFormik = withFormikFormComponent(BusinessApplicationInternal, schemas.applicationSchema);

const BusinessApplicationOverview = ({ application, reloadApplication, setUserInfoUpdates, userInfoUpdates }) => {
  const { legalName, addresses, phoneNumber, emailAddress, homepageUrl, controlNumber, tags, status, phase, visitationStatus, timers } = application;
  const [changeCount, setChangeCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const [modalChoice, setModalChoice] = useState();

  const confirmations = {
    Withdraw: {
      message: "Withdraw Application?",
      action: handleApplicationWithdrawal,
      // action: () => { console.log(userInfoUpdates) },
    },
  };

  const outputArr = [
    {
      name: "Legal Name",
      value: legalName,
      ref: "legalName",
    },
    {
      name: "Address",
      value: addresses,
      ref: "addresses",
    },
    {
      name: "Phone",
      value: formatPhoneNumber(phoneNumber),
      ref: "phoneNumber",
    },
    {
      name: "Email",
      value: emailAddress,
      ref: "emailAddress",
    },
    {
      name: "Website",
      value: homepageUrl,
      ref: "homepageUrl",
    },
    // {
    //   name: "Control Number",
    //   value: controlNumber,
    //   ref: "controlNumber",
    // },
    {
      name: "Keywords",
      value: tags,
      ref: "tags",
    },
  ];

  return (
    <div>
      <h2>Application and Certification Status</h2>
      <div className="p-4 bg-light">
        <p>SDVOB Certification Application</p>
        <h2>{legalName}</h2>
        <h3 className="text-black mb-3">
          Status: {outputStatus(status)}
          {status !== "Certified" ? (
            <>
              <br />
              Phase: {outputPhase(phase)}
            </>
          ) : null}
          {status !== "Certified" ? (
            <>
              <br />
              Director Site Visit: {outputVisitStatus(visitationStatus)}
            </>
          ) : null}
        </h3>

        <nav className="actions-bar">
          <div className="row mb-4">
            <>
              {/* <div className="col-md-3">
                <button onClick={() => { console.log('We should display a read-only application') }} className="btn btn-secondary btn-block btn-sm mb-0">View Application</button>
              </div> */}

              {["New", "InProgress", "Submitted", "Deficient", "Appealed"].includes(status) ? (
                <div className="col-md-3">
                  <button
                    onClick={async () => {
                      setModalChoice("Withdraw");
                      setShowModal(true);
                    }}
                    className="btn btn-secondary btn-block btn-sm mb-0"
                  >
                    Withdraw Application
                  </button>
                </div>
              ) : null}

              {status === "Denied" && !hasExpired(timers.find((item) => item.type === "AbleToAppeal")) ? (
                <div className="col-md-3">
                  <button
                    onClick={async () => {
                      const resObj = await handleApplicationAppeal(application.id);
                      await reloadApplication();
                    }}
                    className="btn btn-secondary btn-block btn-sm mb-0"
                  >
                    Appeal Denial
                  </button>
                </div>
              ) : null}
            </>
          </div>
        </nav>

        {outputArr.map((item, index) => {
          let val = "";
          switch (item.ref) {
            case "addresses":
              const physicalAddr = addresses?.find((addr) => {
                return addr.type === "Physical";
              });
              val = formatAddress(physicalAddr);
              break;
            case "tags":
              val = <span>{item?.value?.join(", ")}</span>;
              break;
            default:
              val = item?.value;
              break;
          }
          return (
            <div className="row" key={index}>
              <div className="col-4 col-sm-3 col-md-2">
                <span className="font-weight-bold">{item.name}</span>
              </div>
              <div className="col-8 col-sm-9 col-md-10">{val}</div>
            </div>
          );
        })}

        <ApplicationReviewComponent {...application}></ApplicationReviewComponent>

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Changes</Modal.Title>
          </Modal.Header>
          <Modal.Body>{confirmations[modalChoice]?.message}</Modal.Body>
          <Modal.Footer className="d-block">
            <div className="row">
              <div className="col-md-6">
                <Button className="btn-block btn-sm" variant="secondary" onClick={handleCloseModal}>
                  Cancel
                </Button>
              </div>
              <div className="col-md-6">
                <Button
                  className="btn-block btn-sm"
                  variant="primary"
                  onClick={async () => {
                    const resObj = await confirmations[modalChoice]?.action(application?.id);
                    setUserInfoUpdates(userInfoUpdates + 1);
                    await reloadApplication();
                    handleCloseModal();
                  }}
                >
                  Confirm
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

// export default ApplicantFormik;

const BusinessApplication = ({ routeInitialValues, userInfoUpdates, setUserInfoUpdates }) => {
  const [application, setApplication] = useState({});
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [error, setError] = useState(false);
  const { applicationId } = useParams();

  async function load() {
    setLoading(true);

    try {
      const application = await getApplication(applicationId);
      hydrateApplication(application);
      setApplication(application);
      setLoading(false);
    } catch (e) {
      setError("You do not have access to this application");
    }
  }

  useEffect(() => {
    load();
  }, []);

  const canEditApplication = (application) => {
    const { phase, status } = application;
    return phase === "Preparation" || status === "Deficient";
  };

  const onSave = async (values) => {
    const application = await handleSaveApplication(values);
    hydrateApplication(application);
    setApplication(application);
  };

  const onSubmitApplication = async (values) => {
    const application = await handleSubmitApplication(values);
    setApplication(application);
    window.scrollTo(0, 0);
  };

  if (!loading) {
    if (canEditApplication(application)) {
      return (
        <>
          <Link to="/" className="py-4 d-block">
            <FontAwesomeIcon className="mr-2" icon={"angle-left"} size="lg" /> Back to Dashboard
          </Link>
          <BusinessApplicationFormik
            validateOnMount={true}
            validateOnChange={true}
            routeInitialValues={{ ...routeInitialValues, ...application }}
            onSubmit={onSubmitApplication}
            appBag={{ onSave, tags }}
          />
        </>
      );
    } else {
      return (
        <>
          <Link to="/" className="py-4 d-block">
            <FontAwesomeIcon className="mr-2" icon={"angle-left"} size="lg" /> Back to Dashboard
          </Link>
          <BusinessApplicationOverview application={application} reloadApplication={load} setUserInfoUpdates={setUserInfoUpdates} userInfoUpdates={userInfoUpdates} />
        </>
      );
    }
  } else {
    return error ? (
      <div className="alert alert-primary">{error}</div>
    ) : (
      <div className="text-center">
        <span className="loader loader-pulse ">Loading your SDVOB application...</span>
      </div>
    );
  }
};
export default BusinessApplication;

import React from "react";
import { Button, Modal } from "react-bootstrap";

function AlertFailureComponent(props) {
  const { message, setMessage, errorMessageTitle } = props;

  const handleClose = () => {
    setMessage("");
  };

  // console.log(message);

  return (
    <>
      <Modal show={!!message} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{errorMessageTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="pre-line">{message}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AlertFailureComponent;

import React from "react";
import { createNavLinks } from "../../util/helpers";
import Navbar from "react-bootstrap/Navbar";

const DivisionAdminNav = () => {
  const items = [
    ["/centralized-contracts", "Centralized Contracts"],
    ["/user-list/agency", "User Management"],
    ["/certification-timers", "Application Timers"],
    ["/system-preferences", "System"],
  ];

  return (
    <Navbar collapseOnSelect expand="md" className="p-0 d-block">
      <div className="nav-header bg-black p-4 d-flex justify-content-between align-items-center">
        Administrator Dashboard
        <Navbar.Toggle aria-controls="sidebar-nav" />
      </div>
      <Navbar.Collapse id="sidebar-nav">{createNavLinks(items)}</Navbar.Collapse>
    </Navbar>
  );
};

export default DivisionAdminNav;

import React from "react";
import { createNavLinks } from "../../util/helpers";
import Navbar from "react-bootstrap/Navbar";

const ContractManagerNav = () => {
  const items = [
    ["/", "Certification Timers"],
    ["/", "Default Setting"],
    ["/", "Reporting"],
  ];

  return (
    <Navbar collapseOnSelect expand="md" className="p-0 d-block">
      <div className="nav-header bg-black p-4 d-flex justify-content-between align-items-center">
        Contract Manager Dashboard <Navbar.Toggle aria-controls="sidebar-nav" />
      </div>
      <Navbar.Collapse id="sidebar-nav">{createNavLinks(items)}</Navbar.Collapse>
    </Navbar>
  );
};

export default ContractManagerNav;

import React, { useEffect, useState, useContext } from "react";
import "./SubmitUtilizationPlan.scss";
import { useParams, useHistory, Link } from "react-router-dom";
import { getPlan, getAgencies, getProcurement, getCertifiedBusinesses, handleUtilizationPlanUpdate, handleUtilizationPlanSubmit } from "../../../util/api";
import UtilizationPlanSubmitForm from "./UtilizationPlanSubmitForm";
import { UserContext } from "../../../Layouts/Base";
import { getActiveOrg, roles, loadUserRole, loadActiveOrgId } from "../../../util/userHelper";

const SubmitUtilizationPlan = (props) => {
  const userInfo = useContext(UserContext);
  let { planId, procurementId } = useParams(); // use these variables explicitly to represent what came from the routes, and not objects we load
  const history = useHistory();
  const [isRevision] = useState(!!planId);
  const [plan, setPlan] = useState(null);
  const [procurement, setProcurement] = useState({});
  const [agencies, setAgencies] = useState([]);
  const [agenciesOptions, setAgenciesOptions] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [businessesOptions, setBusinessesOptions] = useState([]);
  const [initialValues, setInitialValues] = useState(props?.routeInitialValues);
  // const { isCertifiedSdvob } = roles.Business === loadUserRole() ? getActiveOrg(userInfo) : procurement?.contract?.primeContractor;
  const [primeIsCertified, setPrimeIsCertified] = useState(false);
  const [agencyId, setAgencyId] = useState();

  useEffect(() => {
    if (roles.Business === loadUserRole()) {
      setPrimeIsCertified(getActiveOrg(userInfo)?.isCertifiedSdvob);
    } else if (procurement?.contract?.primeContractor) {
      setPrimeIsCertified(procurement?.contract?.primeContractor?.isCertifiedSdvob);
    }
  }, [procurement]);

  // Get the existing plan to revise if a planId exists in our route
  // Set our values based on our situation
  useEffect(() => {
    const fetchExistingProcurement = async (procurementId) => {
      // console.log("getting procurement");
      // set procurement
      const existingProcurementRes = await getProcurement(procurementId);
      setProcurement(existingProcurementRes?.data);
      setAgencyId(procurement?.agencyId);
    };

    const fetchExistingPlan = async (planId) => {
      // set plan
      const existingPlanRes = await getPlan(planId);
      setPlan(existingPlanRes);
      fetchExistingProcurement(existingPlanRes.procurementId);
    };

    if (planId) {
      // console.log("plan");
      fetchExistingPlan(planId);
    } else if (procurementId) {
      // console.log("procurement");
      fetchExistingProcurement(procurementId);
    }
  }, []);

  // Get Businesses and agencies for dropdowns
  // We need these no matter what
  useEffect(() => {
    const fetchAgencies = async () => {
      // const myAgencies = await getAgencies();
      const myAgencies = planId ? { agencies: [[procurement?.agency?.id, procurement?.agency?.name]] } : await getAgencies();
      setAgencies(myAgencies?.agencies);
    };

    const fetchBusinesses = async () => {
      const myBusinesses = await getCertifiedBusinesses();
      setBusinesses(myBusinesses.businesses);
    };

    if (!planId) {
      fetchAgencies();
    }

    if (!primeIsCertified) {
      fetchBusinesses();
    }
  }, []);

  // Format agencies
  useEffect(() => {
    const newAgenciesOptions = [["", ""]];
    agencies.map((item) => {
      newAgenciesOptions.push([item.id, item.name]);
    });
    setAgenciesOptions(newAgenciesOptions);
  }, [agencies]);

  // Format businesses for dropdowns
  useEffect(() => {
    const newBusinessesOptions = [["", ""]];
    businesses.map((item) => {
      newBusinessesOptions.push([item.id, item.legalName]);
      // newBusinessesOptions.push({ id: item.id, label: item.legalName });
    });

    setBusinessesOptions(newBusinessesOptions);
  }, [businesses]);

  useEffect(() => {
    if (procurementId || planId) {
      setInitialValues({
        // ...routeInitialValues,
        agencyId: roles.Business !== loadUserRole() ? loadActiveOrgId() : agencyId,
        procurementId: procurement?.id,
        solicitationNumber: procurement?.solicitationNumber,
        documents: plan ? plan?.documents : [],
        subcontracts: plan ? plan?.subcontracts.map((item) => ({ contractorId: item.contractor.id, description: item.description, value: item.value, goal: item.goal })) : [],
        goal: procurement?.goal,
        realGoal: 0,
        realValue: 0,
        status: plan ? plan?.status : "",
        value: procurement?.contract ? procurement?.contract?.currentValue : plan ? plan?.value : "",
        totalSdvob: 0,
        term: procurement?.contractTerm,
      });
    }
  }, [plan, procurement]);

  // This should handle submits for agencies and businesses
  const onSubmit = async (values, { resetForm }) => {
    // Gather all the information from the form
    const { procurementId, documents, goal, totalSdvob, agencyId, realGoal, subcontracts, waiverDescription, value } = values;

    let submitObj = {
      agencyId: agencyId,
      primeContractorId: procurement?.contract?.primeContractor?.id,
      procurementId: procurementId,
      documents: documents,
      waiverDescription: parseInt(realGoal) < goal ? waiverDescription : "",
      subcontracts: subcontracts,
      value: value,
    };
    // Value doesn't get submitted if it is a "term" contract
    if (procurement?.contractTerm === "Standard") {
      submitObj.value = value;
    }

    let resObj = {};
    // Update if revision
    if (isRevision) {
      resObj = await handleUtilizationPlanUpdate({ ...submitObj, planId: planId });
      if (plan?.awardStatus === "Awarded") {
        // Send them back to the procurement if it has already been awarded and they are the prime
        history.push(`/procurements/${resObj?.data?.procurementId}/plans-waivers`);
      } else {
        // Send them back to the plan list if their submitted plan is just in the queue
        history.push(`/utilization-plans`);
      }
      window.scrollTo(0, 0);
    } else {
      const redirectPath = roles.Business === loadUserRole() ? `/utilization-plans` : `/procurements/${procurementId}`;
      // Create if not revising existin gplan
      resObj = await handleUtilizationPlanSubmit(submitObj);
      history.push(redirectPath);
      window.scrollTo(0, 0);
    }
  };

  return (
    <div className="plan-form-wrapper">
      <h2>Submit a Utilization Plan</h2>
      <UtilizationPlanSubmitForm
        plan={plan}
        onSubmit={onSubmit}
        isRevision={isRevision}
        procurement={procurement}
        setProcurement={setProcurement}
        agenciesOptions={agenciesOptions}
        businesses={businesses}
        businessesOptions={businessesOptions}
        validateOnChange={true}
        primeIsCertified={primeIsCertified}
        routeInitialValues={
          primeIsCertified
            ? {
                ...initialValues,
                realGoal: 100,
                value: procurement?.contract?.currentValue ? procurement?.contract?.currentValue : initialValues?.value,
                // term: procurement?.contractTerm
              }
            : {
                ...initialValues,
                documents: [],
                value: procurement?.contract?.currentValue ? procurement?.contract?.currentValue : initialValues?.value,
                // agencyId: "22485138-3f93-4329-9f93-c7b16455734b",
                // procurementId: "41c7eddd-42f1-43db-9878-17ad1fcd3a7e"
              }
        }
      />
    </div>
  );
};

export default SubmitUtilizationPlan;

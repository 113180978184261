import React, { useState, useEffect } from "react";
import { getSystemData, updateSystemData, createSeedData } from "../../../util/api";
import SystemPreferencesForm from "./SystemPreferencesForm";
import SystemSeedDataForm from "./SystemSeedDataForm";
import ReactiveButton from "../../Forms/Input/ReactiveButton";


function SystemPrefereces(props) {
  const [systemPrefs, setSystemPrefs] = useState({});
  const [successMessage, setSuccessMessage] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const resObj = await getSystemData();
      setSystemPrefs(resObj.data);
    };
    fetchData();
  }, []);

  const onSubmit = async (values, { resetForm }) => {
    setSuccessMessage("");
    const resObj = await updateSystemData(values);
    setSuccessMessage("System preferences successfully updated.");
  };

  const onSeedTestDataSubmit = async (values, { resetForm }) => {
    setSuccessMessage("");
    const res = await createSeedData(values.agencyId)
    setSuccessMessage("Test Data Seeded");
  };

  return (
    <>
      <h2>System Preferences</h2>
      <SystemPreferencesForm routeInitialValues={systemPrefs} onSubmit={onSubmit} successMessage={successMessage} setSuccessMessage={setSuccessMessage} />

    </>
  );
}

export default SystemPrefereces;

import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import InputComponent from "../../Forms/Input/InputComponent";
import { withFormikFormComponent } from "../../Forms/FormikForm";
import { FastField } from "formik";
import Button from "react-bootstrap/Button";
import DebugModal from "../../Debug/DebugModal";
import { schemas } from "../../../util/errorHelper";
import { createCentralizedContract, saveCentralizedContract, getCentralizedContract } from "../../../util/api";
import { YES_NO_RADIO } from "../../../util/typelistHelper";

const CentralizedContractSubmitFormInternal = (props) => {
  const { formikBag, updateValues, setUpdateValues, centralizedContractId, setUpdateCount, updateCount } = props;
  const { values, resetForm } = formikBag;
  const history = useHistory();

  const handleSubmit = async (data, action) => {
    const { contractNumber, name, active, centralizedContractId } = values;
    const id = centralizedContractId;
    let submitObj = {
      contractNumber,
      name,
      active,
      centralizedContractId,
      id,
    };

    if (action === "create") {
      const resObj = await createCentralizedContract(submitObj);
      setUpdateCount(updateCount + 1);
    }

    if (action === "save") {
      const resObj = await saveCentralizedContract(submitObj);
      setUpdateValues(updateValues);
    }
  };

  return (
    <section id="activity-report-submit-form">
      <DebugModal formikBag={formikBag} name="Formik Bag" />

      <div id="activity-report-submit-form-wrapper" className="bg-light p-4">
        <h3>Manage Centralized Contract</h3>
        <hr />
        <form>
          <div className="field-group row">
            <FastField wrapperClass="col-12" label="Contract Number" type="text" name={"contractNumber"} component={InputComponent} placeholder="" />
            <FastField wrapperClass="col-12" label="Name" type="text" name={"name"} component={InputComponent} placeholder="" />
            <FastField wrapperClass="col-12" name={"active"} childClass={""} component={InputComponent} type="radio" label="Active" options={YES_NO_RADIO} required={false} />
          </div>

          <div className="row">
            {values.centralizedContractId ? (
              <div className="col-md-6">
                <Button
                  onClick={async (submitObj) => {
                    await handleSubmit(submitObj, "save");
                    resetForm();
                    history.push("/centralized-contracts");
                  }}
                  type="button"
                  variant="secondary"
                  className="btn-md btn-submit btn-block"
                  disabled={!formikBag.isValid}
                >
                  Save
                </Button>
              </div>
            ) : (
              <div className="col-md-6">
                <Button
                  onClick={async (submitObj) => {
                    await handleSubmit(submitObj, "create");
                    resetForm();
                  }}
                  type="button"
                  variant="secondary"
                  className="btn-md btn-submit btn-block"
                  disabled={!formikBag.isValid}
                >
                  Submit
                </Button>
              </div>
            )}
          </div>
        </form>
      </div>
    </section>
  );
};

const CentralizedContractSubmitForm = withFormikFormComponent(CentralizedContractSubmitFormInternal, schemas.editCentralizedContractSchema);
export default CentralizedContractSubmitForm;

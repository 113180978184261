import React from "react";
import { getLocalDate } from "../../../util/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { roles, loadUserRole } from "../../../util/userHelper";

const BasicInfo = ({ report, isVersion }) => {
  // console.log(report);
  return (
    <div>
      {report?.manuallyUpdated && [roles.DivisionUser, roles.DivisionAdmin].includes(loadUserRole()) && (
        <div className="">
          <p className="alert alert-primary d-inline-block">
            <FontAwesomeIcon icon="exclamation-circle" size="md" className="" /> This report has been manually updated since it's original, system-generated form.
          </p>
        </div>
      )}
      {!isVersion && [roles.DivisionUser, roles.DivisionAdmin].includes(loadUserRole()) ? (
        <div className="row mb-2">
          <div className="col-sm-5">Agency</div>
          <div className="col-sm-7">{report?.agency?.name}</div>
        </div>
      ) : null}

      <div className="row mb-2">
        <div className="col-sm-5">Fiscal Year</div>
        <div className="col-sm-7">{report?.fiscalYear}</div>
      </div>

      {report?.fiscalQuarter ? (
        <div className="row mb-2">
          <div className="col-sm-5">Fiscal Quarter</div>
          <div className="col-sm-7">{report?.fiscalQuarter}</div>
        </div>
      ) : null}

      <div className="row mb-2">
        <div className="col-sm-5">Status</div>
        <div className="col-sm-7">{report?.status}</div>
      </div>

      <div className="row mb-2">
        <div className="col-sm-5">Reviewed</div>
        <div className="col-sm-7">{getLocalDate(report?.reviewed)}</div>
      </div>

      <div className="row mb-2">
        <div className="col-sm-5">Reviewer</div>
        <div className="col-sm-7">{report?.reviewer?.name}</div>
      </div>
    </div>
  );
};

export default BasicInfo;

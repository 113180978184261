import React from "react";
import _ from "lodash";

function InputComponentCheckboxRadio(props) {
  const {
    field: { name },
    form: { setFieldValue, setFieldTouched, values },
    type,
    options,
    multiple,
    wrapperClass,
    childClass,
    isSwitch,
    label,
    required,
    readOnly,
    className,
    isdisabled,
  } = props;
  const value = _.get(values, name);

  /**
   * If checked value is true then unchecked value is false
   * this way no additional parameters is required or true and false conditions
   * @param {*} checkedValue
   * @returns
   */
  const getUncheckedValue = (checkedValue) => {
    return checkedValue === true ? false : "";
  };

  const onChange = (e, checkedValue) => {
    let valueNew = "";
    if (multiple) {
      if (e.target.checked) {
        valueNew = [...value, checkedValue];
        setFieldValue(name, valueNew);
      } else {
        const index = value?.indexOf(checkedValue);
        if (index > -1) {
          valueNew = [...value];
          valueNew.splice(index, 1);
          setFieldValue(name, valueNew);
        }
      }
    } else {
      valueNew = value === checkedValue ? getUncheckedValue(checkedValue) : checkedValue;
      setFieldValue(name, valueNew);
    }
    if (props.onChange) {
      props.onChange(e, checkedValue);
    }
  };

  const getCheckedValue = (checkedValue) => {
    let result;
    if (multiple) {
      result = value?.includes(checkedValue) ? true : false;
    } else {
      result = checkedValue === value ? true : false;
    }
    return result;
  };

  return (
    <div>
      <p>
        {label} {required !== false ? <span className="required text-danger">*</span> : null}
      </p>
      <div className="row">
        {options.map((option, index) => (
          <div key={`${name}-${index}`} className={`d-flex flex-row ${childClass ? childClass : "col-12"} ${isSwitch ? "switch" : null}`}>
            <input
              type={type}
              id={`${name}.${index}`}
              name={name}
              readOnly={readOnly}
              required={required}
              className={`mr-2 ${className}`}
              onChange={(e) => onChange(e, option[0])}
              onBlur={() => setFieldTouched(name, true)}
              disabled={isdisabled}
              checked={getCheckedValue(option[0])}
            />
            {isSwitch ? <span className="slider round"></span> : <span className="checkmark"></span>}
            <label htmlFor={`${name}.${index}`}>{option[1]}</label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default InputComponentCheckboxRadio;

import React from "react";
import { Form } from "formik";
import { FastField } from "formik";
import { withFormikFormComponent } from "../Forms/FormikForm";
import InputComponent from "../Forms/Input/InputComponent";
import ReactiveButton from "../Forms/Input/ReactiveButton";
import DebugModal from "../Debug/DebugModal";
import { schemas } from "../../util/errorHelper";

const InvitePrimeFormInternal = (props) => {
  const { formikBag } = props;
  const { values, isSubmitting, isValid } = formikBag;
  return (
    <Form>
      <DebugModal formikBag={formikBag} name="Formik Bag" />
      <div className="field-group row">
        <div class="col-md-4">
          <FastField inline={true} wrapperClass="" label="First Name" type="text" name={`firstName`} component={InputComponent} placeholder="" />

          <FastField inline={true} wrapperClass="" label="Last Name" type="text" name={`lastName`} component={InputComponent} placeholder="" />

          <FastField inline={true} wrapperClass="" label="Email Address" type="email" name={`emailAddress`} component={InputComponent} placeholder="" />
        </div>
      </div>
      <div class="row">
        <div className="col-md-4">
          {!isValid ? <h3>Please complete all required information</h3> : <ReactiveButton type="submit" className="btn-md btn-secondary" label="Send Secure Invitation" processing={isSubmitting} />}
        </div>
      </div>
    </Form>
  );
};

const InvitePrimeForm = withFormikFormComponent(InvitePrimeFormInternal, schemas.primeInviteSchema);
export default InvitePrimeForm;

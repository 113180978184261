import orderBy from "lodash/orderBy";
import React, { useState } from "react";
import { DocumentsSection } from "../../Forms/DocumentHelper";
import AuthorBox from "./AuthorBox";
import CorrespondencForm from "./CorrespondenceForm";

const CorrespondenceReply = ({ reply, name, correspondence, correspondenceId, updateCount, setUpdateCount, handleReplyToReply }) => {
  const { isClosed } = correspondence;
  const [showForm, setShowForm] = useState(false);

  const handleSubmit = async (data, { resetForm }) => {
    const replyRes = await handleReplyToReply(data);
    setUpdateCount(updateCount + 1);
    setShowForm(false);
    resetForm();
  };

  return (
    <div className="pb-4 mx-4 mxl-lg-5">
      <div className="correspondence-reply ">
        <AuthorBox userId={reply?.userId} created={reply.created} creatorName={reply.creatorName} creatorRole={reply.creatorRole} />
        <div className="reply-body py-3">
          <p>{reply.message}</p>
          <DocumentsSection documents={reply?.attachments}></DocumentsSection>
        </div>
      </div>

      {!isClosed ? (
        <div class="">
          <>
            <CorrespondencForm
              correspondenceId={correspondenceId}
              routeInitialValues={{ correspondenceId: correspondenceId, correspondenceReplyMessageId: reply.id }}
              onSubmit={handleSubmit}
              showForm={showForm}
              level={2}
              creatorName={reply?.creatorName}
            />
          </>

          <div className="reply-toggle d-flex flex-row justify-content-end line-behind mb-4">
            <button
              className="faux-link btn pl-3"
              onClick={() => {
                setShowForm(!showForm);
              }}
            >
              Reply to this post
            </button>
          </div>
        </div>
      ) : null}

      {reply.correspondenceReplies?.length ? (
        <>
          {orderBy(reply?.correspondenceReplies, "created", "desc")?.map((replyReply, index) => {
            return (
              <div key={`${name}.${index}`}>
                <div className="blockquote ml-5 p-4 small">
                  <AuthorBox userId={replyReply.userId} created={replyReply.created} creatorName={replyReply.creatorName} />
                  <div className="reply-body">
                    {console.log(replyReply)}
                    {replyReply.message}
                    <DocumentsSection documents={replyReply.attachments} />
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : null}
    </div>
  );
};

export default CorrespondenceReply;

import React from "react";
import { outputMoney, outputPercentage } from "../../../../util/helpers";

const CompliancePaymentsSummary = (props) => {
  const { contract, complianceReport, monthPaymentsTotal, payments, awardedPlan, contractTerm } = props;
  const { totalDisbursements } = contract;
  const { assessedValueOfPlanToSdvobs, goal } = awardedPlan || 0;
  const allPaymentsTotal = payments?.length ? payments?.map((payment) => payment.amount).reduce((prev, next) => prev + next) : 0;
  // console.log(contract, awardedPlan);

  console.log(contractTerm);
  return (
    <div>
      <div className="row">
        <div className="col-5 font-weight-bold"># of SDVOBs Subcontractors</div>
        <div className="col-7">{awardedPlan?.subcontracts?.length}</div>
      </div>
      <div className="row">
        <div className="col-5 font-weight-bold">{contractTerm == "Term" ?  "SDVOB Participation Goal" : "Total Dollar Value of SDVOB Goal" }</div>
        <div className="col-7">{contractTerm == "Term" ? outputPercentage(goal / 100) : outputMoney(assessedValueOfPlanToSdvobs)}</div>

      </div>
    </div>
  );
};

export default CompliancePaymentsSummary;

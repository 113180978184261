import React from "react";
import { getLocalTime } from "../../../util/helpers";

const AuthorBox = (props) => {
  const { userId, created, creatorName, creatorRole } = props;
  // console.log(props);
  return (
    <div className="author-box d-flex flex-row justify-content-between mb-3">
      <div className="user-info d-flex flex-row">
        <div className="user-initial initial-small bg-secondary p-1 circle d-flex align-items-center justify-content-center mr-3">{userId ? userId.substr(0, 1) : null}</div>
        <p>
          {creatorName ? creatorName : null}
          <br />
          <span className="text-gray small">{creatorRole} User</span>
        </p>
      </div>

      <div className="date">{getLocalTime(created)}</div>
    </div>
  );
};
export default AuthorBox;

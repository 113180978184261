import React, { useState, useEffect, useCallback } from "react";
import QueryBuilder from "react-querybuilder";
import { loadUserToken, loadActiveOrgId, roles } from "../../util/userHelper";
import { generateReport, getReportTypes, getExistingReports, generateReportCsv } from "../../util/api";
import ReportingColumns from "./ReportingColumns";
import ReportingOptions from "./ReportingOptions";
import SaveReportModal from "./SaveReportModal";
import DeleteReportModal from "./DeleteReportModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams, useHistory } from "react-router-dom";
import ReportingResultsModal from "./ReportingResultsModal";
import "./Reporting.scss";

function generateDefaultQuery() {
  return {
    if: 'root',
    combinator: 'and',
    rules: []
  };
}

function generateDefaultReportData() {
  return {
    results: [],
    reportColumns: [],
    total: 0
  }
}

const pageSize = 50;

async function loadUserDefinedReports() {
  const userDefinedReports = await getExistingReports();

  const mappedUserDefinedReports = (userDefinedReports.reports || [])
    .map((userDefinedReport) => ({
      id: userDefinedReport.id,
      name: userDefinedReport.name,
      reportType: userDefinedReport.reportType,
      reportColumns: JSON.parse(userDefinedReport.reportColumns),
      reportQuery: JSON.parse(userDefinedReport.reportQuery)
    }))
    .sort((a, b) => a.name > b.name ? 1 : -1);

  return [
    {
      name: '',
      id: ''
    },
    ...mappedUserDefinedReports
  ]
}

const Reporting = () => {
  const [reportTypes, setReportTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isReportRunning, setIsReportRunning] = useState(false);
  const [selectedReportType, setSelectedReportType] = useState(undefined);
  const [fields, setFields] = useState([]);
  const [userRole, setUserRole] = useState('');
  const [reportData, setReportData] = useState(generateDefaultReportData());
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [existingReport, setExistingReport] = useState(undefined);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showReportingResultsModal, setShowReportingResultsModal] = useState(false);
  const [userDefinedReports, setUserDefinedReports] = useState([]);
  const [reportColumns, setReportColumns] = useState([]);
  const [query, setQuery] = useState(generateDefaultQuery());
  const [agencyId, setAgencyId] = useState(null);
  const [isDownloadingReportCsv, setIsDownloadingReportCsv] = useState(false);
  const { reportTypeOrReportId } = useParams();
  const history = useHistory();

  useEffect( () => {
    const queryableFields = (selectedReportType?.reportColumns.map(
      (reportColumn) => {
        return reportColumn.unfilterable
          ? undefined
          : {
            name: reportColumn.fieldName,
            label: reportColumn.friendlyName,
            inputType: reportColumn.inputType,
            valueEditorType: reportColumn.valueEditorType,
            values: reportColumn.options || [],
            operators:  reportColumn.operators || [],
            filterRestrictedToRoles: reportColumn.filterRestrictedToRoles || []
          };
      }
    ) || []).filter(
      (reportColumn) => reportColumn
        && reportColumn.operators.length
        && reportColumn.valueEditorType !== 'none'
        && reportColumn.filterRestrictedToRoles.indexOf(userRole) > -1
    );

    setFields(queryableFields)
  }, [selectedReportType]);

  const setQueryState = useCallback((query) => {
    setQuery(query);
  }, []);

  function resetReporting() {
    setPage(0);
    setReportData(generateDefaultReportData());
  }

  const loadExistingReport = (report, lodadedReportTypes = reportTypes) => {
    if (report && report.reportType) {
      setSelectedReportType(lodadedReportTypes.find((reportType) => reportType.reportType === report.reportType));
      setExistingReport(report);
      setQuery(report.reportQuery);
    } else {
      setSelectedReportType(selectedReportType || lodadedReportTypes[0]);
      setExistingReport(undefined);
      setQuery(generateDefaultQuery());
    }

    resetReporting();
  };

  async function fetchData() {
    const token = loadUserToken();

    if (token) {
      setUserRole(token.user.role);

      setAgencyId(
        token.user.role === roles.DivisionAdmin || token.user.role === roles.DivisionUser
          ? null
          : loadActiveOrgId()
      );
    }

    let loadedReportTypes = [];

    try {
      loadedReportTypes = await getReportTypes();
      setReportTypes(loadedReportTypes);

      const serDefinedReports = await loadUserDefinedReports();

      setUserDefinedReports(serDefinedReports);

      if (loadedReportTypes.length) {
        if (typeof reportTypeOrReportId === 'string') {
          const sanitizedreportTypeOrReportId = reportTypeOrReportId.toLowerCase();
          const newlySelectedReportType = loadedReportTypes.find((reportType) => reportType.reportType.toLowerCase() === sanitizedreportTypeOrReportId);

          if (newlySelectedReportType) {
            setSelectedReportType(newlySelectedReportType);
          } else {
            const selectedUserDefinedReport = userDefinedReports.find((userDefinedReport) => userDefinedReport.id.toLowerCase() === sanitizedreportTypeOrReportId);

            loadExistingReport(selectedUserDefinedReport);
          }
        } else {
          setSelectedReportType(loadedReportTypes[0]);
        }
      } else {
        // TODO
        throw Error('User cannot run any reports');
      }
    } catch (err) {
      // TODO: console.log('there was an issue', err)
    }

    setIsLoading(false);

    return loadedReportTypes;
  };

  // This is mostly just styling to use bootstrap classes
  const controlClassnames = {
    queryBuilder: "helper-queryBuilder", // Root <div> element
    ruleGroup: "helper-ruleGroup border pt-3 pl-3 mb-3", // <div> containing a RuleGroup
    header: "helper-header mb-3", // <div> containing the RuleGroup header controls
    body: "helper-body", // <div> containing the RuleGroup child rules/groups
    combinators: "helper-combinators", // <select> control for combinators
    addGroup: "btn btn-light mb-0 btn-sm",
    addRule: "btn btn-light mb-0 btn-sm",
    cloneGroup: "btn btn-secondary btn-sm",
    cloneRule: "btn btn-secondary btn-sm",
    lockGroup: "btn btn-secondary btn-sm",
    lockRule: "btn btn-secondary btn-sm",
    removeGroup: "btn btn-danger btn-sm col-md-1 mb-0",
    removeRule: "btn btn-danger col-2 col-md-1 btn-sm mb-0",
    combinators: "form-select form-select-sm helper-combinator col-2 order-2 mb-3",
    rule: "helper-rule row pb-3", // <div> containing the Rule
    dragHandle: "col-2 col-md-1", // <span> as drag-and-drop handle
    fields: "form-select form-select-sm helper-fields col-md-3",
    operators: "form-select form-select-sm helper-operator col-2 col-md-1",
    value: "form-select form-select-sm col-md-5 helper-value",
    valueSource: "form-select form-select-sm helper-source"
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (existingReport || selectedReportType) {
      history.push(`/reporting/${existingReport ? existingReport.id : selectedReportType.reportType}`);
    }
  }, [existingReport, selectedReportType])

  function changeReportType(event) {
    const newlySelectedReportType = reportTypes.find((reportType) => reportType.reportType === event.target.value);

    setSelectedReportType(newlySelectedReportType);
    setExistingReport(undefined);
    resetReporting();
    setQuery(generateDefaultQuery());
  }

  function changePage(newPage) {
    setPage(newPage);
    runReport(newPage);
  }

  async function runReport(pageNumber) {
    setShowReportingResultsModal(true);
    setIsReportRunning(true);
    setReportData(generateDefaultReportData());

    try {
      const data = await generateReport(
        query,
        reportColumns,
        selectedReportType.reportType,
        pageSize,
        pageNumber,
        agencyId
      );

      setTotalPages(Math.ceil(data.total / pageSize) - 1);

      setReportData({
        ...data,
        reportColumns
      });
    } catch (e) {
      // TODO
    } finally {
      setIsReportRunning(false);
    }
  }

  async function downloadReportCsv() {
    setIsDownloadingReportCsv(true);

    try {
      const reportCsvFileName = `${existingReport?.name || selectedReportType.reportType}_${new Date().getTime()}.csv`;

      const reportCsv = await generateReportCsv(
        query,
        reportColumns,
        selectedReportType.reportType,
        agencyId
      );

      const downloadCsvLink = document.createElement('a');
      downloadCsvLink.href = 'data:text/csv;charset=utf-8,' + encodeURI(reportCsv);
      downloadCsvLink.target = '_blank';
      downloadCsvLink.download = reportCsvFileName;
      downloadCsvLink.click();
    } catch (e) {
      // TODO

    } finally {
      setIsDownloadingReportCsv(false);
    }
  }

  async function onHideSaveModal() {
    try {
      const updatedUserDefinedReports = await loadUserDefinedReports();
      setUserDefinedReports(updatedUserDefinedReports);
    } catch (e) {
      // TODO
    } finally {
      setShowSaveModal(false);
    }
  }

  async function onHideDeleteModal(shouldReload = false) {
    if (!shouldReload) {
      setShowDeleteModal(false);
      return;
    }

    try {
      const updatedUserDefinedReports = await loadUserDefinedReports();
      setUserDefinedReports(updatedUserDefinedReports);
    } catch (e) {
      // TODO
    } finally {
      setExistingReport(undefined);
      setShowDeleteModal(false);
    }
  }

  function onHideReportingResultsModal() {
    resetReporting();
    setShowReportingResultsModal(false);
  }

  return (
    <>
      {!isLoading ? (
        selectedReportType ?
          <div>
            <SaveReportModal
              validateOnChange={true}
              show={showSaveModal}
              onHide={onHideSaveModal}
              reportId={existingReport?.id}
              reportType={existingReport?.reportType || selectedReportType.reportType}
              reportName={existingReport?.name}
              reportQuery={query}
              reportColumns={reportColumns}
              agencyId={agencyId}
              userDefinedReports={userDefinedReports}
            />
            <DeleteReportModal
              show={showDeleteModal}
              onHide={onHideDeleteModal}
              report={existingReport}
            />
            <ReportingResultsModal
              show={showReportingResultsModal}
              onHide={onHideReportingResultsModal}
              reportColumnDefinitions={selectedReportType.reportColumns}
              reportData={reportData}
              totalPages={totalPages}
              page={page}
              onPageChange={changePage}
              reportName={existingReport?.name || selectedReportType.name || ''}
              isReportRunning={isReportRunning}
              pageSize={pageSize}
              isDownloadingReportCsv={isDownloadingReportCsv}
              onDownloadReportCsv={downloadReportCsv}
            />
            <section
              id="build-query-form"
              className="border p-3 mb-4"
            >
              <h2>
                Reporting
              </h2>
              <ReportingOptions
                userDefinedReports={userDefinedReports}
                selectedReportType={selectedReportType}
                reportTypes={reportTypes}
                existingReportId={existingReport?.id}
                onChangeReportType={changeReportType}
                loadExistingReport={loadExistingReport}
              />
              <ReportingColumns
                reportColumnDefinitions={selectedReportType.reportColumns}
                reportColumns={reportColumns}
                setReportColumns={setReportColumns}
                existingReportColumns={existingReport?.reportColumns}
              />
              <QueryBuilder
                fields={fields}
                query={query}
                controlClassnames={controlClassnames}
                showCombinatorsBetweenRules={true}
                enableDragAndDrop={false}
                onQueryChange={setQueryState}
              />
              <div className="reporting-button-panel">
                <div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => runReport(0)}
                    disabled={isReportRunning || !reportColumns.filter((reportColumn) => reportColumn.selected).length}
                  >
                    Run Report
                  </button>
                  <button
                    type="button"
                    disabled={isDownloadingReportCsv || !reportColumns.filter((reportColumn) => reportColumn.selected).length}
                    className="btn btn-secondary"
                    onClick={() => downloadReportCsv()}
                  >
                    {isDownloadingReportCsv ? 'Downloading CSV ...' : 'Download CSV'}
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setShowSaveModal(true)}
                  >
                    Save Report
                  </button>
                  <button
                    hidden={!existingReport}
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setShowDeleteModal(true)}
                  >
                    Delete Report
                  </button>
                </div>
              </div>
            </section>
          </div>
        : <div>
          Reporting is not available
        </div>
      ) : (
        <div>
          <div>
            Loading...
          </div>
          <FontAwesomeIcon
            icon={"spinner"}
            className="fa-spin"
          />
        </div>
      )}
    </>
  );
};

export default Reporting;

import React from "react";
import { useParams } from "react-router-dom";
import { createTabNavLinks } from "../../util/helpers";

const LogsNav = (props) => {
  const { procurementId } = props;
  const items = [
    [`/procurements/${procurementId}/logs/annotations-logs`, "Annotations Logs"],
    [`/procurements/${procurementId}/logs/change-logs`, "Change Logs"],
  ];

  return (
    <div className="logs-navigation mb-5">
      <nav id={"logs-nav"} className="nav nav-tabs">
        {createTabNavLinks(items)}
      </nav>
    </div>
  );
};

export default LogsNav;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FastField, Field, FieldArray, Form } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { createGoalPlan, getAgencies, saveGoalPlan, submitGoalPlan, getSystemData } from "../../../util/api";
import { schemas } from "../../../util/errorHelper";
import { getArrayIndex, handleDocumentClick, getPercentage, outputPercentage, outputMoney } from "../../../util/helpers";
import { documentTypes, goalPlanDocumentTypes } from "../../../util/typelistHelper";
import DebugModal from "../../Debug/DebugModal";
import { withFormikFormComponent } from "../../Forms/FormikForm";
import DocumentWrapper from "../../Forms/helpers/DocumentWrapper";
import InputComponent from "../../Forms/Input/InputComponent";

const GoalPlanSubmitFormInternal = (props) => {
  const { formikBag, hasSaved, setHasSaved, reportId } = props;
  const { values, resetForm, arrayHelpers } = formikBag;
  const [agencyOptions, setAgencyOptions] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const [systemPrefs, setSystemPrefs] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const resObj = await getSystemData();
      setSystemPrefs(resObj.data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const agenciesRes = await getAgencies();
      // console.log(agenciesRes);
      const newAgencyOptions = agenciesRes.agencies.map((agency) => {
        return [agency.id, agency.name];
      });
      setAgencyOptions(newAgencyOptions);
    }
    fetchData();
  }, []);

  const getArrayIndexWrapper = (type, arrayName) => {
    const elements = _.get(values, arrayName);
    const index = getArrayIndex(elements, type);
    // console.log(elements, arrayName, index, type);
    return `${arrayName}.${index}`;
  };

  const handleSubmit = async (data, action) => {
    const {
      totalAwards,
      sdvobWebsiteContentUrl,
      sdvobSetAsides,
      discretionaries,
      annualBudget,
      budgetMinusExclusions,
      projectedSDVOBSpendingAmount,
      documents,
      specificDocuments,
      agencyId,
      fiscalYear,
    } = values;

    let submitObj = {
      id: reportId,
      totalAwards: totalAwards,
      sdvobSetAsides: sdvobSetAsides,
      discretionaries: discretionaries,
      annualBudget: annualBudget,
      budgetMinusExclusions: budgetMinusExclusions,
      projectedSDVOBSpendingAmount: projectedSDVOBSpendingAmount,
      documents: documents,
      specificDocuments: specificDocuments,
      sdvobWebsiteContentUrl: sdvobWebsiteContentUrl,
    };

    if (action === "create") {
      submitObj = { fiscalYear: fiscalYear, agencyId: agencyId };
      const resObj = await createGoalPlan(submitObj);
      history.push(`${location.pathname}/${resObj.data.id}`);
    } else if (action === "save") {
      await saveGoalPlan(submitObj);
      setHasSaved(`Report saved ${new Date().toLocaleString()}`);
    } else if (action === "submit") {
      await submitGoalPlan(submitObj);
      history.push("/agency-reporting/goal-plan");
    }
  };

  return (
    <section id="goal-plan-submit-form">
      {hasSaved ? (
        <div className="alert alert-secondary">
          <p>{hasSaved}</p>
        </div>
      ) : null}
      <DebugModal formikBag={formikBag} name="Formik Bag" />

      <div id="goal-plan-submit-form-wrapper" className="bg-light p-4">
        <h3>Goal Plan Submission</h3>
        <p>
          Pursuant to the rules and regulations of the SDVOB program, Annual Master Goal Plans, which include specific information on how a NYS Agency/Authority plans to achieve the 6% SDVOB spending
          goal required by <strong>Article 3 of the Veterans’ Services Law.</strong>, are to be submitted to the DSDVBD Director on or before January 15th.
        </p>
        <hr />
        <Form>
          <div className="field-group row mb-2">
            <FastField wrapperClass="col-md-4" label="Fiscal Year" type="number" name={"fiscalYear"} component={InputComponent} placeholder="" isdisabled={!!reportId} />
            {values.goalPlanId ? (
              <>
                <FastField wrapperClass="col-md-4" label="Total Awards" type="number" name={"totalAwards"} component={InputComponent} placeholder="" />
                <FastField wrapperClass="col-md-4" label="Annual Budget" type="number" name={"annualBudget"} component={InputComponent} placeholder="" />
                <FastField wrapperClass="col-md-4" label="Annual Budget minus Exemptions and Exclusions" type="number" name={"budgetMinusExclusions"} component={InputComponent} placeholder="" />
                <FastField wrapperClass="col-md-4" label="Projected SDVOB Spending Amount" type="number" name={"projectedSDVOBSpendingAmount"} component={InputComponent} placeholder="" />
                {/* <FastField wrapperClass="col-md-4" label="SDVOB Set-Asides" type="number" name={"sdvobSetAsides"} component={InputComponent} placeholder="" />
                <FastField wrapperClass="col-md-4" label="Discretionary" type="number" name={"discretionaries"} component={InputComponent} placeholder="" /> */}
                <p className="col-12">Goal: {outputPercentage(getPercentage(values?.projectedSDVOBSpendingAmount, values?.budgetMinusExclusions) / 100)}</p>
                <p className="col-12">SDVOB Set-Asides: {values?.sdvobSetAsides}</p>
                <p className="col-12">Discretionary: {values?.discretionaries}</p>
              </>
            ) : null}
          </div>

          {values.goalPlanId ? (
            <>
              <h2>Standard Goal Plan Documents</h2>
              <hr />

              <div className="col-12">
                {goalPlanDocumentTypes.map((documentType, index) => {
                  const label = documentTypes.find((item) => item[0] === documentType)[1];
                  const subLabel = documentTypes.find((item) => item[0] === documentType)[3];
                  return (
                    <>
                      {documentType === "SDVOBWebsiteContentPlan" && (
                        <>
                          <p>
                            <strong>SDVOB Content on Agency/Authority's Website</strong>
                          </p>
                          <FastField
                            wrapperClass=""
                            inputWrapperClass="pl-0 col-md-6"
                            label="Link to a procurement, supplier diversity, or other such webpage on the Agency/Authority's website that includes information about the SDVOB program and New York State's 6% SDVOB participation goal"
                            type="text"
                            required={false}
                            name={"sdvobWebsiteContentUrl"}
                            component={InputComponent}
                            placeholder=""
                          />
                          {!values?.sdvobWebsiteContentUrl && <p className="h4 my-3">Or</p>}
                        </>
                      )}
                      {documentType !== "SDVOBWebsiteContentPlan" || !values?.sdvobWebsiteContentUrl ? (
                        <>
                          <DocumentWrapper
                            key={index}
                            label={label}
                            subLabel={subLabel}
                            documentType={documentType}
                            name={`${getArrayIndexWrapper(documentType, "specificDocuments")}`}
                            formikBag={formikBag}
                            optional={true}
                          />
                        </>
                      ) : null}
                      <hr />
                    </>
                  );
                })}
              </div>

              <div className="col-12">
                <h2>Miscellaneous Goal Plan Documents</h2>
                <p className="pre-line">{systemPrefs.additionalGoalPlanUploadInstructions}</p>
              </div>

              <FieldArray
                id={`documents`}
                name={`documents`}
                render={(arrayHelpers) => {
                  return (
                    <>
                      {values?.documents && values?.documents?.length
                        ? values?.documents?.map((document, index) => {
                            return (
                              <div key={index} className="col-12">
                                <div className="faux-document document-sm mb-4 ">
                                  <FontAwesomeIcon className="mr-3 text-primary" icon={["fas", "times"]} size="sm" onClick={() => arrayHelpers.remove(index)} data-target={"documents"} />
                                  <span className="faux-link inline" onClick={() => handleDocumentClick(document?.documentId)}>
                                    {document.description}
                                  </span>
                                </div>
                              </div>
                            );
                          })
                        : []}
                      <Field
                        name={`temp_field`}
                        wrapperClass="col-sm-8 col-md-4 col-xl-4 file-sm"
                        label="Add new document"
                        type="fileonly"
                        component={InputComponent}
                        onFileUpload={(document) => {
                          arrayHelpers.push({ ...document });
                        }}
                      />
                    </>
                  );
                }}
              />
            </>
          ) : null}

          <div className="row">
            {values.goalPlanId ? (
              <>
                <div className="col-md-6">
                  <Button
                    onClick={async (submitObj) => {
                      await handleSubmit(submitObj, "save");
                    }}
                    type="button"
                    variant="secondary"
                    className="btn-md btn-submit btn-block"
                    // disabled={!formikBag.isValid}
                  >
                    Save
                  </Button>
                </div>
                <div className="col-md-6">
                  <Button
                    onClick={async (submitObj) => {
                      await handleSubmit(submitObj, "submit");
                    }}
                    type="button"
                    variant="secondary"
                    className="btn-md btn-submit btn-block"
                    disabled={!formikBag.isValid}
                  >
                    Submit
                  </Button>
                </div>
              </>
            ) : (
              <div className="col-md-6">
                <Button
                  onClick={async (submitObj) => {
                    await handleSubmit(submitObj, "create");
                  }}
                  type="button"
                  variant="secondary"
                  className="btn-md btn-submit btn-block"
                  disabled={!formikBag.isValid}
                >
                  Create
                </Button>
              </div>
            )}
          </div>
        </Form>
      </div>
    </section>
  );
};

const GoalPlanSubmitForm = withFormikFormComponent(GoalPlanSubmitFormInternal, schemas.submitGoalPlanSchema);
export default GoalPlanSubmitForm;

import React, { useState, useEffect } from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { getLocalDate, outputMoney, handleDocumentClick, isPrime, tableSortByValue, getYearMonth, isPastMonth } from "../../../util/helpers";
import { loadUserRole, roles } from "../../../util/userHelper";
import { paymentStatuses } from "../../../util/typelistHelper";
import FilterTableForm from "../../Forms/Input/FilterTableForm";
import PaymentCreateForm from "./PaymentCreateForm";
import ModalActions from "../../DivisionPortal/Applications/ModalActions";
import { Modal, Button } from "react-bootstrap";

const sortByValue = (rowA, rowB) => {
  return tableSortByValue(rowA, rowB, "complianceDate");
};

const FilterListConfig = {
  subcontractor: {
    isJoin: false,
  },
  paidDate: {
    isJoin: false,
  },
  paidBy: {
    isJoin: false,
  },
};

let Columns = [
  {
    Header: "Paid Date",
    accessor: "paidDate", // accessor is the "key" in the data
    disableSortBy: false,
    filterCode: "paidDate",
  },
  {
    Header: "Subcontractor",
    accessor: "subcontractor", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "subcontractor",
  },
  {
    Header: "Amount",
    accessor: "amount", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "amount",
  },
  {
    Header: "Confirmed status",
    accessor: "status", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "status",
  },
  {
    Header: "File",
    accessor: "attachment", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "attachment",
  },
  {
    Header: "Compliance Dates",
    accessor: "date", // accessor is the "key" in the data
    sortType: sortByValue,
    filterCode: "date",
  },
  {
    Header: "Paid By",
    accessor: "paidBy", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "paidBy",
  },
  {
    Header: "Actions",
    accessor: "actions", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "actions",
  },
];

const PaymentsList = (props) => {
  const { awardedPlan, procurement, payments, procurementId, handlePaymentSubmit, handleDelete, payment, paymentId, initialPaymentValues, setInitialPaymentValues, onSubmit } = props;
  const { contract } = procurement;
  const [showModal, setShowModal] = useState(false);
  const [modalChoice, setModalChoice] = useState();
  const [selectedPayment, setSelectedPayment] = useState({});
  const history = useHistory();

  const initialItems = payments
    ? payments?.map((item) => {
        // console.log(item.amount, item.documents);
        let wItem = {};
        const { amount, id, documents, complianceDate, status, paidDate } = item;
        wItem = { amount, id, documents, complianceDate };

        return {
          ...item,
          date: isPastMonth(item.complianceDate) ? (
            <a href={`compliance-reports/${getYearMonth(item.complianceDate)}`}>{getYearMonth(item.complianceDate)}</a>
          ) : (
            <span>{getYearMonth(item.complianceDate)}</span>
          ),
          paidBy: item.paidBy ?? "Prime",
          paidDate: getLocalDate(paidDate),
          // sortDate: `${complianceDate.getFullYear()}/${complianceDate.getMonth()}`,
          // date: `${complianceDate.getMonth()}/${complianceDate.getFullYear()}`,
          nameLink: <a href={`/procurements/${item.id}/details`}>{contract.name}</a>,
          agency: contract?.agency?.name,
          subcontractor: item.contractor.name,
          amount: outputMoney(amount),
          status: _.startCase(item.status),
          attachment: (
            <div>
              {item?.documents.map((doc) => {
                return (
                  <button
                    className="faux-link"
                    onClick={() => {
                      handleDocumentClick(doc?.documentId);
                    }}
                  >
                    <FontAwesomeIcon className="mr-2" icon={["fas", "paperclip"]} size="sm" />
                  </button>
                );
              })}
            </div>
          ),
          actions: (
            <div>
              <button
                type="link"
                className="faux-link"
                to={`/procurements/${procurementId}/payments/${item.id}`}
                aria-label="Edit"
                onClick={() => {
                  window.scrollTo(0, 0);
                  history.push(`/procurements/${procurementId}/payments/${item.id}`);
                }}
              >
                <FontAwesomeIcon className="mr-2 text-success" icon={["fas", "pencil-alt"]} size="sm" />
              </button>{" "}
              |{" "}
              <button
                type="link"
                className="faux-link"
                aria-label="Delete"
                onClick={() => {
                  // handleDelete(item.id);
                  setSelectedPayment(item);
                  setShowModal(true);
                }}
              >
                <FontAwesomeIcon className="mr-2 text-primary" icon={["fas", "trash"]} size="sm" />
              </button>
            </div>
          ),
        };
      })
    : null;

  return (
    <div>
      <h2>Payments</h2>

      {isPrime(awardedPlan) || [roles.AgencyAdmin, roles.AgencyUser].includes(loadUserRole()) ? (
        <PaymentCreateForm
          paymentId={paymentId}
          payment={payment}
          awardedPlan={awardedPlan}
          onSubmit={onSubmit}
          procurement={procurement}
          routeInitialValues={initialPaymentValues}
          setInitialPaymentValues={setInitialPaymentValues}
          validateOnChange={true}
          // validateOnBlur={true}
          // validateOnMount={true}
        />
      ) : null}

      {initialItems ? (
        initialItems?.length ? (
          <FilterTableForm tableName="payments-list" Columns={Columns} FilterListConfig={FilterListConfig} tableItems={initialItems} />
        ) : (
          <h3>No payments to show.</h3>
        )
      ) : (
        <h2>Loading payments...</h2>
      )}

      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mx-3">
            Are you sure you would like to delete the payment for {outputMoney(selectedPayment?.amount)} made to {selectedPayment?.contractor?.name} on {getLocalDate(selectedPayment?.paidDate)}
          </div>
        </Modal.Body>

        <Modal.Footer className="d-block">
          <div className="row">
            <div className="col-lg-6">
              <Button
                className="btn-block btn-sm"
                variant="secondary"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Cancel
              </Button>
            </div>
            <div className="col-lg-6">
              <Button
                className="btn-block btn-sm"
                variant="primary"
                onClick={async () => {
                  setShowModal(false);
                  const resObj = await handleDelete(selectedPayment.id);
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PaymentsList;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { confirmPayment, getPaymentsByContractor } from "../../../util/api";
import { loadActiveOrgId } from "../../../util/userHelper";
import { getLocalDate, handleDocumentClick } from "../../../util/helpers";
import FilterTableForm from "../../Forms/Input/FilterTableForm";
import ConfirmPaymentsForm from "./ConfirmPaymentsForm";

const FilterListConfig = {
  date: {
    isJoin: false,
  },
};

const Columns = [
  {
    Header: "Date",
    accessor: "date", // accessor is the "key" in the data
    disableSortBy: false,
    filterCode: "date",
  },
  {
    Header: "Agency",
    accessor: "contractAgency.name", // accessor is the "key" in the data
    disableSortBy: true,
  },
  {
    Header: "Contract",
    accessor: "contractNumber", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "contractNumber",
  },
  {
    Header: "Amount",
    accessor: "amount", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "amount",
  },
  {
    Header: "Receipt",
    accessor: "receipt", // accessor is the "key" in the data
    disableSortBy: true,
  },
  {
    Header: "Prime",
    accessor: "prime", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "prime",
  },
  {
    Header: "Confirmed",
    accessor: "status", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "status",
  },
  {
    Header: "Actions",
    accessor: "actions", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "actions",
  },
  {
    Header: "Procurement",
    accessor: "procurmentDescription", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "procurmentDescription",
  },
];

const SubPayments = (props) => {
  const { contract } = props;
  const [payments, setPayments] = useState([]);
  const [updateCount, setUpdateCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const paymentsRes = await getPaymentsByContractor();
      setPayments(paymentsRes.data.payments);
    };
    fetchData();
  }, [updateCount]);

  const initialItems = payments?.length
    ? payments?.map((item, index) => {
        const complianceDate = new Date(item?.complianceDate);
        let options = { year: "numeric", month: "numeric" };
        const initialValues = {
          contractId: item?.contractId,
          contractorId: item?.contractor?.id,
          paymentId: item.id,
        };

        const handleSubmit = async (paymentObj, action) => {
          const paymentsObjMod = { ...paymentObj, contractorId: loadActiveOrgId() };
          const resObj = await confirmPayment(paymentObj, action);
          setUpdateCount(updateCount + 1);
        };

        return {
          ...item,
          date: <span className="text-nowrap">{getLocalDate(item.paidDate)}</span>,
          amount: <>${item.amount.toLocaleString()} </>,
          receipt: (
            <div>
              {item?.documents.map((doc) => {
                return (
                  <button
                    className="faux-link"
                    onClick={() => {
                      handleDocumentClick(doc?.documentId);
                    }}
                  >
                    <FontAwesomeIcon icon={["fas", "paperclip"]} size="sm" />
                  </button>
                );
              })}
            </div>
          ),
          contractNumber: <span className="text-nowrap">{item.contractNumber}</span>,
          status: item.status,
          prime: item?.primeContractor ? item?.primeContractor?.name : "Unknown",
          // contractId: item?.contractId,
          actions: <ConfirmPaymentsForm index={index} confirmed={item.confirmed} routeInitialValues={initialValues} onSubmit={handleSubmit} />,
          status: <span>{_.startCase(item.status)}</span>,
        };
      })
    : [];

  return (
    <section id="payments-list">
      <div>
        <h2>Payment Confirmation</h2>
        {initialItems && initialItems?.length ? (
          <FilterTableForm tableName="sub-payment-list" Columns={Columns} FilterListConfig={FilterListConfig} tableItems={initialItems} />
        ) : (
          <h3>No payments to show</h3>
        )}
      </div>
    </section>
  );
};
export default SubPayments;

import Settings from "../components/AgencyAdmin/Settings";
import Help from "../components/Help/Help";
import BusinessApplication from "../components/BusinessApplication/BusinessApplication";
import AttestationForm from "../components/BusinessApplication/tabs/AttestationForm";
import BusinessDetailOverview from "../components/BusinessDetail/BusinessDetailOverview";
import BusinessSearch from "../components/BusinessSearch/BusinessSearch";
import BusinessProcurementListOverview from "../components/Procurements/BusinessProcurementListOverview";
import ProcurementOverview from "../components/Procurements/ProcurementOverview";
import BusinessUtilizationPlans from "../components/Procurements/UtilizationPlans/BusinessUtilizationPlans";
import PlanPresence from "../components/Procurements/UtilizationPlans/PlanPresence";
import SubmitUtilizationPlan from "../components/Procurements/UtilizationPlans/SubmitUtilizationPlan";
// import SubmitUtilizationPlanAgency from "../components/Procurements/UtilizationPlans/SubmitUtilizationPlanAgency";
import ViewUtilizationPlan from "../components/Procurements/UtilizationPlans/ViewUtilizationPlan";
import OwnerBusinessDetail from "../components/BusinessDetail/LEGACY_OwnerBusinessDetail";
// import GovernmentBusinessDetail from "../components/BusinessDetail/GovernmentBusinessDetail";
import ApplicationChangeRequest from "../components/DivisionPortal/Applications/LEGACY_ApplicationChangeRequest";
import SystemsPreferences from "../components/DivisionPortal/SystemPreferences/SystemPreferences";
import ApplicationList from "../components/DivisionPortal/Applications/ApplicationList";
import ApplicationOverviewDivision from "../components/DivisionPortal/Applications/ApplicationOverviewDivision";
import CertificationTimerList from "../components/DivisionPortal/Timers/CertificationTimerList";
import MaintenanceTimerList from "../components/DivisionPortal/Timers/MaintenanceTimerList";
import DocumentChecklist from "../components/BusinessApplication/DocumentChecklist/DocumentChecklist";
import NotificationList from "../components/Notifications/NotificationList";
import PortalHome from "../components/PortalHome/PortalHome";
import Profile from "../components/Profile/Profile";
import Logout from "../pages/Logout";
import ComplianceReportSubmit from "../components/Procurements/Sections/ComplianceReports/ComplianceReportSubmit";
import InvitePrime from "../components/AgencyAdmin/InvitePrime";
import AgencyProcurements from "../components/AgencyAdmin/AgencyProcurements";
import AgencyProcurementsListOverview from "../components/AgencyAdmin/AgencyProcurementsListOverview";
// import ComplianceReportsAgency from "../components/Procurements/Views/ComplianceReportsAgency";
import PrimeContractor from "../components/Signup/PrimeContractor";
import { loadActiveOrgId, roles } from "../util/userHelper";
import UpdateBusinessInfo from "../components/Signup/UpdateBusinessInfo";
import Reporting from "../components/Reporting/Reporting";
import AgencyReporting from "../components/AgencyReporting/AgencyReporting";
import AgencyReportingReview from "../components/AgencyReporting/AgencyReportingReview";
import UserManagement from "../components/DivisionPortal/users/UserManagement";
import CentralizedContracts from "../components/DivisionPortal/CentralizedContracts/CentralizedContracts";
import CorrespondenceWrapper from "../components/Procurements/Correspondence/CorrespondenceWrapper";

import Application404 from "../pages/Application404";
import DocumentDownload from "../components/DocumentDownload/DocumentDownload";

export const open_routes = [
  //shared routes
  {
    path: "/business-search",
    title: "SDVOB Search",
    exact: true,
    component: BusinessSearch,
  },
  {
    path: "/business-search/:businessId",
    title: "SDVOB Search By Business Id",
    exact: true,
    component: BusinessDetailOverview,
  },
  // login logout and callback
  {
    path: "/logout",
    title: "Logout",
    exact: true,
    component: Logout,
  },
  {
    path: "/help",
    title: "Help",
    exact: true,
    component: Help,
  },
  {
    path: "/help/:videoId",
    title: "Help",
    exact: true,
    component: Help,
  },
  // {
  //   path: "/",
  //   title: "Home",
  //   exact: true,
  //   component: Application404,
  // },
];

export const secure_routes = [
  //shared routes
  {
    path: "/",
    title: "Home",
    exact: true,
    component: PortalHome,
  },
  {
    path: "/business-search",
    title: "SDVOB Search",
    exact: true,
    component: BusinessSearch,
    roles: [roles.AgencyAdmin, roles.Business, roles.AgencyUser, roles.DivisionAdmin, roles.DivisionUser],
  },
  {
    path: "/document/:documentId/download",
    title: "Document Download",
    exact: true,
    component: DocumentDownload,
    roles: [roles.AgencyAdmin, roles.Business, roles.AgencyUser, roles.DivisionAdmin, roles.DivisionUser],
  },
  {
    path: "/reconcile-owner/:ownerId",
    title: "Reconcile user to owner",
    exact: true,
    component: BusinessDetailOverview,
    roles: [roles.Business],
  },
  {
    path: "/business-search/:businessId",
    title: "SDVOB Search By Business Id",
    exact: true,
    component: BusinessDetailOverview,
    roles: [roles.AgencyAdmin, roles.Business, roles.AgencyUser, roles.DivisionAdmin, roles.DivisionUser],
  },
  {
    path: "/profile",
    title: "Profile",
    exact: true,
    component: Profile,
    roles: [roles.AgencyAdmin, roles.Business, roles.AgencyUser, roles.DivisionAdmin, roles.DivisionUser],
  },
  {
    path: "/notifications",
    title: "Notifications",
    exact: true,
    component: NotificationList,
    roles: [roles.AgencyAdmin, roles.AgencyUser, roles.Business, roles.DivisionAdmin, roles.DivisionUser],
  },
  {
    path: "/notifications/:notificationId",
    title: "Notifications",
    exact: true,
    component: NotificationList,
    roles: [roles.AgencyAdmin, roles.AgencyUser, roles.Business, roles.DivisionAdmin, roles.DivisionUser],
  },
  // Business routes
  {
    path: "/document-checklist/:applicationId",
    title: "Document Checklist",
    exact: true,
    component: DocumentChecklist,
    roles: [roles.Business],
  },
  {
    path: "/document-checklist",
    title: "Document Checklist",
    exact: true,
    component: DocumentChecklist,
    roles: [roles.Business],
  },
  // {
  //   path: "/procurement/:procurementId/compliance-reports",
  //   title: "Compliance Reports",
  //   exact: true,
  //   component: ComplianceReportList,
  //   roles: [roles.Business]
  // },
  {
    path: "/procurement/:procurementId/compliance-reports/:year-:month",
    title: "Compliance Reports",
    exact: true,
    component: ComplianceReportSubmit,
    roles: [roles.Business],
  },
  {
    path: "/invite-prime",
    title: "Invite Prime",
    exact: true,
    component: InvitePrime,
    roles: [roles.AgencyUser, roles.AgencyAdmin],
  },
  {
    path: "/procurements",
    title: "Agency Procurements",
    exact: true,
    component: AgencyProcurements,
    roles: [roles.AgencyUser, roles.AgencyAdmin],
  },
  {
    path: "/procurements/:procurementId/",
    title: "Contract",
    exact: false,
    component: ProcurementOverview,
    roles: [roles.Business, roles.AgencyAdmin, roles.AgencyUser, roles.DivisionAdmin, roles.DivisionUser],
  },
  {
    path: "/procurements-list",
    title: "Agency Procurements",
    exact: false,
    component: AgencyProcurementsListOverview,
    roles: [roles.AgencyUser, roles.AgencyAdmin],
  },
  // {
  //   path: "/procurement/:procurementId/compliance-reports/:year-:month",
  //   title: "Compliance Reports",
  //   exact: true,
  //   component: ComplianceReportsAgency,
  //   roles: [roles.AgencyUser, roles.AgencyAdmin],
  // },
  {
    path: "/procurements-list",
    title: "Contracts",
    exact: false,
    component: BusinessProcurementListOverview,
    roles: [roles.Business, roles.AgencyAdmin, roles.AgencyUser],
  },
  {
    path: "/user-list",
    title: "UserList",
    exact: false,
    component: UserManagement,
    roles: [roles.AgencyAdmin, roles.DivisionAdmin],
  },
  {
    path: "/utilization-plans/",
    title: "Utilization Plans",
    exact: true,
    component: BusinessUtilizationPlans,
    roles: [roles.Business],
  },
  {
    path: "/plan-presence/",
    title: "Utiization Plan Presence",
    exact: true,
    component: PlanPresence,
    roles: [roles.Business],
  },
  {
    path: "/utilization-plans/submit",
    title: "Utilization Plans",
    exact: true,
    component: SubmitUtilizationPlan,
    roles: [roles.Business, roles.AgencyAdmin, roles.AgencyUser],
    initialValues: {
      agencyId: "",
      solicitationNumber: "",
      subcontracts: [],
      procurementId: "",
      goal: null,
      realGoal: 0,
      realValue: 0,
      status: "",
      value: null,
      totalSdvob: 0,
      documents: [],
      term: "",
    },
    validateOnChange: true,
  },
  {
    path: "/utilization-plans/agency-submit/:procurementId",
    title: "Utilization Plans",
    exact: true,
    component: SubmitUtilizationPlan,
    roles: [roles.AgencyAdmin, roles.AgencyUser],
    initialValues: {
      agencyId: "",
      solicitationNumber: "",
      subcontracts: [],
      procurementId: "",
      goal: null,
      realValue: 0,
      status: "",
      value: null,
      totalSdvob: 0,
      documents: [],
    },
  },
  {
    path: "/utilization-plans/:planId/submit",
    title: "Utilization Plans",
    exact: true,
    component: SubmitUtilizationPlan,
    roles: [roles.Business, roles.AgencyAdmin, roles.AgencyUser],
    initialValues: {
      agencyId: "",
      solicitationNumber: "",
      subcontracts: [],
      procurementId: "",
      goal: null,
      realValue: 0,
      status: "",
      value: null,
      totalSdvob: 0,
      documents: [],
    },
  },
  {
    path: "/utilization-plans/:planId/view",
    title: "Utilization Plans",
    exact: true,
    component: ViewUtilizationPlan,
    roles: [roles.Business],
  },
  {
    path: "/settings",
    title: "Settings",
    exact: true,
    component: Settings,
    roles: [roles.AgencyAdmin, roles.AgencyUser],
    initialValues: {
      contractImportSourceType: "",
      sourceUrl: "",
      sourceFile: "",
      emailRemindersActive: false,
      emailLogo: "",
    },
  },
  {
    path: "/application/:applicationId",
    title: "Application",
    exact: true,
    component: BusinessApplication,
    roles: [roles.Business],
  },
  {
    path: "/application-attest/:applicationId",
    title: "Application",
    exact: true,
    component: AttestationForm,
    roles: [roles.Business],
  },
  {
    path: "/business/:businessId/correspondence/",
    title: "Division Correspondence",
    exact: true,
    component: CorrespondenceWrapper,
    roles: [roles.Business],
  },
  {
    path: "/business/:businessId/correspondence/:correspondenceId",
    title: "Single Division Correspondence",
    exact: false,
    component: CorrespondenceWrapper,
    roles: [roles.Business],
  },
  {
    path: "/business/:businessId",
    title: "Business",
    exact: false,
    component: BusinessDetailOverview,
    roles: [roles.Business, roles.DivisionUser, roles.DivisionAdmin],
  },

  //Division routes
  {
    path: "/system-preferences",
    title: "Business",
    exact: false,
    component: SystemsPreferences,
    roles: [roles.DivisionAdmin],
  },
  // {
  //   path: "/business/:businessId",
  //   title: "Business",
  //   exact: false,
  //   component: BusinessDetailOverview,
  //   roles: [roles.DivisionUser, roles.DivisionAdmin],
  // },
  // {
  //   path: "/business/edit/:businessId",
  //   title: "Business",
  //   exact: true,
  //   component: BusinessDetailOverview,
  //   roles: [roles.DivisionUser, roles.DivisionAdmin],
  // },
  {
    path: "/applications",
    title: "Application List",
    exact: true,
    component: ApplicationList,
    roles: [roles.DivisionUser, roles.DivisionAdmin],
  },
  {
    path: "/application/:applicationId/",
    title: "Application",
    exact: false,
    component: ApplicationOverviewDivision,
    initialValues: { notes: "", status: "Deficient" },
  },
  // {
  //   path: "/application/:applicationId/change-requests",
  //   title: "Application",
  //   exact: true,
  //   component: ApplicationChangeRequest,
  // },
  {
    path: "/notifications",
    title: "Application",
    exact: true,
    component: NotificationList,
    initialValues: { notifications: [] },
  },
  {
    path: "/certification-timers",
    title: "Application",
    exact: true,
    component: CertificationTimerList,
  },
  {
    path: "/maintenance-timers",
    title: "Application",
    exact: true,
    component: MaintenanceTimerList,
  },
  {
    path: "/centralized-contracts",
    title: "Centralized Contracts",
    exact: true,
    component: CentralizedContracts,
    roles: [roles.DivisionAdmin],
  },
  {
    path: "/centralized-contracts/:centralizedContractId",
    title: "Centralized Contracts",
    exact: true,
    component: CentralizedContracts,
    roles: [roles.DivisionAdmin],
  },

  //Agency Routes
  {
    path: "/signup/prime-contractor",
    title: "Prime Contractor Signup ",
    exact: true,
    component: PrimeContractor,
  },
  // {
  //   path: "/business-edit",
  //   title: "Prime Contractor Edit ",
  //   exact: true,
  //   component: UpdateBusinessInfo,
  //   roles: [roles.Business, roles.DivisionUser, roles.DivisionAdmin],
  // },
  {
    path: "/agency-reporting/:reportType/:reportId",
    title: "Agency Submissions",
    exact: true,
    component: AgencyReporting,
    roles: [roles.AgencyAdmin, roles.AgencyUser, roles.DivisionAdmin, roles.DivisionUser],
  },
  {
    path: "/agency-reporting/:reportType",
    title: "Agency Submissions",
    exact: true,
    component: AgencyReporting,
    roles: [roles.AgencyAdmin, roles.AgencyUser, roles.DivisionAdmin, roles.DivisionUser],
  },
  {
    path: "/agency-reporting/",
    title: "Agency Submissions",
    exact: true,
    component: AgencyReporting,
    roles: [roles.AgencyAdmin, roles.AgencyUser, roles.DivisionAdmin, roles.DivisionUser],
  },
  // {
  //   path: "/agency-reporting/",
  //   title: "Agency Reporting",
  //   exact: false,
  //   component: AgencyReportingReview,
  //   roles: [roles.DivisionAdmin, roles.DivisionUser],
  // },
  {
    path: "/reporting/:reportTypeOrReportId",
    title: "Reports",
    exact: false,
    component: Reporting,
    initialValues: {},
    roles: [roles.AgencyAdmin, roles.AgencyUser, roles.DivisionAdmin, roles.DivisionUser],
  },
  {
    path: "/reporting",
    title: "Reports",
    exact: false,
    component: Reporting,
    initialValues: {},
    roles: [roles.AgencyAdmin, roles.AgencyUser, roles.DivisionAdmin, roles.DivisionUser],
  },
  {
    path: "/correspondence/",
    title: "Division Correspondence",
    exact: true,
    component: CorrespondenceWrapper,
    roles: [roles.DivisionAdmin, roles.DivisionUser],
  },
  {
    path: "/correspondence/:correspondenceId",
    title: "Division Correspondence",
    exact: false,
    component: CorrespondenceWrapper,
    roles: [roles.DivisionAdmin, roles.DivisionUser],
  },
];

import React, { useEffect, useState } from "react";
import InputComponent from "../../Forms/Input/InputComponent";
import { withFormikFormComponent } from "../../Forms/FormikForm";
import { FastField } from "formik";
import { getUnassignedContracts, assignContract } from "../../../util/api";

const AssignContractInternal = (props) => {
  // console.log(props);
  const { formikBag, procurement, setUpdateCount, updateCount } = props;
  const { values } = formikBag;
  const { agencyId } = procurement;
  const [unassignedContracts, setUnassignedContracts] = useState(null);

  // useEffect(() => {
  //   async function fetchData() {
  //     const contractsRes = await getUnassignedContracts(agencyId);
  //     // console.log(contractsRes.contracts);
  //     let contractsArr = contractsRes?.contracts.map((contract) => {
  //       return [contract.id, contract.contractName];
  //     });
  //     contractsArr.sort((a, b) => (a[1] < b[1] ? -1 : 1));
  //     setUnassignedContracts(contractsArr);
  //   }

  //   if (!values?.conractId && agencyId) {
  //     fetchData();
  //   }
  // }, [agencyId]);

  const handleAssignContract = async () => {
    await assignContract(procurement.id, values.contractNumber);
    setUpdateCount(updateCount + 1);
  };

  return (
    <>
      {/* {unassignedContracts && unassignedContracts.length ? ( */}
      <section id="assign-contract">
        <div id="invite-prime-form-wrapper" className="bg-light p-4">
          <h2>Assign contract to procurement</h2>
          <div className="field-group row align-items-end">
            <FastField
              inline={true}
              wrapperClass="col-lg-8 "
              label="Contract number to assign"
              // options={[["", "Select Contract"], ...unassignedContracts]}
              type="text"
              name={`contractNumber`}
              component={InputComponent}
              placeholder=""
            />
            {/* <FastField
                inline={true
                labelWrapperClass="col-md-4"
                inputWrapperClass="col-md-3"
                wrapperClass="col-md-6 col-lg-4"
                label="Contract to assign"
                options={[["", "Select Contract"], ...unassignedContracts]}
                type="select"
                name={`contractId`}
                component={InputComponent}
                placeholder=""
              /> */}

            <div className="col-lg-4 mb-2">
              <button
                type="submit"
                className="btn btn-sm btn-secondary mb-0"
                onClick={() => {
                  handleAssignContract();
                }}
              >
                Assign Contract
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* ) : procurement?.id && unassignedContracts === null ? (
        <span className="loader loader-pulse ml-3">Loading Contracts...</span>
      ) : (
        <span className="ml-3">No Contracts Found</span>
      )} */}
    </>
  );
};

const AssignContract = withFormikFormComponent(AssignContractInternal);
export default AssignContract;

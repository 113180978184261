import React, { useState, useEffect } from "react";
import FilterTableForm from "../../Forms/Input/FilterTableForm";
import { getLocalTime } from "../../../util/helpers";
import { getChangeLogs } from "../../../util/api";

const FilterListConfig = {
  propertyName: {
    isJoin: false,
  },
};

const Columns = [
  {
    Header: "Field",
    accessor: "propertyName", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "propertyName",
  },
  {
    Header: "Date",
    accessor: "created", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "created",
  },
  {
    Header: "Changed From",
    accessor: "previousValue", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "previousValue",
  },
  {
    Header: "Changed To",
    accessor: "newValue", // accessor is the "key" in the data
    disableSortBy: true,
    filterCode: "newValue",
  },
];

const ChangeLogList = (props) => {
  // console.log(props);
  const { procurement, contract, procurementId } = props;
  const [changeLogs, setChangeLogs] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const resObj = await getChangeLogs(contract.id);
      setChangeLogs(resObj.data.contractRevisions);
    };
    if (contract?.id) {
      fetchData();
    }
  }, []);

  const initialItems = changeLogs
    ? changeLogs?.map((item, index) => {
        return {
          ...item,
          previousValue: item.previousValue ? item.previousValue : "Unknown",
          newValue: item.newValue,
          propertyName: item.propertyName,
          created: getLocalTime(item.revisionDate),
        };
      })
    : null;

  // console.log(initialItems);

  return (
    <section id="annotations-list">
      <div>
        <h2>
          Change Log for <span className="font-weight-normal">{procurement?.description}</span>
        </h2>
        {initialItems ? (
          initialItems.length ? (
            <FilterTableForm tableName="change-log-list" Columns={Columns} FilterListConfig={FilterListConfig} tableItems={initialItems} />
          ) : (
            <h3>No Change Logs to Show</h3>
          )
        ) : (
          <h2>Loading...</h2>
        )}
      </div>
    </section>
  );
};

export default ChangeLogList;
